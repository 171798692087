
#homepageVideoModal {

  label {
    font-weight: normal;
  }

  .modal-content {
    background-color: #fff;
    color: #333;
    border-radius: 6px;

    .modal-header {
      border-bottom: 1px solid #e5e5e5;
      padding: 0 15px 10px 15px;

      .close {
        color: #333;
        font-size: 25px;
        padding: 0;
      }
    }

    .modal-body {
      padding-bottom: 0;

      section {
        margin-bottom: 0.8rem;
      }
    }
  }
}

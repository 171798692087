<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-0.5 166.1543 800.49805 467.69141">
  <rect id="flag_rectangle" y="166.6543" inkscape:label="flag_rectangle" class="eu1" width="799.49805" height="466.69141">
  </rect>
  <path id="flag_star_8_" sodipodi:r1="24" inkscape:label="flag_star" sodipodi:cy="166.82816" sodipodi:type="star" sodipodi:arg1="0.9451007" sodipodi:sides="5" sodipodi:arg2="1.5434192" sodipodi:cx="493.77145" sodipodi:r2="9.1000004" inkscape:rounded="0" inkscape:randomized="0" inkscape:flatsided="false" class="eu0" d="
    M339.26611,551.67188l-14.97363-11.23145l-15.62402,11.15723l6.06104-17.70605l-15.42871-11.41699l18.70361,0.29199l6.08154-18.2041
    l5.51904,17.87793l19.19141,0.16309l-15.31006,10.7666L339.26611,551.67188L339.26611,551.67188z"/>
  <path id="flag_star_9_" sodipodi:r1="24" inkscape:label="flag_star" sodipodi:cy="166.82816" sodipodi:type="star" sodipodi:arg1="0.9451007" sodipodi:sides="5" sodipodi:arg2="1.5434192" sodipodi:cx="493.77145" sodipodi:r2="9.1000004" inkscape:rounded="0" inkscape:randomized="0" inkscape:flatsided="false" class="eu0" d="
    M414.9668,571.96289l-14.97266-11.23242l-15.625,11.15723l6.06152-17.70703l-15.42871-11.41602l18.70264,0.29297l6.08252-18.20508
    l5.51953,17.87891l19.19141,0.16309l-15.31055,10.7666L414.9668,571.96289L414.9668,571.96289z"/>
  <path id="flag_star_10_" sodipodi:r1="24" inkscape:label="flag_star" sodipodi:cy="166.82816" sodipodi:type="star" sodipodi:arg1="0.9451007" sodipodi:sides="5" sodipodi:arg2="1.5434192" sodipodi:cx="493.77145" sodipodi:r2="9.1000004" inkscape:rounded="0" inkscape:randomized="0" inkscape:flatsided="false" class="eu0" d="
    M490.66797,551.67188l-14.97266-11.23145l-15.625,11.15723l6.06055-17.70605l-15.42969-11.41699l18.70508,0.29199l6.08203-18.2041
    l5.51953,17.87793l19.18945,0.16309l-15.30859,10.7666L490.66797,551.67188L490.66797,551.67188z"/>
  <path id="flag_star_11_" sodipodi:r1="24" inkscape:label="flag_star" sodipodi:cy="166.82816" sodipodi:type="star" sodipodi:arg1="0.9451007" sodipodi:sides="5" sodipodi:arg2="1.5434192" sodipodi:cx="493.77145" sodipodi:r2="9.1000004" inkscape:rounded="0" inkscape:randomized="0" inkscape:flatsided="false" class="eu0" d="
    M546.85742,495.4834L531.88477,484.25l-15.62305,11.15723l6.05859-17.70605l-15.42773-11.41602l18.70313,0.29199l6.08398-18.2041
    l5.51758,17.87793l19.19141,0.16309l-15.31055,10.7666L546.85742,495.4834L546.85742,495.4834z"/>
  <path id="flag_star_4_" sodipodi:r1="24" inkscape:label="flag_star" sodipodi:cy="166.82816" sodipodi:type="star" sodipodi:arg1="0.9451007" sodipodi:sides="5" sodipodi:arg2="1.5434192" sodipodi:cx="493.77145" sodipodi:r2="9.1000004" inkscape:rounded="0" inkscape:randomized="0" inkscape:flatsided="false" class="eu0" d="
    M340.82666,287.89014l-14.97412-11.23291L310.229,287.81445l6.06055-17.70605l-15.42822-11.41699l18.70264,0.29248l6.08252-18.20459
    l5.51904,17.87988l19.19092,0.1626l-15.30908,10.76611L340.82666,287.89014L340.82666,287.89014z"/>
  <path id="flag_star_6_" sodipodi:r1="24" inkscape:label="flag_star" sodipodi:cy="166.82816" sodipodi:type="star" sodipodi:arg1="0.9451007" sodipodi:sides="5" sodipodi:arg2="1.5434192" sodipodi:cx="493.77145" sodipodi:r2="9.1000004" inkscape:rounded="0" inkscape:randomized="0" inkscape:flatsided="false" class="eu0" d="
    M264.34521,418.2207l-14.97314-11.2334l-15.62402,11.15723l6.06152-17.70508l-15.4292-11.41748l18.70361,0.29297l6.08252-18.20459
    l5.51855,17.87939l19.19141,0.16211l-15.31006,10.7666L264.34521,418.2207L264.34521,418.2207z"/>
  <path id="flag_star_7_" sodipodi:r1="24" inkscape:label="flag_star" sodipodi:cy="166.82816" sodipodi:type="star" sodipodi:arg1="0.9451007" sodipodi:sides="5" sodipodi:arg2="1.5434192" sodipodi:cx="493.77145" sodipodi:r2="9.1000004" inkscape:rounded="0" inkscape:randomized="0" inkscape:flatsided="false" class="eu0" d="
    M284.63672,495.4834L269.6626,484.25l-15.62354,11.15723l6.06055-17.70605l-15.42822-11.41602l18.70313,0.29199l6.08252-18.2041
    l5.51855,17.87793l19.19141,0.16309l-15.31006,10.7666L284.63672,495.4834L284.63672,495.4834z"/>
  <path id="flag_star_1_" sodipodi:r1="24" inkscape:label="flag_star" sodipodi:cy="166.82816" sodipodi:type="star" sodipodi:arg1="0.9451007" sodipodi:sides="5" sodipodi:arg2="1.5434192" sodipodi:cx="493.77145" sodipodi:r2="9.1000004" inkscape:rounded="0" inkscape:randomized="0" inkscape:flatsided="false" class="eu0" d="
    M545.29688,343.30029l-14.97461-11.23242l-15.62305,11.15674l6.06055-17.70508l-15.42773-11.41748l18.70313,0.29248
    l6.08203-18.20459l5.51758,17.87939l19.19141,0.16309l-15.30859,10.76563L545.29688,343.30029L545.29688,343.30029z"/>
  <path id="flag_star_2_" sodipodi:r1="24" inkscape:label="flag_star" sodipodi:cy="166.82816" sodipodi:type="star" sodipodi:arg1="0.9451007" sodipodi:sides="5" sodipodi:arg2="1.5434192" sodipodi:cx="493.77145" sodipodi:r2="9.1000004" inkscape:rounded="0" inkscape:randomized="0" inkscape:flatsided="false" class="eu0" d="
    M491.44727,288.67041l-14.97266-11.23242l-15.625,11.15723l6.0625-17.70557l-15.43164-11.41748l18.70508,0.29248l6.08203-18.20459
    l5.51953,17.87939l19.19141,0.16309l-15.31055,10.76611L491.44727,288.67041L491.44727,288.67041z"/>
  <path id="flag_star_3_" sodipodi:r1="24" inkscape:label="flag_star" sodipodi:cy="166.82816" sodipodi:type="star" sodipodi:arg1="0.9451007" sodipodi:sides="5" sodipodi:arg2="1.5434192" sodipodi:cx="493.77145" sodipodi:r2="9.1000004" inkscape:rounded="0" inkscape:randomized="0" inkscape:flatsided="false" class="eu0" d="
    M415.74609,267.59961l-14.97266-11.23291l-15.62451,11.15723l6.06152-17.70557l-15.42871-11.41748l18.70264,0.29248l6.08203-18.2041
    l5.51953,17.87891l19.19141,0.16309l-15.31055,10.76611L415.74609,267.59961L415.74609,267.59961z"/>
  <path id="flag_star_5_" sodipodi:r1="24" inkscape:label="flag_star" sodipodi:cy="166.82816" sodipodi:type="star" sodipodi:arg1="0.9451007" sodipodi:sides="5" sodipodi:arg2="1.5434192" sodipodi:cx="493.77145" sodipodi:r2="9.1000004" inkscape:rounded="0" inkscape:randomized="0" inkscape:flatsided="false" class="eu0" d="
    M284.63672,342.52002l-14.97412-11.23291l-15.62354,11.15723l6.06055-17.70654l-15.42822-11.41699l18.70313,0.29297l6.08252-18.2041
    l5.51855,17.87891l19.19141,0.16211l-15.31006,10.76709L284.63672,342.52002L284.63672,342.52002z"/>
  <path id="flag_star" sodipodi:r1="24" inkscape:label="flag_star" sodipodi:cy="166.82816" sodipodi:type="star" sodipodi:arg1="0.9451007" sodipodi:sides="5" sodipodi:arg2="1.5434192" sodipodi:cx="493.77145" sodipodi:r2="9.1000004" inkscape:rounded="0" inkscape:randomized="0" inkscape:flatsided="false" class="eu0" d="
    M565.58594,419.00195l-14.97266-11.2334l-15.62305,11.15723l6.06055-17.70605l-15.42969-11.41699l18.70313,0.29248l6.08203-18.20459
    l5.51953,17.87939l19.19141,0.1626l-15.31055,10.7666L565.58594,419.00195L565.58594,419.00195z"/>
  </svg>
</template>

<script>
export default {
  name: "euFlag"
}
</script>

<style>
  .eu0{fill:#FFCC00;stroke:#E6F32E;stroke-opacity:0.20886;}
  .eu1{fill:#000099;stroke:#000000;}
</style>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 166.91553 800 466.16943">
  <g>
    <path class="uae-i-2" d="M402.79297,169.91504c-2.4834,0-4.95361,0.05322-7.41797,0.13135v152.45215h224.25488
      C587.74902,233.55078,502.70801,169.91504,402.79297,169.91504z"/>
    <path class="uae-i-1" d="M395.375,478.08203h224.19629c8.74023-24.3252,13.51367-50.54102,13.51367-77.87451
      c0-27.27197-4.75293-53.43066-13.45508-77.70898H395.375V478.08203z"/>
    <path d="M395.375,630.36914c2.46436,0.07813,4.93457,0.13086,7.41797,0.13086c99.85254,0,184.85156-63.55664,216.77832-152.41797
      H395.375V630.36914z"/>
    <path class="uae-i-0" d="M395.375,322.49854V170.04639C271.62207,173.96436,172.5,275.50391,172.5,400.20752
      s99.12207,226.24365,222.875,230.16162V478.08203V322.49854z"/>
  </g>
  </svg>
</template>

<script>
export default {
  name: "uaeIcon"
}
</script>

<style>
  .uae-i-0{fill:#FF0000;}
  .uae-i-1{fill:#FFFFFF;}
  .uae-i-2{fill:#00732F;}
</style>
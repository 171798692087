<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 166.6543 800 466.69141">
  <g id="Layer_3">
    <rect y="166.6543" class="agm-3" width="800" height="466.69141"/>
  </g>
  <g id="Layer_3_copy">
    <g>
      <path class="agm-2" d="M326.08594,378.68945c-0.11475-0.03857-0.19043-0.00195-0.23975,0.09375
        c0.0498-0.08789,0.12451-0.12207,0.23584-0.08398c0.28662,0.09766,0.60254-0.14551,0.84521-0.55322
        C326.68457,378.54688,326.37012,378.78516,326.08594,378.68945z"/>
      <path class="agm-2" d="M337.20117,341.98682c-1.21924,0.97363-4.38672,1.46729-5.93018,2.7959
        C332.82178,343.45508,335.99512,342.96289,337.20117,341.98682z"/>
      <path class="agm-2" d="M338.31641,340.64697c-0.0459,0.03271-0.09131,0.06592-0.13379,0.10059
        C338.22461,340.71094,338.27197,340.68066,338.31641,340.64697z"/>
      <path class="agm-2" d="M345.53076,331.42871c0,0-0.00488,0.00586-0.00537,0.00635c0.01318-0.00244,0.02441,0,0.03711-0.00195
        L345.53076,331.42871z"/>
      <path class="agm-2" d="M341.04932,339.11768c-0.03857,0.03418-0.0835,0.06543-0.12451,0.09814
        C340.96582,339.18311,341.01123,339.15234,341.04932,339.11768z"/>
      <path class="agm-2" d="M324.88281,347.3916c-0.74805,0-2.90479,0.21289-3.84082,1.4707
        c0.94775-1.25439,3.08936-1.45703,3.83057-1.45703c0.74268,0,3.29248,0.20313,4.87451-0.90186
        C328.16797,347.59863,325.62646,347.3916,324.88281,347.3916z"/>
      <path class="agm-2" d="M341.29688,338.84619c0.01953-0.02588,0.03467-0.05371,0.05225-0.08057
        C341.33154,338.79248,341.31641,338.82031,341.29688,338.84619z"/>
      <path class="agm-2" d="M341.48779,338.49316c0.00928-0.02441,0.01904-0.04834,0.02686-0.07373
        C341.50684,338.44434,341.49707,338.46875,341.48779,338.49316z"/>
      <path class="agm-2" d="M337.66992,341.38086c0.12646-0.22217,0.28174-0.42236,0.46631-0.59229
        C337.93457,340.95947,337.77246,341.15332,337.66992,341.38086z"/>
      <path class="agm-2" d="M340.52002,339.49219c0.07764-0.04639,0.15332-0.09375,0.22559-0.14258
        C340.67285,339.39844,340.59814,339.44629,340.52002,339.49219z"/>
      <path class="agm-2" d="M356.02148,323.125c-0.12354,0.14307-0.25195,0.28125-0.38818,0.41211
        C355.76953,323.40625,355.89697,323.26709,356.02148,323.125z"/>
      <path class="agm-2" d="M346.53857,353.16748c0.01904-0.13721,0.03662-0.27441,0.04932-0.41455
        C346.5752,352.89307,346.55762,353.03027,346.53857,353.16748z"/>
      <path class="agm-2" d="M395.19141,392.02539c0.26953,2.61475,1.09717,5.14355,2.44385,7.42041
        C396.31592,397.14648,395.49512,394.62207,395.19141,392.02539z"/>
      <path class="agm-2" d="M396.89258,404.9209c-1.0293,0.9707-2.35352,1.81738-3.39014,2.67285
        C394.54004,406.74023,395.86426,405.89258,396.89258,404.9209z"/>
      <path class="agm-2" d="M373.07275,322.18457c-0.79395,0.39893-1.69922-0.46777-3.09033-0.3042
        C371.37305,321.72363,372.27979,322.5957,373.07275,322.18457z"/>
      <path class="agm-2" d="M367.57568,322.72021c-1.86084,0.27832-3.63184-1.29297-4.8208-1.03906
        C363.9458,321.44434,365.71533,323.00684,367.57568,322.72021z"/>
      <path class="agm-2" d="M377.52637,419.6582c1.50586-0.10645,4.98535,1.25391,6.87158,1.14453
        C382.51221,420.91113,379.03223,419.55176,377.52637,419.6582z"/>
      <path class="agm-2" d="M360.72021,323.11719c-1.20068,0.03223-2.78613-1.06201-3.93848-0.60596
        C357.93359,322.06543,359.52148,323.15967,360.72021,323.11719z"/>
      <path class="agm-2" d="M366.12598,391.03369c0.41455,0.01611,0.80957,0.08447,1.1792,0.21143
        C366.93604,391.11816,366.54053,391.04932,366.12598,391.03369z"/>
      <path class="agm-2" d="M357.1333,403.76563c0,0.21094,0.00781,0.40918,0.02197,0.5957
        C357.14111,404.1748,357.1333,403.97656,357.1333,403.76563z"/>
      <path class="agm-2" d="M383.33545,413.23926c0.28174-0.30762,0.95459-0.36328,2.2334-0.03418
        C384.28809,412.87402,383.61523,412.92969,383.33545,413.23926z"/>
      <path class="agm-2" d="M346.25684,354.57813c0.10693-0.44434,0.20605-0.88477,0.27295-1.33789
        C346.46289,353.69287,346.36377,354.1333,346.25684,354.57813z"/>
      <path class="agm-2" d="M395.0542,389.81787c0-0.00244,0-0.00488,0-0.00732c-1.11719,0.95459-2.54883,1.42969-3.98486,1.35889
        C392.50391,391.2417,393.92969,390.76807,395.0542,389.81787z"/>
      <path class="agm-2" d="M381.59863,393.75c-0.01465-0.00879-0.02881-0.01758-0.04395-0.02588
        C381.57031,393.73242,381.58398,393.74121,381.59863,393.75z"/>
      <path class="agm-2" d="M254.12549,303.46973c0.46191-2.37158,3.2251-0.46973,4.14844-3.32227
        C257.35059,302.99316,254.5874,301.09814,254.12549,303.46973z"/>
      <path class="agm-2" d="M319.58057,310.26318c-0.0166-0.59668,0.1416-1.45557,0.13525-2.25439
        C319.72119,308.80859,319.56299,309.66748,319.58057,310.26318z"/>
      <path class="agm-2" d="M349.46631,335.19434c0.30713,0.0791,0.55518,0.05273,0.75439-0.0542
        c-0.19775,0.10352-0.44434,0.12793-0.74756,0.05029c-1.01709-0.26563-1.60938-1.0166-2.06689-1.77637
        C347.86084,334.17969,348.44971,334.93262,349.46631,335.19434z"/>
      <path class="agm-2" d="M324.69238,297.10205c0.38867-0.45508,0.77832-0.88672,1.16113-1.2793
        c1.21729-1.25244,1.78516-2.6543,2.02002-4.12207c-0.23633,1.46436-0.80518,2.86377-2.02002,4.11523
        C325.47119,296.21094,325.08105,296.64453,324.69238,297.10205z"/>
      <path class="agm-2" d="M319.46338,306.5127c-0.00439-0.06543,0.00635-0.14453,0.00928-0.21631
        C319.46973,306.36816,319.45898,306.44727,319.46338,306.5127z"/>
      <path class="agm-2" d="M340.45654,339.52881c-0.56006,0.31787-1.23096,0.57422-1.79004,0.89453
        c0.07373-0.03906,0.14453-0.08203,0.22266-0.11377l-0.00098-0.00098C339.39844,340.04053,339.96875,339.80762,340.45654,339.52881
        z"/>
      <path class="agm-2" d="M350.99072,324.86865c0.11035,0.11572,0.25537,0.19531,0.41602,0.25537
        c-0.15869-0.06006-0.29834-0.14404-0.40918-0.2583c-0.13916-0.14355-0.28564-0.15967-0.43018-0.09863
        C350.70996,324.70996,350.85352,324.72754,350.99072,324.86865z"/>
      <path class="agm-2" d="M355.02734,324.04883c0.10449-0.07861,0.20605-0.16016,0.30566-0.24512
        C355.23291,323.88867,355.13135,323.9707,355.02734,324.04883z"/>
      <path class="agm-2" d="M355.38965,326.2168c-0.41895-0.50293-0.88281-1.02051-1.3374-1.54492
        c-0.20605,0.10596-0.41162,0.19434-0.61572,0.27148c0.20459-0.07764,0.41016-0.16357,0.61572-0.26904
        C354.50537,325.19629,354.96973,325.71387,355.38965,326.2168z"/>
      <path class="agm-2" d="M255.96826,315.34277c0.38721,1.08838,0.73047,2.18994,1.03662,3.30078
        c-0.30518-1.11084-0.64697-2.2124-1.03369-3.30078c-1.43848-3.78564-2.07129-7.82813-1.8457-11.87305
        C253.8999,307.51465,254.52588,311.55713,255.96826,315.34277z"/>
      <path class="agm-2" d="M258.27393,300.14746c0.91943-2.84961-7.83496-2.85352-5.07422-6.64746
        C250.43604,297.29785,259.19336,297.29785,258.27393,300.14746z"/>
      <path class="agm-2" d="M316.55371,274.4541h-0.00049c0.01416,0.00293,0.02637,0.00488,0.03809,0.00732L316.55371,274.4541z"/>
      <path class="agm-2" d="M313.59375,284.83789c-0.01563-0.00781-0.03223-0.01318-0.04785-0.021
        C313.56152,284.82471,313.57813,284.83008,313.59375,284.83789z"/>
      <path class="agm-2" d="M314.20215,285.07227c-0.08789-0.02734-0.17627-0.05322-0.26465-0.08691
        C314.02588,285.01904,314.11426,285.04492,314.20215,285.07227z"/>
      <path class="agm-2" d="M351.96045,325.22998c-0.19336-0.0083-0.37158-0.03955-0.52979-0.09619
        C351.58936,325.18994,351.76611,325.22266,351.96045,325.22998z"/>
      <path class="agm-2" d="M350.56689,361.81055c1.31055-1.01563,2.46045-1.43848,3.40527-1.43799
        C353.02734,360.37109,351.87793,360.79346,350.56689,361.81055z"/>
      <path class="agm-2" d="M367.06201,338.39209c0.36377-0.25,0.84961-0.50098,1.38184-0.68164
        c-0.53271,0.17969-1.01904,0.43066-1.38379,0.68164c-1.4668,1.00439-6.33887-1.00439-6.33887-3.51563
        C360.72119,337.3916,365.59717,339.39648,367.06201,338.39209z"/>
      <path class="agm-2" d="M378.79053,392.44629l-0.97559-0.8623c1.00342,1.24365,2.50537,1.59814,3.44092,1.99902
        c-0.66211-0.29053-1.60449-0.55762-2.46631-1.13574C378.79004,392.44678,378.79004,392.44678,378.79053,392.44629z"/>
      <path class="agm-2" d="M361.06982,360.11865c0.4126-1.06055,2.51855-1.72119,4.43213-1.56006
        C363.58789,358.39355,361.48096,359.05322,361.06982,360.11865z"/>
      <path class="agm-2" d="M345.05225,348.52832c0.52686,0.12891,0.97803,0.51172,1.25,1.44678
        C346.03027,349.03955,345.5791,348.65674,345.05225,348.52832z"/>
      <path class="agm-2" d="M340.39209,341.85645c0.65527,1.00879-0.33203,3.71777,0.65527,6.08203
        C340.06689,345.57422,341.0542,342.86523,340.39209,341.85645z"/>
      <path class="agm-2" d="M343.67139,364.5166c0.16553,0.7627,0.51611,1.10059,0.98975,1.14697
        C344.18848,365.61572,343.83789,365.27734,343.67139,364.5166z"/>
      <path class="agm-2" d="M381.88623,393.95898c0.00684,0.00684,0.01465,0.01318,0.02148,0.01953
        C381.90088,393.97217,381.89307,393.96582,381.88623,393.95898z"/>
      <path class="agm-2" d="M385.81152,369.72412c-1.6748-2.03662-3.95508-3.99951-4.62646-5.73047
        c-0.58252-1.49609-1.84912-2.98682-2.78174-4.37256c0.93213,1.38916,2.19873,2.88477,2.78174,4.37939
        C381.85645,365.72656,384.13574,367.68701,385.81152,369.72412z"/>
      <path class="agm-2" d="M394.82275,380.42871c-0.25879-0.65039-0.5835-1.27783-0.97314-1.87207
        c-0.54834-0.56152-1.44043-1.12549-2.39209-1.77002c0.95166,0.64551,1.84326,1.21143,2.39209,1.77588
        C394.23926,379.15527,394.56396,379.78076,394.82275,380.42871z"/>
      <path class="agm-2" d="M382.07422,394.25195c0.00244,0.00732,0.00342,0.01563,0.00537,0.02344
        C382.07764,394.26758,382.07666,394.25928,382.07422,394.25195z"/>
      <path class="agm-2" d="M382.0874,395.0459c0.26758,0.67822,2.01318,0.02588,2.26172-1.96191
        C384.10059,395.06689,382.35791,395.72266,382.0874,395.0459z"/>
      <path class="agm-2" d="M368.32178,391.44238c3.13037,0.29639,8.02148-1.6748,9.49316,0.1416
        C376.33984,389.7627,371.44873,391.73682,368.32178,391.44238z"/>
      <path class="agm-2" d="M85.04736,191.38477c0.02344,0.00684,0.04736,0.01221,0.0708,0.01953
        C85.09473,191.39697,85.0708,191.3916,85.04736,191.38477z"/>
      <path class="agm-2" d="M65.56055,173.896c-0.03613-0.04102-0.08203-0.08154-0.12109-0.12207
        C65.47852,173.81445,65.52441,173.85498,65.56055,173.896z"/>
      <path class="agm-2" d="M60.0415,170.14893c-0.02832-0.01807-0.05908-0.03711-0.08691-0.05518
        C59.98242,170.11182,60.01318,170.13086,60.0415,170.14893z"/>
      <path class="agm-2" d="M253.19971,293.5c2.76855-3.80127,2.76465,0,6.45508,0C255.96826,293.5,255.97119,289.69336,253.19971,293.5z
        "/>
      <path class="agm-2" d="M49.76172,210.20068c0.04053-0.00781,0.07959-0.01074,0.11914-0.01563
        C49.84082,210.1875,49.80176,210.19531,49.76172,210.20068z"/>
      <path class="agm-2" d="M59.75293,169.95703c-0.10791-0.07227-0.21875-0.14502-0.31934-0.21631
        C59.53418,169.81201,59.64502,169.88477,59.75293,169.95703z"/>
      <path class="agm-2" d="M65.72363,174.10693c0.04785,0.0625,0.10352,0.125,0.14404,0.1875
        c0.59424,0.60938,1.2251,1.17773,1.88477,1.70703c-0.66211-0.53076-1.29395-1.10156-1.88867-1.71289
        C65.82422,174.22803,65.76953,174.16748,65.72363,174.10693z"/>
      <path class="agm-2" d="M67.81006,176.05029c2.05371,1.63428,4.40234,2.86914,6.92676,3.63135
        c0.79492,0.29297,1.57617,0.79102,2.3291,1.36914c-0.75439-0.58057-1.5376-1.08105-2.33496-1.37598
        C72.20703,178.91357,69.85938,177.68115,67.81006,176.05029z"/>
      <path class="agm-2" d="M82.69141,185.06152c-0.56787,0.07568-1.24707-0.30664-2.00977-0.9043
        c0.76416,0.59961,1.44434,0.98389,2.01318,0.90918c2.39014,0.38428,4.69922,1.17578,6.81982,2.34668l-0.00049-0.00439
        C87.39258,186.23828,85.07568,185.44385,82.69141,185.06152z"/>
      <path class="agm-2" d="M98.61182,195.1543c0.51025,0.27637,1.04004,0.63086,1.56445,0.979
        C99.70117,195.73438,99.17236,195.41016,98.61182,195.1543z"/>
      <path class="agm-2" d="M49.25439,210.36377c0.14453-0.06299,0.2876-0.11279,0.42676-0.14502
        C49.5332,210.24707,49.3916,210.2959,49.25439,210.36377z"/>
      <path class="agm-2" d="M106.86914,167.6377c-1.04785,0.65186-0.20313,3.21387-1.66748,4.16406
        C106.67188,170.85645,105.8374,168.29785,106.86914,167.6377z"/>
      <path class="agm-2" d="M59.34424,169.67334c-0.12695-0.09229-0.25195-0.18457-0.36377-0.2749
        C59.0918,169.48877,59.21729,169.58105,59.34424,169.67334z"/>
      <path class="agm-2" d="M58.83154,169.26758c-0.07715-0.06689-0.15918-0.13428-0.22656-0.2002
        C58.67236,169.1333,58.75439,169.20068,58.83154,169.26758z"/>
      <path class="agm-2" d="M49.03809,210.46631c-0.01074,0.00635-0.01953,0.01465-0.03027,0.021
        c0.01025-0.00586,0.02051-0.01221,0.03125-0.01758C49.03857,210.46826,49.03857,210.46729,49.03809,210.46631z"/>
      <path class="agm-2" d="M44.61328,261.22559c-0.0127-0.02148-0.02441-0.04248-0.03613-0.06348
        C44.58887,261.18311,44.60059,261.2041,44.61328,261.22559z"/>
      <path class="agm-2" d="M354.45166,324.43652c0.08057-0.04834,0.1626-0.09473,0.24072-0.14648
        C354.61328,324.34277,354.53223,324.3877,354.45166,324.43652z"/>
      <path class="agm-2" d="M44.42725,260.85498c-0.00977-0.02441-0.021-0.04883-0.02979-0.07275
        C44.40674,260.80615,44.41748,260.83057,44.42725,260.85498z"/>
      <path class="agm-2" d="M527.39746,167.32666c0.06055-0.05371,0.11035-0.1167,0.17285-0.16797
        c0.36426-0.15869,0.71777-0.34033,1.08008-0.50439h-0.00195c-0.3623,0.16406-0.71582,0.3457-1.08008,0.50439
        C527.50684,167.20996,527.45703,167.27295,527.39746,167.32666z"/>
      <path class="agm-2" d="M542.60742,190.36328c1.7832-1.47168,3.83789-2.80273,4.53516-3.51514
        C546.44531,187.56055,544.38086,188.8916,542.60742,190.36328z"/>
      <path class="agm-2" d="M44.86719,261.60449c-0.00928-0.01221-0.01709-0.02393-0.02637-0.03613
        C44.8501,261.58057,44.85791,261.59229,44.86719,261.60449z"/>
      <path class="agm-2" d="M33.52441,192.30762c0.03076,0.08252,0.04785,0.16699,0.04785,0.25293
        c0.03223,0.21045,0.08447,0.41357,0.15332,0.60742c-0.06592-0.18945-0.11572-0.38672-0.14648-0.5918
        C33.5791,192.48438,33.55908,192.39551,33.52441,192.30762z"/>
      <path class="agm-2" d="M47.63086,200.56494c0.0249,0.06152,0.03955,0.12646,0.03955,0.1958
        c0.03418,1.26807,0.14063,2.53174,0.28564,3.79053c-0.14307-1.25342-0.24805-2.51123-0.27979-3.77441
        C47.67627,200.70117,47.66016,200.63086,47.63086,200.56494z"/>
      <path class="agm-2" d="M44.40186,243.74658c-0.0874-1.14355,0.01953-2.87988-0.17529-4.69971
        C44.41943,240.86816,44.31201,242.60498,44.40186,243.74658z"/>
      <path class="agm-2" d="M47.86328,211.59424c0.0166-0.02686,0.03467-0.05225,0.05176-0.07861
        C47.89746,211.5415,47.88037,211.56787,47.86328,211.59424z"/>
      <path class="agm-2" d="M44.32031,260.49072c-0.00391-0.02344-0.01221-0.04736-0.01465-0.0708
        C44.30859,260.44336,44.31592,260.46729,44.32031,260.49072z"/>
      <path class="agm-2" d="M45.7666,262.62598c-0.07568-0.07715-0.15137-0.1543-0.22363-0.23047
        C45.61475,262.47168,45.69141,262.54883,45.7666,262.62598z"/>
      <path class="agm-2" d="M322.78125,275.32324c0.06982,0.18115,0.12158,0.37158,0.16016,0.56787
        C322.90332,275.69775,322.85254,275.50684,322.78125,275.32324z"/>
      <path class="agm-2" d="M322.50146,274.75879c-0.00342-0.00586-0.00879-0.01025-0.0127-0.01611
        c0.0459,0.07227,0.07861,0.15381,0.11865,0.22998C322.57031,274.90234,322.54346,274.82666,322.50146,274.75879z"/>
      <path class="agm-2" d="M319.07227,273.20898c0.11084-0.01855,0.21973-0.01709,0.32959-0.0249
        C319.2915,273.19141,319.18164,273.19092,319.07227,273.20898z"/>
      <path class="agm-2" d="M322.07861,274.21289c0.01709,0.01855,0.03027,0.04053,0.04736,0.05908
        C322.10889,274.25391,322.0957,274.23096,322.07861,274.21289z"/>
      <path class="agm-2" d="M312.26807,272.28613c0.02295,0.04053,0.05176,0.07861,0.07813,0.11768
        C312.32031,272.36426,312.29053,272.32666,312.26807,272.28613z"/>
      <path class="agm-2" d="M318.4502,273.37256c0.03662-0.01367,0.07617-0.01855,0.11328-0.03076
        C318.52588,273.354,318.4873,273.3584,318.4502,273.37256z"/>
      <path class="agm-2" d="M312.4873,272.60254c0.01416,0.01709,0.03027,0.0332,0.04492,0.05029
        C312.51758,272.63574,312.50146,272.61963,312.4873,272.60254z"/>
      <path class="agm-2" d="M352.65137,325.17285c0.19922-0.03809,0.40576-0.09668,0.61572-0.16846
        C353.05713,325.07568,352.84961,325.13525,352.65137,325.17285z"/>
      <path class="agm-2" d="M352.00879,325.22949c0.17969,0.00342,0.37061-0.01172,0.57129-0.04639
        C352.37988,325.2168,352.18799,325.2334,352.00879,325.22949z"/>
      <path class="agm-2" d="M347.27197,333.18359c0.03906,0.06934,0.0791,0.1377,0.11963,0.20654
        C347.35059,333.32129,347.31152,333.25244,347.27197,333.18359z"/>
      <path class="agm-2" d="M319.84961,304.72607c-0.02002,0.05371-0.0459,0.11182-0.06494,0.16406
        C319.80371,304.83789,319.82959,304.77979,319.84961,304.72607z"/>
      <path class="agm-2" d="M319.75488,304.98535c-0.13477,0.3916-0.23096,0.75293-0.27148,1.06543
        C319.52441,305.73828,319.62061,305.37695,319.75488,304.98535z"/>
      <path class="agm-2" d="M280.75635,279.17822c0.47021-0.18311,0.94971-0.34082,1.43408-0.48291
        C281.69922,278.81836,281.22168,278.97998,280.75635,279.17822z"/>
      <path class="agm-2" d="M151.5166,195.43164c0.21582,0.07617,0.42676,0.16943,0.63672,0.26416
        C151.94482,195.59766,151.73291,195.51025,151.5166,195.43164z"/>
      <path class="agm-2" d="M120.24512,167.19678c-0.2334,0.00049-0.4668,0.00391-0.70068-0.00342
        c0.00049-0.00049,0.00098-0.00146,0.00098-0.00195c-0.36182,0.01611-0.72461,0.01709-1.0874,0.00879
        C119.05469,167.22021,119.65039,167.21826,120.24512,167.19678z"/>
      <path class="agm-2" d="M149.47266,194.99561c0.6626,0.05127,1.31543,0.18555,1.9458,0.39893
        C150.79248,195.17725,150.13867,195.04443,149.47266,194.99561z"/>
      <path class="agm-2" d="M117.17627,167.12988c0.20752,0.01709,0.41553,0.02197,0.62305,0.03418
        c-0.20752-0.01318-0.41602-0.01953-0.62305-0.04102c-2.09717-0.31445-5.66553,3.03564-5.4624,4.62793
        C111.51855,170.1582,115.08008,166.8208,117.17627,167.12988z"/>
      <path class="agm-2" d="M102.77344,197.31055c0.12744-0.00488,0.24902-0.02979,0.36377-0.07422
        C103.02197,197.28076,102.90088,197.30615,102.77344,197.31055z"/>
      <path class="agm-2" d="M103.17822,197.22168c0.11914-0.05273,0.23096-0.12793,0.33398-0.23291
        C103.40918,197.09326,103.29736,197.16943,103.17822,197.22168z"/>
      <path class="agm-2" d="M148.41553,194.9834c0.33545-0.01904,0.67041-0.01514,1.00293,0.0083
        C149.08594,194.96973,148.75146,194.96143,148.41553,194.9834z"/>
      <path class="agm-2" d="M254.49951,276.99512c0.00098-0.00146,0.00244-0.00293,0.00342-0.00391
        C254.50195,276.99219,254.50049,276.99365,254.49951,276.99512z"/>
      <path class="agm-2" d="M156.13672,196.27734h-0.00195c0.1665,0.13428,0.33691,0.26904,0.50928,0.40381
        C156.47217,196.5459,156.30225,196.41113,156.13672,196.27734z"/>
      <path class="agm-2" d="M129.38574,172.79199c0.08154,0.08105,0.1626,0.16504,0.23828,0.27441
        c0.0957,0.1377,0.19873,0.26025,0.30762,0.37012c-0.10645-0.10791-0.20703-0.22852-0.30078-0.36328
        C129.55273,172.96143,129.46973,172.87451,129.38574,172.79199z"/>
      <polygon class="agm-2" points="156.35498,196.23584 156.18018,196.26904 156.35645,196.24609 		"/>
      <path class="agm-2" d="M154.41943,184.65771c0.22607,0.30664,0.37207,0.61816,0.40967,0.92969
        c0.14307,1.16748,0.48389,3.55518,0.80762,5.77588c-0.32178-2.21777-0.65918-4.59912-0.79736-5.76416
        C154.80078,185.28369,154.65088,184.96826,154.41943,184.65771z"/>
      <path class="agm-2" d="M115.87598,308.26563c-0.41309,0.10303-0.7959,0.26025-1.27441,0.38818
        C115.08301,308.52783,115.46533,308.37061,115.87598,308.26563z"/>
      <path class="agm-2" d="M164.22461,205.68359c-0.67725-1.03711-1.06445-2.26416-1.07617-3.55273
        c0-0.23242-0.25781-0.56738-0.68945-0.97607c0.42383,0.40381,0.67578,0.73438,0.67578,0.96387
        C163.15039,203.41309,163.54199,204.64453,164.22461,205.68359z"/>
      <path class="agm-2" d="M154.46729,196.59473l-0.68115,0.12988c-0.40527-0.3374-0.84375-0.62451-1.30371-0.86768l1.30371,0.87354
        L154.46729,196.59473z"/>
      <path class="agm-2" d="M170.30225,216.26611c0.00732-0.00195,0.01416-0.00439,0.02148-0.00684
        c0.91895-0.47168,2.18262-3.4458,2.74609-4.87207c-0.96631-0.6167-1.95313-1.19922-2.9585-1.74756
        c1,0.54834,1.98145,1.12988,2.94531,1.74512C172.49072,212.79492,171.22461,215.78906,170.30225,216.26611z"/>
      <path class="agm-2" d="M147.22314,221.59424c1.5166,0.93799,1.21631,7.50684,1.21631,7.50684s1.82178,8.14209,4.5542,7.51709
        c2.55762-0.58643,5.65234,2.39453,4.03174,3.28809c1.65918-0.88086-1.43701-3.87988-4.00781-3.29688
        c-2.73682,0.61816-4.56104-7.51709-4.56104-7.51709s0.31104-6.56787-1.21631-7.50586
        c-1.22803-0.7627-0.06299-3.16748,2.19922-4.05518C147.16113,218.40918,145.98682,220.8291,147.22314,221.59424z"/>
      <path class="agm-2" d="M162.28174,219.62598c0.16602-0.05908,0.31787-0.13623,0.45361-0.23584
        c1.20752-0.64111,2.4458-1.22314,3.70996-1.74463c-1.27197,0.52393-2.51758,1.10889-3.7334,1.75391
        C162.58301,219.49414,162.43848,219.56787,162.28174,219.62598z"/>
      <path class="agm-2" d="M94.06055,248.34814c0.26172,0.02246,0.52002,0.04688,0.77539,0.0708
        c-0.25391-0.02441-0.51074-0.04883-0.77051-0.07129C94.06396,248.34766,94.0625,248.34814,94.06055,248.34814z"/>
      <path class="agm-2" d="M57.68018,264.05713c-1.95215-0.35547-3.91748-0.64502-5.89111-0.86816
        C53.76123,263.41309,55.72656,263.70215,57.68018,264.05713z"/>
      <path class="agm-2" d="M116.5791,231.38086c-0.81934,0.38135-1.78027,0.66064-2.75098,0.77002
        C114.80029,232.04248,115.76074,231.7627,116.5791,231.38086z"/>
      <path class="agm-2" d="M127.94873,207.33496c0.34131,0.35059,0.57373,0.67822,0.75439,0.99805
        C128.52295,208.01318,128.29004,207.68604,127.94873,207.33496z"/>
      <path class="agm-2" d="M133.55908,272.61328c-0.26465,2.45557-2.15088,5.39063-3.63525,5.88818
        c1.4873-0.47021,3.39307-3.42432,3.66016-5.89697c0.11133-1.05518,0.90479-1.75928,1.90234-2.27686
        C134.47852,270.84668,133.67285,271.55225,133.55908,272.61328z"/>
      <path class="agm-2" d="M168.34863,216.91309c-0.16113,0.05762-0.31787,0.12451-0.47803,0.18359
        C168.03076,217.0376,168.1875,216.9707,168.34863,216.91309z"/>
      <path class="agm-2" d="M112.60791,285.75244c0,2.50586,5.1665,2.50586,4.86914,5.63672
        c0.00732,3.04492,1.20654,5.96094,3.34131,8.13623c1.50732,1.56299,3.94287,5.3208,2.72656,7.2002
        c-0.45117,0.69824-0.9873,1.17871-1.62256,1.47559c0.64453-0.29639,1.18701-0.7793,1.64355-1.48193
        c1.2124-1.87988-1.21338-5.6377-2.72705-7.20068c-2.13135-2.17334-3.32813-5.09619-3.3418-8.14209
        c0.30518-3.12695-4.86133-3.12695-4.86133-5.63281c0-2.50146,4.86133-6.57031,5.77051-7.51025
        c0.05273-0.05469,0.11768-0.0918,0.18994-0.11865c-0.08008,0.02686-0.14941,0.06836-0.20654,0.12744
        C117.47705,279.18213,112.60791,283.25146,112.60791,285.75244z"/>
      <path class="agm-2" d="M178.12109,211.84863c0.00146-0.00293,0.00391-0.00488,0.00537-0.00781
        c-0.12207-0.13477-0.25732-0.25391-0.38525-0.38135c0.6958,0.7251,1.26123,1.56787,1.6377,2.51172
        C179.11865,213.18262,178.68945,212.45508,178.12109,211.84863z"/>
      <path class="agm-2" d="M124.14648,278.86719c-0.1123,0.23242-0.27197,0.38281-0.46582,0.4707
        c0.20313-0.08643,0.37012-0.23779,0.48633-0.47852c0.22412-0.46436,0.56104-0.73633,0.96338-0.87891
        C124.71875,278.12109,124.37451,278.39453,124.14648,278.86719z"/>
      <path class="agm-2" d="M142.97607,260.09229c-0.83008,2.87842-1.84131,5.69922-3.03711,8.44775
        c-0.16943,0.17432-0.48584,0.32764-0.88965,0.479c0.41553-0.15381,0.74072-0.30908,0.91357-0.48682
        c1.18848-2.74951,2.20654-5.56934,3.03369-8.44775c0.11377-0.23389,0.34424-0.3916,0.64355-0.51563
        C143.33105,259.69434,143.0918,259.85303,142.97607,260.09229z"/>
      <path class="agm-2" d="M107.48291,244.12842c-0.04248-0.11768-0.11279-0.21777-0.20605-0.30127
        c0.0918,0.08398,0.16064,0.18359,0.20313,0.30127c0.24707,0.68213,0.76416,1.01709,1.33252,0.9502
        C108.24561,245.14063,107.72998,244.80518,107.48291,244.12842z"/>
      <path class="agm-2" d="M147.52734,256.33203c0,1.71289-0.97412,2.34229-2.02832,2.69629
        c1.06348-0.35254,2.05225-0.97852,2.05225-2.7041c0-2.35742,0.85254-3.39746,1.97705-3.91699
        C148.39453,252.92383,147.52734,253.95801,147.52734,256.33203z"/>
      <path class="agm-2" d="M152.68994,246.00537c-0.30371,2.50391,0.30127,5.61865-1.5127,5.94043
        c0.00635-0.00098,0.01318-0.00244,0.02002-0.00342c1.82031-0.30811,1.2124-3.43896,1.52002-5.94629
        c0.02002-2.58398,1.56641-4.90869,3.93115-5.94482c-0.00586,0.00146-0.00977,0.00439-0.01563,0.00586
        C154.26465,241.09229,152.7207,243.41895,152.68994,246.00537z"/>
      <path class="agm-2" d="M454.72656,473.35645c0.00098,0,0.00195,0,0.00391,0c-0.06934-0.01758-0.13281-0.02344-0.19922-0.0332
        C454.5957,473.33301,454.6582,473.33887,454.72656,473.35645z"/>
      <path class="agm-2" d="M265.37842,299.46094c1.05811,1.34326,3.03223,2.10889,3.03223,2.10889
        S266.43799,300.80176,265.37842,299.46094z"/>
      <path class="agm-2" d="M47.35986,214.64404c-0.08789,2.7876-2.77051,4.51709-3.05225,5.68408
        c-0.23096,0.9375-1.31689,3.29492-1.32568,5.20605c0.01563-1.90625,1.09766-4.25146,1.32568-5.18994
        C44.58984,219.17041,47.27832,217.44189,47.35986,214.64404z"/>
      <path class="agm-2" d="M52.31152,249.48975c0.02246,3.49902-7.72217,8.82324-8.00391,10.58545
        c-0.00098,0.00684,0.00098,0.01367,0,0.02051c0-0.00146,0-0.00293,0-0.00439
        C44.58984,258.33154,52.35352,252.98584,52.31152,249.48975z"/>
      <path class="agm-2" d="M144.22852,198.22607c0.11914,0.00488,0.23779-0.00098,0.35693,0.00684
        c0.3584,0.07959,0.71924,0.13428,1.07959,0.16504c-0.35693-0.03125-0.71436-0.08594-1.06982-0.16504
        C144.47266,198.2251,144.35107,198.23096,144.22852,198.22607z"/>
      <path class="agm-2" d="M45.87354,262.73486c0.64404,0.64893,1.38428,1.32617,2.04102,2.01904
        C47.30127,264.02002,46.62012,263.34277,45.87354,262.73486z"/>
      <polygon class="agm-2" points="351.48828,460.07617 351.479,460.08008 351.479,460.08008 		"/>
      <path class="agm-2" d="M451.5293,492.1084c0.45703,4.21973-2.27148,2.34375-6.82227,4.6875
        c-4.54688,2.34375-2.73242-1.40039-7.27344-1.40039c-4.54883,0-5.01172,2.33301-7.73633,3.27637
        c-2.72852,0.93945-8.18945-0.46582-10.00977,0.93945c-1.81445,1.4082-4.54102-3.2832-6.36523-3.2832
        c-0.5166,0-0.99902-0.80664-1.42285-1.95703c0.42383,1.15625,0.90527,1.9668,1.4248,1.9668
        c1.82422,0,4.55273,4.68359,6.37305,3.28125c1.81836-1.40918,7.2793-0.00781,10.00391-0.94336
        c2.7207-0.93262,3.18359-3.27637,7.73633-3.27637c4.54102,0,2.72266,3.75391,7.27344,1.40234
        c4.55078-2.3457,7.27148-0.46387,6.82422-4.67871c-0.42676-3.96094,2.34277-4.21484,2.3125-6.93359
        C453.87402,487.9082,451.09961,488.15625,451.5293,492.1084z"/>
      <path class="agm-2" d="M195.70361,214.24854c-0.68066,1.93262-5.45361,8.96436-7.67041,9.49219
        c-1.0752,0.21387-2.16797,0.32617-3.26318,0.3457c-0.21094,1.15186-0.479,2.21484-0.479,2.81592
        c0,0.29395,0.09668,0.62109,0.26367,0.97705c-0.1626-0.34961-0.25684-0.67188-0.25684-0.96143l0.47559-2.82568
        c1.10352-0.01709,2.19678-0.12988,3.27637-0.3457c2.2207-0.52588,6.99023-7.55859,7.67773-9.49121
        c0.59473-1.69385-4.42725-6.08252-7.63525-7.26611C191.30371,208.18213,196.30371,212.55713,195.70361,214.24854z"/>
      <path class="agm-2" d="M147.27051,169.771c0.04199,1.4126-0.39795,2.79541-1.25049,3.92285
        c0.00146,0,0.00244-0.00049,0.00391-0.00049l1.21289,6.88477l0.00195,0.00049l-1.21094-6.87451
        c0.85742-1.12598,1.29443-2.51172,1.25391-3.92725c0-2.10986,1.70166-1.58105,3.40625-2.10986
        c0.58936-0.18164,1.11426-0.57715,1.60889-1.0127h-0.01172c-0.49268,0.43262-1.01563,0.82617-1.60107,1.00684
        C148.97656,168.19092,147.27051,167.66113,147.27051,169.771z"/>
      <path class="agm-2" d="M43.70117,228.21729c0.10254,1.47168-0.90332,3.41064-0.76514,5.55615
        C42.80225,231.62451,43.81055,229.68555,43.70117,228.21729z"/>
      <path class="agm-2" d="M129.01855,190.29199c0.62598,0.29297,0.96387,0.729,1.1123,1.25781
        c-0.14648-0.53271-0.48389-0.97217-1.1123-1.2666c-1.04199-0.48926-1.46289-1.28418-1.6333-2.15527
        C127.55322,189.00244,127.97266,189.80078,129.01855,190.29199z"/>
      <path class="agm-2" d="M103.82031,196.55029c1.21338-2.50488,7.896,0,7.896-2.19189c0-2.19238,7.28418-1.56543,7.28418-3.75342
        c0-2.19189,4.25146-7.19873,6.98145-6.88867c0.51074,0.05811,0.81982,0.26953,1.00732,0.58398
        c-0.18604-0.31934-0.49463-0.53369-1.00732-0.59229c-2.72021-0.31055-6.98145,4.69824-6.98145,6.88818
        c0,2.18799-7.28418,1.56006-7.28418,3.75293c0,2.19189-6.68262-0.31201-7.896,2.19189
        c-0.08789,0.18018-0.18652,0.32373-0.29541,0.43701C103.63281,196.86523,103.7334,196.72949,103.82031,196.55029z"/>
      <path class="agm-2" d="M133.06396,199.83154l3.99463-1.39258c1.99756-1.81787,4.4707-4.11133,6.29688-5.18652
        c-1.82715,1.07422-4.30029,3.36572-6.29688,5.18164l-4.10742,1.42334c-1.62549,0.48828-2.94189,0.63184-3.62842-0.18604
        c-0.22705-0.27246-0.3501-0.59863-0.39893-0.96289c0.04785,0.36768,0.1709,0.69629,0.39893,0.9707
        C130.02686,200.51709,131.38672,200.34375,133.06396,199.83154z"/>
      <path class="agm-2" d="M102.73682,197.31104c-0.71631,0.00439-1.60986-0.55469-2.52832-1.16113
        c-0.41699-3.11914,0.77979-6.24023,3.18164-8.27637c2.04248-1.63867-1.3667-4.45557-0.91211-7.03369
        c0.45068-2.57227-5.23096-3.98193-4.77734-6.0918c0.11377-0.52686,0.49658-0.90723,1.03906-1.19629
        c-0.5459,0.28906-0.93213,0.67041-1.0459,1.19922c-0.44678,2.10791,5.23486,3.5127,4.77734,6.0957
        c-0.45459,2.5791,2.9585,5.3916,0.91211,7.03174c-2.39844,2.03076-3.59375,5.15381-3.17773,8.27393
        C101.12598,196.7627,102.01953,197.31641,102.73682,197.31104z"/>
      <path class="agm-2" d="M47.73877,211.81396c-0.36914,0.67139-0.52588,1.45557-0.39795,2.24707
        C47.25586,213.15137,47.43066,212.40479,47.73877,211.81396z"/>
      <path class="agm-2" d="M97.18311,194.67725c-0.0166-0.00342-0.03223-0.00977-0.04883-0.01318
        c-0.6626,0-1.26611-0.30225-1.86475-0.69043c0.60156,0.39258,1.20703,0.69873,1.87158,0.69873
        C97.15479,194.67236,97.16943,194.67676,97.18311,194.67725z"/>
      <path class="agm-2" d="M62.82715,209.38135c0.20703,2.31787-2.61572,3.63867-4.66895,3.58838
        C60.21143,213.02197,63.03418,211.70361,62.82715,209.38135z"/>
      <path class="agm-2" d="M90.76123,192.479c0.854-0.29346,1.58936-0.18994,2.26221,0.0918
        c-0.67383-0.28369-1.40967-0.39063-2.26514-0.09766l-0.65674,0.15723l0.00098,0.00928
        c-1.78125,0.34277-3.0498-0.49219-4.41846-1.03271C87.22021,192.21924,88.61865,193.21582,90.76123,192.479z"/>
      <path class="agm-2" d="M0,337.58105v0.00488c0.40771-1.1748,0.78516-2.35986,1.1377-3.55176
        C0.78467,335.22461,0.40723,336.40771,0,337.58105z"/>
      <path class="agm-2" d="M252.22119,284.71533c0.04639,0.40918,0.07764,0.81055,0.07764,1.19629
        C252.29883,285.52588,252.26758,285.12451,252.22119,284.71533z"/>
      <path class="agm-2" d="M257.17041,280.74121c0.45898-1.87695-0.45752-4.28711-1.45654-4.5752
        C256.71143,276.46094,257.62354,278.86426,257.17041,280.74121z"/>
      <path class="agm-2" d="M259.59619,286.06348c1.21631-0.31299,2.72998,1.87695,5.1626,1.25195
        c2.42969-0.625,3.95703,1.56494,7.28418,0.625c3.33789-0.93799,6.37598,1.25195,7.59619-0.93799
        c0.68262-1.23584,0.30713-3.26221-0.15137-4.79736c0.45703,1.53418,0.8291,3.5542,0.14746,4.78564
        c-1.21631,2.19189-4.25439,0-7.59229,0.93994c-3.33789,0.93604-4.86816-1.25391-7.28711-0.625
        c-2.42676,0.625-3.94727-1.56738-5.16357-1.25293c-0.65576,0.16699-1.46875-0.66113-2.00586-1.84375
        c0.00342-0.00488,0.00586-0.01025,0.00879-0.01514c-0.50732-1.07715-0.65479-2.28809-0.4248-3.45264
        C256.55859,283.24219,258.38281,286.37451,259.59619,286.06348z"/>
      <path class="agm-2" d="M238.04004,289.82129c-0.11523-0.09863-0.25537-0.19482-0.41602-0.28906
        C237.78467,289.62646,237.9248,289.72266,238.04004,289.82129z"/>
      <path class="agm-2" d="M228.16211,286.03711c-0.01172-0.01416-0.01221-0.02979-0.02246-0.04443
        C228.1499,286.00732,228.15039,286.02295,228.16211,286.03711z"/>
      <path class="agm-2" d="M195.83496,262.52783c0.02783-0.04883,0.05762-0.09717,0.08838-0.14551
        C195.89258,262.43066,195.86279,262.479,195.83496,262.52783z"/>
      <path class="agm-2" d="M195.67236,262.85889c0.01904-0.04688,0.04248-0.09375,0.06494-0.14063
        C195.71484,262.76514,195.69141,262.81201,195.67236,262.85889z"/>
      <path class="agm-2" d="M196.04688,262.19873c0.03467-0.04883,0.07031-0.09814,0.10742-0.14648
        C196.11719,262.10059,196.08154,262.1499,196.04688,262.19873z"/>
      <path class="agm-2" d="M662.57031,180.14941c-2.96484,2.81201-8.875,5.15576-7.05664,8.67383
        c1.25391,4.12842,0.59375,8.60645-1.81836,12.18945c-1.8125,2.81299,0.6875,8.20605-2.04688,11.24902
        c-2.72656,3.0459-7.04102,3.75-7.27539,1.1709c-0.22656-2.58057-1.82422,4.45508-4.54883,4.68701
        c-2.73047,0.23389-3.4043-4.22461-5.68164-0.46777c-0.31055,0.49121-0.69727,0.93213-1.13867,1.31348
        c-2.55273,2.21777-6.42383,1.94531-8.64453-0.61182c-1.81836-2.81152-4.77539-0.9375-2.27539,1.17236
        c2.50195,2.10986,0.22656,1.87695-2.72656,5.39355c-2.95508,3.51709-8.63477,4.21729-9.0918,0.46631
        c-0.46289-3.75,3.63867-7.271-3.19141-7.03223c-6.74805,0.03223-13.38281,1.72412-19.32031,4.92139
        c-1.36328,2.11084-5.68945,0.70459-8.41602,2.11084c-1.63672,0.84717-3.86523-3.97852,0-4.68311
        c3.86133-0.70996,8.87695-6.09863,11.82813-9.14795c2.95117-3.0459,6.13672,1.40527,11.36328-0.70264
        c5.22852-2.10986,9.55078-3.28369,9.32227-1.17383c-0.22266,2.10889,6.36719,0.70947,4.77734-1.63818
        c-1.58789-2.34375,5.00195-2.81299,4.77539-7.96973c-0.22656-5.16016,6.60156-8.43701,5.68945-6.32715
        s1.13672,5.61914,5.00195,2.57617c3.86328-3.04492,6.36133-2.3457,7.04883-5.62695
        c0.67969-3.27881,5.00195-3.27881,4.77148-7.73389c-0.2207-4.45264,6.37305-8.43799,3.86914-10.54785
        c-1.27832-1.0752-1.48633-3.49072-1.08496-5.75635h-87.12891c-1.97363,1.21094-3.98535,2.35986-6.0498,3.41748
        c-3.17773,0.82031-4.76758,5.38965-2.15625,7.03174c2.48047,1.96973,4.76563,4.18066,6.81348,6.59863
        c0-0.00049,0.00098-0.00049,0.00098-0.00098c4.01953,4.52197,8.16016,10.1875,7.95898,13.31836
        c-0.40625,3.94141,0.30469,7.92725,2.04883,11.48438c1.13867,1.76074-1.02344,3.99023-3.17969,3.40137
        c-2.16016-0.58838-3.29688-1.17383-7.50781,3.27734c-3.93555,3.69873-9.29297,5.49854-14.66992,4.92188
        c-0.78711-1.16699,0.6875-3.39746-1.13281-5.27148c-1.81445-1.87305,4.78516-5.97266,2.50586-9.60596
        c-2.27148-3.63428-2.27148-7.854,0.3457-7.26611c2.60938,0.58594,2.83984-3.16406,0.44922-3.40186
        c-1.6875-0.16211-1.51563-2.70703-2.39063-4.1958c-0.33789-0.68604-1.04492-1.1084-1.81445-1.07764
        c-3.17773,0-4.88477-0.69727-5.91211,1.17578c-1.01953,1.87402-3.75781,0.11816-3.86523-1.99365
        c-0.11328-2.10986-3.41406-2.22949-1.36719-4.80713c2.04102-2.5752,6.70898-5.73877,4.55469-8.55322
        c-2.27344-2.5376-5.4082-4.13525-8.80469-4.46484c0-0.00098,0.00098-0.00244,0.00098-0.00342
        c-1.25293-0.12842-2.51367,0.0376-3.69629,0.48389c-4.9668,2.31055-9.7207,5.05859-14.21289,8.19922
        c-4.43555,2.92969-7.61328,2.34082-5.33789,0.11816c2.27344-2.22803-0.11719-3.16406,1.12891-6.09375
        c0.77344-1.80127,3.99414-4.31592,5.48633-6.69238h-12.28613c-0.35059,0.38232-0.69922,0.77344-1.04199,1.18311
        c-4.32227,5.15771-7.04492,0.58643-8.97656,6.44922c-1.9375,5.85596-6.48633,2.45947-9.55469,2.92578
        c-3.07031,0.4707-5.00391,10.08154,0.11133,9.84375c5.11914-0.23389,9.20898-0.34961,7.96289,2.81396
        c-1.25195,3.16357,1.12891,8.08545,4.77539,6.32764c3.63867-1.75732,8.41211-9.72705,10.23047-6.91406
        c1.54297,2.58057,4.58203,3.86133,7.50195,3.16455c2.61523-0.7041,6.36523,3.6333,4.43164,5.04297
        c-1.92773,1.40527-7.49219,1.28467-8.74609,2.69043c-1.25391,1.40625-3.41406-0.22998-4.66211,2.45996
        c-1.25586,2.70117-2.61719,3.39941-4.20508,3.04785c-1.5957-0.34668-0.11914,2.69922-3.64453,4.22168
        c-2.98828,1.09668-4.58594,4.34424-3.64063,7.38135c1.13867,1.7583,5.91406,2.22461,6.82422,4.92188
        c1.12109,3.43896,2.77734,6.67773,4.89258,9.60986c1.24805,0.93604-0.67969,3.15869,1.36719,4.80371
        c2.04492,1.64551,7.62305,6.32471,6.47656,7.50049c-1.13672,1.17676-4.20117,0.00098-6.82227-1.40674
        c-2.61719-1.40527-6.59766,0.23389-4.32227,0.23389s4.66797,5.28223,6.82031,5.16162
        c2.16602-0.12207,6.36719,3.74512,4.66797,4.33545c-2.61133,1.52734-5.30664,2.896-8.07813,4.10156
        c-2.49609,0.5835,1.36133,3.39648,2.73242,1.63818c1.36328-1.75879,2.95703,2.81201,4.43164,1.64258
        c1.47656-1.17188,5.9082,0.12061,3.1875,0.93799c-2.73242,0.81982-4.66211,3.74805-3.29883,4.7998
        c1.35938,1.05811-1.70508,0.35254-1.25195,3.0498c0.45313,2.69922-0.11133,5.86084-1.5957,6.44434
        c-1.47266,0.58545-4.65625,2.58105-4.99414,4.80469c-0.34961,2.25537-1.63477,4.25439-3.53711,5.51123
        c-1.92188,1.28662-5.67383,1.52441-5.33594,2.57813c0.3457,1.05811,2.8457,3.27686,1.01953,3.86914
        c-1.8125,0.58838,0,4.68311-1.01953,6.09082c-1.02539,1.40625-3.30078,1.0625-3.30078,2.93359
        c0,1.87207-3.74219,2.34668-3.86133,3.86426c-0.11914,1.51514-5.23047,1.99365-5.23047,3.40137
        c-0.45898,2.30273-2.16992,4.16504-4.4375,4.80371c-2.73047,0.82324-2.16016,1.76611-4.54492,3.75537
        c-2.39258,1.9917-2.26758,3.98682-4.54883,4.09961c-4.53711-0.10889-9.05078,0.65283-13.29883,2.23438
        c-2.39063,1.86914-7.39648-1.41455-7.50977-0.23877c-4.2207,4.14063-9.36328,7.22607-15.00586,9.0166
        c-7.16211,2.46338-11.26367,4.5752-10.80273,6.68311c0.46094,2.10498,0.10938,5.50146-2.04688,3.39258
        c-2.1582-2.11084-3.07227-5.74023-1.70898-6.91504c1.36328-1.17236,0.22656-5.39209-2.04297-3.2832
        c-2.28125,2.11084-3.4082,1.75586-4.43555,0c-1.01953-1.75635-3.52148-2.34082-4.20898-0.23291
        c-0.57617,1.80078-3.21484,0.54688-4.69336,1.29883c-0.63281-0.75195-1.17578-1.24023-1.57227-1.30566
        c-2.12109-0.30029-7.5957-4.37305-7.5957-5.93506c0-1.56787,2.73438-4.07617-2.12549-4.70117
        c-2.56934-0.01367-4.96387-1.30127-6.375-3.44092c-0.40039-0.41357-1.38428-0.10303-2.46533,0.56006
        c1.07617-0.65771,2.05371-0.96582,2.45166-0.56006c1.41016,2.14648,3.80566,3.43408,6.375,3.44385
        c4.86377,0.62891,2.12939,3.14014,2.12939,4.69824c0,1.56201,5.46875,5.63086,7.5918,5.94189
        c0.39453,0.06543,0.94336,0.55371,1.56445,1.3125l-0.65234,0.57764c-1.27734,2.21338-3.49805,3.72412-6.02734,4.10254
        c-2.49414,0.1167-3.06641,2.22461-2.94727,4.68799c0.10742,2.45654-4.88623,3.28369-5.4585,6.91553
        c-0.91113,3.70068-0.21387,7.61914,1.93164,10.77295c2.26855,1.9917,1.81055,4.57813,3.40771,6.0957
        c1.59766,1.51758,10.4707,10.88916,12.40039,10.77197c1.93164-0.11475,7.16602,10.20068,7.27539,12.43115
        c1.6875,5.97266,2.29883,12.20117,1.81836,18.39648c-1.47461,7.37891-2.16016,7.96777-6.02539,10.54688
        c-2.99414,2.56934-6.5957,4.33984-10.45703,5.15625c-2.73047-0.12109-3.98242-0.70117-4.77734,0.81641
        c-0.79541,1.52344-0.22461,4.33594-2.04736,4.22754c-1.81641-0.12109-0.11133,2.45605-1.81641,3.04297
        c-2.65332,1.3623-5.0127,3.23633-6.93945,5.50098c-1.02246,1.53125-3.75195,0.58789-3.86523-3.16016
        s3.98145-5.74023,2.15723-6.90918c-1.27881-0.6709-2.43506-1.57129-3.39697-2.64844c-0.00146,0-0.00293,0.00098-0.00439,0.00195
        l-0.68555-1.2168c-0.7959-1.99316-4.77637,1.05566-6.14063-0.58789c-1.36035-1.64453,1.24707-4.33301-0.33496-4.21973
        c-1.5957,0.11816-4.43848,1.4082-4.78027-0.81641c-0.31738-2.10547,0.82031-5.83008-2.07324-7.1416l0.06738-0.23926
        c0.60107-1.64648,0.10742-2.29199-0.37158-3.30371c0.47803,1.01172,0.96924,1.66113,0.36865,3.31152l-0.06836,0.24121
        l-0.53906-0.24902c-3.64258-1.05664-3.64648-4.69727-8.18652-4.45996c-4.54785,0.24023-4.88965-1.40625-5.80176-4.80176
        c-0.90527-3.39355-8.06689-1.06055-6.48193,1.99121c1.58838,3.05078-3.86768,12.7793-4.2085,16.1709
        c-0.33594,3.39941-3.5166,11.35547-0.90918,12.65625c2.60986,1.29395,4.88867,1.41504,4.88867,3.75391
        c0,2.3418,3.17773,4.69531,2.84277,7.38379c-0.34473,2.69238,1.48047,8.67285,5.34375,9.02148
        c3.50977,0.60449,6.66309,2.50391,8.84961,5.31543c-2.41016,0.7998-1.75049,2.7793-2.2583,3.14941
        c0.52344-0.34961-0.1665-2.3457,2.26221-3.14941l0.59375,1.12695c0.67969,2.33789,2.61426,0.2334,3.75586,3.86523
        c1.13086,3.63281,6.02246,3.04395,5.90723,8.31543c-0.11328,5.27539-2.39551,12.5498,0.1123,15.23828
        c2.50488,2.69434,6.81934,10.43262,5.1123,11.25195c-1.70801,0.82031-10.34961-4.44922-14.78125-7.73535
        c-4.43555-3.28223-6.3623-4.21191-7.39453-7.26465c-1.02051-3.05078-7.72168-10.54297-7.84473-15.22754
        c0.09668-4.14551-0.62842-8.26367-2.12158-12.13184c-0.00488,0-0.00928,0-0.01367,0
        c-0.60107-1.40137-1.36963-2.71387-2.29883-3.92285c-3.97803-5.16016-8.06689-9.95996-10.11719-10.2002
        c-2.04639-0.23145-1.3667-3.39551-0.46143-7.03027c0.73486-2.9043,1.53418-8.2627,2.17285-11.67285
        c0.09473,0.01953,0.19043,0.02246,0.28662,0.0127c-0.09033,0.00781-0.18018,0.00488-0.26953-0.0127l0.44775-2.15527
        c0.68359-2.69629-1.93066-6.91504-1.02148-9.37988c0.99463-4.40918-0.0918-9.03418-2.96533-12.53809
        c-3.2876-3.16211-3.63232-13.12061-3.97119-17.104c-0.05029-3.18945-1.12988-6.26807-3.06738-8.78809
        c-1.5957-1.64404-3.41357-6.32861-3.98096-3.16748c-0.57031,3.16748-4.55127,5.1543-6.48486,7.96777
        c-1.9375,2.81104-4.88916,2.33936-7.50293,0.93896c-2.61426-1.40674-4.77734-1.51953-3.18457-4.33252
        c1.94043-3.97607,2.34766-8.5249,1.14111-12.77832c-1.36719-4.92383-4.32568-14.1792-8.76025-16.05518
        c-3.21484-1.73242-5.75781-4.49268-7.2168-7.8418c0.85498-0.83984,1.77686-1.6123,2.75488-2.31689
        c0,0,0.1958-0.76367,0.52734-1.74023c-0.20605,0.56982-0.39404,1.146-0.5415,1.73438
        c-0.97363,0.70361-1.89697,1.47607-2.75439,2.31982c-0.64893-1.24414-1.05518-2.604-1.1958-3.99756
        c-0.24268-3.9292-1.6709-7.6875-4.09277-10.78369c-1.58545-0.82031-4.42822,1.17188-4.66406,3.86768
        c-0.22607,2.69629-3.63232,3.04492-6.13623,3.04492c-1.98291,0-2.98438,1.02588-5.12891,1.45361c0,0.00049,0,0.00098,0,0.00146
        l-1.90967,0.19141c-3.646,0-13.18457,1.39746-13.75635,4.79688c-0.57324,3.40625,0.57178,5.04688-2.39111,8.21045
        c-2.9624,3.16748-9.4375,4.45215-11.7124,8.31641c-3.61865,4.76318-7.77979,9.08887-12.39941,12.89111
        c-4.1958,2.82617-8.11816,6.04883-11.70557,9.6123c-1.76318,2.69824-4.49023,4.62598-7.61914,5.39453
        c-2.16309,0.23193-1.7085,3.63135-2.84961,6.55908c-1.37012,3.14404-1.56494,6.68408-0.56055,9.96436
        c1.27734,4.28516,0.86768,8.89355-1.14111,12.88477c-1.75342,3.05566-2.5459,6.56934-2.27539,10.08203
        c0,3.04883,0,5.27539-2.04736,5.62402c-2.04639,0.35645-2.61377,2.57324-2.95166,5.38867
        c-0.3418,2.81641-6.13623,4.10059-7.16553,7.38281c-1.021,3.28418-5.23096,6.3291-7.15723,4.9209
        c-4.01172-3.71094-6.65625-8.66113-7.51025-14.05957c-1.07275-6.04395-3.57373-11.75098-7.28125-16.64258
        c-2.04639-2.8125-4.42822-14.5293-7.49609-20.50586c-3.30713-6.72314-5.70898-13.85303-7.14453-21.21045
        c-0.80322-5.38818-3.19141-10.66357-3.52881-14.29541c-1.20361-4.3457-1.69482-8.85645-1.47314-13.35938
        c1.13135-3.2832,1.70166-5.85938,0-7.26416c-1.71143-1.40332-0.46143-7.49902-1.9375-8.20361
        c-1.47266-0.70313-3.97656,3.1582-2.38818,4.09717c1.5918,0.94287-2.49805,7.1543-8.07031,8.20996
        c-5.57275,1.05322-16.59521-12.64844-16.37305-13.71436c0.22559-1.06299,10.68457-0.3584,10.68457-3.75781
        c0-3.39648-3.98096,1.29053-8.4126-0.46191c-3.81641-1.51758-4.77637-4.85156-7.07617-6.7207l0.49219-0.57422
        c1.25049-1.50586,1.78467-3.74414,3.56201-4.70166c-1.79199,0.95068-2.32813,3.20166-3.57861,4.7085l-0.49512,0.57471
        l-1.22754-0.78271c-3.29736-1.52441-5.57275-1.16895-6.71045-3.04785c-1.13428-1.87598,0.11279-3.74805-1.59473-5.39014
        c-1.69873-1.63818-3.41064-2.45947-3.51953-4.33545c-0.10986-1.87793-2.40186-4.02148-6.13721-1.87793
        c-3.99365,1.50732-8.37744,1.5459-12.39209,0.11182c-1.96436-1.01318-4.35938-0.62891-5.91748,0.94092
        c-4.68799,1.13428-9.53271,1.49268-14.34033,1.06982v-0.00049c-2.69189,0.01611-5.38867-0.22852-8.03906-0.71582
        c-9.08594-0.87842-18.07178-2.60889-26.83594-5.16162c-1.93652-2.46143-0.67627-9.375-5.90723-10.43066
        C6.04346,283.68945,3.1875,286.84912,0,287.8125v12.21777c2.60352-4.25439,7.03223-9.00391,8.22705-8.90137
        c0.80908,0.06934,0.98389,4.97119,2.10693,9.854l0.00098-0.00049c0.46436,3.17383,1.91357,6.11914,4.13721,8.42529
        c5.34521,4.32959,11.94873,3.16064,12.854,4.92383c0.90234,1.75293,4.31982,7.96436,6.48193,8.31934
        c2.15625,0.34863,2.03662,1.75684,0.56055,4.33643c-2.31299,2.94873-4.43848,6.04102-6.36914,9.25635
        c-0.11572,1.40332-3.86084,3.98389-6.25293,4.44824c-2.38428,0.47168-2.95459,8.43896-2.61279,10.66357
        c0.34082,2.23096-2.38916,1.52637-4.88623,2.58643c-2.50098,1.05908-3.5293,6.4375-5.00195,7.26318
        c-1.47998,0.82422-5.56982-1.05859-8.41553,1.40869C0.45801,362.93604,0.19336,363.26465,0,363.59375V633.3457h524.88281
        c0.1875-1.41211,0.66309-2.48438,1.67969-2.48438c2.2168,0,2.2168-2.45313,3.57617-2.63672
        c1.36719-0.1748-1.1875-3.16211,1.36719-3.5127c2.56055-0.35254,6.30859,2.29199,5.97266-0.17773
        c-0.33984-2.46094,0.16992-3.69141,2.38086-3.69141c2.2207,0,2.2207-2.46191,4.43945-2.63672
        c2.82617,0.50977,5.23438,2.33984,6.48438,4.92285c0.66992,1.93359,4.76563,0.3584,4.25586,3.69141
        c-0.50977,3.33398,2.72852,4.74219,2.55664,1.75488c-0.16602-2.98926,2.38867-2.99121,4.94922-0.35059
        c2.55859,2.63672,6.81836,2.46191,3.91797-0.87598c-2.89648-3.3418-2.72266-5.09668-0.33398-6.68164
        c2.38086-1.58301,0.84766-3.33789,3.06445-5.79883c2.21875-2.46387,2.73242-7.38672,5.45703-7.20703
        c2.73047,0.1748-1.02148-3.3418,2.2207-2.28809c3.23438,1.05664,14.32617-0.87891,11.76758-3.69043
        c-2.55664-2.81055-5.12305-4.39355-2.90234-5.27539c2.21875-0.87793,3.41406,4.04102,7.16992,4.57227
        c5.28125,0.46387,10.41016,2.08594,14.99805,4.74414c1.70898,2.28613,4.95117-2.28613,5.79883-2.45801
        c0.85742-0.17871,1.88281,4.57031,2.9043,4.21582c1.02148-0.34863,0.17383-3.69043,2.89648-3.16211
        c2.73047,0.52734,4.26367,2.81348,1.70898,3.86523c-2.55859,1.05566,0.85547,3.33789-2.55859,5.27539
        c-3.40625,1.93359-6.31055,1.4082-4.95117,3.69043c1.36914,2.28613,1.88281,4.74707-0.67578,6.68164
        c-2.56641,1.93555-3.41406,1.93555-0.68359,3.86523c2.63867,1.87012,3.84473,2.43652,4.99707,5.64844h40.24512
        c0.22852-1.57422,0.3291-3.16797,0.29492-4.7666c0.50977-4.39844-1.36328-12.13184,0.67969-14.06738
        c2.04883-1.92969-0.50977-7.37891,1.02539-8.78809c1.5-1.74121,2.18359-4.04492,1.87891-6.32715
        c-0.16992-2.28613,3.74609-6.85449,4.93945-5.79785c1.20703,1.05176,1.375,7.72949,3.76758,9.66699
        c2.69922,3.63672,3.96875,8.1416,3.57617,12.6543c-0.3418,4.04004-0.51172,8.26074,2.39063,7.90625
        c2.89648-0.34863,5.79492-2.10742,5.96484-0.17383c0.17383,1.93359,5.45898,2.8125,5.12109,6.32715
        c-0.11523,1.17383-0.01367,2.28418,0.15625,3.36621H800V166.6543H660.90137
        C662.5791,172.0957,664.4375,178.37109,662.57031,180.14941z M194.34375,459.29688
        c-3.85791-2.5752-3.41064-7.26367-3.85791-11.72363c-0.68652-3.49414-0.5293-7.10938,0.44727-10.54297
        c1.14502-3.05078,0.68359-7.25781,2.72998-7.49609c6.31738,4.41016,10.77002,11.00488,12.51221,18.51074
        C205.94336,454.60645,198.78809,462.25293,194.34375,459.29688z M591.8418,224.91357
        c3.63867-0.69873,6.36719,0.47021,7.27734-2.104c0.91406-2.58545,7.73633-2.58545,7.73633,0.93115
        c0,3.51514-2.95508,8.2085-5.23828,7.03174c-0.1875-0.09619-0.37695-0.17969-0.57422-0.25391
        c-2.44531-0.87549-5.13477,0.39355-6.01172,2.83594c-0.6875,2.81396-2.2793,2.81396-4.32617,2.10986
        C588.08398,234.55908,588.21484,225.61768,591.8418,224.91357z M571.1543,229.60547
        c3.4082-2.5752,6.82031-3.75195,7.04883-1.64258c0.21875,2.1123,5.22656,1.64258,5.22656,3.98926l0.00781-0.00293
        c0,2.33643,4.99805,0.69824,1.58203,5.15527c-3.40625,4.45459-6.36523,12.8916-8.64258,13.35986
        c-2.69141,0.55469-4.99805-5.39014-2.95117-9.60986c2.04688-4.21729-0.22461-10.5459-1.14063-7.96973
        c-0.90625,2.58154-2.94727,4.92383-4.31836,2.3457C566.60352,232.6543,567.74023,232.18262,571.1543,229.60547z
        M547.50391,536.41602c-1.82813,3.27637-8.41406,1.16895-9.32813-2.5752v-0.01172
        C537.125,529.48438,549.33008,533.13965,547.50391,536.41602z M540.45898,432.35352c0,5.62402,4.53906,14.29492,1.81055,17.5752
        v-0.00391c-2.73047,3.2832-7.96094,0.93945-8.18164,3.51855c-0.22656,2.57715,2.72461,10.07715,0.22266,10.07715
        c-2.49609,0-2.7207-3.28418-7.95313-4.21777c-5.23438-0.94238-2.5-12.88574-6.36914-13.59082
        c-3.86719-0.7041-2.96484,2.34082-5.6875,2.10938c-2.73242-0.24023-4.0957,3.51465-6.82031,3.74023
        c-2.26758,0.19824-0.45117-7.26465,2.95508-8.19727c3.41406-0.93555,6.58789-7.02539,8.86914-5.38672
        c2.27734,1.63867,7.05273,4.45117,6.82031,1.63867c-0.22461-2.81152,4.09375-0.00586,4.55078-3.51465
        c0.45703-3.5166,6.36914-0.71094,4.31836-4.22754C532.94727,428.36523,540.45898,426.72949,540.45898,432.35352z
        M522.25977,422.50977c0.91016,2.09961-5.00195,13.35254-8.18555,12.64844c-3.1875-0.70508-3.1875-6.56641-1.14063-8.6748
        c2.04688-2.10938-0.45508-8.66895-0.6875-6.3291c-0.21484,2.34766-2.94727,2.10938-4.08984,2.34766
        c-2.67578,0.54688-3.85938-10.0752-0.44922-10.77832c3.41211-0.70215,12.2793,6.56641,11.59766,9.37793
        C518.62109,423.91406,521.34961,420.40234,522.25977,422.50977z M527.48828,427.89063
        c1.82031,2.34668-4.77539,6.08887-5.68945,3.75195C521.12695,429.88281,525.66992,425.54297,527.48828,427.89063z
        M494.05859,313.27979c2.50195-9.37939,11.60156-13.83154,13.64844-11.01953c2.04102,2.81885-7.27539,22.9707-9.54688,24.61377
        C497.38281,327.4209,491.56445,322.65771,494.05859,313.27979z M491.10352,375.85254
        c0.50977-1.56689,2.2793,2.11963,3.64258,0.93701c1.37109-1.1748-1.36133-16.64307,1.5957-18.51221
        c2.95313-1.87891,9.54297,1.87598,11.13867,0.93311c1.5918-0.93311,1.82422,0,1.36328,2.81494
        c-0.45313,2.81201,3.64258,9.14014,0.46094,12.88818c-3.19531,3.75195-7.96289,8.20313-5.23438,11.24805
        c2.72656,3.05127,0.00195,9.604,3.86719,7.27002c3.86914-2.33887,10.45703-0.23145,10.68359,1.87598
        c0.23047,2.1123,5.23633,3.28516,5.23633,7.0332c0,3.75391,6.81836,2.33984,8.63672,6.32715
        c1.81641,3.99023,5.00781,7.50684,2.04297,9.61426c-2.95508,2.1123-3.85352,1.87207-3.4043,4.68555
        c0.44922,2.81055-1.82422,5.86133-2.50977,2.10742c-0.67773-3.75195-5.91016-6.79297-3.86523-9.83984
        c2.05469-3.05078-1.13477-5.38867-3.17578-3.5166c-2.05078,1.87207-3.1875-4.9209-2.27734-7.03223
        c0.91406-2.10742-4.99805-7.73242-9.32031-7.03125c-4.32422,0.70703-2.73047-3.99121-7.04883-2.35352
        c-4.32422,1.64648-8.87695,1.18066-7.73242-2.57324c1.13867-3.75586-0.23438-6.08936-2.04492-6.08936
        C491.33789,386.63916,490.42383,377.96436,491.10352,375.85254z M502.70313,408.20313
        c-4.99805,1.63965-6.59766-5.15527-7.27344-8.43555C494.93555,397.40625,507.70117,406.56055,502.70313,408.20313z
        M493.83398,501.00977c4.31836-0.69727,4.09961-5.85059,9.32813-3.97852s15.45703,4.69141,19.32422,2.1084
        c3.87109-2.5791,10.23633-7.27441,8.18945-3.28125c-2.04297,3.98438-7.95313,10.78516-13.41797,8.20215
        c-5.45117-2.57715-24.33008-1.40527-25.4668,5.85938c-1.13477,7.27051,8.4043,11.95215,9.55273,7.73438
        c1.13867-4.21973,14.55273-7.03125,15.68945-4.68359c1.13867,2.33984-8.4082,7.96387-11.59961,8.66895
        c-3.18555,0.70313-0.9082,4.21191,2.50391,6.56348c3.41016,2.34766-0.9082,6.33105,1.58594,9.14258
        c2.51172,2.8125,3.18555,4.93457-0.45117,6.10352c-3.64258,1.16992-7.04688,3.51367-7.04688-0.93359
        c0-4.45898-5.91992-3.99121-3.86523-7.97559c2.04492-3.97559-4.10547-5.85547-5.00977-3.28223
        c-0.90234,2.58301-1.13477,12.89453,0.9082,14.53418c2.04688,1.64063-0.21289,6.32813-3.86133,7.73242
        c-2.96875,1.14453-1.58984-6.09961-0.9082-9.14063c0.68164-3.04492,1.13477-8.20703-2.27539-8.43555v-0.00977
        c-3.41992-0.22949-8.85938-1.16699-4.76953-7.97168c4.08984-6.79688,4.08984-14.06641,6.13672-15.23535
        C490.42383,511.56641,489.51758,501.7168,493.83398,501.00977z M527.05078,580.26367
        c-0.1875,0.83594-0.57227,1.61328-1.12695,2.26855c-0.63672,0.51465-1.73047-0.5127-2.50781-1.42383
        c0.79102-0.34766,1.63086-0.55957,2.48438-0.63281l0.13086-0.03125L527.05078,580.26367z M481.33203,426.94727
        c3.18359-4.21582,4.76953-11.47559,6.14063-8.66504c0.16992,0.23145,0.32031,0.48535,0.45117,0.74512
        c1.4375,2.81836,0.31641,6.25977-2.49414,7.69434c-4.09766,3.04102-12.51172,15.93262-13.87695,13.35938
        C470.55273,438.17773,478.14258,431.16699,481.33203,426.94727z M405.37891,355.92676
        c-2.95508-6.32129,4.54492-10.76953,7.49805-10.76953c2.95898,0,9.10352-0.94336,9.55859,0.69727
        c0.58984,2.13818-3.86914,6.56006-4.09766,9.37207S408.33594,362.25146,405.37891,355.92676z M400.83008,530.31445
        c0,2.1084-2.27148,4.92285-4.0918,1.87793C394.96094,529.22754,400.83008,528.20508,400.83008,530.31445z M320.09961,502.18848
        c-2.72314,2.81445-5.22803-3.98242-4.77637-6.33008C315.771,493.57129,322.8291,499.37109,320.09961,502.18848z
        M330.10059,520.4707c-1.58594,1.40723-4.08691-3.28027-3.63232-4.44922
        C327.90674,512.31543,331.68555,519.06641,330.10059,520.4707z M372.18115,553.53418c-1.1377,2.10742-2.5-2.10742-3.87109-3.97949
        c-1.36328-1.87598-11.36035-7.97168-13.86914-12.4209c-2.50098-4.45605-10.01074-7.49707-11.14453-13.59766
        c-1.13867-6.09961-8.41553-17.58398-11.83008-18.28711c-3.41309-0.70117-3.85986-15.46387-8.41113-15.93945
        c-4.54443-0.47559-6.59082-8.9043-10.45605-11.25195c-3.86377-2.33984-12.2749-11.24609-10.46436-15.23438
        c1.44775-3.14941,3.86768,3.2832,10.01416,3.04688c6.13281-0.2373,9.76807,1.39844,11.13818,5.38672
        c1.35986,3.98926,11.87695,8.97168,12.95361,11.95703c1.36621,3.74805,5.01465,7.5,6.60352,6.79688
        c1.58594-0.70117,4.08984,0.46387,5.9082,2.5752c1.81836,2.1084,2.73047-0.46289,4.77637,2.34766
        c2.04297,2.81152,3.86426,1.64063,3.86426,3.98047c0,2.34668,4.08984,0.71094,4.08984,3.52344
        c0,2.80566,6.375,4.92285,3.87109,7.26074c-2.50781,2.34375-2.27539,5.62598,1.59277,5.62598
        c3.86328,0,3.18359,6.79297,6.82324,7.02832c3.63477,0.23633,1.58887,4.45996,4.76953,4.21973
        c3.19141-0.23926,4.09668,3.05469,4.77637,1.40918c0.67969-1.65039-0.67285-3.52051-2.95508-3.76074
        c-2.26855-0.23535-2.96191-3.51367,0.90918-3.51367c3.86719,0,4.77637,5.86133,6.37256,6.32715
        c1.58984,0.46777,1.8125,5.38477-0.23389,4.9209c-2.0498-0.45996-3.18359,0.24121-3.18359,1.88574
        c-0.60547,2.69238-0.98779,5.43652-1.13086,8.19727c0.22119,3.27832-0.44775,11.96777-2.72363,11.73535
        C378.09229,553.53418,373.32178,551.42676,372.18115,553.53418z M456.76758,575.79492
        c-4.54883,3.5127-7.50195-2.58301-9.08984-0.24023c-1.59766,2.35156-6.14844-1.17188-10.23633-0.4668
        c-6.55273,1.06738-13.2793-0.08789-19.10156-3.28125c-4.54883-3.75195-13.64453-5.85938-14.55469-3.75195
        c-0.9082,2.1123-10.69141-0.70703-13.64258-1.1748c-2.95166-0.46484-2.04688-4.44922-4.09619-4.44922
        c-2.04688,0-4.99805,1.64063-5.67578-1.1709c-1.03418-4.23145,1.125-0.2334,3.62891-4.68848
        c2.50781-4.45117,14.78369-1.4043,17.96533,0.94043c3.17969,2.34375,16.14453,7.02539,16.37305,3.74805
        c0.22852-3.28027,8.64844,4.22168,15.92188,1.87402c7.26953-2.34277,12.05078-1.6377,9.0918,0.23438
        c-2.95313,1.87598-12.05078,3.51172-7.72852,5.38965c4.31836,1.87598,13.19141-0.70313,13.41797,2.11035v-0.00391
        C449.27539,573.68262,461.32617,572.27832,456.76758,575.79492z M449.26172,538.2959
        c-0.46094-2.8125-7.50977-4.21973-9.77734-4.68848c-2.2832-0.47168-9.33203,3.28418-10.23438,1.16895
        c-0.91602-2.10449-3.1875-5.15332-6.13477-3.04688c-2.9668,2.09961-7.2793-5.62402-7.2793-10.54883
        c0-4.91895-5.01563-5.38086-6.59961-7.96777c-1.58594-2.58594-0.67773-7.73242-4.0918-11.02246
        c-2.8418-2.73242,2.05469-10.77734,5.00195-11.0127l0.87793,0.08887c0-0.00195-0.00098-0.00391-0.00098-0.00586
        c3.4043,0.71289,10.14453,5.74316,11.39844,2.95605c1.36914-3.04102-1.125-9.6084,5.01172-10.0791
        c6.13867-0.46582,10.23047,0.94238,11.13672-3.5166c0.91406-4.44922,5.00195-11.00879,6.82031-11.00879l0.16016,0.02734
        c1.66016,3.16797,3.23438,5.59668,4.16211,5.59668c0.00293,0,0.00586,0,0.00879,0
        c-0.94043-0.00586-2.50879-2.42578-4.16113-5.59668c1.86133,0.14746,5.29883,2.96582,5.29883,0.90625
        c0-1.27734,2.17578-1.26465,3.78906-1.79395c-0.00098-0.00293-0.00098-0.00488-0.00195-0.00684
        c1.04297-0.33887,1.85156-0.89551,1.66797-2.18359c-0.45117-3.28027,3.86523-3.04102,4.0918-5.38867
        c0.23047-2.34668,3.41211-7.96484,6.13477-7.96484c3.28906,0.5918,5.98633,2.92969,7.05664,6.08887
        c0,2.1084,4.7793,2.33984,6.82227,1.87598c2.05078-0.4707,6.13867,1.87891,5.2168,4.68359
        c-0.9082,2.8125-9.0957,1.16895-7.04492,3.05273c1.77148,1.61816-2.08398,3.41895-4.77344,4.78613
        c-1.11328-0.66406-2.35352-1.09766-3.64258-1.27148c-3.63867,0-12.2793-0.94336-11.82422,5.15234
        c0.43652,5.71973-4.3457,4.00098-4.56641,6.48535c0.22949-2.46289,5.00195-0.76367,4.57031-6.47754
        c-0.45117-6.08984,8.18164-5.15234,11.82422-5.15234c1.28516,0.17773,2.52539,0.6084,3.63867,1.26953l-1.14063,0.60645
        c-2.49805,1.40625,2.5,5.62598-0.45117,5.85547c-2.95313,0.23242,0.46094,5.16309,2.50391,7.03125
        c2.05078,1.87598-0.44727,4.9248,2.50195,6.3291c2.95703,1.40625,6.83008,4.45703,5.23438,6.32715
        c-1.5957,1.87598-12.05469,2.11328-11.59961,6.79492c0.94922,4.59961-0.81445,9.34277-4.54883,12.19531v-0.00586
        c-4.08789,2.5752-1.58789,15.93652-5.91602,17.58105C456.08398,538.05664,449.72461,541.10742,449.26172,538.2959z
        M472.4707,580.01465c-3.72852,1.84961-8.23633,1.09961-11.1543-1.88379c-1.44531-1.99121,1.37305-5.85645,3.63867-4.21973
        c2.27344,1.64355,6.14063,1.17676,7.04883-0.93359v-0.00195c0.91406-2.11133,12.74219,0.69922,11.37695,3.74805
        C482.01758,579.77441,478.1543,577.19922,472.4707,580.01465z M490.19727,587.97363
        c-1.58984-1.40625-5.45508-1.40625-6.81836-3.51367v-0.00781c-1.51758-2.33789,14.09375,0.01367,14.09375,3.99707
        C497.47266,592.42969,491.79688,589.37891,490.19727,587.97363z M502.02539,579.30762
        c-6.14648-0.23633-11.59375-0.46582-12.50781-2.3457c-1.00391-2.06641,8.1875-4.45801,14.32422-2.1084
        c6.14258,2.3457,10.23828-2.33887,16.37695-1.40625v-0.00391c6.13867,0.93945,1.58008,2.10742-2.50781,2.35156
        C512.58789,577.40723,507.33984,578.57813,502.02539,579.30762z M548.18359,574.14063
        c-5.24805,2.8584-10.6582,5.41602-16.19336,7.6709c-0.68164-0.98926-1.50195-1.87988-2.42773-2.63477
        c0.92578,0.76465,1.74414,1.64941,2.41797,2.63867l-0.77148,0.52441l-0.76563,0.71191l-1.04102,1.35254
        c-2.43359,3.02148-6.70703,7.29785-9.64648,5.67773c-3.05078-1.66992-0.73438-7.0957,3.66016-8.96777
        c0.77539,0.91309,1.86523,1.93262,2.51172,1.42188c0.55078-0.65723,0.93555-1.43652,1.12695-2.2666
        c0.89453-0.19727,1.74414-0.56641,2.50781-1.08496v-0.00781l0.66016-0.47754c2.28125-1.87012,3.51563-4.47754,6.58984-4.31641
        C541.36719,574.61816,548.86914,572.27441,548.18359,574.14063z M549.77734,493.52344
        c3.63477-5.15234,5.22461,1.40625,5.22461,3.04297c0,1.64355,4.55273-1.1709,4.55273,2.10938
        c0,3.28809,2.28711,6.33301-2.72852,5.8623v-0.00391c-5.00195-0.46387-0.44922,5.86328-2.96094,6.33105
        C550.95703,511.41211,546.14063,498.67578,549.77734,493.52344z M564.33594,535.01172c-4.78125,0-9.55664,1.16504-10.4707-1.64648
        c-0.99023-3.08594,9.09961-4.68848,15.00977-2.8125v-0.00195c5.91406,1.87207,7.73828,7.97363,5.23828,7.97363
        C571.60547,538.52441,569.11133,535.01172,564.33594,535.01172z M570.06445,602.56055
        c-2.58008-2.12793,9.55273-4.56836,9.89844-2.46094C580.30469,602.20996,571.77148,603.96484,570.06445,602.56055z
        M745.11719,537.58496c3.63477,2.34375,6.81641,6.79883,2.49805,7.96777c-2.67188,0.72461-0.22656-3.05078-4.77734-7.50391
        C738.29492,533.60156,741.48242,535.2373,745.11719,537.58496z M728.05859,552.10938
        c5.6875-2.33691,9.32227-3.51172,9.32227-5.62402c0-2.10742,3.4082-4.91211,4.99609-3.04004
        c1.5957,1.87207-2.27148,5.15234-2.27148,7.03027c-4.03906,4.69141-9.74609,7.63086-15.9082,8.20117
        c-6.14258,0-12.73438-3.75195-12.50977-5.86328C712.04297,549.42773,722.37891,554.45898,728.05859,552.10938z
        M690.31055,548.12598c0.44922,4.45703,12.05469,5.62988,15.23242,8.67871c3.1875,3.04102,2.5,5.37695-1.58984,5.14453
        c-4.08984-0.22559-1.58789,4.92676,1.82422,6.56738c3.41016,1.64453,2.27344,8.42969,5.45313,10.54688
        c3.1875,2.11133,7.95703,1.87988,7.73047,3.75781c-0.2207,1.87012,3.86914,4.68164,5.45508,5.38672
        c1.5918,0.70313,6.14258,4.68262,1.14844,4.91699c-5.00586,0.2373-10.69531-4.22754-16.16016-3.04492
        c-5.45117,1.1748-11.58789-11.25195-16.82031-15.46777c-5.23047-4.21973-14.0957-6.33105-16.14258-3.74805
        c-2.05078,2.5752-2.73438,5.85547-4.55469,5.85547c-1.81836,0,0.6875,5.86035-9.31641,5.39453
        c-2.94141-0.12305-5.78516-1.0957-8.1875-2.79297v0.00586c-4.00781-2.77148-6.10547-6.58496-8.18945-6.58496
        c-3.18555,0-16.60352,3.05273-17.74023,0.94238c-1.13477-2.10742,3.18164-6.32715,7.27344-6.32715
        c4.09766,0,3.19141-3.75391,0.23242-7.03027c-2.95508-3.29492,0.22656-5.63184-4.32422-9.14453
        c-6.55273-4.11035-13.92578-6.75293-21.60156-7.7334c-5.22461-0.24023-1.5918-2.34766-6.82031-3.75684
        c-5.22461-1.4043-7.95508-5.39746-7.95508-2.34668s-5.24023,3.74805-5.24023,0c0-3.74023-2.95117-5.38477-4.77734-7.03125
        c-1.81055-1.6377,1.82617-1.87012,4.55859-1.16895c2.72656,0.70313,2.73047-2.57715,6.5918-1.17285
        c3.86523,1.41309,3.4082-2.5791,0.6875-2.33887c-2.72852,0.23145-10.91406,2.33887-12.28516-0.70508
        c-1.36328-3.05078-1.36328-5.38672-4.31836-5.38672c-2.95117,0-4.54688,0.93848-4.0918-2.10938
        c0.48828-3.2793,5.23047-1.1709,6.59375-5.15918c1.36719-3.97949,15.01172-1.16895,17.50586,2.34766
        c2.49805,3.5166,2.73438,21.56348,8.1875,19.6875c5.46094-1.87598,9.55273-8.20313,12.51172-8.20313
        c2.95313,0,3.41406-6.09961,6.82422-6.33984c8.96094,2.68262,17.76367,5.86816,26.37695,9.5332v-0.00684
        c1.86133,0.66992,3.76172,1.24414,5.68945,1.72168C669.16211,533.12207,689.85547,543.6709,690.31055,548.12598z"/>
      <path class="agm-2" d="M195.56787,263.19092c0.00879-0.0415,0.02393-0.08252,0.03613-0.12402
        C195.5918,263.1084,195.57666,263.14941,195.56787,263.19092z"/>
      <path class="agm-2" d="M276.69727,311.07178c-2.58496,0-2.74658,7.09375-0.85693,8.37109
        C273.95752,318.16797,274.11914,311.07178,276.69727,311.07178z"/>
      <path class="agm-2" d="M278.72754,280.11719c0.17627-0.0957,0.35791-0.18164,0.53711-0.27148l-0.5415,0.26172
        C278.72314,280.10742,278.72705,280.11621,278.72754,280.11719z"/>
      <path class="agm-2" d="M276.24561,319.6167c2.30322,0.47559,4.15137-6.17383,5.52832-6.17383
        C280.38721,313.44287,278.5415,320.08838,276.24561,319.6167z"/>
      <path class="agm-2" d="M305.75586,286.37256c-2.13135,1.87695-5.16943,2.81982-5.16943,6.26074
        c0,3.43896-2.73682,6.89209-2.12451,9.7041c0.604,2.81201-3.03418,8.1333-2.73389,9.69824
        c0.3042,1.56787-5.1626-0.31396-6.07471-0.31396s-0.30127,5.94482-1.51758,7.82666
        c-1.21924,1.87598-0.30713,8.44629-2.13135,8.44629l-0.00391,0.00293c0.00439-0.00049,0.00879-0.00293,0.01367-0.00293
        c1.82861,0,0.91211-6.56299,2.12549-8.43896c1.2124-1.88623,0.60449-7.82715,1.52344-7.82715
        c0.90527,0,6.37598,1.88184,6.07471,0.31006c-0.30371-1.56104,3.33203-6.87939,2.73096-9.69434
        c-0.60498-2.81885,2.12744-6.26172,2.12744-9.70605c0-3.4458,3.03809-4.38477,5.16357-6.26367
        c1.8457-1.63916,4.86426-3.75635,6.00439-3.22607C310.63135,282.60303,307.61035,284.72803,305.75586,286.37256z"/>
      <path class="agm-2" d="M312.77441,272.89941h-0.00049C312.77441,272.89941,312.77441,272.89941,312.77441,272.89941z"/>
      <path class="agm-2" d="M279.97607,279.5083c-0.00146-0.00146-0.00293-0.00244-0.00439-0.00342l-0.11182,0.05371
        C279.89844,279.54102,279.9375,279.52539,279.97607,279.5083z"/>
      <path class="agm-2" d="M309.70117,268.24463c-0.35547-0.55273-0.60303-1.08398-0.60303-1.5835
        c0-0.39453-0.13623-0.64844-0.36768-0.78418c0.22461,0.1377,0.35645,0.38867,0.35645,0.77539
        C309.08691,267.15381,309.33984,267.68799,309.70117,268.24463z"/>
      <path class="agm-2" d="M312.25195,272.25049c-0.0459-0.0874-0.08691-0.17725-0.11719-0.271
        c-0.08057-0.24707-0.19434-0.49414-0.32813-0.74072c0.13037,0.24316,0.24023,0.48682,0.31836,0.73047
        C312.15723,272.06689,312.20313,272.15918,312.25195,272.25049z"/>
      <path class="agm-2" d="M196.29883,261.87256c0.04004-0.04883,0.08008-0.09766,0.12256-0.146
        C196.37891,261.7749,196.33887,261.82373,196.29883,261.87256z"/>
      <path class="agm-2" d="M199.03076,258.99561l0.02734-0.08936c-0.01465,0.03027-0.02393,0.06006-0.04102,0.09082
        C199.02148,258.99658,199.02588,258.99609,199.03076,258.99561z"/>
      <path class="agm-2" d="M210.40869,259.23633c-0.20166-0.18115-0.37793-0.34912-0.52588-0.50195
        c-0.44775-0.46191-0.93213-0.8374-1.42529-1.13428c0.48828,0.29541,0.96729,0.66699,1.41113,1.12451
        C210.02002,258.87988,210.20166,259.05176,210.40869,259.23633z"/>
      <path class="agm-2" d="M251.70703,280.43457c0,0.87891,0.14844,1.81934,0.29639,2.75439
        c-0.14795-0.93555-0.29639-1.87793-0.29639-2.75977c0-0.43018,0.04639-0.76465,0.12256-1.04346
        C251.71729,279.72119,251.66895,280.07715,251.70703,280.43457z"/>
      <path class="agm-2" d="M199.15381,258.59375l0.02051-0.06738c0-0.15771-0.05322-0.33057-0.146-0.51172
        c0.08789,0.17578,0.13916,0.34375,0.13916,0.49756C199.16748,258.53857,199.15674,258.56641,199.15381,258.59375z"/>
      <path class="agm-2" d="M183.99072,236.60254c-1.99805-1.76611-2.92285,1.44043-2.01904,3.13525
        c-0.89502-1.69629,0.03076-4.87939,2.02197-3.11963c1.99854,1.76318,5.61377,0.20459,4.76172-2.3374
        C189.58545,236.81445,185.98438,238.36572,183.99072,236.60254z"/>
      <path class="agm-2" d="M314.85889,278.2334c-1.21631,0.62891-1.20654,2.5,0.61084,5.31982
        c1.02246,1.58545,0.03271,1.88281-1.13916,1.56104c1.17529,0.32324,2.17188,0.02783,1.146-1.55664
        c-1.82422-2.8125-1.82422-4.69336-0.60498-5.31934c1.21338-0.625,1.81836-3.75781,1.81836-3.75781l-0.00732-0.00146
        C316.67969,274.49609,316.07861,277.61035,314.85889,278.2334z"/>
      <path class="agm-2" d="M183.1377,243.33105c-0.22998-1.2417-0.40381-2.55518-0.96143-3.27393
        c-0.05713-0.07373-0.10547-0.15674-0.15381-0.23926c0.0459,0.07764,0.09033,0.15576,0.14404,0.22559
        C182.72705,240.76514,182.90381,242.08594,183.1377,243.33105z"/>
      <path class="agm-2" d="M204.33398,275.72949c0.29395,1.25391,3.62793,2.81885,5.75684,3.13281
        C207.97314,278.54834,204.63379,276.98535,204.33398,275.72949z"/>
      <path class="agm-2" d="M198.63867,259.51123c0.03076-0.03564,0.05859-0.07031,0.0874-0.10547
        C198.69727,259.44092,198.66992,259.47559,198.63867,259.51123z"/>
      <path class="agm-2" d="M198.8584,259.23877c0.00879-0.01221,0.01563-0.02393,0.02393-0.03613
        C198.87402,259.21484,198.86719,259.22656,198.8584,259.23877z"/>
      <path class="agm-2" d="M195.53125,263.50684c-0.00049,0.00488-0.00195,0.00977-0.00195,0.01465
        c0,1.87891-1.22021,4.38672-1.22021,5.94971c0,0.00049,0,0.00049,0,0.00098c0.00098-1.56543,1.22021-4.07227,1.22021-5.94873
        C195.5293,263.51807,195.53076,263.51221,195.53125,263.50684z"/>
      <path class="agm-2" d="M196.57813,261.55371c0.04639-0.0498,0.09131-0.1001,0.13916-0.14941
        C196.66943,261.4541,196.62451,261.50391,196.57813,261.55371z"/>
      <path class="agm-2" d="M198.37061,259.80127c0.04883-0.05078,0.0957-0.10059,0.1416-0.15039
        C198.46631,259.70068,198.41992,259.75049,198.37061,259.80127z"/>
      <path class="agm-2" d="M198.25977,259.91357c-0.06934,0.06885-0.13916,0.13818-0.21289,0.2085
        C198.12012,260.05176,198.19092,259.98242,198.25977,259.91357z"/>
      <path class="agm-2" d="M197.9873,260.18018c-0.36279,0.34619-0.76123,0.70996-1.13232,1.08496
        C197.22656,260.88965,197.625,260.52637,197.9873,260.18018z"/>
      <path class="agm-0" d="M554.12109,185.28516c-2.43359-0.26563-4.88477,0.28516-6.97852,1.56299
        c-0.69727,0.7124-2.75195,2.04346-4.53516,3.51514c0.875,1.48877,0.70313,4.03369,2.39063,4.1958
        c2.39063,0.23779,2.16016,3.98779-0.44922,3.40186c-2.61719-0.58789-2.61719,3.63184-0.3457,7.26611
        c2.2793,3.6333-4.32031,7.73291-2.50586,9.60596c1.82031,1.87402,0.3457,4.10449,1.13281,5.27148
        c5.37695,0.57666,10.73438-1.22314,14.66992-4.92188c4.21094-4.45117,5.34766-3.86572,7.50781-3.27734
        c2.15625,0.58887,4.31836-1.64063,3.17969-3.40137c-1.74414-3.55713-2.45508-7.54297-2.04883-11.48438
        c0.20117-3.13086-3.93945-8.79639-7.95898-13.31836c0,0.00049-0.00098,0.00049-0.00098,0.00098
        c0.00098,0.00195,0.00293,0.00391,0.00488,0.00586C557.08398,184.73633,555.62891,185.30371,554.12109,185.28516z"/>
      <path class="agm-1" d="M281.77393,313.44287c-1.37695,0-3.2251,6.64941-5.52832,6.17383
        c-0.14404-0.0293-0.2793-0.08887-0.40527-0.17383c-1.88965-1.27734-1.72803-8.37109,0.85693-8.37109
        c2.77344,0,5.99268-6.18359,5.99268-8.07861s-2.75732-1.89502-5.521-1.89502c-2.77148,0-8.7583,0.47168-8.7583,0.47168
        s-1.97412-0.76563-3.03223-2.10889c-0.38818-0.49072-0.65723-1.05615-0.65723-1.69189c0-2.37012-1.3877-4.26904-5.06641-4.26904
        c-3.69043,0-3.68652-3.80127-6.45508,0c-2.76074,3.79395,5.99365,3.79785,5.07422,6.64746
        c-0.92334,2.85254-3.68652,0.95068-4.14844,3.32227c-0.22559,4.04492,0.40723,8.0874,1.8457,11.87305
        c0.38672,1.08838,0.72852,2.18994,1.03369,3.30078c1.00635,3.6499,1.57715,7.40918,1.68213,11.20166
        c2.14453-0.42773,3.146-1.45361,5.12891-1.45361c2.50391,0,5.91016-0.34863,6.13623-3.04492
        c0.23584-2.6958,3.07861-4.68799,4.66406-3.86768c2.42188,3.09619,3.8501,6.85449,4.09277,10.78369
        c0.14063,1.39355,0.54688,2.75342,1.1958,3.99756c0.85742-0.84375,1.78076-1.61621,2.75439-2.31982
        c0.14746-0.58838,0.33545-1.16455,0.5415-1.73438c0.39355-1.15967,0.98193-2.61133,1.67529-3.47363
        C284.51563,323.52002,283.47119,318.3833,281.77393,313.44287z"/>
      <path class="agm-1" d="M255.96826,315.34277c-1.44238-3.78564-2.06836-7.82813-1.84277-11.87305
        c0.46191-2.37158,3.2251-0.47656,4.14844-3.32227c0.91943-2.84961-7.83789-2.84961-5.07422-6.64746
        c2.77148-3.80664,2.76855,0,6.45508,0c3.67871,0,5.06641,1.89893,5.06641,4.26904c0,0.63574,0.26904,1.20117,0.65723,1.69189
        c1.05957,1.34082,3.03223,2.10889,3.03223,2.10889s5.98682-0.47168,8.7583-0.47168c2.76367,0,5.521,0,5.521,1.89502
        s-3.21924,8.07861-5.99268,8.07861c-2.57813,0-2.73975,7.09619-0.85693,8.37109c0.12598,0.08496,0.26123,0.14453,0.40527,0.17383
        c2.2959,0.47168,4.1416-6.17383,5.52832-6.17383c1.69727,4.94043,2.7417,10.07715,3.10205,15.28955
        c0.35254-0.43896,0.73047-0.72852,1.12451-0.73486l0.00391-0.00293c1.82422,0,0.91211-6.57031,2.13135-8.44629
        c1.21631-1.88184,0.60547-7.82666,1.51758-7.82666s6.37891,1.88184,6.07471,0.31396
        c-0.30029-1.56494,3.33789-6.88623,2.73389-9.69824c-0.6123-2.81201,2.12451-6.26514,2.12451-9.7041
        c0-3.44092,3.03809-4.38379,5.16943-6.26074c1.85449-1.64453,4.87549-3.76953,6.0083-3.22412
        c0.1665,0.07715,0.29346,0.20947,0.37061,0.40918c0.18994,0.48877,0.76563,0.94629,1.41113,1.25928
        c0.01563,0.00781,0.03223,0.01318,0.04785,0.021c0.11328,0.05322,0.22803,0.104,0.34375,0.14746
        c0.08838,0.03369,0.17676,0.05957,0.26465,0.08691c0.04297,0.01318,0.08594,0.03027,0.12842,0.04199
        c1.17188,0.32178,2.16162,0.02441,1.13916-1.56104c-1.81738-2.81982-1.82715-4.69092-0.61084-5.31982
        c1.21973-0.62305,1.8208-3.7373,1.82373-3.75439l-0.09131-0.01758c-0.01172-0.00244-0.02393-0.00439-0.03809-0.00732
        l-0.00684,0.00439c-0.5332-0.09863-2.646-0.54395-3.77197-1.55908c0,0,0,0-0.00049,0
        c-0.0874-0.0791-0.16748-0.16113-0.2417-0.24658c-0.01465-0.01709-0.03076-0.0332-0.04492-0.05029
        c-0.05225-0.06396-0.09717-0.13086-0.14111-0.19873c-0.02637-0.03906-0.05518-0.07715-0.07813-0.11768
        c-0.00635-0.01172-0.01025-0.02393-0.01611-0.03564c-0.04883-0.09131-0.09473-0.18359-0.12695-0.28125
        c-0.07813-0.24365-0.18799-0.4873-0.31836-0.73047c-0.55811-1.02588-1.49219-2.0415-2.10547-2.99414
        c-0.36133-0.55664-0.61426-1.09082-0.61426-1.59229c0-0.38672-0.13184-0.6377-0.35645-0.77539
        c-0.68604-0.40283-2.21729,0.24268-3.57861,1.41309c-1.82178,1.56641-5.1665-1.88574-9.11279,0.30908
        c-3.94629,2.2002-4.8584,4.7002-6.68701,4.38916c-1.81348-0.31494-1.52051,1.56592-3.02686,3.12793
        c-1.51367,1.56592-2.73975,3.44531-3.64502,3.44531c-0.1665,0.03223-0.3252,0.09277-0.48975,0.13379
        c-0.48438,0.14209-0.96387,0.2998-1.43408,0.48291c-0.2583,0.10986-0.52197,0.20752-0.76953,0.33984
        c-0.00391-0.00342-0.00732-0.00586-0.01074-0.00977c-0.03857,0.01709-0.07764,0.03271-0.11621,0.05029l-0.59521,0.28711
        c-0.1792,0.08984-0.36084,0.17578-0.53711,0.27148c0.021,0.04688,0.41309,0.92334,0.76025,2.08789
        c0.4585,1.53516,0.83398,3.56152,0.15137,4.79736c-1.22021,2.18994-4.2583,0-7.59619,0.93799
        c-3.32715,0.93994-4.85449-1.25-7.28418-0.625c-2.43262,0.625-3.94629-1.56494-5.1626-1.25195
        c-1.21338,0.31104-3.0376-2.82129-2.42578-5.32227c0.45313-1.87695-0.45898-4.28027-1.45654-4.5752
        c-0.33105-0.0957-0.6709,0.0415-0.97314,0.50928c-0.07666,0.11914-0.15723,0.21729-0.23779,0.31592
        c-0.00098,0.00098-0.00244,0.00244-0.00342,0.00391c-0.76855,0.93311-1.69482,1.01514-2.25977,1.64355
        c-0.00537-0.00049-0.01025,0-0.01563-0.00049c-0.17676,0.22607-0.30518,0.48096-0.39453,0.74756
        c-0.07617,0.27881-0.12256,0.61328-0.12256,1.04346c0,0.88184,0.14844,1.82422,0.29639,2.75977
        c0.08105,0.51465,0.16064,1.02637,0.21777,1.52637c0.04639,0.40918,0.07764,0.81055,0.07764,1.19629
        c-0.00049,1.0293-0.21484,1.95459-0.89893,2.65967c-4.13135,2.10986-8.90771,2.55762-13.35986,1.25
        c-0.11523-0.09863-0.25537-0.19482-0.41602-0.28906c-2.21875-1.30225-8.41357-2.18457-9.46191-3.49512
        c-0.01172-0.01416-0.01221-0.02979-0.02246-0.04443c-0.05566-0.07861-0.104-0.1582-0.12061-0.24023
        c-0.30811-1.56982-2.73682-4.38428-4.25732-4.38428c-1.52734,0-6.08154,2.18506-7.28809,1.24707
        c-1.84814-1.66895-4.02148-2.94678-6.38281-3.75293c-2.12891-0.31396-5.46289-1.87891-5.75684-3.13281
        c-0.01904-0.07813-0.05566-0.1543-0.1084-0.22754c-0.78809-1.10498-5.11523-1.72852-5.97021-2.9043
        c-0.11426-0.15674-0.26221-0.29834-0.43262-0.42969c-1.19336-0.91895-3.51367-1.32422-3.51367-2.69434
        c0-0.00049,0-0.00098,0-0.00146s0-0.00049,0-0.00098c0-1.56299,1.22021-4.0708,1.22021-5.94971
        c0-0.00488,0.00146-0.00977,0.00195-0.01465c0.00098-0.10547,0.01367-0.21094,0.03662-0.31592
        c0.00879-0.0415,0.02393-0.08252,0.03613-0.12402c0.02002-0.06934,0.04004-0.13867,0.06836-0.20801
        c0.01904-0.04688,0.04248-0.09375,0.06494-0.14063c0.03027-0.06396,0.06152-0.12744,0.09766-0.19043
        c0.02783-0.04883,0.05762-0.09717,0.08838-0.14551c0.03906-0.06152,0.08008-0.12256,0.12354-0.18359
        c0.03467-0.04883,0.07031-0.09814,0.10742-0.14648c0.04639-0.06006,0.09473-0.12012,0.14453-0.17969
        c0.04004-0.04883,0.08008-0.09766,0.12256-0.146c0.05078-0.05762,0.104-0.11523,0.15674-0.17285
        c0.04639-0.0498,0.09131-0.09961,0.13916-0.14941c0.04492-0.04688,0.09131-0.09277,0.1377-0.13916
        c0.37109-0.375,0.76953-0.73877,1.13232-1.08496c0.02002-0.01953,0.04004-0.03857,0.05957-0.05811
        c0.07373-0.07031,0.14355-0.13965,0.21289-0.2085c0.0376-0.0376,0.07471-0.0752,0.11084-0.1123
        c0.04932-0.05078,0.0957-0.10059,0.1416-0.15039c0.04346-0.04688,0.08643-0.09375,0.12646-0.13965
        c0.03125-0.03564,0.05859-0.07031,0.0874-0.10547c0.04639-0.05615,0.09229-0.1123,0.13232-0.16699
        c0.00879-0.01221,0.01563-0.02393,0.02393-0.03613c0.04883-0.06934,0.09473-0.13818,0.13135-0.20508
        c0.00098,0,0.00195-0.00049,0.00342-0.00049c0.01709-0.03076,0.02637-0.06055,0.04102-0.09082l0.0957-0.3125
        c0.00293-0.02734,0.01367-0.05518,0.01367-0.08154c0-0.15381-0.05127-0.32178-0.13916-0.49756
        c-0.64258-1.25879-3.19189-2.93262-3.19189-2.93262s-2.125-4.07275-4.55469-4.07275s-4.25-4.37305-6.37549-4.68799
        c-1.14844-0.1709-1.49756-1.53076-1.76855-2.99023c-0.23389-1.24512-0.41064-2.56592-0.97119-3.2876
        c-0.05371-0.06982-0.09814-0.14795-0.14404-0.22559c-0.01563-0.02734-0.03564-0.05127-0.05078-0.08008
        c-0.90381-1.69482,0.021-4.90137,2.01904-3.13525c1.99365,1.76318,5.59473,0.21191,4.76465-2.32178
        c-0.05273-0.15723-0.11865-0.31738-0.20703-0.48145c-1.23877-2.29688-3.28027-4.38037-3.99365-5.91992
        c-0.16699-0.35596-0.26367-0.68311-0.26367-0.97705c0-0.60107,0.26807-1.66406,0.479-2.81592
        c-0.00439,0-0.00928,0.00098-0.01367,0.00098c0.33789-1.84521,0.52588-3.92236-0.7793-4.69287
        c-2.13867-1.1333-3.76221-3.0332-4.55859-5.31738c-0.01123-0.03613-0.02783-0.07031-0.03955-0.10596
        c-0.37646-0.94385-0.94189-1.78662-1.6377-2.51172c-0.4165-0.41357-0.85449-0.80029-1.33887-1.13135l-3.03467,0.31201
        l-0.28613,0.75391c-0.00391-0.00244-0.00781-0.00439-0.01172-0.00684c-0.56348,1.42627-1.82715,4.40039-2.74609,4.87207
        c-0.00732,0.00244-0.01416,0.00488-0.02148,0.00684c-0.00049,0.00049-0.00146,0.00146-0.00244,0.00195
        c-0.65625,0.19971-1.30664,0.41455-1.95117,0.64502c-0.16113,0.05762-0.31787,0.12451-0.47803,0.18359
        c-0.47754,0.17725-0.95508,0.35449-1.42529,0.54883c-1.26416,0.52148-2.50244,1.10352-3.70996,1.74463
        c-0.13574,0.09961-0.2876,0.17676-0.45361,0.23584c-2.42627,0.89746-7.97266-2.41699-11.1123-2.41699
        c-0.62451,0-1.20654,0.12061-1.72998,0.32227c-2.26221,0.8877-3.42725,3.29248-2.19922,4.05518
        c1.52734,0.93799,1.21631,7.50586,1.21631,7.50586s1.82422,8.13525,4.56104,7.51709
        c2.5708-0.58301,5.66699,2.41602,4.00781,3.29688c-0.10498,0.05811-0.23145,0.10596-0.37695,0.14502
        c-2.36475,1.03613-3.91113,3.36084-3.93115,5.94482c-0.30762,2.50732,0.30029,5.63818-1.52002,5.94629
        c-0.00684,0.00098-0.01367,0.00244-0.02002,0.00342c-0.00293,0.00049-0.00488,0.00195-0.00781,0.00244
        c-0.56494,0.09766-1.12988,0.22607-1.64111,0.45898c-1.12451,0.51953-1.97705,1.55957-1.97705,3.91699
        c0,1.72559-0.98877,2.35156-2.05225,2.7041c-0.66211,0.22217-1.35449,0.33643-1.85889,0.54053
        c-0.29932,0.12402-0.52979,0.28174-0.64355,0.51563c-0.82715,2.87842-1.84521,5.69824-3.03369,8.44775
        c-0.17285,0.17773-0.49805,0.33301-0.91357,0.48682c-0.95264,0.35742-2.39355,0.70703-3.56299,1.30859
        c-0.99756,0.51758-1.79102,1.22168-1.90234,2.27686c-0.26709,2.47266-2.17285,5.42676-3.66016,5.89697
        c-0.21533,0.07227-0.42236,0.09424-0.61475,0.05469c-1.1377-0.23486-2.95605-0.99414-4.17871-0.57568
        c-0.40234,0.14258-0.73926,0.41455-0.96338,0.87891c-0.11621,0.24072-0.2832,0.39209-0.48633,0.47852
        c-1.27441,0.57715-4.05811-1.56689-5.08447-1.22314c-0.07227,0.02686-0.13721,0.06396-0.18994,0.11865
        c-0.90918,0.93994-5.77051,5.00879-5.77051,7.51025c0,2.50586,5.1665,2.50586,4.86133,5.63281
        c0.01367,3.0459,1.21045,5.96875,3.3418,8.14209c1.51367,1.56299,3.93945,5.3208,2.72705,7.20068
        c-0.45654,0.70264-0.99902,1.18555-1.64355,1.48193c-1.0708,0.50098-2.42432,0.47852-4.13818,0.08643
        c-0.84473-0.19434-1.40039-0.14893-1.9082-0.02197c-0.41064,0.10498-0.79297,0.26221-1.27441,0.38818
        c-0.83984,0.22461-1.97852,0.3584-4.11523-0.05225c-1.51221-0.29395-2.58203-0.13037-3.39453,0.30029
        c-1.77734,0.95752-2.31152,3.1958-3.56201,4.70166l-0.49219,0.57422c2.2998,1.86914,3.25977,5.20313,7.07617,6.7207
        c4.43164,1.75244,8.4126-2.93457,8.4126,0.46191c0,3.39941-10.45898,2.69482-10.68457,3.75781
        c-0.22217,1.06592,10.80029,14.76758,16.37305,13.71436c5.57227-1.05566,9.66211-7.26709,8.07031-8.20996
        c-1.58838-0.93896,0.91553-4.80029,2.38818-4.09717c1.47607,0.70459,0.22607,6.80029,1.9375,8.20361
        c1.70166,1.40479,1.13135,3.98096,0,7.26416c-0.22168,4.50293,0.26953,9.01367,1.47314,13.35938
        c0.3374,3.63184,2.72559,8.90723,3.52881,14.29541c1.43555,7.35742,3.8374,14.4873,7.14453,21.21045
        c3.06787,5.97656,5.44971,17.69336,7.49609,20.50586c3.70752,4.8916,6.2085,10.59863,7.28125,16.64258
        c0.854,5.39844,3.49854,10.34863,7.51025,14.05957c1.92627,1.4082,6.13623-1.63672,7.15723-4.9209
        c1.0293-3.28223,6.82373-4.56641,7.16553-7.38281c0.33789-2.81543,0.90527-5.03223,2.95166-5.38867
        c2.04736-0.34863,2.04736-2.5752,2.04736-5.62402c-0.27051-3.5127,0.52197-7.02637,2.27539-10.08203
        c2.00879-3.99121,2.41846-8.59961,1.14111-12.88477c-1.00439-3.28027-0.80957-6.82031,0.56055-9.96436
        c1.14111-2.92773,0.68652-6.32715,2.84961-6.55908c3.12891-0.76855,5.85596-2.69629,7.61914-5.39453
        c3.5874-3.56348,7.50977-6.78613,11.70557-9.6123c4.61963-3.80225,8.78076-8.12793,12.39941-12.89111
        c2.2749-3.86426,8.75-5.14893,11.7124-8.31641c2.96289-3.16357,1.81787-4.8042,2.39111-8.21045
        c0.57178-3.39941,10.11035-4.79688,13.75635-4.79688l1.90967-0.19141c0-0.00049,0-0.00098,0-0.00146
        c-0.10498-3.79248-0.67578-7.55176-1.68213-11.20166C256.69873,317.53271,256.35547,316.43115,255.96826,315.34277z"/>
      <path class="agm-0" d="M120.24512,167.19678c-0.59473,0.02148-1.19043,0.02344-1.78711,0.00342
        c-0.22021-0.00488-0.43896-0.02197-0.65869-0.03613c-0.20752-0.01221-0.41553-0.01709-0.62305-0.03418
        c-2.09619-0.30908-5.65771,3.02832-5.4624,4.62109c0.00244,0.021-0.00146,0.04492,0.00244,0.06543
        c0.30127,1.56592,8.19678-0.94141,10.93018,0.93799c2.59082,1.78516,5.18506-1.5083,6.73926,0.0376
        c0.08398,0.08252,0.16699,0.16943,0.24512,0.28125c0.09375,0.13477,0.19434,0.25537,0.30078,0.36328
        c1.62793,1.63525,4.64893,0.29639,6.06787,0.88379c3.35352,0.15723,6.71436-0.05664,10.02051-0.62646
        c0.85254-1.12744,1.29248-2.51025,1.25049-3.92285c0-2.10986,1.70605-1.58008,3.41357-2.10986
        c0.58545-0.18066,1.1084-0.57422,1.60107-1.00684h-27.28076C123.44727,167.01465,121.85107,167.19434,120.24512,167.19678z"/>
      <path class="agm-1" d="M179.37891,213.97119c0.01172,0.03564,0.02832,0.06982,0.03955,0.10596
        c0.79639,2.28418,2.41992,4.18408,4.55859,5.31738c1.30518,0.77051,1.11719,2.84766,0.7793,4.69287
        c0.00439,0,0.00928-0.00098,0.01367-0.00098c1.09521-0.01953,2.18799-0.13184,3.26318-0.3457
        c2.2168-0.52783,6.98975-7.55957,7.67041-9.49219c0.6001-1.69141-4.3999-6.06641-7.61133-7.25928
        c-0.45508-0.16748-0.87402-0.27148-1.23486-0.29346c-3.57764,0.18262-6.8335,2.10986-8.71631,5.15967
        c-0.00439-0.00488-0.01025-0.00928-0.01465-0.01465c-0.00146,0.00293-0.00391,0.00488-0.00537,0.00781
        C178.68945,212.45508,179.11865,213.18262,179.37891,213.97119z"/>
      <path class="agm-0" d="M527.39746,167.32666c-0.97754,0.88037-1.67578,2.02881-1.9834,3.31299
        c-0.00488-0.00049-0.00977,0-0.01465-0.00049c0,0.00098-0.00098,0.00244-0.00098,0.00342
        c3.39648,0.32959,6.53125,1.92725,8.80469,4.46484c2.1543,2.81445-2.51367,5.97803-4.55469,8.55322
        c-2.04688,2.57764,1.25391,2.69727,1.36719,4.80713c0.10742,2.11182,2.8457,3.86768,3.86523,1.99365
        c1.02734-1.87305,2.73438-1.17578,5.91211-1.17578c0.76953-0.03076,1.47656,0.3916,1.81445,1.07764
        c1.77344-1.47168,3.83789-2.80273,4.53516-3.51514c2.09375-1.27783,4.54492-1.82861,6.97852-1.56299
        c1.50781,0.01855,2.96289-0.54883,4.0625-1.57715c-0.00195-0.00195-0.00391-0.00391-0.00488-0.00586
        c-2.04785-2.41797-4.33301-4.62891-6.81348-6.59863c-2.61133-1.64209-1.02148-6.21143,2.15625-7.03174
        c2.06445-1.05762,4.07617-2.20654,6.0498-3.41748h-30.9209c-0.3623,0.16406-0.71582,0.3457-1.08008,0.50439
        C527.50781,167.20996,527.45801,167.27295,527.39746,167.32666z"/>
      <path class="agm-1" d="M199.01367,258.99756c-0.03662,0.06689-0.08252,0.13574-0.13135,0.20508
        c-0.0083,0.01221-0.01514,0.02393-0.02393,0.03613c-0.04004,0.05469-0.08594,0.11084-0.13232,0.16699
        c-0.02881,0.03516-0.05664,0.06982-0.0874,0.10547c-0.04004,0.0459-0.08301,0.09277-0.12646,0.13965
        c-0.0459,0.0498-0.09277,0.09961-0.1416,0.15039c-0.03613,0.03711-0.07324,0.07471-0.11084,0.1123
        c-0.06885,0.06885-0.13965,0.13818-0.21289,0.2085c-0.01953,0.01953-0.03955,0.03857-0.05957,0.05811
        c-0.3623,0.34619-0.76074,0.70947-1.13232,1.08496c-0.04639,0.04639-0.09277,0.09229-0.1377,0.13916
        c-0.04785,0.04932-0.09277,0.09961-0.13916,0.14941c-0.05273,0.05762-0.10596,0.11523-0.15674,0.17285
        c-0.04248,0.04834-0.08252,0.09717-0.12256,0.146c-0.0498,0.05957-0.09814,0.11963-0.14453,0.17969
        c-0.03711,0.04834-0.07275,0.09766-0.10742,0.14648c-0.04346,0.06104-0.08447,0.12207-0.12354,0.18359
        c-0.03076,0.04834-0.06055,0.09668-0.08838,0.14551c-0.03613,0.06299-0.06738,0.12646-0.09766,0.19043
        c-0.02246,0.04688-0.0459,0.09375-0.06494,0.14063c-0.02832,0.06934-0.04834,0.13867-0.06836,0.20801
        c-0.01221,0.0415-0.02734,0.08252-0.03613,0.12402c-0.02295,0.10498-0.03564,0.21045-0.03662,0.31592
        c-0.00049,0.00537-0.00195,0.01123-0.00195,0.0166c0,1.87646-1.21924,4.3833-1.22021,5.94873c0,0.00049,0,0.00098,0,0.00146
        c0,1.37012,2.32031,1.77539,3.51367,2.69434c0.17041,0.13135,0.31836,0.27295,0.43262,0.42969
        c0.85498,1.17578,5.18213,1.79932,5.97021,2.9043c0.05273,0.07324,0.08936,0.14941,0.1084,0.22754
        c0.2998,1.25586,3.63916,2.81885,5.75684,3.13281c2.36133,0.80615,4.53467,2.08398,6.38281,3.75293
        c1.20654,0.93799,5.76074-1.24707,7.28809-1.24707c1.52051,0,3.94922,2.81445,4.25732,4.38428
        c0.0166,0.08203,0.06494,0.16162,0.12061,0.24023c0.01025,0.01465,0.01074,0.03027,0.02246,0.04443
        c1.04834,1.31055,7.24316,2.19287,9.46191,3.49512c0.16064,0.09424,0.30078,0.19043,0.41602,0.28906
        c4.45215,1.30762,9.22852,0.85986,13.35986-1.25c0.68408-0.70508,0.89844-1.63037,0.89893-2.65967
        c0-0.38574-0.03125-0.78711-0.07764-1.19629c-0.05713-0.5-0.13672-1.01172-0.21777-1.52637
        c-0.14795-0.93506-0.29639-1.87549-0.29639-2.75439c-0.03809-0.35742,0.01025-0.71338,0.12256-1.04883
        c0.08936-0.2666,0.21777-0.52148,0.39453-0.74756c-0.58594-0.04883-1.17139-0.06396-1.75439-0.04639
        c-4.43164,0.17627-7.16455-2.10742-10.05469-1.93311c-5.38818-0.77783-10.49316-2.89453-14.84277-6.14844
        c-3.35791-2.80273-12.12549-8.54199-15.16357-11.27393c-0.20703-0.18457-0.38867-0.35645-0.54004-0.51172
        c-0.44385-0.45752-0.92285-0.8291-1.41113-1.12451c-2.13428-1.28369-4.43994-1.09619-4.71777-0.09717
        c-0.3042,1.12549-2.06055,1.22168-4.71191,1.50195l0.00293-0.00928c-0.00488,0.00049-0.00928,0.00098-0.01367,0.00146
        C199.01563,258.99707,199.01465,258.99756,199.01367,258.99756z"/>
      <path class="agm-1" d="M333.05615,408.43652c0-3.12793-2.41943-3.44043-2.41943-5.94727
        c0.37598-3.51465-0.72119-7.02637-3.04102-9.69531c-3.04102-2.81934-4.55713-6.896-2.73291-8.45703
        c1.59863-1.37744,0.62549-4.92041,0.9834-5.55371c0.04932-0.0957,0.125-0.13232,0.23975-0.09375
        c0.28418,0.0957,0.59863-0.14258,0.84131-0.54346c0.52881-0.89063,0.70605-2.56738-0.54785-3.20459
        c-1.82471-0.93311,0-4.6875-1.5166-5.31738c-2.57324-1.79346-4.57227-4.29443-5.76074-7.19824
        c0-1.56201,1.20264-10.3252,1.50635-12.51611c0.05957-0.41211,0.21484-0.75732,0.43359-1.04736
        c0.93604-1.25781,3.09277-1.4707,3.84082-1.4707c0.74365,0,3.28516,0.20703,4.86426-0.88818
        c0.36182-0.25244,0.67334-0.57324,0.90039-0.98096c0.15186-0.27393,0.36523-0.51855,0.62354-0.73975
        c1.54346-1.32861,4.71094-1.82227,5.93018-2.7959c0.20068-0.1626,0.34717-0.33789,0.42236-0.5332
        c0.01367-0.02588,0.03223-0.04736,0.04639-0.07275c0.10254-0.22754,0.26465-0.42139,0.46631-0.59229
        c0.01514-0.01416,0.03076-0.02734,0.04639-0.04102c0.04248-0.03467,0.08789-0.06787,0.13379-0.10059
        c0.10986-0.08398,0.22607-0.15869,0.3501-0.22363c0.55908-0.32031,1.22998-0.57666,1.79004-0.89453
        c0.021-0.01221,0.04248-0.02441,0.06348-0.03662c0.07813-0.0459,0.15283-0.09375,0.22559-0.14258
        c0.06299-0.04297,0.12109-0.08838,0.1792-0.13379c0.04102-0.03271,0.08594-0.06396,0.12451-0.09814
        c0.09375-0.08447,0.17578-0.17529,0.24756-0.27148c0.01953-0.02588,0.03467-0.05371,0.05225-0.08057
        c0.05566-0.08545,0.10303-0.17578,0.13867-0.27246c0.00928-0.02441,0.01904-0.04883,0.02686-0.07373
        c0.03809-0.12598,0.06299-0.25928,0.06299-0.40674c0-1.875,3.93945-6.57715,3.93945-6.57715
        c0.00293-0.00049,0.00537,0,0.0083-0.00049c0.00049-0.00049,0.00537-0.00635,0.00537-0.00635l0.03174,0.00439
        c0.03467-0.00537,0.06689-0.00439,0.09961-0.00586c-0.45459-0.78125-1.45996-0.03711-3.43896,0.77686
        c-3.41309,1.41162-5.12158-0.52588-4.77734-2.63086c0.34863-2.11182-2.37793-2.11182-4.25977-2.11182
        c-1.87598,0-0.67627-3.15967,0-5.26807c0.68652-2.11816-0.67627-3.34131-2.89844-3.52246
        c-2.21631-0.17432-3.23877-5.09814-2.39063-6.50586c0.85742-1.4043-4.60303-2.6377-6.9873-1.4043
        c-1.04932,0.5415-1.30908,0.20215-1.3291-0.49756c-0.01758-0.5957,0.14063-1.45459,0.13525-2.25439
        c-0.00342-0.39941-0.04834-0.78369-0.17676-1.1123c-0.04297-0.11133-0.06738-0.24023-0.07568-0.38379
        c-0.00439-0.06543,0.00635-0.14453,0.00928-0.21631c0.00293-0.08154-0.00098-0.15625,0.01074-0.24561
        c0.04053-0.3125,0.13672-0.67383,0.27148-1.06543c0.01123-0.03271,0.01807-0.06201,0.02979-0.09521
        c0.01904-0.05225,0.04492-0.11035,0.06494-0.16406c0.81641-2.16992,2.81104-5.24414,4.84277-7.62402
        c0.38867-0.45752,0.77881-0.89111,1.16113-1.28613c1.21484-1.25146,1.78369-2.65088,2.02002-4.11523
        c0.39111-2.44238-0.14111-5.06641-0.14111-7.48242c0-3.86816-1.5376-3.86816-3.06787-3.86816
        c-1.53418,0-1.70557-2.28516-1.70557-4.396c-0.00391-0.02148-0.01318-0.0415-0.01758-0.06299
        c-0.03857-0.19629-0.09033-0.38672-0.16016-0.56787c-0.04736-0.12158-0.11279-0.23486-0.17383-0.35059
        c-0.04004-0.07617-0.07275-0.15771-0.11865-0.22998c-0.10791-0.1709-0.23145-0.32568-0.36279-0.4707
        c-0.01709-0.01855-0.03027-0.04053-0.04736-0.05908c-0.70166-0.7373-1.69043-1.09277-2.67676-1.02881
        c-0.10986,0.00781-0.21875,0.00635-0.32959,0.0249c-0.17188,0.02832-0.34082,0.07666-0.50879,0.13281
        c-0.03711,0.01221-0.07666,0.01709-0.11328,0.03076c-0.2085,0.07764-0.4126,0.17627-0.60889,0.29834l-1.2876,0.7832
        l0.0376,0.00732l0.09131,0.01758l0.00732,0.00146c0,0-0.60498,3.13281-1.81836,3.75781
        c-1.21924,0.62598-1.21924,2.50684,0.60498,5.31934c1.02588,1.58447,0.0293,1.87988-1.146,1.55664
        c-0.04248-0.01172-0.08545-0.02881-0.12842-0.04199c-0.08789-0.02734-0.17627-0.05322-0.26465-0.08691
        c-0.11572-0.04346-0.23047-0.09424-0.34375-0.14746c-0.01563-0.00781-0.03223-0.01318-0.04785-0.021
        c-0.64551-0.31299-1.22119-0.77051-1.41113-1.25928c-0.07715-0.19971-0.2041-0.33203-0.37061-0.40918
        c-1.14014-0.53027-4.15869,1.58691-6.00439,3.22607c-2.12549,1.87891-5.16357,2.81787-5.16357,6.26367
        c0,3.44434-2.73242,6.88721-2.12744,9.70605c0.60107,2.81494-3.03467,8.1333-2.73096,9.69434
        c0.30127,1.57178-5.16943-0.31006-6.07471-0.31006c-0.91895,0-0.31104,5.94092-1.52344,7.82715
        c-1.21338,1.87598-0.29688,8.43896-2.12549,8.43896c-0.00488,0-0.00928,0.00244-0.01367,0.00293
        c-0.39404,0.00635-0.77197,0.2959-1.12451,0.73486c-0.69336,0.8623-1.28174,2.31396-1.67529,3.47363
        c-0.33154,0.97656-0.52734,1.74023-0.52734,1.74023c-0.97803,0.70459-1.8999,1.47705-2.75488,2.31689
        c1.45898,3.34912,4.00195,6.10938,7.2168,7.8418c4.43457,1.87598,7.39307,11.13135,8.76025,16.05518
        c1.20654,4.25342,0.79932,8.80225-1.14111,12.77832c-1.59277,2.81299,0.57031,2.92578,3.18457,4.33252
        c2.61377,1.40039,5.56543,1.87207,7.50293-0.93896c1.93359-2.81348,5.91455-4.80029,6.48486-7.96777
        c0.56738-3.16113,2.38525,1.52344,3.98096,3.16748c1.9375,2.52002,3.01709,5.59863,3.06738,8.78809
        c0.33887,3.9834,0.68359,13.94189,3.97119,17.104c2.87354,3.50391,3.95996,8.12891,2.96533,12.53809
        c-0.90918,2.46484,1.70508,6.68359,1.02148,9.37988l-0.44775,2.15527c0.08936,0.01758,0.1792,0.02051,0.26953,0.0127
        C329.65625,421.22852,333.05615,408.43652,333.05615,408.43652z"/>
      <path class="agm-1" d="M259.59229,286.05273c1.21631-0.31445,2.73682,1.87793,5.16357,1.25293
        c2.41895-0.62891,3.94922,1.56104,7.28711,0.625c3.33789-0.93994,6.37598,1.25195,7.59229-0.93994
        c0.68164-1.23145,0.30957-3.25146-0.14746-4.78564c-0.34717-1.16455-0.73926-2.04102-0.76025-2.08789
        c-0.00049-0.00098-0.00439-0.00977-0.00439-0.00977l0.5415-0.26172l0.59521-0.28711l0.11182-0.05371
        c-4.5625-4.32422-5.81055-0.8374-8.86084-2.49463c-3.24268-1.75732-4.77734-2.28223-6.31152-1.05615
        c-1.5332,1.23242-3.92236,1.05615-5.45557,4.92188c-0.4585,1.17578-1.04199,2.30029-1.73633,3.34912
        c-0.00488-0.01025-0.00732-0.021-0.01221-0.03125c-0.00293,0.00488-0.00537,0.01025-0.00879,0.01514
        C258.12354,285.3916,258.93652,286.21973,259.59229,286.05273z"/>
      <path class="agm-1" d="M373.77002,321.41748c-0.229,0.4126-0.4585,0.64697-0.69727,0.76709
        c-0.79297,0.41113-1.69971-0.46094-3.09033-0.3042c-0.41455,0.04883-0.87109,0.18799-1.38135,0.47998
        c-0.34277,0.19678-0.68555,0.30908-1.02539,0.35986c-1.86035,0.28662-3.62988-1.27588-4.8208-1.03906
        c-0.32178,0.06885-0.60107,0.27002-0.82764,0.6792c-0.30469,0.55029-0.72461,0.74414-1.20703,0.75684
        c-1.19873,0.04248-2.78662-1.05176-3.93848-0.60596c-0.22754,0.09033-0.43848,0.23975-0.62598,0.47119
        c-0.04102,0.05078-0.09131,0.09326-0.13428,0.14258c-0.12451,0.14209-0.25195,0.28125-0.38818,0.41211
        c-0.09717,0.09375-0.19775,0.17969-0.30029,0.2666c-0.09961,0.08496-0.20117,0.1665-0.30566,0.24512
        c-0.11084,0.08398-0.2207,0.16553-0.33496,0.24121c-0.07813,0.05176-0.16016,0.09814-0.24072,0.14648
        c-0.1333,0.08057-0.26465,0.1665-0.39941,0.23535c0.45459,0.52441,0.91846,1.04199,1.3374,1.54492
        c0.80762,0.96777,1.43994,1.87695,1.43994,2.63281c0,2.0127,3.8916,3.51221,3.8916,6.02686
        c0,2.51123,4.87207,4.52002,6.33887,3.51563c0.36475-0.25098,0.85107-0.50195,1.38379-0.68164
        c1.59717-0.54297,3.60938-0.45459,3.97656,2.18799c0.48535,3.51953,2.43652,3.50928,2.43652,6.02441
        c0,2.51758-4.87305,1.00439-5.8457,3.51514c-0.97852,2.51221,8.27832,5.52197,8.27832,7.53076
        c0,0.81152,0.48047,1.70801,1.11377,2.65234c0.93262,1.38574,2.19922,2.87646,2.78174,4.37256
        c0.67139,1.73096,2.95166,3.69385,4.62646,5.73047c0.76221,0.92676,1.39941,1.86865,1.7041,2.81201
        c0.60986,1.87988,2.35889,3.17578,3.94189,4.25049c0.95166,0.64453,1.84375,1.2085,2.39209,1.77002
        c0.38965,0.59424,0.71436,1.22168,0.97314,1.87207c0.77588,1.94385,0.95459,4.09033,0.4917,6.1626l-0.23438,3.19971
        c-0.0083,0.00684-0.01758,0.0127-0.02588,0.01953c0,0.00244,0,0.00488,0,0.00732c0.01123-0.00977,0.02441-0.01709,0.03564-0.02686
        c-0.01465,0.74951,0.02539,1.49463,0.10156,2.23438c0.30371,2.59668,1.12451,5.12109,2.44385,7.42041
        c0.03906,0.06592,0.07275,0.13477,0.11279,0.20068c1.46143,2.26367,0.60693,3.89453-0.85547,5.27441
        c-1.02832,0.97168-2.35254,1.81934-3.39014,2.67285c-0.43506,0.3584-0.82471,0.71875-1.11182,1.09082
        c-1.94727,2.51953-0.9707,6.03223-6.82178,4.52051c-1.27881-0.3291-1.95166-0.27344-2.2334,0.03418
        c-0.99756,1.10156,2.99609,5.42773,2.2334,6.99023c-0.18213,0.37695-0.60498,0.54102-1.1709,0.57324
        c-1.88623,0.10938-5.36572-1.25098-6.87158-1.14453c-0.45215,0.03223-0.72607,0.19629-0.72607,0.57129
        c0,1.20996-2.62354,2.7793-4.73193,3.84375c0.96191,1.07715,2.11816,1.97754,3.39697,2.64844
        c1.82422,1.16895-2.27051,3.16113-2.15723,6.90918s2.84277,4.69141,3.86523,3.16016
        c1.92676-2.26465,4.28613-4.13867,6.93945-5.50098c1.70508-0.58691,0-3.16406,1.81641-3.04297
        c1.82275,0.1084,1.25195-2.7041,2.04736-4.22754c0.79492-1.51758,2.04688-0.9375,4.77734-0.81641
        c3.86133-0.81641,7.46289-2.58691,10.45703-5.15625c3.86523-2.5791,4.55078-3.16797,6.02539-10.54688
        c0.48047-6.19531-0.13086-12.42383-1.81836-18.39648c-0.10938-2.23047-5.34375-12.5459-7.27539-12.43115
        c-1.92969,0.11719-10.80273-9.25439-12.40039-10.77197c-1.59717-1.51758-1.13916-4.104-3.40771-6.0957
        c-2.14551-3.15381-2.84277-7.07227-1.93164-10.77295c0.57227-3.63184,5.56592-4.45898,5.4585-6.91553
        c-0.11914-2.46338,0.45313-4.57129,2.94727-4.68799c2.5293-0.37842,4.75-1.88916,6.02734-4.10254l0.65234-0.57764
        c-0.62109-0.75879-1.16992-1.24707-1.56445-1.3125c-2.12305-0.31104-7.5918-4.37988-7.5918-5.94189
        c0-1.55811,2.73438-4.06934-2.12939-4.69824c-2.56934-0.00977-4.96484-1.29736-6.375-3.44385
        c-0.39795-0.40576-1.37549-0.09766-2.45166,0.56006C375.99268,318.76074,374.4502,320.18799,373.77002,321.41748z"/>
      <path class="agm-1" d="M376.80029,420.22949c0-0.375,0.27393-0.53906,0.72607-0.57129
        c1.50586-0.10645,4.98584,1.25293,6.87158,1.14453c0.56592-0.03223,0.98877-0.19629,1.1709-0.57324
        c0.7627-1.5625-3.23096-5.88867-2.2334-6.99023c0.27979-0.30957,0.95264-0.36523,2.2334-0.03418
        c5.85107,1.51172,4.87451-2.00098,6.82178-4.52051c0.28711-0.37207,0.67676-0.73242,1.11182-1.09082
        c1.03662-0.85547,2.36084-1.70215,3.39014-2.67285c1.4624-1.37988,2.31689-3.01074,0.85547-5.27441
        c-0.04004-0.06592-0.07373-0.13477-0.11279-0.20068c-1.34668-2.27686-2.17432-4.80566-2.44385-7.42041
        c-0.07617-0.73975-0.11621-1.48486-0.10156-2.23438c-0.01123,0.00977-0.02441,0.01709-0.03564,0.02686
        c-1.12451,0.9502-2.55029,1.42383-3.98486,1.35156c-0.48438-0.02344-0.96924-0.10889-1.44238-0.25928
        c-1.63672-0.67969-5.24854-1.00977-5.24854,1.69141c0,0.16895-0.01025,0.32959-0.0293,0.48242
        c-0.24854,1.98779-1.99414,2.64014-2.26172,1.96191c-0.04248-0.10645-0.0498-0.24463-0.00781-0.41699
        c0.03125-0.1333,0.02734-0.24854,0-0.35352c-0.00195-0.00781-0.00293-0.01611-0.00537-0.02344
        c-0.03125-0.10156-0.0874-0.19141-0.1665-0.27344c-0.00684-0.00635-0.01465-0.0127-0.02148-0.01953
        c-0.07764-0.0752-0.1748-0.14404-0.2876-0.20898c-0.01465-0.00879-0.02832-0.01758-0.04395-0.02588
        c-0.08887-0.04883-0.19385-0.09473-0.29883-0.14111c-0.93555-0.40088-2.4375-0.75537-3.44092-1.99902
        c-1.47168-1.81641-6.36279,0.15479-9.49316-0.1416c-0.36377-0.03418-0.70898-0.0918-1.0166-0.19727
        c-0.36963-0.12695-0.76465-0.19531-1.1792-0.21143c-2.896-0.10938-6.73828,2.36914-9.31982,5.62744
        c-2.95898,3.7207,0.32715,3.7207,0.32715,7.10449c0,0.21094,0.00781,0.40918,0.02197,0.5957
        c0.07471,0.9834,0.33057,1.63867,0.58984,2.18848c0.479,1.01172,0.97266,1.65723,0.37158,3.30371l-0.06738,0.23926
        c2.89355,1.31152,1.75586,5.03613,2.07324,7.1416c0.3418,2.22461,3.18457,0.93457,4.78027,0.81641
        c1.58203-0.11328-1.02539,2.5752,0.33496,4.21973c1.36426,1.64355,5.34473-1.40527,6.14063,0.58789l0.68555,1.2168
        c0.00146-0.00098,0.00293-0.00195,0.00439-0.00195C374.17676,423.00879,376.80029,421.43945,376.80029,420.22949z"/>
      <path class="agm-1" d="M345.52539,331.43506c-0.00293,0.00049-0.00537,0-0.0083,0.00049c0,0-3.93945,4.70215-3.93945,6.57715
        c0,0.14746-0.0249,0.28076-0.06299,0.40674c-0.00781,0.02539-0.01758,0.04932-0.02686,0.07373
        c-0.03564,0.09668-0.08301,0.18701-0.13867,0.27246c-0.01758,0.02686-0.03271,0.05469-0.05225,0.08057
        c-0.07178,0.09619-0.15381,0.18701-0.24756,0.27148c-0.03809,0.03467-0.0835,0.06543-0.12451,0.09814
        c-0.05811,0.04541-0.11621,0.09082-0.1792,0.13379c-0.07227,0.04883-0.14795,0.09619-0.22559,0.14258
        c-0.021,0.01221-0.04248,0.02441-0.06348,0.03662c-0.48779,0.27881-1.05811,0.51172-1.56836,0.77979l0.00098,0.00098
        c0.00049-0.00049,0.00146-0.00049,0.00195-0.00098l1.50098,1.54785c0.66211,1.00879-0.3252,3.71777,0.65527,6.08203
        c0.71484,1.70996,2.62744,0.25293,4.00488,0.58984c0.52686,0.12842,0.97803,0.51123,1.25,1.44678
        c0.30908,1.05566,0.36035,1.94727,0.28564,2.77783c-0.0127,0.14014-0.03027,0.27734-0.04932,0.41455
        c-0.00342,0.02393-0.00537,0.04883-0.00879,0.07275c-0.06689,0.45313-0.16602,0.89355-0.27295,1.33789
        c-0.27539,1.14648-0.60693,2.32715-0.60693,3.84961c0,3.38281-2.62402,3.04395-1.97852,6.08887
        c0.1665,0.76074,0.51709,1.09912,0.98975,1.14697c1.41699,0.13916,3.93652-2.33008,5.90576-3.85303
        c1.31104-1.01709,2.46045-1.43945,3.40527-1.43799c0.94531,0.00049,1.68506,0.42578,2.17383,1.10596
        c0.9873,1.34619,4.26758,0.3252,4.92383-1.35986c0.41113-1.06543,2.51807-1.7251,4.43213-1.56006
        c1.12402,0.09473,2.18213,0.47217,2.79053,1.22217c1.5127,2.23096,3.40332,4.1792,5.58008,5.75391
        c2.2959,1.35303-1.63574,8.79443,0.65625,9.47168c3.31348,1.48584,5.39355,4.83398,5.25488,8.45898
        c-0.27734,3.48877,1.60938,5.979-0.99219,8.98193l-0.00098-0.00098c-0.00049,0.00049-0.00049,0.00049-0.00098,0.00098
        c0.86182,0.57813,1.8042,0.84521,2.46631,1.13574c0.10498,0.04639,0.20996,0.09229,0.29883,0.14111
        c0.01514,0.0083,0.0293,0.01709,0.04395,0.02588c0.11279,0.06494,0.20996,0.13379,0.2876,0.20898
        c0.00684,0.00684,0.01465,0.01318,0.02148,0.01953c0.0791,0.08203,0.13525,0.17188,0.1665,0.27344
        c0.00244,0.00732,0.00342,0.01563,0.00537,0.02344c0.02734,0.10498,0.03125,0.22021,0,0.35352
        c-0.04199,0.17236-0.03467,0.31055,0.00781,0.41699c0.27051,0.67676,2.01318,0.021,2.26172-1.96191
        c0.01904-0.15283,0.0293-0.31348,0.0293-0.48242c0-2.70117,3.61182-2.37109,5.24854-1.69141
        c0.47314,0.15039,0.95801,0.23584,1.44238,0.25928c1.43604,0.0708,2.86768-0.4043,3.98486-1.35889
        c0.0083-0.00684,0.01758-0.0127,0.02588-0.01953l0.23438-3.19971c0.46289-2.07227,0.28418-4.21875-0.4917-6.1626
        c-0.25879-0.64795-0.5835-1.27344-0.97314-1.86621c-0.54883-0.56445-1.44043-1.13037-2.39209-1.77588
        c-1.58301-1.07471-3.33203-2.37061-3.94189-4.25049c-0.30469-0.94336-0.94189-1.88525-1.7041-2.81201
        c-1.67578-2.03711-3.95508-3.99756-4.62646-5.72363c-0.58301-1.49463-1.84961-2.99023-2.78174-4.37939
        c-0.6333-0.94434-1.11377-1.84082-1.11377-2.65234c0-2.00879-9.25684-5.01855-8.27832-7.53076
        c0.97266-2.51074,5.8457-0.99756,5.8457-3.51514c0-2.51514-1.95117-2.50488-2.43652-6.02441
        c-0.36719-2.64258-2.37939-2.73096-3.97656-2.18799c-0.53223,0.18066-1.01807,0.43164-1.38184,0.68164
        c-1.46484,1.00439-6.34082-1.00049-6.34082-3.51563c0-2.51465-3.8916-4.01416-3.8916-6.02686
        c0-0.75586-0.63232-1.66504-1.43994-2.63281c-0.41992-0.50293-0.88428-1.02051-1.3374-1.54248
        c-0.20557,0.10547-0.41113,0.19141-0.61572,0.26904c-0.05713,0.02148-0.11279,0.04199-0.16943,0.06104
        c-0.20996,0.07178-0.4165,0.13037-0.61572,0.16846c-0.02441,0.00439-0.04688,0.00586-0.07129,0.01025
        c-0.20068,0.03467-0.3916,0.0498-0.57129,0.04639c-0.01563,0-0.03223,0.00098-0.04834,0.00049
        c-0.19434-0.00732-0.37109-0.04004-0.52979-0.09619c-0.00781-0.00293-0.01563-0.00635-0.02393-0.00977
        c-0.16064-0.06006-0.30566-0.13965-0.41602-0.25537c-0.13721-0.14111-0.28076-0.15869-0.42334-0.10156
        c-0.80273,0.33838-1.55664,3.36768-0.78369,4.16064c0.7959,0.82275,1.82764,5.48438,0.43701,6.2124
        c-0.19922,0.10693-0.44727,0.1333-0.75439,0.0542c-1.0166-0.26172-1.60547-1.01465-2.06006-1.78027
        c-0.00488-0.00781-0.00977-0.01563-0.01465-0.02393c-0.04053-0.06885-0.08057-0.13721-0.11963-0.20654
        c-0.51807-0.90576-0.88477-1.75586-1.5918-1.73438c-0.00537-0.00977-0.0127-0.0127-0.01807-0.02197
        c-0.03271,0.00146-0.06494,0.00049-0.09961,0.00586C345.5498,331.43506,345.53857,331.43262,345.52539,331.43506z"/>
      <path class="agm-1" d="M357.1333,403.76563c0-3.38379-3.28613-3.38379-0.32715-7.10449
        c2.58154-3.2583,6.42383-5.73682,9.31982-5.62744c0.41455,0.01563,0.81006,0.08447,1.1792,0.21143
        c0.30762,0.10547,0.65283,0.16309,1.0166,0.19727c3.12695,0.29443,8.01807-1.67969,9.49316,0.1416l0.97559,0.8623l0.00098,0.00098
        c2.60156-3.00293,0.71484-5.49316,0.99219-8.98193c0.13867-3.625-1.94141-6.97314-5.25488-8.45898
        c-2.29199-0.67725,1.63965-8.11865-0.65625-9.47168c-2.17676-1.57471-4.06738-3.52295-5.58008-5.75391
        c-0.6084-0.75-1.6665-1.12744-2.79053-1.22217c-1.91357-0.16113-4.01953,0.49951-4.43213,1.56006
        c-0.65625,1.68506-3.93652,2.70605-4.92383,1.35986c-0.48877-0.68018-1.22852-1.10547-2.17383-1.10596
        c-0.94482-0.00049-2.09473,0.42236-3.40527,1.43799c-1.96924,1.52295-4.48877,3.99219-5.90576,3.85303
        c-0.47363-0.04639-0.82422-0.38428-0.98975-1.14697c-0.64551-3.04492,1.97852-2.70605,1.97852-6.08887
        c0-1.52246,0.33154-2.70313,0.60693-3.84961c0.10693-0.44482,0.20605-0.88525,0.27295-1.33789
        c0.00342-0.02393,0.00537-0.04883,0.00879-0.07275c0.01904-0.13721,0.03662-0.27441,0.04932-0.41455
        c0.07471-0.83057,0.02344-1.72217-0.28564-2.77783c-0.27197-0.93506-0.72314-1.31787-1.25-1.44678
        c-1.37744-0.33691-3.29004,1.12012-4.00488-0.58984c-0.9873-2.36426,0-5.07324-0.65527-6.08203l-1.50098-1.54785
        c-0.00049,0.00049-0.00146,0.00049-0.00195,0.00098c-0.07813,0.03174-0.14893,0.07471-0.22266,0.11377
        c-0.12402,0.06494-0.24023,0.13965-0.3501,0.22363c-0.04443,0.03369-0.0918,0.06396-0.13379,0.10059
        c-0.01563,0.01367-0.03125,0.02686-0.04639,0.04102c-0.18457,0.16992-0.33984,0.37012-0.46631,0.59229
        c-0.01416,0.02539-0.03271,0.04688-0.04639,0.07275c-0.0752,0.19531-0.22168,0.37061-0.42236,0.5332
        c-1.20605,0.97607-4.37939,1.46826-5.93018,2.7959c-0.2583,0.22119-0.47168,0.46582-0.62354,0.73975
        c-0.22705,0.40771-0.53857,0.72852-0.90039,0.98096c-1.58203,1.10498-4.13184,0.90186-4.87451,0.90186
        c-0.74121,0-2.88281,0.20264-3.83057,1.45703c-0.21875,0.29004-0.37402,0.63525-0.43359,1.04736
        c-0.30371,2.19092-1.50635,10.9541-1.50635,12.51611c1.18848,2.90381,3.1875,5.40479,5.76074,7.19824
        c1.5166,0.62988-0.30811,4.38428,1.5166,5.31738c1.25391,0.63721,1.07666,2.31396,0.54785,3.20459
        c-0.24268,0.40771-0.55859,0.65088-0.84521,0.55322c-0.11133-0.03809-0.18604-0.00391-0.23584,0.08398
        c-0.35791,0.6333,0.61523,4.17627-0.9834,5.55371c-1.82422,1.56104-0.30811,5.6377,2.73291,8.45703
        c2.31982,2.66895,3.41699,6.18066,3.04102,9.69531c0,2.50684,2.41943,2.81934,2.41943,5.94727
        c0,0-3.3999,12.79199-6.30127,13.07324c-0.09619,0.00977-0.19189,0.00684-0.28662-0.0127
        c-0.63867,3.41016-1.43799,8.76855-2.17285,11.67285c-0.90527,3.63477-1.58496,6.79883,0.46143,7.03027
        c2.05029,0.24023,6.13916,5.04004,10.11719,10.2002c0.9292,1.20898,1.69775,2.52148,2.29883,3.92285
        c0.00439,0,0.00879,0,0.01367,0c-0.00098-0.00293-0.00195-0.00488-0.00293-0.00781
        c1.49561-0.07813,2.99268,0.02344,4.46533,0.30859c2.25195,0.38867,3.86816,2.38672,3.77539,4.67871
        c0,1.55957,5.11084,0.97559,6.05469,0.77734c0.00342-0.00098,0.00586-0.00293,0.00928-0.00391
        c0.03613-0.00879,0.06836-0.02344,0.09668-0.04199c0.50781-0.37012-0.15186-2.34961,2.2583-3.14941
        c-2.18652-2.81152-5.33984-4.71094-8.84961-5.31543c-3.86328-0.34863-5.68848-6.3291-5.34375-9.02148
        c0.33496-2.68848-2.84277-5.04199-2.84277-7.38379c0-2.33887-2.27881-2.45996-4.88867-3.75391
        c-2.60742-1.30078,0.57324-9.25684,0.90918-12.65625c0.34082-3.3916,5.79688-13.12012,4.2085-16.1709
        c-1.58496-3.05176,5.57666-5.38477,6.48193-1.99121c0.91211,3.39551,1.25391,5.04199,5.80176,4.80176
        c4.54004-0.2373,4.54395,3.40332,8.18652,4.45996l0.53906,0.24902l0.06836-0.24121
        c0.60059-1.65039,0.10938-2.2998-0.36865-3.31152c-0.25928-0.5498-0.51514-1.20508-0.58984-2.18848
        C357.14111,404.1748,357.1333,403.97656,357.1333,403.76563z"/>
      <path class="agm-0" d="M2.8291,327.26367c0-1.28076-1.29004-4.33594-2.8291-5.34326v15.66064
        c0.40723-1.17334,0.78467-2.35645,1.1377-3.54688C1.79785,331.80322,2.36523,329.54541,2.8291,327.26367z"/>
      <path class="agm-0" d="M9.24561,361.20508c1.47266-0.82568,2.50098-6.2041,5.00195-7.26318
        c2.49707-1.06006,5.22705-0.35547,4.88623-2.58643c-0.3418-2.22461,0.22852-10.19189,2.61279-10.66357
        c2.39209-0.46436,6.13721-3.04492,6.25293-4.44824c1.93066-3.21533,4.05615-6.30762,6.36914-9.25635
        c1.47607-2.57959,1.5957-3.98779-0.56055-4.33643c-2.16211-0.35498-5.57959-6.56641-6.48193-8.31934
        c-0.90527-1.76318-7.50879-0.59424-12.854-4.92383c-2.22363-2.30615-3.67285-5.25146-4.13721-8.42529l-0.00098,0.00049
        c0.00049,0.00146,0.00049,0.00293,0.00098,0.00439l-2.67139,1.57178H4.75244v6.00146L0,314.771v7.14941
        c1.53906,1.00732,2.8291,4.0625,2.8291,5.34326c-0.46387,2.28174-1.03125,4.53955-1.69141,6.77051
        c-0.35254,1.19189-0.72998,2.37695-1.1377,3.55176v26.00781c0.19336-0.3291,0.45801-0.65771,0.83008-0.97998
        C3.67578,360.14648,7.76563,362.0293,9.24561,361.20508z"/>
      <path class="agm-0" d="M4.75244,302.55908h2.91113l2.67139-1.57178c-0.00049-0.00146-0.00049-0.00293-0.00098-0.00439
        c-1.12305-4.88281-1.29785-9.78467-2.10693-9.854C7.03223,291.02637,2.60352,295.77588,0,300.03027V314.771l4.75244-6.21045
        V302.55908z"/>
      <path class="agm-1" d="M411.89941,494.37109c0.42383,1.15039,0.90625,1.95703,1.42285,1.95703
        c1.82422,0,4.55078,4.69141,6.36523,3.2832c1.82031-1.40527,7.28125,0,10.00977-0.93945
        c2.72461-0.94336,3.1875-3.27637,7.73633-3.27637c4.54102,0,2.72656,3.74414,7.27344,1.40039
        c4.55078-2.34375,7.2793-0.46777,6.82227-4.6875c-0.42969-3.95215,2.34473-4.2002,2.31836-6.91895
        c-0.00195-0.18066-0.0166-0.37109-0.04492-0.5752c-0.02832-0.20508-0.0332-0.38867-0.01758-0.55273
        c0.2207-2.48438,5.00293-0.76563,4.56641-6.48535c-0.45508-6.0957,8.18555-5.15234,11.82422-5.15234
        c1.28906,0.17383,2.5293,0.60742,3.64258,1.27148c2.68945-1.36719,6.54492-3.16797,4.77344-4.78613
        c-2.05078-1.88379,6.13672-0.24023,7.04492-3.05273c0.92188-2.80469-3.16602-5.1543-5.2168-4.68359
        c-2.04297,0.46387-6.82227,0.23242-6.82227-1.87598c-1.07031-3.15918-3.76758-5.49707-7.05664-6.08887
        c-2.72266,0-5.9043,5.61816-6.13477,7.96484c-0.22656,2.34766-4.54297,2.1084-4.0918,5.38867
        c0.18359,1.28809-0.625,1.84473-1.66797,2.18359c0.00098,0.00195,0.00098,0.00391,0.00195,0.00684
        c0-0.00098,0.00098-0.00098,0.00195-0.00098c0.70117,2.82813,1.08398,4.86914,0.08008,4.60547l0,0c-0.00195,0-0.00293,0-0.00391,0
        c-0.06836-0.01758-0.13086-0.02344-0.19531-0.0332c-1.66113-0.24707-2.18164,1.91309-4.80664,1.91309
        c-0.00098,0-0.00195,0-0.00293,0c-0.00293,0-0.00586,0-0.00879,0c-0.92773,0-2.50195-2.42871-4.16211-5.59668l-0.16016-0.02734
        c-1.81836,0-5.90625,6.55957-6.82031,11.00879c-0.90625,4.45898-4.99805,3.05078-11.13672,3.5166
        c-6.13672,0.4707-3.64258,7.03809-5.01172,10.0791c-1.25391,2.78711-7.99414-2.24316-11.39844-2.95605
        c0,0.00195,0.00098,0.00391,0.00098,0.00586h0.00293C411.27637,492.36523,411.57031,493.47266,411.89941,494.37109z"/>
      <path class="agm-1" d="M351.479,460.08008L351.479,460.08008c-0.94385,0.19824-6.05469,0.78223-6.05469-0.77734
        c0.09277-2.29199-1.52344-4.29004-3.77539-4.67871c-1.47266-0.28516-2.96973-0.38672-4.46533-0.30859
        c0.00098,0.00293,0.00195,0.00488,0.00293,0.00781c1.49316,3.86816,2.21826,7.98633,2.12158,12.13184
        c0.12305,4.68457,6.82422,12.17676,7.84473,15.22754c1.03223,3.05273,2.95898,3.98242,7.39453,7.26465
        c4.43164,3.28613,13.07324,8.55566,14.78125,7.73535c1.70703-0.81934-2.60742-8.55762-5.1123-11.25195
        c-2.50781-2.68848-0.22559-9.96289-0.1123-15.23828c0.11523-5.27148-4.77637-4.68262-5.90723-8.31543
        c-1.1416-3.63184-3.07617-1.52734-3.75586-3.86523l-0.59375-1.12695c-2.42871,0.80371-1.73877,2.7998-2.26221,3.14941
        c-0.02832,0.01855-0.06055,0.0332-0.09668,0.04199C351.48486,460.07715,351.48242,460.0791,351.479,460.08008z"/>
      <path class="agm-1" d="M454.53125,473.32324c0.06641,0.00977,0.12988,0.01563,0.19922,0.0332l0,0
        c1.00391,0.26367,0.62109-1.77734-0.08008-4.60547c-0.00098,0-0.00195,0-0.00195,0.00098
        c-1.61328,0.5293-3.78906,0.5166-3.78906,1.79395c0,2.05957-3.4375-0.75879-5.29883-0.90625
        c1.65234,3.1709,3.2207,5.59082,4.16113,5.59668c0.00098,0,0.00195,0,0.00293,0
        C452.34961,475.23633,452.87012,473.07617,454.53125,473.32324z"/>
      <path class="agm-0" d="M58.60498,169.06738c0.06738,0.06592,0.14941,0.1333,0.22656,0.2002
        c0.05078,0.04395,0.09424,0.08691,0.14893,0.13086c0.11182,0.09033,0.23682,0.18262,0.36377,0.2749
        c0.03125,0.02246,0.05762,0.04443,0.08936,0.06738c0.10059,0.07129,0.21143,0.14404,0.31934,0.21631
        c0.06787,0.0459,0.13086,0.09082,0.20166,0.13672c0.02783,0.01807,0.05859,0.03711,0.08691,0.05518
        c1.75049,1.12842,4.19922,2.36963,5.39795,3.625c0.03906,0.04053,0.08496,0.08105,0.12109,0.12207
        c0.0625,0.07031,0.10938,0.14063,0.16309,0.21094c0.0459,0.06055,0.10059,0.12109,0.14014,0.18164
        c0.59473,0.61133,1.22656,1.18213,1.88867,1.71289c0.01953,0.01563,0.03809,0.0332,0.05762,0.04883
        c2.04932,1.63086,4.39697,2.86328,6.9209,3.62451c0.79736,0.29492,1.58057,0.79541,2.33496,1.37598
        c1.31738,1.01172,2.54834,2.26904,3.61572,3.10645c0.7627,0.59766,1.44189,0.97998,2.00977,0.9043
        c2.38428,0.38232,4.70117,1.17676,6.82275,2.34717l0.00049,0.00439c0.00098,0.00049,0.00244,0.00098,0.00342,0.00146
        l0.5835,5.21582l0.65674-0.15723c0.85547-0.29297,1.59131-0.18604,2.26514,0.09766
        c0.80762,0.33838,1.52441,0.93262,2.24609,1.40283c0.59863,0.38818,1.20215,0.69043,1.86475,0.69043
        c0.0166,0.00342,0.03223,0.00977,0.04883,0.01318c0.43604,0.01123,0.92139,0.20313,1.42871,0.47705
        c0.56055,0.25586,1.08936,0.58008,1.56445,0.979c0.00977,0.00635,0.01953,0.0127,0.0293,0.01904
        c-0.41602-3.12012,0.7793-6.24316,3.17773-8.27393c2.04639-1.64014-1.3667-4.45264-0.91211-7.03174
        c0.45752-2.58301-5.22412-3.98779-4.77734-6.0957c0.11377-0.52881,0.5-0.91016,1.0459-1.19922
        c1.62988-0.86914,4.7002-0.91357,6.23535-1.61572c0.08447-0.03857,0.15381-0.0874,0.22656-0.13428
        c1.46436-0.9502,0.61963-3.51221,1.66748-4.16406c0.15674-0.1001,0.35596-0.15674,0.61377-0.15674
        c0.79004,0,1.40674-0.31885,1.90967-0.82666H57.40234c0.26172,0.58496,0.50732,1.17773,0.73242,1.7793
        C58.21973,168.6377,58.38281,168.84961,58.60498,169.06738z"/>
      <path class="agm-0" d="M119.54541,167.19141c0,0.00049-0.00049,0.00146-0.00098,0.00195
        c0.23389,0.00732,0.46729,0.00391,0.70068,0.00342c1.60596-0.00244,3.20215-0.18213,4.75928-0.54248h-5.13672
        c-0.11475,0.19482-0.22461,0.37891-0.32031,0.53711C119.54688,167.19141,119.54639,167.19141,119.54541,167.19141z"/>
      <path class="agm-0" d="M572.28516,232.88477c0.91602-2.57617,3.1875,3.75244,1.14063,7.96973
        c-2.04688,4.21973,0.25977,10.16455,2.95117,9.60986c2.27734-0.46826,5.23633-8.90527,8.64258-13.35986
        c3.41602-4.45703-1.58203-2.81885-1.58203-5.15527l-0.00781,0.00293c0-2.34668-5.00781-1.87695-5.22656-3.98926
        c-0.22852-2.10938-3.64063-0.93262-7.04883,1.64258c-3.41406,2.57715-4.55078,3.04883-3.1875,5.625
        C569.33789,237.80859,571.37891,235.46631,572.28516,232.88477z"/>
      <path class="agm-0" d="M590.70508,235.46436c2.04688,0.7041,3.63867,0.7041,4.32617-2.10986
        c0.87695-2.44238,3.56641-3.71143,6.01172-2.83594c0.19727,0.07422,0.38672,0.15771,0.57422,0.25391
        c2.2832,1.17676,5.23828-3.5166,5.23828-7.03174c0-3.5166-6.82227-3.5166-7.73633-0.93115
        c-0.91016,2.57422-3.63867,1.40527-7.27734,2.104C588.21484,225.61768,588.08398,234.55908,590.70508,235.46436z"/>
      <path class="agm-0" d="M647.78516,172.41064c2.50391,2.10986-4.08984,6.09521-3.86914,10.54785
        c0.23047,4.45508-4.0918,4.45508-4.77148,7.73389c-0.6875,3.28125-3.18555,2.58203-7.04883,5.62695
        c-3.86523,3.04297-5.91406-0.46631-5.00195-2.57617s-5.91602,1.16699-5.68945,6.32715
        c0.22656,5.15674-6.36328,5.62598-4.77539,7.96973c1.58984,2.34766-5,3.74707-4.77734,1.63818
        c0.22852-2.10986-4.09375-0.93604-9.32227,1.17383c-5.22656,2.10791-8.41211-2.34326-11.36328,0.70264
        c-2.95117,3.04932-7.9668,8.43799-11.82813,9.14795c-3.86523,0.70459-1.63672,5.53027,0,4.68311
        c2.72656-1.40625,7.05273,0,8.41602-2.11084c5.9375-3.19727,12.57227-4.88916,19.32031-4.92139
        c6.83008-0.23877,2.72852,3.28223,3.19141,7.03223c0.45703,3.75098,6.13672,3.05078,9.0918-0.46631
        c2.95313-3.5166,5.22852-3.28369,2.72656-5.39355c-2.5-2.10986,0.45703-3.98389,2.27539-1.17236
        c2.2207,2.55713,6.0918,2.82959,8.64453,0.61182c0.44141-0.38135,0.82813-0.82227,1.13867-1.31348
        c2.27734-3.75684,2.95117,0.70166,5.68164,0.46777c2.72461-0.23193,4.32227-7.26758,4.54883-4.68701
        c0.23438,2.5791,4.54883,1.875,7.27539-1.1709c2.73438-3.04297,0.23438-8.43604,2.04688-11.24902
        c2.41211-3.58301,3.07227-8.06104,1.81836-12.18945c-1.81836-3.51807,4.0918-5.86182,7.05664-8.67383
        c1.86719-1.77832,0.00879-8.05371-1.66895-13.49512H646.7002C646.29883,168.91992,646.50684,171.33545,647.78516,172.41064z"/>
      <path class="agm-0" d="M418.33789,355.22656c0.22852-2.81201,4.6875-7.23389,4.09766-9.37207
        c-0.45508-1.64063-6.59961-0.69727-9.55859-0.69727c-2.95313,0-10.45313,4.44824-7.49805,10.76953
        C408.33594,362.25146,418.10938,358.03857,418.33789,355.22656z"/>
      <path class="agm-0" d="M502.15234,179.44043c-2.27539,2.22266,0.90234,2.81152,5.33789-0.11816
        c4.49219-3.14063,9.24609-5.88867,14.21289-8.19922c1.18262-0.44629,2.44336-0.6123,3.69629-0.48389
        c0.00488,0.00049,0.00977,0,0.01465,0.00049c0.30762-1.28418,1.00586-2.43262,1.9834-3.31299
        c0.05957-0.05371,0.10938-0.1167,0.1709-0.16797c0.36426-0.15869,0.71777-0.34033,1.08008-0.50439h-19.88086
        c-1.49219,2.37646-4.71289,4.89111-5.48633,6.69238C502.03516,176.27637,504.42578,177.2124,502.15234,179.44043z"/>
      <path class="agm-0" d="M147.28174,169.77686c0.04053,1.41553-0.39648,2.80127-1.25391,3.92725l1.21094,6.87451
        c0.01953,0.00684,5.59082,1.92383,7.18066,4.0791c0.23145,0.31055,0.38135,0.62598,0.41992,0.94141
        c0.13818,1.16504,0.47559,3.54639,0.79736,5.76416c0.37109,2.54639,0.71973,4.87207,0.71973,4.87207l-0.00146,0.00049
        c0,0.00049,0.00146,0.01025,0.00146,0.01025l-0.17627,0.02295l-0.04346,0.0083c0.16553,0.13379,0.33545,0.26855,0.50732,0.40381
        c2.12695,1.66162,4.63623,3.35059,5.81494,4.47363c0.43164,0.40869,0.68945,0.74365,0.68945,0.97607
        c0.01172,1.28857,0.39893,2.51563,1.07617,3.55273c0.68213,1.03809,1.6543,1.88428,2.83252,2.41113
        c1.03662,0.48193,2.05469,0.99658,3.0542,1.54492c1.00537,0.54834,1.99219,1.13086,2.9585,1.74756
        c0.00391,0.00244,0.00781,0.00439,0.01172,0.00684l0.28613-0.75391l3.03467-0.31201
        c0.48438,0.33105,0.92236,0.71777,1.33887,1.13135c0.12793,0.12744,0.26318,0.24658,0.38525,0.38135
        c0.00439,0.00537,0.01025,0.00977,0.01465,0.01465c1.88281-3.0498,5.13867-4.97705,8.71631-5.15967
        c0.36084,0.02197,0.77979,0.12598,1.23486,0.29346c3.20801,1.18359,8.22998,5.57227,7.63525,7.26611
        c-0.6875,1.93262-5.45703,8.96533-7.67773,9.49121c-1.07959,0.21582-2.17285,0.32861-3.27637,0.3457l-0.47559,2.82568
        c0,0.28955,0.09424,0.61182,0.25684,0.96143c0.71338,1.53955,2.75488,3.62305,3.99365,5.91992
        c0.08838,0.16406,0.1543,0.32422,0.20703,0.48145c0.85205,2.54199-2.76318,4.10059-4.76172,2.3374
        c-1.99121-1.75977-2.91699,1.42334-2.02197,3.11963c0.01514,0.02881,0.03516,0.05273,0.05078,0.08008
        c0.04834,0.08252,0.09668,0.16553,0.15381,0.23926c0.55762,0.71875,0.73145,2.03223,0.96143,3.27393
        c0.271,1.45947,0.62012,2.81934,1.76855,2.99023c2.12549,0.31494,3.9458,4.68799,6.37549,4.68799s4.55469,4.07275,4.55469,4.07275
        s2.54932,1.67383,3.19189,2.93262c0.09277,0.18115,0.146,0.354,0.146,0.51172l-0.02051,0.06738l-0.0957,0.3125l-0.02734,0.08936
        l-0.00293,0.00928c2.65137-0.28027,4.40771-0.37646,4.71191-1.50195c0.27783-0.99902,2.5835-1.18652,4.71777,0.09717
        c0.49316,0.29688,0.97754,0.67236,1.42529,1.13428c0.14795,0.15283,0.32422,0.3208,0.52588,0.50195
        c3.03809,2.73193,11.80566,8.47119,15.16357,11.27393c4.34961,3.25391,9.45459,5.37061,14.84277,6.14844
        c2.89014-0.17432,5.62305,2.10938,10.05469,1.93311c0.58301-0.01758,1.16846-0.00244,1.75439,0.04639
        c0.00537,0.00049,0.01025,0,0.01563,0.00049c0.56494-0.62842,1.49121-0.71045,2.25977-1.64355
        c0.00098-0.00146,0.00244-0.00293,0.00342-0.00391c0.08057-0.09863,0.16113-0.19678,0.23779-0.31592
        c0.30225-0.46777,0.64209-0.60498,0.97314-0.50928c0.99902,0.28809,1.91553,2.69824,1.45654,4.5752
        c-0.22998,1.16455-0.08252,2.37549,0.4248,3.45264c0.00488,0.01025,0.00732,0.021,0.01221,0.03125
        c0.69434-1.04883,1.27783-2.17334,1.73633-3.34912c1.5332-3.86572,3.92236-3.68945,5.45557-4.92188
        c1.53418-1.22607,3.06885-0.70117,6.31152,1.05615c3.05029,1.65723,4.29834-1.82959,8.86084,2.49463
        c0.00146,0.00098,0.00293,0.00195,0.00439,0.00342c0.00342,0.00391,0.00684,0.00635,0.01074,0.00977
        c0.24756-0.13232,0.51123-0.22998,0.76953-0.33984c0.46533-0.19824,0.94287-0.35986,1.43408-0.48291
        c0.16455-0.04102,0.32324-0.10156,0.48975-0.13379c0.90527,0,2.13135-1.87939,3.64502-3.44531
        c1.50635-1.56201,1.21338-3.44287,3.02686-3.12793c1.82861,0.31104,2.74072-2.18896,6.68701-4.38916
        c3.94629-2.19482,7.29102,1.25732,9.11279-0.30908c1.36133-1.17041,2.89258-1.81592,3.57861-1.41309
        c0.23145,0.13574,0.36768,0.38965,0.36768,0.78418c0,0.49951,0.24756,1.03076,0.60303,1.5835
        c0.61328,0.95264,1.54736,1.96826,2.10547,2.99414c0.13379,0.24658,0.24756,0.49365,0.32813,0.74072
        c0.03027,0.09375,0.07129,0.18359,0.11719,0.271c0.00586,0.01172,0.00977,0.02393,0.01611,0.03564
        c0.02246,0.04053,0.05225,0.07813,0.07813,0.11768c0.04395,0.06787,0.08887,0.13477,0.14111,0.19873
        c0.01416,0.01709,0.03027,0.0332,0.04492,0.05029c0.07422,0.08545,0.1543,0.16748,0.2417,0.24658h0.00049
        c1.12598,1.01514,3.23877,1.46045,3.77197,1.55908l0.00684-0.00439h0.00049l1.2876-0.7832
        c0.19629-0.12207,0.40039-0.2207,0.60889-0.29834c0.03711-0.01416,0.07568-0.01855,0.11328-0.03076
        c0.16797-0.05615,0.33691-0.10449,0.50879-0.13281c0.10938-0.01807,0.21924-0.01758,0.32959-0.0249
        c0.98633-0.06396,1.9751,0.2915,2.67676,1.02881c0.01709,0.01807,0.03027,0.04102,0.04736,0.05908
        c0.13135,0.14502,0.25488,0.2998,0.36279,0.4707c0.00391,0.00586,0.00928,0.01025,0.0127,0.01611
        c0.04199,0.06787,0.06885,0.14355,0.10596,0.21387c0.06104,0.11572,0.12646,0.229,0.17383,0.35059
        c0.07129,0.18359,0.12207,0.37451,0.16016,0.56787c0.00439,0.02148,0.01367,0.0415,0.01758,0.06299
        c0,2.11084,0.17139,4.396,1.70557,4.396c1.53027,0,3.06787,0,3.06787,3.86816c0,2.41602,0.53223,5.04004,0.14111,7.48242
        c-0.23486,1.46777-0.80273,2.86963-2.02002,4.12207c-0.38281,0.39258-0.77246,0.82422-1.16113,1.2793
        c-2.03174,2.37988-4.02637,5.4541-4.84277,7.62402c-0.02002,0.05371-0.0459,0.11182-0.06494,0.16406
        c-0.01172,0.0332-0.01855,0.0625-0.02979,0.09521c-0.13428,0.3916-0.23047,0.75293-0.27148,1.06543
        c-0.01172,0.08936-0.00781,0.16406-0.01074,0.24561c-0.00293,0.07178-0.01367,0.15088-0.00928,0.21631
        c0.0083,0.14355,0.03271,0.27246,0.07568,0.38379c0.12842,0.32861,0.17334,0.71289,0.17676,1.1123
        c0.00635,0.79883-0.15186,1.65771-0.13525,2.25439c0.02002,0.69971,0.27979,1.03906,1.3291,0.49756
        c2.38428-1.2334,7.84473,0,6.9873,1.4043c-0.84814,1.40771,0.17432,6.33154,2.39063,6.50586
        c2.22217,0.18115,3.58496,1.4043,2.89844,3.52246c-0.67627,2.1084-1.87598,5.26807,0,5.26807c1.88184,0,4.6084,0,4.25977,2.11182
        c-0.34424,2.10498,1.36426,4.04248,4.77734,2.63086c1.979-0.81396,2.98438-1.55811,3.43896-0.77686
        c0.00537,0.00928,0.0127,0.01221,0.01807,0.02197c0.70703-0.02148,1.07373,0.82861,1.5918,1.73438
        c0.03955,0.06885,0.07861,0.1377,0.11963,0.20654c0.00488,0.0083,0.00977,0.01611,0.01465,0.02393
        c0.45752,0.75977,1.0498,1.51074,2.06689,1.77637c0.30322,0.07764,0.5498,0.05322,0.74756-0.05029
        c1.39063-0.72803,0.35889-5.38965-0.43701-6.2124c-0.77295-0.79297-0.01904-3.82227,0.78369-4.16064
        c0.14453-0.06104,0.29102-0.04492,0.43018,0.09863c0.11084,0.11426,0.25049,0.19824,0.40918,0.2583
        c0.0083,0.00342,0.01611,0.00684,0.02393,0.00977c0.1582,0.05664,0.33643,0.08789,0.52979,0.09619
        c0.01611,0.00049,0.03271-0.00049,0.04834-0.00049c0.1792,0.00391,0.37109-0.0127,0.57129-0.04639
        c0.02441-0.00439,0.04688-0.00586,0.07129-0.01025c0.19824-0.0376,0.40576-0.09717,0.61572-0.16846
        c0.05664-0.01904,0.1123-0.03955,0.16943-0.06104c0.2041-0.07715,0.40967-0.16553,0.61572-0.27148
        c0.13477-0.06885,0.26611-0.15479,0.39941-0.23535c0.08057-0.04883,0.16162-0.09375,0.24072-0.14648
        c0.11426-0.07568,0.22412-0.15723,0.33496-0.24121c0.104-0.07813,0.20557-0.16016,0.30566-0.24512
        c0.10254-0.08691,0.20313-0.17285,0.30029-0.2666c0.13623-0.13086,0.26465-0.26904,0.38818-0.41211
        c0.04297-0.04932,0.09326-0.0918,0.13428-0.14258c0.1875-0.23145,0.39844-0.38086,0.62598-0.47119
        c1.15234-0.45605,2.73779,0.63818,3.93848,0.60596c0.48242-0.0127,0.90234-0.20654,1.20703-0.75684
        c0.22656-0.40918,0.50586-0.61035,0.82764-0.6792c1.18896-0.25391,2.95996,1.31738,4.8208,1.03906
        c0.33984-0.05078,0.68262-0.16309,1.02539-0.35986c0.51025-0.29199,0.9668-0.43115,1.38135-0.47998
        c1.39111-0.16357,2.29639,0.70313,3.09033,0.3042c0.23877-0.12012,0.46826-0.35449,0.69727-0.76709
        c0.68018-1.22949,2.22266-2.65674,3.60596-3.50586c1.08105-0.66309,2.06494-0.97363,2.46533-0.56006
        c1.41113,2.13965,3.80566,3.42725,6.375,3.44092c4.85986,0.625,2.12549,3.1333,2.12549,4.70117
        c0,1.56201,5.47461,5.63477,7.5957,5.93506c0.39648,0.06543,0.93945,0.55371,1.57227,1.30566
        c1.47852-0.75195,4.11719,0.50195,4.69336-1.29883c0.6875-2.10791,3.18945-1.52344,4.20898,0.23291
        c1.02734,1.75586,2.1543,2.11084,4.43555,0c2.26953-2.10889,3.40625,2.11084,2.04297,3.2832
        c-1.36328,1.1748-0.44922,4.8042,1.70898,6.91504c2.15625,2.10889,2.50781-1.2876,2.04688-3.39258
        c-0.46094-2.10791,3.64063-4.21973,10.80273-6.68311c5.64258-1.79053,10.78516-4.87598,15.00586-9.0166
        c0.11328-1.17578,5.11914,2.10791,7.50977,0.23877c4.24805-1.58154,8.76172-2.34326,13.29883-2.23438
        c2.28125-0.11279,2.15625-2.10791,4.54883-4.09961c2.38477-1.98926,1.81445-2.93213,4.54492-3.75537
        c2.26758-0.63867,3.97852-2.50098,4.4375-4.80371c0-1.40771,5.11133-1.88623,5.23047-3.40137
        c0.11914-1.51758,3.86133-1.99219,3.86133-3.86426c0-1.87109,2.27539-1.52734,3.30078-2.93359
        c1.01953-1.40771-0.79297-5.50244,1.01953-6.09082c1.82617-0.59229-0.67383-2.81104-1.01953-3.86914
        c-0.33789-1.05371,3.41406-1.2915,5.33594-2.57813c1.90234-1.25684,3.1875-3.25586,3.53711-5.51123
        c0.33789-2.22363,3.52148-4.21924,4.99414-4.80469c1.48438-0.5835,2.04883-3.74512,1.5957-6.44434
        c-0.45313-2.69727,2.61133-1.9917,1.25195-3.0498c-1.36328-1.05176,0.56641-3.97998,3.29883-4.7998
        c2.7207-0.81738-1.71094-2.10986-3.1875-0.93799c-1.47461,1.16943-3.06836-3.40137-4.43164-1.64258
        c-1.37109,1.7583-5.22852-1.05469-2.73242-1.63818c2.77148-1.20557,5.4668-2.57422,8.07813-4.10156
        c1.69922-0.59033-2.50195-4.45752-4.66797-4.33545c-2.15234,0.12061-4.54492-5.16162-6.82031-5.16162
        s1.70508-1.63916,4.32227-0.23389c2.62109,1.40771,5.68555,2.5835,6.82227,1.40674
        c1.14648-1.17578-4.43164-5.85498-6.47656-7.50049c-2.04688-1.64502-0.11914-3.86768-1.36719-4.80371
        c-2.11523-2.93213-3.77148-6.1709-4.89258-9.60986c-0.91016-2.69727-5.68555-3.16357-6.82422-4.92188
        c-0.94531-3.03711,0.65234-6.28467,3.64063-7.38135c3.52539-1.52246,2.04883-4.56836,3.64453-4.22168
        c1.58789,0.35156,2.94922-0.34668,4.20508-3.04785c1.24805-2.68994,3.4082-1.05371,4.66211-2.45996
        c1.25391-1.40576,6.81836-1.28516,8.74609-2.69043c1.93359-1.40967-1.81641-5.74707-4.43164-5.04297
        c-2.91992,0.69678-5.95898-0.58398-7.50195-3.16455c-1.81836-2.81299-6.5918,5.15674-10.23047,6.91406
        c-3.64648,1.75781-6.02734-3.16406-4.77539-6.32764c1.24609-3.16357-2.84375-3.04785-7.96289-2.81396
        c-5.11523,0.23779-3.18164-9.37305-0.11133-9.84375c3.06836-0.46631,7.61719,2.93018,9.55469-2.92578
        c1.93164-5.86279,4.6543-1.2915,8.97656-6.44922c0.34277-0.40967,0.69141-0.80078,1.04199-1.18311H152.29688
        c-0.49463,0.43555-1.01953,0.83105-1.60889,1.0127C148.9834,168.1958,147.28174,167.66699,147.28174,169.77686z"/>
      <path class="agm-0" d="M498.16016,326.87402c2.27148-1.64307,11.58789-21.79492,9.54688-24.61377
        c-2.04688-2.81201-11.14648,1.64014-13.64844,11.01953C491.56445,322.65771,497.38281,327.4209,498.16016,326.87402z"/>
      <path class="agm-1" d="M493.1582,386.63916c1.81055,0,3.18359,2.3335,2.04492,6.08936
        c-1.14453,3.75391,3.4082,4.21973,7.73242,2.57324c4.31836-1.6377,2.72461,3.06055,7.04883,2.35352
        c4.32227-0.70117,10.23438,4.92383,9.32031,7.03125c-0.91016,2.11133,0.22656,8.9043,2.27734,7.03223
        c2.04102-1.87207,5.23047,0.46582,3.17578,3.5166c-2.04492,3.04688,3.1875,6.08789,3.86523,9.83984
        c0.68555,3.75391,2.95898,0.70313,2.50977-2.10742c-0.44922-2.81348,0.44922-2.57324,3.4043-4.68555
        c2.96484-2.10742-0.22656-5.62402-2.04297-9.61426c-1.81836-3.9873-8.63672-2.57324-8.63672-6.32715
        c0-3.74805-5.00586-4.9209-5.23633-7.0332c-0.22656-2.10742-6.81445-4.21484-10.68359-1.87598
        c-3.86523,2.33398-1.14063-4.21875-3.86719-7.27002c-2.72852-3.04492,2.03906-7.49609,5.23438-11.24805
        c3.18164-3.74805-0.91406-10.07617-0.46094-12.88818c0.46094-2.81494,0.22852-3.74805-1.36328-2.81494
        c-1.5957,0.94287-8.18555-2.81201-11.13867-0.93311c-2.95703,1.86914-0.22461,17.3374-1.5957,18.51221
        c-1.36328,1.18262-3.13281-2.50391-3.64258-0.93701C490.42383,377.96436,491.33789,386.63916,493.1582,386.63916z"/>
      <path class="agm-1" d="M495.42969,399.76758c0.67578,3.28027,2.27539,10.0752,7.27344,8.43555
        C507.70117,406.56055,494.93555,397.40625,495.42969,399.76758z"/>
      <path class="agm-1" d="M534.99414,431.87402c2.05078,3.5166-3.86133,0.71094-4.31836,4.22754
        c-0.45703,3.50879-4.77539,0.70313-4.55078,3.51465c0.23242,2.8125-4.54297,0-6.82031-1.63867
        c-2.28125-1.63867-5.45508,4.45117-8.86914,5.38672c-3.40625,0.93262-5.22266,8.39551-2.95508,8.19727
        c2.72461-0.22559,4.08789-3.98047,6.82031-3.74023c2.72266,0.23145,1.82031-2.81348,5.6875-2.10938
        c3.86914,0.70508,1.13477,12.64844,6.36914,13.59082c5.23242,0.93359,5.45703,4.21777,7.95313,4.21777
        c2.50195,0-0.44922-7.5-0.22266-10.07715c0.2207-2.5791,5.45117-0.23535,8.18164-3.51855v0.00391
        c2.72852-3.28027-1.81055-11.95117-1.81055-17.5752S532.94727,428.36523,534.99414,431.87402z"/>
      <path class="agm-1" d="M485.42969,426.72168c2.81055-1.43457,3.93164-4.87598,2.49414-7.69434
        c-0.13086-0.25977-0.28125-0.51367-0.45117-0.74512c-1.37109-2.81055-2.95703,4.44922-6.14063,8.66504
        c-3.18945,4.21973-10.7793,11.23047-9.7793,13.13379C472.91797,442.6543,481.33203,429.7627,485.42969,426.72168z"/>
      <path class="agm-1" d="M527.48828,427.89063c-1.81836-2.34766-6.36133,1.99219-5.68945,3.75195
        C522.71289,433.97949,529.30859,430.2373,527.48828,427.89063z"/>
      <path class="agm-1" d="M507.70703,411.72363c-3.41016,0.70313-2.22656,11.3252,0.44922,10.77832
        c1.14258-0.23828,3.875,0,4.08984-2.34766c0.23242-2.33984,2.73438,4.21973,0.6875,6.3291
        c-2.04688,2.1084-2.04688,7.96973,1.14063,8.6748c3.18359,0.7041,9.0957-10.54883,8.18555-12.64844
        c-0.91016-2.10742-3.63867,1.4043-2.95508-1.4082C519.98633,418.29004,511.11914,411.02148,507.70703,411.72363z"/>
      <path class="agm-1" d="M724.19727,558.67676c6.16211-0.57031,11.86914-3.50977,15.9082-8.20117
        c0-1.87793,3.86719-5.1582,2.27148-7.03027c-1.58789-1.87207-4.99609,0.93262-4.99609,3.04004
        c0,2.1123-3.63477,3.28711-9.32227,5.62402c-5.67969,2.34961-16.01563-2.68164-16.37109,0.7041
        C711.46289,554.9248,718.05469,558.67676,724.19727,558.67676z"/>
      <path class="agm-1" d="M654.38281,529.29199v0.00684v50.02246c2.40234,1.69727,5.24609,2.66992,8.1875,2.79297
        c10.00391,0.46582,7.49805-5.39453,9.31641-5.39453c1.82031,0,2.50391-3.28027,4.55469-5.85547
        c2.04688-2.58301,10.91211-0.47168,16.14258,3.74805c5.23242,4.21582,11.36914,16.64258,16.82031,15.46777
        c5.46484-1.18262,11.1543,3.28223,16.16016,3.04492c4.99414-0.23438,0.44336-4.21387-1.14844-4.91699
        c-1.58594-0.70508-5.67578-3.5166-5.45508-5.38672c0.22656-1.87793-4.54297-1.64648-7.73047-3.75781
        c-3.17969-2.11719-2.04297-8.90234-5.45313-10.54688c-3.41211-1.64063-5.91406-6.79297-1.82422-6.56738
        c4.08984,0.23242,4.77734-2.10352,1.58984-5.14453c-3.17773-3.04883-14.7832-4.22168-15.23242-8.67871
        c-0.45508-4.45508-21.14844-15.00391-30.23828-17.1123C658.14453,530.53613,656.24414,529.96191,654.38281,529.29199z"/>
      <path class="agm-1" d="M747.61523,545.55273c4.31836-1.16895,1.13672-5.62402-2.49805-7.96777
        c-3.63477-2.34766-6.82227-3.9834-2.2793,0.46387C747.38867,542.50195,744.94336,546.27734,747.61523,545.55273z"/>
      <path class="agm-1" d="M193.66309,429.53418c-2.04639,0.23828-1.58496,4.44531-2.72998,7.49609
        c-0.97656,3.43359-1.13379,7.04883-0.44727,10.54297c0.44727,4.45996,0,9.14844,3.85791,11.72363
        c4.44434,2.95605,11.59961-4.69043,11.83154-11.25195C204.43311,440.53906,199.98047,433.94434,193.66309,429.53418z"/>
      <path class="agm-0" d="M570.06445,602.56055c1.70703,1.4043,10.24023-0.35059,9.89844-2.46094
        C579.61719,597.99219,567.48438,600.43262,570.06445,602.56055z"/>
      <path class="agm-0" d="M687.22461,629.97949c0.33789-3.51465-4.94727-4.39355-5.12109-6.32715
        c-0.16992-1.93359-3.06836-0.1748-5.96484,0.17383c-2.90234,0.35449-2.73242-3.86621-2.39063-7.90625
        c0.39258-4.5127-0.87695-9.01758-3.57617-12.6543c-2.39258-1.9375-2.56055-8.61523-3.76758-9.66699
        c-1.19336-1.05664-5.10938,3.51172-4.93945,5.79785c0.30469,2.28223-0.37891,4.58594-1.87891,6.32715
        c-1.53516,1.40918,1.02344,6.8584-1.02539,8.78809c-2.04297,1.93555-0.16992,9.66895-0.67969,14.06738
        c0.03418,1.59863-0.06641,3.19238-0.29492,4.7666h29.79492C687.21094,632.26367,687.10938,631.15332,687.22461,629.97949z"/>
      <path class="agm-0" d="M612.34375,627.69727c-2.73047-1.92969-1.88281-1.92969,0.68359-3.86523
        c2.55859-1.93457,2.04492-4.39551,0.67578-6.68164c-1.35938-2.28223,1.54492-1.75684,4.95117-3.69043
        c3.41406-1.9375,0-4.21973,2.55859-5.27539c2.55469-1.05176,1.02148-3.33789-1.70898-3.86523
        c-2.72266-0.52832-1.875,2.81348-2.89648,3.16211c-1.02148,0.35449-2.04688-4.39453-2.9043-4.21582
        c-0.84766,0.17188-4.08984,4.74414-5.79883,2.45801c-4.58789-2.6582-9.7168-4.28027-14.99805-4.74414
        c-3.75586-0.53125-4.95117-5.4502-7.16992-4.57227c-2.2207,0.88184,0.3457,2.46484,2.90234,5.27539
        c2.55859,2.81152-8.5332,4.74707-11.76758,3.69043c-3.24219-1.05371,0.50977,2.46289-2.2207,2.28809
        c-2.72461-0.17969-3.23828,4.74316-5.45703,7.20703c-2.2168,2.46094-0.68359,4.21582-3.06445,5.79883
        c-2.38867,1.58496-2.5625,3.33984,0.33398,6.68164c2.90039,3.33789-1.35938,3.5127-3.91797,0.87598
        c-2.56055-2.64063-5.11523-2.63867-4.94922,0.35059c0.17188,2.9873-3.06641,1.5791-2.55664-1.75488
        c0.50977-3.33301-3.58594-1.75781-4.25586-3.69141c-1.25-2.58301-3.6582-4.41309-6.48438-4.92285
        c-2.21875,0.1748-2.21875,2.63672-4.43945,2.63672c-2.21094,0-2.7207,1.23047-2.38086,3.69141
        c0.33594,2.46973-3.41211-0.1748-5.97266,0.17773c-2.55469,0.35059,0,3.33789-1.36719,3.5127
        c-1.35938,0.18359-1.35938,2.63672-3.57617,2.63672c-1.0166,0-1.49219,1.07227-1.67969,2.48438h92.45801
        C616.18848,630.13379,614.98242,629.56738,612.34375,627.69727z"/>
      <path class="agm-0" d="M8.78369,185.76172c1.14795-1.63867,7.73584-1.17188,8.87305,0.2334
        c1.60986,1.3877,3.57764,2.28223,5.68262,2.57861c0.91211,0,1.3667,2.10986,3.64502,1.87695
        c2.1333-0.22119,6.06592,0.59326,6.54004,1.85693c0.03467,0.08789,0.05469,0.17676,0.05469,0.26855
        c0.03076,0.20508,0.08057,0.40234,0.14648,0.5918c0.47754,1.34814,1.78467,2.26611,3.26025,2.20459
        c1.81738,0,3.40332,4.68115,5.22754,4.4502c1.71582-0.21484,5.02832-0.22705,5.41748,0.74219
        c0.0293,0.06592,0.04541,0.13623,0.04541,0.21191c0.03174,1.26318,0.13672,2.521,0.27979,3.77441
        c0.229,1.99268,0.57959,3.9707,1.08398,5.91357c-0.00049,0.00049-0.00146,0.00098-0.00195,0.00146
        c0.00049,0.00098,0.00049,0.00195,0.00098,0.00342c0.07178-0.03955,0.14355-0.07422,0.21533-0.10596
        c0.13721-0.06787,0.27881-0.1167,0.42676-0.14502c0.02686-0.00635,0.05371-0.0127,0.08057-0.01807
        c0.04004-0.00537,0.0791-0.01318,0.11914-0.01563c0.31787-0.04053,0.60205,0.00146,0.79932,0.13623
        c0.9126,0.62695,4.86182-0.62402,5.4668,1.56592c0.19971,0.71484,1.01611,1.05713,2.01123,1.08252
        c2.05322,0.05029,4.87598-1.27051,4.66895-3.58838c-0.30518-3.44385,10.021-3.44385,10.93311-6.56836
        c0.05664-0.1958,0.11475-0.40137,0.17432-0.61426c0.89063-3.19629,2.10205-8.09473,4.37988-7.21533
        c2.42578,0.94092,3.94336-4.06641,6.06787-3.75c0.22852,0.0332,0.44873,0.0874,0.66504,0.15137
        c0.02344,0.00684,0.04736,0.01221,0.0708,0.01953c0.19189,0.05908,0.37988,0.12793,0.56592,0.20264
        c1.36865,0.54053,2.63721,1.37549,4.41846,1.03271l-0.00098-0.00928l-0.5835-5.21582
        c-0.00098-0.00049-0.00244-0.00098-0.00342-0.00146c-2.12061-1.1709-4.42969-1.9624-6.81982-2.34668
        c-0.56885,0.07471-1.24902-0.30957-2.01318-0.90918c-1.06738-0.8374-2.29834-2.09473-3.61572-3.10645
        c-0.75293-0.57813-1.53418-1.07617-2.3291-1.36914c-2.52441-0.76221-4.87305-1.99707-6.92676-3.63135
        c-0.01953-0.01563-0.03809-0.0332-0.05762-0.04883c-0.65967-0.5293-1.29053-1.09766-1.88477-1.70703
        c-0.04053-0.0625-0.09619-0.125-0.14404-0.1875c-0.05371-0.07031-0.10059-0.14063-0.16309-0.21094
        c-0.03613-0.04102-0.08203-0.08154-0.12109-0.12207c-1.19873-1.25537-3.64746-2.49658-5.39795-3.625
        c-0.02832-0.01807-0.05908-0.03711-0.08691-0.05518c-0.0708-0.0459-0.13379-0.09082-0.20166-0.13672
        c-0.10791-0.07227-0.21875-0.14502-0.31934-0.21631c-0.03174-0.02295-0.05811-0.04492-0.08936-0.06738
        c-0.12695-0.09229-0.25244-0.18457-0.36377-0.2749c-0.05469-0.04395-0.09814-0.08691-0.14893-0.13086
        c-0.07715-0.06689-0.15918-0.13428-0.22656-0.2002c-0.22217-0.21777-0.38525-0.42969-0.47021-0.63379
        c-0.2251-0.60156-0.4707-1.19434-0.73242-1.7793H0v21.34033c1.18506-0.87158,2.4165-1.52881,3.33105-1.52881
        C5.37354,186.46582,7.646,187.39795,8.78369,185.76172z"/>
      <path class="agm-0" d="M10.39648,284.56494c5.23096,1.05566,3.9707,7.96924,5.90723,10.43066
        c8.76416,2.55273,17.75,4.2832,26.83594,5.16162c2.65039,0.4873,5.34717,0.73193,8.03906,0.71582l-0.19434-1.98145
        c-0.08203-3.41748,1.80664-6.58398,4.8584-8.13525c2.17334-1.1499,4.5376-1.89258,6.98438-2.19189
        c2.43262-0.30615,0-5.31689-1.82861-5.94434c-1.82471-0.62695,0-7.20166-3.63525-8.76855
        c-3.41699-1.20459-6.24219-3.66797-7.90625-6.88135c-0.44678-0.78564-0.96533-1.52539-1.54248-2.21631
        c-0.65674-0.69287-1.39697-1.37012-2.04102-2.01904c-0.00781-0.00635-0.01416-0.01318-0.02197-0.01953
        c0.00098,0,0.00146,0,0.00244,0c-0.03027-0.02979-0.05811-0.05957-0.0874-0.08936c-0.0752-0.07715-0.15186-0.1543-0.22363-0.23047
        c-0.25488-0.26953-0.4834-0.5332-0.67578-0.79102c-0.00928-0.01221-0.01709-0.02393-0.02637-0.03613
        c-0.08447-0.11572-0.16016-0.22998-0.22754-0.34277c-0.0127-0.02148-0.02441-0.04248-0.03613-0.06348
        c-0.05908-0.10352-0.1084-0.20605-0.1499-0.30713c-0.00977-0.02441-0.02051-0.04883-0.02979-0.07275
        c-0.03564-0.09863-0.06055-0.1958-0.07715-0.2915c-0.00439-0.02344-0.01172-0.04736-0.01465-0.0708
        c-0.01318-0.10986-0.01465-0.21826,0.00195-0.32422c0.00098-0.00684-0.00098-0.01367,0-0.02051
        c0.28174-1.76221,8.02637-7.08643,8.00391-10.58545c-0.00293-0.23096-0.03906-0.4541-0.11475-0.66748
        c-1.2124-3.43896-6.98389-2.81494-7.58838-4.06689c-0.11377-0.23486-0.17383-0.58008-0.20654-1.00879
        c-0.08984-1.1416,0.01758-2.87842-0.17529-4.69971c-0.12109-1.13037-0.3584-2.29297-0.83154-3.36475
        c-0.28564-0.64941-0.42041-1.2876-0.45898-1.90869c-0.13818-2.14551,0.86768-4.08447,0.76514-5.55615
        c-0.02686-0.35791-0.11279-0.69043-0.30615-0.98828c-0.30469-0.46973-0.41846-1.05762-0.41309-1.69482
        c0.00879-1.91113,1.09473-4.26855,1.32568-5.20605c0.28174-1.16699,2.96436-2.89648,3.05225-5.68408
        c0.00537-0.18359,0-0.37158-0.01855-0.56494c-0.00049-0.00635,0-0.01172-0.00049-0.01807
        c-0.12793-0.7915,0.02881-1.57568,0.39795-2.24707c0.03955-0.07568,0.08057-0.14893,0.12451-0.21973
        c0.01709-0.02637,0.03418-0.05273,0.05176-0.07861c0.30518-0.46143,0.69238-0.8042,1.09277-1.02832
        c0.01074-0.00635,0.01953-0.01465,0.03027-0.021c0.00049-0.00049,0.00146-0.00098,0.00195-0.00146
        c-0.50439-1.94287-0.85498-3.9209-1.08398-5.91357c-0.14502-1.25879-0.25146-2.52246-0.28564-3.79053
        c0-0.06934-0.01465-0.13428-0.03955-0.1958c-0.38916-0.96924-3.70166-0.95703-5.41748-0.74219
        c-1.82422,0.23096-3.41016-4.4502-5.22754-4.4502c-1.47559,0.06152-2.78271-0.85645-3.26025-2.20459
        c-0.06885-0.19385-0.12109-0.39697-0.15332-0.60742c0-0.08594-0.01709-0.17041-0.04785-0.25293
        c-0.47412-1.26367-4.40674-2.07813-6.54004-1.85693c-2.27832,0.23291-2.73291-1.87695-3.64502-1.87695
        c-2.10498-0.29639-4.07275-1.19092-5.68262-2.57861c-1.13721-1.40527-7.7251-1.87207-8.87305-0.2334
        c-1.1377,1.63623-3.41016,0.7041-5.45264,0.7041c-0.91455,0-2.146,0.65723-3.33105,1.52881v99.81787
        C3.1875,286.84912,6.04346,283.68945,10.39648,284.56494z"/>
      <path class="agm-0" d="M107.48291,167.48096c-0.25781,0-0.45703,0.05664-0.61377,0.15674
        c-1.03174,0.66016-0.19727,3.21875-1.66748,4.16406c-0.07275,0.04688-0.14209,0.0957-0.22656,0.13428
        c-1.53516,0.70215-4.60547,0.74658-6.23535,1.61572c-0.54248,0.28906-0.92529,0.66943-1.03906,1.19629
        c-0.45361,2.10986,5.22803,3.51953,4.77734,6.0918c-0.45459,2.57813,2.95459,5.39502,0.91211,7.03369
        c-2.40186,2.03613-3.59863,5.15723-3.18164,8.27637c0.91846,0.60645,1.81201,1.16553,2.52832,1.16113
        c0.01221,0,0.02441-0.00049,0.03662-0.00049c0.12744-0.00439,0.24854-0.02979,0.36377-0.07422
        c0.01367-0.00537,0.02783-0.00879,0.04102-0.01465c0.11914-0.05225,0.23096-0.12842,0.33398-0.23291
        c0.00391-0.00439,0.00879-0.00635,0.0127-0.01074c0.10889-0.11328,0.20752-0.25684,0.29541-0.43701
        c1.21338-2.50391,7.896,0,7.896-2.19189c0-2.19287,7.28418-1.56494,7.28418-3.75293c0-2.18994,4.26123-7.19873,6.98145-6.88818
        c0.5127,0.05859,0.82129,0.27295,1.00732,0.59229c0.50244,0.84326,0.12891,2.43213,0.396,3.82764
        c0.17041,0.87109,0.59131,1.66602,1.6333,2.15527c0.62842,0.29443,0.96582,0.73389,1.1123,1.2666
        c0.54492,1.94189-1.46826,5.14111-1.20703,7.15869c0.04883,0.36426,0.17188,0.69043,0.39893,0.96289
        c0.68652,0.81787,2.00293,0.67432,3.62842,0.18604l4.10742-1.42334c1.99658-1.81592,4.46973-4.10742,6.29688-5.18164
        c0.90674-0.53369,1.65674-0.77246,2.10449-0.49365c1.70166,1.05469,1.36328,2.45996,2.95557,2.22461
        c0.33594-0.02197,0.67041-0.01367,1.00293,0.0083c0.01807,0.00098,0.03613,0.00244,0.0542,0.00391
        c0.66602,0.04883,1.31982,0.18164,1.9458,0.39893c0.0332,0.01123,0.06494,0.02539,0.09814,0.03711
        c0.21631,0.07861,0.42822,0.16602,0.63672,0.26416c0.08057,0.03613,0.16406,0.06201,0.24316,0.10059
        c-0.00049,0.00049-0.00098,0.00098-0.00146,0.00146l0.0874,0.05908c0.45996,0.24316,0.89844,0.53027,1.30371,0.86768
        l0.68115-0.12988l1.65283-0.32861c0.00488,0.00391,0.00977,0.00781,0.01465,0.01123h0.00195l0.04346-0.0083l0.1748-0.0332
        l0.00146-0.00049c0,0-0.34863-2.32568-0.71973-4.87207c-0.32373-2.2207-0.66455-4.6084-0.80762-5.77588
        c-0.0376-0.31152-0.18359-0.62305-0.40967-0.92969c-1.58984-2.15527-7.16113-4.07227-7.18066-4.0791l-0.00195-0.00049
        l-1.21289-6.88477c-0.00146,0-0.00244,0.00049-0.00391,0.00049c-3.30615,0.56982-6.66699,0.78369-10.02051,0.62646
        c-1.41895-0.5874-4.43994,0.75146-6.06787-0.88379c-0.10889-0.10986-0.21191-0.23242-0.30762-0.37012
        c-0.07568-0.10938-0.15674-0.19336-0.23828-0.27441c-1.5542-1.5459-4.14844,1.74756-6.73926-0.0376
        c-2.7334-1.87939-10.62891,0.62793-10.93018-0.93799c-0.00391-0.02051,0-0.04443-0.00244-0.06543
        c-0.20313-1.59229,3.36523-4.94238,5.4624-4.62793c0.20703,0.02148,0.41553,0.02783,0.62305,0.04102
        c0.21973,0.01416,0.43848,0.03125,0.65869,0.03613c0.36279,0.0083,0.72559,0.00732,1.0874-0.00879c0.00098,0,0.00146,0,0.00195,0
        c0.0957-0.1582,0.20557-0.34229,0.32031-0.53711h-10.4751C108.88965,167.16211,108.27295,167.48096,107.48291,167.48096z"/>
      <path class="agm-0" d="M51.78906,263.18896c1.97363,0.22314,3.93896,0.5127,5.89111,0.86816
        c10.42529-0.09717,20.80859-1.43311,30.92188-3.98193c0.45459-0.93994-0.9082-6.80273-0.68359-7.97217
        c1.24121-2.25244,3.56787-3.68213,6.13721-3.75537c0.00195,0,0.00342,0.00049,0.00488,0.00049
        c0.00195,0,0.00342-0.00049,0.00488-0.00049c0.25977,0.02246,0.5166,0.04688,0.77051,0.07129
        c2.41064,0.22803,4.45752,0.42432,4.45752-1.47852c0-1.97705,6.60107-4.37158,7.9834-3.11328
        c0.09326,0.0835,0.16357,0.18359,0.20605,0.30127c0.24707,0.67676,0.7627,1.01221,1.32959,0.9502
        c0.99609-0.11816,2.15137-1.4834,2.2998-4.4668c0.22852-4.68799-0.22998-8.20703,2.50391-8.43604
        c0.0708-0.00586,0.14111-0.01709,0.21191-0.0249c0.9707-0.10938,1.93164-0.38867,2.75098-0.77002
        c1.41699-0.66162,2.40381-1.62061,2.26514-2.48682c-0.22998-1.40039-3.41016-4.21924-2.49805-5.15918
        c0.91211-0.93213,7.73584,2.34717,8.64404,0.23779c0.90918-2.10986-0.45752-4.4624,0.68359-6.32764
        c1.13379-1.86719,3.87061-3.75195,3.63525-5.62793c-0.17676-1.46973,0.05518-2.51074-0.60596-3.68408
        c-0.18066-0.31982-0.41309-0.64746-0.75439-0.99805c-1.59473-1.64551-0.91113-3.75488,1.13525-3.98877l1.14795-0.50244
        c1.41455-0.17627,2.76758-0.66357,3.97021-1.43506c2.86768-2.21484,6.42139-3.33203,10.02637-3.18262
        c0.12256,0.00488,0.24414-0.00098,0.3667,0.00684c0.35547,0.0791,0.71289,0.13379,1.06982,0.16504
        c2.50049,0.21387,4.99268-0.729,6.72754-2.60156l0.00244,0.00146c0.00049-0.00049,0.00098-0.00098,0.00146-0.00146
        c-0.0791-0.03857-0.1626-0.06445-0.24316-0.10059c-0.20996-0.09473-0.4209-0.18799-0.63672-0.26416
        c-0.0332-0.01172-0.06494-0.02588-0.09814-0.03711c-0.63037-0.21338-1.2832-0.34766-1.9458-0.39893
        c-0.01807-0.00146-0.03613-0.00293-0.0542-0.00391c-0.33252-0.02344-0.66748-0.02734-1.00293-0.0083
        c-1.59229,0.23535-1.25391-1.16992-2.95557-2.22461c-0.44775-0.27881-1.19775-0.04004-2.10449,0.49365
        c-1.82617,1.0752-4.29932,3.36865-6.29688,5.18652l-3.99463,1.39258c-1.67725,0.51221-3.03711,0.68555-3.74121-0.15234
        c-0.22803-0.27441-0.35107-0.60303-0.39893-0.9707c-0.26123-2.01758,1.75195-5.2168,1.20703-7.15869
        c-0.14844-0.52881-0.48633-0.96484-1.1123-1.25781c-1.0459-0.49121-1.46533-1.28955-1.6333-2.16406
        c-0.26709-1.39551,0.10645-2.98438-0.396-3.82764c-0.1875-0.31445-0.49658-0.52588-1.00732-0.58398
        c-2.72998-0.31006-6.98145,4.69678-6.98145,6.88867c0,2.18799-7.28418,1.56104-7.28418,3.75342
        c0,2.19189-6.68262-0.31299-7.896,2.19189c-0.08691,0.1792-0.1875,0.31494-0.29541,0.42773
        c-0.00391,0.00439-0.00879,0.00635-0.0127,0.01074c-0.10303,0.10498-0.21484,0.18018-0.33398,0.23291
        c-0.01318,0.00586-0.02734,0.00928-0.04102,0.01465c-0.11475,0.04443-0.23633,0.06934-0.36377,0.07422
        c-0.01221,0-0.02441,0.00049-0.03662,0.00049c-0.71729,0.00537-1.61084-0.54834-2.53125-1.15869
        c-0.00977-0.00635-0.01953-0.0127-0.0293-0.01904c-0.52441-0.34814-1.0542-0.70264-1.56445-0.979
        c-0.50732-0.27393-0.99268-0.46582-1.42871-0.47705c-0.01367-0.00049-0.02832-0.00488-0.04199-0.00488
        c-0.66455,0-1.27002-0.30615-1.87158-0.69873c-0.72168-0.47021-1.43848-1.06445-2.24609-1.40283
        c-0.67285-0.28174-1.4082-0.38525-2.26221-0.0918c-2.14258,0.73682-3.54102-0.25977-5.07715-0.87207
        c-0.18604-0.07471-0.37402-0.14355-0.56592-0.20264c-0.02344-0.00732-0.04736-0.0127-0.0708-0.01953
        c-0.21631-0.06396-0.43652-0.11816-0.66504-0.15137c-2.12451-0.31641-3.64209,4.69092-6.06787,3.75
        c-2.27783-0.87939-3.48926,4.01904-4.37988,7.21533c-0.05957,0.21289-0.11768,0.41846-0.17432,0.61426
        c-0.91211,3.12451-11.23828,3.12451-10.93311,6.56836c0.20703,2.32227-2.61572,3.64063-4.66895,3.58838
        c-0.99512-0.02539-1.81152-0.36768-2.01123-1.08252c-0.60498-2.18994-4.5542-0.93896-5.4668-1.56592
        c-0.19727-0.13477-0.48145-0.17676-0.79932-0.13623c-0.03955,0.00488-0.07861,0.00781-0.11914,0.01563
        c-0.02686,0.00537-0.05371,0.01172-0.08057,0.01807c-0.13916,0.03223-0.28223,0.08203-0.42676,0.14502
        c-0.07178,0.03174-0.14355,0.06641-0.21533,0.10596c-0.01074,0.00537-0.021,0.01172-0.03125,0.01758
        c-0.40039,0.22412-0.7876,0.56689-1.09277,1.02832c-0.01709,0.02637-0.03516,0.05176-0.05176,0.07861
        c-0.04395,0.0708-0.08496,0.14404-0.12451,0.21973c-0.30811,0.59082-0.48291,1.3374-0.39795,2.24707
        c0.00049,0.00635,0,0.01172,0.00049,0.01807c0.01855,0.19336,0.02393,0.38135,0.01855,0.56494
        c-0.08154,2.79785-2.77002,4.52637-3.05225,5.7002c-0.22803,0.93848-1.31006,3.28369-1.32568,5.18994
        c-0.00537,0.63721,0.1084,1.2251,0.41309,1.69482c0.19336,0.29785,0.2793,0.63037,0.30615,0.98828
        c0.10938,1.46826-0.89893,3.40723-0.76514,5.55615c0.03857,0.62109,0.17334,1.25928,0.45898,1.90869
        c0.47314,1.07178,0.71045,2.23438,0.83154,3.36475c0.19482,1.81982,0.08789,3.55615,0.17529,4.69971
        c0.03271,0.42871,0.09277,0.77393,0.20654,1.00879c0.60449,1.25195,6.37598,0.62793,7.58838,4.06689
        c0.07568,0.21338,0.11182,0.43652,0.11475,0.66748c0.04199,3.49609-7.72168,8.8418-8.00391,10.60156
        c0,0.00146,0,0.00293,0,0.00439c-0.0166,0.10596-0.01514,0.21436-0.00195,0.32422c0.00244,0.02344,0.01074,0.04736,0.01465,0.0708
        c0.0166,0.0957,0.0415,0.19287,0.07715,0.2915c0.00879,0.02393,0.02002,0.04834,0.02979,0.07275
        c0.0415,0.10107,0.09082,0.20361,0.1499,0.30713c0.01172,0.021,0.02344,0.04199,0.03613,0.06348
        c0.06738,0.11279,0.14307,0.22705,0.22754,0.34277c0.00928,0.01221,0.01709,0.02393,0.02637,0.03613
        c0.19238,0.25781,0.4209,0.52148,0.67578,0.79102c0.07227,0.07617,0.14795,0.15332,0.22363,0.23047
        c0.0293,0.02979,0.05713,0.05957,0.0874,0.08936C47.83691,262.80762,49.81689,262.96533,51.78906,263.18896z"/>
      <path class="agm-0" d="M110.48633,308.60156c2.13672,0.41064,3.27539,0.27686,4.11523,0.05225
        c0.47852-0.12793,0.86133-0.28516,1.27441-0.38818c0.50781-0.12695,1.06348-0.17236,1.9082,0.02197
        c1.71387,0.39209,3.06738,0.41455,4.13818-0.08643c0.63525-0.29688,1.17139-0.77734,1.62256-1.47559
        c1.21631-1.87939-1.21924-5.63721-2.72656-7.2002c-2.13477-2.17529-3.33398-5.09131-3.34131-8.13623
        c0.29736-3.13086-4.86914-3.13086-4.86914-5.63672c0-2.50098,4.86914-6.57031,5.78174-7.51025
        c0.05713-0.05908,0.12646-0.10059,0.20654-0.12744c1.02637-0.34375,3.81006,1.80029,5.08447,1.22314
        c0.19385-0.08789,0.35352-0.23828,0.46582-0.4707c0.22803-0.47266,0.57227-0.74609,0.98389-0.88672
        c1.22266-0.41846,3.04102,0.34082,4.17871,0.57568c0.19238,0.03955,0.39941,0.01758,0.61475-0.05469
        c1.48438-0.49756,3.37061-3.43262,3.63525-5.88818c0.11377-1.06104,0.91943-1.7666,1.92725-2.28564
        c1.16943-0.60156,2.61035-0.95117,3.56299-1.30859c0.40381-0.15137,0.72021-0.30469,0.88965-0.479
        c1.1958-2.74854,2.20703-5.56934,3.03711-8.44775c0.11572-0.23926,0.35498-0.39795,0.66406-0.52344
        c0.50439-0.2041,1.19678-0.31836,1.85889-0.54053c1.0542-0.354,2.02832-0.9834,2.02832-2.69629
        c0-2.37402,0.86719-3.4082,2.00098-3.9248c0.51123-0.23291,1.07617-0.36133,1.64111-0.45898
        c0.00293-0.00049,0.00488-0.00195,0.00781-0.00244c1.81396-0.32178,1.20898-3.43652,1.5127-5.94043
        c0.03076-2.58643,1.57471-4.91309,3.94287-5.94824c0.00586-0.00146,0.00977-0.00439,0.01563-0.00586
        c0.14551-0.03906,0.27197-0.08691,0.37695-0.14502c1.62061-0.89355-1.47412-3.87451-4.03174-3.28809
        c-2.73242,0.625-4.5542-7.51709-4.5542-7.51709s0.30029-6.56885-1.21631-7.50684
        c-1.23633-0.76514-0.06201-3.18506,2.21631-4.06299c0.52344-0.20166,1.10547-0.32227,1.72998-0.32227
        c3.13965,0,8.68604,3.31445,11.1123,2.41699c0.15674-0.05811,0.30127-0.13184,0.43018-0.22656
        c1.21582-0.64502,2.46143-1.22998,3.7334-1.75391c0.47021-0.19434,0.94775-0.37158,1.42529-0.54883
        c0.16016-0.05908,0.31689-0.12598,0.47803-0.18359c0.64453-0.23047,1.29492-0.44531,1.95117-0.64502
        c0.00098-0.00049,0.00195-0.00146,0.00244-0.00195c0.92236-0.47705,2.18848-3.47119,2.75439-4.88135
        c-0.96387-0.61523-1.94531-1.19678-2.94531-1.74512c-0.99951-0.54834-2.01758-1.06299-3.0542-1.54492
        c-1.17822-0.52686-2.15039-1.37305-2.83252-2.41113c-0.68262-1.03906-1.07422-2.27051-1.08984-3.56494
        c0-0.22949-0.25195-0.56006-0.67578-0.96387c-1.17871-1.12305-3.68799-2.81201-5.81494-4.47363
        c-0.17236-0.13477-0.34277-0.26953-0.50928-0.40381c-0.00488-0.00342-0.00977-0.00732-0.01465-0.01123l-1.65283,0.32861
        l-0.68115,0.13574l-1.30371-0.87354l-0.0874-0.05908l-0.00244-0.00146c-1.73486,1.87256-4.22705,2.81543-6.72754,2.60156
        c-0.36035-0.03076-0.72119-0.08545-1.07959-0.16504c-0.11914-0.00781-0.23779-0.00195-0.35693-0.00684
        c-3.60498-0.14941-7.15869,0.96777-10.02637,3.18262c-1.20264,0.77148-2.55566,1.25879-3.97021,1.43506l-1.14795,0.50244
        c-2.04639,0.23389-2.72998,2.34326-1.13525,3.98877c0.34131,0.35107,0.57422,0.67822,0.75439,0.99805
        c0.66113,1.17334,0.4292,2.21436,0.60596,3.68408c0.23535,1.87598-2.50146,3.76074-3.63525,5.62793
        c-1.14111,1.86523,0.22559,4.21777-0.68359,6.32764c-0.9082,2.10938-7.73193-1.16992-8.64404-0.23779
        c-0.91211,0.93994,2.26807,3.75879,2.49805,5.15918c0.13867,0.86621-0.84814,1.8252-2.26514,2.48682
        c-0.81836,0.38184-1.77881,0.66162-2.75098,0.77002c-0.0708,0.00781-0.14111,0.01904-0.21191,0.0249
        c-2.73389,0.229-2.27539,3.74805-2.50391,8.43604c-0.14844,2.9834-1.30371,4.34863-2.2998,4.4668
        c-0.56836,0.06689-1.08545-0.26807-1.33252-0.9502c-0.04248-0.11768-0.11133-0.21729-0.20313-0.30127
        c-1.38232-1.2583-7.9834,1.13623-7.9834,3.11328c0,1.90283-2.04688,1.70654-4.45752,1.47852
        c-0.25537-0.02393-0.51367-0.04834-0.77539-0.0708c-0.00146,0-0.00293-0.00049-0.00488-0.00049
        c-2.56934,0.07324-4.896,1.50293-6.13721,3.75537c-0.22461,1.16943,1.13818,7.03223,0.68359,7.97217
        c-10.11328,2.54883-20.49658,3.88477-30.92188,3.98193c-1.95361-0.35498-3.91895-0.64404-5.89111-0.86816
        c-1.97217-0.22363-3.95215-0.38135-5.93506-0.47363c-0.00098,0-0.00146,0-0.00244,0
        c0.00781,0.00635,0.01416,0.01318,0.02197,0.01953c0.74658,0.60791,1.42773,1.28516,2.04102,2.01904
        c0.57715,0.69092,1.0957,1.43066,1.54248,2.21631c1.66406,3.21338,4.48926,5.67676,7.90625,6.88135
        c3.63525,1.56689,1.81055,8.1416,3.63525,8.76855c1.82861,0.62744,4.26123,5.63818,1.82861,5.94434
        c-2.44678,0.29932-4.81104,1.04199-6.98438,2.19189c-3.05176,1.55127-4.94043,4.71777-4.8584,8.13525l0.19434,1.98145v0.00049
        c4.80762,0.42285,9.65234,0.06445,14.34033-1.06982c1.55811-1.56982,3.95313-1.9541,5.91748-0.94092
        c4.01465,1.43408,8.39844,1.39551,12.39209-0.11182c3.73535-2.14355,6.02734,0,6.13721,1.87793
        c0.10889,1.87598,1.8208,2.69727,3.51953,4.33545c1.70752,1.64209,0.46045,3.51416,1.59473,5.39014
        c1.1377,1.87891,3.41309,1.52344,6.71045,3.04785l1.22754,0.78271l0.49512-0.57471
        c1.25049-1.50684,1.78662-3.75781,3.57861-4.7085C107.9043,308.47119,108.97412,308.30762,110.48633,308.60156z"/>
      <path class="agm-1" d="M483.37891,584.45215v0.00781c1.36328,2.10742,5.22852,2.10742,6.81836,3.51367
        c1.59961,1.40527,7.27539,4.45605,7.27539,0.47559C497.47266,584.46582,481.86133,582.11426,483.37891,584.45215z"/>
      <path class="agm-1" d="M466.31836,518.83496v0.00586c3.73438-2.85254,5.49805-7.5957,4.54883-12.19531
        c-0.45508-4.68164,10.00391-4.91895,11.59961-6.79492c1.5957-1.87012-2.27734-4.9209-5.23438-6.32715
        c-2.94922-1.4043-0.45117-4.45313-2.50195-6.3291c-2.04297-1.86816-5.45703-6.79883-2.50391-7.03125
        c2.95117-0.22949-2.04688-4.44922,0.45117-5.85547l1.14063-0.60645c-1.11328-0.66113-2.35352-1.0918-3.63867-1.26953
        c-3.64258,0-12.27539-0.9375-11.82422,5.15234c0.43164,5.71387-4.34082,4.01465-4.57031,6.47754
        c-0.01563,0.16406-0.01074,0.34766,0.01758,0.55273c0.02832,0.2041,0.04297,0.39453,0.04492,0.5752
        c0.03027,2.71875-2.73926,2.97266-2.3125,6.93359c0.44727,4.21484-2.27344,2.33301-6.82422,4.67871
        c-4.55078,2.35156-2.73242-1.40234-7.27344-1.40234c-4.55273,0-5.01563,2.34375-7.73633,3.27637
        c-2.72461,0.93555-8.18555-0.46582-10.00391,0.94336c-1.82031,1.40234-4.54883-3.28125-6.37305-3.28125
        c-0.51953,0-1.00098-0.81055-1.4248-1.9668c-0.3291-0.89844-0.62305-2.00586-0.87207-3.10449h-0.00293l-0.87793-0.08887
        c-2.94727,0.23535-7.84375,8.28027-5.00195,11.0127c3.41406,3.29004,2.50586,8.43652,4.0918,11.02246
        c1.58398,2.58691,6.59961,3.04883,6.59961,7.96777c0,4.9248,4.3125,12.64844,7.2793,10.54883
        c2.94727-2.10645,5.21875,0.94238,6.13477,3.04688c0.90234,2.11523,7.95117-1.64063,10.23438-1.16895
        c2.26758,0.46875,9.31641,1.87598,9.77734,4.68848c0.46289,2.81152,6.82227-0.23926,11.14063-1.87988
        C464.73047,534.77148,462.23047,521.41016,466.31836,518.83496z"/>
      <path class="agm-1" d="M472.00391,572.97559v0.00195c-0.9082,2.11035-4.77539,2.57715-7.04883,0.93359
        c-2.26563-1.63672-5.08398,2.22852-3.63867,4.21973c2.91797,2.9834,7.42578,3.7334,11.1543,1.88379
        c5.68359-2.81543,9.54688-0.24023,10.91016-3.29102C484.74609,573.6748,472.91797,570.86426,472.00391,572.97559z"/>
      <path class="agm-1" d="M556.82617,504.53418v0.00391c5.01563,0.4707,2.72852-2.57422,2.72852-5.8623
        c0-3.28027-4.55273-0.46582-4.55273-2.10938c0-1.63672-1.58984-8.19531-5.22461-3.04297
        c-3.63672,5.15234,1.17969,17.88867,4.08789,17.3418C556.37695,510.39746,551.82422,504.07031,556.82617,504.53418z"/>
      <path class="agm-1" d="M568.875,530.55078v0.00195c-5.91016-1.87598-16-0.27344-15.00977,2.8125
        c0.91406,2.81152,5.68945,1.64648,10.4707,1.64648c4.77539,0,7.26953,3.5127,9.77734,3.5127
        C576.61328,538.52441,574.78906,532.42285,568.875,530.55078z"/>
      <path class="agm-1" d="M527.05469,580.26953c-0.19141,0.83008-0.57617,1.60938-1.12695,2.2666
        c-0.64648,0.51074-1.73633-0.50879-2.51172-1.42188c-4.39453,1.87207-6.71094,7.29785-3.66016,8.96777
        c2.93945,1.62012,7.21289-2.65625,9.64648-5.67773l1.04102-1.35254l0.76563-0.71191l0.77148-0.52441
        c-0.67383-0.98926-1.49219-1.87402-2.41797-2.63867v0.00781C528.79883,579.70313,527.94922,580.07227,527.05469,580.26953z"/>
      <path class="agm-1" d="M520.21875,573.44336v0.00391c-6.13867-0.93262-10.23438,3.75195-16.37695,1.40625
        c-6.13672-2.34961-15.32813,0.04199-14.32422,2.1084c0.91406,1.87988,6.36133,2.10938,12.50781,2.3457
        c5.31445-0.72949,10.5625-1.90039,15.68555-3.5127C521.79883,575.55078,526.35742,574.38281,520.21875,573.44336z"/>
      <path class="agm-1" d="M449.04102,570.86426v0.00391c-0.22656-2.81348-9.09961-0.23438-13.41797-2.11035
        c-4.32227-1.87793,4.77539-3.51367,7.72852-5.38965c2.95898-1.87207-1.82227-2.57715-9.0918-0.23438
        c-7.27344,2.34766-15.69336-5.1543-15.92188-1.87402c-0.22852,3.27734-13.19336-1.4043-16.37305-3.74805
        c-3.18164-2.34473-15.45752-5.3916-17.96533-0.94043c-2.50391,4.45508-4.66309,0.45703-3.62891,4.68848
        c0.67773,2.81152,3.62891,1.1709,5.67578,1.1709c2.04932,0,1.14453,3.98438,4.09619,4.44922
        c2.95117,0.46777,12.73438,3.28711,13.64258,1.1748c0.91016-2.10742,10.00586,0,14.55469,3.75195
        c5.82227,3.19336,12.54883,4.34863,19.10156,3.28125c4.08789-0.70508,8.63867,2.81836,10.23633,0.4668
        c1.58789-2.34277,4.54102,3.75293,9.08984,0.24023C461.32617,572.27832,449.27539,573.68262,449.04102,570.86426z"/>
      <path class="agm-1" d="M628.00586,519.76563c-3.41016,0.24023-3.87109,6.33984-6.82422,6.33984
        c-2.95898,0-7.05078,6.32715-12.51172,8.20313c-5.45313,1.87598-5.68945-16.1709-8.1875-19.6875
        c-2.49414-3.5166-16.13867-6.32715-17.50586-2.34766c-1.36328,3.98828-6.10547,1.87988-6.59375,5.15918
        c-0.45508,3.04785,1.14063,2.10938,4.0918,2.10938c2.95508,0,2.95508,2.33594,4.31836,5.38672
        c1.37109,3.04395,9.55664,0.93652,12.28516,0.70508c2.7207-0.24023,3.17773,3.75195-0.6875,2.33887
        c-3.86133-1.4043-3.86523,1.87598-6.5918,1.17285c-2.73242-0.70117-6.36914-0.46875-4.55859,1.16895
        c1.82617,1.64648,4.77734,3.29102,4.77734,7.03125c0,3.74805,5.24023,3.05078,5.24023,0s2.73047,0.94238,7.95508,2.34668
        c5.22852,1.40918,1.5957,3.5166,6.82031,3.75684c7.67578,0.98047,15.04883,3.62305,21.60156,7.7334
        c4.55078,3.5127,1.36914,5.84961,4.32422,9.14453c2.95898,3.27637,3.86523,7.03027-0.23242,7.03027
        c-4.0918,0-8.4082,4.21973-7.27344,6.32715c1.13672,2.11035,14.55469-0.94238,17.74023-0.94238
        c2.08398,0,4.18164,3.81348,8.18945,6.58496v-0.00586v-50.02246C645.76953,525.63379,636.9668,522.44824,628.00586,519.76563z"/>
      <path class="agm-1" d="M482.24414,527.9668c-4.08984,6.80469,1.34961,7.74219,4.76953,7.97168v0.00977
        c3.41016,0.22852,2.95703,5.39063,2.27539,8.43555c-0.68164,3.04102-2.06055,10.28516,0.9082,9.14063
        c3.64844-1.4043,5.9082-6.0918,3.86133-7.73242c-2.04297-1.63965-1.81055-11.95117-0.9082-14.53418
        c0.9043-2.57324,7.05469-0.69336,5.00977,3.28223c-2.05469,3.98438,3.86523,3.5166,3.86523,7.97559
        c0,4.44727,3.4043,2.10352,7.04688,0.93359c3.63672-1.16895,2.96289-3.29102,0.45117-6.10352
        c-2.49414-2.81152,1.82422-6.79492-1.58594-9.14258c-3.41211-2.35156-5.68945-5.86035-2.50391-6.56348
        c3.19141-0.70508,12.73828-6.3291,11.59961-8.66895c-1.13672-2.34766-14.55078,0.46387-15.68945,4.68359
        c-1.14844,4.21777-10.6875-0.46387-9.55273-7.73438c1.13672-7.26465,20.01563-8.43652,25.4668-5.85938
        c5.46484,2.58301,11.375-4.21777,13.41797-8.20215c2.04688-3.99316-4.31836,0.70215-8.18945,3.28125
        c-3.86719,2.58301-14.0957-0.23633-19.32422-2.1084s-5.00977,3.28125-9.32813,3.97852
        c-4.31641,0.70703-3.41016,10.55664-5.45313,11.72168C486.33398,513.90039,486.33398,521.16992,482.24414,527.9668z"/>
      <path class="agm-1" d="M400.83008,530.31445c0-2.10938-5.86914-1.08691-4.0918,1.87793
        C398.55859,535.2373,400.83008,532.42285,400.83008,530.31445z"/>
      <path class="agm-1" d="M538.17578,533.8291v0.01172c0.91406,3.74414,7.5,5.85156,9.32813,2.5752
        C549.33008,533.13965,537.125,529.48438,538.17578,533.8291z"/>
      <path class="agm-1" d="M315.32324,495.8584c-0.45166,2.34766,2.05322,9.14453,4.77637,6.33008
        C322.8291,499.37109,315.771,493.57129,315.32324,495.8584z"/>
      <path class="agm-1" d="M383.09424,542.03809c0.14307-2.76074,0.52539-5.50488,1.13086-8.19727
        c0-1.64453,1.13379-2.3457,3.18359-1.88574c2.04639,0.46387,1.82373-4.45313,0.23389-4.9209
        c-1.59619-0.46582-2.50537-6.32715-6.37256-6.32715c-3.87109,0-3.17773,3.27832-0.90918,3.51367
        c2.28223,0.24023,3.63477,2.11035,2.95508,3.76074c-0.67969,1.64551-1.58496-1.64844-4.77637-1.40918
        c-3.18066,0.24023-1.13477-3.9834-4.76953-4.21973c-3.63965-0.23535-2.95996-7.02832-6.82324-7.02832
        c-3.86816,0-4.10059-3.28223-1.59277-5.62598c2.50391-2.33789-3.87109-4.45508-3.87109-7.26074
        c0-2.8125-4.08984-1.17676-4.08984-3.52344c0-2.33984-1.82129-1.16895-3.86426-3.98047
        c-2.0459-2.81055-2.95801-0.23926-4.77637-2.34766c-1.81836-2.11133-4.32227-3.27637-5.9082-2.5752
        c-1.58887,0.70313-5.2373-3.04883-6.60352-6.79688c-1.07666-2.98535-11.59375-7.96777-12.95361-11.95703
        c-1.37012-3.98828-5.00537-5.62402-11.13818-5.38672c-6.14648,0.23633-8.56641-6.19629-10.01416-3.04688
        c-1.81055,3.98828,6.60059,12.89453,10.46436,15.23438c3.86523,2.34766,5.91162,10.77637,10.45605,11.25195
        c4.55127,0.47559,4.99805,15.23828,8.41113,15.93945c3.41455,0.70313,10.69141,12.1875,11.83008,18.28711
        c1.13379,6.10059,8.64355,9.1416,11.14453,13.59766c2.50879,4.44922,12.50586,10.54492,13.86914,12.4209
        c1.37109,1.87207,2.7334,6.08691,3.87109,3.97949c1.14063-2.10742,5.91113,0,8.18945,0.23926
        C382.64648,554.00586,383.31543,545.31641,383.09424,542.03809z"/>
      <path class="agm-1" d="M326.46826,516.02148c-0.45459,1.16895,2.04639,5.85645,3.63232,4.44922
        C331.68555,519.06641,327.90674,512.31543,326.46826,516.02148z"/>
      <path class="agm-1" d="M525.90039,580.47559c-0.85352,0.07324-1.69336,0.28516-2.48438,0.63281
        c0.77734,0.91113,1.87109,1.93848,2.50781,1.42383c0.55469-0.65527,0.93945-1.43262,1.12695-2.26855l-1.01953,0.18066
        L525.90039,580.47559z"/>
      <path class="agm-1" d="M536.8125,574.38281c-3.07422-0.16113-4.30859,2.44629-6.58984,4.31641l-0.66016,0.47754
        c0.92578,0.75488,1.74609,1.64551,2.42773,2.63477c5.53516-2.25488,10.94531-4.8125,16.19336-7.6709
        C548.86914,572.27441,541.36719,574.61816,536.8125,574.38281z"/>
    </g>
  </g>
  </svg>
</template>

<script>
export default {
  name: "asiaGlobalMap"
}
</script>

<style>
  .agm-0{fill:#6E6E6E;stroke:#000000;stroke-width:0.5;}
  .agm-1{fill:#3367B3;stroke:#000000;stroke-width:0.5;}
  .agm-2{fill:none;stroke:#000000;stroke-width:0.5;}
  .agm-3{fill:#252525;}
</style>

.photo-in {
  position: relative;
}
.wrap-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 1;
  transition: all 0.3s ease;
  cursor: pointer;
}
.photo-profile .yellow-check {
  z-index: 2;
}
.wrap-file input[type='file'] {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.wrap-file i {
  opacity: 0;
  transition: all 0.3s ease;
  position: absolute;
  font-size: 1.8rem;
  top: calc(50% - 9px);
  left: calc(50% - 9px);
  z-index: 1;
}
.wrap-file:hover {
  background: rgba(0, 0, 0, 0.5);
}
.wrap-file:hover i {
  opacity: 1;
  color: #eee;
}
.photo-profile .photo-in img {
  margin-top: 0;
}

.fb-btn-group{
  margin-bottom: 10px;
}

<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 166.91553 800 604.58447">
  <g>
    <path class="in-i-1" d="M401.27246,170.19678c-99.92041,0-184.96533,63.64209-216.84277,152.59717h433.68457
      C586.23633,233.83887,501.19238,170.19678,401.27246,170.19678z"/>
    <path class="in-i-2" d="M170.97949,400.48926c0,27.2666,4.75146,53.4209,13.45068,77.69531h433.68408
      c8.69824-24.27441,13.4502-50.42871,13.4502-77.69531s-4.75195-53.4209-13.4502-77.69531H184.42969
      C175.73096,347.06836,170.97949,373.22266,170.97949,400.48926z M401.27246,338.3335c34.32813,0,62.1543,27.82764,62.1543,62.15625
      s-27.82617,62.15576-62.1543,62.15576s-62.15625-27.82715-62.15625-62.15576S366.94434,338.3335,401.27246,338.3335z"/>
    <path class="in-i-0" d="M401.27246,630.78125c99.91992,0,184.96387-63.6416,216.8418-152.59668H184.43018
      C216.30762,567.13965,301.35205,630.78125,401.27246,630.78125z"/>
    <path class="in-i-3" d="M401.27246,462.64551c34.32813,0,62.1543-27.82715,62.1543-62.15576s-27.82617-62.15625-62.1543-62.15625
      s-62.15625,27.82764-62.15625,62.15625S366.94434,462.64551,401.27246,462.64551z M344.6543,393.03516
      c0.19629-1.48926,1.5625-2.53711,3.05176-2.34131c0.02539,0.00342,0.04834,0.01318,0.07324,0.01709
      c0.53125-2.92188,1.2876-5.76465,2.26563-8.50439c-0.02002-0.00732-0.04053-0.00977-0.06055-0.01807
      c-1.38672-0.57471-2.0459-2.16553-1.4707-3.55273c0.57422-1.3877,2.16504-2.04688,3.55273-1.47217
      c0.01953,0.0083,0.03516,0.021,0.05469,0.0293c0.63184-1.33008,1.32275-2.625,2.05664-3.89307l-0.00586-0.00391l0.00635,0.00293
      c0.73682-1.27344,1.52002-2.51611,2.35449-3.72168c-0.02051-0.01465-0.04297-0.02393-0.06299-0.03906
      c-1.19238-0.91455-1.41699-2.62109-0.50293-3.81299c0.91504-1.19092,2.62207-1.41602,3.81348-0.50146
      c0.02148,0.0166,0.03711,0.0376,0.05811,0.05469c1.9043-2.23828,3.98486-4.31934,6.22314-6.22314
      c-0.01709-0.021-0.03809-0.03662-0.05469-0.05811c-0.91504-1.19189-0.68945-2.89893,0.50098-3.81299
      c1.19238-0.91406,2.89941-0.68945,3.81348,0.50195c0.01563,0.02002,0.02441,0.04297,0.03906,0.06299
      c1.20557-0.83447,2.44824-1.61719,3.72168-2.354l-0.00293-0.00586l0.00342,0.00537
      c1.26855-0.73389,2.56348-1.42529,3.89404-2.05664c-0.0083-0.01953-0.02148-0.03516-0.0293-0.05469
      c-0.5752-1.3877,0.08398-2.97852,1.47168-3.55322s2.97754,0.08447,3.55273,1.47168c0.0083,0.01953,0.01025,0.04004,0.01807,0.06006
      c2.73975-0.97803,5.58252-1.73486,8.50537-2.26563c-0.00439-0.02539-0.01416-0.04785-0.01758-0.07373
      c-0.19629-1.48877,0.85156-2.85449,2.34082-3.05078c1.48926-0.1958,2.85449,0.85254,3.05176,2.34131
      c0.00391,0.02783-0.00049,0.05469,0.00244,0.08203c1.45264-0.11621,2.91699-0.19238,4.3999-0.19238
      c1.48242,0,2.94727,0.07617,4.39941,0.19238c0.00293-0.02734-0.00098-0.0542,0.00293-0.08203
      c0.19727-1.48877,1.5625-2.53711,3.05078-2.34131c1.49023,0.19629,2.53711,1.56201,2.3418,3.05078
      c-0.00293,0.02588-0.01367,0.04834-0.01758,0.07373c2.92285,0.53076,5.76563,1.2876,8.50586,2.26563
      c0.00684-0.02002,0.00977-0.04053,0.01758-0.06006c0.57422-1.38721,2.16406-2.04639,3.55273-1.47168
      c1.38672,0.57471,2.04688,2.16553,1.4707,3.55322c-0.00781,0.01953-0.02051,0.03516-0.0293,0.0542
      c1.33105,0.63184,2.62598,1.32324,3.89453,2.05713l0.00391-0.00537l-0.00293,0.00586
      c1.27344,0.73682,2.51563,1.51953,3.72168,2.354c0.01465-0.02002,0.02344-0.04297,0.03906-0.06299
      c0.91406-1.19141,2.62109-1.41602,3.8125-0.50195s1.41602,2.62109,0.50195,3.81299
      c-0.0166,0.02148-0.03809,0.03711-0.05469,0.05811c2.23828,1.90381,4.31934,3.98486,6.22266,6.22314
      c0.02148-0.01709,0.03711-0.03809,0.05859-0.05469c1.19141-0.91455,2.89844-0.68945,3.8125,0.50146
      c0.91406,1.19189,0.68945,2.89844-0.50195,3.81299c-0.02051,0.01514-0.04297,0.02441-0.0625,0.03906
      c0.83398,1.20557,1.61719,2.44824,2.35352,3.72168l0.00586-0.00293l-0.00488,0.00342
      c0.7334,1.26807,1.4248,2.56299,2.05664,3.89307c0.01855-0.0083,0.03418-0.021,0.05371-0.02881
      c1.38672-0.57471,2.97852,0.08447,3.55273,1.47217c0.57617,1.38721-0.08203,2.97803-1.4707,3.55273
      c-0.01953,0.0083-0.04004,0.01025-0.05957,0.01807c0.97754,2.73926,1.73438,5.58203,2.26563,8.50439
      c0.02539-0.00391,0.04785-0.01367,0.07324-0.01709c1.48828-0.1958,2.85547,0.85205,3.05078,2.34131
      s-0.85156,2.85498-2.33984,3.05127c-0.02832,0.00391-0.05566-0.00049-0.08301,0.00244
      c0.11621,1.45313,0.19238,2.91797,0.19238,4.40088s-0.07617,2.94775-0.19238,4.3999
      c0.02832,0.00293,0.05469-0.00098,0.08301,0.00293c1.48828,0.19531,2.53516,1.5625,2.33984,3.05176
      s-1.5625,2.53613-3.05078,2.33887c-0.02539-0.00293-0.04785-0.01367-0.07324-0.01758
      c-0.53027,2.92285-1.28711,5.76563-2.26465,8.50586c0.01953,0.00781,0.03906,0.00977,0.05859,0.01758
      c1.38867,0.57617,2.04688,2.16504,1.4707,3.55371c-0.57422,1.38867-2.16602,2.0459-3.55273,1.47168
      c-0.01953-0.00781-0.03418-0.02051-0.05371-0.0293c-0.63184,1.33105-1.32324,2.62695-2.05664,3.89453l0.00488,0.00391
      l-0.00586-0.00293c-0.73633,1.27344-1.51953,2.5166-2.35449,3.72168c0.02051,0.01465,0.04297,0.02344,0.06348,0.03906
      c1.19141,0.91309,1.41602,2.62012,0.50195,3.81152c-0.91406,1.19238-2.62109,1.41797-3.8125,0.50195
      c-0.02148-0.0166-0.03711-0.03809-0.05762-0.05469c-1.9043,2.23828-3.98535,4.31934-6.22363,6.22266
      c0.0166,0.02148,0.03809,0.03711,0.05469,0.05859c0.91406,1.19238,0.68945,2.89941-0.50195,3.81348
      s-2.89844,0.69043-3.8125-0.50195c-0.01563-0.02051-0.02441-0.04297-0.03906-0.06348
      c-1.20605,0.83398-2.44824,1.61719-3.72168,2.35352l0.00293,0.00586l-0.00391-0.00488
      c-1.26855,0.7334-2.56445,1.4248-3.89453,2.05664c0.00781,0.01953,0.02148,0.03613,0.0293,0.05566
      c0.57617,1.38672-0.08398,2.97656-1.4707,3.55273c-1.38867,0.5752-2.97852-0.08496-3.55273-1.47168
      c-0.00879-0.02051-0.01074-0.04102-0.01855-0.06055c-2.73926,0.97754-5.58203,1.73438-8.50488,2.26465
      c0.00391,0.02539,0.01465,0.04883,0.01758,0.07422c0.19531,1.49023-0.85156,2.85449-2.3418,3.05078
      c-1.48828,0.19629-2.85352-0.85254-3.05078-2.3418c-0.00391-0.02734,0-0.05469-0.00293-0.08203
      c-1.45215,0.11621-2.91699,0.19238-4.39941,0.19238c-1.48291,0-2.94727-0.07617-4.3999-0.19238
      c-0.00293,0.02734,0.00146,0.05469-0.00244,0.08203c-0.19727,1.48926-1.5625,2.53809-3.05176,2.3418
      s-2.53711-1.56055-2.34082-3.05078c0.00342-0.02539,0.01318-0.04883,0.01758-0.07422
      c-2.92285-0.53027-5.76563-1.28711-8.50488-2.26465c-0.00781,0.01953-0.01025,0.04004-0.01855,0.06055
      c-0.5752,1.38672-2.16504,2.04688-3.55273,1.47168c-1.3877-0.57617-2.04688-2.16602-1.47168-3.55273
      c0.0083-0.01953,0.02148-0.03613,0.02979-0.05566c-1.33057-0.63184-2.62598-1.32324-3.89453-2.05664l-0.00342,0.00488
      l0.00293-0.00586c-1.27344-0.73633-2.51611-1.51953-3.72119-2.35352c-0.01514,0.02051-0.02393,0.04297-0.03955,0.06348
      c-0.91406,1.19238-2.62109,1.41602-3.81348,0.50195c-1.19043-0.91406-1.41602-2.62109-0.50098-3.81348
      c0.0166-0.02148,0.0376-0.03711,0.05469-0.05859c-2.23828-1.90332-4.31934-3.98438-6.22314-6.22266
      c-0.021,0.0166-0.03662,0.03809-0.05811,0.05469c-1.19141,0.91602-2.89844,0.69043-3.81348-0.50195
      c-0.91406-1.19141-0.68945-2.89844,0.50293-3.81152c0.02002-0.01563,0.04297-0.02441,0.06299-0.03906
      c-0.83447-1.20605-1.61768-2.44824-2.35449-3.72168l-0.00635,0.00293l0.00586-0.00391
      c-0.73389-1.26855-1.42529-2.56348-2.05713-3.89453c-0.01904,0.00879-0.03467,0.02148-0.0542,0.0293
      c-1.3877,0.57422-2.97852-0.08301-3.55273-1.47168c-0.5752-1.38867,0.08398-2.97754,1.4707-3.55371
      c0.01953-0.00781,0.04004-0.01074,0.06006-0.01758c-0.97803-2.74023-1.73438-5.58301-2.26514-8.50586
      c-0.02539,0.00391-0.04785,0.01465-0.07324,0.01758c-1.48926,0.19727-2.85547-0.84961-3.05176-2.33887
      c-0.19531-1.48926,0.85254-2.85645,2.3418-3.05176c0.02783-0.00391,0.05469,0,0.08203-0.00293
      c-0.11621-1.45215-0.19238-2.91699-0.19238-4.3999s0.07617-2.94775,0.19238-4.40088
      c-0.02734-0.00293-0.0542,0.00098-0.08203-0.00244C345.50684,395.89014,344.45898,394.52441,344.6543,393.03516z"/>
    <path class="in-i-2" d="M350.04785,393.74512c-0.19238,1.46094-1.5127,2.49121-2.96973,2.34375
      c-0.11621,1.45313-0.19238,2.91797-0.19238,4.40088l32.63184-1.86523l10.90527,1.30908
      c0.0293-0.58496,0.09473-1.16016,0.21338-1.7168l-10.85986-1.55615l-31.03711-10.24756l32.00195,6.64404l10.18457,4.08252
      c0.1792-0.55371,0.40527-1.08545,0.6665-1.59619L381.5,391.22705l-27.32227-17.92725
      c-0.73389,1.26807-1.4248,2.56299-2.05664,3.89307c1.34766,0.59473,1.98291,2.15576,1.41699,3.52393
      c-0.56689,1.36768-2.12012,2.02148-3.49316,1.48975c-0.97803,2.73975-1.73438,5.58252-2.26563,8.50439
      C349.22803,390.94238,350.23975,392.28174,350.04785,393.74512z"/>
    <path class="in-i-2" d="M410.95215,395.54346c0.26074,0.51123,0.4873,1.04297,0.66602,1.59619l10.18555-4.08252l32.00195-6.64404
      l-31.03906,10.24756l-10.8584,1.55615c0.11816,0.55664,0.18359,1.13184,0.21289,1.7168l10.90527-1.30908l32.63281,1.86523
      c0-1.48291-0.07617-2.94775-0.19238-4.40088c-1.45703,0.14697-2.77637-0.88281-2.96973-2.34375
      c-0.19238-1.46338,0.82031-2.80225,2.26855-3.03418c-0.53125-2.92236-1.28809-5.76514-2.26563-8.50439
      c-1.37305,0.53223-2.92676-0.12158-3.49316-1.48975c-0.56836-1.36816,0.06934-2.92969,1.41699-3.52441
      c-0.63184-1.33008-1.32324-2.625-2.05664-3.89307l-27.32324,17.92773L410.95215,395.54346z"/>
    <path class="in-i-2" d="M400.7168,411.33887c-0.58545-0.0293-1.16016-0.09473-1.71729-0.21387l-1.55615,10.86035l-10.24707,31.03809
      l6.64453-32.00293l4.08154-10.18457c-0.55371-0.17871-1.08545-0.40527-1.59619-0.66699l-4.31641,10.09277l-17.92725,27.32227
      c1.26855,0.7334,2.56396,1.4248,3.89453,2.05664c0.59473-1.34668,2.15527-1.98438,3.52295-1.41602
      c1.36768,0.56445,2.021,2.11914,1.49023,3.49219c2.73926,0.97754,5.58203,1.73438,8.50488,2.26465
      c0.23145-1.44824,1.56982-2.45898,3.0332-2.26758c1.46143,0.19238,2.49072,1.51367,2.34424,2.96875
      c1.45264,0.11621,2.91699,0.19238,4.3999,0.19238l-1.86426-32.63086L400.7168,411.33887z"/>
    <path class="in-i-2" d="M391.59229,405.43555c-0.26123-0.51074-0.4873-1.04297-0.6665-1.59668l-10.18457,4.08203l-32.00195,6.64551
      l31.03711-10.24707l10.85986-1.55664c-0.11865-0.55664-0.18408-1.13184-0.21338-1.7168l-10.90527,1.30859l-32.63184-1.86475
      c0,1.48291,0.07617,2.94775,0.19238,4.3999c1.45703-0.14648,2.77734,0.88281,2.96973,2.34277
      c0.19189,1.46484-0.81982,2.80371-2.26855,3.0332c0.53076,2.92285,1.28711,5.76563,2.26514,8.50586
      c1.37305-0.53125,2.92676,0.12305,3.49365,1.49023c0.56641,1.36816-0.06934,2.92871-1.41748,3.52344
      c0.63184,1.33105,1.32324,2.62598,2.05713,3.89453L381.5,409.75098L391.59229,405.43555z"/>
    <path class="in-i-2" d="M395.36133,409.61328c-0.48584-0.31543-0.94043-0.6709-1.36865-1.05762l-6.78467,8.63574l-24.39258,21.75488
      l21.75586-24.39258l8.63477-6.78418c-0.38623-0.42871-0.74219-0.88281-1.05713-1.36816l-8.78467,6.58008l-29.18604,14.69922
      c0.73682,1.27344,1.52002,2.51563,2.35449,3.72168c1.18701-0.86133,2.8501-0.63086,3.74951,0.53906
      c0.89746,1.1709,0.69141,2.83398-0.44385,3.75879c1.90381,2.23828,3.98486,4.31934,6.22314,6.22266
      c0.92432-1.13477,2.58789-1.33984,3.75781-0.44434c1.1709,0.90039,1.40186,2.56055,0.5415,3.75098
      c1.20508,0.83398,2.44775,1.61719,3.72119,2.35352l14.69824-29.18555L395.36133,409.61328z"/>
    <path class="in-i-2" d="M392.14844,394.57813c0.31543-0.48535,0.67139-0.94043,1.05811-1.36865l-8.63525-6.78516l-21.75586-24.3916
      l24.39258,21.75488l6.78516,8.63525c0.42773-0.38672,0.88281-0.74219,1.36865-1.05713l-6.58154-8.78467l-14.69824-29.18604
      c-1.27344,0.73682-2.51611,1.51953-3.72168,2.354c0.86084,1.18848,0.63037,2.85059-0.54102,3.74951
      c-1.16992,0.89795-2.8335,0.69141-3.75781-0.44336c-2.23828,1.90381-4.31885,3.98486-6.22314,6.22314
      c1.13525,0.92578,1.34131,2.5874,0.44385,3.75781c-0.89941,1.17139-2.5625,1.40234-3.74951,0.54102
      c-0.83447,1.20557-1.61768,2.44824-2.35449,3.72168l29.18604,14.69775L392.14844,394.57813z"/>
    <path class="in-i-2" d="M396.32666,390.80957c0.51074-0.26172,1.04248-0.4873,1.59619-0.6665l-4.08203-10.18457l-6.64453-32.00293
      l10.24707,31.0376l1.55615,10.86035c0.55713-0.11816,1.13184-0.18408,1.71729-0.21387l-1.30859-10.90527l1.86426-32.63184
      c-1.48291,0-2.94727,0.07617-4.3999,0.19238c0.14648,1.45703-0.88281,2.77637-2.34424,2.96924
      c-1.46387,0.19287-2.80225-0.81934-3.0332-2.26807c-2.92285,0.53076-5.76563,1.2876-8.50537,2.26563
      c0.53174,1.37305-0.12158,2.92676-1.48975,3.49268c-1.36816,0.56689-2.9292-0.06934-3.52344-1.4165
      c-1.33057,0.63135-2.62549,1.32275-3.89404,2.05664l17.92725,27.32227L396.32666,390.80957z"/>
    <path class="in-i-2" d="M407.18262,391.36523c0.48535,0.31543,0.94043,0.6709,1.36816,1.05762l6.78613-8.63525l24.39258-21.75488
      l-21.75586,24.3916l-8.63574,6.78516c0.38672,0.42822,0.74219,0.8833,1.05762,1.36914l8.78516-6.58203l29.18555-14.69775
      c-0.73633-1.27344-1.51953-2.51611-2.35352-3.72168c-1.18848,0.86084-2.85156,0.63037-3.75-0.54102
      c-0.89746-1.17041-0.69141-2.83203,0.44336-3.75781c-1.90332-2.23828-3.98438-4.31934-6.22266-6.22314
      c-0.92578,1.13525-2.58984,1.34131-3.75781,0.44336c-1.17285-0.89893-1.40234-2.56104-0.54102-3.74951
      c-1.20605-0.83447-2.44824-1.61719-3.72168-2.354l-14.69824,29.18604L407.18262,391.36523z"/>
    <path class="in-i-2" d="M410.39551,406.40039c-0.31543,0.48633-0.6709,0.94043-1.05762,1.36914l8.63574,6.78418l21.75586,24.39258
      l-24.39258-21.75488l-6.78516-8.63574c-0.42773,0.38672-0.88281,0.74219-1.36816,1.05762l6.58105,8.78418l14.69824,29.18555
      c1.27344-0.73633,2.51563-1.51953,3.72168-2.35352c-0.86133-1.19043-0.63184-2.85059,0.54102-3.75098
      c1.16797-0.89551,2.83203-0.69043,3.75781,0.44434c2.23828-1.90332,4.31934-3.98438,6.22363-6.22266
      c-1.13574-0.9248-1.3418-2.58789-0.44434-3.75879c0.89844-1.16992,2.56152-1.40039,3.74902-0.53906
      c0.83496-1.20508,1.61816-2.44824,2.35449-3.72168l-29.18555-14.69922L410.39551,406.40039z"/>
    <path class="in-i-2" d="M412.12109,401.0459c-0.0293,0.58496-0.09473,1.16016-0.21289,1.7168l10.8584,1.55664l31.03906,10.24707
      l-32.00195-6.64551l-10.18555-4.08203c-0.17871,0.55371-0.40527,1.08594-0.66602,1.59668l10.0918,4.31543l27.32324,17.92871
      c0.7334-1.26758,1.4248-2.56348,2.05664-3.89453c-1.34766-0.59375-1.98535-2.15527-1.41699-3.52344
      c0.56641-1.36719,2.12109-2.02148,3.49414-1.49023c0.97754-2.74023,1.73438-5.58301,2.26465-8.50586
      c-1.44824-0.22949-2.46094-1.56836-2.26855-3.0332c0.19336-1.45898,1.5127-2.48926,2.96973-2.34277
      c0.11621-1.45215,0.19238-2.91699,0.19238-4.3999l-32.63281,1.86475L412.12109,401.0459z"/>
    <path class="in-i-2" d="M401.8291,389.63965c0.58398,0.02979,1.15918,0.09521,1.71582,0.21387l1.55566-10.86035l10.24805-31.0376
      l-6.64453,32.00293l-4.08203,10.18457c0.55371,0.17871,1.08496,0.40479,1.5957,0.66602l4.31641-10.09229l17.92773-27.32227
      c-1.26855-0.73389-2.56348-1.42529-3.89453-2.05713c-0.59375,1.34766-2.1543,1.98438-3.52344,1.41699
      c-1.36719-0.56592-2.02051-2.11963-1.48828-3.49268c-2.74023-0.97803-5.58301-1.73486-8.50586-2.26563
      c-0.23145,1.44873-1.57031,2.46094-3.0332,2.26807s-2.49121-1.51221-2.34473-2.96924
      c-1.45215-0.11621-2.91699-0.19238-4.39941-0.19238l1.86523,32.63184L401.8291,389.63965z"/>
    <path class="in-i-2" d="M406.21777,410.16992c-0.51074,0.26172-1.04199,0.4873-1.5957,0.66602l4.08203,10.18457l6.64453,32.00293
      l-10.24805-31.03809l-1.55566-10.86035c-0.55664,0.11914-1.13086,0.18457-1.71582,0.21387l1.30859,10.90527l-1.86523,32.63086
      c1.48242,0,2.94727-0.07617,4.39941-0.19238c-0.14551-1.45508,0.88281-2.77637,2.34473-2.96875
      c1.46289-0.19141,2.80176,0.81934,3.0332,2.26758c2.92285-0.53027,5.76563-1.28711,8.50488-2.26465
      c-0.53027-1.37305,0.12305-2.92773,1.48926-3.49219c1.36914-0.56836,2.92871,0.06934,3.52344,1.41602
      c1.33008-0.63184,2.62598-1.32324,3.89453-2.05664l-17.92773-27.32227L406.21777,410.16992z"/>
    <path class="in-i-3" d="M390.42285,401.0459c0.0293,0.58496,0.09473,1.16016,0.21338,1.7168l4.63232-0.66406l-4.34277,1.74023
      c0.1792,0.55371,0.40527,1.08594,0.6665,1.59668l4.29736-1.83691l-3.74072,2.80273c0.31494,0.48535,0.6709,0.93945,1.05713,1.36816
      l3.6709-2.88477l-2.88428,3.6709c0.42822,0.38672,0.88281,0.74219,1.36865,1.05762l2.80273-3.74121l-1.83789,4.29688
      c0.51074,0.26172,1.04248,0.48828,1.59619,0.66699l1.74072-4.34277l-0.66357,4.63184
      c0.55713,0.11914,1.13184,0.18457,1.71729,0.21387l0.55566-4.63477l0.55664,4.63477
      c0.58496-0.0293,1.15918-0.09473,1.71582-0.21387l-0.66309-4.63184l1.74023,4.34277c0.55371-0.17871,1.08496-0.4043,1.5957-0.66602
      l-1.83789-4.29785l2.80371,3.74121c0.48535-0.31543,0.94043-0.6709,1.36816-1.05762l-2.88477-3.6709l3.6709,2.88477
      c0.38672-0.42871,0.74219-0.88281,1.05762-1.36914l-3.74023-2.80176l4.29688,1.83691
      c0.26074-0.51074,0.4873-1.04297,0.66602-1.59668l-4.3418-1.74023l4.63184,0.66406c0.11816-0.55664,0.18359-1.13184,0.21289-1.7168
      l-4.63379-0.55615l4.63379-0.55615c-0.0293-0.58496-0.09473-1.16016-0.21289-1.7168l-4.63184,0.66357l4.3418-1.74072
      c-0.17871-0.55322-0.40527-1.08496-0.66602-1.59619l-4.29688,1.83789l3.74023-2.80273
      c-0.31543-0.48584-0.6709-0.94092-1.05762-1.36914l-3.6709,2.88379l2.88379-3.67041
      c-0.42773-0.38672-0.88281-0.74219-1.36816-1.05762l-2.80273,3.74072l1.83789-4.29688
      c-0.51074-0.26123-1.04199-0.4873-1.5957-0.66602l-1.74023,4.3418l0.66309-4.63135
      c-0.55664-0.11865-1.13184-0.18408-1.71582-0.21387l-0.55664,4.63379l-0.55566-4.63379
      c-0.58545,0.02979-1.16016,0.0957-1.71729,0.21387l0.66357,4.63135l-1.74023-4.3418
      c-0.55371,0.1792-1.08545,0.40479-1.59619,0.6665l1.8374,4.29639l-2.80225-3.74023
      c-0.48584,0.31494-0.94092,0.67041-1.36865,1.05713l2.88379,3.67041l-3.67041-2.88379
      c-0.38672,0.42822-0.74268,0.8833-1.05811,1.36865l3.74121,2.80322l-4.29736-1.83789
      c-0.26123,0.51074-0.4873,1.04248-0.6665,1.59619l4.34277,1.74072l-4.63232-0.66357
      c-0.11865,0.55664-0.18408,1.13184-0.21338,1.7168l4.63379,0.55615L390.42285,401.0459z"/>
    <path class="in-i-3" d="M452.49707,407.23242c-0.19238,1.46484,0.82031,2.80371,2.26855,3.0332
      c0.02539,0.00391,0.04785,0.01465,0.07324,0.01758c1.48828,0.19727,2.85547-0.84961,3.05078-2.33887
      s-0.85156-2.85645-2.33984-3.05176c-0.02832-0.00391-0.05469,0-0.08301-0.00293
      C454.00977,404.74316,452.69043,405.77344,452.49707,407.23242z"/>
    <polygon class="in-i-3" points="401.8291,411.33887 401.27246,406.7041 400.7168,411.33887 399.4082,422.24414 401.27246,454.875 
      403.1377,422.24414 	"/>
    <path class="in-i-3" d="M449.00684,420.26172c-0.56836,1.36816,0.06934,2.92969,1.41699,3.52344
      c0.01953,0.00879,0.03418,0.02148,0.05371,0.0293c1.38672,0.57422,2.97852-0.08301,3.55273-1.47168
      c0.57617-1.38867-0.08203-2.97754-1.4707-3.55371c-0.01953-0.00781-0.03906-0.00977-0.05859-0.01758
      C451.12793,418.24023,449.57324,418.89453,449.00684,420.26172z"/>
    <polygon class="in-i-3" points="397.92236,410.83594 393.84082,421.02051 387.19629,453.02344 397.44336,421.98535 398.99951,411.125 
      399.66309,406.49316 	"/>
    <path class="in-i-3" d="M442.2627,431.94141c-0.89746,1.1709-0.69141,2.83398,0.44434,3.75879
      c0.02051,0.0166,0.03613,0.03809,0.05762,0.05469c1.19141,0.91602,2.89844,0.69043,3.8125-0.50195
      c0.91406-1.19141,0.68945-2.89844-0.50195-3.81152c-0.02051-0.01563-0.04297-0.02441-0.06348-0.03906
      C444.82422,430.54102,443.16113,430.77148,442.2627,431.94141z"/>
    <polygon class="in-i-3" points="396.32617,410.16895 398.16406,405.87207 395.36133,409.61328 388.78027,418.39746 
      374.08203,447.58301 374.0791,447.58887 374.08252,447.58398 392.00977,420.26172 	"/>
    <path class="in-i-3" d="M432.72559,441.47852c-1.17285,0.90039-1.40234,2.56055-0.54102,3.75098
      c0.01465,0.02051,0.02344,0.04297,0.03906,0.06348c0.91406,1.19238,2.62109,1.41602,3.8125,0.50195
      s1.41602-2.62109,0.50195-3.81348c-0.0166-0.02148-0.03809-0.03711-0.05469-0.05859
      C435.55762,440.78809,433.89355,440.58301,432.72559,441.47852z"/>
    <polygon class="in-i-3" points="393.20605,407.76953 384.57129,414.55371 362.81543,438.94629 387.20801,417.19141 
      393.99268,408.55566 396.87695,404.88477 	"/>
    <path class="in-i-3" d="M421.04395,448.22461c-1.36621,0.56445-2.01953,2.11914-1.48926,3.49219
      c0.00781,0.01953,0.00977,0.04004,0.01855,0.06055c0.57422,1.38672,2.16406,2.04688,3.55273,1.47168
      c1.38672-0.57617,2.04688-2.16602,1.4707-3.55273c-0.00781-0.01953-0.02148-0.03613-0.0293-0.05566
      C423.97266,448.29395,422.41309,447.65625,421.04395,448.22461z"/>
    <polygon class="in-i-3" points="392.14893,406.40137 395.88965,403.59863 391.59229,405.43555 381.5,409.75098 354.17773,427.67969 
      354.17188,427.68359 354.17822,427.68066 383.36426,412.98145 	"/>
    <path class="in-i-3" d="M408.0166,451.71387c-1.46191,0.19238-2.49023,1.51367-2.34473,2.96875
      c0.00293,0.02734-0.00098,0.05469,0.00293,0.08203c0.19727,1.48926,1.5625,2.53809,3.05078,2.3418
      c1.49023-0.19629,2.53711-1.56055,2.3418-3.05078c-0.00293-0.02539-0.01367-0.04883-0.01758-0.07422
      C410.81836,452.5332,409.47949,451.52246,408.0166,451.71387z"/>
    <polygon class="in-i-3" points="390.63623,402.7627 379.77637,404.31934 348.73926,414.56641 380.74121,407.9209 390.92578,403.83887 
      395.26855,402.09863 	"/>
    <path class="in-i-3" d="M394.52832,451.71387c-1.46338-0.19141-2.80176,0.81934-3.0332,2.26758
      c-0.00439,0.02539-0.01416,0.04883-0.01758,0.07422c-0.19629,1.49023,0.85156,2.85449,2.34082,3.05078
      s2.85449-0.85254,3.05176-2.3418c0.00391-0.02734-0.00049-0.05469,0.00244-0.08203
      C397.01904,453.22754,395.98975,451.90625,394.52832,451.71387z"/>
    <polygon class="in-i-3" points="390.42285,401.0459 395.05664,400.48975 390.42285,399.93359 379.51758,398.62451 
      346.88574,400.48975 379.51758,402.35449 	"/>
    <path class="in-i-3" d="M381.5,448.22461c-1.36768-0.56836-2.92822,0.06934-3.52295,1.41602
      c-0.0083,0.01953-0.02148,0.03613-0.02979,0.05566c-0.5752,1.38672,0.08398,2.97656,1.47168,3.55273
      c1.3877,0.5752,2.97754-0.08496,3.55273-1.47168c0.0083-0.02051,0.01074-0.04102,0.01855-0.06055
      C383.521,450.34375,382.86768,448.78906,381.5,448.22461z"/>
    <polygon class="in-i-3" points="390.92578,397.13965 380.74121,393.05713 348.73926,386.41309 379.77637,396.66064 
      390.63623,398.2168 395.26855,398.88037 	"/>
    <path class="in-i-3" d="M369.81934,441.47852c-1.16992-0.89551-2.8335-0.69043-3.75781,0.44434
      c-0.01709,0.02148-0.03809,0.03711-0.05469,0.05859c-0.91504,1.19238-0.68945,2.89941,0.50098,3.81348
      c1.19238,0.91406,2.89941,0.69043,3.81348-0.50195c0.01563-0.02051,0.02441-0.04297,0.03955-0.06348
      C371.22119,444.03906,370.99023,442.37891,369.81934,441.47852z"/>
    <polygon class="in-i-3" points="391.59229,395.54346 395.88965,397.38135 392.14844,394.57813 383.36426,387.99658 
      354.17822,373.29883 354.17188,373.2959 354.17773,373.2998 381.5,391.22705 	"/>
    <path class="in-i-3" d="M360.28223,431.94141c-0.89941-1.16992-2.5625-1.40039-3.74951-0.53906
      c-0.02002,0.01465-0.04297,0.02344-0.06299,0.03906c-1.19238,0.91309-1.41699,2.62012-0.50293,3.81152
      c0.91504,1.19238,2.62207,1.41797,3.81348,0.50195c0.02148-0.0166,0.03711-0.03809,0.05811-0.05469
      C360.97363,434.77539,361.17969,433.1123,360.28223,431.94141z"/>
    <polygon class="in-i-3" points="393.99316,392.42285 387.20801,383.7876 362.81543,362.03271 384.57129,386.42432 
      393.20654,393.20947 396.87695,396.09326 	"/>
    <path class="in-i-3" d="M353.53809,420.26172c-0.56689-1.36719-2.12061-2.02148-3.49365-1.49023
      c-0.02002,0.00684-0.04053,0.00977-0.06006,0.01758c-1.38672,0.57617-2.0459,2.16504-1.4707,3.55371
      c0.57422,1.38867,2.16504,2.0459,3.55273,1.47168c0.01953-0.00781,0.03516-0.02051,0.0542-0.0293
      C353.46875,423.19043,354.10449,421.62988,353.53809,420.26172z"/>
    <polygon class="in-i-3" points="395.36182,391.36572 398.16406,395.10596 396.32666,390.80957 392.00977,380.7168 
      374.08252,353.39453 374.0791,353.38916 374.08203,353.39502 388.78027,382.58105 	"/>
    <path class="in-i-3" d="M350.04785,407.23242c-0.19238-1.45996-1.5127-2.48926-2.96973-2.34277
      c-0.02734,0.00293-0.0542-0.00098-0.08203,0.00293c-1.48926,0.19531-2.53711,1.5625-2.3418,3.05176
      c0.19629,1.48926,1.5625,2.53613,3.05176,2.33887c0.02539-0.00293,0.04785-0.01367,0.07324-0.01758
      C349.22803,410.03613,350.23975,408.69727,350.04785,407.23242z"/>
    <polygon class="in-i-3" points="398.99951,389.85352 397.44336,378.99316 387.19629,347.95557 393.84082,379.9585 
      397.92285,390.14307 399.66309,394.48486 	"/>
    <path class="in-i-3" d="M346.99609,396.08643c0.02783,0.00342,0.05469-0.00049,0.08203,0.00244
      c1.45703,0.14746,2.77734-0.88281,2.96973-2.34375c0.19189-1.46338-0.81982-2.80273-2.26855-3.03418
      c-0.0249-0.00391-0.04785-0.01367-0.07324-0.01709c-1.48926-0.1958-2.85547,0.85205-3.05176,2.34131
      C344.45898,394.52441,345.50684,395.89014,346.99609,396.08643z"/>
    <polygon class="in-i-3" points="400.7168,389.63965 401.27246,394.27344 401.8291,389.63965 403.1377,378.73438 401.27246,346.10254 
      399.4082,378.73438 	"/>
    <path class="in-i-3" d="M353.53809,380.7168c0.56592-1.36816-0.06934-2.9292-1.41699-3.52393
      c-0.01953-0.0083-0.03516-0.021-0.05469-0.0293c-1.3877-0.57471-2.97852,0.08447-3.55273,1.47217
      c-0.5752,1.38721,0.08398,2.97803,1.4707,3.55273c0.02002,0.0083,0.04053,0.01074,0.06055,0.01807
      C351.41797,382.73828,352.97119,382.08447,353.53809,380.7168z"/>
    <polygon class="in-i-3" points="404.62207,390.14307 408.7041,379.9585 415.34863,347.95557 405.10059,378.99316 403.54492,389.85352 
      402.88184,394.48486 	"/>
    <path class="in-i-3" d="M360.28223,369.03613c0.89746-1.17041,0.69141-2.83203-0.44385-3.75781
      c-0.021-0.01709-0.03662-0.03809-0.05811-0.05469c-1.19141-0.91455-2.89844-0.68945-3.81348,0.50146
      c-0.91406,1.19189-0.68945,2.89844,0.50293,3.81299c0.02002,0.01514,0.04248,0.02441,0.06299,0.03906
      C357.71973,370.43848,359.38281,370.20752,360.28223,369.03613z"/>
    <polygon class="in-i-3" points="406.21777,390.80908 404.37988,395.10596 407.18262,391.36523 413.76465,382.58105 
      428.46289,353.39502 428.46582,353.38916 428.46191,353.39453 410.53418,380.7168 	"/>
    <path class="in-i-3" d="M369.81934,359.49854c1.17139-0.89893,1.40186-2.56104,0.54102-3.74951
      c-0.01465-0.02002-0.02344-0.04297-0.03906-0.06299c-0.91406-1.19141-2.62109-1.41602-3.81348-0.50195
      c-1.19043,0.91406-1.41602,2.62109-0.50098,3.81299c0.0166,0.02148,0.0376,0.03711,0.05469,0.05811
      C366.98584,360.18994,368.64941,360.39648,369.81934,359.49854z"/>
    <polygon class="in-i-3" points="409.33789,393.20947 417.97363,386.42432 439.72949,362.03271 415.33691,383.7876 
      408.55078,392.42285 405.66699,396.09326 	"/>
    <path class="in-i-3" d="M381.5,352.75439c1.36816-0.56592,2.02148-2.11963,1.48975-3.49268
      c-0.00781-0.02002-0.00977-0.04053-0.01807-0.06006c-0.5752-1.38721-2.16504-2.04639-3.55273-1.47168
      s-2.04688,2.16553-1.47168,3.55322c0.00781,0.01953,0.021,0.03516,0.0293,0.05469
      C378.5708,352.68506,380.13184,353.32129,381.5,352.75439z"/>
    <polygon class="in-i-3" points="410.39551,394.57861 406.65527,397.38135 410.95215,395.54346 421.04395,391.22705 
      448.36719,373.29932 448.37207,373.2959 448.36621,373.29883 419.18066,387.99658 	"/>
    <path class="in-i-3" d="M394.52832,349.26416c1.46143-0.19287,2.49072-1.51221,2.34424-2.96924
      c-0.00293-0.02734,0.00146-0.0542-0.00244-0.08203c-0.19727-1.48877-1.5625-2.53711-3.05176-2.34131
      c-1.48926,0.19629-2.53711,1.56201-2.34082,3.05078c0.00342,0.02588,0.01318,0.04834,0.01758,0.07373
      C391.72607,348.44482,393.06445,349.45703,394.52832,349.26416z"/>
    <polygon class="in-i-3" points="411.9082,398.2168 422.7666,396.66064 453.80566,386.41309 421.80371,393.05713 411.61816,397.13965 
      407.27637,398.88037 	"/>
    <path class="in-i-3" d="M408.0166,349.26416c1.46289,0.19287,2.80176-0.81934,3.0332-2.26807
      c0.00391-0.02539,0.01465-0.04785,0.01758-0.07373c0.19531-1.48877-0.85156-2.85449-2.3418-3.05078
      c-1.48828-0.1958-2.85352,0.85254-3.05078,2.34131c-0.00391,0.02783,0,0.05469-0.00293,0.08203
      C405.52539,347.75195,406.55371,349.07129,408.0166,349.26416z"/>
    <polygon class="in-i-3" points="412.12109,399.93359 407.4873,400.48975 412.12109,401.0459 423.02637,402.35449 455.65918,400.48975 
      423.02637,398.62451 	"/>
    <path class="in-i-3" d="M421.04395,352.75439c1.36914,0.56738,2.92969-0.06934,3.52344-1.41699
      c0.00879-0.01904,0.02148-0.03467,0.0293-0.0542c0.57617-1.3877-0.08398-2.97852-1.4707-3.55322
      c-1.38867-0.57471-2.97852,0.08447-3.55273,1.47168c-0.00781,0.01953-0.01074,0.04004-0.01758,0.06006
      C419.02344,350.63477,419.67676,352.18848,421.04395,352.75439z"/>
    <polygon class="in-i-3" points="411.61816,403.83887 421.80371,407.9209 453.80566,414.56641 422.7666,404.31934 411.9082,402.7627 
      407.27637,402.09863 	"/>
    <path class="in-i-3" d="M432.72559,359.49854c1.16797,0.89795,2.83203,0.69189,3.75781-0.44336
      c0.0166-0.021,0.03809-0.03662,0.05469-0.05811c0.91406-1.19189,0.68945-2.89893-0.50195-3.81299s-2.89844-0.68945-3.8125,0.50195
      c-0.01563,0.02002-0.02441,0.04297-0.03906,0.06299C431.32324,356.9375,431.55273,358.59961,432.72559,359.49854z"/>
    <polygon class="in-i-3" points="410.95215,405.43555 406.65527,403.59863 410.39551,406.40039 419.18066,412.98145 
      448.36621,427.68066 448.37207,427.68359 448.36719,427.67969 421.04395,409.75098 	"/>
    <path class="in-i-3" d="M442.2627,369.03613c0.89844,1.17139,2.56152,1.40186,3.75,0.54102
      c0.01953-0.01465,0.04199-0.02393,0.0625-0.03906c1.19141-0.91455,1.41602-2.62109,0.50195-3.81299
      c-0.91406-1.19092-2.62109-1.41602-3.8125-0.50146c-0.02148,0.0166-0.03711,0.0376-0.05859,0.05469
      C441.57129,366.2041,441.36523,367.86572,442.2627,369.03613z"/>
    <polygon class="in-i-3" points="408.55176,408.55566 415.33691,417.19141 439.72949,438.94629 417.97363,414.55371 
      409.33789,407.76953 405.66699,404.88477 	"/>
    <path class="in-i-3" d="M449.00684,380.7168c0.56641,1.36816,2.12012,2.02197,3.49316,1.48975
      c0.01953-0.00781,0.04004-0.00977,0.05957-0.01807c1.38867-0.57471,2.04688-2.16553,1.4707-3.55273
      c-0.57422-1.3877-2.16602-2.04688-3.55273-1.47217c-0.01953,0.00781-0.03516,0.02051-0.05371,0.02881
      C449.07617,377.78711,448.43848,379.34863,449.00684,380.7168z"/>
    <polygon class="in-i-3" points="407.18359,409.61328 404.37988,405.87207 406.21777,410.16992 410.53418,420.26172 
      428.46191,447.58398 428.46582,447.58887 428.46289,447.58301 413.76465,418.39746 	"/>
    <path class="in-i-3" d="M452.49707,393.74512c0.19336,1.46094,1.5127,2.49072,2.96973,2.34375
      c0.02734-0.00293,0.05469,0.00146,0.08301-0.00244c1.48828-0.19629,2.53516-1.56201,2.33984-3.05127
      s-1.5625-2.53711-3.05078-2.34131c-0.02539,0.00342-0.04785,0.01318-0.07324,0.01709
      C453.31738,390.94287,452.30469,392.28174,452.49707,393.74512z"/>
    <polygon class="in-i-3" points="403.54492,411.125 405.10059,421.98535 415.34863,453.02344 408.7041,421.02051 404.62207,410.83594 
      402.88184,406.49316 	"/>
  </g>
  </svg>
</template>

<script>
export default {
  name: "indiaIcon"
}
</script>

<style>
  .in-i-0{fill:#128807;}
  .in-i-1{fill:#FF9933;}
  .in-i-2{fill:#FFFFFF;}
  .in-i-3{fill:#000088;}
</style>
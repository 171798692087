
#shop-products {
  width: 100%;

  #main-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 25px;

    #sub-categories {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(31.5%, 1fr));
      grid-gap: calc(2.6vmin);
      width: 100%;
      margin-bottom: 25px;

      .sub-category-card {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        overflow: hidden;

        .caption {
          z-index: 2;
          background-color: #fff;
        }

        .thumbnail {
          margin: 0;
          padding: 0;

          img {
            max-height: 150px;
            max-width: inherit;
            width: 100%;
            -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
            transition: opacity 0.35s, transform 0.35s;
            -webkit-transform: scale(1);
            transform: scale(1);

            &:hover {
              opacity: 0.5;
              -webkit-transform: scale(1.05);
              transform: scale(1.05);
            }
          }

          .image-fallback {
            padding: 10px;
          }
        }
      }

      @media (max-width: 1024px) {
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
      }

      @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));

        .thumbnail {
          .caption {
            color: #fff;
            background-color: #f99839;
          }

          h4 {
            min-height: 40px;
            font-size: 1.4rem;
          }

          img {
            display: none;
          }
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }

    #products-and-pills {
      display: flex;
      flex-grow: 1;
      flex-direction: column;

      .collection-info {
        margin-bottom: 25px;
        background-color: #FFF;
        padding: 15px;
        border-radius: 6px;
        display: inline-block;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        width: 100%;
        color: #393939;
      }

      @media (max-width: 768px) {
        padding: 0;
      }
    }
  }
}

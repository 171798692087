<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06174 1.20317">
    <defs>
      <clipPath id="clip-path">
        <rect class="can-1" width="2.06174" height="1.20317"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="can-2">
          <rect class="can-3" width="0.47951" height="1.20317"/>
          <rect class="can-3" x="1.5749" width="0.48684" height="1.20317"/>
          <rect class="can-4" x="0.47951" width="1.09539" height="1.20317"/>
          <g class="can-2">
            <path class="can-3" d="M1.03087.154.959.29328C.95168.30794.93775.30794.91649.30061L.8659.272.90916.48317C.91649.52277.8879.52277.87323.5081L.78745.40106.77279.45458C.76619.46191.75885.46924.75152.46924L.63714.44432.66574.555C.67307.58.67967.591.65841.59755l-.04326.022.201.16423a.02317.02317,0,0,1,.00733.0286L.80877.87323C.87323.863.93775.85564,1.00228.8483c.00733,0,.01393.00367.01393.011l-.0066.21849H1.0448L1.0382.863A.01511.01511,0,0,1,1.05213.8483c.07191.00734.129.01467.2009.02493L1.231.81238a.03856.03856,0,0,1,.00733-.0286l.201-.16423L1.396.59755C1.38134.591,1.38134.58,1.38867.555L1.41732.44432l-.1071.02492c-.01467,0-.022-.00733-.022-.01466L1.27429.40106l-.09312.107c-.01466.01467-.03592.01467-.02859-.02493L1.18851.272l-.05059.0286C1.124.30794,1.10938.30794,1.10272.29328Z"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "canadaFlag"
}
</script>

<style type="text/css">
  .can-1 {
    fill: none;
  }

  .can-3 {
    fill: #ea0020;
  }

  .can-4 {
    fill: #f4f4f4;
  }
</style>
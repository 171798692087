<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06526 1.55574">
    <defs>
      <clipPath id="clip-path" transform="translate(0.44522 0.01462)">
        <circle class="swe-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.44522 0.01462)">
        <rect class="swe-i-1" x="-0.44522" y="-0.01462" width="2.06526" height="1.20406"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="swe-i-2">
          <g id="Sweden">
            <g>
              <path d="M.27942,1.51909a.03687.03687,0,0,0,.02863.00735c.01763,0,.02864-.00735.02864-.0213,0-.00734-.00734-.01468-.0257-.02129-.02129-.00734-.03524-.022-.03524-.036a.038.038,0,0,1,.04258-.036c.01469,0,.022,0,.02864.00734L.3433,1.43319c-.00294-.0066-.014-.0066-.025-.0066-.01762,0-.025.0066-.025.01395,0,.01468.00734.02129.025.02863.022.00734.036.01468.036.036s-.01762.036-.04626.036c-.01468,0-.02863,0-.0323-.00734Z" transform="translate(0.44522 0.01462)"/>
              <path d="M.37927,1.44788.394,1.4978a.08379.08379,0,0,0,.00735.02864h0A.0811.0811,0,0,1,.4079,1.4978l.01469-.04992H.43654l.01468.04992c.00367.00734.00734.02129.011.02864h0A.08112.08112,0,0,1,.46884,1.4978l.011-.04992H.49747l-.02863.09324H.45122L.43654,1.4978a.06555.06555,0,0,0-.00661-.02863h0c-.00367.00734-.00367.02129-.00734.02863l-.01836.04332H.39034L.36165,1.44788Z" transform="translate(0.44522 0.01462)"/>
              <path d="M.52317,1.4978a.03305.03305,0,0,0,.03157.036c.011,0,.01836-.00734.0257-.00734l.00367.01468H.55186a.04124.04124,0,0,1-.047-.04332.0463.0463,0,0,1,.047-.04992c.02858,0,.03519.02863.03519.04258V1.4978Zm.04993-.014c0-.00734-.00368-.02863-.025-.02863-.0183,0-.025.02129-.025.02863Z" transform="translate(0.44522 0.01462)"/>
              <path d="M.69135,1.40456v.13656h-.014L.67368,1.52644h0a.03481.03481,0,0,1-.0323.01468A.03884.03884,0,0,1,.60173,1.4978c0-.036.02129-.04992.04332-.04992a.03369.03369,0,0,1,.02863.01395h0V1.40456Zm-.01767.07929V1.47651A.0309.0309,0,0,0,.648,1.46183c-.01762,0-.02863.01468-.02863.036,0,.01469.011.036.02863.036.011,0,.022-.01469.0257-.02129V1.48385Z" transform="translate(0.44522 0.01462)"/>
              <path d="M.72727,1.4978a.03312.03312,0,0,0,.03231.036c.01028,0,.01762-.00734.025-.00734l.00367.01468H.75591A.03848.03848,0,0,1,.71259,1.4978c0-.02863.01468-.04992.04332-.04992a.04236.04236,0,0,1,.03964.04258L.79188,1.4978Zm.04993-.014c0-.00734-.00367-.02863-.025-.02863-.014,0-.02129.02129-.025.02863Z" transform="translate(0.44522 0.01462)"/>
              <path d="M.81317,1.47651V1.44788H.82786l.003.01395h0a.03409.03409,0,0,1,.02937-.01395.03331.03331,0,0,1,.03518.036v.05727H.87778V1.49046c0-.01395-.00734-.02863-.02129-.02863a.0308.0308,0,0,0-.02564.01468v.06461H.81317Z" transform="translate(0.44522 0.01462)"/>
            </g>
            <g class="swe-i-3">
              <rect class="swe-i-4" width="2.06526" height="1.20406"/>
              <rect class="swe-i-5" x="0.60277" width="0.24375" height="1.20406"/>
              <rect class="swe-i-5" y="0.48016" width="2.06526" height="0.24375"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "swedenIcon"
}
</script>

<style>
  .swe-i-1 {
    fill: none;
  }

  .swe-i-2 {
    clip-path: url(#clip-path);
  }

  .swe-i-3 {
    clip-path: url(#clip-path-2);
  }

  .swe-i-4 {
    fill: #2d5a95;
  }

  .swe-i-5 {
    fill: #f3d02f;
  }
</style>
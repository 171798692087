<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06526 1.56675">
    <defs>
      <clipPath id="clip-path" transform="translate(0.44522)">
        <circle class="fra-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.44522)">
        <rect class="fra-i-1" x="-0.44522" width="2.06526" height="1.2048"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="fra-i-2">
          <g id="France">
            <g>
              <path d="M.33669,1.43753H.4079v.011H.35431v.04258H.40423v.01468H.35431v.06094H.33669Z" transform="translate(0.44522)"/>
              <path d="M.42626,1.50214V1.47351h.014v.01762h0a.02671.02671,0,0,1,.025-.02129H.46884l.00367.01762H.46517a.021.021,0,0,0-.02129.022v.05726H.42626Z" transform="translate(0.44522)"/>
              <path d="M.55474,1.54179v.025h-.014L.53718,1.55207h0a.03163.03163,0,0,1-.02869.01468.02582.02582,0,0,1-.02864-.025c0-.022.0213-.036.05733-.036h0a.02064.02064,0,0,0-.02135-.022.03243.03243,0,0,0-.025.00734l-.00367-.014a.06743.06743,0,0,1,.0323-.00734c.02863,0,.03524.02129.03524.03965Zm-.01756-.0257c-.01768,0-.03971.00367-.03971.022a.01653.01653,0,0,0,.01469.01762.02446.02446,0,0,0,.025-.01762v-.022Z" transform="translate(0.44522)"/>
              <path d="M.58044,1.49847v-.025h.014v.01395h0a.05186.05186,0,0,1,.0323-.01762c.01469,0,.03231.00734.03231.03965v.05726h-.014V1.51242a.02576.02576,0,0,0-.0257-.02863.02312.02312,0,0,0-.02129.01835v.06461H.58044Z" transform="translate(0.44522)"/>
              <path d="M.75224,1.56308a.1896.1896,0,0,1-.025.00367.044.044,0,0,1-.047-.047A.04923.04923,0,0,1,.731,1.46984a.05258.05258,0,0,1,.025.00734l-.00734.01028A.04307.04307,0,0,0,.731,1.48379c-.022,0-.03225.01468-.03225.036a.03267.03267,0,0,0,.03225.036A.03894.03894,0,0,0,.75224,1.5484Z" transform="translate(0.44522)"/>
              <path d="M.78087,1.52343a.03041.03041,0,0,0,.0323.03231.04745.04745,0,0,0,.0213-.00367l.00734.011a.18176.18176,0,0,1-.02864.00367.04338.04338,0,0,1-.04625-.047c0-.02863.01395-.04992.04258-.04992a.04232.04232,0,0,1,.03965.04258v.011Zm.05-.01394c0-.011-.003-.0257-.02135-.0257a.03137.03137,0,0,0-.02863.0257Z" transform="translate(0.44522)"/>
            </g>
            <g class="fra-i-3">
              <rect class="fra-i-4" width="0.68872" height="1.2048"/>
              <rect class="fra-i-5" x="0.68872" width="0.68788" height="1.2048"/>
              <rect class="fra-i-6" x="1.3766" width="0.68867" height="1.2048"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "franceIcon"
}
</script>

<style>
  .fra-i-1 {
    fill: none;
  }

  .fra-i-2 {
    clip-path: url(#clip-path);
  }

  .fra-i-3 {
    clip-path: url(#clip-path-2);
  }

  .fra-i-4 {
    fill: #323e95;
  }

  .fra-i-5 {
    fill: #f4f4f4;
  }

  .fra-i-6 {
    fill: #d80031;
  }
</style>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_2" viewBox="0 0 100 80"
    width="75px" height="auto">
    <g id="Iconography">
      <g>
        <rect class="laser-1" x="18.48" y="54.97" width="0" height="11.5"
          transform="translate(-22.93 12.62) rotate(-23.79)" />
        <rect class="laser-1" x="33.19" y="50.01" width="0" height="6.09" />
        <rect class="laser-1" x="7.69" y="56.09" width="6.76" height="0"
          transform="translate(-45.89 61.04) rotate(-83.8)" />
        <polygon class="laser-1" points="40.47 30.08 40.47 30.08 30.16 31.2 30.16 31.2 40.47 30.08" />
        <polygon class="laser-1"
          points=".61 41.97 6.79 39.24 0 29.96 0 29.96 0 29.96 6.79 39.24 .61 41.97 6.79 44.69 0 53.97 0 53.97 0 53.97 6.79 44.69 .61 41.97" />
        <polygon class="laser-1" points="30.51 49.56 34.75 50.02 34.34 49.46 29.66 49.46 30.51 49.56" />
        <rect class="laser-1" x="7.69" y="27.84" width="6.76" height="0"
          transform="translate(-16.45 15.98) rotate(-45)" />
        <rect class="laser-1" x="28.17" y="54.07" width="0" height="6.76"
          transform="translate(-34.83 46.25) rotate(-53.8)" />
        <polygon class="laser-1" points="36.21 53.39 41.59 53.97 36.21 53.39 36.21 53.39" />
        <circle class="laser-1" cx="69.15" cy="19.11" r="8.73" />
        <path class="laser-1"
          d="m78.45,49.08v-3.4c.01-.32-.11-.64-.32-.87l-13.56-14.27c-.51-.55-1.34-.61-1.92-.14-1.58,1.27-3.15,2.54-4.73,3.81-.03.02-.07.02-.09,0-3.36-3.67-6.72-7.34-10.08-11.01-.52-.57-1.42-.54-1.9.07-1.09,1.38-2.19,2.76-3.28,4.15l4.23-.46-8.26,11.28,8.46,3.73-8.46,3.73,2.76,3.77h36.77c.21,0,.38-.17.38-.39Z" />
        <polygon class="laser-1" points="41.59 29.96 41.59 29.96 40.47 30.08 40.47 30.08 41.59 29.96" />
        <path class="laser-1"
          d="m27.83,41.96c0-2.28-1.08-4.3-2.76-5.59-1.18-.91-2.66-1.45-4.27-1.45-3.88,0-7.03,3.15-7.03,7.03s3.15,7.03,7.03,7.03c1.61,0,3.09-.54,4.27-1.45,1.68-1.28,2.76-3.31,2.76-5.59Z" />
        <polygon class="laser-1"
          points="10.7 24.48 11.43 31.2 11.43 31.2 0 29.96 0 29.96 6.79 39.24 .61 41.97 6.79 44.69 0 53.97 0 53.97 0 53.97 11.44 52.73 11.44 52.73 10.71 59.45 10.71 59.45 16.16 55.46 16.16 55.46 20.8 65.98 20.8 65.98 25.44 55.45 25.44 55.45 30.89 59.45 30.89 59.45 30.89 59.45 30.16 52.73 30.16 52.73 36.21 53.39 41.59 53.97 41.59 53.97 34.8 44.69 40.99 41.96 34.8 39.24 41.59 29.96 41.59 29.96 40.47 30.08 30.16 31.2 30.16 31.2 30.89 24.48 30.89 24.48 25.53 28.4 25.53 32.35 26.94 31.33 26.56 34.8 30.5 34.37 34.75 33.91 32.23 37.35 29.88 40.55 31.63 41.32 33.08 41.96 30.07 43.29 29.88 43.37 29.94 43.45 32.23 46.57 34.34 49.46 34.75 50.02 30.51 49.56 29.66 49.46 28.87 49.38 26.56 49.13 26.94 52.6 25.07 51.23 24.12 50.54 22.52 54.17 20.8 58.07 20.8 58.07 19.08 54.17 17.48 50.54 14.66 52.6 15.04 49.13 11.09 49.56 6.85 50.02 9.37 46.58 11.71 43.37 8.52 41.97 11.71 40.56 9.37 37.35 6.85 33.91 11.09 34.37 15.03 34.8 14.65 31.33 16.06 32.36 16.06 28.4 10.7 24.48 10.7 24.48" />
        <path class="laser-1" d="m18.7,32.93c.67-.19,1.37-.29,2.1-.29s1.43.1,2.1.29V0h0s-4.2,0-4.2,0v32.93Z" />
        <path class="laser-1"
          d="m85.17,0H25.6v4.21h56.52v49.17h-37.94l2.63,3.59-13.9-1.51,1,9.19-7.46-5.46-5.64,12.8v.88c0,.67.54,1.21,1.21,1.21h63.17c.67,0,1.21-.54,1.21-1.21V1.22c0-.67-.54-1.21-1.21-1.21Z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "laserOptimizedIcon"
}
</script>

<style>
.laser-1 {
  fill: #414042;
}
</style>
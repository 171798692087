
select {
  padding-right: 1.875em;
  cursor: pointer;
  color: #000;
  height: 40px;
  padding-bottom: 8px;
  border-radius: 2px;
  appearance: none;
  position: relative;
  background-color: #fff;
  color: #000;
  font-size: 100%;
  padding: .625em .5em;
  border: 1px solid #d2d2d2;
  width: 100%;
}


.filter-pill {
  border: 1px solid #bcbcbc;
  cursor: pointer;
  border-radius: 15px;
  background: #393939;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 2px 6px;
  margin: 5px 0 0 5px;
  color: #fff;

  &:hover {
    background: #000;
  }

  .remove-filter {
    padding-left: 3px;
    color: #fff;
    cursor: pointer;
  }
}

<template>
  <div class="col-sm-12">
    <div class="form-group">
      <label
        >Credit Card Number <img src="img/card_icons.png" alt="Cards"
      /></label>
      <input
        type="text"
        name="card number"
        class="form-control"
        v-validate="'required'"
        v-model="newPaymentOption.card_number"
      />
      <span v-show="errors.has('card number')" class="text-danger">
        {{ errors.first("card number") }}
      </span>
    </div>
    <!-- /form-group -->
    <div class="row">
      <div class="col-sm-8">
        <label>Expiration Date</label>
        <div class="row">
          <div class="form-group col-sm-6">
            <span class="styled-select">
              <select
                id=""
                class="form-control"
                name="expiration month"
                v-validate="'required'"
                v-model="expiryMonth"
              >
                <option selected="selected">Select Month</option>
                <option
                  :value="month.val"
                  v-for="month in monthsList"
                  :key="month.index"
                >
                  {{ month.labelShort }}
                </option>
              </select>
            </span>
            <span v-show="errors.has('expiration month')" class="text-danger">
              {{ errors.first("expiration month") }} </span
            ><!-- /styled-select -->
          </div>
          <!-- /form-group -->
          <div class="form-group col-sm-6">
            <span class="styled-select">
              <select
                id=""
                class="form-control"
                name="expiration year"
                v-validate="'required'"
                v-model="expiryYear"
              >
                <option selected="selected">Select Year</option>
                <option
                  :value="year.toString()"
                  v-for="year in yearsList"
                  :key="year.index"
                >
                  {{ year }}
                </option>
              </select> </span
            ><span v-show="errors.has('expiration year')" class="text-danger">{{
              errors.first("expiration year")
            }}</span
            ><!-- /styled-select -->
          </div>
          <!-- /form-group -->
        </div>
        <!-- /row -->
      </div>
      <!-- /col-sm-8 -->
      <div class="form-group col-sm-4">
        <label>Security Code</label>
        <input
          type="text"
          name="security_code"
          class="form-control"
          v-validate="'required'"
          v-model="newPaymentOption.security_code"
        />
        <span v-show="errors.has('security_code')" class="text-danger">{{
          errors.first("security_code")
        }}</span>
      </div>
      <!-- /form-group -->
    </div>
    <!-- /row -->
    <div class="row">
      <div class="form-group col-sm-12">
        <label>Company / Personal (First Last)*</label>
        <input
          type="text"
          name="name"
          class="form-control"
          :class="{ 'has-error': errors.has('name') }"
          maxlength="30"
          v-validate="'required'"
          v-model="newPaymentOption.billing_address.name.given_name"
          autocomplete="name"
          @input="
            newPaymentOption.billing_address.name.given_name = formatName(
              $event.target.value
            )
          "
        />
        <span v-show="errors.has('name')" class="text-danger">{{
          errors.first("name")
        }}</span>
      </div>

      <div class="col-sm-6 form-group">
        <label>Country*</label>
        <span class="styled-select">
          <select
            name="country"
            type="country"
            id="countryCode"
            v-model="selectedCountryChecked"
            class="form-control"
            :class="{ 'has-error': errors.has('country') }"
            v-validate="'required'"
            autocomplete="country"
            @change="updatedCountry"
          >
            <option value="" disabled>Select country...</option>
            <option
              :value="{
                code: 'USA',
                long_name: 'United States',
                region_name: 'State',
              }"
            >
              United States
            </option>
            <option
              :value="{
                code: 'CAN',
                long_name: 'Canada',
                region_name: 'Province',
              }"
            >
              Canada
            </option>
            <option disabled>--------------</option>
            <option
              v-for="country in countries"
              :value="country"
              :key="country.code"
            >
              {{ country.long_name }}
            </option>
          </select>
        </span>
        <span
          v-show="errors.has('country')"
          class="text-danger"
          style="float: left"
          >{{ errors.first("country") }}</span
        >
      </div>

      <div class="col-sm-6 form-group">
        <label>Phone Number*</label>
        <input
          type="tel"
          name="phone_number"
          class="form-control"
          :class="{ 'has-error': errors.has('phone_number') }"
          maxlength="30"
          v-model="newPaymentOption.billing_address.phone_number"
          v-validate="{ required: true }"
          autocomplete="tel"
          @input="
            newPaymentOption.billing_address.phone_number = formatPhone(
              $event.target.value
            )
          "
        />
        <span
          v-show="errors.has('phone_number')"
          class="text-danger"
          style="float: left"
        >
          {{ errors.first("phone_number") }}
        </span>
      </div>

      <div class="col-sm-6 form-group">
        <label>Address*</label>
        <input
          type="text"
          name="street_address"
          class="form-control"
          :class="{ 'has-error': errors.has('street_address') }"
          maxlength="30"
          v-validate="{ required: true }"
          v-model="newPaymentOption.billing_address.street_address"
          autocomplete="address-line1"
          @input="
            newPaymentOption.billing_address.street_address = formatAddress(
              $event.target.value
            )
          "
        />
        <span v-show="errors.has('street_address')" class="text-danger">{{
          errors.first("street_address")
        }}</span>
      </div>

      <div class="col-sm-6 form-group">
        <label>Address Line 2</label>
        <input
          type="text"
          name="address-line2"
          class="form-control"
          maxlength="30"
          v-model="newPaymentOption.billing_address.street_address_2"
          autocomplete="address-line2"
          @input="
            newPaymentOption.billing_address.street_address_2 = formatAddress(
              $event.target.value
            )
          "
        />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4 form-group">
        <label>City*</label>
        <input
          type="text"
          name="city"
          class="form-control"
          :class="{ 'has-error': errors.has('city') }"
          maxlength="20"
          v-validate="'required'"
          v-model="newPaymentOption.billing_address.city"
          @input="
            newPaymentOption.billing_address.city = formatAddress(
              $event.target.value
            )
          "
        />
        <span
          v-show="errors.has('city')"
          class="text-danger"
          style="float: left"
          >{{ errors.first("city") }}</span
        >
      </div>

      <div class="col-sm-4 form-group">
        <label
          >State{{
            regionCheck(newPaymentOption.billing_address.country_code)
              ? "*"
              : ""
          }}</label
        >
        <span class="styled-select">
          <select
            name="state"
            type="text"
            id="regionCode"
            v-model="newPaymentOption.billing_address.region_code"
            class="form-control"
            :class="{ 'has-error': errors.has('state') }"
            v-validate="{
              required: regionCheck(
                newPaymentOption.billing_address.country_code
              ),
            }"
            autocomplete="state"
            :disabled="!isStateRequired"
          >
            <option value="" disabled>
              {{ isStateRequired ? "Select state..." : "" }}
            </option>
            <option
              v-for="region in regions"
              :value="region.code"
              :key="region.code"
            >
              {{ region.name }}
            </option>
          </select>
        </span>
        <span
          v-show="errors.has('state')"
          class="text-danger"
          style="float: left"
          >{{ errors.first("state") }}</span
        >
      </div>

      <div class="col-sm-4 form-group">
        <label
          >Postal Code{{
            postalCheck(newPaymentOption.billing_address.country_code)
              ? "*"
              : ""
          }}</label
        >
        <input
          type="text"
          maxlength="10"
          name="postal_code"
          class="form-control"
          :class="{ 'has-error': errors.has('postal_code') }"
          autocomplete="postal_code"
          @input="
            newPaymentOption.billing_address.postal_code = formatPostalCode(
              $event.target.value
            )
          "
          v-validate="{
            required: postalCheck(
              newPaymentOption.billing_address.country_code
            ),
          }"
          v-model="newPaymentOption.billing_address.postal_code"
        />
        <span
          v-show="errors.has('postal_code')"
          class="text-danger"
          style="float: left"
          >{{ errors.first("postal_code") }}</span
        >
      </div>
    </div>
    <br />
    <reCAPTCHA
      @verification-changed="captchaVerificationChanged" />
    <div class="form-group col-sm-12">
      <p>
        By clicking "Add Card" I agree that I have read and accepted
        {{ $root.domainName }}
        <a href="/terms-and-policies">Terms and Conditions</a>
      </p>
      <button
        type="submit"
        class="btn btn-blue"
        @click.prevent="addPaymentOption"
        :disabled="processing || !addressFormIsCompleted || !captchaIsVerified"
      >
        Add Card <i v-if="processing" class="fa fa-spin fa-spinner"></i>
      </button>
      <input
        type="button"
        name=""
        value="Cancel"
        class="btn btn-blue"
        @click.prevent="emitClose"
        :disabled="processing"
      />
    </div>
    <!-- /form-group -->
  </div>
</template>

<script>
import formatting from "../../mixins/formatting";
import reCAPTCHA from "../reCAPTCHA.vue";
export default {
  name: "payment-option-list-form",
  components: { reCAPTCHA },
  mixins: [formatting],
  data() {
    return {
      captchaError: null,
      captcha_verified: null,
      invalidFormError: null,
      monthsList: window.monthsList,
      yearsList: window.yearsList,
      paymentOptions: window._PR?.paymentOptions?.data || [],
      newPaymentOption: {
        card_number: "",
        security_code: "",
        valid_through: "",
        billing_address: {
          country_code: "",
          type: "shipping",
          last_used_at: "",
          company: "",
          city: "",
          name: {
            middle_name: "",
            family_name: "",
            given_name: "",
          },
          street_address: "",
          street_address_2: "",
          postal_code: "",
          phone_number: "",
          region_code: "",
          type: "billing",
        },
      },
      selectedCountry: "",
      countries: "",
      country: "",
      comments: "", // not sure where this will go since API doesn't accepts this parameter
      expiryMonth: "",
      expiryYear: "",
      regions: [],
      postalRequired: [],
      regionRequired: [],
      processing: false,
      deleting: false,
      isStateRequired: false,
    };
  },
  created() {
    let vm = this;

    vm.getCountries();

    vm.$on("add-billing-address", (billing_address) => {
      vm.$set(
        vm.availableBillingAddresses,
        vm.availableBillingAddresses.length,
        billing_address
      );
    });
  },

  computed: {
    addressFormIsCompleted() {
      if (
        this.newPaymentOption.billing_address.name.given_name !== "" &&
        this.newPaymentOption.billing_address.street_address !== "" &&
        this.newPaymentOption.billing_address.country_code !== "" &&
        this.newPaymentOption.billing_address.postal_code !== "" &&
        this.newPaymentOption.billing_address.city !== "" &&
        this.newPaymentOption.card_number !== "" &&
        this.expiryYear !== "" &&
        this.expiryMonth !== ""
          ? true
          : false
      ) {
        if (
          (this.isStateRequired &&
            this.newPaymentOption.billing_address.region_code !== "") ||
          !this.isStateRequired
        ) {
          return true;
        }
      }

      return false;
    },
    selectedCountryChecked: {
      get() {
        if (this.selectedCountry === "") {
          return {};
        }

        return this.selectedCountry;
      },
      set(value) {
        this.selectedCountry = value;
      },
    },
    captchaIsVerified() {
      return this.captcha_verified !== null
    }
  },

  watch: {
    "selectedCountry.code"(code) {
      this.isStateRequired = ["CAN", "USA", "JAM", "IND", "AUS"].includes(code);

      if (this.isStateRequired) {
        this.getRegions();
      } else {
        // If user has switched from a country where state is
        // required to non required then remove the previously
        // filled value
        this.newPaymentOption.billing_address.region_code = "";
      }
    },
  },

  methods: {
    getCountries() {
      let vm = this;
      let countriesUrl =
        "/api/proxify/domains/cerakote/shop/countries?fields=code,long_name,region_name,postal_required,region_required";
      axios
        .get(countriesUrl)
        .then(({ data: response }) => {
          vm.countries = response;

          vm.postalRequired = response
            .filter(function (elem) {
              if (elem.postal_required == true) return elem.code;
            })
            .map((a) => a.code);
          vm.regionRequired = response
            .filter(function (elem) {
              if (elem.region_required == true) return elem.code;
            })
            .map((a) => a.code);

          if (vm.validateBillingAddress) {
            vm.selectedCountry = vm.countries.filter(
              (country) => country.code === vm.addressToValidate.country.code
            )[0];
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    captchaVerificationChanged(verified) {
      console.debug('reCAPTCHA verification changed:', verified)
      this.captcha_verified = verified
    },
    postalCheck() {
      return _.includes(
        this.postalRequired,
        this.newPaymentOption.billing_address.country_code
      );
    },
    emitClose() {
      this.$emit("close-form");
    },
    regionCheck() {
      return _.includes(
        this.regionRequired,
        this.newPaymentOption.billing_address.country_code
      );
    },
    async addPaymentOption() {
      if (this.processing) return false;
      let vm = this;

      try {
        // Verify that the user has completed the reCAPTCHA.
        // If not, display an error message and return early.
        if (!this.captchaIsVerified) {
          this.captchaError = this.$root.$refs.notifications.add({
            delay: false,
            type: "error",
            icon: "exclamation-triangle",
            title: "Invalid Request",
            message: "Please verify that you are not a robot before submitting the form.",
          });
          console.log('captcha error', this.captchaError);
          return
        }

        let success = await this.$validator.validateAll();

        // Verify that the user has completed the form correctly.
        // If not, display an error message and return early.
        if (!success) {
          this.invalidFormError = this.$root.$refs.notifications.add({
            delay: false,
            type: "error",
            icon: "exclamation-triangle",
            title: "Invalid Request",
            message: "Please verify that you have completed the form correctly.",
          });
          console.log('invalid form error', this.invalidFormError);
          return
        }

      } catch {
        console.error(error)
        return
      }

      vm.processing = true;

      let newPayment = vm.newPaymentOption;
      newPayment.valid_through = `${this.expiryMonth}/${this.expiryYear}`;

      const cardNumber = this.newPaymentOption.card_number;
      const validCardNumbers = ["2", "4", "5", "6", "34", "37"];
      const isValidCardNumber = validCardNumbers.some((number) =>
        cardNumber.startsWith(number)
      );

      if (!isValidCardNumber) {
        alert(
          "Credit Card number is invalid, please try again with" +
            "either a Visa, Mastercard, American Express or Discover Card"
        );
        // this.setSavingPaymentOption(false);
        // this.setPaymentFailureMessage(this.errorResponse);
        vm.processing = false;
        return;
      }

      this.sendAddressToApi();

      axios
        .post(`/api/proxify/me/payment-options`, newPayment)
        .then((response) => {
          this.paymentOptions.unshift(response.data);
          this.paymentOptions[0].formatted_name =
            this.paymentOptions[0].issuer +
            " ending in " +
            this.paymentOptions[0].masked_number;
          this.emitClose();
          vm.processing = false;
        })
        .catch((response) => {
          console.log(response);
          vm.processing = false;
          alert("Could not add payment option");
        });
    },
    isValidCard(card) {
      const MONTH = 0;
      const YEAR = 1;

      let splitExpire = card.valid_through.split("/");
      let expMonth = parseInt(splitExpire[MONTH]);
      let expYear = parseInt(splitExpire[YEAR]);
      let curMonth = new Date().getMonth() + 1; // Months are 0-based
      let curYear = new Date().getFullYear();

      /*
       *  If the current year is less than the expire year,
       *  the month doesn't matter.
       *
       *  The card is VALID.
       */
      if (curYear < expYear) {
        return true;
      }

      /*
       *  If the current year is equal to the expire year
       *  AND
       *  The current month is less than or equal to the expire
       *  month.
       *
       *  The card is VALID.
       */
      if (curYear === expYear && curMonth <= expMonth) {
        return true;
      }

      /*
       *  Else if the current year is greater than the expire year
       *  OR
       *  the current year is equal to the expire year, but the current
       *  month is greater than the expire month.
       *
       *  The card is INVALID.
       */
      return false;
    },
    updatedCountry() {
      this.newPaymentOption.billing_address.country_code =
        this.selectedCountryChecked.code;
    },

    async getRegions() {
      const countryCode =
        "country_code" in this.newPaymentOption.billing_address
          ? this.newPaymentOption.billing_address.country_code
          : this.newPaymentOption.billing_address.country.code;
      const regionsUrl =
        "/api/proxify/domains/cerakote/shop/countries/" +
        countryCode +
        "/regions";

      try {
        let response = await axios.get(regionsUrl);
        this.regions = response.data;

        if (this.newPaymentOption.billing_address.id) {
          if (this.editedAddress.region) {
            this.newPaymentOption.billing_address.region_code =
              this.editedAddress.region.code;
          } else {
            this.newPaymentOption.billing_address.region_code =
              this.editedAddress.region_code;
          }
        }

        this.$validator.validateAll();
      } catch (e) {
        console.error(e);
      }
    },
    async sendAddressToApi(action = "new") {
      try {
        let response = "";
        let addressURL = "/api/proxify/me/addresses";

        response = await axios.post(
          addressURL,
          this.newPaymentOption.billing_address
        );

        if (action === "new") {
          // this.$root.availableBillingAddresses.push(savedAddress);
        }
      } catch (error) {
        console.error(error);
      } finally {
        // this.bools.submitting = false;
      }
    },
  },
};
</script>

<style scoped>
.payment-options {
  margin-top: 20px;
}
#comments {
  height: 125px;
}
</style>

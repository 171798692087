
.applicator-marker {
  transition: z-index 200ms ease-in-out,
              transform 200ms ease-in-out;
  transform: scale(1) translate3d(-24px, -24px, 0);
  backface-visibility: hidden;
  transform-origin: top left;
  justify-content: center;
  pointer-events: none;
  align-items: center;
  position: absolute;
  cursor: pointer;
  display: flex;
  z-index: 1;
  opacity: 1;

  &::before,
  &::after {
    transition: transform 200ms ease-in-out,
                opacity 200ms ease-in-out;
    transform: translate3d(0, 0, 0);
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    opacity: 0;
    z-index: 2;
  }

  &::before {
    box-shadow: 0 1px 3px rgba(black, 0.12),
                0 1px 2px rgba(black, 0.24);
    border-radius: var(--applicator-border-radius);
    text-transform: uppercase;
    background-color: white;
    content: attr(aria-label);
    letter-spacing: 0.65px;
    white-space: nowrap;
    padding-right: 8px;
    padding-left: 8px;
    line-height: 20px;
    font-weight: 700;
    font-size: 10px;
    color: #333;
  }

  &::after {
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 8px solid white;
    content: "";
    z-index: 3;
  }

  &-pin {
    transition: box-shadow 200ms ease-in-out,
                transform 200ms ease-in-out;
    box-shadow: 0 1px 2px 0 rgba(black, 0.15);
    background-repeat: no-repeat;
    border: 2px solid white;
    background-color: white;
    transform: scale(1);
    pointer-events: all;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    height: 48px;
    width: 48px;

    > img {
      position: absolute;
      border-radius: 50%;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }

  }

  &[aria-selected] {
    z-index: 10;
  }

  &:hover {
    z-index: 11;
  }

  &:hover,
  &[aria-selected] {
    transform: scale(1) translate3d(-24px, -24px, 0);

    &::before,
    &::after {
      opacity: 1;
    }

    &::before {
      transform: translate3d(0, -14px, 0);
    }

    &::after {
      transform: translate3d(0, -7px, 0);
    }

    .applicator-marker-pin {
      box-shadow: 0 10px 20px rgba(black, 0.19),
                  0 6px 6px rgba(black, 0.23);
      transform: scale(1.125);
    }

  }

}

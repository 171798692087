<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06174 1.20317">
    <defs>
      <clipPath id="clip-path">
        <rect class="uk-1" width="2.06174" height="1.20317"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="uk-2">
          <rect class="uk-3" width="2.06174" height="1.20317"/>
          <polygon class="uk-4" points="1.381 0.437 2.062 0.057 2.062 0 2.019 0 1.238 0.437 1.381 0.437"/>
          <polygon class="uk-4" points="1.353 0.795 2.062 1.189 2.062 1.11 1.489 0.795 1.353 0.795"/>
          <polygon class="uk-4" points="0 0.093 0.609 0.437 0.752 0.437 0 0.014 0 0.093"/>
          <polygon class="uk-4" points="0.723 0.795 0 1.196 0 1.203 0.128 1.203 0.866 0.795 0.723 0.795"/>
          <polygon class="uk-5" points="1.919 0 1.202 0 1.202 0.408 1.919 0"/>
          <polygon class="uk-5" points="0.881 0 0.164 0 0.881 0.408 0.881 0"/>
          <polygon class="uk-5" points="2.062 0.437 2.062 0.158 1.575 0.437 2.062 0.437"/>
          <polygon class="uk-5" points="2.062 1.067 2.062 0.795 1.575 0.795 2.062 1.067"/>
          <polygon class="uk-5" points="0.2 1.203 0.881 1.203 0.881 0.823 0.2 1.203"/>
          <polygon class="uk-5" points="1.202 1.203 1.883 1.203 1.202 0.823 1.202 1.203"/>
          <polygon class="uk-5" points="0 0.795 0 1.081 0.508 0.795 0 0.795"/>
          <polygon class="uk-5" points="0 0.437 0.508 0.437 0 0.15 0 0.437"/>
          <polygon class="uk-4" points="0.945 0 0.945 0.508 0 0.508 0 0.723 0.945 0.723 0.945 1.203 1.138 1.203 1.138 0.723 2.062 0.723 2.062 0.508 1.138 0.508 1.138 0 0.945 0"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ukFlag"
}
</script>

<style type="text/css">
  .uk-1 {
    fill: none;
  }

  .uk-3 {
    fill: #fff;
  }

  .uk-4 {
    fill: #bd0034;
  }

  .uk-5 {
    fill: #1a237b;
  }
</style>

#about-content {
  background-color: rgb(15, 15, 15);
  padding: 0 110px 25px 110px;

  #history::after {
    content: 'History';
  }

  #mission::after {
    content: 'Mission';
  }

  #core-values::after {
    content: 'Core Values';
  }

  #corp-governance::after {
    content: 'Corporate Governance';
  }

  #facility-location::after {
    content: 'Facility & Location';
  }

  #foundation::after {
    content: 'The Foundation of Freedom';
  }

  p {
    color: rgb(221, 221, 221);
    font-size: 2rem;
    margin-bottom: 30px;
  }

  h3 {
    color: rgb(15, 15, 15);
    font-size: 2.2rem;
    font-weight: 700;
    padding: 5px 5px 5px 20px;
    text-transform: uppercase;
  }

  h2 {
    color: rgb(248, 150, 33);
    font-size: 3.5rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  h2::after {
    display: flex;
    transform: rotateX(180deg);
    background-image: linear-gradient(
      180deg,
      rgba(248, 150, 33, 0) 20%,
      rgba(248, 150, 33, 0.25)
    );
    -webkit-background-clip: text;
    color: transparent;
    margin-top: -15px;
  }

  .cv-background {
    background-color: #f89621;
    width: 300px;
  }

  .center {
    margin: 0 auto;
    width: 40%;
    display: block;
  }

  @media screen and (max-width: 480px) {
    padding: 10px;

    .center {
      width: 90%;
    }
  }
}

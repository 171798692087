
#featured-brands {
  #featured-brands-carousel {
    overflow: hidden;

    .carousel-inner {
      overflow: visible !important;
      display: flex;
      white-space: nowrap;
      animation: marquee 60s linear infinite;
      width: max-content;

      .brand-group {
        flex: 0 0 auto;
        display: inline-block;
        margin-right: 10px;
      }

      .brand {
        padding: 0;
      }

      img {
        border-radius: 5%;
        height: 150px;
        width: 150px;
      }
    }
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }
}


#projectUpload {

  label {
    font-weight: normal;
  }

  .modal-content {
    background-color: #fff;
    color: #333;
    border-radius: 6px;

    .modal-header {
      border-bottom: 1px solid #e5e5e5;
      padding: inherit;

      .close {
        color: #333;
        font-size: 25px;
        padding: 0;
      }
    }

    .modal-body {
      padding-bottom: 0;

      section {
        margin-bottom: 0.8rem;
      }

      .input-label {
        font-size: 1.1rem;
        text-transform: uppercase;
        color: #777;
      }

      .file-input {
        position: absolute;
        visibility: hidden;
      }

      ul.file-list {
        font-family: arial;
        list-style: none;
        padding: 0;
      }

      ul.file-list li {
        margin: 10px;
        outline: 1px solid #333;
        padding: 5px;
        border-radius: 3px;
        display: inline-block;
      }

      .form-group {
        margin-bottom: 0;
      }
    }

    .modal-footer {
      text-align: left;
      padding-top: 0;

      .project-terms {
        margin-bottom: 15px;
      }

      label {
        display: block;
      }
    }
  }
}


#applicator-details {
  width: calc(var(--sidebar-width) + var(--applicator-filters-width));
  margin-left: calc(var(--applicator-filters-width) * -1);
  padding: var(--sidebar-padding);
  background-color: #f9fafc;
  align-items: flex-start;
  flex-direction: row;
  min-height: 100%;
  display: flex;
  z-index: 2;

  &-back-to-results {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 1500px) {
    flex-direction: column;
    width: 100%;
    margin: 0;
  }

  a:not([class]) {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &-left,
  &-right {
    flex-direction: column;
    display: flex;
  }

  &-left {
    top: calc(var(--desktop-header-size) + var(--sidebar-padding));
    padding-right: calc(var(--sidebar-padding) / 2);
    position: sticky;
    flex: 1 0 55%;
    width: 55%;

    @media screen and (max-width: 1500px) {
      position: relative;
      flex: 1 0 auto;
      width: 100%;
      padding: 0;
      top: auto;
    }
  }

  &-right {
    padding-left: calc(var(--sidebar-padding) / 2);
    flex: 1 0 45%;
    width: 45%;

    @media screen and (max-width: 1500px) {
      flex: 1 0 auto;
      width: 100%;
      padding: 0;
    }
  }

  .applicator-card-showcase,
  .applicator-card-banner {
    margin: 0 0 22px;
    width: 100%;
  }

  .applicator-card-banner,
  .applicator-card-showcase-slider {
    border-bottom-right-radius: var(--applicator-border-radius);
    border-bottom-left-radius: var(--applicator-border-radius);
  }

  .applicator-card-banner {
    padding-bottom: 56.25%;

    @media screen and (max-width: 1500px) {
      margin: 0 0 22px;
    }
  }

  .applicator-card-showcase-slider {
    margin: 0;
  }

  .project-review-quote {
    margin: 16px 0 0 0;

    p {
      -webkit-line-clamp: initial;
      text-overflow: initial;
      overflow: initial;
      max-height: none;
      font-size: 14px;
    }
  }

  &-actions {
    flex-direction: row;
    margin-top: 16px;
    display: flex;
    width: 100%;

    > .btn {
      margin: 0;
      flex: 1;

      + .btn {
        margin-left: 16px;
      }
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;

      > .btn {
        flex: auto;

        + .btn {
          margin-top: 12px;
          margin-left: 0;
        }
      }
    }
  }
}


#filter-pills-container {
  margin-bottom: 10px;
  position: sticky;
  top: 149px;
  z-index: 10;
  margin-left: -2px;

  hr {
    border-top: 1px solid #bcbcbc;
    margin: 0;
  }

  .filter-pills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (max-width: 510px) {
  #filter-pills-container {
    top: 117px;
  }
}

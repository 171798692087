
form {
  input {
    position: relative;
    vertical-align: top;
    background-color: transparent !important;
  }

  >span {
    position: relative;
    display: inline-block;
  }
}

ol {
  margin-left: 25px;

  >li>a {
    line-height: 4.0rem;
  }
}

.bookmark-true,
.bookmark-false {
  transition: color .3s;
  font-size: initial;
  cursor: pointer;
  min-width: 2rem;
  flex: 0 1 4rem;
}

.bookmark-false {
  color: #888888;

  &:hover {
    color: #7ED323;
  }
}

.bookmark-true {
  color: #7ED323;
}

<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 166.91553 800 466.16943">
  <g>
    <path class="is-i-0" d="M314.99805,453.41309h179.83008l-89.91602-155.73779L314.99805,453.41309z M404.91211,505.3252
      l89.91602-155.7373H314.99805L404.91211,505.3252z"/>
    <g>
      <path class="is-i-1" d="M172.5,400.20752c0,54.40771,18.87939,104.39795,50.42822,143.80615h359.729
        c31.54883-39.4082,50.42773-89.39844,50.42773-143.80615c0-53.22314-18.06348-102.22266-48.38379-141.22119H220.8833
        C190.56396,297.98486,172.5,346.98438,172.5,400.20752z"/>
      <path class="is-i-2" d="M220.8833,258.98633h363.81787c-42.13379-54.1958-107.94531-89.07129-181.9082-89.07129
        C328.8291,169.91504,263.01807,204.79053,220.8833,258.98633z"/>
      <path class="is-i-2" d="M402.79297,630.5c72.7793,0,137.66211-33.77148,179.86426-86.48633h-359.729
        C265.13037,596.72852,330.01318,630.5,402.79297,630.5z"/>
    </g>
  </g>
  </svg>
</template>

<script>
export default {
  name: "israelIcon"
}
</script>

<style>
  .is-i-0{fill:none;stroke:#0038B8;stroke-width:27.5;}
  .is-i-1{fill:none;}
  .is-i-2{fill:#0038B8;}
</style>
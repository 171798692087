<template>
  <div class="my-account">
    <div class="heading-bordered heading-account">
      <h2><span>Manage Your Payment Options</span></h2>
    </div>
    <!-- /heading-bordered -->

    <div class="wrap-accordion" v-if="paymentOptions.length">
      <div
        id="accordion"
        class="accordion-in"
        role="tablist"
        aria-multiselectable="true"
      >
        <div
          class="card shadow-box"
          v-for="paymentOption in paymentOptions"
          :key="paymentOption.index"
        >
          <input type="radio" name="paymentoption" class="form-control" />
          <div class="accordion-head cc-option">
            <div class="col-sm-8">
              <img :src="cardImgPath(paymentOption.issuer)" alt="" />
              <span>{{ paymentOption.formatted_name }}</span>
            </div>
            <!-- /col-sm-8 -->
            <div class="col-sm-4">
              <span>Expires {{ paymentOption.valid_through }}</span>
            </div>
            <!-- /col-sm-4 -->
          </div>
          <!-- /accordion-head -->
          <div
            class="remove-payment-opt"
            @click="deletePaymentOption(paymentOption)"
            :disabled="deleting"
          >
            <i class="fa fa-trash" v-if="!deleting"></i>
            <i v-if="deleting" class="fa fa-spin fa-spinner"></i>
          </div>
        </div>
        <!-- /shadow-box -->
      </div>
      <!-- /accordion-in -->
    </div>
    <a
      href="#"
      class="shadow-box addPaymentOption"
      @click.prevent="showPaymentOptionForm = true"
    >
      + Add New Payment Option
    </a>
    <div class="row payment-options" v-if="showPaymentOptionForm">
      <payment-option-list-form @close-form="closeForm">
      </payment-option-list-form>
      <!-- /col-sm-12 -->
    </div>
    <!-- /row -->
    <!-- /wrap-accordion -->
  </div>
</template>

<script>
import PaymentOptionListForm from "./PaymentOptionListForm.vue";
import formatting from "../../mixins/formatting";
export default {
  name: "payment-option-list",
  mixins: [formatting],
  components: {
    PaymentOptionListForm,
  },
  data() {
    return {
      showPaymentOptionForm: false,
      paymentOptions: window._PR?.paymentOptions?.data || [],
      processing: false,
      deleting: false,
    };
  },

  methods: {
    cardImgPath(issuer) {
      switch (issuer) {
        case "visa":
          return "/img/visa.svg";
        case "mastercard":
          return "/img/mastercard.svg";
        case "american_express":
          return "/img/american-express.svg";
        case "discover":
          return "/img/discover.svg";
        case "diners_club":
          return "/img/diners-club.svg";
        case "jcb":
          return "/img/jcb.svg";
        default:
          return "/img/cvc.svg";
      }
    },
    deletePaymentOption(paymentOption) {
      let vm = this;
      vm.deleting = true;
      axios({
        url: `/api/proxify/me/payment-options/${paymentOption.id}`,
        method: "delete",
        headers: { "Content-Type": "application/json" },
        data: {},
      })
        .then((response) => {
          vm.paymentOptions = vm.paymentOptions.filter(
            (addr) => addr.id !== paymentOption.id
          );
          vm.deleting = false;
        })
        .catch((response) => alert("Could not delete"));
    },
    closeSelector() {
      this.$parent.showSageCardForm = true;
      this.$emit("close-selector");
      this.editOption = {};
      this.selectedNewPayment = false;
      this.isEditing = false;
    },
    closeForm() {
      this.showPaymentOptionForm = false;
    },
  },
};
</script>

<style scoped>
.payment-options {
  margin-top: 20px;
}
#comments {
  height: 125px;
}
</style>

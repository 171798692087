<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06907 1.20317">
    <defs>
      <clipPath id="clip-path">
        <rect class="ger-1" width="2.06907" height="1.20317"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="ger-2">
          <rect width="2.06907" height="0.40106"/>
          <rect class="ger-3" y="0.40106" width="2.06907" height="0.40106"/>
          <rect class="ger-4" y="0.80211" width="2.06907" height="0.40106"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "germanyFlag"
}
</script>

<style type="text/css">
  .ger-1 {
    fill: none;
  }

  .ger-3 {
    fill: #e1001f;
  }

  .ger-4 {
    fill: #f6d928;
  }
</style>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="124.43311 279.56641 551.1001 240.86719">
  <g>
    <polygon class="best-rates" points="170.0332,442.87793 125.31104,448.57813 170.0332,445.59961 324.74414,435.28906 325.38867,423.06641 
        "/>
    <path class="best-rates" d="M346.62207,412.5332c7.15527-0.01074,13.7666-0.82227,19.83301-2.42188
      c5.2334-1.37793,9.11133-3.28906,11.65625-5.72266c2.5332-2.43359,3.86621-5.05566,4.02148-7.87793
      c0.08984-1.75537-0.32129-3.1665-1.23242-4.23291c-0.91113-1.06689-2.42285-1.97803-4.52246-2.75586
      c-2.10059-0.77734-7.74512-2-16.92285-3.68848c-8.2998-1.56689-14.48828-3.57813-18.55469-6.03369
      c-4.06738-2.45557-7-5.72217-8.81152-9.82227c-1.81152-4.08887-2.54492-9.2998-2.21094-15.64404
      c0.62207-11.84473,5.47754-22.24463,14.57715-31.17773c4.77832-4.68896,10.42285-8.68896,16.90039-12.03369l0.75586-14.43311
      l-191.9668,48.5332l-45.6333,11.54443l45.6333-6.83301l100.11084-15.00049L170.1333,358.5332L124.5,369.28906l45.6333-6.07813
      l118.82227-15.82227L170.11133,373.6665l-44.7334,9.88916l44.72217-5.48926l138.34424-16.97754L170.08887,387.6001
      l-44.7334,8.56689l44.7334-5.48926l153.84473-18.87793l-153.85596,29.68945l-45.6333,8.81055l45.6333-5.15527l171.87744-19.43359
      l-171.88867,29.45605l-45.6333,7.82227l45.6333-4.17773l184.7002-16.90039l-184.71094,27.41113l-44.7334,6.63281l44.72217-3.43262
      l155.47803-11.94434l0.55566-10.4668C332.61133,411.7334,339.4668,412.54492,346.62207,412.5332z"/>
    <path class="best-rates" d="M673.37793,342.45557c-0.17773,0-0.40039,0-0.60059,0v-0.58887c0-4.04443-3.09961-7.3335-6.91113-7.32227
      l-6.89941,0.01123l-0.7002-12.2002c-0.04395-0.3667-1.13379-9.19971-8.92188-12.69971l-26.68945-12
      c0,0-4.0332-1.54443-5.42188-1.53369l-4.11133-0.08887l-31.7334-11.33301l-0.51074-0.01123l-20.25586-0.51123l0.0332-1.28906
      l-130.7334-3.32227l-40.69922,10.28906l-0.64453,12.21094c11.02148-1.98877,22.12207-2.0332,33.28809-0.0332l-12.21094,29.68896
      c-11.89941-2.28906-22.28906-2.2666-31.15527,0.07764c-4.57813,1.21143-7.95508,2.87793-10.11133,5.01123
      c-2.16602,2.1333-3.31055,4.37793-3.43359,6.72217c-0.13281,2.52246,1.06738,4.43359,3.61133,5.72217
      c2.54492,1.30029,9.5332,3.03369,20.97852,5.21143c10.97754,2.01074,18.51074,5.2998,22.57715,9.8667
      c4.06641,4.56641,5.87793,11.18848,5.42285,19.86621c-0.42285,7.97803-2.84473,15.6001-7.26758,22.87842
      c-4.43262,7.27734-10.59961,13.64355-18.51074,19.12207c-2.77734,1.92188-5.7334,3.63281-8.81152,5.21094l30.84473-2.05566
      l-32.16602,3.59961l-1.67871,13.13379l49.78906-3.0332l0.02246-14.7666l0.02246-15.7334l0.02246-21.78906l0.02148-20.1333
      l0.01172-11.84424l0.01074-12.62256l0.0332-29.95557l0.02246-18.92188l0.01074-10.32227h0.25586h2.02246v-0.08887l3.45508,0.07764
      h0.01172l0,0l12.01074,0.28906c-3.47754,7.69971-10.58887,25.14404-10.61133,39.32178l-0.09961,81.87793
      c-0.01172,9.2666,4.2002,28.71094,5.34375,33.81152l-0.82227,0.33301l-0.18848,0.05566l1.0332-0.06641l-0.2334,0.89941
      l-0.61133,2.41113l-0.39941,1.60059l-11.71094,0.02148l-64.02246,4.0332l-11.42188,1.13379l0.02148-0.41113l-171.37744,10.84473
      v3.44434l-44.43311,4.57813l35.3667-1.03418c-0.3335,0.53418-0.54443,1.17773-0.54443,1.95605c0,1.64453,0,3.7334-0.01123,5.56641
      l-33.22217,2.7666l33.22217,0.71094c0,0.62305,0,1,0,1l9.6001-0.02148v1.69922l2.8667,2.94531l-43.28906,2.89941l50.54443,2.17773
      c0,0.22266-0.0332,0.42285-0.0332,0.64453c0,1.55566,0.11084,3.06641,0.27783,4.55566l-46.94482,3.05566l47.85596,2
      c0.69971,2.77734,1.68848,5.31055,2.92188,7.48926c-19.47754,2.04395-40.38867,5.17773-44.45557,6.59961
      c-2.51074,0.87793,30.47803,4.2998,34.52246,4.28906c26.2998-0.06641,425.7666-1.02246,447.15527-1.06641
      c21.38867-0.05566,28.47754-3.45605,33.11133-4.82227c3.08887-0.91113-7.88867-4.13379-20.36621-6.17773h2.28809l10.31152-6.25586
      c0.87793-0.81152,3.2334-5.2002,3.2334-10.67773v-5.34473l-1.43359-6.5332l1.44434-2.43359l0.01172-13.74414h0.15527
      l0.01074-14.17773c0,0-0.07715,0-0.15527,0v-3.01172l-4.2998-5.72168l4.31055-6.08887l0.0332-35.72266l-0.28809-4.96631
      l3.42188-0.01123c3.81152-0.01074,6.92188-3.31104,6.92188-7.35547v-2.8335h0.30078c0,0,2.48828-0.18848,2.5-3.19971
      c0-3,0.04395-34.65576,0.04395-34.65576S675.0332,342.45557,673.37793,342.45557z M213.34424,513.95508
      c-0.37744,0.05566-0.75537,0.10059-1.14404,0.10059c-6.37793,0.01074-11.71143-7.31152-13.02246-17.06641
      c0.32227,0.83301,0.6665,1.66602,1.06689,2.5c3.85547,7.93262,10.33301,13,16.22217,13.39941
      C215.47754,513.43359,214.43311,513.7998,213.34424,513.95508z M206.5332,472.56641
      c1.7334-1.35547,3.67773-2.13281,5.72217-2.14453c0.48926,0,0.9668,0.04492,1.44482,0.13379
      c5.34424,0.93359,9.75537,7.02246,11.2998,15.16699C220.62207,476.81152,212.93311,471.56641,206.5332,472.56641z
      M247.27783,513.74414c-0.62207,0.13379-1.24463,0.2334-1.88916,0.2334c-6.09961,0.01172-11.28857-6.31055-13.2666-15.14453
      c4.3999,8.52246,11.62256,13.80078,18.02246,13.7666C249.2334,513.13281,248.27783,513.52246,247.27783,513.74414z
      M258.61133,483.31152c-4.13379-8.51172-11.06689-13.98926-17.40039-14.5c1.34473-0.68945,2.7666-1.06738,4.24463-1.06738
      c0.61084,0,1.21094,0.07813,1.7998,0.2002c5.58887,1.15527,10.16699,7.63379,11.74463,16.22266
      C258.8667,483.87793,258.75537,483.58887,258.61133,483.31152z M285.15576,513.14453
      c-1.14453,0.4668-2.3335,0.74414-3.56689,0.74414c-6.7998,0.02246-12.52246-7.45508-14.2666-17.63281
      c0.1001,0.19922,0.17773,0.39941,0.27783,0.59961c5,9.83301,13.64453,15.5332,20.74414,14.34473
      C287.34424,512.0332,286.27783,512.68848,285.15576,513.14453z M294.87793,481.17773
      c-4.3667-8.58887-11.52246-14.0332-17.98926-14.47754c1.5-0.83398,3.1001-1.31152,4.76709-1.31152
      c1.11084,0,2.19971,0.21094,3.24414,0.58887c5.4668,1.98926,9.78906,8.91113,11.1333,17.75586
      C295.67773,482.87793,295.2998,482.02246,294.87793,481.17773z M375.4668,513.66699
      c-8.84473,0.02148-16.15527-10.10059-17.41113-23.25586c0.55566,1.58887,1.2334,3.17773,2.0332,4.74414
      c5.47754,10.77832,14.88867,17.07813,22.71094,15.98926C380.56641,512.74414,378.07813,513.65527,375.4668,513.66699z
      M390.38867,477.74414c-5.43359-10.66602-14.71094-16.95508-22.48828-16.02148c2.31055-1.7334,4.89941-2.7334,7.63281-2.74512
      c4.08887-0.01074,7.84473,2.16699,10.83301,5.78906c0.01172,0.01074,0.02246,0.02246,0.03418,0.04492
      c3.84375,4.67773,6.38867,11.78809,6.69922,19.83301C392.43359,482.33301,391.54395,480.01074,390.38867,477.74414z
      M580.18848,291.7666l10.61133,3.78906l-110.44434-2.37793l-19.91113-4.43311L580.18848,291.7666z M478.15527,512.78906
      c-1.2998,0.41113-2.63281,0.64453-4.01074,0.64453c-3.83301,0.01074-7.40039-1.7002-10.43359-4.62207
      c-1.07813-1.03418-2.07813-2.22266-3-3.54492c-0.57715-0.83301-1.14453-1.71094-1.65527-2.65527
      c-1.16699-2.13379-2.13379-4.52246-2.87793-7.08887c-1.09961-3.78906-1.7334-7.97754-1.72266-12.40039
      c0-0.17773,0.01172-0.35547,0.02246-0.5332c0.68945,3.35547,1.86719,6.77734,3.56738,10.11133
      c6.2998,12.38867,17.5,19.18848,26.13281,16.44434C482.33301,510.83301,480.31152,512.08887,478.15527,512.78906z
      M493.83301,485.2334c-0.65527-3.62207-1.87793-7.35547-3.72168-10.97754c-6.54492-12.85547-18.36719-19.72266-27.12207-16.10059
      c3.18848-3.41113,7.05469-5.43262,11.22168-5.44434c0.66699,0,1.32227,0.05566,1.9668,0.14453
      c9.95508,1.5,17.74414,14.43359,17.7334,30.15527C493.91113,483.7666,493.86621,484.5,493.83301,485.2334z M527.72266,338.4668
      l-3.12305,0.01074l-4.75488,0.01123c-1.52246,0-2.81152,1.0332-3.31152,2.47803c0.86719,0,1.74414,0,2.56641-0.01123
      c2.34473,0,2.78906,6.25537,2.78906,6.25537s-0.01074,13.57813-0.02246,18.54443c-0.01074,4.97803-5.28809,5.03369-5.28809,5.03369
      h-0.31152l-0.02246,15.8999c0,2.06641,1.57813,3.74414,3.52246,3.7334l7.82227-0.02246h0.06641v1.1333v2.45557l-7.88867,0.02246
      c-3.81152,0.01074-6.91113-3.27783-6.91113-7.32227l0.02246-15.8999h-0.2998c0,0-2.48926-0.17773-2.48926-3.17773
      s0.02246-23.45605,0.02246-23.45605s0.54395-3.18848,2.2002-3.19971c0.21094,0,0.47754,0,0.72168,0
      c0.57813-3.43359,3.40039-6.06689,6.7998-6.07764l4.54492-0.01123l3.34473-0.01123V338.4668z M530.38867,322.6665
      c0-0.84424-1.52246-12.1665-10.58887-16.24414c-2.97754-1.3335-7.4668-3.45557-11.86621-5.56689l109.87793,2.34473
      l28.88867,12.98877c3.68848,1.65527,4.65527,5.56641,4.84473,6.6001l-121.14453,0.28857L530.38867,322.6665z M655.86621,491.44434
      c0,0.7998-0.11035,1.56641-0.25488,2.2998l-29.05566,0.06738l-3.36719-6.08887l-43.04395,0.09961l-3.37793,6.09961
      l-43.35547,0.10059L533.42188,483c0.25586-0.5,0.55566-0.9668,0.86719-1.40039l120.35547-0.28809l1.22168,5.58887V491.44434z
      M573.31152,461.25586l48.57715-0.12305v2.80078c0,0-5.94434,2.81055-9.25586,2.82227l-37.35547,0.08887l3.30078,9.34375
      l-38.33398,0.08887c0,0,0,0-0.01074,0c-3.97754,0.01172-4.17773-3.35547-4.17773-3.35547l0.01074-9.25488l30.77832-0.07813
      l-3.37793-10.81152c0,0-1.13379-3.35547-4.58887-3.35547c-2.5,0.01172-15.7002,0.0332-22.7998,0.05566v-0.11035l-2.2002-3.81152
      l0.09961-1.43359l33.2666-0.07715l1.84473,5.22168l56.34473-0.13281v2.7998c0,0-5.95605,2.81055-9.25586,2.82227l-45.11133,0.11133
      L573.31152,461.25586z M655.92188,449.21094c-5.31055,0.01172-12.09961,0.0332-14.11035,0.0332
      c-3.45605,0.01172-4.58887,3.36719-4.58887,3.36719l-2.56738,10.83301l21.24512-0.05566l-0.01172,11.7002l-0.54395,0.92188
      l-29.88965,0.06738l8.87793-32.17773h0.11133l17.85547-0.04492l0,0l0.88867,1.17773l0.14453,0.2002l2.60059,3.45508
      L655.92188,449.21094z M655.95508,420.40039L655.95508,420.40039l-0.01074,7.68848L649.5,428.11133l0.01074-7.68945
      l-106.13281,0.25586l0.06641,7.93359l-8.68848,0.21094l-3.34473,0.01074c3.17773,0.14453,3.25586-5.94434,3.25586-5.94434
      l0.01074-2.17773l0.05566-10.16699l0.05566-11.43262l-3.81152-66.02246l7.38965-0.02246l3.34375,64.24512
      c0,0,23.38867-0.02246,53.0332-0.08887c29.65625-0.06738,56.66699-0.16699,56.66699-0.16699l-3.74414-64.24463l4.47754-0.01123
      l3.83301,66.22266L655.95508,420.40039z"/>
    <path class="best-rates" d="M342.5,442.51074c-5.82227,0.35547-11.84473-0.12207-18.04492-1.43262l0.08984-1.7334l-154.52246,17.27734
      l-44.72266,5l44.72266-2.7334l184.16602-11.2334l0.36719-6.87793C350.0332,441.7002,345.97754,442.2998,342.5,442.51074z"/>
  </g>
  </svg>
</template>

<script>
export default {
  name: "bestShippingRates"
}
</script>

<style>
  .best-rates{fill:#333232;}
</style>
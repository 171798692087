
.form-group .input-group .form-control {
  border-radius: 3px 0 0 3px !important;
}

.login-signup .modal-body .btn {
  padding: 16px;

  i {
    margin: 0 !important;
  }
}

.login-signup .modal-body p {
  margin: 0;
}

.frmLabel {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.frmValidation {
  font-size: 13px;
}

.frmValidation--passed {
  color: #717b85;
}

.frmIcon {
  color: #eb0029;
}

.frmValidation--passed .frmIcon {
  color: #0fa140;
}


ul {
  list-style: none;
}

.error-text {
  color: #da4f49;
  background-color: #da4f492e;
  border-radius: 6px;
  border: 1px solid #da4f49;
  padding: 5px;
  font-weight: 700;
  margin-bottom: 20px;
}

<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 166.91553 800 466.16943">
  <g>
    <path class="sa-i-1" d="M242.94141,333.87256c-0.89941-0.25684-1.88916-0.42822-2.87939-0.59912
      c0.18018,1.45508,0.54004,2.99609,1.3501,4.27979C241.95215,336.35498,242.4917,335.0708,242.94141,333.87256z"/>
    <path class="sa-i-1" d="M246.09131,334.72852c-1.25977,0.94189-1.43945,2.65332-1.97949,4.1084
      c0.98926-0.08545,1.97949-0.25635,2.96924-0.34229C246.90137,337.21094,246.63135,335.92676,246.09131,334.72852z"/>
    <path class="sa-i-1" d="M345.43311,291.50342c-0.35938-1.11279-1.25928-1.88281-2.33887-2.22559
      C343.72412,290.21973,344.53369,290.98975,345.43311,291.50342z"/>
    <path class="sa-i-1" d="M307.82031,370.84912c3.14941-3.08105,5.66895-6.93262,6.74805-11.29834
      c0.54053-2.13965,0.54053-4.36475,0.63086-6.59082c-2.69971-0.08545-5.5791,0-8.0083-1.36914
      c-1.98047-1.11279-3.06006-3.25244-4.23047-5.13574c-1.88916,1.79736-3.95898,3.42383-6.02832,4.79346h0.00049
      c0.17969,2.73926,0.26904,5.47803-0.63037,8.0459c-1.43945,4.36523-4.76904,7.875-8.18896,10.78467
      c-6.56836,5.39258-14.30713,8.90186-21.77588,12.83887c-3.32959,1.96924-6.92871,3.50977-10.52783,5.05029
      c4.58936,0.34229,9.17822-0.42773,13.67725-0.77002c6.9292-0.42822,13.5874-2.39697,19.97656-4.96533
      C296.12207,379.49463,302.60059,375.98535,307.82031,370.84912z"/>
    <path class="sa-i-1" d="M512.80371,307.08203c-1.08105,0-2.25,0-3.33008,0c1.16992,1.28369,2.24902,2.56738,3.41992,3.85156
      C512.89355,309.64941,512.80371,308.36572,512.80371,307.08203z"/>
    <path class="sa-i-1" d="M519.01465,505.7998h-29.23438v2.78125c0.89258,0.61914,1.96582,1.62305,2.50195,2.39551h9.3877
      c0.62598-1.08008,2.05664-1.77637,3.75488-1.77637c1.69922,0,3.12988,0.69629,3.75488,1.77637h10.19238
      c-0.53613-1.39063-0.44727-3.16797,0.08984-5.17676H519.01465z"/>
    <path class="sa-i-1" d="M511.63379,352.61816c-0.54102,1.19824-0.90039,2.48193-1.25977,3.76563
      c0.98926-0.08545,1.97949-0.25635,2.96875-0.34229c-0.26855-1.19824-0.44922-2.39697-0.98926-3.50928
      C512.17285,352.53223,511.81348,352.61816,511.63379,352.61816z"/>
    <path class="sa-i-1" d="M536.73926,305.02734c-4.86035,3.50928-9.26855,7.61768-14.03809,11.38379v0.00049
      c-0.71973,0.77002-2.06934,1.45459-1.97949,2.73877c0.54004,1.11328,1.62012,1.96875,2.51953,2.91016
      c5.21973,4.70752,10.16797,9.67236,15.65723,13.95166c-0.17969-5.0498-0.71973-10.01416-0.99023-15.06494
      C537.45801,315.64111,537.27832,310.33398,536.73926,305.02734z"/>
    <path class="sa-i-1" d="M514.42285,323.77246c-6.0293,4.70752-11.69824,9.67285-17.54688,14.55127
      c-0.89941,0.77002-1.97949,1.62598-1.88867,2.91016c-0.09082,6.50488,0.62988,13.18115-0.99121,19.51514
      c-0.35938,1.62646-1.5293,2.73926-2.69824,3.76611c-1.43945-6.24805-1.53027-12.66748-2.61035-19.00146
      c-3.86914,3.08154-7.55859,6.41943-11.1582,9.75732c2.06934,2.31152,4.13867,4.79346,4.85938,7.96045
      c1.16992,4.36523-0.17969,9.41553-3.7793,12.32568c-4.40918,3.68066-10.34766,4.27979-15.83691,4.96436
      c0.80859,3.59473,1.5293,7.36084,3.68848,10.44238c1.35059,1.79785,3.50977,3.50928,5.84961,2.82422
      c2.51953-0.68408,4.04883-3.08105,4.94922-5.30615c1.7998-4.53613,3.77832-8.9873,6.47852-13.09619
      c2.16016-3.42334,5.66992-6.24805,9.80859-6.3335c1.34961,0.08545,3.14941-0.34277,3.95801,1.1123
      c1.80078,2.99561,2.43066,6.59082,4.68066,9.41553c3.77832,4.96436,11.78711,5.05029,16.28613,0.94141
      c1.53027-1.36963,1.53027-3.59473,1.35059-5.47803C517.75195,358.01025,516.22168,340.80615,514.42285,323.77246z
      M514.33301,357.32568c-1.62012,1.28369-4.22949,0.51367-5.66992,2.2251c-4.31836,4.96436-10.97754,8.13184-17.63574,7.44727
      c1.25977-1.79834,3.3291-2.56787,5.39941-3.16748c3.68945-1.19824,6.56836-3.93701,9.26855-6.59082
      c-0.7207-2.39648-1.80078-4.96484-0.99121-7.53174c0.4502-1.36963,2.07031-1.36963,3.24023-1.19873
      c2.51855,0.42773,4.31934,2.56787,5.93848,4.36523C515.05273,353.98779,515.41211,355.95605,514.33301,357.32568z"/>
    <path class="sa-i-1" d="M474.83008,369.47949c1.0791-0.68457,2.33887-1.79688,2.07031-3.25195
      c-0.27051-2.91064-2.7002-4.96484-4.86035-6.67676c-2.42969,2.39697-4.8584,5.05029-6.92773,7.875
      c-0.99023,1.28369-1.53125,2.91016-1.98047,4.53662C467.18164,372.39063,471.32129,371.53418,474.83008,369.47949z"/>
    <path class="sa-i-1" d="M413.91016,346.62598c2.43066,0.51416,4.76953-0.94141,6.57031-2.48193
      c-2.25-1.71191-4.85938-3.08105-7.74023-3.08105c-1.34863,0-2.78809,1.11279-2.78809,2.48193
      C410.94141,344.91455,412.29102,346.02734,413.91016,346.62598z"/>
    <path class="sa-i-1" d="M507.49414,355.271c0.63086-1.19824,1.25977-2.39648,1.61914-3.68018
      c-0.89941-0.34229-1.88867-0.59912-2.69922-0.85596C506.59473,352.2749,506.9541,353.81641,507.49414,355.271z"/>
    <path class="sa-i-1" d="M281.63477,344.05859c-1.52979-0.08594-3.68945,0-4.31934,1.62598
      c-0.44971,0.94141,0.62988,1.54102,1.3501,1.88281c2.69971,0.94189,5.66895,0.34277,8.4585-0.17041
      C285.68408,345.85596,283.97461,344.14404,281.63477,344.05859z"/>
    <path class="sa-i-1" d="M402.79297,169.91504c-127.1875,0-230.29297,103.10547-230.29297,230.29248S275.60547,630.5,402.79297,630.5
      c127.18652,0,230.29199-103.10547,230.29199-230.29248S529.97949,169.91504,402.79297,169.91504z M460.70215,287.65137
      c1.98047,0.59961,3.14941,2.39697,4.31934,4.02344c0.54004-1.79736,0.80957-3.9375,2.42969-4.96436
      c1.88965-0.85596,4.58887-0.17139,5.48926,1.79736c0.36035,0.94189-0.26953,1.79785-0.98926,2.31152
      c-1.35059-0.25684-2.52051-0.94189-3.68945-1.45508c-1.53027,2.90967-2.33984,6.1626-3.24023,9.41504
      c-0.62988-1.54102-1.16992-3.16748-2.15918-4.53711c-1.25977-1.71143-3.23926-2.56738-4.67871-4.1084
      C457.7334,288.76416,459.35254,287.22363,460.70215,287.65137z M455.48438,303.40088
      c0.98926-0.77002,2.06934-1.19824,3.23828-1.71143c5.12891-1.8833,10.25781-4.02295,15.56738-5.73535
      c1.70996-0.51318,3.59961-0.68408,5.39941-0.68408c-0.90039,1.02686-1.88965,1.96826-3.15039,2.48193
      c-6.20898,2.48242-12.59668,4.27979-18.53711,7.44678c0.54102,1.1123,1.08105,2.14014,1.62109,3.25244
      c-1.43945-0.25684-3.14941-0.25684-4.22949-1.45508C454.40332,306.0542,454.31348,304.25732,455.48438,303.40088z
      M463.85156,319.9209c0.4502,1.28418,1.97949,1.02734,3.05957,1.28418c0.36035-2.22607,0.18066-4.53662,1.08105-6.59082
      c0.62891-0.51416,1.43848-0.85645,2.24902-1.19824c0.08887,1.96826-0.27051,4.1084,0.71875,5.90576
      c0.62988,1.11279,1.98047,1.19824,3.06055,1.54053c-0.08984-2.14014-0.71973-4.27979-0.89941-6.41943
      c0.62988-0.77002,1.43848-1.28418,2.24902-1.88281c0,2.13965,0.71973,4.1084,1.61914,5.99121
      c0.90039,1.8833,0.90039,4.87891-1.34961,5.73486c-2.06836,0.94189-4.22949-0.1709-5.93848-1.36914
      c-1.34961,1.02637-2.96973,2.31104-4.85938,1.79688c-2.78906-0.59863-3.95996-3.85156-3.50977-6.41943
      c0.18066-1.88281,1.7998-3.08105,3.05957-4.27979C464.12207,315.98291,463.3125,317.95264,463.85156,319.9209z
      M473.39063,332.93115c3.59863-1.79736,7.01758-3.7666,10.70801-5.0498c0.81055-0.17139,1.88867-0.68506,2.42969,0.1709
      c-0.62988,2.0542-2.79004,2.91016-4.49902,3.7666c-6.47949,2.99512-12.77832,6.3335-19.16699,9.58594
      c-0.26953-0.59863-0.62988-1.1123-0.89941-1.71143C465.5625,337.04004,469.52051,335.0708,473.39063,332.93115z
      M419.66992,290.98975c1.71094-2.90967,4.41016-5.13525,7.46875-6.59033c-0.81055,2.13965-2.07031,3.93701-3.24023,5.82031
      C423,291.58936,423,293.30078,422.90918,294.8418c2.06934-0.08594,4.13965-0.42773,6.11816-0.94141
      c-0.53809,1.62646-1.0791,3.33789-2.6084,4.36523c-1.70996,1.19775-4.22949,1.96875-6.0293,0.51367
      C418.5,296.81055,418.23047,293.38672,419.66992,290.98975z M421.83008,328.56592c0.53906-1.11279,0.99023-2.31104,1.79883-3.33789
      c0.99023-1.11279,2.61035-1.45557,4.0498-1.11279c1.79883,1.71143,1.43945,4.79297,0,6.59082
      c-2.61035,3.42285-6.92871,5.04932-11.1582,5.04932c2.61035-1.54004,5.5791-2.396,7.91895-4.36523
      c1.0791-0.77002,0.89941-2.22559,1.0791-3.42334C424.25977,328.13818,423,328.39453,421.83008,328.56592z M436.76758,347.22559
      c0.35938-1.71191,2.33887-1.96875,3.77832-2.48242c0.36035,2.48242,0.62988,5.0498,2.16016,7.10449
      c-0.80957,0.08545-1.62012,0.1709-2.33984,0.25684c-1.08008,1.62646-2.24902,3.42334-4.04883,4.2793
      c-2.96973,1.45557-6.2998,1.45557-9.53906,1.28418c3.41992-0.94141,7.64941-1.19824,9.89844-4.27979
      C438.20703,351.59082,436.58594,349.27979,436.76758,347.22559z M443.51563,387.62549
      c0.18066,2.22607-3.05957,2.56787-4.22949,1.19824c-1.34961-1.54004-1.16992-3.93652-0.35938-5.73438
      c0.7207-1.79785,2.42871-2.65332,3.95898-3.59473c-0.62988,1.45508-1.70996,2.91016-1.88965,4.53613
      C441.71582,385.31543,442.88574,386.25684,443.51563,387.62549z M437.2168,370.0791
      c-7.8291,1.71143-15.65625,3.25244-23.48633,4.79346c-5.84863,1.19775-11.78711,2.56738-17.09619,5.22119
      c-1.97949,0.94141-3.59912,2.56738-4.94922,4.36523c3.86865,1.45508,7.91846,2.31104,11.96729,3.08154
      c1.35059,0.3418,3.24023,0.59912,3.59961,2.2251c0.54102,2.13965,0.62988,5.13525-1.43945,6.50537
      c-2.60938,0.59912-5.30957,0-7.91846-0.42822c-7.19922-1.11328-14.12793-4.87891-18.2666-11.0415
      c-3.1499-4.45117-4.04932-10.01465-4.85889-15.32178c-2.06982,2.91113-4.22949,5.8208-6.83984,8.21729
      c-7.01758,6.59082-15.47607,11.38428-24.29492,15.23584c-3.05957,1.36963-6.9292,1.79688-9.98828-0.08594
      c-2.15918-1.28369-2.96973-3.93652-3.86963-6.1626c-8.18799,4.19434-16.55664,8.2168-25.64502,9.9292
      c-5.5791,1.02783-11.24756,1.79834-16.91699,1.71094c2.96973-1.4541,6.02881-2.56689,8.99854-3.76563
      c9.89746-4.1084,19.6167-8.72998,29.1543-13.69482c1.17041-0.68457,2.60986-1.28369,3.41992-2.39648
      c0.8999-2.48193,0.98926-5.13574,2.33887-7.53223c1.3501-2.91016,3.7793-5.13525,6.38965-6.84814
      c-1.70996,3.7666-3.51025,7.61865-4.0498,11.72656c5.75879-3.50928,11.24805-7.44629,16.10791-12.23975
      c1.25928-1.36963,3.05908-3.08154,2.33887-5.13525c-0.8999-2.91064-2.4292-5.47852-3.50928-8.30273
      c-1.61963-4.1084-3.59961-8.38818-3.14941-12.9248c0.09033-1.45557,1.16992-2.56787,1.88916-3.68066
      c3.95996,2.56836,6.47949,6.76221,8.729,10.78467c-0.81006,0.17139-1.70996,0.34277-2.51953,0.51367
      c1.52979,4.19482,4.04883,7.96045,4.76904,12.41113c0.54004,3.42383-0.27051,7.19043-2.69971,9.75781
      c-6.11865,6.59082-13.67725,11.55518-20.78564,16.94824c1.25977,0.68457,2.60938,1.62598,4.13916,1.54004
      c2.96924-0.08545,5.84863-1.11279,8.54834-2.2251c9.89746-4.36572,17.99658-11.64111,26.36426-18.40283
      c-2.06836-8.0459-5.75879-15.57813-9.62695-22.93945c-1.3501-2.56787-2.33984-5.81982-1.89063-8.9873
      c0.18018-1.28418,1.08008-2.2251,1.80029-3.16699c4.94922,5.39258,9.17822,11.64063,11.42822,18.74512
      c2.15918,6.76172,2.69922,13.86621,4.04883,20.79932c0.71924,3.50977,1.3501,7.18994,3.68945,10.01465
      c1.52979,1.96826,3.7793,3.16699,5.93848,4.36572c0.44971-2.22607,0.99023-4.62256,3.06006-5.90625
      c6.29932-4.19434,13.5874-6.76172,20.78662-9.07324c-5.84961-0.17139-11.78906,0-17.6377-0.08545
      c-4.76904-0.08594-9.53857,0.34229-14.21729-0.51367c0.18066-1.79736,0.18066-3.68018,1.25977-5.30664
      c2.15967-3.59473,5.93896-5.64941,9.53857-7.53271c5.48828-2.82422,11.06738-5.30615,17.00684-6.93262
      c-1.53027-1.62646-3.86914-3.42383-3.23926-6.07764c1.07813-3.08105,3.7793-6.16211,7.19824-6.07715
      c3.78027,0,7.46777,1.54102,10.70801,3.42383c1.97949,1.28369,4.40918,2.65332,5.12891,5.05029
      c-0.08984,1.71143-0.98926,3.25244-1.97852,4.62207c-2.07031,2.56787-5.75977,2.39648-8.82031,2.73877
      c-0.17969,2.0542-0.44922,4.53711-2.51758,5.47803c-0.62988-1.62598-1.26074-3.16699-1.98047-4.79346
      c-7.46875,1.36963-14.12695,5.22119-19.97607,9.84375c16.55713,0.08545,33.02393-0.34277,49.58057-0.25684
      c1.08008,0.08545,2.16016,0.17139,3.23926,0.34229C445.49512,366.99756,441.08594,369.1377,437.2168,370.0791z
      M418.67969,384.03076c0.99023-1.36963,1.89063-3.08105,3.60059-3.42334c2.51855-0.85645,5.21875,0,7.46777,1.28369
      c-1.61914,3.33789-5.39941,4.19385-8.1875,6.24854c0.08887,0.34229,0.17969,1.02734,0.17969,1.36914
      c1.97852,0.08594,4.04883,0.34277,5.75879-0.68506c2.24902-1.19824,4.58887-2.31055,7.1084-2.82422
      c-1.97949,1.71191-3.86914,3.42383-5.84863,5.05029c-2.33984,1.88281-5.5791,1.88281-8.36914,1.11279
      c-1.34961-0.42822-3.05957-1.62598-2.60938-3.33887c0.71973-2.13965,2.87988-3.25195,4.58887-4.53613
      C421.10938,384.20215,419.93945,384.11621,418.67969,384.03076z M371.52832,392.93262
      c0.09082,1.36963-0.98926,2.48193-1.61963,3.59521c-0.44971-2.13965-0.80957-4.45117-1.97998-6.33447
      c-0.71973-1.36963-1.79932-2.73877-1.43896-4.36475c0.35986-1.79785,2.24951-2.65381,3.59912-3.50928
      c-0.08984-0.51367-0.18018-1.19873-0.18018-1.8833c1.5293-0.25684,3.32959-0.94141,4.6792,0.17139
      c1.3501,0.85547,1.7998,3.08154,0.54004,4.27979c-1.88965,1.02686-4.31934,0.42822-5.93848,1.88281
      C369.45898,388.99512,371.16895,390.79297,371.52832,392.93262z M327.79639,352.87451
      c2.06982,0.25684,3.3291,2.13965,4.76953,3.50928c0.53955-1.36963,0.89941-2.91016,1.88916-4.02246
      c1.70996-1.62646,4.76904-0.77051,5.84912,1.19824c0.54053,1.11279-0.62988,1.96826-1.08008,2.82422
      c-0.89941-0.51318-1.79932-1.19775-2.78906-1.45459c-0.90039-0.25684-1.16943,0.77002-1.43945,1.36914
      c-1.08008,2.56738-1.62012,5.30664-2.42969,7.96045c-0.62988-1.62646-1.07959-3.25293-2.06982-4.70801
      c-1.07959-1.54053-2.87988-2.39648-4.22949-3.68018C325.00732,354.84326,326.35742,352.61816,327.79639,352.87451z
      M381.87695,338.75146c0.17969,1.36963,1.70947,1.79785,2.78955,2.39697c0.71973-2.05469,0.89941-4.28027,1.25977-6.50537
      c0.18018-1.19775,1.61963-1.36963,2.42969-1.96875c0.08936,1.79785,0,3.68066,0.53955,5.39258
      c0.54004,1.19824,1.79932,1.79736,2.78955,2.48242c1.08008-2.31152-0.44971-4.62256-0.54004-6.93359
      c0.54004-0.68457,1.44043-1.11279,2.15967-1.54004c0,2.99512,2.15967,5.30615,2.42969,8.2168
      c0.26953,2.99561-3.59912,4.53613-5.93896,3.1665c-0.72021-0.51318-1.3501-1.11279-1.97998-1.62646
      c-1.25928,1.11328-2.60938,2.73926-4.49902,2.31152c-2.87939-0.51367-4.40918-3.85156-3.86914-6.59082
      c0.26953-1.88281,1.80029-3.08105,3.14893-4.19385C382.32666,335.15674,381.69678,336.86816,381.87695,338.75146z
      M380.25732,300.40527c0.72021-0.59912,1.52979-1.02686,2.24951-1.45508c-0.09033,2.31104,0.72021,4.36523,1.70947,6.41943
      c0.98975,2.0542,0.17969,4.96436-2.24951,5.47852c-2.06934,0.68457-3.95947-0.59961-5.39941-1.79785
      c-1.16895,1.02686-2.42969,2.39648-4.13867,2.14014c-3.1499-0.17188-4.67969-3.85156-4.0498-6.59082
      c0.27002-1.79736,1.70996-2.91016,2.96973-4.02344c-0.17969,1.8833-0.8999,3.7666-0.4502,5.6499
      c0.35986,1.28369,1.88965,1.28369,2.97021,1.71143c0.35938-2.05371,0.44971-4.19385,0.98926-6.33398
      c0.17969-1.1123,1.52979-1.19824,2.24951-1.71143c0.17969,1.79736,0.09033,3.59473,0.62988,5.30664
      c0.4502,1.28369,1.97949,1.71143,3.05908,2.31104C381.33691,305.02734,380.25732,302.71631,380.25732,300.40527z
      M370.08887,286.9668c0.98975-1.1123,2.78955-0.94141,3.95947-0.1709c2.60938,1.71191,4.13916,4.87891,3.95898,7.95996
      c-0.09033,1.45557-0.72021,2.73926-1.16992,4.02344c-0.35986-1.96875-0.27002-4.02344-1.16992-5.8208
      c-1.34912-1.88281-3.86865-2.22559-5.48877-3.76563C369.54932,288.6792,369.27881,287.48047,370.08887,286.9668z
      M354.25195,318.0376c1.88916-5.64941,3.86914-11.38379,6.65918-16.69092c1.16895-2.14014,2.4292-4.36523,4.40869-5.90576
      c0.98926-0.85596,2.4292-0.85596,3.68896-1.1123c0.27002,4.79297-2.42969,8.98682-3.32959,13.60889
      c-0.89941,4.02295-1.16895,8.81641,1.71045,12.1543c3.23877,3.50977,8.63867,4.36523,13.1377,3.16699
      c6.20898-1.96826,10.70801-8.21729,10.97803-14.72217c0.17969-3.85156,0.35986-8.04541-1.70996-11.46924
      c-0.8999-1.79736-2.60938-3.68066-1.88965-5.90625c0.4502-2.2251,2.06934-3.9375,3.95947-4.96436
      c1.3501,2.13965,2.51904,4.45068,2.78955,7.01855c0.71924,5.90625,0.80957,11.9834,2.87988,17.63281
      c1.25928,3.25195,3.14893,6.59082,6.47803,8.13086c3.05957,1.45557,6.83887,1.79785,9.80859,0.25684
      c1.88965-0.94141,2.33984-3.25293,1.88965-5.13574c-1.25977-6.1626-5.48828-11.04102-7.46875-16.94678
      c-1.08008-2.99609-1.89063-6.07764-1.25977-9.15918c0.17969-1.19824,1.34961-2.48242,2.60938-1.79736
      c3.50977,1.71143,6.0293,4.96436,7.64844,8.47363c-1.25977,0-2.51953-0.08545-3.68945-0.17139
      c0.63086,2.39746,1.25977,4.70752,2.25,7.01904c1.34961,3.25293,3.23926,6.33398,3.7793,9.92871
      c0.62988,3.9375-0.08984,8.38867-2.87891,11.38428c-5.21875,5.82031-15.6582,4.79346-20.15723-1.45508
      c-1.70947-2.22559-2.6084-4.79346-3.50879-7.44629c-1.44043,5.64893-4.49951,11.12646-9.62842,14.03711
      c-5.21924,3.16699-12.14844,2.73926-17.09766-0.59912c-3.05908-1.96875-5.12891-5.39258-5.12891-8.9873
      c-0.35986-5.30713,1.70996-10.27148,2.43018-15.40723c-1.26025,1.28418-2.43018,2.73926-2.96973,4.53662
      c-2.15918,5.73438-3.05908,11.89746-6.11914,17.29004c-1.43896,2.56787-3.86914,4.87891-6.92871,5.13574
      c-2.96924,0.1709-6.20898-0.94141-7.91846-3.50977c-1.5293-2.396-1.89014-5.30664-1.89014-8.13135
      c0.09082-4.27979,1.08057-8.64502,3.06055-12.41064c0,5.3916-1.62061,11.29785,1.0791,16.34814
      c1.3501,2.82422,4.94971,4.45068,7.73877,2.91016C352.18213,323.60156,353.35205,320.69092,354.25195,318.0376z
      M340.03418,299.80615c1.62012-1.54053,2.69922-3.50928,3.7793-5.39209c-1.3501-1.36963-3.68896-2.65381-3.32959-4.87939
      c0.54053-2.82422,4.76953-2.73877,6.11963-0.59863c0.89941,1.54004,1.34961,3.1665,2.24951,4.70752
      c0.80957,1.54053,1.79932,3.16699,1.25977,4.96436c-0.81006,2.82471-2.87939,5.0498-3.1499,8.0459
      c0.99023-0.42773,1.97949-0.94141,2.96973-1.45508c-0.27002,1.54053,0,3.59473-1.62012,4.45068
      c-1.97949,0.94189-4.40869-0.94141-4.229-3.08105c0.27051-3.85205,4.04932-6.84766,2.87939-10.95654
      c-3.7793,5.05029-8.99756,9.58691-15.38721,10.52832C334.36572,303.91455,337.51514,302.37402,340.03418,299.80615z
      M324.55713,291.6748c3.23926,2.99561,4.76904,7.78857,3.68896,12.06885c-0.71973-1.62598-1.25928-3.33838-2.06982-4.87891
      c-1.34912-2.91064-4.31885-4.62256-5.66846-7.53223C321.85742,290.98975,323.47754,290.47607,324.55713,291.6748z
      M284.69434,294.32861c0.44971,1.2832,0.8999,2.56787,1.34961,3.93701c0.54004-0.25684,1.16992-0.42871,1.70996-0.68506
      c0-1.19824,0-2.39648,0-3.50928c0.44971,0,0.8999,0,1.25977,0c0.17969,1.02734,0.35938,2.14014,0.54004,3.16699
      c0.71924-0.08496,1.34961-0.1709,2.06885-0.25684c-0.08936-1.28418-0.08936-2.56738-0.08936-3.85156
      c1.3501,1.45508,1.97949,3.25244,1.70947,5.22119c-2.15967,0.34229-4.31982,0.94189-6.479,1.45508
      c-1.16943,0.34229-2.4292-0.51318-2.69922-1.71191C283.97461,296.81055,284.42432,295.61182,284.69434,294.32861z
      M294.32227,316.41162c-1.07959-2.82471-1.97949-5.90625-4.31836-8.0459c-1.44043-1.28369-2.87988-2.73877-3.1499-4.70801
      c1.34961,0,2.96924-0.25684,4.04932,0.85645C293.9624,307.50977,295.49268,312.21729,294.32227,316.41162z M225.39502,293.90039
      c1.52979-3.25293,4.49902-5.64941,7.73877-7.18994c-0.81006,2.13965-2.15967,3.93701-3.23975,5.90576
      c-0.8999,1.36963-0.81006,3.08105-0.8999,4.62207c2.06982-0.1709,4.13965-0.59912,6.20947-1.28418
      c-0.63037,1.62646-1.26025,3.42432-2.78955,4.53711c-1.62012,1.19824-4.0498,1.88281-5.75928,0.68457
      C224.67529,299.46338,224.31494,296.29688,225.39502,293.90039z M225.84473,308.62207
      c0.8999-1.88281,2.78955-2.90967,4.40967-3.85107c-1.08008,1.79736-2.33984,3.59473-2.60986,5.64893
      c-0.35938,2.91016,0.18018,5.90625,0.71973,8.73047c0.36035,1.71191,0.72021,3.59521,2.06982,4.79297
      c2.06982,1.8833,5.5791,1.7124,7.55908-0.1709c1.07959-1.19824,1.61914-2.73877,2.15918-4.19385
      c1.7998-5.47852,3.23926-11.12744,5.48877-16.43408c1.97998-4.45117,5.48926-8.81592,10.52832-9.75781
      c-0.54004,6.07715-3.32959,11.72607-3.50928,17.88867c-0.08984,2.73926,0.17969,5.73535,1.97949,7.87549
      c1.3501,1.79736,3.59961,2.396,5.66943,2.99561c-0.54053-8.30322-1.34961-16.60547-1.89014-24.99316
      c-0.17969-3.08203-0.71973-6.24902-0.17969-9.33008c0.44971-2.14014,2.78955-2.91016,4.58887-3.59473
      c3.41943,5.04932,5.93896,11.0415,5.84863,17.2041c-1.16895-0.25684-2.24902-0.51367-3.41943-0.77051
      c0.72021,7.10449,1.43994,14.29443,2.25049,21.39844c2.96924-0.94141,5.66846-2.73877,7.37842-5.39209
      c3.50928-5.05029,4.04932-11.64111,2.33936-17.46143c-0.72021-2.48193-1.88965-4.79297-2.4292-7.36084
      c-0.54053-2.39648,1.16943-4.87891,3.3291-5.64941c0.98975-0.42822,1.43945,0.77002,1.88965,1.45508
      c1.3501,2.91064,1.80029,6.1626,2.4292,9.32959c0.81055,4.19434,0,8.55957,1.53027,12.58252
      c1.25928,3.42383,3.50928,6.59082,6.74854,8.38867c2.60986,1.45508,5.66895,1.79688,8.63867,1.2832
      c1.43945-0.25684,2.60986-1.45459,2.96924-2.90967c0.71973-2.73926-0.26953-5.56396-1.43945-8.0459
      c-2.69971-5.64893-6.02881-11.04199-7.28906-17.20459c-0.4502-2.22559,0.18018-5.30713,2.51953-6.67627
      c1.07959-0.68457,2.06982,0.51367,2.78955,1.28369c2.15967,2.65332,4.5,5.30664,5.75879,8.55908
      c-1.0791,0.17188-2.24951,0.34277-3.32861,0.51367c0.27002,5.13623,4.58838,8.64502,5.84912,13.52393
      c0.89893,2.99561,0.98877,6.24854,0.35938,9.24463c-0.8999,4.1084-4.31982,7.70361-8.54883,8.38818
      c-4.94922,0.85547-10.52832-0.34277-14.2168-4.02295c-2.43018-2.39697-3.32959-5.82031-4.40967-8.90186
      c-1.16943,4.19385-2.87988,8.21729-5.84912,11.46924c-1.70947,1.96875-3.95898,3.33838-6.2085,4.53711
      c0.62988,11.46924,1.7998,22.93896,0.80957,34.32275c-0.35986,3.25293-0.54004,7.104-3.50928,9.24414
      c-1.07959-4.53613-1.25977-9.24414-1.7998-13.95166c-0.98975-9.50098-1.70947-19.00195-2.69922-28.50293
      c-2.78955-0.68506-5.84863-1.28369-8.0083-3.33838c-3.59961-3.25244-4.76953-8.30225-4.5-12.9248
      c0.09033-3.59473,1.26025-7.104,2.07031-10.61328c-1.3501,1.36963-2.42969,2.99609-3.05957,4.79346
      c-1.89014,5.73438-3.14941,11.72607-5.75879,17.20459c-0.99023,2.13965-2.87939,3.93652-5.21924,4.45068
      c-2.87939,0.59912-6.29883,0.42773-8.54834-1.79736c-2.43018-2.39648-2.96924-5.9917-3.32959-9.24414
      C224.58545,315.29883,224.31494,311.70361,225.84473,308.62207z M253.55957,346.28418
      c-1.88916,1.45459-4.13916,2.0542-6.29883,2.99561c-5.03906,2.39648-10.07813,4.70801-15.02734,7.36084
      c-1.07959,0.68506-2.4292,0.51367-3.59912,0.34229c0.72021-1.45508,1.70996-2.82471,3.14941-3.68018
      c5.21875-3.16699,10.43799-6.07715,15.74756-8.73096c1.5293-0.68457,3.14941-1.54053,4.85889-1.71191
      C254.00977,342.77441,254.81982,345.25684,253.55957,346.28418z M230.25439,346.28418
      c3.68896-1.19873,6.6582-3.85205,9.35791-6.59082c-0.71973-2.22559-1.61914-4.45117-1.25977-6.76221
      c0.09033-1.02734,0.8999-2.14014,2.06982-2.05371c3.05908-0.34326,5.21875,2.13916,7.10889,4.10791
      c0.98926,1.02686,1.79932,2.39648,1.16943,3.85156c-0.35986,1.62646-2.24951,1.54102-3.50928,1.88281
      c-2.69971,0.17188-3.7793,3.08203-5.84912,4.36572c-4.13867,2.82471-9.26807,5.05029-14.39697,4.36523
      C226.20459,347.65332,228.27441,346.88281,230.25439,346.28418z M226.29443,367.25439
      c1.71045-2.91016,4.40967-5.13574,7.46924-6.59082c-0.81006,2.14014-2.06982,4.02344-3.23975,5.90625
      c-0.89941,1.36914-0.89941,2.99561-0.98975,4.53613c2.06982-0.08496,4.13965-0.42822,6.20898-0.85547
      c-0.54004,1.62646-1.16992,3.42334-2.69922,4.36523c-1.71045,1.11279-4.13965,1.96826-5.93945,0.51318
      C225.12549,372.98926,224.94482,369.65137,226.29443,367.25439z M260.66846,396.52783
      c-3.86914-0.08545-7.82813-0.94189-11.15771-2.99609c-1.43945-0.94141-2.69922-2.22559-4.13916-3.08154
      c-1.7998-0.3418-3.68896,0.17139-5.5791,0.17139c-4.49902,0.17139-8.99854,0.34229-13.40771-0.42822
      c5.39893-1.79688,10.8877-3.1665,16.19727-5.13525c-1.43994-9.67236,0.4502-19.68652,5.39844-28.0752
      c1.3501-2.2251,3.06006-4.19385,4.85986-5.90576c-1.53027,3.08105-3.41992,6.07715-4.22998,9.50146
      c-1.88916,7.18945-1.79883,14.89307,0.36035,21.91162c3.68945-1.19873,7.28906-2.82422,10.79785-4.36572
      c8.18848-3.59473,16.19727-7.44678,23.66602-12.41113c2.78955-1.96826,5.66943-4.02246,7.55811-7.01807
      c0.90039-1.45557,0.72021-3.16748,0.72021-4.79395c-3.86914,0.771-7.64795,2.56836-11.69775,2.48242
      c-2.51904-0.1709-5.21875-0.68457-7.01855-2.65283c-1.7998-1.96924-1.88965-4.79395-1.52979-7.27588
      c0.54004-3.25244,1.97998-6.50537,4.58887-8.64502c2.97021-2.48193,7.37891-2.39648,10.79883-0.85596
      c3.3291,1.54053,5.21826,4.79346,6.83789,7.87451c5.03955-1.88281,8.45898-6.33398,13.4082-8.47363
      c0.17969,2.56787-0.09033,5.56348,1.88965,7.61768c1.16943,1.45508,3.14893,1.36963,4.76855,1.54102
      c-0.26953-4.10889-0.89941-8.13184-1.43896-12.1543c-1.70996-13.43848-3.41992-26.9624-4.85986-40.48682
      c-0.08936-1.96826-0.35938-4.02246,0.63037-5.64893h-0.00049c0.89941-1.36914,2.60986-1.88281,3.95996-2.48193
      c2.78906,3.59473,4.49902,7.96045,5.57861,12.32568c0.4502,1.71191,0.27051,3.42383,0.08936,5.13525
      c-0.98926-0.34229-2.06836-0.68457-3.05908-1.02686c1.17041,8.30273,2.60938,16.52002,3.59961,24.82227
      c4.76953-2.31152,9.17822-5.13574,14.12793-7.18994c1.5293-0.68506,3.14844-1.02686,4.85889-1.19873
      c-0.62988,1.36963-1.34961,2.73926-2.79004,3.33887c-5.21875,2.65332-10.52734,4.96436-15.65674,7.87451
      c0.44971,4.1084,0.8999,8.30225,1.43945,12.41113c2.33984-2.99609,4.40918-6.76172,8.4585-7.70361
      c0.17969,2.2251-0.53955,4.87939,1.07959,6.67627c2.52002,1.96924,5.93994,0.77002,8.27881-1.02686
      c-0.17969-3.25293,0.72021-6.41943,1.34961-9.58691c0.99023,0.42822,2.25,0.59961,2.60938,1.79785
      c1.08057,2.99512,0.81006,6.33398,0,9.3291c-0.80957,3.42432-3.59863,5.99219-6.65869,7.53271
      c-1.88965,0.94141-4.22949,1.45557-6.29834,0.59961c-2.16016-0.85645-3.51025-2.91064-4.94922-4.70801
      c-1.26025,3.08154-3.3291,5.90576-3.41943,9.41553c-0.35986,7.36084-3.23926,14.37939-7.64893,20.2002
      c-7.64844,10.01465-19.34668,16.00586-31.22363,19.51563C274.97607,395.15771,267.86719,396.87012,260.66846,396.52783z
      M525.18359,515.07422c-1.16309,0-3.12988-0.07813-4.55957-1.69922h-11.44434c-0.62598,1.00391-2.05566,1.69922-3.75488,1.69922
      c-1.69824,0-3.12891-0.61914-3.75488-1.69922h-9.56641c-1.07227,1.4668-3.12988,3.16797-3.75488,3.16797
      c-0.9834,0-4.29004-3.01465-4.29004-4.4834c0-0.85059,1.51855-2.31738,2.77051-3.40039v-2.78125
      c-155.47266,0.30762-155.3833,0.30762-176.03516,0c-23.15527-0.23242-34.68848-0.1543-42.4668-7.88379h218.59082v-2.70605
      l-0.08887-0.0752c-1.25195-1.08301-2.77051-2.39648-2.77051-3.32324c0-1.39258,3.39648-4.48242,4.29004-4.48242
      c0.80566,0,4.29102,3.01367,4.29102,4.48242c0,0.84863-1.69824,2.31836-2.9502,3.39844v2.62793h27.71484v0.00195
      c8.67188-0.15625,13.05371,0.54102,13.14258,7.03125C530.54688,512.67773,529.56348,514.91992,525.18359,515.07422z
      M488.6875,383.77441c2.42969-0.42871,4.49902,1.54102,5.5791,3.50928c1.62012,2.73877,1.53027,6.1626,0.17969,8.9873
      c-0.4502-1.96875-0.4502-4.02295-1.44043-5.8208c-1.34863-1.79688-3.86816-2.13965-5.48828-3.76611
      C486.61719,385.65723,487.42676,383.9458,488.6875,383.77441z M512.44336,395.07227
      c5.03906-2.90967,10.4375-4.96387,15.83691-7.104c3.3291-1.19824,6.6582-2.73926,10.25781-2.39648
      c-0.80957,1.28369-1.79883,2.48242-3.3291,2.99512c-5.5791,2.05469-11.24707,3.93799-16.7373,6.24854
      c-1.70898,0.77051-3.50879,1.37012-5.39746,1.79785C512.89355,396.09961,512.62305,395.58594,512.44336,395.07227z
      M569.49316,288.6792c1.7998,4.02295,3.41895,8.38818,2.96875,12.92432c-1.08008-0.34229-2.1582-0.77051-3.3291-1.19824
      c1.62012,13.26758,3.24023,26.53418,4.22949,39.88721c0.4502,8.9873,1.7998,18.06006-0.08984,26.96191
      c-1.62012,8.21729-5.66895,16.52002-12.86816,21.22754c-6.02832,3.9375-13.04688,6.16211-20.06641,7.61768
      c-2.87988,0.59961-5.84766,0.85645-8.63867,0.08594c2.9707-1.62695,6.2998-2.82471,9.44922-4.19434
      c4.58887-1.96875,8.99805-4.10889,13.13672-6.84717c3.87012-2.56787,7.91895-5.13623,10.79883-8.90234
      c-3.60059-9.58643-9.53906-18.57373-17.99707-24.47998c0.27051,9.41553,0.7207,19.00244-0.71973,28.33203
      c-0.26953,2.22559-1.25977,4.19385-2.79004,5.73486c-0.98926-3.9375-1.0791-8.0459-1.5293-12.06885
      c-0.80957-8.73047-1.5293-17.54688-2.16016-26.27734c0-1.79785-1.16895-3.16699-2.51855-4.19434
      c-5.31055-4.45117-10.25879-9.41553-15.3877-14.03711c0.18066,5.39209,0.99023,10.69873,1.35059,16.00586
      c0.62988,7.18994,0.71875,14.37988,1.43945,21.56934c0.4502,5.05078,0.53906,10.35742-1.35059,15.15039
      c-1.88867,4.53711-6.11816,8.38818-11.15723,8.73047c-2.7002,0.25684-5.39941,0-8.00879-0.42725
      c-2.78906-0.42822-4.94922-2.56836-6.20898-4.96436c-1.5293-2.91064-2.33984-6.16357-3.59961-9.15918
      c-2.33887,0.51367-4.76953,1.02734-6.47852,2.82471c-3.59961,3.42383-4.67969,8.64502-6.38867,13.0957
      c-0.99023,2.65283-2.16016,5.56445-4.76953,7.01904c-2.69922,1.54004-6.0293,0.94092-8.6377-0.51367
      c-3.95996-2.13965-6.38867-6.24854-7.55859-10.44238c-1.1709-3.25244,0.44922-7.01904-1.53027-10.1001
      c-1.44043-2.82471-0.63086-6.1626,0.08984-9.07324c1.5293-5.22119,4.76953-9.67188,8.63965-13.43848
      c-5.12988-4.45068-10.25879-8.90186-15.38867-13.35205c0.44922,12.3252,1.43945,24.65039-0.18066,36.89063
      c-0.26953,2.48242-1.16895,4.79297-2.96777,6.59082c-0.54102-2.31152-0.90039-4.70752-1.08105-7.10449
      c-1.25977-13.52344-2.33984-27.04736-3.23828-40.57129c-0.09082-1.54102-0.35938-3.25293-1.62012-4.27979
      c-5.21875-4.96436-10.25781-10.27148-14.9375-15.83545c-2.7002-3.42334-5.48926-6.93262-6.92871-11.04102
      c-0.54004-1.7124-0.98926-4.10889,0.62988-5.39258c1.88867,0.1709,3.59863,1.02686,5.21973,1.88281
      c2.96875,1.71191,5.75879,3.9375,8.1875,6.50537c1.0791,1.11279,1.62012,2.56738,2.07031,4.02246
      c-1.53027,0-2.96973-0.08545-4.49902-0.08545c3.05957,4.36523,6.6582,8.38867,10.79785,11.81201
      c-0.36035-11.46973-1.62012-23.0249-2.16016-34.49414c0-1.54102,0-3.25244,1.08008-4.53662
      c0.98926-1.11279,2.51953-1.54053,3.86816-2.0542c2.52051,3.68018,4.32031,7.78906,5.39941,12.1543
      c0.4502,1.71191,0.36035,3.50928,0.27051,5.30664c-1.08008-0.25635-2.15918-0.59912-3.14941-0.94141
      c0.7207,9.32959,1.70996,18.57422,2.33984,27.90381c0.08887,1.28418,0.17969,2.65332,0.53906,3.9375
      c0.54102,0.94141,1.35059,1.54004,2.07031,2.2251c6.38867,5.39258,12.77832,10.78516,19.25684,16.00635
      c3.86914-3.33838,7.64746-6.76172,11.51855-10.01416c0.89844-0.77051,1.79883-1.45557,2.51855-2.31152
      c0.71973-0.77002,0.44922-1.8833,0.44922-2.82471c-1.25977-11.21289-2.1582-22.42529-3.14844-33.63818
      c-0.26953-4.10889-0.89941-8.13135-0.89941-12.15479c-0.09082-2.39648,1.79883-4.79297,4.22852-4.87891
      c1.08008-0.08545,1.53027,1.11328,2.06934,1.79785c1.79883,3.16699,3.14941,6.59082,3.95996,10.18555
      c0.4502,1.71191,0.26953,3.50977-0.09082,5.30664c-0.99023-0.42725-2.07031-0.94141-3.05859-1.36914
      c1.34961,10.52783,2.42969,21.05615,3.41895,31.58447c2.78906-2.0542,5.30957-4.45117,7.91895-6.67676
      c2.87891-2.48193,5.93848-4.87842,8.72852-7.53223c-3.3291-3.59473-6.83984-6.93311-10.25781-10.44238
      c-2.16113-2.2251-4.76953-4.36523-5.66992-7.53271c-0.71973-2.2251-0.26953-4.62207,0.17969-6.76172
      c4.0498,0.68457,7.55957,3.08203,10.79883,5.47852c2.24902,1.71143,4.22852,3.68018,6.20898,5.64893
      c-0.17969-4.45068-0.7207-8.90186-1.08008-13.35254c0-1.71191-0.17969-3.59521,0.99023-4.96436
      c0.98926-1.11328,2.51855-1.54102,3.7793-2.14014c2.60938,3.42334,4.22852,7.53223,5.30859,11.64063
      c0.53906,1.8833,0.53906,3.76611,0.4502,5.64941c-1.08008-0.25684-2.24902-0.51367-3.3291-0.77051
      c0.35938,3.42383,0.71875,6.76221,1.16895,10.18604c4.22949-3.16699,8.09863-6.76221,12.32715-9.9292
      c1.44043-1.36963,4.05078-2.39648,4.05078-4.79346c0.08984-2.56738-0.4502-5.2207-0.09082-7.78857
      c0.27051-2.22559,2.42969-4.02295,4.67871-3.85205c2.16016,2.0542,2.96973,4.62256,4.13965,6.84766
      c3.95898-2.82471,7.64941-5.9917,12.05859-8.2168c-1.26074,5.0498-5.04004,8.90137-8.9082,12.15381
      c-1.97949,1.5415-1.08008,4.36572-1.35059,6.50586c-1.0791-0.25732-2.07031-0.51416-3.14941-0.85645
      c0.98926,12.83887,2.42969,25.59277,3.05957,38.43213c0.08984,1.45508,0.17969,2.99561,1.44043,3.9375
      c5.93848,5.30615,12.05664,10.52734,16.37598,17.28955c1.70996,2.65332,2.61035,5.64941,3.50977,8.64502
      c1.08008-4.96436,0.98926-10.09961,0.4502-15.15039c-2.16016-19.51514-4.13965-39.11621-5.84863-58.71777
      c-0.18066-2.39648-0.54102-4.87793-0.27051-7.27539c0.17969-2.2251,2.42969-4.1084,4.67871-3.93701
      C568.05273,285.59766,568.77344,287.13818,569.49316,288.6792z M556.1748,330.53418
      c-0.80957-3.50928-0.71875-7.96045-3.95898-10.27051c-1.43945-0.85645-3.14941-1.36963-4.31934-2.73926
      c-0.53906-1.36963,0.54004-2.73926,1.98047-2.73926c2.24902,0,3.95898,1.8833,5.03809,3.68066
      C556.80469,322.14648,557.70508,326.59717,556.1748,330.53418z"/>
    <path class="sa-i-0" d="M562.02441,288.42188c-0.27051,2.39746,0.08984,4.87891,0.27051,7.27539
      c1.70898,19.60156,3.68848,39.20264,5.84863,58.71777c0.53906,5.05078,0.62988,10.18604-0.4502,15.15039
      c-0.89941-2.99561-1.7998-5.9917-3.50977-8.64502c-4.31934-6.76221-10.4375-11.9834-16.37598-17.28955
      c-1.26074-0.94189-1.35059-2.48242-1.44043-3.9375c-0.62988-12.83936-2.07031-25.59326-3.05957-38.43213
      c1.0791,0.34229,2.07031,0.59912,3.14941,0.85645c0.27051-2.14014-0.62891-4.96436,1.35059-6.50586
      c3.86816-3.25244,7.64746-7.104,8.9082-12.15381c-4.40918,2.2251-8.09961,5.39209-12.05859,8.2168
      c-1.16992-2.2251-1.97949-4.79346-4.13965-6.84766c-2.24902-0.1709-4.4082,1.62646-4.67871,3.85205
      c-0.35938,2.56787,0.18066,5.22119,0.09082,7.78857c0,2.39697-2.61035,3.42383-4.05078,4.79346
      c-4.22852,3.16699-8.09766,6.76221-12.32715,9.9292c-0.4502-3.42383-0.80957-6.76221-1.16895-10.18604
      c1.08008,0.25684,2.24902,0.51367,3.3291,0.77051c0.08887-1.8833,0.08887-3.76611-0.4502-5.64941
      c-1.08008-4.1084-2.69922-8.21729-5.30859-11.64063c-1.26074,0.59912-2.79004,1.02686-3.7793,2.14014
      c-1.16992,1.36914-0.99023,3.25244-0.99023,4.96436c0.35938,4.45068,0.90039,8.90186,1.08008,13.35254
      c-1.98047-1.96875-3.95996-3.9375-6.20898-5.64893c-3.23926-2.39648-6.74902-4.79395-10.79883-5.47852
      c-0.44922,2.13965-0.89941,4.53662-0.17969,6.76172c0.90039,3.16748,3.50879,5.30762,5.66992,7.53271
      c3.41797,3.50928,6.92871,6.84766,10.25781,10.44238c-2.79004,2.65381-5.84961,5.05029-8.72852,7.53223
      c-2.60938,2.22559-5.12988,4.62256-7.91895,6.67676c-0.98926-10.52832-2.06934-21.05664-3.41895-31.58447
      c0.98828,0.42773,2.06836,0.94189,3.05859,1.36914c0.36035-1.79688,0.54102-3.59473,0.09082-5.30664
      c-0.81055-3.59473-2.16113-7.01855-3.95996-10.18555c-0.53906-0.68457-0.98926-1.8833-2.06934-1.79785
      c-2.42969,0.08594-4.31934,2.48242-4.22852,4.87891c0,4.02344,0.62988,8.0459,0.89941,12.15479
      c0.99023,11.21289,1.88867,22.42529,3.14844,33.63818c0,0.94141,0.27051,2.05469-0.44922,2.82471
      c-0.71973,0.85596-1.62012,1.54102-2.51855,2.31152c-3.87109,3.25244-7.64941,6.67578-11.51855,10.01416
      c-6.47852-5.22119-12.86816-10.61377-19.25684-16.00635c-0.71973-0.68506-1.5293-1.28369-2.07031-2.2251
      c-0.35938-1.28418-0.4502-2.65332-0.53906-3.9375c-0.62988-9.32959-1.61914-18.57422-2.33984-27.90381
      c0.99023,0.34229,2.06934,0.68506,3.14941,0.94141c0.08984-1.79736,0.17969-3.59473-0.27051-5.30664
      c-1.0791-4.36523-2.87891-8.47412-5.39941-12.1543c-1.34863,0.51367-2.87891,0.94141-3.86816,2.0542
      c-1.08008,1.28418-1.08008,2.99561-1.08008,4.53662c0.54004,11.46924,1.7998,23.02441,2.16016,34.49414
      c-4.13965-3.42334-7.73828-7.44678-10.79785-11.81201c1.5293,0,2.96875,0.08545,4.49902,0.08545
      c-0.4502-1.45508-0.99121-2.90967-2.07031-4.02246c-2.42871-2.56787-5.21875-4.79346-8.1875-6.50537
      c-1.62109-0.85596-3.33105-1.71191-5.21973-1.88281c-1.61914,1.28369-1.16992,3.68018-0.62988,5.39258
      c1.43945,4.1084,4.22852,7.61768,6.92871,11.04102c4.67969,5.56396,9.71875,10.87109,14.9375,15.83545
      c1.26074,1.02686,1.5293,2.73877,1.62012,4.27979c0.89844,13.52393,1.97852,27.04785,3.23828,40.57129
      c0.18066,2.39697,0.54004,4.79297,1.08105,7.10449c1.79883-1.79785,2.69824-4.1084,2.96777-6.59082
      c1.62012-12.24023,0.62988-24.56543,0.18066-36.89063c5.12988,4.4502,10.25879,8.90137,15.38867,13.35205
      c-3.87012,3.7666-7.11035,8.21729-8.63965,13.43848c-0.7207,2.91064-1.53027,6.24854-0.08984,9.07324
      c1.97949,3.08105,0.35938,6.84766,1.53027,10.1001c1.16992,4.19385,3.59863,8.30273,7.55859,10.44238
      c2.6084,1.45459,5.93848,2.05371,8.6377,0.51367c2.60938-1.45459,3.7793-4.36621,4.76953-7.01904
      c1.70898-4.45068,2.78906-9.67188,6.38867-13.0957c1.70898-1.79736,4.13965-2.31104,6.47852-2.82471
      c1.25977,2.99561,2.07031,6.24854,3.59961,9.15918c1.25977,2.396,3.41992,4.53613,6.20898,4.96436
      c2.60938,0.42725,5.30859,0.68408,8.00879,0.42725c5.03906-0.34229,9.26855-4.19336,11.15723-8.73047
      c1.88965-4.79297,1.80078-10.09961,1.35059-15.15039c-0.7207-7.18945-0.80957-14.37939-1.43945-21.56934
      c-0.36035-5.30713-1.16992-10.61377-1.35059-16.00586c5.12891,4.62158,10.07715,9.58594,15.3877,14.03711
      c1.34961,1.02734,2.51855,2.39648,2.51855,4.19434c0.63086,8.73047,1.35059,17.54688,2.16016,26.27734
      c0.4502,4.02295,0.54004,8.13135,1.5293,12.06885c1.53027-1.54102,2.52051-3.50928,2.79004-5.73486
      c1.44043-9.32959,0.99023-18.9165,0.71973-28.33203c8.45801,5.90625,14.39648,14.89355,17.99707,24.47998
      c-2.87988,3.76611-6.92871,6.33447-10.79883,8.90234c-4.13867,2.73828-8.54785,4.87842-13.13672,6.84717
      c-3.14941,1.36963-6.47852,2.56738-9.44922,4.19434c2.79102,0.77051,5.75879,0.51367,8.63867-0.08594
      c7.01953-1.45557,14.03809-3.68018,20.06641-7.61768c7.19922-4.70752,11.24805-13.01025,12.86816-21.22754
      c1.88965-8.90186,0.54004-17.97461,0.08984-26.96191c-0.98926-13.35303-2.60938-26.61963-4.22949-39.88721
      c1.1709,0.42773,2.24902,0.85596,3.3291,1.19824c0.4502-4.53613-1.16895-8.90137-2.96875-12.92432
      c-0.71973-1.54102-1.44043-3.08154-2.79004-4.19434C564.4541,284.31348,562.2041,286.19678,562.02441,288.42188z
      M509.47363,307.08203c1.08008,0,2.24902,0,3.33008,0c0,1.28369,0.08984,2.56738,0.08984,3.85156
      C511.72266,309.64941,510.64355,308.36572,509.47363,307.08203z M465.1123,367.42578
      c2.06934-2.82471,4.49805-5.47803,6.92773-7.875c2.16016,1.71191,4.58984,3.76611,4.86035,6.67676
      c0.26855,1.45508-0.99121,2.56738-2.07031,3.25195c-3.50879,2.05469-7.64844,2.91113-11.69824,2.48291
      C463.58105,370.33594,464.12207,368.70947,465.1123,367.42578z M518.47168,380.52148
      c-4.49902,4.10889-12.50781,4.02295-16.28613-0.94141c-2.25-2.82471-2.87988-6.41992-4.68066-9.41553
      c-0.80859-1.45508-2.6084-1.02686-3.95801-1.1123c-4.13867,0.08545-7.64844,2.91016-9.80859,6.3335
      c-2.7002,4.10889-4.67871,8.56006-6.47852,13.09619c-0.90039,2.2251-2.42969,4.62207-4.94922,5.30615
      c-2.33984,0.68506-4.49902-1.02637-5.84961-2.82422c-2.15918-3.08154-2.87988-6.84766-3.68848-10.44238
      c5.48926-0.68457,11.42773-1.28369,15.83691-4.96436c3.59961-2.91016,4.94922-7.96045,3.7793-12.32568
      c-0.7207-3.16699-2.79004-5.64893-4.85938-7.96045c3.59961-3.33789,7.28906-6.67578,11.1582-9.75732
      c1.08008,6.33398,1.1709,12.75342,2.61035,19.00146c1.16895-1.02686,2.33887-2.13965,2.69824-3.76611
      c1.62109-6.33398,0.90039-13.01025,0.99121-19.51514c-0.09082-1.28418,0.98926-2.14014,1.88867-2.91016
      c5.84863-4.87842,11.51758-9.84375,17.54688-14.55127c1.79883,17.03369,3.3291,34.23779,5.39941,51.271
      C520.00195,376.92676,520.00195,379.15186,518.47168,380.52148z M523.24121,322.06055
      c-0.89941-0.94141-1.97949-1.79688-2.51953-2.91016c-0.08984-1.28418,1.25977-1.96875,1.97949-2.73877v-0.00049
      c4.76953-3.76611,9.17773-7.87451,14.03809-11.38379c0.53906,5.30664,0.71875,10.61377,1.16895,15.91992
      c0.27051,5.05078,0.81055,10.01514,0.99023,15.06494C533.40918,331.73291,528.46094,326.76807,523.24121,322.06055z"/>
    <path class="sa-i-0" d="M236.64307,329.67871c2.33984-0.51416,4.229-2.31104,5.21924-4.45068
      c2.60938-5.47852,3.86865-11.47021,5.75879-17.20459c0.62988-1.79736,1.70947-3.42383,3.05957-4.79346
      c-0.81006,3.50928-1.97998,7.01855-2.07031,10.61328c-0.26953,4.62256,0.90039,9.67236,4.5,12.9248
      c2.15967,2.05469,5.21875,2.65332,8.0083,3.33838c0.98975,9.50098,1.70947,19.00195,2.69922,28.50293
      c0.54004,4.70752,0.72021,9.41553,1.7998,13.95166c2.96924-2.14014,3.14941-5.99121,3.50928-9.24414
      c0.99023-11.38379-0.17969-22.85352-0.80957-34.32275c2.24951-1.19873,4.49902-2.56836,6.2085-4.53711
      c2.96924-3.25195,4.67969-7.27539,5.84912-11.46924c1.08008,3.08154,1.97949,6.50488,4.40967,8.90186
      c3.68848,3.68018,9.26758,4.87842,14.2168,4.02295c4.229-0.68457,7.64893-4.27979,8.54883-8.38818
      c0.62939-2.99609,0.53955-6.24902-0.35938-9.24463c-1.26074-4.87891-5.5791-8.3877-5.84912-13.52393
      c1.0791-0.1709,2.24951-0.3418,3.32861-0.51367c-1.25879-3.25244-3.59912-5.90576-5.75879-8.55908
      c-0.71973-0.77002-1.70996-1.96826-2.78955-1.28369c-2.33936,1.36914-2.96973,4.45068-2.51953,6.67627
      c1.26025,6.1626,4.58936,11.55566,7.28906,17.20459c1.16992,2.48193,2.15918,5.30664,1.43945,8.0459
      c-0.35938,1.45508-1.52979,2.65283-2.96924,2.90967c-2.96973,0.51367-6.02881,0.17188-8.63867-1.2832
      c-3.23926-1.79785-5.48926-4.96484-6.74854-8.38867c-1.53027-4.02295-0.71973-8.38818-1.53027-12.58252
      c-0.62891-3.16699-1.0791-6.41895-2.4292-9.32959c-0.4502-0.68506-0.8999-1.8833-1.88965-1.45508
      c-2.15967,0.77051-3.86963,3.25293-3.3291,5.64941c0.53955,2.56787,1.70898,4.87891,2.4292,7.36084
      c1.70996,5.82031,1.16992,12.41113-2.33936,17.46143c-1.70996,2.65332-4.40918,4.45068-7.37842,5.39209
      c-0.81055-7.104-1.53027-14.29395-2.25049-21.39844c1.17041,0.25684,2.25049,0.51367,3.41943,0.77051
      c0.09033-6.1626-2.4292-12.15479-5.84863-17.2041c-1.79932,0.68457-4.13916,1.45459-4.58887,3.59473
      c-0.54004,3.08105,0,6.24805,0.17969,9.33008c0.54053,8.3877,1.34961,16.68994,1.89014,24.99316
      c-2.06982-0.59961-4.31934-1.19824-5.66943-2.99561c-1.7998-2.14014-2.06934-5.13623-1.97949-7.87549
      c0.17969-6.1626,2.96924-11.81152,3.50928-17.88867c-5.03906,0.94189-8.54834,5.30664-10.52832,9.75781
      c-2.24951,5.30664-3.68896,10.95557-5.48877,16.43408c-0.54004,1.45508-1.07959,2.99561-2.15918,4.19385
      c-1.97998,1.8833-5.48926,2.0542-7.55908,0.1709c-1.34961-1.19775-1.70947-3.08105-2.06982-4.79297
      c-0.53955-2.82422-1.0791-5.82031-0.71973-8.73047c0.27002-2.0542,1.52979-3.85156,2.60986-5.64893
      c-1.62012,0.94141-3.50977,1.96826-4.40967,3.85107c-1.52979,3.08154-1.25928,6.67676-1.07959,10.01514
      c0.36035,3.25244,0.89941,6.84766,3.32959,9.24414C230.34424,330.10645,233.76367,330.27783,236.64307,329.67871z"/>
    <path class="sa-i-0" d="M426.41895,298.26563c1.5293-1.02734,2.07031-2.73877,2.6084-4.36523
      c-1.97852,0.51367-4.04883,0.85547-6.11816,0.94141c0.09082-1.54102,0.09082-3.25244,0.98926-4.62207
      c1.16992-1.8833,2.42969-3.68066,3.24023-5.82031c-3.05859,1.45508-5.75781,3.68066-7.46875,6.59033
      c-1.43945,2.39697-1.16992,5.8208,0.71973,7.78955C422.18945,300.23438,424.70898,299.46338,426.41895,298.26563z"/>
    <path class="sa-i-0" d="M313.03906,373.75977c4.40967-5.8208,7.28906-12.83936,7.64893-20.2002
      c0.09033-3.50977,2.15918-6.33398,3.41943-9.41553c1.43896,1.79736,2.78906,3.85156,4.94922,4.70801
      c2.06885,0.85596,4.40869,0.3418,6.29834-0.59961c3.06006-1.54053,5.84912-4.1084,6.65869-7.53271
      c0.81006-2.99512,1.08057-6.33398,0-9.3291c-0.35938-1.19824-1.61914-1.36963-2.60938-1.79785
      c-0.62939,3.16748-1.5293,6.33398-1.34961,9.58691c-2.33887,1.79688-5.75879,2.99609-8.27881,1.02686
      c-1.61914-1.79688-0.8999-4.45117-1.07959-6.67627c-4.04932,0.94189-6.11865,4.70752-8.4585,7.70361
      c-0.53955-4.10889-0.98975-8.30273-1.43945-12.41113c5.12939-2.91016,10.43799-5.22119,15.65674-7.87451
      c1.44043-0.59961,2.16016-1.96924,2.79004-3.33887c-1.71045,0.17188-3.32959,0.51367-4.85889,1.19873
      c-4.94971,2.0542-9.3584,4.87842-14.12793,7.18994c-0.99023-8.30225-2.4292-16.51953-3.59961-24.82227
      c0.99072,0.34229,2.06982,0.68457,3.05908,1.02686c0.18115-1.71143,0.36084-3.42334-0.08936-5.13525
      c-1.07959-4.36523-2.78955-8.73096-5.57861-12.32568c-1.3501,0.59912-3.06055,1.11279-3.95996,2.48193h0.00049
      c-0.98975,1.62646-0.71973,3.68066-0.63037,5.64893c1.43994,13.52441,3.1499,27.04834,4.85986,40.48682
      c0.53955,4.02246,1.16943,8.04541,1.43896,12.1543c-1.61963-0.17139-3.59912-0.08594-4.76855-1.54102
      c-1.97998-2.0542-1.70996-5.0498-1.88965-7.61768c-4.94922,2.13965-8.36865,6.59082-13.4082,8.47363
      c-1.61963-3.08105-3.50879-6.33398-6.83789-7.87451c-3.41992-1.54053-7.82861-1.62598-10.79883,0.85596
      c-2.60889,2.13965-4.04883,5.39258-4.58887,8.64502c-0.35986,2.48193-0.27002,5.30664,1.52979,7.27588
      c1.7998,1.96826,4.49951,2.48193,7.01855,2.65283c4.0498,0.08594,7.82861-1.71143,11.69775-2.48242
      c0,1.62646,0.18018,3.33838-0.72021,4.79395c-1.88867,2.99561-4.76855,5.0498-7.55811,7.01807
      c-7.46875,4.96436-15.47754,8.81641-23.66602,12.41113c-3.50879,1.5415-7.1084,3.16699-10.79785,4.36572
      c-2.15918-7.01855-2.24951-14.72217-0.36035-21.91162c0.81006-3.42432,2.69971-6.42041,4.22998-9.50146
      c-1.7998,1.71191-3.50977,3.68066-4.85986,5.90576c-4.94824,8.38867-6.83838,18.40283-5.39844,28.0752
      c-5.30957,1.96875-10.79834,3.33838-16.19727,5.13525c4.40918,0.77051,8.90869,0.59961,13.40771,0.42822
      c1.89014,0,3.7793-0.51318,5.5791-0.17139c1.43994,0.85596,2.69971,2.14014,4.13916,3.08154
      c3.32959,2.0542,7.28857,2.91064,11.15771,2.99609c7.19873,0.34229,14.30762-1.37012,21.14697-3.25244
      C293.69238,389.76563,305.39063,383.77441,313.03906,373.75977z M278.66553,347.56738
      c-0.72021-0.3418-1.7998-0.94141-1.3501-1.88281c0.62988-1.62598,2.78955-1.71191,4.31934-1.62598
      c2.33984,0.08545,4.04932,1.79736,5.48926,3.33838C284.33447,347.91016,281.36523,348.50928,278.66553,347.56738z
      M255.80957,387.96826c3.59912-1.54053,7.19824-3.08105,10.52783-5.05029c7.46875-3.93701,15.20752-7.44629,21.77588-12.83887
      c3.41992-2.90967,6.74951-6.41943,8.18896-10.78467c0.89941-2.56787,0.81006-5.30664,0.63037-8.0459h-0.00049
      c2.06934-1.36963,4.13916-2.99609,6.02832-4.79346c1.17041,1.8833,2.25,4.02295,4.23047,5.13574
      c2.4292,1.36914,5.30859,1.28369,8.0083,1.36914c-0.09033,2.22607-0.09033,4.45117-0.63086,6.59082
      c-1.0791,4.36572-3.59863,8.21729-6.74805,11.29834c-5.21973,5.13623-11.69824,8.64551-18.35693,11.38379
      c-6.38916,2.56836-13.04736,4.53711-19.97656,4.96533C264.98779,387.54053,260.39893,388.31055,255.80957,387.96826z"/>
    <path class="sa-i-0" d="M375.66748,292.9585c0.8999,1.79736,0.81006,3.85205,1.16992,5.8208
      c0.44971-1.28418,1.07959-2.56787,1.16992-4.02344c0.18018-3.08105-1.34961-6.24805-3.95898-7.95996
      c-1.16992-0.77051-2.96973-0.94141-3.95947,0.1709c-0.81006,0.51367-0.53955,1.7124,0.08984,2.22607
      C371.79883,290.73291,374.31836,291.07568,375.66748,292.9585z"/>
    <path class="sa-i-0" d="M341.92383,322.23193c-2.69971-5.05029-1.0791-10.95654-1.0791-16.34814
      c-1.97998,3.76563-2.96973,8.13086-3.06055,12.41064c0,2.82471,0.36084,5.73535,1.89014,8.13135
      c1.70947,2.56836,4.94922,3.68066,7.91846,3.50977c3.05957-0.25684,5.48975-2.56787,6.92871-5.13574
      c3.06006-5.39258,3.95996-11.55566,6.11914-17.29004c0.53955-1.79736,1.70947-3.25244,2.96973-4.53662
      c-0.72021,5.13574-2.79004,10.1001-2.43018,15.40723c0,3.59473,2.06982,7.01855,5.12891,8.9873
      c4.94922,3.33838,11.87842,3.76611,17.09766,0.59912c5.12891-2.91064,8.18799-8.38818,9.62842-14.03711
      c0.90039,2.65283,1.79932,5.2207,3.50879,7.44629c4.49902,6.24854,14.93848,7.27539,20.15723,1.45508
      c2.78906-2.99561,3.50879-7.44678,2.87891-11.38428c-0.54004-3.59473-2.42969-6.67578-3.7793-9.92871
      c-0.99023-2.31152-1.61914-4.62158-2.25-7.01904c1.16992,0.08594,2.42969,0.17139,3.68945,0.17139
      c-1.61914-3.50928-4.13867-6.76221-7.64844-8.47363c-1.25977-0.68506-2.42969,0.59912-2.60938,1.79736
      c-0.63086,3.08154,0.17969,6.16309,1.25977,9.15918c1.98047,5.90576,6.20898,10.78418,7.46875,16.94678
      c0.4502,1.88281,0,4.19434-1.88965,5.13574c-2.96973,1.54102-6.74902,1.19873-9.80859-0.25684
      c-3.3291-1.54004-5.21875-4.87891-6.47803-8.13086c-2.07031-5.64941-2.16064-11.72656-2.87988-17.63281
      c-0.27051-2.56787-1.43945-4.87891-2.78955-7.01855c-1.89014,1.02686-3.50928,2.73926-3.95947,4.96436
      c-0.71973,2.22559,0.98975,4.10889,1.88965,5.90625c2.06982,3.42383,1.88965,7.61768,1.70996,11.46924
      c-0.27002,6.50488-4.76904,12.75391-10.97803,14.72217c-4.49902,1.19824-9.89893,0.34277-13.1377-3.16699
      c-2.87939-3.33789-2.60986-8.13135-1.71045-12.1543c0.8999-4.62207,3.59961-8.81592,3.32959-13.60889
      c-1.25977,0.25635-2.69971,0.25635-3.68896,1.1123c-1.97949,1.54053-3.23975,3.76563-4.40869,5.90576
      c-2.79004,5.30713-4.77002,11.0415-6.65918,16.69092c-0.8999,2.65332-2.06982,5.56396-4.58936,7.10449
      C346.87354,326.68262,343.27393,325.05615,341.92383,322.23193z"/>
    <path class="sa-i-0" d="M462.8623,294.24219c0.98926,1.36963,1.5293,2.99609,2.15918,4.53711
      c0.90039-3.25244,1.70996-6.50537,3.24023-9.41504c1.16895,0.51318,2.33887,1.19824,3.68945,1.45508
      c0.71973-0.51367,1.34961-1.36963,0.98926-2.31152c-0.90039-1.96875-3.59961-2.65332-5.48926-1.79736
      c-1.62012,1.02686-1.88965,3.16699-2.42969,4.96436c-1.16992-1.62646-2.33887-3.42383-4.31934-4.02344
      c-1.34961-0.42773-2.96875,1.11279-2.51855,2.48242C459.62305,291.6748,461.60254,292.53076,462.8623,294.24219z"/>
    <path class="sa-i-0" d="M232.41406,300.49121c1.5293-1.11279,2.15918-2.91064,2.78955-4.53711
      c-2.06982,0.68506-4.13965,1.11328-6.20947,1.28418c0.08984-1.54102,0-3.25244,0.8999-4.62207
      c1.08008-1.96875,2.42969-3.76611,3.23975-5.90576c-3.23975,1.54053-6.20898,3.93701-7.73877,7.18994
      c-1.08008,2.39648-0.71973,5.56299,1.25977,7.27539C228.36426,302.37402,230.79395,301.68945,232.41406,300.49121z"/>
    <path class="sa-i-0" d="M346.96289,295.61182c1.16992,4.10889-2.60889,7.10449-2.87939,10.95654
      c-0.17969,2.13965,2.24951,4.02295,4.229,3.08105c1.62012-0.85596,1.3501-2.91016,1.62012-4.45068
      c-0.99023,0.51367-1.97949,1.02734-2.96973,1.45508c0.27051-2.99609,2.33984-5.22119,3.1499-8.0459
      c0.53955-1.79736-0.4502-3.42383-1.25977-4.96436c-0.8999-1.54102-1.3501-3.16748-2.24951-4.70752
      c-1.3501-2.14014-5.5791-2.22559-6.11963,0.59863c-0.35938,2.22559,1.97949,3.50977,3.32959,4.87939
      c-1.08008,1.88281-2.15918,3.85156-3.7793,5.39209c-2.51904,2.56787-5.66846,4.1084-8.4585,6.33398
      C337.96533,305.19873,343.18359,300.66211,346.96289,295.61182z M343.09424,289.27783
      c1.07959,0.34277,1.97949,1.11279,2.33887,2.22559C344.53369,290.98975,343.72412,290.21973,343.09424,289.27783z"/>
    <path class="sa-i-0" d="M328.24609,303.74365c1.08008-4.28027-0.44971-9.07324-3.68896-12.06885
      c-1.07959-1.19873-2.69971-0.68506-4.04932-0.34229c1.34961,2.90967,4.31934,4.62158,5.66846,7.53223
      C326.98682,300.40527,327.52637,302.11768,328.24609,303.74365z"/>
    <path class="sa-i-0" d="M286.76367,299.80615c2.15918-0.51318,4.31934-1.11279,6.479-1.45508
      c0.27002-1.96875-0.35938-3.76611-1.70947-5.22119c0,1.28418,0,2.56738,0.08936,3.85156
      c-0.71924,0.08594-1.34961,0.17188-2.06885,0.25684c-0.18066-1.02686-0.36035-2.13965-0.54004-3.16699
      c-0.35986,0-0.81006,0-1.25977,0c0,1.11279,0,2.31104,0,3.50928c-0.54004,0.25635-1.16992,0.42822-1.70996,0.68506
      c-0.44971-1.36914-0.8999-2.65381-1.34961-3.93701c-0.27002,1.2832-0.71973,2.48193-0.62988,3.76563
      C284.33447,299.29297,285.59424,300.14844,286.76367,299.80615z"/>
    <path class="sa-i-0" d="M459.62305,308.45117c-0.54004-1.1123-1.08008-2.14014-1.62109-3.25244
      c5.94043-3.16699,12.32813-4.96436,18.53711-7.44678c1.26074-0.51367,2.25-1.45508,3.15039-2.48193
      c-1.7998,0-3.68945,0.1709-5.39941,0.68408c-5.30957,1.7124-10.43848,3.85205-15.56738,5.73535
      c-1.16895,0.51318-2.24902,0.94141-3.23828,1.71143c-1.1709,0.85645-1.08105,2.65332-0.09082,3.59521
      C456.47363,308.19434,458.18359,308.19434,459.62305,308.45117z"/>
    <path class="sa-i-0" d="M377.7373,305.19873c-0.53955-1.71191-0.4502-3.50928-0.62988-5.30664
      c-0.71973,0.51318-2.06982,0.59912-2.24951,1.71143c-0.53955,2.14014-0.62988,4.28027-0.98926,6.33398
      c-1.08057-0.42773-2.61035-0.42773-2.97021-1.71143c-0.44971-1.8833,0.27051-3.7666,0.4502-5.6499
      c-1.25977,1.11328-2.69971,2.22607-2.96973,4.02344c-0.62988,2.73926,0.8999,6.41895,4.0498,6.59082
      c1.70898,0.25635,2.96973-1.11328,4.13867-2.14014c1.43994,1.19824,3.33008,2.48242,5.39941,1.79785
      c2.4292-0.51416,3.23926-3.42432,2.24951-5.47852c-0.98926-2.0542-1.7998-4.1084-1.70947-6.41943
      c-0.71973,0.42822-1.5293,0.85596-2.24951,1.45508c0,2.31104,1.07959,4.62207,0.53906,7.10449
      C379.7168,306.91016,378.1875,306.48242,377.7373,305.19873z"/>
    <path class="sa-i-0" d="M286.854,303.65771c0.27002,1.96924,1.70947,3.42432,3.1499,4.70801
      c2.33887,2.13965,3.23877,5.22119,4.31836,8.0459c1.17041-4.19434-0.35986-8.90186-3.41895-11.89746
      C289.82324,303.40088,288.20361,303.65771,286.854,303.65771z"/>
    <path class="sa-i-0" d="M461.33203,318.29443c-0.4502,2.56787,0.7207,5.8208,3.50977,6.41943
      c1.88965,0.51416,3.50977-0.77051,4.85938-1.79688c1.70898,1.19824,3.87012,2.31104,5.93848,1.36914
      c2.25-0.85596,2.25-3.85156,1.34961-5.73486c-0.89941-1.88281-1.61914-3.85156-1.61914-5.99121
      c-0.81055,0.59863-1.61914,1.11279-2.24902,1.88281c0.17969,2.13965,0.80957,4.2793,0.89941,6.41943
      c-1.08008-0.34229-2.43066-0.42773-3.06055-1.54053c-0.98926-1.79736-0.62988-3.9375-0.71875-5.90576
      c-0.81055,0.3418-1.62012,0.68408-2.24902,1.19824c-0.90039,2.0542-0.7207,4.36475-1.08105,6.59082
      c-1.08008-0.25684-2.60938,0-3.05957-1.28418c-0.53906-1.96826,0.27051-3.93799,0.54004-5.90625
      C463.13184,315.21338,461.5127,316.41162,461.33203,318.29443z"/>
    <path class="sa-i-0" d="M549.87695,314.78516c-1.44043,0-2.51953,1.36963-1.98047,2.73926
      c1.16992,1.36963,2.87988,1.88281,4.31934,2.73926c3.24023,2.31006,3.14941,6.76123,3.95898,10.27051
      c1.53027-3.93701,0.62988-8.3877-1.25977-12.06836C553.83594,316.66846,552.12598,314.78516,549.87695,314.78516z"/>
    <path class="sa-i-0" d="M424.43945,331.39014c-2.33984,1.96924-5.30859,2.8252-7.91895,4.36523
      c4.22949,0,8.54785-1.62646,11.1582-5.04932c1.43945-1.79785,1.79883-4.87939,0-6.59082c-1.43945-0.34277-3.05957,0-4.0498,1.11279
      c-0.80859,1.02686-1.25977,2.2251-1.79883,3.33789c1.16992-0.17139,2.42969-0.42773,3.68848-0.59912
      C425.33887,329.16455,425.51855,330.62012,424.43945,331.39014z"/>
    <path class="sa-i-0" d="M482.0293,331.81885c1.70898-0.85645,3.86914-1.7124,4.49902-3.7666
      c-0.54102-0.85596-1.61914-0.34229-2.42969-0.1709c-3.69043,1.2832-7.10938,3.25244-10.70801,5.0498
      c-3.87012,2.13965-7.82813,4.10889-11.42773,6.76221c0.26953,0.59912,0.62988,1.11279,0.89941,1.71143
      C469.25098,338.15234,475.5498,334.81396,482.0293,331.81885z"/>
    <path class="sa-i-0" d="M446.21484,362.63232c-1.0791-0.1709-2.15918-0.25684-3.23926-0.34229
      c-16.55664-0.08594-33.02344,0.34229-49.58057,0.25684c5.84912-4.62256,12.50732-8.47412,19.97607-9.84375
      c0.71973,1.62646,1.35059,3.16748,1.98047,4.79346c2.06836-0.94092,2.33789-3.42383,2.51758-5.47803
      c3.06055-0.34229,6.75-0.1709,8.82031-2.73877c0.98926-1.36963,1.88867-2.91064,1.97852-4.62207
      c-0.71973-2.39697-3.14941-3.7666-5.12891-5.05029c-3.24023-1.88281-6.92773-3.42383-10.70801-3.42383
      c-3.41895-0.08496-6.12012,2.99609-7.19824,6.07715c-0.62988,2.65381,1.70898,4.45117,3.23926,6.07764
      c-5.93945,1.62646-11.51855,4.1084-17.00684,6.93262c-3.59961,1.8833-7.37891,3.93799-9.53857,7.53271
      c-1.0791,1.62646-1.0791,3.50928-1.25977,5.30664c4.67871,0.85596,9.44824,0.42773,14.21729,0.51367
      c5.84863,0.08545,11.78809-0.08594,17.6377,0.08545c-7.19922,2.31152-14.4873,4.87891-20.78662,9.07324
      c-2.06982,1.28369-2.61035,3.68018-3.06006,5.90625c-2.15918-1.19873-4.40869-2.39746-5.93848-4.36572
      c-2.33936-2.82471-2.97021-6.50488-3.68945-10.01465c-1.34961-6.93311-1.88965-14.0376-4.04883-20.79932
      c-2.25-7.10449-6.479-13.35254-11.42822-18.74512c-0.72021,0.94189-1.62012,1.88281-1.80029,3.16699
      c-0.44922,3.16748,0.54053,6.41943,1.89063,8.9873c3.86816,7.36133,7.55859,14.89355,9.62695,22.93945
      c-8.36768,6.76172-16.4668,14.03711-26.36426,18.40283c-2.69971,1.1123-5.5791,2.13965-8.54834,2.2251
      c-1.52979,0.08594-2.87939-0.85547-4.13916-1.54004c7.1084-5.39307,14.66699-10.35742,20.78564-16.94824
      c2.4292-2.56738,3.23975-6.33398,2.69971-9.75781c-0.72021-4.45068-3.23926-8.21631-4.76904-12.41113
      c0.80957-0.1709,1.70947-0.34229,2.51953-0.51367c-2.24951-4.02246-4.76904-8.21631-8.729-10.78467
      c-0.71924,1.11279-1.79883,2.2251-1.88916,3.68066c-0.4502,4.53662,1.52979,8.81641,3.14941,12.9248
      c1.08008,2.82422,2.60938,5.39209,3.50928,8.30273c0.72021,2.05371-1.07959,3.76563-2.33887,5.13525
      c-4.85986,4.79346-10.34912,8.73047-16.10791,12.23975c0.53955-4.10791,2.33984-7.95996,4.0498-11.72656
      c-2.61035,1.71289-5.03955,3.93799-6.38965,6.84814c-1.34961,2.39648-1.43896,5.05029-2.33887,7.53223
      c-0.81006,1.11279-2.24951,1.71191-3.41992,2.39648c-9.5376,4.96484-19.25684,9.58643-29.1543,13.69482
      c-2.96973,1.19873-6.02881,2.31152-8.99854,3.76563c5.66943,0.0874,11.33789-0.68311,16.91699-1.71094
      c9.08838-1.7124,17.45703-5.73486,25.64502-9.9292c0.8999,2.22607,1.71045,4.87891,3.86963,6.1626
      c3.05908,1.88281,6.92871,1.45557,9.98828,0.08594c8.81885-3.85156,17.27734-8.64502,24.29492-15.23584
      c2.61035-2.39648,4.77002-5.30615,6.83984-8.21729c0.80957,5.30713,1.70898,10.87061,4.85889,15.32178
      c4.13867,6.1626,11.06738,9.92822,18.2666,11.0415c2.60889,0.42822,5.30908,1.02734,7.91846,0.42822
      c2.06934-1.37012,1.98047-4.36572,1.43945-6.50537c-0.35938-1.62598-2.24902-1.8833-3.59961-2.2251
      c-4.04883-0.77051-8.09863-1.62646-11.96729-3.08154c1.3501-1.79785,2.96973-3.42383,4.94922-4.36523
      c5.30908-2.65381,11.24756-4.02344,17.09619-5.22119c7.83008-1.54102,15.65723-3.08203,23.48633-4.79346
      C441.08594,369.1377,445.49512,366.99756,446.21484,362.63232z M412.74023,341.06299c2.88086,0,5.49023,1.36914,7.74023,3.08105
      c-1.80078,1.54053-4.13965,2.99609-6.57031,2.48193c-1.61914-0.59863-2.96875-1.71143-3.95801-3.08105
      C409.95215,342.17578,411.3916,341.06299,412.74023,341.06299z"/>
    <path class="sa-i-0" d="M239.34229,345.08545c2.06982-1.28369,3.14941-4.19385,5.84912-4.36572
      c1.25977-0.3418,3.14941-0.25635,3.50928-1.88281c0.62988-1.45508-0.18018-2.82471-1.16943-3.85156
      c-1.89014-1.96875-4.0498-4.45117-7.10889-4.10791c-1.16992-0.08643-1.97949,1.02637-2.06982,2.05371
      c-0.35938,2.31104,0.54004,4.53662,1.25977,6.76221c-2.69971,2.73877-5.66895,5.39209-9.35791,6.59082
      c-1.97998,0.59863-4.0498,1.36914-5.30908,3.1665C230.07422,350.13574,235.20361,347.91016,239.34229,345.08545z
      M246.09131,334.72852c0.54004,1.19824,0.81006,2.48242,0.98975,3.76611c-0.98975,0.08594-1.97998,0.25684-2.96924,0.34229
      C244.65186,337.38184,244.83154,335.67041,246.09131,334.72852z M242.94141,333.87256
      c-0.44971,1.19824-0.98926,2.48242-1.5293,3.68066c-0.81006-1.28369-1.16992-2.82471-1.3501-4.27979
      C241.05225,333.44434,242.04199,333.61572,242.94141,333.87256z"/>
    <path class="sa-i-0" d="M379.44727,337.55322c-0.54004,2.73926,0.98975,6.07715,3.86914,6.59082
      c1.88965,0.42773,3.23975-1.19824,4.49902-2.31152c0.62988,0.51367,1.25977,1.11328,1.97998,1.62646
      c2.33984,1.36963,6.2085-0.1709,5.93896-3.1665c-0.27002-2.91064-2.42969-5.22168-2.42969-8.2168
      c-0.71924,0.42725-1.61963,0.85547-2.15967,1.54004c0.09033,2.31104,1.62012,4.62207,0.54004,6.93359
      c-0.99023-0.68506-2.24951-1.28418-2.78955-2.48242c-0.53955-1.71191-0.4502-3.59473-0.53955-5.39258
      c-0.81006,0.59912-2.24951,0.771-2.42969,1.96875c-0.36035,2.2251-0.54004,4.45068-1.25977,6.50537
      c-1.08008-0.59912-2.60986-1.02734-2.78955-2.39697c-0.18018-1.8833,0.44971-3.59473,0.71924-5.39209
      C381.24756,334.47217,379.7168,335.67041,379.44727,337.55322z"/>
    <path class="sa-i-0" d="M247.53125,344.57178c-5.30957,2.65381-10.52881,5.56396-15.74756,8.73096
      c-1.43945,0.85547-2.4292,2.2251-3.14941,3.68018c1.16992,0.17139,2.51953,0.34277,3.59912-0.34229
      c4.94922-2.65283,9.98828-4.96436,15.02734-7.36084c2.15967-0.94141,4.40967-1.54102,6.29883-2.99561
      c1.26025-1.02734,0.4502-3.50977-1.16943-3.42432C250.68066,343.03125,249.06055,343.88721,247.53125,344.57178z"/>
    <path class="sa-i-0" d="M426.77832,357.66797c3.23926,0.17139,6.56934,0.17139,9.53906-1.28418
      c1.7998-0.85596,2.96875-2.65283,4.04883-4.2793c0.71973-0.08594,1.53027-0.17139,2.33984-0.25684
      c-1.53027-2.05469-1.7998-4.62207-2.16016-7.10449c-1.43945,0.51367-3.41895,0.77051-3.77832,2.48242
      c-0.18164,2.0542,1.43945,4.36523-0.09082,6.1626C434.42773,356.46973,430.19824,356.72656,426.77832,357.66797z"/>
    <path class="sa-i-0" d="M507.94434,348.50928c-1.16992-0.1709-2.79004-0.1709-3.24023,1.19873
      c-0.80957,2.56689,0.27051,5.13525,0.99121,7.53174c-2.7002,2.65381-5.5791,5.39258-9.26855,6.59082
      c-2.07031,0.59961-4.13965,1.36914-5.39941,3.16748c6.6582,0.68457,13.31738-2.48291,17.63574-7.44727
      c1.44043-1.71143,4.0498-0.94141,5.66992-2.2251c1.0791-1.36963,0.71973-3.33789-0.4502-4.45117
      C512.26367,351.07715,510.46289,348.93701,507.94434,348.50928z M507.49414,355.271
      c-0.54004-1.45459-0.89941-2.99609-1.08008-4.53613c0.81055,0.25684,1.7998,0.51367,2.69922,0.85596
      C508.75391,352.87451,508.125,354.07275,507.49414,355.271z M510.37402,356.38379c0.35938-1.28369,0.71875-2.56738,1.25977-3.76563
      c0.17969,0,0.53906-0.08594,0.71973-0.08594c0.54004,1.1123,0.7207,2.31104,0.98926,3.50928
      C512.35352,356.12744,511.36328,356.29834,510.37402,356.38379z"/>
    <path class="sa-i-0" d="M330.49609,359.55078c0.99023,1.45508,1.43994,3.08154,2.06982,4.70801
      c0.80957-2.65381,1.34961-5.39307,2.42969-7.96045c0.27002-0.59912,0.53906-1.62598,1.43945-1.36914
      c0.98975,0.25684,1.88965,0.94141,2.78906,1.45459c0.4502-0.85596,1.62061-1.71143,1.08008-2.82422
      c-1.08008-1.96875-4.13916-2.82471-5.84912-1.19824c-0.98975,1.1123-1.34961,2.65283-1.88916,4.02246
      c-1.44043-1.36963-2.69971-3.25244-4.76953-3.50928c-1.43896-0.25635-2.78906,1.96875-1.52979,2.99609
      C327.61621,357.1543,329.4165,358.01025,330.49609,359.55078z"/>
    <path class="sa-i-0" d="M233.04395,374.61572c1.5293-0.94189,2.15918-2.73877,2.69922-4.36523
      c-2.06934,0.42725-4.13916,0.77051-6.20898,0.85547c0.09033-1.54053,0.09033-3.16699,0.98975-4.53613
      c1.16992-1.88281,2.42969-3.76611,3.23975-5.90625c-3.05957,1.45508-5.75879,3.68066-7.46924,6.59082
      c-1.34961,2.39697-1.16895,5.73486,0.81006,7.87451C228.9043,376.58398,231.3335,375.72852,233.04395,374.61572z"/>
    <path class="sa-i-0" d="M374.58789,380.60742c-1.34961-1.11279-3.1499-0.42822-4.6792-0.17139
      c0,0.68457,0.09033,1.36963,0.18018,1.8833c-1.34961,0.85547-3.23926,1.71143-3.59912,3.50928
      c-0.36035,1.62598,0.71924,2.99512,1.43896,4.36475c1.17041,1.8833,1.53027,4.19482,1.97998,6.33447
      c0.63037-1.11328,1.71045-2.22559,1.61963-3.59521c-0.35938-2.13965-2.06934-3.9375-2.33887-6.1626
      c1.61914-1.45459,4.04883-0.85596,5.93848-1.88281C376.3877,383.68896,375.93799,381.46289,374.58789,380.60742z"/>
    <path class="sa-i-0" d="M438.92676,383.08936c-0.81055,1.79785-0.99023,4.19434,0.35938,5.73438
      c1.16992,1.36963,4.41016,1.02783,4.22949-1.19824c-0.62988-1.36865-1.7998-2.31006-2.51953-3.59473
      c0.17969-1.62598,1.25977-3.08105,1.88965-4.53613C441.35547,380.43604,439.64746,381.2915,438.92676,383.08936z"/>
    <path class="sa-i-0" d="M417.78027,388.82373c-0.4502,1.71289,1.25977,2.91064,2.60938,3.33887
      c2.79004,0.77002,6.0293,0.77002,8.36914-1.11279c1.97949-1.62646,3.86914-3.33838,5.84863-5.05029
      c-2.51953,0.51367-4.85938,1.62598-7.1084,2.82422c-1.70996,1.02783-3.78027,0.771-5.75879,0.68506
      c0-0.3418-0.09082-1.02686-0.17969-1.36914c2.78809-2.05469,6.56836-2.91064,8.1875-6.24854
      c-2.24902-1.28369-4.94922-2.14014-7.46777-1.28369c-1.70996,0.34229-2.61035,2.05371-3.60059,3.42334
      c1.25977,0.08545,2.42969,0.17139,3.68945,0.25684C420.66016,385.57178,418.5,386.68408,417.78027,388.82373z"/>
    <path class="sa-i-0" d="M493.00586,390.4502c0.99023,1.79785,0.99023,3.85205,1.44043,5.8208
      c1.35059-2.82471,1.44043-6.24854-0.17969-8.9873c-1.08008-1.96826-3.14941-3.93799-5.5791-3.50928
      c-1.26074,0.17139-2.07031,1.88281-1.16992,2.90967C489.1377,388.31055,491.65723,388.65332,493.00586,390.4502z"/>
    <path class="sa-i-0" d="M535.20898,388.56689c1.53027-0.5127,2.51953-1.71143,3.3291-2.99512
      c-3.59961-0.34277-6.92871,1.19824-10.25781,2.39648c-5.39941,2.14014-10.79785,4.19434-15.83691,7.104
      c0.17969,0.51367,0.4502,1.02734,0.63086,1.54102c1.88867-0.42773,3.68848-1.02734,5.39746-1.79785
      C523.96191,392.50488,529.62988,390.62158,535.20898,388.56689z"/>
    <path class="sa-i-0" d="M517.4043,497.91797v-0.00195h-27.71484v-2.62793c1.25195-1.08008,2.9502-2.5498,2.9502-3.39844
      c0-1.46875-3.48535-4.48242-4.29102-4.48242c-0.89355,0-4.29004,3.08984-4.29004,4.48242
      c0,0.92676,1.51855,2.24023,2.77051,3.32324l0.08887,0.0752v2.70605H268.32715c7.77832,7.72949,19.31152,7.65137,42.4668,7.88379
      c20.65186,0.30762,20.5625,0.30762,176.03516,0v2.78125c-1.25195,1.08301-2.77051,2.5498-2.77051,3.40039
      c0,1.46875,3.30664,4.4834,4.29004,4.4834c0.625,0,2.68262-1.70117,3.75488-3.16797h9.56641
      c0.62598,1.08008,2.05664,1.69922,3.75488,1.69922c1.69922,0,3.12891-0.69531,3.75488-1.69922h11.44434
      c1.42969,1.62109,3.39648,1.69922,4.55957,1.69922c4.37988-0.1543,5.36328-2.39648,5.36328-10.125
      C530.45801,498.45898,526.07617,497.76172,517.4043,497.91797z M519.37207,510.97656h-10.19238
      c-0.625-1.08008-2.05566-1.77637-3.75488-1.77637c-1.69824,0-3.12891,0.69629-3.75488,1.77637h-9.3877
      c-0.53613-0.77246-1.60938-1.77637-2.50195-2.39551v-2.78125h29.23438h0.44727
      C518.9248,507.80859,518.83594,509.58594,519.37207,510.97656z"/>
  </g>
  </svg>
</template>

<script>
export default {
  name: "saudiArabiaIcon"
}
</script>

<style>
  .sa-i-0{fill:#FFFFFF;}
  .sa-i-1{fill:#006C35;}
</style>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06247 1.2061">
    <defs>
      <clipPath id="clip-path" transform="translate(0.44382 0.01564)">
        <circle class="hk-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.44382 0.01564)">
        <rect class="hk-i-1" x="-0.44382" y="-0.01564" width="2.06247" height="1.2061"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="hk-i-2">
          <g class="hk-i-3">
            <rect class="hk-i-4" width="2.06247" height="1.2061"/>
            <g class="hk-i-3">
              <path class="hk-i-5" d="M.58778.58925A.18466.18466,0,0,1,.6699.23438.06306.06306,0,0,0,.645.32383.09255.09255,0,0,1,.60538.45654a.07917.07917,0,0,0-.0176.13271" transform="translate(0.44382 0.01564)"/>
              <polygon class="hk-i-4" points="1.013 0.411 1.038 0.361 0.988 0.39 1.046 0.394 1.003 0.358 1.013 0.411"/>
              <path class="hk-i-4" d="M.58778.58558A.13506.13506,0,0,1,.55185.39935L.54452.39568A.14243.14243,0,0,0,.58411.58925Z" transform="translate(0.44382 0.01564)"/>
              <path class="hk-i-5" d="M.58778.58925a.18088.18088,0,0,1,.3578-.0286A.05494.05494,0,0,0,.85613.56432a.09848.09848,0,0,1-.14,0A.07589.07589,0,0,0,.58778.58925" transform="translate(0.44382 0.01564)"/>
              <polygon class="hk-i-4" points="1.207 0.529 1.264 0.533 1.221 0.497 1.232 0.551 1.257 0.501 1.207 0.529"/>
              <path class="hk-i-4" d="M.59145.58925A.13174.13174,0,0,1,.75574.49613V.4888A.144.144,0,0,0,.58411.58558Z" transform="translate(0.44382 0.01564)"/>
              <path class="hk-i-5" d="M.58778.58925A.18587.18587,0,0,1,.3297.61051.1822.1822,0,0,1,.27984.39935.05744.05744,0,0,0,.35462.44921.09709.09709,0,0,1,.46979.52839a.078.078,0,0,0,.118.06086" transform="translate(0.44382 0.01564)"/>
              <polygon class="hk-i-4" points="0.845 0.558 0.802 0.522 0.813 0.576 0.838 0.526 0.788 0.554 0.845 0.558"/>
              <path class="hk-i-4" d="M.58411.58558A.1338.1338,0,0,1,.39788.56432L.39055.56725a.14275.14275,0,0,0,.19723.022Z" transform="translate(0.44382 0.01564)"/>
              <path class="hk-i-5" d="M.58778.58925a.18093.18093,0,0,1,.22142.132.18425.18425,0,0,1-.08578.2009A.05688.05688,0,0,0,.69483.83633.09641.09641,0,0,1,.64863.70362.07624.07624,0,0,0,.58778.58925" transform="translate(0.44382 0.01564)"/>
              <polygon class="hk-i-4" points="1.157 0.748 1.167 0.801 1.192 0.752 1.142 0.78 1.2 0.784 1.157 0.748"/>
              <path class="hk-i-4" d="M.58778.58925a.138.138,0,0,1,.13931.129H.73075a.13894.13894,0,0,0-.143-.13271Z" transform="translate(0.44382 0.01564)"/>
              <path class="hk-i-5" d="M.58778.58925A.18522.18522,0,0,1,.53059.84.18444.18444,0,0,1,.3121.82167.0575.0575,0,0,0,.38322.76448.09464.09464,0,0,1,.49466.68236.07948.07948,0,0,0,.58778.58925" transform="translate(0.44382 0.01564)"/>
              <polygon class="hk-i-4" points="0.931 0.765 0.881 0.795 0.938 0.801 0.895 0.763 0.91 0.82 0.931 0.765"/>
              <path class="hk-i-4" d="M.58411.58925A.134.134,0,0,1,.50493.76081L.50859.76448A.13842.13842,0,0,0,.58778.58558Z" transform="translate(0.44382 0.01564)"/>
              <path class="hk-i-4" d="M.58778.57825c.00367,0,.00665.00366.00665.011L.58778.59584.58045.58925a.01606.01606,0,0,1,.00366-.011" transform="translate(0.44382 0.01564)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "hongKongIcon"
}
</script>

<style>
  .hk-i-1 {
    fill: none;
  }

  .hk-i-2 {
    clip-path: url(#clip-path);
  }

  .hk-i-3 {
    clip-path: url(#clip-path-2);
  }

  .hk-i-4 {
    fill: #cb1d23;
  }

  .hk-i-5 {
    fill: #fff;
  }
</style>

#spec-table {
  display: inline-grid;
  width: 100%;

  .table {
    $border: #ddd;
    $stripe: #e8e8e8;
    margin-bottom: 0;

    .table-border {
      display: inline-block;
      width: 100%;
      border: 1px solid $border;
      border-radius: 4px;
    }

    .tr {
      .td {
        padding: 8px;
        border-bottom: 1px solid $border;
        border-left: 1px solid $border;

        &:first-child {
          border-left: 0;
        }
      }

      &:last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .text-center {
      margin-bottom: 20px;
    }
  }

  #table-content {
    div.collapse[aria-expanded='false'] {
      display: block;
      height: 135px !important;
      overflow: hidden;
      -webkit-mask-image: -webkit-gradient(linear,
          0% 75%,
          0% 100%,
          from(#000000),
          to(#00000000));

      div.collapsing[aria-expanded='false'] {
        height: 135px !important;
      }
    }
  }

  .collapsing {
    height: 135px !important;
    transition: none !important;
    -webkit-mask-image: -webkit-gradient(linear,
        0% 75%,
        0% 100%,
        from(#000000),
        to(#00000000));
  }

  .heading-bordered {
    margin-bottom: 15px;

    span {
      background: #fff;
    }
  }

  .btn {
    margin-top: 10px;
  }
}

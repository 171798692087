
#products {
  .product-cards {
    margin: 0;
  }

  .back-to-top {
    margin: 0;
    position: fixed;
    bottom: 0;
    right: 20px;
    width: 70px;
    height: 70px;
    z-index: 100;
    display: none;
    text-decoration: none;
    color: rgb(249, 152, 57);
    background: transparent none;
    font-size: 70px;
    line-height: 68px;
    padding: 0 5px;

    &:hover {
      opacity: .6;
    }
  }
}

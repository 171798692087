<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06247 1.20244">
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g>
          <g>
            <rect class="gaf-1" x="0.028" width="2.042" height="0.401"/>
            <rect class="gaf-2" x="0.028" y="0.401" width="2.042" height="0.401"/>
            <rect class="gaf-1" x="0.028" y="0.802" width="2.042" height="0.401"/>
            <rect class="gaf-3" x="0.028" width="2.042" height="1.203"/>
          </g>
          <g>
            <polygon points="1.308 0 0 0 0 0.401 1.14 0.401 1.308 0"/>
            <polygon class="gaf-4" points="1.14 0.401 0 0.401 0 0.802 0.972 0.802 1.14 0.401"/>
            <polygon class="gaf-5" points="0 0.802 0 1.203 0.804 1.203 0.972 0.802 0 0.802"/>
          </g>
          <polygon class="gaf-6" points="0.773 1.203 0.831 1.203 1.313 0.001 1.255 0.001 0.773 1.203"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "germanyAndAustriaFlag"
}
</script>

<style>
  .gaf-1 { fill: #c6001e; }
  .gaf-2 { fill: #fff; }
  .gaf-3 { fill: none; }
  .gaf-4 { fill: #e1001f; }
  .gaf-5 { fill: #f6d928; }
  .gaf-6 { fill: #b5b5b6; }
</style>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06247 1.2061">
    <defs>
      <clipPath id="clip-path">
        <rect class="hk-1" width="2.06247" height="1.2061"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="hk-2">
          <rect class="hk-3" width="2.06247" height="1.2061"/>
          <g class="hk-2">
            <path class="hk-4" d="M1.0316.60488A.18465.18465,0,0,1,1.11372.25a.06308.06308,0,0,0-.02493.08945A.09254.09254,0,0,1,1.0492.47218a.07917.07917,0,0,0-.0176.1327"/>
            <polygon class="hk-3" points="1.013 0.411 1.038 0.361 0.988 0.39 1.046 0.394 1.003 0.358 1.013 0.411"/>
            <path class="hk-3" d="M1.0316.60122A.13506.13506,0,0,1,.99568.415L.98834.41132a.14242.14242,0,0,0,.0396.19356Z"/>
            <path class="hk-4" d="M1.0316.60488A.18088.18088,0,0,1,1.3894.57629.05494.05494,0,0,0,1.3.58a.09848.09848,0,0,1-.14,0A.07589.07589,0,0,0,1.0316.60488"/>
            <polygon class="hk-3" points="1.207 0.529 1.264 0.533 1.221 0.497 1.232 0.551 1.257 0.501 1.207 0.529"/>
            <path class="hk-3" d="M1.03527.60488A.13174.13174,0,0,1,1.19956.51177V.50444a.144.144,0,0,0-.17162.09678Z"/>
            <path class="hk-4" d="M1.0316.60488A.18586.18586,0,0,1,.77352.62615.1822.1822,0,0,1,.72366.415.05744.05744,0,0,0,.79845.46484.0971.0971,0,0,1,.91361.544a.078.078,0,0,0,.118.06085"/>
            <polygon class="hk-3" points="0.845 0.558 0.802 0.522 0.813 0.576 0.838 0.526 0.788 0.554 0.845 0.558"/>
            <path class="hk-3" d="M1.02794.60122A.1338.1338,0,0,1,.84171.58L.83437.58289a.14274.14274,0,0,0,.19723.022Z"/>
            <path class="hk-4" d="M1.0316.60488a.181.181,0,0,1,.22143.132.18422.18422,0,0,1-.08579.20089A.05687.05687,0,0,0,1.13865.852.0964.0964,0,0,1,1.09246.71926.07625.07625,0,0,0,1.0316.60488"/>
            <polygon class="hk-3" points="1.157 0.748 1.167 0.801 1.192 0.752 1.142 0.78 1.2 0.784 1.157 0.748"/>
            <path class="hk-3" d="M1.0316.60488a.138.138,0,0,1,.13931.12905h.00367a.13893.13893,0,0,0-.143-.13271Z"/>
            <path class="hk-4" d="M1.0316.60488A.18209.18209,0,0,1,.75592.83731.0575.0575,0,0,0,.827.78012.09465.09465,0,0,1,.93849.698.07948.07948,0,0,0,1.0316.60488"/>
            <polygon class="hk-3" points="0.931 0.765 0.881 0.795 0.938 0.801 0.895 0.763 0.91 0.82 0.931 0.765"/>
            <path class="hk-3" d="M1.02794.60488A.13407.13407,0,0,1,.94875.77645L.95242.78012A.13841.13841,0,0,0,1.0316.60122Z"/>
            <path class="hk-3" d="M1.0316.59389c.00367,0,.00666.00366.00666.011L1.0316.61148,1.02427.60488a.016.016,0,0,1,.00367-.011"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "hongKongFlag"
}
</script>

<style type="text/css">
  .hk-1 {
    fill: none;
  }

  .hk-3 {
    fill: #cb1d23;
  }

  .hk-4 {
    fill: #fff;
  }
</style>
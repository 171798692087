<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06116 1.20241">
    <defs>
      <clipPath id="clip-path" transform="translate(0.44317 0.01379)">
        <circle class="un-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.44317 0.01379)">
        <rect class="un-i-1" x="-0.44317" y="-0.01379" width="2.06116" height="1.20241"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="un-i-2">
          <g class="un-i-3">
            <rect class="un-i-4" width="2.06116" height="1.20241"/>
            <polygon class="un-i-5" points="1.03 0.133 1.016 0.179 0.966 0.179 1.005 0.207 0.991 0.254 1.03 0.226 1.07 0.254 1.055 0.207 1.095 0.179 1.045 0.179 1.03 0.133"/>
            <polygon class="un-i-5" points="1.045 0.98 1.03 0.933 1.016 0.98 0.966 0.98 1.005 1.009 0.991 1.055 1.03 1.027 1.07 1.055 1.055 1.009 1.095 0.98 1.045 0.98"/>
            <polygon class="un-i-5" points="0.629 0.626 0.669 0.654 0.655 0.608 0.69 0.58 0.644 0.58 0.629 0.533 0.615 0.58 0.565 0.58 0.604 0.608 0.591 0.654 0.629 0.626"/>
            <polygon class="un-i-5" points="0.83 0.186 0.816 0.232 0.766 0.232 0.805 0.261 0.791 0.308 0.83 0.279 0.87 0.308 0.855 0.261 0.895 0.232 0.844 0.232 0.83 0.186"/>
            <polygon class="un-i-5" points="0.684 0.333 0.669 0.379 0.619 0.379 0.658 0.408 0.644 0.454 0.684 0.426 0.722 0.454 0.709 0.408 0.748 0.379 0.698 0.379 0.684 0.333"/>
            <polygon class="un-i-5" points="0.698 0.78 0.684 0.733 0.669 0.78 0.619 0.78 0.658 0.808 0.644 0.855 0.684 0.827 0.722 0.855 0.709 0.808 0.748 0.78 0.698 0.78"/>
            <polygon class="un-i-5" points="0.844 0.927 0.83 0.88 0.816 0.927 0.766 0.927 0.805 0.955 0.791 1.002 0.83 0.973 0.87 1.002 0.855 0.955 0.895 0.927 0.844 0.927"/>
            <polygon class="un-i-5" points="1.495 0.58 1.446 0.58 1.431 0.533 1.417 0.58 1.367 0.58 1.406 0.608 1.391 0.654 1.431 0.626 1.471 0.654 1.456 0.608 1.495 0.58"/>
            <polygon class="un-i-5" points="1.231 0.186 1.216 0.232 1.167 0.232 1.206 0.261 1.191 0.308 1.231 0.279 1.27 0.308 1.256 0.261 1.295 0.232 1.245 0.232 1.231 0.186"/>
            <polygon class="un-i-5" points="1.353 0.408 1.338 0.454 1.378 0.426 1.417 0.454 1.402 0.408 1.442 0.379 1.391 0.379 1.378 0.333 1.363 0.379 1.313 0.379 1.353 0.408"/>
            <polygon class="un-i-5" points="1.391 0.78 1.378 0.733 1.363 0.78 1.313 0.78 1.353 0.808 1.338 0.855 1.378 0.827 1.417 0.855 1.402 0.808 1.442 0.78 1.391 0.78"/>
            <polygon class="un-i-5" points="1.245 0.927 1.231 0.88 1.216 0.927 1.167 0.927 1.206 0.955 1.191 1.002 1.231 0.973 1.27 1.002 1.256 0.955 1.295 0.927 1.245 0.927"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "unIcon"
}
</script>

<style>
  .un-i-1 {
    fill: none;
  }

  .un-i-2 {
    clip-path: url(#clip-path);
  }

  .un-i-3 {
    clip-path: url(#clip-path-2);
  }

  .un-i-4 {
    fill: #213095;
  }

  .un-i-5 {
    fill: #f5cb27;
  }
</style>
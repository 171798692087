<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-.40 0 2.06453 1.56969">
    <defs>
      <clipPath id="clip-path" transform="translate(0.04223 0.01462)">
        <circle class="tai-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.04223 0.01462)">
        <rect class="tai-i-1" x="-0.04223" y="-0.01462" width="2.06453" height="1.20406"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="tai-i-2">
          <g id="Taiwan">
            <g>
              <path d="M.75,1.43319H.71031V1.41924h.097v.01395H.76758v.11527H.75Z" transform="translate(0.04223 0.01462)"/>
              <path d="M.87183,1.52644c0,.00734,0,.022.00368.022H.85715V1.54112h0a.0361.0361,0,0,1-.02863.01395.02814.02814,0,0,1-.02858-.02863c0-.0213.02124-.036.05721-.036h0a.0204.0204,0,0,0-.02129-.02129c-.011,0-.01836,0-.025.00734L.80728,1.46183c.00734,0,.01757-.00661.02858-.00661a.03333.03333,0,0,1,.036.03524Zm-.01468-.0213c-.01762,0-.03965,0-.03965.0213a.0158.0158,0,0,0,.01469.01468c.01468,0,.02129-.00734.02129-.01468l.00367-.00735Z" transform="translate(0.04223 0.01462)"/>
              <path d="M.91809,1.43319c0,.00735-.00367.00735-.01028.00735-.00367,0-.011,0-.011-.00735a.01622.01622,0,0,1,.011-.01395C.91442,1.41924.91809,1.42659.91809,1.43319Zm-.01762.11527V1.45522H.91809v.09324Z" transform="translate(0.04223 0.01462)"/>
              <path d="M.95039,1.45522l.01469.04992c.00372.00735.00372.0213.0066.02864h0c.00367-.00734.00367-.02129.00735-.02864l.01468-.04992h.014l.01835.04992a.101.101,0,0,0,.00661.02864h0A.08264.08264,0,0,1,1.04,1.50514l.01469-.04992H1.0686L1.04,1.54846H1.026l-.01468-.04332c-.00367-.00734-.00734-.02129-.011-.02863h0a.08111.08111,0,0,1-.00661.02863L.979,1.54846H.96146l-.025-.09324Z" transform="translate(0.04223 0.01462)"/>
              <path d="M1.15156,1.52644v.022H1.13688V1.54112h0a.04277.04277,0,0,1-.02858.01395.02617.02617,0,0,1-.02869-.02863c0-.0213.01762-.036.05359-.036h0c0-.00661,0-.02129-.02129-.02129a.02483.02483,0,0,0-.02129.00734L1.087,1.46183a.28852.28852,0,0,1,.02869-.00661.03331.03331,0,0,1,.03592.03524Zm-.01468-.0213c-.02124,0-.03965,0-.03965.0213a.01579.01579,0,0,0,.01468.01468.02457.02457,0,0,0,.02129-.01468c0-.00735.00368-.00735.00368-.00735Z" transform="translate(0.04223 0.01462)"/>
              <path d="M1.18019,1.48385v-.022l.01395-.00661v.02129h0c.00367-.01468.01469-.02129.03231-.02129.011,0,.0323.00661.0323.04258v.05066H1.24113V1.4978c0-.014-.00367-.02863-.022-.02863a.02458.02458,0,0,0-.02129.01468c0,.00661-.00367.00661-.00367.014v.05066H1.18019Z" transform="translate(0.04223 0.01462)"/>
            </g>
            <g class="tai-i-3">
              <rect class="tai-i-4" width="2.06453" height="1.20406"/>
              <rect class="tai-i-5" width="0.90305" height="0.60203"/>
              <path class="tai-i-6" d="M.46656.2864.4093.51546.34836.2864.4093.06394m0,.28706L.63469.2864.4093.22913.18322.2864" transform="translate(0.04223 0.01462)"/>
              <path class="tai-i-6" d="M.45922.315.29476.47949.3557.25776.52016.09257M.377.33632l.2254.06461L.43793.23574.2118.17186" transform="translate(0.04223 0.01462)"/>
              <path class="tai-i-6" d="M.43793.33632.2118.40093.377.23574.60239.17186M.3557.315.52016.47949.45922.25776.29476.09257" transform="translate(0.04223 0.01462)"/>
              <g class="tai-i-3">
                <path class="tai-i-5" d="M.4093.15718A.12922.12922,0,1,1,.28008.2864.12773.12773,0,0,1,.4093.15718" transform="translate(0.04223 0.01462)"/>
                <path class="tai-i-6" d="M.4093.17186A.11208.11208,0,0,1,.52016.2864a.11271.11271,0,1,1-.2254,0A.11288.11288,0,0,1,.4093.17186" transform="translate(0.04223 0.01462)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "taiwanIcon"
}
</script>

<style>
  .tai-i-1 {
    fill: none;
  }

  .tai-i-2 {
    clip-path: url(#clip-path);
  }

  .tai-i-3 {
    clip-path: url(#clip-path-2);
  }

  .tai-i-4 {
    fill: #d10e2f;
  }

  .tai-i-5 {
    fill: #1c2666;
  }

  .tai-i-6 {
    fill: #fff;
  }
</style>

// IMG PATH
$imgDir: '../../../img/';

.bookmark-project {
  position: absolute;
  z-index: 3;
  left: 20px;
  top: 0;
  cursor: pointer;
  width: 29px;
  height: 55px;
  opacity: .4;
  transition: visibility .3s, opacity .3s;
  background: url($imgDir + 'arrow-bookmark.svg') -108px 0 no-repeat;
  background-size: 165px auto;


  &:hover .bookmark-project {
    visibility: visible;
    opacity: .6;
    background: url($imgDir + 'arrow-bookmark.svg') -108px 0 no-repeat;
    background-size: 165px auto;
  }
}

.bookmark-project.project-highlight {
  visibility: visible;
  opacity: 1;
  background: url($imgDir + 'arrow-bookmark.svg') -138px 0 no-repeat;
  background-size: 166px auto;
  left: 21px;
}


$alternate: #f69422;
$white: #fafafa;
$highlight: #3a3a39;
$font: #393939;

#careers-content {
  .jumbotron {
    position: relative;
    overflow: hidden;
    background-color: #e6e8e8;
    margin: 0;

    &-background {
      position: absolute;
      left: 100%;
      bottom: 50%;
      margin: auto;
      overflow: hidden;
      height: 3000px;
      width: 3000px;
      max-width: 3000px;
      max-height: 3000px;
      background-image: radial-gradient($white, $highlight);
      transform: translateX(-85%) rotate(15deg);
      z-index: 1;
    }

    .container {
      position: relative;
      z-index: 2;

      h1 {
        position: relative;
        color: $font;
        font: 800 350%/1.4 "Open Sans", sans-serif;
        width: max-content;
        display: inline;
      }

      h1::before {
        content: "";
        position: absolute;
        width: 110%;
        height: 100%;
        background: linear-gradient(to bottom, transparent 60%, $alternate 60%);
        background-repeat: no-repeat;
        z-index: -1;
        left: -5%
      }

      h1::selection {
        -webkit-text-stroke: 4px $white;
        color: transparent;
      }

      p {
        margin: 3% 0;
      }
    }

    .hero-image-container {
      padding: 0 1%;
      border-left: 10px solid $alternate;

      img {
        max-height: 100%;
        border-radius: 4px;
        margin: 0 auto;
        width: 100%;
      }
    }
  }

  #careers-detail {
    padding-bottom: 3%;

    .section {
      padding: 3% 0;

      .row {
        margin-top: 1%;
      }

      .title {
        font-size: 3rem;
        font-weight: bold;
      }

      .title span {
        color: $alternate;
      }
    }

    #benefits,
    #values {
      .item-group {
        display: flex;
      }

      .item {
        display: flex;

        .panel-default {
          width: 100%;

          .panel-body {
            blockquote {
              font-size: 1.5rem;
              border-color: $alternate;

              footer {
                background-color: transparent;
              }
            }

            h4 {
              font-weight: 700;
              letter-spacing: 1px;
              margin: auto;
              padding-bottom: 10px;

              &::before {
                background-color: $highlight;
                content: '';
                display: block;
                height: 3px;
                width: 75px;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .jumbotron {
      h1::before {
        display: none;
      }
    }

    #careers-detail {

      #benefits,
      #values {

        .item-group {
          display: inherit;
        }
      }
    }
  }
}

<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06907 1.20317">
    <defs>
      <clipPath id="clip-path" transform="translate(0.44712)">
        <circle class="ger-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.44712)">
        <rect class="ger-i-1" x="-0.44712" width="2.06907" height="1.20317"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="ger-i-2">
          <g class="ger-i-3">
            <rect width="2.06907" height="0.40106"/>
            <rect class="ger-i-4" y="0.40106" width="2.06907" height="0.40106"/>
            <rect class="ger-i-5" y="0.80211" width="2.06907" height="0.40106"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "germanyIcon"
}
</script>

<style>
  .ger-i-1 {
    fill: none;
  }

  .ger-i-2 {
    clip-path: url(#clip-path);
  }

  .ger-i-3 {
    clip-path: url(#clip-path-2);
  }

  .ger-i-4 {
    fill: #e1001f;
  }

  .ger-i-5 {
    fill: #f6d928;
  }
</style>
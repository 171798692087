<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="207.34424 273.91113 385.30615 252.18848">
  <g>
    <path class="get-order-fast" d="M326.54492,426.7998l-23.35596-0.08887l-95.84473-4.7334l149.95557,0.54492L326.54492,426.7998z"/>
    <path class="get-order-fast" d="M371.22266,354.3999l11.55469-2.65527c4.25586-2.93359,8.66699-5.6001,13.22266-7.95557l-152.3335,0.17773
      l110.30029,9.75537L371.22266,354.3999z"/>
    <path class="get-order-fast" d="M586.42188,370.62207c-4.65527-12.68896-11.66602-24.04443-20.82227-33.82227L542.15527,334.5l23.36719-0.1001
      l3.24414-4.12207c1.08887-1.25586,1.59961-2.94434,1.41113-4.64453c-0.17773-1.55566-0.92188-3-2.04492-3.9668l-0.05469-0.04443
      l-0.05566-0.04443l-17.35547-12.64453c-0.87793-0.73291-2.01172-1.12207-3.28906-1.12207
      c-1.97754,0-4.06738,0.9668-5.44434,2.52246l-0.04492,0.05518l-0.04395,0.05566l-4.28906,5.44434
      c-7.18945-3.57764-15.2002-6.32227-23.35547-8.01074l0.59961-6.8667l-21.25586-2.08887l28.10059-0.12256
      c3.12207-0.3667,5.59961-2.83301,5.87793-6.06641l1.11035-12.68896c0.31152-3.5-2.09961-6.1333-5.59961-6.1333H322.64453
      l136.04395,11.91113l-0.43262,5.7334l-46.0332,7.31104l64.55469-0.12207l-7.15527,2.31104l-0.13281,1.54443l-0.45605,5.2666
      c-10.64453,2.18896-21.02246,5.92236-30.7998,11c-4.55566,2.3667-8.9668,5.0332-13.22266,7.95557
      c-7.63281,5.25537-14.75488,11.38867-21.13281,18.31104c-8.93359,9.67773-16.18945,20.54443-21.57813,32.30029
      c-5.57715,12.17773-8.97754,24.9668-10.12207,38.04395c-1.2998,14.85547,0.34473,29.2666,4.90039,42.83398
      c4.39941,13.09961,11.2998,24.86621,20.52148,34.97754c0.28906,0.31055,0.56738,0.61133,0.84473,0.92188l-7.33301-1.59961
      l-16.91113-0.10059l-62.14453,7.33398L404.5,500.42188c1.82227,1.7334,3.62207,3.31152,5.5,4.77832
      c0.74414,0.57715,2.27734,1.68848,2.27734,1.68848c0.77832,0.54492,2.36719,1.61133,2.36719,1.61133l-6.77832,0.45508
      l-64.07715,4.66699l84.98828,1.05566l1.01172,0.07813l31.74414,3.42188l-61.07813,0.91113l-114.77734,6.85547l237.93359,0.15527
      l-23.5-3.97754c5.42188-1.35547,10.77734-3.09961,16.05566-5.25586c13.58887-5.54395,26.04395-13.47754,37.0332-23.57715
      c10.98828-10.10059,19.94434-21.86719,26.63281-34.97754c6.92285-13.56738,11.08887-27.97852,12.38965-42.83398
      C593.59961,399.95508,591.64453,384.85547,586.42188,370.62207z M574.82227,415.47754
      c-4.4668,51.06738-49.65527,92.61133-100.72266,92.61133c-51.06641,0-88.97754-41.54395-84.51074-92.61133
      c4.4668-51.06641,49.65527-92.61084,100.72266-92.61084C541.37793,322.8667,579.28906,364.41113,574.82227,415.47754z"/>
    <polygon class="get-order-fast" points="491.32227,327.01123 489.59961,327.01123 488.28906,342.1001 490,342.1001 	"/>
    <polygon class="get-order-fast" points="444.05566,339.4668 450.45508,352.5332 452.01074,351.67773 445.61133,338.61133 	"/>
    <polygon class="get-order-fast" points="408.94434,372.14453 421.34473,379.68896 422.33301,378.2002 409.92188,370.65576 	"/>
    <polygon class="get-order-fast" points="408.92188,414.45508 393.83301,414.45508 393.67773,416.16699 408.7666,416.16699 	"/>
    <polygon class="get-order-fast" points="401.16699,459.16699 401.90039,460.65527 415.62207,453.11133 414.90039,451.62207 	"/>
    <polygon class="get-order-fast" points="430.13379,491.64453 431.54492,492.5 440.2334,479.43359 438.82227,478.57813 	"/>
    <polygon class="get-order-fast" points="473.41113,504.28906 475.12207,504.28906 476.44434,489.2002 474.7334,489.2002 	"/>
    <polygon class="get-order-fast" points="512.35547,480.01074 518.75586,493.06641 520.32227,492.21094 513.92188,479.14453 	"/>
    <polygon class="get-order-fast" points="542.16699,453.5332 554.56641,461.07813 555.55566,459.59961 543.14453,452.05566 	"/>
    <polygon class="get-order-fast" points="555.4668,416.85547 570.55566,416.85547 570.7002,415.13281 555.61133,415.13281 	"/>
    <polygon class="get-order-fast" points="549.48926,380.05566 563.21094,372.51123 562.48926,371.02197 548.75586,378.56641 	"/>
    <polygon class="get-order-fast" points="524.0332,351.81104 525.44434,352.6665 534.12207,339.6001 532.72266,338.74463 	"/>
    <path class="get-order-fast" d="M485.56641,404.51074l2.57813-29.48877h5.14453l-6.37793-13.37744l-8.71094,13.37744h5.14453
      l-2.57813,29.48877c-5.2334,1.0332-9.42188,5.83398-9.87793,10.97852c-0.54395,6.16602,4.15625,11.31055,10.32227,11.31055
      c6.16699,0,11.7666-5.14453,12.2998-11.31055C493.97754,410.33301,490.58887,405.87793,485.56641,404.51074z"/>
  </g>
  </svg>
</template>

<script>
export default {
  name: "getOrderFast"
}
</script>

<style>
  .get-order-fast{fill:#333232;}
</style>
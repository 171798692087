<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-.40 0 2.06521 1.61301">
    <defs>
      <clipPath id="clip-path" transform="translate(0.07617 0.01499)">
        <circle class="tur-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.07617 0.01499)">
        <rect class="tur-i-1" x="-0.07617" y="-0.01499" width="2.06521" height="1.2048"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="tur-i-2">
          <g id="Turkey">
            <g>
              <path d="M.727,1.43356H.68738V1.41888H.78424v.01468H.74533v.12188H.727Z" transform="translate(0.07617 0.01499)"/>
              <path d="M.8672,1.5268v.02864H.85252v-.022h0c-.00734.00734-.01469.022-.03231.022-.014,0-.0323-.00735-.0323-.04332V1.46219H.80626v.04993c0,.01468.00294.02863.0213.02863a.02541.02541,0,0,0,.02134-.014V1.46219H.8672Z" transform="translate(0.07617 0.01499)"/>
              <path d="M.89583,1.49083V1.46219h.014v.01395h0a.02672.02672,0,0,1,.025-.02129H.94209v.02129L.93842,1.4688a.02834.02834,0,0,0-.025.02937v.05727H.89583Z" transform="translate(0.07617 0.01499)"/>
              <path d="M.97439,1.50478H.97806c0-.00661.00367-.00661.00735-.01395l.025-.02864h.02124l-.03592.036.04326.05727H1.01776L.98541,1.50478l-.011.01468v.036H.95971V1.41888H.97439Z" transform="translate(0.07617 0.01499)"/>
              <path d="M1.06029,1.50478c0,.02863.01469.036.02864.036.01468,0,.022,0,.02863-.00734v.01468a.09918.09918,0,0,1-.02863.00735.04635.04635,0,0,1-.04626-.05066.04514.04514,0,0,1,.04258-.04993.03994.03994,0,0,1,.03965.04332v.00661Zm.047-.00661c0-.01468-.00367-.02937-.022-.02937-.01762,0-.025.01469-.025.02937Z" transform="translate(0.07617 0.01499)"/>
              <path d="M1.15353,1.46219l.01841.04993a.169.169,0,0,0,.00656.02129h0a.05271.05271,0,0,1,.00734-.02129l.01835-.04993h.01762l-.025.06461a.20842.20842,0,0,1-.03225.05727c-.01107.00661-.01841.00661-.02135.01395l-.00367-.01395c.00367-.00734.01034-.00734.01395-.01468a.056.056,0,0,0,.01469-.014V1.54809h0l-.036-.0859Z" transform="translate(0.07617 0.01499)"/>
            </g>
            <g class="tur-i-3">
              <rect class="tur-i-4" width="2.06521" height="1.2048"/>
              <g class="tur-i-3">
                <path class="tur-i-5" d="M.52654.286a.301.301,0,1,1-.301.301.30092.30092,0,0,1,.301-.301" transform="translate(0.07617 0.01499)"/>
                <path class="tur-i-4" d="M.60143.3433A.241.241,0,0,1,.84156.58705.24013.24013,0,1,1,.60143.3433" transform="translate(0.07617 0.01499)"/>
                <polygon class="tur-i-5" points="0.857 0.602 1.001 0.653 1.026 0.581 0.857 0.602"/>
                <polygon class="tur-i-5" points="0.857 0.602 1.001 0.559 1.026 0.631 0.857 0.602"/>
                <polygon class="tur-i-5" points="0.961 0.459 0.961 0.609 1.036 0.609 0.961 0.459"/>
                <polygon class="tur-i-5" points="0.961 0.459 1.051 0.581 0.99 0.624 0.961 0.459"/>
                <polygon class="tur-i-5" points="0.961 0.746 1.051 0.624 0.99 0.581 0.961 0.746"/>
                <polygon class="tur-i-5" points="0.961 0.746 0.961 0.595 1.036 0.595 0.961 0.746"/>
                <polygon class="tur-i-5" points="1.129 0.516 0.986 0.559 1.011 0.631 1.129 0.516"/>
                <polygon class="tur-i-5" points="1.129 0.516 1.043 0.638 0.979 0.595 1.129 0.516"/>
                <polygon class="tur-i-5" points="1.129 0.688 1.043 0.566 0.979 0.617 1.129 0.688"/>
                <polygon class="tur-i-5" points="1.129 0.688 0.986 0.645 1.011 0.573 1.129 0.688"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "turkeyIcon"
}
</script>

<style>
  .tur-i-1 {
    fill: none;
  }

  .tur-i-2 {
    clip-path: url(#clip-path);
  }

  .tur-i-3 {
    clip-path: url(#clip-path-2);
  }

  .tur-i-4 {
    fill: #d00027;
  }

  .tur-i-5 {
    fill: #fff;
  }
</style>
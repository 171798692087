
.input-group {
  .form-control {
    border-radius: 3px 0 0 3px !important;
  }
}

.login-signup .modal-body .btn,
.signup-country .modal-body .btn {
  padding: 16px;

  i {
    margin: 0 !important;
  }
}

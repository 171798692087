
#applicator-map-filter {
  top: calc(var(--desktop-header-size) + var(--sidebar-padding));
  display: flex;
  flex: 1;

  @media screen and (max-width: 1024px) {
    top: calc(var(--mobile-header-size) + var(--sidebar-padding));
  }

  &-toggle-desktop {
    top: calc(var(--desktop-header-size) + var(--sidebar-padding));
    border-bottom-right-radius: var(--applicator-border-radius);
    border-top-right-radius: var(--applicator-border-radius);
    border: 1px solid #DBDBDB;
    background-color: #F9FAFC;
    justify-content: center;
    align-items: center;
    border-left: none;
    padding: 0 12px;
    position: sticky;
    display: none;
    height: 40px;
    width: auto;
    z-index: 5;
    left: 0;
    margin-left: -30px;

    @media screen and (max-width: 1500px) {
      display: flex;
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }

    > i {
      margin-right: 8px;
    }

  }

  &-count {
    background-color: #3E6B85;
    padding: 5px 8px 6px 8px;
    border-radius: 4px;
    margin-left: 6px;
    font-weight: 600;
    font-size: 10px;
    line-height: 1;
    color: white;
  }

  &-form {
    flex-direction: column;
    padding: 0 !important;
    pointer-events: all;
    white-space: nowrap;
    display: flex;
    width: 100%;
    opacity: 1;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .filter-form-field {
      padding-right: var(--sidebar-padding);
      padding-bottom: 16px;
      flex-direction: row;
      display: flex;
      width: 100%;

      &:last-child {
        padding-bottom: 0;
      }

      &.column {
        flex-direction: column;
      }

      &-label {
        margin-right: calc(var(--sidebar-padding) * -1);
        align-items: flex-start;
        flex-direction: column;
        white-space: normal;
        display: flex;

        > small {
          margin-top: 4px;
          color: #828282;

          > i {
            margin-right: 2px;
          }

        }

      }

      select {
        font-weight: normal;
        padding: 0 8px;
        height: 40px;
        flex: 1;
      }

      + .filter-form-field {
        border-top: 2px solid #DBDBDB;
        padding-top: 16px;
      }

    }

    .filter-form-options {
      align-items: flex-start;
      flex-direction: column;
      margin-top: 8px;
      flex-wrap: wrap;
      display: flex;
      width: 100%;

      > .filter-form-checkbox {
        width: 50%;
      }

      &#filter-ratings {
        flex-direction: column-reverse;
        flex-wrap: nowrap;

        .fa {
          margin: 2px 2px 0 0;
          font-size: 18px;
          color: #D8D8D8;

          &.fa-star {
            color: #F69422;
          }

        }

      }

    }

    .filter-form-checkbox {
      align-items: center;
      flex-direction: row;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      height: 32px;
      margin: 0;

      .checkbox-input {
        transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
        background-color: transparent;
        border: 2px solid #CCC;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-right: 8px;
        cursor: inherit;
        display: flex;
        height: 16px;
        width: 16px;

        &::before {
          content: "✓";
          transition: opacity 200ms ease-in-out;
          font-size: 14px;
          color: white;
          opacity: 0;
        }

      }

      > input {
        visibility: hidden;
        display: none;

        &:checked + .checkbox-input {
          background-color: #3E6B85;
          border-color: #3E6B85;

          &::before {
            opacity: 1;
          }

        }

      }

      &:hover,
      &:focus-within {

        .checkbox-input {
          border-color: #3E6B85;
        }

      }

    }

    &-reset {
      background-color: transparent;
      text-decoration: underline;
      justify-content: center;
      align-items: center;
      display: flex;
      color: #3E6B85;
      height: 42px;
      border: none;
      padding: 0;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

    }

    &-header {
      border-bottom: 2px solid #DBDBDB;
      padding-bottom: 16px;
      margin-bottom: 16px;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      display: flex;

      @media screen and (max-width: 1024px) {
        margin-top: calc(var(--sidebar-padding) * -1);
        top: calc(var(--sidebar-padding) * -1);
        background-color: #F9FAFC;
        padding-top: 16px;
        position: sticky;
      }

      > h3 {
        margin: 0;
        flex: 1;
      }

      > button {
        background-color: transparent;
        border: none !important;
        width: 40px !important;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        color: #AAAAAA;
        line-height: 1;
        display: none;

        &#applicator-map-filter-close-desktop {
          display: none;

          @media screen and (max-width: 1500px) {
            display: flex;
          }

          @media screen and (max-width: 1024px) {
            display: none;
          }

        }

        &#applicator-map-filter-close-mobile {
          display: none;

          @media screen and (max-width: 1024px) {
            display: flex;
          }

        }

      }

    }

    &-content {
      flex-direction: column;
      max-height: none;
      overflow-y: auto;
      display: flex;
      width: 100%;
      flex: 1;

      @media screen and (max-width: 1024px) {
        max-height: 100%;
      }

    }

    &-footer {
      bottom: calc(var(--sidebar-padding) * -1);
      padding: var(--sidebar-padding) 0 0;
      background-color: #F9FAFC;
      flex-direction: column;
      position: sticky;
      display: flex;

      @media screen and (max-width: 1500px) {
        margin: 0 calc(var(--sidebar-padding) * -1) calc(var(--sidebar-padding) * -1);
        padding: var(--sidebar-padding) var(--sidebar-padding) 16px;
      }

      @media screen and (max-width: 1024px) {
        margin: var(--sidebar-padding) calc(var(--sidebar-padding) * -1) calc(var(--sidebar-padding) * -1);
        padding: 16px var(--sidebar-padding);
        background-color: #3E6B85;
        flex-direction: row;

        > button {
          color: white;
          flex: 1;

          &.btn {
            border-color: white;
          }

        }

      }

    }

  }

}

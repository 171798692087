<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-.40 0 2.06453 1.56969">
    <defs>
      <clipPath id="clip-path" transform="translate(0.44382 0.01381)">
        <circle class="jp-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.44382 0.01381)">
        <rect class="jp-i-1" x="-0.44382" y="-0.01381" width="2.06247" height="1.20244"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="jp-i-2">
          <g class="jp-i-3">
            <rect class="jp-i-4" width="2.06247" height="1.20244"/>
            <g class="jp-i-3">
              <path class="jp-i-5" d="M.58705.226a.36147.36147,0,1,1,0,.72293.36147.36147,0,1,1,0-.72293" transform="translate(0.04223 0.01381)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "japanIcon"
}
</script>

<style>
  .jp-i-1 {
    fill: none;
  }

  .jp-i-2 {
    clip-path: url(#clip-path);
  }

  .jp-i-3 {
    clip-path: url(#clip-path-2);
  }

  .jp-i-4 {
    fill: #f4f4f4;
  }

  .jp-i-5 {
    fill: #ad0018;
  }
</style>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06174 1.20317">
    <defs>
      <clipPath id="clip-path" transform="translate(0.44346 0.01417)">
        <circle class="sk-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.44346 0.01417)">
        <rect class="sk-i-1" x="-0.44346" y="-0.01417" width="2.06174" height="1.20317"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="sk-i-2">
          <g class="sk-i-3">
            <rect class="sk-i-4" width="2.06174" height="1.20317"/>
            <polygon points="1.364 0.197 1.442 0.312 1.4 0.34 1.324 0.226 1.364 0.197"/>
            <polygon points="1.453 0.333 1.532 0.447 1.489 0.477 1.414 0.361 1.453 0.333"/>
            <polygon points="1.428 0.154 1.593 0.405 1.554 0.433 1.385 0.183 1.428 0.154"/>
            <polygon points="1.489 0.114 1.568 0.229 1.525 0.254 1.45 0.14 1.489 0.114"/>
            <polygon points="1.579 0.251 1.657 0.365 1.614 0.39 1.539 0.276 1.579 0.251"/>
            <polygon points="0.573 0.727 0.738 0.977 0.698 1.002 0.529 0.752 0.573 0.727"/>
            <polygon points="0.508 0.766 0.587 0.881 0.544 0.909 0.469 0.795 0.508 0.766"/>
            <rect x="0.14797" y="0.89134" width="0.05188" height="0.13708" transform="translate(-0.05688 0.26907) rotate(-33.45414)"/>
            <rect x="0.04039" y="0.7843" width="0.05035" height="0.30056" transform="translate(-0.06041 0.20479) rotate(-33.41779)"/>
            <polygon points="0.529 0.447 0.698 0.197 0.738 0.226 0.573 0.477 0.529 0.447"/>
            <polygon points="0.469 0.405 0.633 0.154 0.677 0.183 0.508 0.433 0.469 0.405"/>
            <polygon points="0.404 0.365 0.573 0.114 0.612 0.14 0.447 0.39 0.404 0.365"/>
            <polygon points="1.324 0.977 1.4 0.859 1.442 0.888 1.364 1.002 1.324 0.977"/>
            <polygon points="1.414 0.842 1.489 0.727 1.532 0.752 1.453 0.867 1.414 0.842"/>
            <rect x="0.93232" y="0.9341" width="0.13686" height="0.05155" transform="translate(0.09178 1.2803) rotate(-56.56496)"/>
            <rect x="1.02226" y="0.79832" width="0.1381" height="0.05037" transform="translate(0.23651 1.2662) rotate(-55.30485)"/>
            <polygon points="1.45 1.059 1.525 0.945 1.568 0.974 1.489 1.088 1.45 1.059"/>
            <polygon points="1.539 0.924 1.614 0.809 1.657 0.838 1.579 0.952 1.539 0.924"/>
            <g class="sk-i-3">
              <polyline class="sk-i-5" points="0.78 0.433 0.776 0.437 0.78 0.433"/>
              <path class="sk-i-5" d="M.75531.337A.29982.29982,0,0,0,.33666.41914.14722.14722,0,0,0,.38.62664.149.149,0,0,0,.58741.58705.15007.15007,0,0,1,.83816.752.29939.29939,0,0,0,.75531.337" transform="translate(0.44346 0.01417)"/>
              <path class="sk-i-6" d="M.33666.41914A.14722.14722,0,0,0,.38.62664.149.149,0,0,0,.58741.58705.15007.15007,0,0,1,.83816.752.301.301,0,1,1,.33666.41914" transform="translate(0.44346 0.01417)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "southKoreaIcon"
}
</script>

<style>
  .sk-i-1 {
    fill: none;
  }

  .sk-i-2 {
    clip-path: url(#clip-path);
  }

  .sk-i-3 {
    clip-path: url(#clip-path-2);
  }

  .sk-i-4 {
    fill: #f4f4f4;
  }

  .sk-i-5 {
    fill: #b50037;
  }

  .sk-i-6 {
    fill: #1e3476;
  }
</style>

#documents-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 1%;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  #loading-documents {
    font-size: 60px;
    text-align: center;
    position: relative;
    height: 15vh;

    .loading-contents {
      width: 100%;
      position: absolute;
      z-index: 10;
      background-color: #fff;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }

  .navbar-fixed-top {
    z-index: 1;
    padding: 5px;

    .navbar-brand {
      margin: auto;
    }

    .navbar-btn {
      margin: auto 5px;
    }

    .container-fluid {
      justify-content: center;
      display: inline-flex;
      width: 100%;
    }
  }

  .documents-nav {
    margin-top: 10px;
  }

  .document-section,
  .documentList {
    h2 {
      display: inline-flex;
      align-items: center;

      span {
        display: inline-block;
      }
    }

    .doc-item {
      font-size: 1.8rem;
      margin: 3px 0;
    }
  }
}

@media (max-width: 1199px) {
  .container-fluid {
    .navbar-form {
      display: inline-flex;
      border: none;
      margin: 5px;
      padding: 0;
    }

    button {
      margin: 0 0 5px 5px;
    }
  }
}

@media (max-width: 767px) {
  .navbar-header {
    h1 {
      display: none;
    }
  }
}

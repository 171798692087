
#user-account {
  margin: 2% 10%;

  #applicator-cta {
    margin: 0 15px 40px 15px;

    .panel-body {
      padding: 30px;

      h2 {
        margin: 0;
        line-height: 42px;
      }

      .btn-info {
        padding: 10px 20px;
      }
    }
  }

  #account-forms {
    .col-lg-9, .col-md-6, .col-xs-12 {
      .tab-content {
        padding: 5px 30px 30px 30px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-top: 0;
      }
    }
  }

  .nav-tabs > li > a {
    border-radius: 0;
  }

  .sidebar {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1600px) {
  #user-account {
  margin: 2% 0%
  }
}

@media only screen and (max-width: 770px) {
  #account-forms {
    .col-lg-9, .col-md-6, .col-xs-12 {
      display: grid;
    }
  }
}

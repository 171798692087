<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06242 1.20317">
    <defs>
      <clipPath id="clip-path">
        <rect class="usa-1" width="2.06242" height="1.20317"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="usa-2">
          <rect class="usa-3" y="1.11005" width="2.06242" height="0.09312"/>
          <rect class="usa-4" y="1.01694" width="2.06242" height="0.09312"/>
          <rect class="usa-3" y="0.92382" width="2.06242" height="0.09312"/>
          <rect class="usa-4" y="0.83071" width="2.06242" height="0.09312"/>
          <rect class="usa-3" y="0.73759" width="2.06242" height="0.09312"/>
          <rect class="usa-4" y="0.65181" width="2.06242" height="0.08578"/>
          <rect class="usa-3" y="0.55869" width="2.06242" height="0.09312"/>
          <rect class="usa-4" y="0.46558" width="2.06242" height="0.09312"/>
          <rect class="usa-3" y="0.37246" width="2.06242" height="0.09312"/>
          <rect class="usa-4" y="0.27935" width="2.06242" height="0.09312"/>
          <rect class="usa-3" y="0.18623" width="2.06242" height="0.09312"/>
          <rect class="usa-4" y="0.09312" width="2.06242" height="0.09312"/>
          <rect class="usa-3" width="2.06242" height="0.09312"/>
          <rect class="usa-5" width="0.93849" height="0.65181"/>
          <polygon class="usa-4" points="0.086 0.072 0.093 0.093 0.115 0.093 0.093 0.114 0.1 0.136 0.086 0.122 0.064 0.136 0.072 0.114 0.051 0.093 0.072 0.093 0.086 0.072"/>
          <polygon class="usa-4" points="0.086 0.179 0.093 0.207 0.115 0.207 0.093 0.222 0.1 0.243 0.086 0.229 0.064 0.243 0.072 0.222 0.051 0.207 0.072 0.207 0.086 0.179"/>
          <polygon class="usa-4" points="0.086 0.287 0.093 0.315 0.115 0.315 0.093 0.329 0.1 0.35 0.086 0.337 0.064 0.35 0.072 0.329 0.051 0.315 0.072 0.315 0.086 0.287"/>
          <polygon class="usa-4" points="0.086 0.394 0.093 0.422 0.115 0.422 0.093 0.437 0.1 0.458 0.086 0.444 0.064 0.458 0.072 0.437 0.051 0.422 0.072 0.422 0.086 0.394"/>
          <polygon class="usa-4" points="0.086 0.502 0.093 0.53 0.115 0.53 0.093 0.544 0.1 0.565 0.086 0.551 0.064 0.565 0.072 0.544 0.051 0.53 0.072 0.53 0.086 0.502"/>
          <polygon class="usa-4" points="0.158 0.129 0.165 0.15 0.194 0.15 0.172 0.165 0.179 0.194 0.158 0.179 0.136 0.194 0.144 0.165 0.122 0.15 0.15 0.15 0.158 0.129"/>
          <polygon class="usa-4" points="0.158 0.236 0.165 0.258 0.194 0.258 0.172 0.279 0.179 0.301 0.158 0.287 0.136 0.301 0.144 0.279 0.122 0.258 0.15 0.258 0.158 0.236"/>
          <polygon class="usa-4" points="0.158 0.344 0.165 0.372 0.194 0.372 0.172 0.386 0.179 0.408 0.158 0.394 0.136 0.408 0.144 0.386 0.122 0.372 0.15 0.372 0.158 0.344"/>
          <polygon class="usa-4" points="0.158 0.451 0.165 0.48 0.194 0.48 0.172 0.494 0.179 0.515 0.158 0.502 0.136 0.515 0.144 0.494 0.122 0.48 0.15 0.48 0.158 0.451"/>
          <polygon class="usa-4" points="0.237 0.072 0.243 0.093 0.265 0.093 0.243 0.114 0.258 0.136 0.237 0.122 0.215 0.136 0.222 0.114 0.201 0.093 0.229 0.093 0.237 0.072"/>
          <polygon class="usa-4" points="0.237 0.179 0.243 0.207 0.265 0.207 0.243 0.222 0.258 0.243 0.237 0.229 0.215 0.243 0.222 0.222 0.201 0.207 0.229 0.207 0.237 0.179"/>
          <polygon class="usa-4" points="0.237 0.287 0.243 0.315 0.265 0.315 0.243 0.329 0.258 0.35 0.237 0.337 0.215 0.35 0.222 0.329 0.201 0.315 0.229 0.315 0.237 0.287"/>
          <polygon class="usa-4" points="0.237 0.394 0.243 0.422 0.265 0.422 0.243 0.437 0.258 0.458 0.237 0.444 0.215 0.458 0.222 0.437 0.201 0.422 0.229 0.422 0.237 0.394"/>
          <polygon class="usa-4" points="0.237 0.502 0.243 0.53 0.265 0.53 0.243 0.544 0.258 0.565 0.237 0.551 0.215 0.565 0.222 0.544 0.201 0.53 0.229 0.53 0.237 0.502"/>
          <polygon class="usa-4" points="0.308 0.129 0.315 0.15 0.344 0.15 0.323 0.165 0.33 0.194 0.308 0.179 0.287 0.194 0.294 0.165 0.272 0.15 0.301 0.15 0.308 0.129"/>
          <polygon class="usa-4" points="0.308 0.236 0.315 0.258 0.344 0.258 0.323 0.279 0.33 0.301 0.308 0.287 0.287 0.301 0.294 0.279 0.272 0.258 0.301 0.258 0.308 0.236"/>
          <polygon class="usa-4" points="0.308 0.344 0.315 0.372 0.344 0.372 0.323 0.386 0.33 0.408 0.308 0.394 0.287 0.408 0.294 0.386 0.272 0.372 0.301 0.372 0.308 0.344"/>
          <polygon class="usa-4" points="0.308 0.451 0.315 0.48 0.344 0.48 0.323 0.494 0.33 0.515 0.308 0.502 0.287 0.515 0.294 0.494 0.272 0.48 0.301 0.48 0.308 0.451"/>
          <polygon class="usa-4" points="0.387 0.072 0.394 0.093 0.422 0.093 0.401 0.114 0.408 0.136 0.387 0.122 0.365 0.136 0.372 0.114 0.351 0.093 0.38 0.093 0.387 0.072"/>
          <polygon class="usa-4" points="0.387 0.179 0.394 0.207 0.422 0.207 0.401 0.222 0.408 0.243 0.387 0.229 0.365 0.243 0.372 0.222 0.351 0.207 0.38 0.207 0.387 0.179"/>
          <polygon class="usa-4" points="0.387 0.287 0.394 0.315 0.422 0.315 0.401 0.329 0.408 0.35 0.387 0.337 0.365 0.35 0.372 0.329 0.351 0.315 0.38 0.315 0.387 0.287"/>
          <polygon class="usa-4" points="0.387 0.394 0.394 0.422 0.422 0.422 0.401 0.437 0.408 0.458 0.387 0.444 0.365 0.458 0.372 0.437 0.351 0.422 0.38 0.422 0.387 0.394"/>
          <polygon class="usa-4" points="0.387 0.502 0.394 0.53 0.422 0.53 0.401 0.544 0.408 0.565 0.387 0.551 0.365 0.565 0.372 0.544 0.351 0.53 0.38 0.53 0.387 0.502"/>
          <polygon class="usa-4" points="0.458 0.129 0.473 0.15 0.494 0.15 0.473 0.165 0.48 0.194 0.458 0.179 0.437 0.194 0.452 0.165 0.43 0.15 0.452 0.15 0.458 0.129"/>
          <polygon class="usa-4" points="0.458 0.236 0.473 0.258 0.494 0.258 0.473 0.279 0.48 0.301 0.458 0.287 0.437 0.301 0.452 0.279 0.43 0.258 0.452 0.258 0.458 0.236"/>
          <polygon class="usa-4" points="0.458 0.344 0.473 0.372 0.494 0.372 0.473 0.386 0.48 0.408 0.458 0.394 0.437 0.408 0.452 0.386 0.43 0.372 0.452 0.372 0.458 0.344"/>
          <polygon class="usa-4" points="0.458 0.451 0.473 0.48 0.494 0.48 0.473 0.494 0.48 0.515 0.458 0.502 0.437 0.515 0.452 0.494 0.43 0.48 0.452 0.48 0.458 0.451"/>
          <polygon class="usa-4" points="0.537 0.072 0.545 0.093 0.573 0.093 0.551 0.114 0.559 0.136 0.537 0.122 0.515 0.136 0.523 0.114 0.501 0.093 0.53 0.093 0.537 0.072"/>
          <polygon class="usa-4" points="0.537 0.179 0.545 0.207 0.573 0.207 0.551 0.222 0.559 0.243 0.537 0.229 0.515 0.243 0.523 0.222 0.501 0.207 0.53 0.207 0.537 0.179"/>
          <polygon class="usa-4" points="0.537 0.287 0.545 0.315 0.573 0.315 0.551 0.329 0.559 0.35 0.537 0.337 0.515 0.35 0.523 0.329 0.501 0.315 0.53 0.315 0.537 0.287"/>
          <polygon class="usa-4" points="0.537 0.394 0.545 0.422 0.573 0.422 0.551 0.437 0.559 0.458 0.537 0.444 0.515 0.458 0.523 0.437 0.501 0.422 0.53 0.422 0.537 0.394"/>
          <polygon class="usa-4" points="0.537 0.502 0.545 0.53 0.573 0.53 0.551 0.544 0.559 0.565 0.537 0.551 0.515 0.565 0.523 0.544 0.501 0.53 0.53 0.53 0.537 0.502"/>
          <polygon class="usa-4" points="0.616 0.129 0.623 0.15 0.644 0.15 0.623 0.165 0.631 0.194 0.616 0.179 0.595 0.194 0.602 0.165 0.58 0.15 0.602 0.15 0.616 0.129"/>
          <polygon class="usa-4" points="0.616 0.236 0.623 0.258 0.644 0.258 0.623 0.279 0.631 0.301 0.616 0.287 0.595 0.301 0.602 0.279 0.58 0.258 0.602 0.258 0.616 0.236"/>
          <polygon class="usa-4" points="0.616 0.344 0.623 0.372 0.644 0.372 0.623 0.386 0.631 0.408 0.616 0.394 0.595 0.408 0.602 0.386 0.58 0.372 0.602 0.372 0.616 0.344"/>
          <polygon class="usa-4" points="0.616 0.451 0.623 0.48 0.644 0.48 0.623 0.494 0.631 0.515 0.616 0.502 0.595 0.515 0.602 0.494 0.58 0.48 0.602 0.48 0.616 0.451"/>
          <polygon class="usa-4" points="0.688 0.072 0.695 0.093 0.724 0.093 0.702 0.114 0.709 0.136 0.688 0.122 0.666 0.136 0.673 0.114 0.659 0.093 0.68 0.093 0.688 0.072"/>
          <polygon class="usa-4" points="0.688 0.179 0.695 0.207 0.724 0.207 0.702 0.222 0.709 0.243 0.688 0.229 0.666 0.243 0.673 0.222 0.659 0.207 0.68 0.207 0.688 0.179"/>
          <polygon class="usa-4" points="0.688 0.287 0.695 0.315 0.724 0.315 0.702 0.329 0.709 0.35 0.688 0.337 0.666 0.35 0.673 0.329 0.659 0.315 0.68 0.315 0.688 0.287"/>
          <polygon class="usa-4" points="0.688 0.394 0.695 0.422 0.724 0.422 0.702 0.437 0.709 0.458 0.688 0.444 0.666 0.458 0.673 0.437 0.659 0.422 0.68 0.422 0.688 0.394"/>
          <polygon class="usa-4" points="0.688 0.502 0.695 0.53 0.724 0.53 0.702 0.544 0.709 0.565 0.688 0.551 0.666 0.565 0.673 0.544 0.659 0.53 0.68 0.53 0.688 0.502"/>
          <polygon class="usa-4" points="0.766 0.129 0.773 0.15 0.795 0.15 0.781 0.165 0.788 0.194 0.766 0.179 0.745 0.194 0.752 0.165 0.73 0.15 0.759 0.15 0.766 0.129"/>
          <polygon class="usa-4" points="0.766 0.236 0.773 0.258 0.795 0.258 0.781 0.279 0.788 0.301 0.766 0.287 0.745 0.301 0.752 0.279 0.73 0.258 0.759 0.258 0.766 0.236"/>
          <polygon class="usa-4" points="0.766 0.344 0.773 0.372 0.795 0.372 0.781 0.386 0.788 0.408 0.766 0.394 0.745 0.408 0.752 0.386 0.73 0.372 0.759 0.372 0.766 0.344"/>
          <polygon class="usa-4" points="0.766 0.451 0.773 0.48 0.795 0.48 0.781 0.494 0.788 0.515 0.766 0.502 0.745 0.515 0.752 0.494 0.73 0.48 0.759 0.48 0.766 0.451"/>
          <polygon class="usa-4" points="0.838 0.072 0.852 0.093 0.874 0.093 0.852 0.114 0.859 0.136 0.838 0.122 0.817 0.136 0.831 0.114 0.809 0.093 0.831 0.093 0.838 0.072"/>
          <polygon class="usa-4" points="0.838 0.179 0.852 0.207 0.874 0.207 0.852 0.222 0.859 0.243 0.838 0.229 0.817 0.243 0.831 0.222 0.809 0.207 0.831 0.207 0.838 0.179"/>
          <polygon class="usa-4" points="0.838 0.287 0.852 0.315 0.874 0.315 0.852 0.329 0.859 0.35 0.838 0.337 0.817 0.35 0.831 0.329 0.809 0.315 0.831 0.315 0.838 0.287"/>
          <polygon class="usa-4" points="0.838 0.394 0.852 0.422 0.874 0.422 0.852 0.437 0.859 0.458 0.838 0.444 0.817 0.458 0.831 0.437 0.809 0.422 0.831 0.422 0.838 0.394"/>
          <polygon class="usa-4" points="0.838 0.502 0.852 0.53 0.874 0.53 0.852 0.544 0.859 0.565 0.838 0.551 0.817 0.565 0.831 0.544 0.809 0.53 0.831 0.53 0.838 0.502"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "usaFlag"
}
</script>

<style type="text/css">
  .usa-1 {
    fill: none;
  }

  .usa-3 {
    fill: #cb001c;
  }

  .usa-4 {
    fill: #fff;
  }

  .usa-5 {
    fill: #2a3560;
  }
</style>
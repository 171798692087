<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 -1.25928 800 472.74951">
  <g id="Layer_2">
    <g>
      <path class="a-i-0" d="M400.95313,3.33789c-100.89648,0-186.72021,64.47852-218.55371,154.47217h437.10938
        C587.67578,67.81641,501.85156,3.33789,400.95313,3.33789z"/>
      <path class="a-i-0" d="M400.95313,466.89355c100.89844,0,186.72266-64.47852,218.55566-154.47217H182.3999
        C214.2334,402.41504,300.05664,466.89355,400.95313,466.89355z"/>
      <path class="a-i-1" d="M182.3999,312.42139h437.10889c8.55371-24.18018,13.22363-50.1958,13.22363-77.30615
        c0-27.10986-4.66992-53.125-13.22363-77.30518H182.39941c-8.55322,24.18018-13.22314,50.19531-13.22314,77.30518
        C169.17627,262.22559,173.84668,288.24121,182.3999,312.42139z"/>
    </g>
  </g>
  <g id="Layer_3">
  </g>
  </svg>
</template>

<script>
export default {
  name: "austriaIcon"
}
</script>

<style>
  .a-i-0{fill:#ED2939;}
  .a-i-1{fill:#FFFFFF;}
</style>
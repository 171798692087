<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 166.6543 800 466.69141">
  <g id="Layer_3_copy">
    <rect y="166.6543" class="megm-3" width="800" height="466.69141"/>
  </g>
  <g id="Layer_3">
    <g>
      <path class="megm-1" d="M253.71191,187.83301c2.32275-1.40869,4.95947-2.17627,7.64355-2.24805
        C258.67041,185.65527,256.03711,186.42285,253.71191,187.83301z"/>
      <path class="megm-1" d="M236.09863,187.60352c0.08594-0.00293,0.17188-0.00781,0.25928,0.00342
        C236.27051,187.5957,236.18457,187.60107,236.09863,187.60352z"/>
      <path class="megm-1" d="M235.9707,187.60156l-0.00391,0.00391c0.00537-0.00049,0.00977-0.00293,0.01514-0.00391
        C235.97803,187.60205,235.97461,187.60107,235.9707,187.60156z"/>
      <path class="megm-1" d="M417.80078,201.24902c0.35742-3.34277-4.49805-10.56543-6.89453-13.44922
        c-2.48828-3.75439-4.70703-7.66992-6.65918-11.7207l0,0c1.95215,4.05078,4.1709,7.96631,6.65039,11.7207
        C413.30078,190.68896,418.1582,197.90723,417.80078,201.24902z"/>
      <path class="megm-1" d="M207.79297,207.49414c-0.32324-0.43652-0.72998-0.81104-1.03076-1.25684
        C207.05908,206.68555,207.4668,207.05957,207.79297,207.49414z"/>
      <path class="megm-1" d="M216.72217,168.36914c0.99561-0.03564,2.65332,0.47021,2.84229,1.52002
        C219.38281,168.84082,217.71973,168.33301,216.72217,168.36914z"/>
      <path class="megm-1" d="M204.82471,220.11572c0.3916-0.47803,1.58447-0.36816,2.4292-0.80713
        C206.41113,219.74316,205.21533,219.63379,204.82471,220.11572z"/>
      <path class="megm-1" d="M207.84277,227.66455c0.08789-0.16748,0.18701-0.33154,0.30762-0.48584
        C208.03027,227.33008,207.93164,227.49463,207.84277,227.66455z"/>
      <path class="megm-1" d="M272.28613,175.92188c0.03027-0.04883,0.06836-0.09521,0.104-0.14258
        C272.35449,175.82666,272.31641,175.87305,272.28613,175.92188z"/>
      <path class="megm-1" d="M359.37744,176.73633c-0.63623-0.75146-1.11035-1.63525-1.39502-2.60059
        C358.21338,175.11426,358.68945,176.00879,359.37744,176.73633z"/>
      <path class="megm-1" d="M428.67578,200.32715c-2.02539-0.52051-3.00586,0.42773-3.47559,1.68555
        c0.4707-1.24854,1.44922-2.18506,3.46582-1.66602c3.37109,0.87012,6.47852,2.28564,6.84473,1.43164
        C435.09668,202.58594,432.00977,201.18848,428.67578,200.32715z"/>
      <path class="megm-1" d="M207.62939,228.19092c0.04004-0.12939,0.08789-0.25732,0.14502-0.38184
        C207.71777,227.93311,207.66943,228.06006,207.62939,228.19092z"/>
      <path class="megm-1" d="M405.06152,175.77051c1.93262-0.62842,3.89844-1.1416,5.8877-1.53027
        C408.94043,174.5752,406.9707,175.08496,405.06152,175.77051z"/>
      <path class="megm-1" d="M418.79883,214.51758c0.63086-0.74316,1.23047-1.51025,1.80176-2.29639
        c-0.49414,0.66406-1.00488,1.31641-1.5459,1.9458L418.79883,214.51758z"/>
      <path class="megm-1" d="M424.77246,204.97559c0-0.00342,0-0.00684,0-0.01025c-0.04883,0.11572-0.11035,0.22559-0.16016,0.34082
        C424.66309,205.19434,424.72266,205.08789,424.77246,204.97559z"/>
      <path class="megm-1" d="M272.89697,175.26172c0.03955-0.03271,0.0791-0.06592,0.12109-0.09814
        C272.97559,175.1958,272.93701,175.229,272.89697,175.26172z"/>
      <path class="megm-1" d="M272.54395,175.59082c0.0415-0.04492,0.08643-0.08936,0.13232-0.13379
        C272.62988,175.50146,272.58545,175.5459,272.54395,175.59082z"/>
      <path class="megm-1" d="M305.896,173.30469c0.02734,0.07275,0.0542,0.14551,0.08252,0.21826
        C305.95361,173.44922,305.91992,173.37891,305.896,173.30469z"/>
      <path class="megm-1" d="M417.30566,202.41992c0.20605-0.21191,0.32422-0.49316,0.40527-0.80273
        C417.62988,201.92676,417.51172,202.20801,417.30566,202.41992z"/>
      <path class="megm-1" d="M417.30566,202.41992c-1.68066,3.93066-1.00684,8.47852,1.74902,11.74707
        C416.30664,210.89844,415.625,206.35059,417.30566,202.41992z"/>
      <path class="megm-1" d="M479.89063,490.63574c2.80469-1.31055,2.50195-6.28711,4.53516-7.00293
        C482.3877,484.33105,482.69922,489.31641,479.89063,490.63574z"/>
      <path class="megm-1" d="M473.29199,495.67871c1.4834-0.80859,1.07422-3.69922,3.55762-4.51172
        C474.36035,491.97168,474.7627,494.85742,473.29199,495.67871z"/>
      <path class="megm-1" d="M207.4873,228.88379c-0.00049,0.00391,0.00049,0.00781,0.00049,0.01221
        c0.0127-0.18164,0.04639-0.36182,0.09131-0.54004C207.53564,228.52881,207.50195,228.70459,207.4873,228.88379z"/>
      <path class="megm-1" d="M148.07275,251.8418c0.01318,0.00684,0.02295,0.01465,0.03613,0.021
        C148.0957,251.85596,148.08594,251.84863,148.07275,251.8418z"/>
      <path class="megm-1" d="M292.75488,506.07422c-0.29297,4.01758,2.99023,3.70703,1.79395,8.0293
        c-1.08008,3.59668-1.68652,7.33887-1.79395,11.10352c0.25488,8.64844-0.14941,17.31445-1.19238,25.91309
        c0.00635-0.00586,0.01221-0.01172,0.01855-0.01758c1.04785-8.58887,1.44727-17.25195,1.19531-25.91406
        c0.10352-3.75879,0.71094-7.49219,1.79395-11.10352c1.20215-4.31348-2.09473-4.01563-1.79395-8.01563
        c0.30176-4.00391-4.18652-7.09082-4.18652-9.57031C288.58984,498.97852,293.05078,502.06543,292.75488,506.07422z"/>
      <path class="megm-1" d="M264.62695,591.22949c-0.16162,0.05566-0.32813,0.09863-0.49805,0.13379
        c0.17773-0.03516,0.35205-0.08105,0.52148-0.13867c2.52148-0.86816,4.78027-5.52246,6.77734-6.62207
        C269.41992,585.68457,267.1626,590.3584,264.62695,591.22949z"/>
      <path class="megm-1" d="M147.20752,251.22803c-0.03857-0.03955-0.08008-0.07764-0.11963-0.11719
        c0.0376,0.03809,0.07764,0.07471,0.11426,0.11279C147.20361,251.2251,147.20605,251.22656,147.20752,251.22803z"/>
      <path class="megm-1" d="M279.96387,487.57715c-1.67871-2.59766-3.85059-4.8457-6.40527-6.5918
        c2.55469,1.75098,4.72656,3.99414,6.40527,6.60254l8.02734-4.49316l9.05371-5.0459l77.26953,42.82617v-0.00684l-77.26953-42.8291
        L279.96387,487.57715z"/>
      <rect x="374.31445" y="515.3457" class="megm-1" width="9.37402" height="0.00879"/>
      <path class="megm-1" d="M147.34717,251.3457c0.0835,0.07373,0.16211,0.14844,0.26074,0.21729
        C147.50928,251.49414,147.43018,251.41943,147.34717,251.3457z"/>
      <path class="megm-1" d="M209.27686,231.9585c-0.04053-0.01904-0.08154-0.03662-0.12158-0.05713
        C209.19482,231.92236,209.23633,231.93945,209.27686,231.9585z"/>
      <path class="megm-1" d="M209.8291,232.16455c-0.04883-0.01318-0.09668-0.0293-0.14502-0.04443
        C209.73242,232.13525,209.77979,232.15137,209.8291,232.16455z"/>
      <path class="megm-1" d="M207.68506,230.2168c-0.01221-0.03369-0.03174-0.06494-0.04297-0.09912
        C207.65381,230.15186,207.67236,230.18311,207.68506,230.2168z"/>
      <path class="megm-1" d="M207.54541,229.69824c-0.01172-0.06299-0.0332-0.12402-0.0415-0.1875
        C207.51221,229.5752,207.5332,229.63477,207.54541,229.69824z"/>
      <path class="megm-1" d="M62.09717,302.62793c-0.0083,0.0376-0.01221,0.07324-0.01953,0.11035
        C62.08496,302.70117,62.08887,302.66553,62.09717,302.62793z"/>
      <path class="megm-1" d="M62.34863,304.90625c0.81934,0.84814,3.88281,2.9541,3.68701,4.27148
        c0.22656-1.31641-2.86426-3.43799-3.68701-4.28516c-0.34131-0.35205-0.43359-1.03271-0.33301-1.80273
        C61.9126,303.86572,62.00488,304.55225,62.34863,304.90625z"/>
      <path class="megm-1" d="M61.98438,293.63965c2.00781,2.06885,3.66357,3.95654,3.7959,4.93457
        c-0.11133-0.97021-1.77441-2.86523-3.7959-4.94824c-1.0835-1.11768-1.35498-1.97412-1.13965-2.60986
        C60.62256,291.65234,60.89307,292.51318,61.98438,293.63965z"/>
      <path class="megm-1" d="M67.22998,280.69189c0.56445-2.68457-1.6001-6.25146-1.6001-8.08057
        c0-0.13867,0.01416-0.27148,0.04102-0.39941c-0.02881,0.1333-0.04492,0.27148-0.04492,0.41602
        C65.62598,274.45898,67.77783,278.01465,67.22998,280.69189z"/>
      <path class="megm-1" d="M358.55469,235.56543c0.01221-0.11182,0.03174-0.22217,0.05176-0.33301
        C358.58643,235.34375,358.56641,235.45313,358.55469,235.56543z"/>
      <path class="megm-1" d="M234.68994,593.99121c0.41113-0.08789,0.82031-0.18945,1.22607-0.30664
        c1.56445-0.91602,3.4707-3.40527,5.46143-4.46289c-1.99707,1.04883-3.91162,3.54883-5.48975,4.46777
        C235.49121,593.80469,235.09131,593.9043,234.68994,593.99121z"/>
      <path class="megm-1" d="M395.74609,264.81055c-0.28223-0.73047,0.5752-1.32275,1.78027-1.74316
        c-1.21484,0.42139-2.0835,1.01563-1.79883,1.75098c0.60156,1.5332-1.48877,2.45801-3.87598,2.46143
        c0.00098,0,0.00244,0.00049,0.00391,0.00049C394.24805,267.28027,396.34863,266.35449,395.74609,264.81055z"/>
      <path class="megm-1" d="M413.01172,259.73828c0.14746-0.02832,0.29199-0.06934,0.43555-0.11426
        C413.30371,259.66895,413.15918,259.70996,413.01172,259.73828z"/>
      <polygon class="megm-1" points="367.49512,265.2627 367.49121,265.26318 367.4917,265.27148 		"/>
      <path class="megm-1" d="M352.73877,273.30713l-0.17236,0.03174C352.62451,273.3291,352.68164,273.31836,352.73877,273.30713z"/>
      <path class="megm-1" d="M359.0957,233.81494c0.05859-0.10986,0.12549-0.21631,0.19336-0.32227
        C359.22119,233.59912,359.1543,233.70459,359.0957,233.81494z"/>
      <path class="megm-1" d="M358.7373,234.67432c0.03516-0.11621,0.0791-0.22998,0.12354-0.34326
        C358.81592,234.44531,358.77246,234.55811,358.7373,234.67432z"/>
      <path class="megm-1" d="M359.73535,232.87891c-0.04053,0.04541-0.06641,0.09766-0.10498,0.14404
        c0.11719-0.14111,0.23926-0.27881,0.37451-0.40869C359.91357,232.70068,359.8208,232.78516,359.73535,232.87891z"/>
      <path class="megm-1" d="M359.88672,250.54102c-0.01367-0.08594-0.02002-0.17676-0.02051-0.27197
        c0,0.09814,0.00684,0.19189,0.02051,0.27979c0.01904,0.48193,0.01025,0.96582-0.01563,1.44824
        C359.92432,251.44385,359.95117,250.92773,359.88672,250.54102z"/>
      <path class="megm-1" d="M400.53613,259.56152c1.26465-0.35059,2.55566-0.56982,3.85352-0.65771
        c-1.30371,0.0874-2.60156,0.30762-3.87207,0.66064c-0.58105,0.24707-0.30957,1.42676,0.08105,2.84961
        c-0.0127,0.00146-0.02637,0.00342-0.03906,0.00488c0.01465-0.00195,0.0293-0.00391,0.04395-0.00537
        C400.21631,260.98145,399.95215,259.80225,400.53613,259.56152z"/>
      <path class="megm-1" d="M328.40967,238.32178c0.06934-0.00293,0.13623-0.03662,0.20117-0.0874
        c-0.06494,0.04785-0.13135,0.08691-0.20068,0.08691L328.40967,238.32178z"/>
      <path class="megm-1" d="M328.56152,256.93311c-0.06934,0.19824-0.1333,0.39795-0.19336,0.59961
        C328.42773,257.33105,328.49268,257.13135,328.56152,256.93311z"/>
      <path class="megm-1" d="M328.78955,256.3252c-0.04639,0.11572-0.08936,0.23291-0.13281,0.34961
        C328.7002,256.55762,328.74365,256.44092,328.78955,256.3252z"/>
      <path class="megm-1" d="M294.81445,219.66846c-0.13379,0.12744-0.25391,0.26709-0.36084,0.41699
        C294.5625,219.93262,294.68311,219.79297,294.81445,219.66846z"/>
      <path class="megm-1" d="M340.14355,284.09082c-0.09277,0.01953-0.18262,0.04883-0.26855,0.08691
        C339.96094,284.13965,340.05078,284.11035,340.14355,284.09082z"/>
      <path class="megm-1" d="M365.5,262.35352c0.01758,0.10693,0.06299,0.20459,0.10449,0.30322
        c-0.04395-0.10205-0.08984-0.20313-0.10742-0.31396c-1.55176-2.48047-3.44238-4.73389-5.60352-6.70703l0.00195,0.00586
        C362.06445,257.61523,363.94336,259.87793,365.5,262.35352z"/>
      <path class="megm-1" d="M329.03467,255.74951c-0.02246,0.04932-0.04346,0.09961-0.06543,0.14941
        C328.99121,255.84912,329.01221,255.79932,329.03467,255.74951z"/>
      <path class="megm-1" d="M367.44922,264.8833c-0.23926-0.73438-1.37549-1.29199-1.8042-2.1084
        C366.06934,263.59668,367.20117,264.15332,367.44922,264.8833z"/>
      <path class="megm-1" d="M341.66406,256.56445l0.59375,0.43164c0,0,10.40918-2.229,17.42822-3.28223
        c0.00049-0.00244,0.00049-0.00439,0.00049-0.00635c-7.02148,1.04785-17.43359,3.27979-17.43359,3.27979
        c-0.19043-0.15186-0.39258-0.28613-0.58545-0.43457c-0.08447,0.24854-0.15576,0.5-0.23193,0.75049
        C341.51074,257.05664,341.58105,256.80859,341.66406,256.56445z"/>
      <path class="megm-1" d="M360.40283,227.646c0.02637,0.18652,0.05859,0.37061,0.09912,0.55029
        C360.46191,228.0166,360.4292,227.83252,360.40283,227.646z"/>
      <path class="megm-1" d="M344.77344,215.23096c0.08936,0.16895,0.18896,0.32959,0.30469,0.47949
        C344.96191,215.56104,344.86279,215.39844,344.77344,215.23096z"/>
      <path class="megm-1" d="M360.56641,228.46094c0.01367,0.05225,0.02588,0.10449,0.03955,0.15625
        C360.59229,228.56543,360.57959,228.51318,360.56641,228.46094z"/>
      <path class="megm-1" d="M364.02051,180.01904l-0.00879,0.00049c0.02832,0.05859,0.05859,0.11621,0.07764,0.17676
        C364.06982,180.13623,364.04883,180.07715,364.02051,180.01904z"/>
      <path class="megm-1" d="M347.77148,216.99512c1.23584,0,1.18115,1.50391,1.0127,3.06592
        c0.17041-1.56689,0.22998-3.07959-1.0127-3.07959c-0.16699,0.00391-0.33789-0.00293-0.49512-0.02344
        c-0.90186-0.11133-1.67041-0.57568-2.18848-1.23633C345.71094,216.51953,346.68359,217.02686,347.77148,216.99512z"/>
      <path class="megm-1" d="M349.11621,223.47461c1.57813,0.45117,11.23047,1.14844,11.23047,3.46094
        c0,0.20264,0.01758,0.40039,0.0415,0.59619c-0.02441-0.2002-0.0415-0.40332-0.0415-0.61084
        c0-2.30566-9.65234-2.99902-11.23047-3.46191c-0.4873-0.14404-0.56104-0.82715-0.50684-1.69824
        C348.55371,222.64063,348.62549,223.33105,349.11621,223.47461z"/>
      <path class="megm-1" d="M347.07031,202.42773c-1.15918,1.02588-2.3833,1.97461-3.65381,2.8584
        C344.47168,204.65039,345.74951,203.78516,347.07031,202.42773z"/>
      <path class="megm-1" d="M344.55762,214.71094c0.05029,0.15576,0.10547,0.30859,0.17725,0.45313
        C344.6626,215.01953,344.6084,214.86572,344.55762,214.71094z"/>
      <path class="megm-1" d="M364.12988,180.36963c0.01758,0.11279,0.02539,0.22754,0.00977,0.3457
        C364.15283,180.59766,364.14941,180.48242,364.12988,180.36963z"/>
      <path class="megm-1" d="M344.43066,214.15381c0.01904,0.13379,0.03711,0.2666,0.0708,0.39453
        C344.4668,214.42041,344.4502,214.28711,344.43066,214.15381z"/>
      <path class="megm-1" d="M344.40771,213.29346c0.00049-0.00488,0-0.00928,0.00049-0.01416c0-1.84619-3.7959-4.84668-3.59082-6.00293
        l0,0C340.59375,208.43604,344.4043,211.43848,344.40771,213.29346z"/>
      <path class="megm-1" d="M363.87109,245.24023c-0.00244-0.01074-0.00732-0.02344-0.01025-0.03418
        C363.86377,245.2168,363.86865,245.22949,363.87109,245.24023z"/>
      <path class="megm-1" d="M359.56543,239.04736c-0.06738-0.08496-0.12842-0.17236-0.18896-0.26025
        C359.43701,238.87549,359.49805,238.9624,359.56543,239.04736z"/>
      <path class="megm-1" d="M344.39502,213.88232c-0.01123-0.18799-0.00928-0.37939,0.0127-0.57324
        c-0.02295,0.16064-0.02783,0.3252-0.02393,0.48682C344.38477,213.82568,344.39355,213.85303,344.39502,213.88232z"/>
      <path class="megm-1" d="M358.7041,237.36328c-0.02393-0.08643-0.04248-0.17383-0.06152-0.26172
        C358.66211,237.18945,358.67969,237.27637,358.7041,237.36328z"/>
      <path class="megm-1" d="M359.04346,238.23535c-0.04248-0.08398-0.0791-0.16943-0.11621-0.25488
        C358.96436,238.06641,359.00098,238.15137,359.04346,238.23535z"/>
      <path class="megm-1" d="M329.2207,222.19336l0.44922,2.11572C329.55957,223.40723,329.41064,222.67041,329.2207,222.19336z"/>
      <path class="megm-1" d="M358.54395,236.4668c-0.0083-0.09863-0.00977-0.19678-0.01172-0.29541
        C358.53467,236.27051,358.53564,236.36816,358.54395,236.4668z"/>
      <path class="megm-1" d="M328.82764,237.97754c-0.04932,0.08105-0.09912,0.15332-0.15137,0.20508
        C328.72803,238.12891,328.77832,238.06006,328.82764,237.97754z"/>
      <path class="megm-1" d="M328.96924,237.68604c-0.01221,0.0293-0.02441,0.06055-0.03711,0.0874
        C328.94482,237.74609,328.95703,237.71533,328.96924,237.68604z"/>
      <path class="megm-1" d="M477.75781,615.74707c0.67188-0.94629,2.97852-1.66797,4.69727-3
        c0.16309-0.12598,0.30664-0.26953,0.43555-0.42676c-0.12402,0.14844-0.26172,0.28418-0.41699,0.4043
        C480.74023,614.07031,478.40332,614.78809,477.75781,615.74707z"/>
      <path class="megm-1" d="M279.11426,597.70996c-0.25195-0.42871-0.40137-0.85352-0.40137-1.24023
        c0.02246-1.88379,0.23828-3.75781,0.62695-5.59863c-0.00342,0-0.00635-0.00098-0.01025-0.00195
        c-0.38916,1.83789-0.59912,3.70996-0.62158,5.5918C278.70801,596.85059,278.85938,597.27832,279.11426,597.70996z"/>
      <path class="megm-1" d="M291.98193,623.35156c-0.07764-0.11523-0.2085-0.23438-0.40088-0.35254
        c-1.04688-1.39648-1.90869-2.90918-2.57275-4.49902c0.66455,1.59375,1.52783,3.10938,2.57666,4.50879
        C291.77197,623.12402,291.90234,623.23926,291.98193,623.35156z"/>
      <path class="megm-1" d="M171.35303,604.08496c-0.40137-0.05957-0.84814-0.19238-1.33545-0.39453
        C170.43848,603.90918,170.88818,604.05566,171.35303,604.08496z"/>
      <path class="megm-1" d="M194.06445,583.43359c-0.51367,0.24121-0.99805,0.69238-1.47949,1.15234
        C193.06641,584.12891,193.55127,583.67773,194.06445,583.43359z"/>
      <path class="megm-1" d="M280.1333,598.97754c-0.10791-0.10645-0.19971-0.21875-0.2998-0.32813
        C279.93311,598.75879,280.02539,598.87109,280.1333,598.97754z"/>
      <path class="megm-1" d="M281.63184,591.58789c-0.57617-0.42676-1.16162-0.67773-1.72656-0.67773
        c-0.03125,0-0.05713-0.00488-0.0874-0.00488l0.09717,0.00977C280.47607,590.91504,281.05859,591.16406,281.63184,591.58789z"/>
      <path class="megm-1" d="M106.88672,626.49805c-0.26563-0.15527-0.5498-0.37891-0.85107-0.68164
        C106.26904,626.10645,106.55957,626.33594,106.88672,626.49805z"/>
      <path class="megm-1" d="M283.21094,600.93359c0.07031,0.04395,0.13086,0.09668,0.18994,0.15137
        C283.34131,601.03125,283.28125,600.97754,283.21094,600.93359z"/>
      <path class="megm-1" d="M279.29395,597.96777c0.0874,0.12793,0.16602,0.25684,0.26855,0.38379
        C279.45996,598.22461,279.38086,598.0957,279.29395,597.96777z"/>
      <path class="megm-1" d="M280.23926,599.07227c0.59717,0.56738,1.29395,1.07422,1.9668,1.41504
        C281.53174,600.14355,280.83496,599.6377,280.23926,599.07227z"/>
      <path class="megm-1" d="M272.31104,584.32813c-0.13721,0.01563-0.27197,0.04297-0.40479,0.08496
        C272.05518,584.37207,272.18311,584.34961,272.31104,584.32813z"/>
      <path class="megm-1" d="M109.09814,625.59766c-0.00342,0.00977-0.00732,0.01953-0.01074,0.0293
        C109.09082,625.61719,109.09473,625.60742,109.09814,625.59766z"/>
      <path class="megm-1" d="M133.36865,615.26074c-0.04248,0.02148-0.08594,0.0293-0.13086,0.01855
        C133.28271,615.28906,133.32617,615.2832,133.36865,615.26074z"/>
      <path class="megm-1" d="M163.44873,599.63184c-0.04053,0.06055-0.0835,0.11816-0.12061,0.18164
        C163.36523,599.75,163.40869,599.69238,163.44873,599.63184z"/>
      <path class="megm-1" d="M107.86182,626.77051c0.00586,0,0.01123-0.00195,0.0166-0.00293c-0.00537,0-0.01123,0.00098-0.01709,0.00098
        C107.86133,626.76953,107.86182,626.77051,107.86182,626.77051z"/>
      <path class="megm-1" d="M109.21094,625.23047c-0.00488,0.02148-0.01172,0.04199-0.01709,0.0625
        C109.19922,625.27246,109.20557,625.25195,109.21094,625.23047z"/>
      <path class="megm-1" d="M163.08301,600.32715c-0.04492,0.1123-0.08887,0.22559-0.12549,0.34375
        C162.99414,600.55273,163.03809,600.43945,163.08301,600.32715z"/>
      <path class="megm-1" d="M163.25342,599.95996c-0.0459,0.08691-0.09229,0.17383-0.13184,0.26563
        C163.16113,600.13379,163.20801,600.04688,163.25342,599.95996z"/>
      <path class="megm-1" d="M151.53369,629.97656c-0.04346-1.03711,0.51807-2.29004,0.50537-3.55078
        C152.04932,627.68555,151.4873,628.94043,151.53369,629.97656z"/>
      <path class="megm-1" d="M162.94141,600.73047c-0.04004,0.13477-0.07568,0.27051-0.10449,0.41016
        C162.86572,601.00195,162.90186,600.86523,162.94141,600.73047z"/>
      <path class="megm-1" d="M163.66064,599.35059c-0.02686,0.03125-0.05713,0.05957-0.08252,0.09277
        C163.60352,599.41016,163.63379,599.38184,163.66064,599.35059z"/>
      <path class="megm-1" d="M564.00977,622.30957c-0.68848-1.16992-1.30371-2.38379-1.83691-3.6377c0,0.00195,0,0.00391,0.00098,0.00586
        C562.70703,619.92969,563.32227,621.1416,564.00977,622.30957z"/>
      <path class="megm-1" d="M483.21094,611.84961c-0.04492,0.08008-0.10156,0.14941-0.15234,0.22461
        C483.1084,611.99902,483.16602,611.92969,483.21094,611.84961z"/>
      <path class="megm-1" d="M294.36914,220.22607c-0.06787,0.10791-0.13525,0.21484-0.18799,0.33105
        C294.2334,220.44238,294.30127,220.33398,294.36914,220.22607z"/>
      <path class="megm-1" d="M566.56055,626.01563c0.59668,0.74414,1.22852,1.46094,1.89355,2.14746
        C567.78906,627.47559,567.15723,626.75879,566.56055,626.01563z"/>
      <path class="megm-1" d="M564.11426,622.49512c0.15234,0.25391,0.31348,0.50195,0.47266,0.75098
        C564.42773,622.99609,564.2666,622.74902,564.11426,622.49512z"/>
      <path class="megm-1" d="M0,633.3457h0.46338c-0.14941-0.25293-0.30762-0.5-0.46338-0.74902V633.3457z"/>
      <path class="megm-1" d="M491.87305,594.66602c-1.88867,0-3.76465,5.16309-6.26465,7.73145
        c-0.16113,0.16602-0.30371,0.34668-0.43066,0.53809c0.12109-0.17969,0.25586-0.34863,0.4082-0.50586
        c2.51758-2.5791,4.39453-7.74121,6.27051-7.74121c1.24121-0.25586,2.31445-0.9209,3.08887-1.83789
        C494.17188,593.75684,493.10547,594.41406,491.87305,594.66602z"/>
      <path class="megm-1" d="M564.87695,623.70703c0.11914,0.17969,0.24609,0.35449,0.36914,0.53125
        C565.12305,624.06152,564.99609,623.88672,564.87695,623.70703z"/>
      <path class="megm-1" d="M281.93066,591.83789c0.08936,0.07617,0.17969,0.14258,0.26807,0.22559
        C282.11035,591.98047,282.02002,591.91406,281.93066,591.83789z"/>
      <path class="megm-1" d="M565.69336,624.88184c0.12793,0.17578,0.26465,0.3457,0.39648,0.51855
        C565.95801,625.22754,565.82129,625.05762,565.69336,624.88184z"/>
      <path class="megm-1" d="M364.31738,566.18848c0,3.21289-0.63281,5.79199-3.74609,6.43066c-3.11328,0.64258,0,7.71582-4.36719,7.71582
        c-4.37598,0,1.87012,5.15234-0.62988,7.72754c-2.49121,2.57324-1.87598,5.79199,1.25098,5.79199
        c3.125,0,1.87402,3.8623,1.87402,7.71777c0,3.85352,4.99316,6.43262,4.99316,6.43262l-1.24609,4.50781l0.30615,0.36914
        c-0.10254-0.12207-0.19922-0.24805-0.30225-0.36914l1.25586-4.50781c0,0-5-2.57031-5-6.43262
        c0-3.86523,1.25391-7.71777-1.87598-7.71777c-3.11816,0-3.74414-3.21875-1.24707-5.79199
        c2.50488-2.5752-3.74121-7.72754,0.62988-7.72754c4.36328,0,1.24512-7.08203,4.36328-7.71582
        c3.12988-0.63867,3.74609-3.20898,3.74609-6.43066c0-1.00488,0.66992-1.88379,1.64795-2.6377
        C364.98926,564.30566,364.31738,565.18457,364.31738,566.18848z"/>
      <path class="megm-1" d="M173.93555,601.72949c0,0.46875-0.0835,0.86621-0.2207,1.20996
        c0.18604-0.37012,0.27588-0.78906,0.2373-1.21875c0-2.16211,3.5957-6.4834,4.48633-7.39941
        c0.84766-0.87695,0.63184-6.1543,2.12207-8.04395c-1.50537,1.85938-1.28613,7.17285-2.13281,8.05273
        C177.52637,595.25586,173.93555,599.56836,173.93555,601.72949z"/>
      <path class="megm-1" d="M373.06445,560.40137c-0.55859,0.19043-1.56348,0.49902-2.69531,0.91895
        c1.1333-0.41992,2.14014-0.72754,2.69922-0.91895c1.87207-0.64551,1.24609-10.93848,1.24609-10.93848V520.875l-0.00879,28.58789
        C374.30566,549.46289,374.92676,559.74707,373.06445,560.40137z"/>
      <path class="megm-1" d="M275.94629,587.2666c0.07422,0.1123,0.14307,0.22852,0.21143,0.34473
        C276.09033,587.49414,276.02002,587.37891,275.94629,587.2666z"/>
      <polygon class="megm-1" points="445.43164,493.43066 448.69434,491.18164 445.42383,493.43066 		"/>
      <path class="megm-1" d="M537.1582,575.69043c-2.20703-0.33105-8.51758,0.64941-10.0918,0c-1.58496-0.65039-1.26758-1.63086-4.42188,0
        c-3.1582,1.62402-4.41211-0.98145-6.30957-2.60449c-1.8877-1.62598-3.78711-1.94141-4.73633,1.2998
        c-0.93164,3.21094-1.55957,4.82129-3.09668,3.31055c1.53516,1.52734,2.16797-0.0791,3.10645-3.30078
        c0.93945-3.25098,2.84277-2.93066,4.72949-1.29883c1.88574,1.62598,3.14844,4.22754,6.30664,2.59766
        c3.1582-1.62109,2.8418-0.64648,4.42188,0c1.57422,0.65918,7.88477-0.32617,10.0957,0
        c7.61328,6.67773,14.7793,13.83887,21.45313,21.45801l0.00195-0.00293C551.94531,589.5332,544.77246,582.36523,537.1582,575.69043
        z"/>
      <path class="megm-1" d="M372.20898,629.25488c0.07031-2.2793-1.83447-5.12598-2.26172-6.4541
        c-0.13867-0.22461-0.2876-0.44141-0.42822-0.66406c0.14063,0.22559,0.29004,0.44629,0.42822,0.67383
        C370.37402,624.13379,372.2749,626.97559,372.20898,629.25488z"/>
      <path class="megm-1" d="M558.93359,613.08301c-1.89258,1.2998-5.0498,1.62793-5.67969,0.32813
        c-0.65527-1.40039-0.95508-2.91504-0.90234-4.41797c-0.05469,1.50684,0.24512,3.02539,0.90234,4.42676
        c0.62988,1.2998,3.78711,0.9668,5.67969-0.32617c1.46484-1.01074,2.57031-0.43555,4.75195,1.68164
        c0.00098-0.00098,0.00098-0.00195,0.00195-0.00293C561.50586,612.64941,560.39648,612.06348,558.93359,613.08301z"/>
      <path class="megm-1" d="M500.00195,577.17383c0.00098,0.00391,0.00195,0.00781,0.00195,0.01172
        c2.80078-0.50293,5.69434-0.34668,8.44727,0.46289c-0.00391-0.00391-0.00684-0.00586-0.00977-0.00879
        C505.70215,576.83105,502.81348,576.66992,500.00195,577.17383z"/>
      <path class="megm-1" d="M275.42285,586.59766c0.15479,0.15918,0.28906,0.33496,0.41748,0.51563
        C275.71289,586.93164,275.57715,586.75684,275.42285,586.59766z"/>
      <path class="megm-1" d="M25.97656,569.97656c-0.22705,0.14453-0.49463,0.22559-0.83252,0.19043
        C25.48242,570.20313,25.74951,570.12207,25.97656,569.97656z"/>
      <path class="megm-1" d="M16.15234,570.56934c0-1.92871,0.64453-4.2998,2.521-4.22559
        c-1.87793-0.07813-2.52588,2.2959-2.52588,4.22559c0,2.25586-2.35254,3.29004-5.39404,3.36816l0,0
        C13.79492,573.86816,16.15234,572.8252,16.15234,570.56934z"/>
      <path class="megm-1" d="M50.63086,610.66504c1.44336-0.50098-1.77197-2.00195-3.07617-3.69922
        c1.30615,1.7002,4.51074,3.19727,3.07227,3.69531c-1.46484,0.49902-1.93945,2.63086-0.93457,3.41113
        C48.69873,613.28906,49.1748,611.16699,50.63086,610.66504z"/>
      <path class="megm-1" d="M47.51904,606.92383c-0.08447-0.11328-0.15527-0.22754-0.2207-0.34277
        C47.36377,606.69629,47.43457,606.81055,47.51904,606.92383z"/>
      <path class="megm-1" d="M20.39844,600.78223c0.01367-0.24609,0.00928-0.49316,0.02002-0.74023l-0.03076,0.74023
        c0,0,0.79004,3.4707,2.05371,3.43945C21.18506,604.23828,20.39844,600.78223,20.39844,600.78223z"/>
      <path class="megm-1" d="M15.01367,585.6709c-1.17871-1.94336-1.70313-4.21289-1.49805-6.48047l-1.8623-2.8457l-0.02637-0.06934
        c0.01318,0.04883,0.02051,0.07813,0.02051,0.07813l1.85645,2.83691c-0.20605,2.27148,0.32617,4.53711,1.49805,6.48438
        c2.17676,1.93652,3.99316,4.23145,5.39648,6.78809C19.00781,589.89941,17.18262,587.60645,15.01367,585.6709z"/>
      <path class="megm-1" d="M80.47803,565.17188c-0.09619,0.1084-0.20068,0.19434-0.31396,0.25293
        C80.27734,565.36621,80.38184,565.28125,80.47803,565.17188z"/>
      <polygon class="megm-1" points="68.81641,461.06543 78.34668,553.26465 81.52637,556.53613 78.35156,553.26465 		"/>
      <path class="megm-1" d="M28.56201,566.77734c-0.47119,0.02148-0.84229,0.16797-1.06787,0.52148
        C27.72314,566.94727,28.09326,566.80078,28.56201,566.77734z"/>
      <path class="megm-1" d="M40.66406,565.8916c-0.00049,0-0.00684,0-0.00684,0c-1.97705,1.02148-4.1377,1.65918-6.35205,1.87109
        c0.00146,0,0.00293,0,0.00439,0C36.5293,567.55078,38.68262,566.91309,40.66406,565.8916z"/>
      <path class="megm-1" d="M60.60498,620.02344c0.88379,0.5459,1.74072,1.51367,2.2876,2.35938
        c0.0752,0.11621,0.16113,0.21582,0.25635,0.30078c-0.09277-0.08398-0.17725-0.18164-0.25049-0.2959
        C62.34961,621.53711,61.49072,620.56738,60.60498,620.02344z"/>
      <path class="megm-1" d="M162.83887,603.4375c0.00342,0.00293,0.00684,0.00488,0.00977,0.00781
        c-0.15869-0.7334-0.16602-1.53906-0.01514-2.28516C162.68213,601.90234,162.68311,602.7041,162.83887,603.4375z"/>
      <path class="megm-1" d="M160.17676,607.56738c-1.2002,1.54395-6.58691,1.8457-8.38477,1.8457
        c-1.79688,0-3.88184,3.40332-5.68262,4.01563c-1.43115-0.01465-2.85742-0.11914-4.2749-0.29297
        c0.96436,0.19531,1.88623,0.35742,2.67041,0.40918c0,0.00391,0.00049,0.00586,0.00049,0.00977l1.604-0.11133
        c1.80078-0.61328,3.8916-4.01855,5.68262-4.01855c1.79395,0,7.18848-0.31348,8.38477-1.85156
        c1.19922-1.53027,3.29785-1.53711,2.69531-4.00684c-0.00244-0.00977-0.00342-0.01953-0.00537-0.0293l0.00049,0.02441
        C163.46191,606.02246,161.37598,606.0127,160.17676,607.56738z"/>
      <path class="megm-1" d="M163.87061,599.12109c0.00049,0,0.00146-0.00098,0.00195-0.00098
        C163.87207,599.12012,163.87109,599.12109,163.87061,599.12109z"/>
      <path class="megm-1" d="M51.5249,614.74512c0.93262,1.04883,0.51855,3.98047,1.48584,5.48145l0.01855,0.01953
        c-0.00244-0.00391-0.00537-0.00684-0.00781-0.01074C52.04785,618.72754,52.46387,615.79004,51.5249,614.74512z"/>
      <path class="megm-1" d="M132.93359,614.96387c-8.35156-1.89453-17.00781-2.10254-25.43262-0.61328
        c-0.00146,0.00195-0.00195,0.00488-0.00342,0.00781c8.43408-1.49219,17.07568-1.2832,25.43604,0.61426
        c0.11279,0.19629,0.21094,0.28516,0.30371,0.30664C133.14502,615.25586,133.04639,615.1582,132.93359,614.96387z"/>
      <path class="megm-1" d="M109.27539,624.92676c0.00439-0.02539,0.01123-0.04785,0.01563-0.07422
        c0.00195-0.0127,0-0.03027,0.00146-0.04297c-0.00146,0.01172,0.00049,0.02734-0.00146,0.03809
        C109.28662,624.875,109.28027,624.90039,109.27539,624.92676z"/>
      <path class="megm-1" d="M73.67773,618.99023c0.09375-0.87109-0.31494-1.37695-0.9375-1.58594
        c0.61475,0.21094,1.01807,0.7168,0.92676,1.58203c-0.06934,0.67773-0.06836,1.22461,0.01563,1.63672
        C73.60156,620.21191,73.60303,619.66602,73.67773,618.99023z"/>
      <path class="megm-1" d="M154.70215,513.99512c2.30664,0.30469,4.57129,0.85742,6.7583,1.62988
        c-2.18506-0.77344-4.44727-1.32617-6.75342-1.62988c-1.80469-3.39063-3.94824-6.58887-6.41016-9.53809
        c-2.84473-2.9375-93.22949-64.55469-98.21484-67.49121c-0.8125-0.43457-1.49805-1.08105-1.97363-1.87402
        c-0.16895-6.83105-0.39844-13.57031-0.23242-14.2334c5.06641-4.0791,10.47949-7.71973,16.17383-10.87793
        c2.30762-1.36523,6.58984,0.68359,6.58984-2.04102c0-1.36035,1.40088-1.7002,3.21484-1.78516
        c-1.82129,0.08398-3.22852,0.42188-3.22852,1.78516c0,2.7168-4.2832,0.67578-6.59473,2.04102
        c-5.6875,3.1582-11.0957,6.79883-16.16602,10.87793c-0.16309,0.66016,0.05566,7.39941,0.22461,14.22852l0,0
        c0,0.00195,0,0.00391,0.00049,0.00488c0.4834,0.79297,1.1665,1.43945,1.98584,1.87402
        c1.98438,1.16992,17.58984,11.7002,35.79102,24.09961c27.40625,18.67969,60.71875,41.62207,62.42188,43.3916
        C150.74805,507.40625,152.89648,510.60449,154.70215,513.99512z"/>
      <path class="megm-1" d="M94.02734,627.30859c-2.69629,3.08887-8.0791-2.77441-8.97363-4.31348
        c-0.00684-0.00977-0.01465-0.01855-0.02148-0.02832c0.00635,0.00977,0.01563,0.02246,0.02148,0.03223
        c0.89844,1.53906,6.2832,7.40723,8.97363,4.31836c3.32031-2.85449,8.02783-3.46582,11.96484-1.54199
        c-0.00049,0-0.00049-0.00098-0.00098-0.00098C102.05469,623.8457,97.34961,624.44727,94.02734,627.30859z"/>
      <path class="megm-1" d="M43.1416,598.01074L43.1416,598.01074c2.11914,2.06445,3.48486,4.77148,3.88867,7.69531
        c0,0.27441,0.08594,0.54785,0.23145,0.81836c-0.14355-0.26758-0.22754-0.53809-0.22754-0.80859
        C46.63281,602.79004,45.26367,600.07129,43.1416,598.01074z"/>
      <path class="megm-1" d="M233.53027,402.88672c-0.00977,0.00977-0.02002,0.01855-0.03027,0.02734
        C233.51025,402.90527,233.52051,402.89648,233.53027,402.88672z"/>
      <path class="megm-1" d="M233.03076,403.41895c-0.01807,0.02344-0.03125,0.04785-0.04785,0.07129
        C232.99902,403.4668,233.0127,403.44238,233.03076,403.41895z"/>
      <path class="megm-1" d="M138.83301,571.66016c-2.05664-0.11523-4.1001-0.47266-6.08643-1.07813
        C134.64746,571.45313,136.74365,571.82227,138.83301,571.66016z"/>
      <path class="megm-1" d="M138.92627,571.67578c0.55762,0.02637,1.11621,0.03516,1.67383,0.02637
        c-0.55811,0.00684-1.11572-0.00488-1.67236-0.03418C138.92725,571.66992,138.92676,571.67285,138.92627,571.67578z"/>
      <path class="megm-1" d="M232.86133,403.68848c-0.0127,0.02637-0.01758,0.05176-0.02832,0.07813
        C232.84375,403.74023,232.84863,403.71484,232.86133,403.68848z"/>
      <path class="megm-1" d="M47.86523,450.11328c-0.99902,0.67969-33.99023,0-33.99023,0v0.00488c0,0,33.00391,0.68262,33.99023,0
        c0.50439-4.76563,0.57959-9.56641,0.26025-14.34668C48.44385,440.54883,48.35938,445.34473,47.86523,450.11328z"/>
      <path class="megm-1" d="M10.63281,573.69043c-0.36816-0.84961-0.8623-1.62695-1.45996-2.31348
        C9.78564,572.12793,10.26367,572.94043,10.63281,573.69043z"/>
      <path class="megm-1" d="M4.70996,598.84082c-0.09375-0.09766-0.15625-0.19727-0.18457-0.29883
        C4.55322,598.64355,4.61621,598.74316,4.70996,598.84082z"/>
      <path class="megm-1" d="M8.83252,570.99219c0.04346,0.04883,0.08691,0.09668,0.12939,0.14551
        C8.91748,571.09082,8.87793,571.03906,8.83252,570.99219z"/>
      <path class="megm-1" d="M84.82813,399.77734c0-3.06836,4.625-2.38574,7.59082-2.72852
        c2.96484-0.33984,2.63574-3.05859,4.61719-3.05859c1.97363,0,0.98633-3.06445,1.31641-6.46289
        c0.24756-2.54102,0.31104-2.80176,1.56641-2.77148c-1.271-0.0332-1.3335,0.21729-1.58203,2.77148
        c-0.32617,3.4043,0.65625,6.46289-1.31348,6.46289c-1.98438,0-1.65527,2.71875-4.62012,3.05859
        c-2.96875,0.34277-7.58984-0.33984-7.58984,2.72852c0.0332,3.07422-2.22852,5.69043-5.26855,6.11426
        c-0.82617,0.17188-1.79492,0.2168-2.78369,0.22852c0.99414-0.01172,1.96826-0.05566,2.79736-0.22852
        C82.60254,405.46777,84.8584,402.85156,84.82813,399.77734z"/>
      <path class="megm-1" d="M235.0166,401.74805c0.02783-0.01953,0.05518-0.03906,0.0835-0.05859
        c-0.02979,0.01758-0.05615,0.03809-0.08594,0.05566C235.01514,401.74609,235.01563,401.74707,235.0166,401.74805z"/>
      <path class="megm-1" d="M215.20117,505.91699l-18.50586,15.4043c0,0-12.10254,4.4043-15.65918,5.13281l-1.31836,0.29297
        l1.30566-0.27539c3.56934-0.7373,15.66699-5.13672,15.66699-5.13672l18.50586-15.40234l43.41797-27.87988
        c5.13184-0.10059,10.22754,0.89453,14.94434,2.93262c-4.67188-2.04297-9.73633-3.05078-14.83301-2.93652
        c-0.00098-0.00293-0.00195-0.00684-0.00293-0.00977h-0.10352L215.20117,505.91699z"/>
      <path class="megm-1" d="M233.20801,403.20703c0.01758-0.01953,0.03467-0.03906,0.05273-0.05859
        C233.24268,403.16797,233.22559,403.1875,233.20801,403.20703z"/>
      <path class="megm-1" d="M213.61621,356.37012c-0.14404,0.04102-0.29053,0.0625-0.43945,0.0625
        c-0.15039,0-0.28174,0.0376-0.39502,0.10693c0.10938-0.06299,0.2334-0.1001,0.37646-0.1001
        C213.31348,356.43945,213.46582,356.41406,213.61621,356.37012z"/>
      <path class="megm-1" d="M107.58496,381.07031c0-0.76514,1.80713-1.05322,4.17188-1.11426
        c-2.37256,0.06006-4.18848,0.34766-4.18848,1.11426c0,2.04004-2.96338,3.7373-5.93066,3.73926c0.00488,0,0.00879,0,0.01367,0
        C104.61816,384.80957,107.58496,383.11621,107.58496,381.07031z"/>
      <path class="megm-1" d="M167.42871,518.69141c3.62988,3.44922,3.64258,9.97461,3.64258,9.97461l0.00391-0.00098
        C171.0752,528.65625,171.06787,522.13086,167.42871,518.69141z"/>
      <polygon class="megm-1" points="179.67969,526.75977 179.67969,526.75488 172.39844,528.37207 		"/>
      <path class="megm-1" d="M152.27246,568.31641c0.42383-0.0166,0.8584,0.00781,1.30273,0.08398c0.00342,0,0.00684,0,0.00977-0.00098
        C153.1377,568.32324,152.69922,568.29883,152.27246,568.31641z"/>
      <path class="megm-1" d="M241.88867,384.97754c-1.21094,1.21387-1.56934,3.04297-0.89551,4.62891
        c0.22559,2.88574-0.40527,5.77832-1.79785,8.32813c-0.53955,0.76465-1.15918,1.46631-1.84424,2.09668
        c0.77734-0.6416,1.46143-1.33594,1.85303-2.09668c1.38867-2.5498,2.01465-5.44238,1.79395-8.32813
        c-0.67383-1.58594-0.32031-3.41504,0.89355-4.62891c1.35645-1.23438,6.73926-7.09375,7.78223-7.55859
        c0.27832-0.12354,0.72754-0.1709,1.23242-0.23633c-0.5083,0.06543-0.96143,0.1123-1.24121,0.23633
        C248.62598,377.88379,243.23242,383.74316,241.88867,384.97754z"/>
      <path class="megm-1" d="M275.40234,572.10547c-0.89844,5.55664-2.09375,3.39063-2.09375,8.94141l-0.05371,3.3125
        c0.00732,0.00195,0.01465,0.00488,0.02197,0.00781l0.05322-3.32324c0-5.56543,1.19727-3.39551,2.0957-8.95215
        c3.75879-7.20215,8.6582-13.73926,14.48389-19.39453C284.08008,558.35059,279.17383,564.89648,275.40234,572.10547z"/>
      <polygon class="megm-1" points="491.17773,367.05811 488.46484,365.875 491.52246,367.21729 491.52246,367.21484 
        491.17578,367.06152 		"/>
      <path class="megm-1" d="M488.25684,374.13721c0.80176-0.09521,1.6123-0.09326,2.41602,0.01318l-0.33594,1.99512
        c0.31641-1.32227,0.79785-5.33301,1.17676-8.84521l-0.83203,6.8501C489.87598,374.04395,489.06152,374.04102,488.25684,374.13721z
        "/>
      <path class="megm-1" d="M490.33008,376.18555c-0.2998,1.25098-0.45508,4.99365-0.75879,8.71973
        C489.97266,382.01611,490.22559,379.10645,490.33008,376.18555z"/>
      <path class="megm-1" d="M506.55469,326.26123c-0.02832-0.01123-0.05566-0.0249-0.08398-0.03662
        C506.49902,326.23584,506.52637,326.25,506.55469,326.26123z"/>
      <path class="megm-1" d="M506.11035,326.06592c-0.06934-0.03418-0.13574-0.07373-0.20313-0.11035
        C505.97461,325.99219,506.04102,326.03174,506.11035,326.06592z"/>
      <path class="megm-1" d="M493.70313,412.60254c-0.34766-0.09961-0.68652-0.22559-1.03125-0.33594
        c0.34473,0.11426,0.68359,0.24414,1.03125,0.34863c3.74219-0.0459,6.99902-2.57031,7.98828-6.17969c0-0.00098,0-0.00098,0-0.00195
        C500.69629,410.03906,497.44434,412.55566,493.70313,412.60254z"/>
      <path class="megm-1" d="M514.08496,398.58984c-2.39551,1.23633-2.79102,4.94629-5.99414,4.94629
        c-2.80469,0-5.6084,0.31738-6.25488,2.06348c0.6582-1.73535,3.45801-2.05273,6.25488-2.05273
        c3.19824,0,3.58887-3.70801,5.99121-4.94336c0.37793-0.19482,0.50879-0.53174,0.45215-0.96387
        C514.58789,398.06543,514.45703,398.39746,514.08496,398.58984z"/>
      <path class="megm-1" d="M523.5752,364.65234c-2.52148,1.32813-4.89063,2.5542-6.87402,3.54492
        C519.03027,367.09619,521.32422,365.9165,523.5752,364.65234z"/>
      <path class="megm-1" d="M516.48828,382.93164c-5.49707,0.29004-9.29297,2.91504-10.3457,4.79248
        c1.05957-1.87842,4.85059-4.49414,10.3457-4.77588c7.99219-0.41504,5.99414-3.29883,10.38867-3.29883
        c0.08008-0.00586,0.16113-0.00781,0.24121-0.01367h-0.24121C522.49121,379.63574,524.48047,382.51367,516.48828,382.93164z"/>
      <path class="megm-1" d="M571.77148,275.99023c-0.00488-0.03711-0.00879-0.07373-0.01367-0.11035
        C571.76172,275.91602,571.7666,275.95313,571.77148,275.99023z"/>
      <path class="megm-1" d="M564.4209,264.15332c2.14063,0.1958,3.34473,3.20508,5.53125,6.21289
        C567.76465,267.35742,566.56348,264.34766,564.4209,264.15332z"/>
      <path class="megm-1" d="M505.68555,325.83545c-0.06152-0.03711-0.11914-0.07861-0.17969-0.11719
        C505.56543,325.75684,505.62402,325.79834,505.68555,325.83545z"/>
      <path class="megm-1" d="M488.74902,410.75781c-0.55957-0.25586-1.1123-0.52539-1.65723-0.80957
        C487.64063,410.22656,488.1875,410.50488,488.74902,410.75781z"/>
      <path class="megm-1" d="M589.01465,268.17383c-0.00195-0.05518-0.00879-0.1123-0.00488-0.16553
        C589.00684,268.06152,589.01172,268.11816,589.01465,268.17383z"/>
      <path class="megm-1" d="M589.08496,268.62793c-0.00488-0.0166-0.00879-0.0332-0.0127-0.0498
        C589.07617,268.59473,589.08008,268.61133,589.08496,268.62793z"/>
      <path class="megm-1" d="M579.49902,299.146c-0.68457-2.09424-1.42188-5.45557-0.70117-6.72559
        C578.07324,293.68799,578.8125,297.05176,579.49902,299.146z"/>
      <path class="megm-1" d="M520.48535,324.04492c-0.40137-0.51807-0.72656-0.82715-1.05957-0.96729
        c0.33105,0.14209,0.65625,0.45117,1.05566,0.96533c0.2002,0.25732,0.43164,0.45605,0.69629,0.60742
        C520.91504,324.49902,520.68457,324.30078,520.48535,324.04492z"/>
      <path class="megm-1" d="M505.2793,325.57031c-0.01953-0.01367-0.03711-0.0293-0.05664-0.04297
        C505.24219,325.54102,505.25977,325.55664,505.2793,325.57031z"/>
      <path class="megm-1" d="M579.5166,310.64307c-0.27637-2.42822,1.07715-8.32861,0.57227-9.9209
        C580.58203,302.31836,579.23438,308.21777,579.5166,310.64307z"/>
      <path class="megm-1" d="M604.02539,297.26904c-0.2666,0.11572-0.53809,0.21729-0.81055,0.31787
        C603.48828,297.48584,603.75781,297.38184,604.02539,297.26904z"/>
      <path class="megm-1" d="M602.4668,297.85205c-0.41797,0.13135-0.83887,0.25098-1.26465,0.34717
        C601.62891,298.09814,602.05176,297.98584,602.4668,297.85205z"/>
      <path class="megm-1" d="M586.77051,291.80518c-0.00977-0.02344-0.01758-0.04688-0.02734-0.0708
        C586.75293,291.7583,586.76074,291.78174,586.77051,291.80518z"/>
      <path class="megm-1" d="M587.05273,292.3916c-0.00586-0.00977-0.01074-0.02002-0.0166-0.03027
        C587.04199,292.37158,587.04688,292.38184,587.05273,292.3916z"/>
      <path class="megm-1" d="M586.5918,291.31445c-0.27246-0.82861-0.41992-1.67822-0.68262-2.43213
        C586.16699,289.63721,586.31641,290.48584,586.5918,291.31445z"/>
      <path class="megm-1" d="M585.88477,288.81055c-0.14648-0.52539-0.42773-0.98535-0.79688-1.35645
        C585.46094,287.83545,585.7041,288.29834,585.88477,288.81055z"/>
      <path class="megm-1" d="M754.75586,333.78076c-0.21875-1.7959-0.37109-3.60059-0.41699-5.4126
        c0-0.10059-0.02246-0.19385-0.06152-0.28174c0.0332,0.08252,0.05273,0.16992,0.05273,0.26318
        C754.37988,330.16748,754.53418,331.97803,754.75586,333.78076z"/>
      <path class="megm-1" d="M735.97461,318.35449c-0.08691-0.25-0.15234-0.51025-0.19336-0.78027
        c0-0.12061-0.02637-0.23779-0.07227-0.35303c0.04102,0.10889,0.06348,0.21973,0.06348,0.3335
        C735.81543,317.83154,735.88379,318.09863,735.97461,318.35449z"/>
      <path class="megm-1" d="M610.91992,293.15137c-2.14258,1.37988-4.32227,2.70361-6.54199,3.96191
        C606.76563,296.05273,608.94727,294.58057,610.91992,293.15137z"/>
      <path class="megm-1" d="M600.48242,265.73535c-0.24121-0.42236,0.00977-1.09326,0.49121-1.84424
        c-0.48438,0.75244-0.7373,1.4248-0.49121,1.84717c0.67773,1.15527,3.02832,2.30762,3.53613,3.46436
        C603.5127,268.04297,601.16113,266.88672,600.48242,265.73535z"/>
      <path class="megm-1" d="M580.2959,291.63574c0,0.00049-0.00098,0.00098-0.00098,0.00195
        C580.29492,291.63672,580.29492,291.63623,580.2959,291.63574z"/>
      <path class="megm-1" d="M606.85938,407.66113c-1.35449,2.5293-2.53125,5.14941-3.50195,7.84863
        c0.00391,0.00098,0.00781,0.00098,0.01172,0.00195C604.33984,412.8125,605.5127,410.19238,606.85938,407.66113z"/>
      <path class="megm-1" d="M531.23438,379.49414c1.05566,0.00342,2.11133,0.04297,3.16699,0.10645
        C533.34668,379.53271,532.29102,379.49219,531.23438,379.49414z"/>
      <path class="megm-1" d="M505.94531,388.16357c-0.00488,0.01465-0.01563,0.03076-0.01953,0.04492
        C505.92969,388.19434,505.94043,388.17822,505.94531,388.16357z"/>
      <path class="megm-1" d="M491.10547,411.7373c-0.07422-0.02832-0.14746-0.06152-0.22168-0.08984
        C490.95801,411.67578,491.03125,411.70898,491.10547,411.7373z"/>
      <path class="megm-1" d="M582.52637,321.68018c-1.91699-0.46631-4.61328-2.5542-6.57031-3.32715
        C577.91895,319.13428,580.61523,321.22266,582.52637,321.68018z"/>
      <path class="megm-1" d="M588.41602,328.15625c0.3584,2.64209,2.11816,4.85156,4.53711,5.83594
        c-2.41504-0.98633-4.16309-3.19189-4.52344-5.83496c0-2.76416-3.67871-6.66455-3.62012-8.06543
        c-0.00391,0.02393-0.00781,0.04639-0.0127,0.06934C584.86523,321.62354,588.41602,325.43945,588.41602,328.15625z"/>
      <path class="megm-1" d="M580.03516,291.82129c-0.00684,0.00342-0.01172,0.00879-0.01855,0.01172
        C580.02344,291.83008,580.02832,291.82471,580.03516,291.82129z"/>
      <path class="megm-1" d="M598.01367,338.04492c-0.80176,2.47559,1.19336,6.1748-1.20215,6.1748
        c-2.3916,0-1.99512,7.00977-3.99316,8.24609s-0.39648,8.64844,1.2002,9.47363c1.60254,0.82031,5.19434,2.89551,4.79883,5.77051
        c-0.12695,0.89844,0.64844,1.95996,1.85938,3.00732c-1.20313-1.04443-1.97363-2.104-1.84961-3.00537
        c0.39941-2.88184-3.18848-4.94238-4.79004-5.77051c-1.59668-0.82227-3.19727-8.23145-1.20215-9.47168
        c1.99121-1.23828,1.58984-8.24609,3.99512-8.24609c2.39648,0,0.39941-3.69824,1.20215-6.1709
        c0.54785-1.70654-0.61035-2.82422-2.03711-3.35352C597.41602,335.23096,598.56543,336.34668,598.01367,338.04492z"/>
      <path class="megm-1" d="M594.01855,334.33496c-0.01953-0.00488-0.03809-0.01367-0.05762-0.01855
        C593.98047,334.32129,593.99902,334.33008,594.01855,334.33496L594.01855,334.33496z"/>
      <path class="megm-1" d="M277.7334,171.6626l0.00488-0.02881c-1.91455-1.77344-3.93164-3.42627-6.02441-4.97949h-0.0083
        c2.09326,1.55322,4.11035,3.20605,6.01904,4.97949C277.72998,171.64355,277.72852,171.65283,277.7334,171.6626z"/>
      <path class="megm-1" d="M8.27295,627.58008c0.00635-0.02051,0.00684-0.03809,0.01221-0.05859
        C8.27979,627.54199,8.2793,627.55957,8.27295,627.58008z"/>
      <path class="megm-1" d="M8.37988,626.98633c0.00977-0.0625,0.01563-0.13281,0.02783-0.19043
        C8.39551,626.85352,8.38916,626.92383,8.37988,626.98633z"/>
      <path class="megm-1" d="M9.27441,625.63574c0.00293-0.00098,0.00537-0.00293,0.0083-0.00488
        C9.27979,625.63281,9.27734,625.63379,9.27441,625.63574z"/>
      <path class="megm-1" d="M9.04199,625.74707c-0.30225,0.1875-0.51953,0.51465-0.63135,1.03809
        C8.52344,626.26172,8.74072,625.93555,9.04199,625.74707z"/>
      <path class="megm-1" d="M20.68848,624.46094c-0.60938,0-1.27002,0.15625-1.95752,0.37207
        c0.68701-0.21484,1.34766-0.37109,1.95898-0.37207C20.68945,624.46094,20.68896,624.46094,20.68848,624.46094z"/>
      <path class="megm-1" d="M8.12158,627.96387c0.01514-0.03125,0.02246-0.05859,0.03564-0.08887
        C8.14404,627.90527,8.13672,627.93262,8.12158,627.96387z"/>
      <path class="megm-1" d="M753.89941,345.85303c-0.11133-1.19385,0.11719-2.17383,0.52051-2.95068
        C753.93652,343.78467,753.73145,344.81445,753.89941,345.85303z"/>
      <path class="megm-1" d="M3.17871,431.09961c1.99707-1.95996,4.55957-3.25195,7.33105-3.70215
        c8.07617-1.38965,9.72559-7.24512,14.81445-10.33008c5.08691-3.08984,13.91113-13.72363,11.96875-17.12695
        c-1.93945-3.39648-3.74707-12.81152,0-15.89355c3.74023-3.07813,2.5459-7.24121,5.69336-9.87109
        c3.13574-2.62305,3.58301-8.94043,7.62207-8.94043c5.93555-0.4082,11.46582-3.125,15.41504-7.56641
        c3.00684-4.46973,8.25098-19.90039,11.08203-21.28809c2.82813-1.38672,5.53125,0,9.42773,4.47949
        c3.88867,4.47266,11.96582,1.69629,17.20508,1.85156c3.7627,0.10449,8.13965,2.9043,11.74512,2.83301l1.66016,3.21289
        c2.17676,3.19629,3.21387,7.02539,2.96777,10.87793c0,4.08691-1.31348,9.1875,0.66504,9.52441
        c1.97559,0.33887,3.62598,5.44922,3.95703,7.14648c0.33105,1.70068-0.31982,4.0752-2.31055,4.07666
        c0.00488,0,0.01172,0.00049,0.0166,0.00049c1.97852,0,2.63379-2.375,2.30957-4.07715
        c-0.33105-1.70508-1.97852-6.80762-3.95215-7.14648c-1.98242-0.33691-0.66992-5.4375-0.66992-9.52441
        c0.24805-3.85254-0.79492-7.6875-2.96582-10.88574L115.5,345.53711c1.46973,0.01367,2.87695-0.62695,3.82031-1.75586
        c4.09473-4.63379,9.72559-7.64355,15.86133-8.48047c4.64063,0,7.93262-8.33008,15.71777-8.64551
        c12.55859-2.07813,25.29004-2.85156,38.01172-2.30566c2.69141,2.62598,10.77539-2.77734,14.06738-2.92871
        c3.40625-0.66113,6.86133-1.02637,10.32617-1.07617c3.07422,1.10059,6.28809,1.73535,9.54199,1.90039
        c-0.00049,0.00195-0.00049,0.00342-0.00098,0.00537c1.94971,0.17969,3.88525-0.396,5.4248-1.60596
        c3.74121-3.54199,13.47949-2.92676,13.91895,0.77637c0.44531,3.70215,3.12988,3.24609,4.78027,1.69629
        c1.65625-1.5459,4.49414,0.30957,1.3457,3.7041c-3.13965,3.38867-6.28809,5.39453-1.79395,9.0957
        c0.38086,0.33887,0.73633,0.7041,1.05566,1.09766c3.20313,3.94922,2.60059,9.74414-1.34863,12.94629
        c-4.20117,3.54199-8.68555,3.38184-7.93359,7.24219c0.75488,3.86426,6.44531,5.40039,7.93359,3.5498
        c1.49316-1.84766,4.1875,1.55078,4.1875,3.55273c0,0.93359,1.47461,2.82031,3.89063,4.43359
        c-0.21191,1.80469-0.31055,3.62012-0.29688,5.43652c0.08545,1.91699-0.96631,2.55664-2.09033,2.83203
        c1.12842-0.27539,2.18896-0.91309,2.10791-2.8291c-0.01758-1.81934,0.08594-3.63477,0.2959-5.43945
        c3.42773,2.30078,7.60156,3.2002,11.67578,2.50781c8.97656-1.54492,13.32031,2.78906,18.4082,3.85645
        c5.08691,1.06934,7.02539,2.62207,6.58008,6.01465s3.88965,7.40723,10.03418,7.40723
        c6.8623-0.04004,13.50977,2.43262,18.69922,6.94043c0.27441,0.29883,0.56738,0.58691,0.86816,0.84961
        c4.33594,3.78223,10.91504,3.33594,14.70215-1.00293c3.28711-4.47656-1.49316-9.26758-1.35449-13.58008
        c0.14941-4.31348,12.71582-12.4834,22.5957-11.86816c9.87207,0.61133,6.59277,6.77539,15.4209,8.16602
        c8.8291,1.39746,12.85938,1.5498,13.60938,3.70215l0.29297,0.40723l-0.00195,0.00586
        c4.6499,2.63281,9.93311,3.93262,15.27539,3.76074c6.44043,0.77637,12.70508,2.60059,18.55664,5.4043
        c4.49316,1.85059,9.43555,1.85059,11.37402-1.08008c6.29688-3.90527,13.88379-5.17969,21.11621-3.54785
        c3.68652,2.5957,8.27441,3.59863,12.71387,2.77734c4.64453-1.24023,9.58301,1.85059,10.7793,0.6123l1.1377-1.64941
        c3.60352-5.74121,6.30176-12.00195,7.99219-18.55859c0.3291-1.69336,0.87109-3.3335,1.61719-4.88281l-0.01172-0.00488
        c3.04492-4.93945,5.55859-10.17871,7.52539-15.63672l0.10156-1.37109c0.46875-3.28613,2.37207-5.68652,0.7832-7.72754
        c-1.06543-1.37695-0.33594-3.35156,0.08984-5.25195c0.00195,0,0.00391-0.00098,0.00586-0.00098
        c0.41211-1.16357,0.3252-2.43652-0.22559-3.53027c-1.65527-2.47363,4.4873-7.24512,2.69336-8.33105
        c-1.80273-1.08203-4.9375,0.61035-7.7793,2.15234c-2.84766,1.5498-5.99121-3.07813-7.78516-2.00195
        c-1.80469,1.08008-6.28809,6.1709-9.43652,6.1709c-3.13867,0-8.07813,2.77734-10.46973,1.85156
        c-2.39355-0.92578-8.83691-7.87305-13.77051-8.33008c-4.94434-0.46387-8.23633,0.46484-8.23633,3.85938
        c0,3.39063-11.08398,5.55371-13.02344,2.46582c-1.94336-3.08594-3.14355-7.55469-6.28711-6.63086
        c-3.13477,0.92676-5.08691,0.30664-5.08691-1.54492c0-1.85938-6.45898-2.62988-6.59473-4.16406
        c-0.13867-1.54199-7.92871-4.93457-5.08496-5.39648c2.8418-0.46094,2.39746-3.8623,0-5.71289
        c-0.14355-0.12207-0.2793-0.24707-0.41504-0.38379c-1.86133-1.92676-1.8125-4.99805,0.11719-6.86133
        c1.93848-1.85059-1.94336-6.1748-0.9043-7.7168c1.04883-1.53809-0.75098-3.23828-4.19043-2.92578
        c-3.44141,0.30176-1.94336-5.86816,2.0957-7.71875c4.03906-1.84961,15.86719,0.31348,21.25-1.84961
        c5.39258-2.16016,3.60254-6.94238,5.09473-8.01953c1.48926-1.08105,17.6582,2.77637,22.15137-1.23633
        c8.47656-7.26758,19.55859-10.77734,30.68555-9.7168c14.06445,1.85645,10.61328,4.16406,15.10645,4.16406
        c4.48438,0,4.48438,4.47852,8.97754,4.16406c4.4873-0.30762,6.14258,4.0166,11.97754,4.0166
        c5.84473-0.90625,11.77539-1.06348,17.66016-0.46582c6.84473,0.30664,13.55664-2.00586,18.76367-6.46387
        c0.00195,0,0.00391,0.00049,0.00488,0.00049c1.11035-0.93408,1.91992-2.17139,2.34277-3.55908
        c1.49805-6.32715-2.54102-3.39258-3.89551-10.79785c-1.15527-6.36621-9.71875-5.44727-15.61426-8.97852
        c1.15332-0.80566,2.4375-1.42383,3.78906-1.82422c4.33203-0.07031,8.56348,1.22754,12.12402,3.70508
        c3.14648,2.30957,14.3623-0.4668,15.70801,1.3877c1.44727,2.17969,3.71875,3.68066,6.29102,4.16406
        c2.24023,0,4.49316,4.62793,6.28906,3.2373c3.30371-1.14258,6.81641-1.61133,10.32617-1.38477
        c1.79297,0,1.79297,1.38477,3.59375,1.38477c0.44531,0,0.77832,0.43066,1.05078,1.06885
        c-0.27637-0.66504-0.61621-1.11963-1.07324-1.11963c-1.80176,0-1.80176-1.3877-3.59375-1.3877
        c-3.49609-0.22559-7.00879,0.24805-10.32715,1.3877c-1.80469,1.38867-4.03906-3.23926-6.28711-3.23926
        c-2.57324-0.48242-4.83984-1.98535-6.29199-4.16504c-1.3457-1.85156-12.56152,0.92188-15.70801-1.38574
        c-3.54395-2.48047-7.79199-3.77246-12.12109-3.7041c-1.35645,0.40137-2.63086,1.01758-3.79395,1.82129
        c-0.97852-0.5752-1.86523-1.29004-2.6416-2.12793c-4.92969-6.25879-11.44336-11.10156-18.85059-14.04102
        c-6.43945-1.69141-6.28711-6.31055-7.93359-6.62793c-1.6543-0.31836-0.75488-2.47559,2.69434-2.47559
        c3.43652,0,6.12598-0.76367,5.53125-4.47461c-0.58789-3.70996,5.84766-4.16602,5.99609-6.01465
        c0.14453-1.85059-5.99609-5.09766-3.89453-5.86816c2.09082-0.7627,13.46875-5.70215,12.56641-7.55371
        c-0.52832-1.10645-5.4873-0.71582-10.32715,0.23926c-0.09863-0.43701-0.19824-0.88721-0.29004-1.33203
        c0.09277,0.46533,0.19531,0.93799,0.29492,1.39746c-2.82031,0.49414-5.57715,1.29199-8.22656,2.38574
        c-4.00098,2.11914-8.37012,3.47656-12.86328,4.01074c-5.08105,1.20898-9.57813,4.15625-12.72168,8.32813
        c0,1.54688,0.44434,9.10059,8.22461,8.48828c7.78516-0.62207,11.23633,1.08691,10.17773,2.77832
        c-1.04688,1.69141-9.43457-0.7793-13.46484,1.99805c-4.03809,2.77734-6.59277,2.00586-9.73145,4.31934
        c-3.14844,2.3125-7.03125,3.54883-8.22168,2.01074c-1.20117-1.5498,2.0957-6.33203-0.4502-8.02832
        c-2.55469-1.69238-9.88477-3.23828-10.18164-4.78027c-0.30566-1.54004,14.0625-4.63672,13.4707-7.7168
        c-0.59863-3.07617-12.58496-0.29688-16.47168-2.46289c-3.88477-2.16504,4.64648-5.86719-1.04785-6.79297
        c-3.82715-0.41602-7.68555,0.16992-11.2207,1.69922c-3.88965,0.77637-4.33496,7.10352-8.67871,10.49512
        c-4.1748,3.26416-5.14746,3.01318-4.8252,7.7876c0.00586,0.00635,0.01367,0.01221,0.01953,0.01904l0.02832,0.49121
        c0.4541,5.39648-2.0918,5.39648-5.24023,5.70605c-3.13867,0.31445-4.9375,5.24316-5.08496,8.48438
        c0.13379,1.82129,0.13379,3.64844,0,5.47266c-2.23242-0.56934-4.31836-1.58984-6.13965-3
        c-0.89551-1.54199-5.68555-2.16211-8.07715-2.47266c-4.1123-0.51758-8.29785,0.2334-11.97656,2.1582
        c-1.7959,2.07715-4.44336,3.21191-7.17871,3.08984c-3.58301-0.40039-7.1875-0.50586-10.78027-0.3125
        c-1.49316,0.3125-9.57617-1.53906-10.77637-1.23535c-1.06201,0.27393-3.04443-3.50879-5.74561-3.99805
        c-0.00244,0.00732-0.00586,0.01514-0.00879,0.02246c2.7124,0.48828,4.69287,4.2627,5.75439,3.99219
        c1.2002-0.30664,9.27832,1.5459,10.77637,1.23535c3.59277-0.19531,7.19727-0.09082,10.76758,0.31055
        c2.74805,0.12598,5.39551-1.01367,7.19141-3.08496c3.67871-1.9248,7.85156-2.67871,11.96777-2.16602
        c2.39648,0.31152,7.19043,0.93359,8.07227,2.47461c1.81641,1.41211,3.9043,2.43262,6.14355,2.99707
        c-0.21973,1.0918-1.03418,1.96582-2.11426,2.24512c-3.28223,1.07715-5.98535,8.47949-8.07813,8.94238
        c-2.08496,0.46484,0.45508,7.56543,2.0957,7.86719c0.69434,0.13477,1.03906,1.1582,1.40039,2.4541
        c-0.41504,0.37646-0.89551,0.65332-1.40918,0.82568c0.51758-0.17285,1.00293-0.45068,1.42188-0.82959
        c0.31055,2.00586,1.17383,3.87988,2.49707,5.41797c2.53613,2.16309,9.58301,4.47559,7.63574,6.32715
        c-1.95117,1.85156-10.0293,0-13.62598,1.38965c-3.5957,1.3877-5.23242,6.01563-9.42676,6.01563
        c-2.22754-0.35645-4.28809-1.39746-5.88379-2.98047c1.2998-1.47656,2.37109-2.3291,2.37109-3.58008
        c0-1.62109,2.91016-2.31348,2.91016-4.85938c0-0.61133-0.11719-1.30322-0.27832-2.00732
        c0.12988,0.66357,0.22363,1.33496,0.27344,2.01025c0,2.55176-2.91504,3.23828-2.91504,4.85645
        c0,1.25098-1.0752,2.10547-2.37012,3.58008c-1.8623-1.82129-4.25879-2.99219-6.83691-3.34766
        c-4.78906,0-6.13379,4.48145-7.63086,3.86523c-1.49316-0.62402-1.50781-3.86523-6.13965-2.0127
        c-4.63086,1.85059,0.59277,6.79004-1.93945,7.86719c-2.54395,1.0791-6.58301,1.54492-8.08984-1.2334
        c-1.4873-2.77441-7.92871-0.92383-5.23828,2.93262c2.70703,3.85156,8.24316,8.47754,8.24316,11.71875
        c0,3.24805-6.89453,1.7041-3.30078,5.55371c3.58887,3.86133,2.8457-1.84277,8.38574-0.30469
        c5.53711,1.54395,5.08789,8.1748,3.28906,8.48535c-1.79883,0.30859-3.58496,3.23828-7.32617,0.92578
        c-3.74219-2.31348-6.74219,2.15234-3.59863,2.00488c3.14355-0.1543,6.7334,1.68848,4.6377,4.16113
        c-2.08691,2.47266-6.2793-0.92871-7.0293,0.31055c-0.75195,1.23438,3.88965,9.41309,1.79395,10.49023
        c-2.0918,1.08008-5.98242-4.77539-9.43164-4.62793c-0.26367-0.02246-0.53027-0.06543-0.80078-0.125
        c-3.19238-0.74121-5.17676-3.93555-4.43262-7.12695c0.89355-3.08691-5.99121-5.86426-2.10156-8.79199
        c3.89355-2.92871,10.7793,0.92578,10.7793-0.92578s-6.74023-6.16504-9.12891-3.38965
        c-2.3877,2.77539-7.63672,1.38672-7.1875-2.47363c0.3916-3.84668-1.80273-7.49219-5.38281-8.94629l-0.44434-0.22852
        c0.00049-0.00146,0.00098-0.00244,0.00098-0.00391c-2.88477-1.42334-3.74658-6.37793-6.72949-8.87207
        c-2.90137-4.76367-2.7832-10.77441,0.2959-15.4248c2.71191-2.79492,1.17969-4.33691-2.75586-6.49023
        c0.07861-0.45605,0.18701-0.91406,0.32031-1.36719c-0.13428,0.45459-0.24609,0.91455-0.3252,1.37305l-0.52637-0.29492
        c-2.54102-1.62109-4.93896-3.45557-7.15479-5.47705l-0.00049,0.00439c-1.66406-1.41406-3.40039-2.73926-5.20801-3.95801
        c-1.25977-0.93555-2.71191-1.56055-4.25586-1.82715c-4.49219-0.31641-8.4043-2.4668-8.68652-4.78125
        c-0.29297-2.31543-5.68457-1.70313-8.07813-4.47656c-3.98926-4.20703-7.04785-9.19922-8.98145-14.65918
        c-0.14453-3.69824-5.08691-3.39063-7.78125-3.39063c-1.63672,0-1.50684-2.3418-1.8252-4.44922
        c0.00146,0,0.00244-0.00049,0.00391-0.00049c-0.20215-1.36426-0.59277-2.62451-1.76758-3.10986
        c-2.18555-0.43164-4.45703-0.13574-6.46191,0.84375c-0.03955-1.15234-0.05762-2.3042-0.05518-3.45605
        c-0.00293,1.15625,0.01563,2.31348,0.05518,3.46973c-3.0459,1.24805-5.61914,3.44824-4.45703,5.93359
        c2.08594,4.47949-2.55469,5.10059-0.1582,10.49414c2.40234,5.39844,11.97754,5.86328,14.2168,14.96094
        c2.24512,9.09863,9.58301,16.96484,15.71875,16.65723c6.14355-0.30664,3.59766,3.7002,7.4873,6.1748v-0.00977
        c3.88086,2.46191,20.35156,9.40918,20.06348,14.66016c-0.30078,5.24805-2.39355,2.93652-7.63184-0.46484
        c-5.23926-3.3877-10.78125,0.31152-9.7334,4.47559c1.04395,4.16406,7.03125,4.94043,4.6377,8.18164
        c-2.3916,3.23535-5.38477,3.07813-5.83789,6.16309c-0.4502,3.08984-3.58398,8.64355-5.08691,8.64355
        c-1.49805,0-2.69336-5.24121-0.4502-8.32813c2.04297-3.52832,1.86719-7.91406-0.44043-11.2666
        c-2.10547-2.61914,0.13574-5.70801-3.30566-6.16992c-3.43945-0.46387-3.58887-4.46582-7.18555-6.16895
        c-3.36523-1.29492-6.38281-3.35254-8.82031-6.01563c-2.24316-2.77637-5.99023-2.3125-9.73145-4.32422
        c-3.73438-2-6.58496-7.39941-13.76758-12.18457c-4.80664-2.71191-8.27051-7.28809-9.57813-12.64258
        c-0.45117-2.62793-1.0498-2.9375-3.13965-6.1748c-3.2666-4.49316-8.71289-6.85938-14.22168-6.1709
        c-5.23438,1.54297-3.88672,4.47461-7.02637,6.1709c-1.57324,0.6499-3.2085,1.13867-4.88232,1.44971
        c0,0.00049-0.00049,0.00146-0.00049,0.00244c-2.48438,0.77246-4.68848,2.25-6.34863,4.25879
        c-4.93555,5.39258-8.82813,5.85645-11.67285,4.00488c-5.76465-2.84668-12.13184-4.26953-18.56543-4.16504
        c-4.93652,0.77637-10.16797,4.3252-8.37402,10.34082l0.61133,2.68164c-0.00098,0-0.00244,0-0.00391,0
        c1.15137,3.55957-0.70068,7.39453-4.20313,8.70801c-4.34082,1.85059-5.97949,5.39648-11.07227,6.0166
        c-5.09375,0.62207-9.29004,1.83789-9.29004,5.08984c0,3.55273-4.33984,3.86035-8.37891,10.18066
        c-4.03906,6.33008,0.29785,13.42285-0.14648,15.27148c-0.44629,1.85156-7.19141,5.57031-7.4873,8.02832
        c-0.29199,2.45703,0.30762,6.78906-3.74219,7.40332c-3.44824,0.29004-6.47852,2.40625-7.93066,5.55469
        c-1.32227,2.75098-4.32813,4.27051-7.33496,3.70117c-3.58594-0.16016-7.18164,0.31055-12.86816,0.46289
        c-5.6875,0.14941-6.87598,3.70215-9.27148,3.70215c-2.40234,0-4.63867-0.93066-5.83887,0.30176
        c-1.20215,1.24023,0,3.0918-2.40234,3.39844c-5.65723,0.72266-8.08301-10.03418-12.2666-12.03223
        c-1.7041-0.71191-3.5957-0.85742-5.39063-0.41309c-0.75098-0.99902-1.25-2.16113-1.46484-3.39453
        c0-0.84375,0.00049-1.84277,0.2085-2.80273c-0.21533,0.96924-0.21826,1.97998-0.22217,2.83398
        c0.2168,1.23047,0.71973,2.39648,1.46777,3.39355c-2.64648,0.76172-5.34473,1.30859-8.07715,1.64258
        c-4.19043,0-7.78516,0.76758-7.78516-2.00684c0-2.77637,2.69824-14.49707-1.3457-17.73535
        c-4.03711-3.24023-7.78711-4.47754-3.89258-9.10645c4.64355-7.47559,6.83691-16.20703,6.28711-24.98828
        c-0.67871-2.30078-0.7793-4.7334-0.27539-7.07813c1.01758-0.0332,1.98145-0.45605,2.69824-1.17871
        c1.8291-2.24902,3.64063-1.88281,3.64063,0.37305c0,2.25781,3.99805,1.50098,6.19824,1.50098c2.1875,0,1.45215,1.88281,4.00098,0
        c2.5498-1.87402,6.18945-1.10742,6.18945,1.13184c0,1.95898,4.16016,1.35547,3.28223,3.16406
        c0.90967-1.82861-3.28223-1.20313-3.28223-3.18066c0-2.25488-3.63965-3.00684-6.18555-1.12891
        c-2.55273,1.88281-1.81738,0-4.00488,0c-2.18652,0-6.1875,0.75684-6.1875-1.50098c0-2.25586-1.82227-2.62207-3.65137-0.37695
        c-0.71191,0.72363-1.67773,1.14453-2.69238,1.1748c0.35352-3.90137,0.64941-8.16992-1.82715-10.19043
        c-4.33496-3.55469-3.74219-7.40137,2.24805-7.56445c5.99023-0.1543,4.19043-5.39258,8.38477-5.39258
        c4.19043,0,4.63477,3.85742,12.26855,3.23535c7.63281-0.61719,27.24316,1.07715,33.37988,0.30566
        c5.75977-0.7207,14.47559,2.58691,17.51074,1.97656c0.00488,0.00244,0.00928,0.00439,0.01367,0.00684l0.59375-0.12109
        c3.39648-5.66699,5.0625-12.20801,4.79004-18.81445c-0.75293-4.16504,2.9873-9.10254,1.49023-12.49512
        c-1.49023-3.39453,2.25586-6.79102-2.69238-8.17773c-4.32422-0.91797-7.54492-4.5459-7.93164-8.94336
        c-0.15625-3.8623-0.75098-2.62012-3.29883-4.62793c-2.54492-2.01074-3.74609,0.30371-8.83301-2.47266
        c-5.08691-2.77734-11.98145-2.46191-13.46777-4.46875c-1.50391-2.01074,0.75879-4.15527-1.19238-6.47852
        c-1.95215-2.3252,1.0459-5.41406,6.28711-4.94824c5.22461,0.46191,6.58398-2.30664,9.87109-1.0752
        c3.29199,1.22949,3.44238,5.08789,6.13672,3.0791c2.69238-2.00977,6.88672,2.00879,8.83301,0.46387
        c1.36768-1.09033,1.83691-4.17139,0.79395-7.29785H0v268.70996c0.90381-1.52539,1.96631-2.95801,3.18262-4.26465H3.17871z
        M465.18555,340.39648c5.13281,0.00293,10.14648-1.55566,14.36523-4.47461c2.24902-2.00293,3.60645,0.61914,0.15723,2.00879
        c-3.44531,1.3877-5.69434,4.93164-5.38379,6.63086c0.29688,1.69922-8.38672,6.47949-13.77637,3.24121
        C456.35352,345.27637,458.44922,340.70508,465.18555,340.39648z M376.13574,338.38965
        c2.8418,2.77637,13.62012,0.46387,16.02441,1.84961v0.00195c2.38379,1.38965,6.13184-1.08105,6.27832,0.46094
        c0.14941,1.54004-7.78418,4.48242-13.62598,4.31641c-5.05957-0.82324-10.02051-2.1709-14.80957-4.01172
        C367.49609,340.58008,373.29883,335.61133,376.13574,338.38965z M272.4248,307.53906c3.59082,1.85059,6.13184,1.3877,12.27441,0
        c6.13086-1.3877,8.52246-0.15723,8.07715,0.61328c-0.44531,0.77441-4.9375,5.55566-4.18652,9.71973
        c0.74707,4.16406,0.44531,7.25586-2.70313,7.10156c-3.13965-0.16309-4.04297-5.3916-11.51367-7.40527
        c-7.4834-2.01074-9.88477-4.16602-10.47949-5.86328C263.0166,309.22559,268.83789,305.68848,272.4248,307.53906z
        M226.17871,252.4707c4.1875-2.62695,4.6377-5.70508,6.28613-5.55469c1.63379,0.15332,2.24609,18.82227-2.09863,19.43848
        C226.21094,266.94336,221.99512,255.0957,226.17871,252.4707z M219.44629,274.37891
        c1.32422-2.35645,4.03027,1.08301,7.32617-1.85352c3.29785-2.9375,10.62695-0.76367,9.2832,3.86426
        c-1.34473,4.62793,0.29199,14.33496-1.65137,16.96484c-1.93945,2.62207-3.59277,0.92578-7.03809,4.4707
        c-3.43262,3.55273-5.5293-4.00879-4.18262-10.02734C224.52832,281.7832,217.79297,277.31348,219.44629,274.37891z
        M170.50391,288.25684c0.75,5.24902-4.33691,6.1748-8.8291,3.08691C159.47461,289.83105,169.75293,283.01465,170.50391,288.25684z
        "/>
      <path class="megm-1" d="M361.85059,166.6543h-0.00879c-0.5791,0.64502-1.11621,1.32422-1.59668,2.04102
        C360.72852,167.97852,361.26855,167.29883,361.85059,166.6543z"/>
      <path class="megm-1" d="M7.896,628.38379c0.03174-0.05371,0.05469-0.10254,0.0835-0.15527
        C7.95068,628.28125,7.92822,628.33008,7.896,628.38379z"/>
      <path class="megm-1" d="M340.66992,631.33887c3.0625-3.7041,6.35938-7.20605,9.87207-10.48438
        c1.49805-1.55371,0.0127-2.79395,2.10547-4.95117c2.08594-2.15723,6.28711-4.62793,8.37402-3.39063l1.75391,0.41016
        c0.57959,0.67285,1.14111,1.3623,1.6958,2.05566c-0.55225-0.7002-1.12207-1.38672-1.69629-2.06934l-1.75342-0.40625
        c-2.08691-1.24121-6.27832,1.21484-8.37402,3.38672c-2.10547,2.1709-0.60742,3.41113-2.10547,4.94629
        c-3.5127,3.2832-6.80957,6.78027-9.87207,10.49316c-0.78027,1.02344-1.85205,1.63965-3.06055,2.0166h0.03516
        C338.83789,632.96582,339.89648,632.35254,340.66992,631.33887z"/>
      <path class="megm-1" d="M279.63574,631.69336c0.40088,0.56348,0.89307,1.07129,1.46973,1.49805
        c0.05615,0.05371,0.11133,0.10156,0.16748,0.1543h0.00537c-0.05908-0.05566-0.11719-0.10645-0.17676-0.16309
        C280.52686,632.75781,280.03613,632.25293,279.63574,631.69336z"/>
      <path class="megm-1" d="M269.14258,628.87012c1.49316-0.62207,2.69336-5.24805,2.38867-6.78711
        c1.45215-4.06738,3.4668-7.91699,5.98047-11.41797c0.90527-1.24023,5.69922-0.61328,5.99512-3.3877
        c0.25732-2.43652,0.98389-5.10938-0.0542-6.15039c1.03076,1.0459,0.31006,3.71582,0.04932,6.1416
        c-0.30957,2.77441-5.08984,2.14746-5.99414,3.39258c-2.51855,3.5-4.52734,7.3457-5.98145,11.41699
        c0.30566,1.53418-0.89551,6.15625-2.38867,6.77832c-1.20947,0.50391-1.24805,2.80371-1.21826,4.48926h0.00488
        C267.89453,631.66113,267.93506,629.3623,269.14258,628.87012z"/>
      <path class="megm-1" d="M7.78809,628.54883c-0.87891,1.375-2.67383,3.1543-4.479,4.79688h0.0083
        C5.11621,631.7041,6.90967,629.9248,7.78809,628.54883z"/>
      <path class="megm-1" d="M785.61523,457.47754c-2.58691-1.33203-5.74121-0.82715-7.79102,1.24023
        c-6.1709,1.49316-12.54785,1.96484-18.875,1.4082l0,0c-3.54199,0.01953-7.09473-0.30078-10.58301-0.94336
        c-11.95898-1.15625-23.78711-3.43359-35.32324-6.79199c-2.55078-3.24121-0.89063-12.3418-7.77637-13.73145
        c-6.89746-1.3877-10.93848,4.9375-16.32422,4.63086c-8.39551-0.75293-16.39746-3.91113-23.04492-9.09863
        c-4.94434-5.24902-8.82422-4.01953-12.27441-6.3291c-3.44336-2.30566-3.29102-4.15918-5.83691-9.56055
        c-2.5498-5.39258-8.24609-16.49707-10.32617-16.34863c-2.08691,0.14453-4.94238,2.16309-7.03418,0.30957
        c-2.09668-1.85156-1.94922,1.23047-4.63965,1.23047l-2.45996,0.44141c-2.70215-2.88184-5.95703-6.21777-5.71484-8.20801
        c0.40625-3.2998-3.19824-4.95117-3.5918-7.00488c-0.40137-2.05469,2.39355-5.35254,0.79492-7
        c-1.49902-1.54785-1.9375-5.26807-4.29297-6.06494c2.33594,0.81445,2.78906,4.51758,4.2793,6.05615
        c1.60254,1.65039-1.19727,4.94238-0.7998,7c0.39648,2.0625,3.99805,3.70996,3.59668,7.00684
        c-0.2334,1.98828,3.00879,5.32422,5.71484,8.20508c-1.63477,0.46289-3.14453,1.29883-4.41602,2.43848
        c0.00098,0.00098,0.00195,0.00195,0.00293,0.00293l-0.90039,1.74414c1.33008,4.48633,2.95508,8.88477,4.85645,13.15527l0,0
        c0.59375,1.42285,1.01758,2.91211,1.26953,4.42969c0.31152,3.5459,7.0332,9.25098,10.33887,12.33887
        c3.29297,3.08691,6.72852,8.64355,4.48438,11.26172c-1.83594,2.14746,3.42676,9.84277,5.41211,11.24121
        c0.00098,0.00293,0.00195,0.00586,0.00293,0.00977l0.72461-0.1377c0-2.46484,2.3916-11.41992,6.73828-10.17969
        c4.33496,1.22949,3.13281,10.02441,1.18945,13.42285c-0.41602,0.90527-0.49805,1.9375-0.20215,2.89844h0.00098
        c0.70605,2.58398,2.16699,4.9082,4.19434,6.66699c0.00098,0.00293,0.00195,0.00684,0.00293,0.00977
        c0.85547,0.6748,1.64453,0.96191,2.14453,0.60156c3.11523-3.14844,7.68652-4.38965,11.97168-3.23828
        c7.67188,1.08496,15.23828-2.57129,19.16406-9.25586c2.98047-6.02148,10.3252-14.18848,12.11914-14.03613
        c1.06641,0.0918,1.29395,6.54395,2.77441,12.9707l0.00195-0.00098c0.61035,4.17969,2.51855,8.05566,5.44531,11.0918
        c7.03516,5.69824,15.72656,4.16113,16.91797,6.48047c1.18945,2.30664,5.68652,10.48438,8.53418,10.95117
        c2.83594,0.45898,2.68066,2.31055,0.7373,5.70801c-3.04492,3.87891-5.84277,7.9502-8.38379,12.18262
        c-0.1543,1.84766-5.08301,5.24414-8.23242,5.85645c-3.1377,0.62012-3.8877,11.10742-3.43652,14.03613
        c0.44727,2.93457-3.14551,2.00879-6.43359,3.4043c-3.29199,1.39453-4.64551,8.47461-6.58398,9.5625
        c-1.94727,1.08301-7.33203-1.39746-11.07813,1.85156c-3.74023,3.24512,0.45703,6.95313-3.89355,8.17285
        c-4.33594,1.24219-10.77832-0.45898-16.45703,2.93262l-2.57715,1.27637c-0.00098-0.00195-0.00195-0.00488-0.00391-0.00781
        c-4.13281,1.70313-6.76855,1.0293-8.64844,3.35742c-2.24805,2.77441-0.14453,7.41504-9.73438,10.48828
        c-7.42773,1.40527-14.63672,3.85156-21.39648,7.25488c-2.83887,2.92871-5.37988,5.8584-7.92285,5.55566
        c-2.54883-0.31445-8.69141-0.16211-10.03711,1.68945c-2.90234,3.11035-6.83496,5.08691-11.06836,5.54785
        c-4.68555-0.11914-9.28027,1.35156-13.02344,4.1709c-5.6084,3.1377-12.23145,3.91699-18.41113,2.15918
        c-1.49805-1.69922-0.29883-10.48828-3.4375-15.26855c-2.98047-5.00488-4.04297-10.91992-2.99023-16.65723
        c0.42383-2.17773,0.11914-4.43555-0.8584-6.42969c1.65625-0.69531,3.21973-1.58594,4.67383-2.63379
        c0.07129-0.15039,0.10645-0.33398,0.11426-0.54199c-0.00879,0.20508-0.04395,0.38574-0.11426,0.5332
        c-1.4541,1.05664-3.01758,1.93848-4.67383,2.63867c-0.86523-2.14941-1.98438-4.18262-3.33887-6.05859
        c-1.94043-2.62891,0-4.47852-3.43848-8.02246c-4.08008-4.6582-7.2334-10.05078-9.27148-15.89258
        c-2.14941-5.81152-6.28906-10.66699-11.67969-13.72168c-5.2793-4.30078-8.30859-10.77734-8.24023-17.58496
        c-0.14551-6.1748,0.59961-9.10059-2.53809-11.57324c-3.14453-2.46875-3.14453-7.56348-9.13477-11.72363
        c-4.36328-2.30762-7.29492-6.65332-7.77246-11.57129c-3.59668-8.56055-8.55371-16.47656-14.67871-23.44824
        c-4.78613-5.39941-5.98633-9.56543-8.2207-10.33691c-2.04297-0.69727-0.99512-9.69043-1.69238-11.87695l0.38281-2.82422
        l1.05762-2.98535c1.01953,0.65039,2.07617,1.23438,3.14941,1.78906c-0.71387-0.38086-1.41602-0.78516-2.10254-1.21582
        c-0.00098,0.00195-0.00098,0.00391-0.00098,0.00586l-1.0459-0.58398l0.43457-1.20898l-7.27637-18.375l7.26367,18.375
        l-1.47949,4.19629l-0.3916,2.82715l-0.25195-0.45605c-1.19531-0.77344-2.5498,14.81055-6.13281,14.81055
        c-3.59375,0-8.69531-10.33887-10.18359-13.7334c-1.49609-3.39063-5.39258-13.88379-6.7373-13.41992
        c-0.78711,6.72852,0.86035,13.5127,4.6416,19.12988c4.63672,6.78418,7.02832,7.24512,6.88672,11.56445
        c-0.14941,4.32617,4.49707,6.63086,4.79297,10.64746c0.29297,4.01758,8.68555,14.34766,10.77148,21.28809
        c2.0957,6.94336,5.3877,6.63672,4.6416,11.57129c-0.21191,1.39258-0.20215,2.80664,0.02637,4.18848l-0.33301,0.4375
        c-1.48926,2.15332-2.08887,3.0791-2.68555,3.4668c0.60156-0.38574,1.20508-1.31152,2.71777-3.48047l0.33691-0.42969
        c0.55371,3.34961,2.65039,6.25098,5.64844,7.83594c4.17383,2.3916,7.31348,6.2373,8.8291,10.80566
        c1.27734,5.67676,2.03613,11.45996,2.24414,17.26953c0,8.17773,2.40234,14.66211,5.99414,16.35352
        c2.45117,1.58105,4.58887,3.61133,6.28809,5.99023c-0.00293,0.00391-0.00586,0.00781-0.00879,0.01172
        c2.93652,3.2627,4.89355,7.27441,5.6709,11.58691c0.14746,5.86133,5.24414,14.96094,7.4834,15.27051
        c2.23926,0.30859,2.84277,6.63574,6.43262,6.63574c6.61035,1.60352,12.34473,5.69531,16.00977,11.41602
        c3.29297,4.40234,6.90137,8.57227,10.78027,12.4668l1.34668,1.25781c2.38965,2.16895,3.73535,5.55762,0.89844,6.16406
        c-2.84766,0.62305-6.88574,3.71289-5.83887,4.79297c0.61328,0.61914,2.9209,0.87109,5.42383,2.36914
        c2.3125,1.40039,4.12305,3.4834,5.2002,5.95898c3.43945,7.39355,14.06641,4.62988,16.76074,1.38086
        c2.69043-3.24414,10.17383,0.46484,13.16504-2.46973c4.73145-3.22754,10.48242-4.59473,16.16016-3.85254
        c8.24316-0.15137,21.10156-2.62598,22.75488-6.33398c1.64746-3.70215,7.04395-3.0752,6.58984,3.55762
        c-0.43945,6.62891-3.58496,14.49414-3.43555,18.19727c-0.58789,2.47754-1.34961,4.90332-2.25,7.27441H800V457.96387
        C795.28125,459.29688,790.26074,459.13867,785.61523,457.47754z M672.24219,599.45996
        c-3.77832-4.02832,10.63965-3.45801,10.29492-2.02832C682.19531,598.86328,675.60645,603.03809,672.24219,599.45996z"/>
      <path class="megm-1" d="M54.36523,630.01563c-1.6665-0.9541-0.73584-4.87109,0.0083-8.30078l0,0
        c-0.75586,3.42969-1.67529,7.34668-0.01904,8.30078c1.20605,0.72754,1.92285,1.99121,1.99756,3.33008h0.00439
        C56.28271,632.00684,55.57129,630.74316,54.36523,630.01563z"/>
      <path class="megm-1" d="M140.12305,625.38672c0.50586-2.3916-0.46973-4.84766-2.47461-6.24609
        c-1.19727-2.05078-1.89453-4.35254-2.0332-6.7168l1.21582-0.22754c0.0249,0.00293,0.05322,0.00781,0.07861,0.01074
        c-0.02783-0.00781-0.05566-0.0127-0.0835-0.01953c-2.42529-0.24121-2.88281,2.75879-3.45166,3.07031
        c0.45459-0.25488,0.85596-2.19629,2.23584-2.83398c0.13965,2.36426,0.83301,4.67578,2.03418,6.7168
        c2.00195,1.39844,2.97363,3.85449,2.46777,6.24609c-0.38135,2.63965-0.52539,5.30176-0.4458,7.95898h0.00195
        C139.58936,630.6875,139.73584,628.02441,140.12305,625.38672z"/>
      <path class="megm-1" d="M153.01855,633.3457h0.00293c-0.10352-0.48828-0.23779-0.92969-0.41943-1.30859
        C152.78223,632.41602,152.91553,632.85742,153.01855,633.3457z"/>
      <path class="megm-1" d="M169.07715,631.64355c0.96094-2.19531,2.24414-4.2207,3.82129-6.01758
        c1.12451-1.16113,1.46045-4.3457,1.4873-7.49805c-0.02734,3.15137-0.36328,6.33301-1.4873,7.48926
        c-1.57715,1.7998-2.86035,3.83008-3.82129,6.0127c-0.63525,0.51465-1.20459,1.09277-1.71338,1.71582h0.01514
        C167.88379,632.72656,168.44873,632.15332,169.07715,631.64355z"/>
      <path class="megm-1" d="M570.93262,543.45801c1.72852-0.59863,3.76172,1.79004,7.5166,1.19434
        c4.1875-0.5127,8.43262-0.10254,12.44141,1.18848c0.85742,0.90527,11.56543,0.2998,14.16895,2.38867
        c2.6084,2.08789,5.20898,1.49316,6.94336-2.68359c4.3457-5.55078,9.63086-10.29492,15.62109-14.01465
        c1.72852-0.90625,14.75098,0,19.96387-1.19141c1.43555-0.33691,5.1543-1.46191,9.76367-2.92871
        c0,0.00098,0.00098,0.00195,0.00098,0.00195c11.9375-3.79785,29.97168-9.92285,31.00879-10.78613
        c1.86035-4.2002,3.43848-8.51563,4.73633-12.91211c-1.2998,4.39355-2.87695,8.7041-4.73633,12.90234
        c-1.43848,1.19727-35.57324,12.52051-40.77344,13.71289c-5.21289,1.19629-18.22656,0.29492-19.96387,1.19629
        c-5.98535,3.71582-11.27539,8.46875-15.62109,14.01074c-1.73438,4.16992-4.33496,4.77539-6.94336,2.68262
        c-2.60352-2.08496-13.31152-1.49121-14.16895-2.38867c-4.00879-1.29004-8.25391-1.69629-12.44141-1.18848
        c-3.75488,0.59668-5.78418-1.7832-7.5166-1.19434c-1.76172,0.88086-3.25977,2.2168-4.33984,3.87598
        c-0.07715,0.10645-0.12695,0.23438-0.16602,0.37109c0.04004-0.13281,0.08984-0.25781,0.16602-0.36133
        C567.67285,545.67383,569.16211,544.33301,570.93262,543.45801z"/>
      <path class="megm-1" d="M589.61035,269.85596c0-0.00049,0-0.00098-0.00098-0.00146c-2.91895-0.80127-5.94629-1.12061-8.96777-0.94336
        c-2.80859,0.3418-5.08984,1.04785-7.61328,1.62646c2.52344-0.57861,4.80469-1.28418,7.61328-1.62549
        C583.66406,268.73584,586.69141,269.05322,589.61035,269.85596z"/>
      <path class="megm-1" d="M572.65137,279.31543c-0.50098-1.29102-0.72363-2.26953-0.84668-3.09375
        c0.11719,0.82129,0.33496,1.7959,0.83789,3.08887c0.45117,1.16064,1.07227,1.87451,1.77246,2.31299
        C573.71777,281.18457,573.10059,280.47168,572.65137,279.31543z"/>
      <path class="megm-1" d="M580.42676,282.70557c0.85254,1.13184,1.47461,2.41992,1.83496,3.78809
        c0.00098,0,0.00195-0.00049,0.00195-0.00049c-0.36035-1.37109-0.97852-2.6582-1.83105-3.78418
        C580.43066,282.70752,580.42871,282.70703,580.42676,282.70557z"/>
      <path class="megm-1" d="M583.23047,286.54199c0.79883,0.14941,1.35449,0.43848,1.76367,0.81787
        C584.5166,286.91797,583.90527,286.62109,583.23047,286.54199z"/>
      <path class="megm-1" d="M595.99316,284.24707c0,3.0791,6.28711,5.24512,6.28711,7.70996
        c0.33203,1.84473,1.00488,3.61035,1.99902,5.19971l0,0c-0.98926-1.59229-1.66699-3.35889-1.99023-5.20557
        c0-2.46484-6.28711-4.62207-6.28711-7.70801c0-3.05713-4.09961-5.80615-3.60352-8.26074
        C591.88477,278.43506,595.99316,281.19531,595.99316,284.24707z"/>
      <path class="megm-1" d="M592.39941,274.91895c0.00098,0.00586,0.00195,0.01172,0.00293,0.01807
        C592.40137,274.93066,592.40039,274.9248,592.39941,274.91895z"/>
      <path class="megm-1" d="M592.24023,274.36572c0.00391,0.01172,0.00879,0.02344,0.0127,0.03516
        C592.24902,274.38916,592.24414,274.37744,592.24023,274.36572z"/>
      <path class="megm-1" d="M590.89746,295.70068c0.19434,0.48682-0.0332,0.8916,0.125,1.26563
        C590.87305,296.5918,591.09961,296.18652,590.89746,295.70068z"/>
      <path class="megm-1" d="M592.45117,275.37256c0.00195,0.03711,0.00293,0.07373,0.00195,0.11035
        C592.4541,275.44629,592.45313,275.40967,592.45117,275.37256z"/>
      <path class="megm-1" d="M595.59766,293.02832c-2.58594-2.96387-3.4502-1.18555-4.74805-1.77441v0.00488
        c1.29785,0.58887,2.1582-1.18945,4.74805,1.78125c2.25293,2.59375,2.43457,3.25098,2.14941,5.56055l-0.87695-0.05273
        c1.45605,0.11035,2.85742-0.0083,4.2002-0.31494c-1.08691,0.23584-2.19727,0.35742-3.31445,0.35986
        C598.03223,296.28809,597.85059,295.61719,595.59766,293.02832z"/>
      <path class="megm-1" d="M800,307.68018v-0.00635c-0.7168-0.1001-1.54785-0.63916-2.46289-1.38184
        C798.45215,307.03564,799.2832,307.57715,800,307.68018z"/>
      <path class="megm-1" d="M294.12646,220.70752c-0.04736,0.12061-0.09424,0.2417-0.125,0.36914
        C294.03223,220.9502,294.0791,220.82861,294.12646,220.70752z"/>
      <path class="megm-1" d="M590.35156,266.92529c-0.57227,0.00391-1.02344,0.19629-1.24902,0.65967
        c-0.02148,0.04541-0.01855,0.10107-0.0332,0.14941c0.01563-0.04932,0.01953-0.10303,0.04199-0.14941
        C589.33594,267.12451,589.78418,266.93164,590.35156,266.92529z"/>
      <path class="megm-1" d="M757.08301,206.06152c2.58105-3.62891-15.08984-6.625-14.02832-3.95605
        C744.03809,204.59375,755.87695,207.75391,757.08301,206.06152z"/>
      <path class="megm-1" d="M606.13477,168.82227c-0.89063,0.46094-3.13867,5.08887-2.23633,6.47656
        c0.00684,0.01074,0.01953,0.01904,0.02637,0.0293c-0.88574-1.38867,1.34668-6.0127,2.25-6.47656
        c0.67285-0.34619,2.33984-1.20654,3.14258-2.19727h-0.06152C608.44238,167.63379,606.80176,168.4834,606.13477,168.82227z"/>
      <path class="megm-1" d="M618.41211,298.90723c0.68164-1.39258,2.20313-8.95459-0.22461-9.78467
        c2.39258,0.86963,0.88281,8.3916,0.21094,9.77686c-0.20703,0.42627,0.20117,1.07324,1.00293,1.79785
        C618.60645,299.97607,618.2041,299.33203,618.41211,298.90723z"/>
      <path class="megm-1" d="M596.29004,258.64844c0.93848-0.33008,3.62598,1.80908,6.22461,3.2959
        c0.00195-0.00146,0.00293-0.00293,0.00488-0.00488c0.97949,0.62305,2.06543,1.07227,3.19922,1.32324
        c3.1416,0.46289,6.28418,6.01367,7.62988,5.55078c1.34668-0.46289,3.59961,2.31055,4.49316,0
        c0.85449-2.19238,5.29004-1.12109,6.52441-4.93359c3.24902,3.83496,6.26758,7.85254,9.04492,12.03809
        c2.99121,5.55371-0.89844,15.73438-5.97168,22.20996c-1.33105,1.76855-1.97168,3.97266-1.77344,6.18359
        c-0.00488-0.00195-0.00977-0.00439-0.01563-0.00635c0.4043,6.60498,5.64355,11.87939,12.24609,12.32373
        c9.28418,0,9.88086,11.7207,18.56445,11.41113c7.58008,0.24121,15.0957-1.45996,21.84961-4.93164
        c0.46094-1.77783,0.57324-3.63623,0.31152-5.45605c-0.00098,0-0.00098,0-0.00195,0
        c-0.4834-4.6416-0.48828-9.32324-0.01367-13.96484c1.19238-4.93945-1.49805-5.24316-2.09277-8.33203
        c-0.60156-3.08203-8.97949,0.92676-5.69238-2.46582c3.29102-3.39453,4.2002-6.78809-0.59668-6.48633
        c-4.79297,0.31445-3.2998-4.30859-2.09766-8.62891c1.20117-4.32617,5.69434-1.23242,9.28809,0
        c3.58301,1.23145,6.58691-2.4707,10.16699-2.16309c3.59961,0.3125-0.88965-5.86035-4.77734-6.16504
        c-3.89648-0.30664-0.60449-8.95117-7.7832-9.56543c-2.6709-0.23193-4.01465,0.61182-4.71484,1.87598
        c0,0.00049,0,0.00098,0,0.00098c-1.17871,2.14551-0.51953,5.5127-1.27637,7.0625
        c-1.19922,2.47559-6.58301-8.62988-3.28418-12.64648c3.28418-4.00586-0.6084-5.54297-4.79688-5.23828
        c-4.1875,0.30078-4.1875-5.25781-7.18164-4.32715c-3.00098,0.92578-3.29297-5.24316-3.29297-8.01855
        c-0.94043-3.65039-4.0332-6.35156-7.7793-6.79297c-3.29395,0-0.90234-6.79492,5.09082-3.39941
        c5.98145,3.39941,2.68848-3.08594,1.48926-5.86426c-1.20117-2.77539,8.08105-5.24219,12.57324-4.63086
        c4.48926,0.62207,11.06934,0.3125,9.87402-2.77148c-1.18945-3.08301,1.80566-11.10645-1.18945-15.4209
        c-2.99512-4.32031-8.68457-1.24219-15.56543-3.09277c-7.78711-0.93652-15.59375,1.67188-21.25,7.10059l-1.82715,1.39648
        c-0.01074-0.0083-0.02246-0.01465-0.0332-0.02295c-5.41113,3.71924-13.91504,6.01123-17.92383,11.24463
        c-4.49219,5.86621-11.37402,9.57129-5.0957,15.12207c6.2832,5.54492,4.19727,8.93945,3.89844,14.18066
        c2.6084,6.7832,6.56055,12.97559,11.61133,18.20508c-1.23828,3.80957-5.67383,2.74219-6.5332,4.93164
        c-0.88867,2.31055-3.13477-0.46289-4.48438,0s-4.49219-5.0918-7.62988-5.55469c-3.14063-0.45996-8.08301-5.08496-9.42871-4.625
        c-0.33496,0.11426-0.58594-0.22852-0.80273-0.80615C595.68945,258.39844,595.94531,258.7666,596.29004,258.64844z"/>
      <path class="megm-1" d="M669.13867,485.23438c-1.5-0.25488-3-0.5127-4.49219-0.80859
        C666.14063,484.71875,667.63867,484.9834,669.13867,485.23438z"/>
      <path class="megm-1" d="M794.66797,241.2207c-6.80859,0.15527-13.61523-0.11719-20.38281-0.82227
        c-2-0.82617-12.39063,3.29492-13.19043,0.82227c-3.3291-4.9541-7.31738-9.44336-11.84277-13.32813
        c1.49805-6.02246-3.28711-13.68945-5.39355-13.68945c-2.68945,0,1.20313-5.86719-2.3916-6.16504
        c-3.59863-0.29785-2.88184,5.91406-4.19336,4.94531c-1.5625-1.16406-5.98535-5.24902-9.28027-0.31152l-0.84863,1.75879
        l-0.00488,0.00146c-2.21973,5.83154-1.19629,16.96045,1.44336,16.13428c3.00098-0.92578,0.2998-15.12402,4.19336-15.12402
        c3.8916,0,3.59375,8.02148,1.20215,14.80957c-2.39453,6.79199,4.77734,7.71777,12.26367,2.16406
        c1.51172-1.10059,2.56641-2.70215,3.00586-4.51953c4.52344,3.88965,8.50781,8.37598,11.83301,13.33496
        c0.80859,2.4707,11.19043-1.64844,13.19922-0.82324c6.76855,0.7041,13.57422,0.97754,20.38379,0.82324
        c1.93555,0,3.49219,2.58447,5.33594,4.44043v-0.01758C798.16016,243.79883,796.60449,241.2207,794.66797,241.2207z"/>
      <path class="megm-1" d="M674.06836,256.6626c0.12695-0.03027,0.25488-0.05811,0.38281-0.08496
        C674.32324,256.60498,674.19434,256.63232,674.06836,256.6626z"/>
      <path class="megm-1" d="M694.55566,267.50049c0.19141,0.16211,0.38184,0.30713,0.56934,0.43115
        C694.9375,267.80762,694.74707,267.66211,694.55566,267.50049z"/>
      <path class="megm-1" d="M695.19922,267.97754c0.19434,0.12354,0.38477,0.22998,0.57324,0.31738
        C695.58398,268.20801,695.39258,268.10059,695.19922,267.97754z"/>
      <path class="megm-1" d="M699.86035,267.96191c0.01465-0.00879,0.0293-0.01807,0.04297-0.02686
        C699.88965,267.94385,699.875,267.95313,699.86035,267.96191z"/>
      <path class="megm-1" d="M626.37305,198.46777c3.15039-0.92383,1.80078-2.77148-1.34375-6.47949
        c-2.36426-2.78271-3.70996-5.56543-4.99902-6.7749c1.2793,1.22656,2.62402,3.99023,4.97168,6.75049
        c3.1377,3.70215,4.4834,5.55371,1.3457,6.47949c-2.56348,0.75391-2.14941,2.12109,0.27441,3.60205
        C624.2334,200.5752,623.83008,199.22119,626.37305,198.46777z"/>
      <path class="megm-1" d="M609.76367,179.49512c0.11426-0.81689-0.14453-1.39746-0.6084-1.83203
        c0.44336,0.43164,0.68848,1.00342,0.57617,1.80176c-0.33496,2.43701-0.66992,4.86426,0.32129,5.53516
        C609.10645,184.29688,609.4375,181.89844,609.76367,179.49512z"/>
      <path class="megm-1" d="M604.11816,175.54102c-0.04004-0.03564-0.06934-0.07471-0.10254-0.11279
        C604.0498,175.46533,604.07715,175.50586,604.11816,175.54102z"/>
      <path class="megm-1" d="M315.72705,209.72607c1.3667,0.1499,2.76807,0.05518,4.13818-0.30029l0.18652-0.05615
        c-0.06934,0.01855-0.13965,0.0376-0.2002,0.05322C318.4873,209.77881,317.09033,209.87451,315.72705,209.72607z"/>
      <path class="megm-1" d="M771.7041,288.73242c-1.21582-0.73584-2.30957-1.44336-2.98438-2.10596
        C769.39258,287.28857,770.48633,287.99609,771.7041,288.73242z"/>
      <path class="megm-1" d="M261.99658,197.49902c0.18994,0.44873,0.41504,0.81396,0.69678,0.98828
        c1.41016,0.53076,2.88818,0.85498,4.38672,0.99023c-1.48291-0.23242-2.9502-0.5625-4.38672-0.99902
        C262.41113,198.30518,262.18604,197.94336,261.99658,197.49902z"/>
      <path class="megm-1" d="M234.85547,193.15332c0-0.2666-0.0752-0.82861-0.13379-1.49268
        C234.74463,192.15967,234.7832,192.6582,234.85547,193.15332z"/>
      <path class="megm-1" d="M768.34668,286.20605c0.06152,0.08301,0.14551,0.16797,0.22266,0.25195
        C768.49219,286.37402,768.4082,286.28906,768.34668,286.20605z"/>
      <path class="megm-1" d="M777.87012,292.97559c0.00195,0.00293,0.00586,0.00586,0.00781,0.0083
        C777.87598,292.98145,777.87207,292.97852,777.87012,292.97559z"/>
      <path class="megm-1" d="M793.75586,302.98926c-1.21484-1.00586-2.49609-1.91162-3.80664-2.39648
        c-3.32129-1.00244-6.41016-2.62402-9.10645-4.77002c2.70215,2.15088,5.79395,3.77588,9.11523,4.77881
        C791.26465,301.08398,792.54297,301.98584,793.75586,302.98926z"/>
      <path class="megm-1" d="M780.5459,295.57178c-0.79004-0.64746-1.55078-1.33252-2.2666-2.06885
        c-0.02637-0.04053-0.06543-0.08105-0.09375-0.12158c0.03027,0.04297,0.07129,0.08545,0.09863,0.12842
        C778.99902,294.24316,779.75879,294.92627,780.5459,295.57178z"/>
      <path class="megm-1" d="M272.12012,176.26465c0.00635-0.01855,0.01855-0.03613,0.02539-0.05469
        c-0.00684,0.01758-0.01953,0.03369-0.02539,0.05176c-0.58594,1.82373-4.44043,1.16455-4.34375,4.69141
        C267.68018,177.42676,271.53418,178.08545,272.12012,176.26465z"/>
      <path class="megm-1" d="M631.34277,204.73828c-0.86426-0.61182-1.76563-1.17236-2.71582-1.64111L631.34277,204.73828z"/>
      <path class="megm-1" d="M285.59863,172.50586c-1.72607,0.75879-5.40771,0.6167-7.95898-0.08398
        c-0.00049,0.00098-0.00098,0.00146-0.00146,0.00195C280.19092,173.12842,283.875,173.26807,285.59863,172.50586z"/>
      <path class="megm-1" d="M229.0166,186.13672c-1.56104-0.70508-3.29639-0.84668-4.92676-0.4541
        C225.72266,185.2915,227.45654,185.43213,229.0166,186.13672z"/>
      <path class="megm-1" d="M220.25293,186.78125c-2.2124,0.31885-4.46582,0.31592-6.68018-0.03125v0.00049
        C215.79248,187.09766,218.04102,187.10205,220.25293,186.78125z"/>
      <path class="megm-1" d="M241.81738,189.21973c1.45215-1.86133,4.11328-2.26855,6.05664-0.92285
        c1.8125,1.23828,4.24512,1.04785,5.83789-0.46387c-1.59277,1.50684-4.02539,1.70215-5.83789,0.46094
        c-1.94727-1.34277-4.60449-0.93555-6.0625,0.92578c-0.25195,0.43311-0.54346,0.63867-0.86328,0.68945
        C241.27051,189.86035,241.56396,189.65527,241.81738,189.21973z"/>
      <path class="megm-1" d="M234.83838,188.75635c-1.86523-1.03516-3.8125-1.91406-5.82178-2.61963
        c2.0127,0.7085,3.95264,1.5918,5.81982,2.62549C234.83691,188.76025,234.83789,188.7583,234.83838,188.75635z"/>
      <path class="megm-1" d="M208.11719,210.2207c3.07422-0.27637,7.33594-0.62695,8.63672-0.62695
        c2.09473,0,4.19043-6.79199,5.08301-7.40723c0.9043-0.61621,3.29883,4.93652,5.69238,7.10059
        c2.39648,2.16602,2.69238-1.84961,2.69238-3.7002s2.09668-2.47461,3.59473-2.16309c1.49219,0.3125,4.79297,0.91992,4.79297-1.2373
        c0-2.15332,1.78613-1.22754,2.98633-1.22754c1.20117,0,1.49805-2.47266,1.49805-2.47266l1.95996-1.87207l1.25586,0.71387
        c1.79395,1.1582,5.17188-0.92969,6.28711-2.08301c1.11523-1.15527,7.40332-1.15527,8.07715-1.15527
        c0.26025,0,0.45361,0.55078,0.65674,1.26563c-0.20313-0.71875-0.39551-1.27344-0.65674-1.27344
        c-0.67383,0-6.96191-0.00098-8.07715,1.15625c-1.12891,1.1582-4.49316,3.23535-6.28711,2.08105
        c-0.69678-0.57617-1.4873-0.99854-2.32275-1.27051c0.36621,0.14355,0.72266,0.31934,1.05811,0.53809l-1.95508,1.87305
        c0,0-0.31445,2.47559-1.50293,2.47559c-1.19141,0-2.98926-0.93359-2.98926,1.22559c0,2.16406-3.29883,1.55273-4.79492,1.23828
        c-1.49316-0.31152-3.58594,0.30664-3.58594,2.1582c0,1.85645-0.30957,5.85645-2.69238,3.70117
        c-2.39355-2.14941-4.7959-7.71875-5.69238-7.09766c-0.9043,0.62207-2.99023,7.40234-5.08887,7.40234
        c-1.29932,0-5.55664,0.35645-8.62939,0.63184c-0.61279,2.01807-0.27588,3.40479,0.39844,4.26221
        C207.84717,213.59961,207.51709,212.21338,208.11719,210.2207z"/>
      <path class="megm-1" d="M268.15576,183.05176c0.9917,3.89844-0.98975,3.83594-2.68555,3.27197
        C267.16406,186.89063,269.15234,186.95605,268.15576,183.05176z"/>
      <path class="megm-1" d="M535.25488,166.7168c0.2373,0.77832,2.09668,1.42529,2.40039,2.53809
        C537.40918,168.13281,535.56738,167.48926,535.25488,166.7168z"/>
      <path class="megm-1" d="M333.89746,204.93848c0,0-0.54053,0.18066-1.41309,0.46973l1.41797-0.46191
        c1.42383,0.55176,2.87793,1.0127,4.35254,1.38477l2.8208,0.47119c0.00146-0.00146,0.00293-0.00293,0.00439-0.00439
        l-2.84277-0.47754C336.76172,205.9502,335.30957,205.48926,333.89746,204.93848z"/>
      <path class="megm-1" d="M534.10938,172.78711c0.31543,1.04785,1.8457,2.28955,2.23145,3.229
        c-0.00293-0.01025-0.00293-0.02393-0.00684-0.03369C535.9707,175.05615,534.46777,173.83203,534.10938,172.78711z"/>
      <path class="megm-1" d="M363.03516,182.27637c-0.6709,0.52148-1.59717,1.13965-2.56299,1.7583
        c0.96631-0.61768,1.89307-1.23584,2.56689-1.7583c0.67383-0.51172,1.02197-1.02686,1.09668-1.50879
        C364.05273,181.24707,363.69873,181.76123,363.03516,182.27637z"/>
      <path class="megm-1" d="M309.125,179.85547l1.27979,1.54199c-0.70752-0.83789-1.33643-1.73291-1.92188-2.65527
        c0.2124,0.37207,0.41553,0.75,0.64014,1.11523C309.12402,179.85693,309.12451,179.85596,309.125,179.85547z"/>
      <path class="megm-1" d="M305.15039,171.1748c0.22266,0.70605,0.47021,1.40332,0.73291,2.09473
        c0.71973-1.69775,1.65918-3.19629,2.80615-3.36816c1.66943-0.03076,3.28125-0.41797,4.76611-1.08008
        c-1.48389,0.65918-3.09326,1.04443-4.75732,1.0752c-1.15625,0.17188-2.0957,1.6748-2.81543,3.36914l-0.73242-2.09375
        c0,0-5.0127,2.06543-7.69531-1.03906c-2.67285-2.07422-5.97852-3.16797-9.35059-3.10156
        c-0.00049,0.00195-0.00098,0.00342-0.00146,0.00537c3.38135-0.06592,6.68262,1.02832,9.35205,3.104
        C300.12988,173.23828,305.15039,171.1748,305.15039,171.1748z"/>
      <path class="megm-1" d="M348.18555,201.05273c3.05127-4.30127,6.91162-13.52637,8.11719-14.37939
        c-0.00098,0.00049-0.00293,0.00146-0.00391,0.00244C355.09912,187.49951,351.23975,196.73975,348.18555,201.05273z"/>
      <path class="megm-1" d="M742.19336,258.50342c-0.38477-0.5835-0.82813-0.98291-1.33398-1.09814
        c-2.4248-0.26807-4.76172-1.03076-6.88184-2.23096c2.11426,1.20264,4.45605,1.9668,6.88672,2.23779
        C741.36816,257.52734,741.80957,257.92383,742.19336,258.50342z"/>
      <path class="megm-1" d="M748.09961,371.80322c-0.18555-2.94873,1.27344-5.60107,0.98633-7.56006
        C749.36133,366.20752,747.9082,368.85938,748.09961,371.80322z"/>
      <path class="megm-1" d="M293.92432,221.61865c0.00684-0.14307,0.03174-0.28369,0.05908-0.42334
        C293.95605,221.33447,293.93066,221.47412,293.92432,221.61865z"/>
      <path class="megm-1" d="M300.97266,199.66504c-0.10254-0.20459-0.20703-0.40723-0.31689-0.60693
        C300.77344,199.2793,300.87988,199.48438,300.97266,199.66504z"/>
      <path class="megm-1" d="M749.90332,406.42383c-0.00098,0.00781,0.00098,0.0166,0,0.02441c0-0.00098,0-0.00293,0-0.00488
        c0.37207-2.31445,10.5918-9.35254,10.53809-13.95264C760.46875,397.0957,750.27441,404.10449,749.90332,406.42383z"/>
      <path class="megm-1" d="M750.25293,407.84375c0.01953,0.03516,0.03906,0.07031,0.06055,0.10547
        C750.29199,407.91406,750.27246,407.87891,750.25293,407.84375z"/>
      <path class="megm-1" d="M323.35156,208.38232l0.65381-0.21289c-0.22217,0.07031-0.44092,0.13916-0.65479,0.20654
        C323.35107,208.37793,323.35156,208.38037,323.35156,208.38232z"/>
      <path class="megm-1" d="M304.90137,218.01563c-2.23145-0.92188-4.46582,3.00195-6.48145,1.38965
        c-0.59766-0.42773-1.34375-0.59961-2.06836-0.49316c-0.59863,0.09131-1.12256,0.36377-1.53125,0.75
        c0.96875-0.91162,2.47607-1.04785,3.59961-0.25391c2.01563,1.61035,4.25-2.30469,6.48145-1.3916
        c5.17285,0.8877,10.40332,1.4248,15.64258,1.61523c3.27539,1.11328,6.34863,2.71582,9.13525,4.76025
        c0-0.00195-0.00049-0.00391-0.00049-0.00537c-2.78662-2.04102-5.85986-3.64648-9.13477-4.75879
        C315.30469,219.4375,310.07422,218.90039,304.90137,218.01563z"/>
      <path class="megm-1" d="M751.94043,409.89844c-0.14941-0.15039-0.2959-0.30078-0.43652-0.44922
        c0.13965,0.14844,0.28613,0.29883,0.43555,0.44922C751.94043,409.89844,751.94043,409.89844,751.94043,409.89844z"/>
      <path class="megm-1" d="M749.92188,406.97852c-0.00586-0.03418-0.01758-0.06934-0.02148-0.10254
        C749.90527,406.90918,749.91602,406.94434,749.92188,406.97852z"/>
      <path class="megm-1" d="M201.85254,204.47656c-0.87256,1.09863-1.4917,1.37744-1.80908,1.01172
        c0.3125,0.39697,0.93994,0.12598,1.82471-0.9873c1.62598-2.05273,3.55273-1.84521,4.23584-0.59375
        C205.43555,202.63086,203.49414,202.39893,201.85254,204.47656z"/>
      <path class="megm-1" d="M283.43506,214.0791c1.41895-0.02881,3.42822,0.79004,4.60254,0.81592
        C286.86328,214.86816,284.85352,214.0498,283.43506,214.0791z"/>
      <path class="megm-1" d="M751.02637,408.91797c0.01465,0.0166,0.02734,0.0332,0.04199,0.05078
        C751.05371,408.95117,751.04102,408.93457,751.02637,408.91797z"/>
      <path class="megm-1" d="M750.58984,408.36328c0.02051,0.0293,0.03906,0.05762,0.06055,0.08691
        C750.62891,408.4209,750.61035,408.39258,750.58984,408.36328z"/>
      <path class="megm-1" d="M754.64941,412.5791c-0.80762-0.96582-1.70508-1.85742-2.6875-2.6582
        C752.80957,410.77539,753.78418,411.66699,754.64941,412.5791z"/>
      <path class="megm-1" d="M757.07422,340.77539c0.05762-0.01123,0.11328-0.01563,0.1709-0.02295
        C757.1875,340.75635,757.13086,340.76709,757.07422,340.77539z"/>
      <path class="megm-1" d="M694.47266,267.43311c-0.20313-0.17725-0.40723-0.37842-0.6123-0.60254
        C694.06543,267.05518,694.26953,267.25586,694.47266,267.43311z"/>
      <path class="megm-1" d="M750.02051,407.34961c0.01367,0.03613,0.03027,0.07324,0.0459,0.11035
        C750.05078,407.42285,750.03418,407.38574,750.02051,407.34961z"/>
      <path class="megm-1" d="M693.15039,265.96484c0.22266,0.30664,0.44531,0.57617,0.66699,0.82227
        c-0.22168-0.24561-0.44434-0.51953-0.66699-0.82617c-3.59668-4.94727-9.19141-9.89258-12.38574-9.89258
        c-1.25488,0-3.86621,0.00439-6.20703,0.48584c2.33789-0.47949,4.94531-0.48389,6.20605-0.48389
        C683.94727,256.07031,689.54492,261.01563,693.15039,265.96484z"/>
      <path class="megm-1" d="M699.69531,268.06348c-0.79004,0.45801-2.22461,0.99658-3.87891,0.25098
        C697.48438,269.06885,698.92188,268.521,699.69531,268.06348z"/>
      <path class="megm-1" d="M710.94434,264.50684c0.98828-3.16992,3.85742-5.3877,7.17676-5.55371
        c3,0.31543,2.69336-4.00879,3.59668-4.00879c1.64258-0.07324,3.17285-0.86035,4.18457-2.15625
        c0.73145-1.25391,4.01563-0.27441,6.0332,0.96387c-2.01367-1.24268-5.31836-2.229-6.04785-0.97363
        c-1.00684,1.30078-2.53223,2.08691-4.18359,2.15723c-0.89844,0-0.5918,4.32129-3.58789,4.00879
        c-3.31934,0.16602-6.19238,2.38672-7.18555,5.55371c0,2.16113,0.29395,5.24414-3.59375,4.93848
        c-2.45996-0.12793-4.86035-0.72266-7.10449-1.73438c-0.00195,0.00098-0.00195,0.00146-0.00391,0.00244
        c2.24707,1.01807,4.65918,1.60693,7.11719,1.74463C711.23828,269.75098,710.94434,266.66797,710.94434,264.50684z"/>
      <path class="megm-1" d="M760.12598,270.63867c-0.20801-0.13965-0.43945-0.23389-0.70605-0.27246
        c-1.89648-0.28662-12.64453-0.05371-14.64844-1.38379c1.97754,1.33984,12.75293,1.10742,14.6582,1.39258
        C759.69141,270.4126,759.9209,270.50342,760.12598,270.63867z"/>
      <path class="megm-1" d="M748.16016,360.95996c0.01953-2.50928,1.44336-5.59863,1.74316-6.83496
        c0.37207-1.54395,3.91211-3.81934,4.02148-7.50293c-0.11816,3.66895-3.64941,5.94678-4.02148,7.4834
        C749.59961,355.34082,748.16992,358.44434,748.16016,360.95996z"/>
      <path class="megm-1" d="M298.41357,202.77148c0.94287-0.66699,1.83496-1.40625,2.65674-2.22461
        c0.53467,0.30176,1.05127,0.6333,1.5708,0.96045c-0.52002-0.32861-1.03711-0.66064-1.57275-0.96436
        C300.6167,200.95166,299.56299,201.88525,298.41357,202.77148z"/>
      <path class="megm-1" d="M754.65039,342.50928c-0.02051,0.03125-0.04102,0.0625-0.06055,0.09375
        C754.60938,342.57129,754.62988,342.54053,754.65039,342.50928z"/>
      <path class="megm-1" d="M756.13281,341.12744c0-0.00146,0-0.00293-0.00098-0.00439c-0.01367,0.0083-0.02539,0.01904-0.03906,0.02734
        C756.10645,341.14258,756.11914,341.13477,756.13281,341.12744z"/>
      <path class="megm-1" d="M749.68555,377.85791c0.44238,2.96387,0.16895,5.8833,0.39063,7.51563
        C749.85938,383.7373,750.13184,380.82031,749.68555,377.85791z"/>
      <path class="megm-1" d="M774.28027,339.69531c0.30859,3.44775-4.47461,5.22266-7.13477,4.60449
        C769.80566,344.9209,774.58887,343.15039,774.28027,339.69531z"/>
      <path class="megm-1" d="M759.75195,410.52148c2.59668,0.29492,5.18359,0.67578,7.75391,1.14355
        C764.93652,411.19727,762.34961,410.81543,759.75195,410.52148z"/>
      <path class="megm-1" d="M756.41699,340.98828c0.19434-0.08496,0.3877-0.15234,0.57422-0.19434
        C756.79199,340.83057,756.60156,340.896,756.41699,340.98828z"/>
      <path class="megm-2" d="M648.52344,607.87402c0.4541-6.63281-4.94238-7.25977-6.58984-3.55762
        c-1.65332,3.70801-14.51172,6.18262-22.75488,6.33398c-5.67773-0.74219-11.42871,0.625-16.16016,3.85254
        c-2.99121,2.93457-10.47461-0.77441-13.16504,2.46973c-2.69434,3.24902-13.32129,6.0127-16.76074-1.38086
        c-1.07715-2.47559-2.8877-4.55859-5.2002-5.95898c-1.52832,1.60742-2.92773,3.32324-4.18848,5.15527
        c-0.00586-0.00586-0.01074-0.00977-0.0166-0.01563c-0.00098,0.00098-0.00098,0.00195-0.00195,0.00293
        c0.00879,0.00781,0.01563,0.01367,0.02441,0.02246c-1.12695,1.67773-1.84668,3.14941-1.53711,3.87402
        c0.5332,1.25391,1.14844,2.46777,1.83691,3.6377c0.03613,0.06152,0.06836,0.12402,0.10449,0.18555
        c0.15234,0.25391,0.31348,0.50098,0.47266,0.75098c0.09766,0.15332,0.18945,0.30957,0.29004,0.46094
        c0.11914,0.17969,0.24609,0.35449,0.36914,0.53125c0.14844,0.21484,0.29297,0.43262,0.44727,0.64355
        c0.12793,0.17578,0.26465,0.3457,0.39648,0.51855c0.15625,0.20508,0.30957,0.41406,0.4707,0.61523
        c0.59668,0.74316,1.22852,1.45996,1.89355,2.14746c1.34082,0.69043,3.30664,3.57129,5.05762,5.18262h69.32617
        c0.90039-2.37109,1.66211-4.79688,2.25-7.27441C644.93848,622.36816,648.08398,614.50293,648.52344,607.87402z"/>
      <path class="megm-0" d="M47.86523,420.8584c5.07031-4.0791,10.47852-7.71973,16.16602-10.87793
        c2.31152-1.36523,6.59473,0.67578,6.59473-2.04102c0-1.36328,1.40723-1.70117,3.22852-1.78516
        c0.90674-0.04297,1.9165-0.02246,2.90674-0.03418c0.98877-0.01172,1.95752-0.05664,2.78369-0.22852
        c3.04004-0.42383,5.30176-3.04004,5.26855-6.11426c0-3.06836,4.62109-2.38574,7.58984-2.72852
        c2.96484-0.33984,2.63574-3.05859,4.62012-3.05859c1.96973,0,0.9873-3.05859,1.31348-6.46289
        c0.24854-2.5542,0.31104-2.80469,1.58203-2.77148c0.42139,0.01025,0.97705,0.05273,1.71875,0.05371
        c2.96729-0.00195,5.93066-1.69922,5.93066-3.73926c0-0.7666,1.81592-1.0542,4.18848-1.11426
        c3.93066-0.10205,9.39941,0.42334,10.66602,0.42725c1.99072-0.00146,2.6416-2.37598,2.31055-4.07666
        c-0.33105-1.69727-1.98145-6.80762-3.95703-7.14648c-1.97852-0.33691-0.66504-5.4375-0.66504-9.52441
        c0.24609-3.85254-0.79102-7.68164-2.96777-10.87793l-1.66016-3.21289c-3.60547,0.07129-7.98242-2.72852-11.74512-2.83301
        c-5.23926-0.15527-13.31641,2.62109-17.20508-1.85156c-3.89648-4.47949-6.59961-5.86621-9.42773-4.47949
        c-2.83105,1.3877-8.0752,16.81836-11.08203,21.28809c-3.94922,4.44141-9.47949,7.1582-15.41504,7.56641
        c-4.03906,0-4.48633,6.31738-7.62207,8.94043c-3.14746,2.62988-1.95313,6.79297-5.69336,9.87109
        c-3.74707,3.08203-1.93945,12.49707,0,15.89355c1.94238,3.40332-6.88184,14.03711-11.96875,17.12695
        c-5.08887,3.08496-6.73828,8.94043-14.81445,10.33008c-2.77148,0.4502-5.33398,1.74219-7.33105,3.70215h0.00391
        c0.00244-0.00293,0.00439-0.00488,0.00684-0.00781h37.63672c7.80615,0,6.01465,1.80273,7.26367,3.99512
        C47.9209,428.25781,47.70215,421.51855,47.86523,420.8584z"/>
      <path class="megm-0" d="M222.75586,338.53711c1.67871,3.95117,0.43652,8.53125-2.99512,11.10645
        c-2.24609,1.16016-3.96875,6.11426-6.14453,6.72656c-0.15039,0.04395-0.30273,0.06934-0.45801,0.06934
        c-0.14307,0-0.26709,0.03711-0.37646,0.1001c-1.68604,1.03076,0.65869,9.26465,4.5835,11.00146
        c2.80566,1.00977,4.54492,3.82031,4.19043,6.78027c0,1.85156,5.99023,3.7002,7.78516,6.17773
        c2.65039,5.84766,4.26855,12.11133,4.78125,18.51172c-0.00488,0.98047,0.30566,1.94043,0.89209,2.73438
        c0.02979-0.01758,0.05615-0.03809,0.08594-0.05566c0.73096-0.51172,1.53027-1.0625,2.25098-1.6582
        c0.68506-0.63037,1.30469-1.33203,1.84424-2.09668c1.39258-2.5498,2.02344-5.44238,1.79785-8.32813
        c-0.67383-1.58594-0.31543-3.41504,0.89551-4.62891c1.34375-1.23438,6.7373-7.09375,7.77637-7.55859
        c0.27979-0.12402,0.73291-0.1709,1.24121-0.23633c0.32471-0.04199,0.67139-0.0918,1.01318-0.1748
        c1.12402-0.27539,2.17578-0.91504,2.09033-2.83203c-0.01367-1.81641,0.08496-3.63184,0.29688-5.43652
        c-2.41602-1.61328-3.89063-3.5-3.89063-4.43359c0-2.00195-2.69434-5.40039-4.1875-3.55273
        c-1.48828,1.85059-7.17871,0.31445-7.93359-3.5498c-0.75195-3.86035,3.73242-3.7002,7.93359-7.24219
        c3.94922-3.20215,4.55176-8.99707,1.34863-12.94629c-0.31934-0.39355-0.6748-0.75879-1.05566-1.09766
        c-4.49414-3.70117-1.3457-5.70703,1.79395-9.0957c3.14844-3.39453,0.31055-5.25-1.3457-3.7041
        c-1.65039,1.5498-4.33496,2.00586-4.78027-1.69629c-0.43945-3.70313-10.17773-4.31836-13.91895-0.77637
        c-1.53955,1.20996-3.4751,1.78564-5.4248,1.60596C221.55078,327.60156,221.51807,333.1748,222.75586,338.53711z"/>
      <path class="megm-2" d="M111.75684,379.95605c-2.36475,0.06104-4.17188,0.34912-4.17188,1.11426
        c0,2.0459-2.9668,3.73926-5.93359,3.73926c-0.00488,0-0.00879,0-0.01367,0c-0.7417-0.00098-1.29736-0.04346-1.71875-0.05371
        c-1.25537-0.03027-1.31885,0.23047-1.56641,2.77148c-0.33008,3.39844,0.65723,6.46289-1.31641,6.46289
        c-1.98145,0-1.65234,2.71875-4.61719,3.05859c-2.96582,0.34277-7.59082-0.33984-7.59082,2.72852
        c0.03027,3.07422-2.22559,5.69043-5.26953,6.11426c-0.8291,0.17285-1.80322,0.2168-2.79736,0.22852
        c-0.99023,0.01172-2-0.00879-2.90674,0.03418c-1.81396,0.08496-3.21484,0.4248-3.21484,1.78516
        c0,2.72461-4.28223,0.67578-6.58984,2.04102c-5.69434,3.1582-11.10742,6.79883-16.17383,10.87793
        c-0.16602,0.66309,0.06348,7.40234,0.23242,14.2334c0.47559,0.79297,1.16113,1.43945,1.97363,1.87402
        c4.98535,2.93652,95.37012,64.55371,98.21484,67.49121c2.46191,2.94922,4.60547,6.14746,6.41016,9.53809
        c2.30615,0.30371,4.56836,0.85645,6.75342,1.62988c1.58936,0.56152,3.13867,1.23535,4.62842,2.03125
        c0.49902,0.29395,0.93555,0.65039,1.33984,1.03516c3.63916,3.43945,3.64648,9.96484,3.64648,9.97363l1.32324-0.29297
        l7.28125-1.61719l0,0l0.03809-0.00781l1.31836-0.29297c3.55664-0.72852,15.65918-5.13281,15.65918-5.13281l18.50586-15.4043
        l43.41797-27.87793h0.10352c-1.34277-4.92969-2.77588-8.8877-5.0918-8.8877c-2.62598,0-4.49609-2.31152-7.47852-2.31152
        c-2.97754,0-6.71484-1.54492-7.08887-4.23828c-0.37207-2.69238,1.11523-4.61035-1.12402-8.07813
        c-2.23145-3.46289-4.47559-5.00781-4.47559-6.54297c0-1.53125,3.79688-4.54492,4.39063-5.16113
        c0.59766-0.62109-1.49805-3.08496-1.49805-4.78516c0-1.69336-1.48926-4.62695,0-6.47852
        c1.87891-1.89648,2.19043-4.8418,0.74707-7.08984c-1.64063-3.39551,2.24902-6.48145,0.30957-10.95117
        c-1.9502-4.47461-4.9375-8.02441-4.64551-9.56641c0.01123-0.05957,0.04102-0.12109,0.06543-0.18164
        c0.01074-0.02637,0.01563-0.05176,0.02832-0.07813c0.03174-0.06543,0.07666-0.13184,0.12158-0.19824
        c0.0166-0.02344,0.02979-0.04785,0.04785-0.07129c0.05176-0.07031,0.11279-0.14063,0.17725-0.21191
        c0.01758-0.01953,0.03467-0.03906,0.05273-0.05859c0.07373-0.07715,0.15332-0.15527,0.23926-0.23438
        c0.01025-0.00879,0.02051-0.01758,0.03027-0.02734c0.39893-0.35938,0.91504-0.7373,1.48633-1.13867
        c-0.00098-0.00098-0.00146-0.00195-0.00244-0.00293c-0.58643-0.79395-0.89697-1.75391-0.89209-2.73438
        c-0.5127-6.40039-2.13086-12.66406-4.78125-18.51172c-1.79492-2.47754-7.78516-4.32617-7.78516-6.17773
        c0.35449-2.95996-1.38477-5.77051-4.19043-6.78027c-3.9248-1.73682-6.26953-9.9707-4.5835-11.00146
        c0.11328-0.06934,0.24463-0.10693,0.39502-0.10693c0.14893,0,0.29541-0.02148,0.43945-0.0625
        c2.17578-0.6123,3.89844-5.56641,6.14453-6.72656c3.43164-2.5752,4.67383-7.15527,2.99512-11.10645
        c-1.23779-5.3623-1.20508-10.93555,0.08984-16.28662c0.00049-0.00195,0.00049-0.00342,0.00098-0.00537
        c-3.25391-0.16504-6.46777-0.7998-9.54199-1.90039c-3.46484,0.0498-6.91992,0.41504-10.32617,1.07617
        c-3.29199,0.15137-11.37598,5.55469-14.06738,2.92871c-12.72168-0.5459-25.45313,0.22754-38.01172,2.30566
        c-7.78516,0.31543-11.07715,8.64551-15.71777,8.64551c-6.13574,0.83691-11.7666,3.84668-15.86133,8.48047
        c-0.94336,1.12891-2.35059,1.76953-3.82031,1.75586l1.66113,3.21289c2.1709,3.19824,3.21387,7.0332,2.96582,10.88574
        c0,4.08691-1.3125,9.1875,0.66992,9.52441c1.97363,0.33887,3.62109,5.44141,3.95215,7.14648
        c0.32422,1.70215-0.33105,4.07715-2.30957,4.07715c-0.00488,0-0.01172-0.00049-0.0166-0.00049
        C121.15625,380.37939,115.6875,379.854,111.75684,379.95605z"/>
      <path class="megm-2" d="M383.6748,413.05176c0-7.07227-3.11621-11.57813,0-15.43848c3.125-3.86035-1.25098-5.79297,1.25-12.87109
        l0.92676-2.59766c0.00049,0.00049,0.00098,0.00049,0.00195,0.00098l0.00195-0.00586l-0.29297-0.40723
        c-0.75-2.15234-4.78027-2.30469-13.60938-3.70215c-8.82813-1.39063-5.54883-7.55469-15.4209-8.16602
        c-9.87988-0.61523-22.44629,7.55469-22.5957,11.86816c-0.13867,4.3125,4.6416,9.10352,1.35449,13.58008
        c-3.78711,4.33887-10.36621,4.78516-14.70215,1.00293c-0.30078-0.2627-0.59375-0.55078-0.86816-0.84961
        c-5.18945-4.50781-11.83691-6.98047-18.69922-6.94043c-6.14453,0-10.47949-4.01465-10.03418-7.40723
        s-1.49316-4.94531-6.58008-6.01465c-5.08789-1.06738-9.43164-5.40137-18.4082-3.85645
        c-4.07422,0.69238-8.24805-0.20703-11.67578-2.50781c-0.20996,1.80469-0.31348,3.62012-0.2959,5.43945
        c0.08105,1.91602-0.97949,2.55371-2.10791,2.8291c-0.3418,0.08301-0.68848,0.13281-1.01318,0.1748
        c-0.50488,0.06543-0.9541,0.11279-1.23242,0.23633c-1.04297,0.46484-6.42578,6.32422-7.78223,7.55859
        c-1.21387,1.21387-1.56738,3.04297-0.89355,4.62891c0.2207,2.88574-0.40527,5.77832-1.79395,8.32813
        c-0.3916,0.76074-1.07568,1.45508-1.85303,2.09668c-0.7207,0.5957-1.52002,1.14648-2.25098,1.6582
        c-0.02832,0.01953-0.05566,0.03906-0.0835,0.05859c-0.57129,0.40137-1.0874,0.7793-1.48633,1.13867
        c-0.00977,0.00977-0.02002,0.01855-0.03027,0.02734c-0.08594,0.0791-0.16553,0.15723-0.23926,0.23438
        c-0.01807,0.01953-0.03516,0.03906-0.05273,0.05859c-0.06445,0.07129-0.12549,0.1416-0.17725,0.21191
        c-0.01807,0.02344-0.03174,0.04785-0.04785,0.07129c-0.04492,0.06641-0.08984,0.13281-0.12158,0.19824
        c-0.0127,0.02637-0.01758,0.05176-0.02832,0.07813c-0.02441,0.06055-0.0542,0.12207-0.06543,0.18164
        c-0.29199,1.54199,2.69531,5.0918,4.64551,9.56641c1.93945,4.46973-1.9502,7.55566-0.30957,10.95117
        c1.44336,2.24805,1.13184,5.19336-0.74707,7.08984c-1.48926,1.85156,0,4.78516,0,6.47852c0,1.7002,2.0957,4.16406,1.49805,4.78516
        c-0.59375,0.61621-4.39063,3.62988-4.39063,5.16113c0,1.53516,2.24414,3.08008,4.47559,6.54297
        c2.23926,3.46777,0.75195,5.38574,1.12402,8.07813c0.37402,2.69336,4.11133,4.23828,7.08887,4.23828
        c2.98242,0,4.85254,2.31152,7.47852,2.31152c2.31592,0,3.74902,3.95801,5.0918,8.8877
        c0.00098,0.00293,0.00195,0.00684,0.00293,0.00977c5.09668-0.11426,10.16113,0.89355,14.83301,2.93652
        c2.55469,1.74609,4.72656,3.99414,6.40527,6.5918l17.08105-9.53809l77.26953,42.8291v-5.51367v-0.00879h9.37402v-21.91504
        h-0.01367V413.05176z"/>
      <polygon class="megm-2" points="10.75342,573.9375 10.74609,573.9375 10.75342,573.9375 		"/>
      <path class="megm-2" d="M4.52539,598.54199c0.02832,0.10156,0.09082,0.20117,0.18457,0.29883
        c1.39941,1.45117,9.7915,2.36035,14.63477,0.62793l1.10059-0.41016c0.07031-2.19824,0.07324-4.39941-0.04688-6.5957
        c-1.40332-2.55664-3.21973-4.85156-5.39648-6.78809c-1.17188-1.94727-1.7041-4.21289-1.49805-6.48438l-1.85645-2.83691
        c0,0-0.00732-0.0293-0.02051-0.07813l-0.88086-2.33789c-0.03369-0.08496-0.07666-0.16406-0.11328-0.24707
        c-0.36914-0.75-0.84717-1.5625-1.45996-2.31348c-0.06982-0.08008-0.13867-0.16113-0.21094-0.23926
        c-0.04248-0.04883-0.08594-0.09668-0.12939-0.14551c-0.81445-0.83398-1.79102-1.50977-2.8833-1.97852
        C3.30566,568.1084,1.21338,564.67578,0,562.18555v34.0127C1.93848,596.75684,4.26709,597.61035,4.52539,598.54199z"/>
      <path class="megm-2" d="M370.56836,631.81445c1.16992-0.60547,1.60889-1.52441,1.64063-2.55957
        c0.06592-2.2793-1.83496-5.12109-2.26172-6.44434c-0.13818-0.22754-0.2876-0.44824-0.42822-0.67383
        c-1.56006-2.47168-3.23975-4.86426-5.04785-7.1582c-0.55469-0.69336-1.11621-1.38281-1.6958-2.05566l-1.75391-0.41016
        c-2.08691-1.2373-6.28809,1.2334-8.37402,3.39063c-2.09277,2.15723-0.60742,3.39746-2.10547,4.95117
        c-3.5127,3.27832-6.80957,6.78027-9.87207,10.48438c-0.77344,1.01367-1.83203,1.62695-3.02539,2.00684h31.09082
        C369.00586,632.86035,369.55225,632.33789,370.56836,631.81445z"/>
      <path class="megm-2" d="M269.1377,628.85645c1.49316-0.62207,2.69434-5.24414,2.38867-6.77832
        c1.4541-4.07129,3.46289-7.91699,5.98145-11.41699c0.9043-1.24512,5.68457-0.61816,5.99414-3.39258
        c0.26074-2.42578,0.98145-5.0957-0.04932-6.1416c-0.01563-0.01563-0.03564-0.02734-0.05176-0.04199
        c-0.05908-0.05469-0.11963-0.10742-0.18994-0.15137c-0.09082-0.05664-0.19043-0.10547-0.30078-0.14355
        c-0.22754-0.07813-0.46387-0.18066-0.7041-0.30273c-0.67285-0.34082-1.36963-0.84766-1.9668-1.41504
        c-0.03418-0.03223-0.07227-0.06152-0.10596-0.09473c-0.10791-0.10645-0.2002-0.21875-0.2998-0.32813
        c-0.09033-0.09961-0.18896-0.19629-0.271-0.29785c-0.10254-0.12695-0.18115-0.25586-0.26855-0.38379
        c-0.05811-0.08594-0.12939-0.17188-0.17969-0.25781c-0.25488-0.43164-0.40625-0.85938-0.40625-1.24902
        c0.02246-1.88184,0.23242-3.75391,0.62158-5.5918c-1.91455-0.26758-2.37988-1.88379-3.17188-3.25781
        c-0.06836-0.11621-0.13721-0.23242-0.21143-0.34473c-0.03418-0.05176-0.07031-0.10254-0.10596-0.15332
        c-0.12842-0.18066-0.2627-0.35645-0.41748-0.51563c-1.02246-1.05469-1.38281-1.92578-2.14648-2.19727l0.00049-0.0332
        c-0.00732-0.00293-0.01465-0.00586-0.02197-0.00781l-0.00098,0.05469c-0.30957-0.09668-0.62988-0.12207-0.94287-0.08594
        c-0.12793,0.02148-0.25586,0.04395-0.40479,0.08496c-0.03027,0.00977-0.0625,0.01465-0.09277,0.02539
        c-0.12744,0.03809-0.25635,0.09375-0.38574,0.16406c-1.99707,1.09961-4.25586,5.75391-6.77734,6.62207
        c-0.16943,0.05762-0.34375,0.10352-0.52148,0.13867c-2.65283,0.53418-6.19873-1.42969-7.88477-2.2998
        c-4.27734-0.41992-8.58496-0.51758-12.87598-0.29785c-0.6665-0.07813-1.33301,0.11035-1.99072,0.45605
        c-1.99072,1.05762-3.89697,3.54688-5.46143,4.46289c-0.40576,0.11719-0.81494,0.21875-1.22607,0.30664
        c-2.86523,0.61816-5.84424,0.51758-8.67236-0.30176c-1.7959-0.61133-5.99316-4.31348-7.78906-4.31348
        c-2.95996,0.11133-5.83691,0.95898-8.37207,2.47363c-3.00098,1.8584-3.89258-3.39063-5.69434-5.55176
        c-1.79492-2.15137-5.68848-0.29883-8.08301-2.46484c-0.74316-0.67285-1.39941-0.68848-2.01465-0.39941
        c-0.51318,0.24414-0.99805,0.69531-1.47949,1.15234c-0.79785,0.7627-1.58789,1.54492-2.48828,1.41309
        c-2.09473-0.31445-7.47754-1.54688-9.27832,0c-0.09277,0.0791-0.17627,0.17676-0.25781,0.27832
        c-1.49023,1.88965-1.27441,7.16699-2.12207,8.04395c-0.89063,0.91602-4.48633,5.2373-4.48633,7.39941
        c0.03857,0.42969-0.05127,0.84863-0.2373,1.21875c-0.15527,0.38867-0.39746,0.6875-0.71973,0.88965l1.02539,6.98242
        c0.11084,1.04004,0.39258,4.16309,0.36523,7.31641c-0.02686,3.15234-0.36279,6.33691-1.4873,7.49805
        c-1.57715,1.79688-2.86035,3.82227-3.82129,6.01758c-0.62842,0.50977-1.19336,1.08301-1.69824,1.70215h100.54053
        C267.88965,631.66016,267.92822,629.36035,269.1377,628.85645z"/>
      <path class="megm-2" d="M169.07715,631.62988c0.96094-2.18262,2.24414-4.21289,3.82129-6.0127
        c1.12402-1.15625,1.45996-4.33789,1.4873-7.48926c0.02734-3.15332-0.25439-6.27637-0.36523-7.31641l-1.02539-6.98242
        c-0.4165,0.26074-0.96484,0.35645-1.64209,0.25586c-0.46484-0.0293-0.91455-0.17578-1.33545-0.39453
        c-0.09473-0.03906-0.17969-0.06055-0.27734-0.10547c-2.69336-1.24219-0.29199-4.0166-3.29395-4.94238
        c-1.10254-0.3418-1.96045-0.09473-2.57373,0.47754c-0.00049,0-0.00146,0.00098-0.00195,0.00098
        c-0.07471,0.07031-0.14258,0.15039-0.20996,0.22949c-0.02686,0.03125-0.05713,0.05957-0.08252,0.09277
        c-0.04639,0.05859-0.08643,0.125-0.12939,0.18848c-0.04004,0.06055-0.0835,0.11816-0.12061,0.18164
        c-0.02686,0.04688-0.04932,0.09766-0.07471,0.14648c-0.04541,0.08691-0.09229,0.17383-0.13184,0.26563
        c-0.01416,0.0332-0.0249,0.06836-0.03857,0.10156c-0.04492,0.1123-0.08887,0.22559-0.12549,0.34375
        c-0.00586,0.01953-0.01025,0.04004-0.01611,0.05957c-0.03955,0.13477-0.07568,0.27148-0.10449,0.41016
        c-0.00146,0.00684-0.00195,0.01367-0.00342,0.01953c-0.15088,0.74609-0.14355,1.55176,0.01514,2.28516
        c0.00488,0.00488,0.01074,0.00781,0.01563,0.0127l0.00244,0.0791c0.00195,0.00977,0.00293,0.01953,0.00537,0.0293
        c0.60254,2.46973-1.49609,2.47656-2.69531,4.00684c-1.19629,1.53809-6.59082,1.85156-8.38477,1.85156
        c-1.79102,0-3.88184,3.40527-5.68262,4.01855l-1.604,0.11133c0.41455,8.12988,1.72754,7.49805,5.48975,9.74512
        c1.60352,0.95703,2.03516,2.04395,2.04395,3.12598c0.0127,1.26074-0.54883,2.51367-0.50537,3.55078
        c0.01953,0.44434,0.15088,0.84766,0.48682,1.19531c0.23389,0.24121,0.42383,0.53223,0.58154,0.86523
        c0.18164,0.37891,0.31592,0.82031,0.41943,1.30859h14.34229C167.87256,632.72266,168.44189,632.14453,169.07715,631.62988z"/>
      <path class="megm-2" d="M152.02051,631.17188c-0.33594-0.34766-0.46729-0.75098-0.48682-1.19531
        c-0.04639-1.03613,0.51563-2.29102,0.50537-3.55078c-0.00879-1.08203-0.44043-2.16895-2.04395-3.12598
        c-3.76221-2.24707-5.0752-1.61523-5.48975-9.74512c0-0.00391-0.00049-0.00586-0.00049-0.00977
        c-0.78418-0.05176-1.70605-0.21387-2.67041-0.40918c-1.65771-0.20313-3.30322-0.50781-4.9248-0.92871
        c-0.02539-0.00293-0.05371-0.00781-0.07861-0.01074l-1.21582,0.22754c0.13867,2.36426,0.83594,4.66602,2.0332,6.7168
        c2.00488,1.39844,2.98047,3.85449,2.47461,6.24609c-0.38721,2.6377-0.53369,5.30078-0.45459,7.95898h13.3501
        c-0.10303-0.48828-0.23633-0.92969-0.4165-1.30859C152.44434,631.7041,152.25439,631.41309,152.02051,631.17188z"/>
      <path class="megm-2" d="M279.63574,631.69336c-1.20313-1.68457-1.58154-3.87109-0.92773-5.91504
        c0-1.54688,5.08691-1.55078,8.68066-1.24023c3.125,0.27441,5.12305-0.39746,4.59326-1.18652
        c-0.07959-0.1123-0.20996-0.22754-0.39697-0.34277c-1.04883-1.39941-1.91211-2.91504-2.57666-4.50879
        c-1.11084-2.65918-1.66992-5.53613-1.61963-8.45703c0.29785-4.94043-1.49316-6.48145-1.19141-9.26074
        c0.22168-2.08203-1.7373-6.59277-3.99854-8.71875c-0.08838-0.08301-0.17871-0.14941-0.26807-0.22559
        c-0.09961-0.08398-0.19824-0.17676-0.29883-0.25c-0.57324-0.42383-1.15576-0.67285-1.7168-0.67285l-0.09717-0.00977
        c-0.16797-0.00391-0.32861-0.01367-0.47803-0.03418c-0.38867,1.84082-0.60449,3.71484-0.62695,5.59863
        c0,0.38672,0.14941,0.81152,0.40137,1.24023c0.05029,0.08594,0.12158,0.17188,0.17969,0.25781
        c0.08691,0.12793,0.16602,0.25684,0.26855,0.38379c0.08203,0.10156,0.18066,0.19824,0.271,0.29785
        c0.1001,0.10938,0.19189,0.22168,0.2998,0.32813c0.03369,0.0332,0.07178,0.0625,0.10596,0.09473
        c0.5957,0.56543,1.29248,1.07129,1.9668,1.41504c0.24023,0.12207,0.47656,0.22461,0.7041,0.30273
        c0.11035,0.03809,0.20996,0.08691,0.30078,0.14355c0.07031,0.04395,0.13037,0.09766,0.18994,0.15137
        c0.01611,0.01465,0.03613,0.02637,0.05176,0.04199c1.03809,1.04102,0.31152,3.71387,0.0542,6.15039
        c-0.2959,2.77441-5.08984,2.14746-5.99512,3.3877c-2.51367,3.50098-4.52832,7.35059-5.98047,11.41797
        c0.30469,1.53906-0.89551,6.16504-2.38867,6.78711c-1.20752,0.49219-1.24805,2.79102-1.21826,4.47559h13.34863
        c-0.05615-0.05273-0.11133-0.10059-0.16748-0.1543C280.52881,632.76465,280.03662,632.25684,279.63574,631.69336z"/>
      <path class="megm-2" d="M107.86182,626.77051c0,0-0.00049-0.00098-0.00049-0.00195c-0.28906,0.02148-0.61426-0.06055-0.97461-0.27051
        c-0.32715-0.16211-0.61768-0.3916-0.85107-0.68164c-0.01465-0.01465-0.02881-0.02637-0.04346-0.04102
        c-3.93701-1.92383-8.64453-1.3125-11.96484,1.54199c-2.69043,3.08887-8.0752-2.7793-8.97363-4.31836
        c-0.00586-0.00977-0.01514-0.02246-0.02148-0.03223c-1.16406-1.68945-2.9043-2.88281-4.896-3.34961
        c-0.00049,0.00195-0.00098,0.00293-0.00146,0.00488c-1.00391-0.19824-2.03613,0.01367-2.87207,0.61328
        c-2.26172,1.62891-3.32813,1.66992-3.58008,0.3877c-0.08398-0.41211-0.08496-0.95898-0.01563-1.63672
        c0.09131-0.86523-0.31201-1.37109-0.92676-1.58203c-1.36035-0.45605-3.74219,0.50586-4.14941,2.19922
        c-0.55713,2.32715-4.0293,4.36914-5.44189,3.08008c-0.09521-0.08496-0.18115-0.18457-0.25635-0.30078
        c-0.54688-0.8457-1.40381-1.81348-2.2876-2.35938c-1.05029-0.64355-2.1377-0.68359-2.78955,0.82227
        c-0.83545,1.93555-2.25342,1.77148-3.44189,0.86914c-0.74414,3.42969-1.6748,7.34668-0.0083,8.30078
        c1.20605,0.72754,1.91748,1.99121,1.99121,3.33008h53.53125c-0.5459-2.22754-1.22119-4.42383-2.03809-6.57227
        C107.854,626.77344,107.85742,626.77148,107.86182,626.77051z"/>
      <path class="megm-2" d="M140.1123,625.38672c0.50586-2.3916-0.46582-4.84766-2.46777-6.24609
        c-1.20117-2.04102-1.89453-4.35254-2.03418-6.7168c-1.37988,0.6377-1.78125,2.5791-2.23584,2.83398
        c-0.00195,0.00098-0.00391,0.00195-0.00586,0.00293c-0.04248,0.02246-0.08594,0.02832-0.13086,0.01855c0,0,0,0-0.00049,0
        c-0.09277-0.02148-0.19092-0.11035-0.30371-0.30664c-8.36035-1.89746-17.00195-2.10645-25.43604-0.61426
        c-1.18115,1.85156,2.0542,8.55469,1.79492,10.45117c-0.00146,0.0127,0.00049,0.03027-0.00146,0.04297
        c-0.00439,0.02637-0.01123,0.04883-0.01563,0.07422c-0.01855,0.10449-0.03955,0.20605-0.06445,0.30371
        c-0.00537,0.02148-0.01172,0.04199-0.01709,0.0625c-0.02832,0.10645-0.06006,0.20703-0.0957,0.30469
        c-0.00342,0.00977-0.00732,0.01953-0.01074,0.0293c-0.24854,0.66211-0.65967,1.0918-1.20898,1.14063
        c-0.00537,0.00098-0.01074,0.00293-0.0166,0.00293c-0.00439,0.00098-0.00781,0.00293-0.01221,0.00293
        c0.81689,2.14844,1.49219,4.34473,2.03809,6.57227h29.77881C139.58691,630.68848,139.73096,628.02637,140.1123,625.38672z"/>
      <path class="megm-2" d="M54.35449,630.01563c-1.65625-0.9541-0.73682-4.87109,0.01904-8.30078
        c-0.5127-0.38867-0.98291-0.91309-1.34424-1.46875l-0.01855-0.01953c-0.96729-1.50098-0.55322-4.43262-1.48584-5.48145
        c-0.21533-0.24023-0.50244-0.38086-0.89404-0.38086c-0.39648,0-0.70703-0.11035-0.93848-0.29199
        c-1.00488-0.78027-0.53027-2.91211,0.93457-3.41113c1.43848-0.49805-1.76611-1.99512-3.07227-3.69531
        c-0.01074-0.01465-0.0249-0.02832-0.03564-0.04199c-0.08447-0.11328-0.15527-0.22754-0.2207-0.34277
        c-0.01074-0.01855-0.02588-0.03711-0.03662-0.05664c-0.14551-0.27051-0.23145-0.54395-0.23145-0.81836
        c-0.40381-2.92383-1.76953-5.63086-3.88867-7.69531c-1.18896-0.30664-5.38867,5.24414-7.17969,5.24414
        c-1.79883,0-5.67871-3.69629-6.88086-0.61426c-1.20508,3.0752-3.89941-1.54199-5.69238,0.9209
        c-0.33838,0.46582-0.65527,0.65625-0.94727,0.66016c-1.26367,0.03125-2.05371-3.43945-2.05371-3.43945l0.03076-0.74023
        c0.01514-0.32715,0.0166-0.65527,0.02686-0.9834l-1.10059,0.41016c-4.84326,1.73242-13.23535,0.82324-14.63477-0.62793
        c-0.09375-0.09766-0.15674-0.19727-0.18457-0.29883c-0.2583-0.93164-2.58691-1.78516-4.52539-2.34375v36.39844
        c0.15576,0.24902,0.31396,0.49609,0.46338,0.74902h2.8457c1.80518-1.64258,3.6001-3.42188,4.479-4.79688
        c0.03516-0.05469,0.07617-0.11133,0.10791-0.16504c0.03223-0.05371,0.05469-0.10254,0.0835-0.15527
        c0.0498-0.08887,0.10254-0.17969,0.14209-0.26465c0.01514-0.03125,0.02246-0.05859,0.03564-0.08887
        c0.04395-0.10156,0.0874-0.20117,0.11572-0.29492c0.00635-0.02051,0.00684-0.03809,0.01221-0.05859
        c0.02734-0.09961,0.05078-0.19824,0.05859-0.28906c0.0083-0.08984,0.02344-0.16504,0.03613-0.24609
        c0.00928-0.0625,0.01563-0.13281,0.02783-0.19043c0.00098-0.00391,0.00195-0.00684,0.00293-0.01074
        c0.11182-0.52344,0.3291-0.85059,0.63135-1.03809c0.07275-0.04492,0.15039-0.08105,0.23242-0.11133
        c0.00293-0.00195,0.00537-0.00293,0.0083-0.00488c0.86475-0.3125,2.23389,0.1582,3.77881,0.44531
        c1.80029,0.33984,3.83008-0.66797,5.66943-1.24316c0.6875-0.21582,1.34814-0.37207,1.95752-0.37207c0.00049,0,0.00098,0,0.00146,0
        c0.00244,0,0.00439,0,0.00635,0c1.88037,0,6.27148,5.68164,7.64746,8.88477h28.0083
        C56.27734,632.00684,55.56055,630.74316,54.35449,630.01563z"/>
      <path class="megm-2" d="M20.69629,624.46094c-0.00195,0-0.00391,0-0.00635,0c-0.61133,0.00098-1.27197,0.15723-1.95898,0.37207
        c-1.83936,0.5752-3.86914,1.58301-5.66943,1.24316c-1.54492-0.28711-2.91406-0.75781-3.77881-0.44531
        c-0.00293,0.00195-0.00537,0.00391-0.0083,0.00488c-0.08203,0.03027-0.15967,0.06641-0.23242,0.11133
        c-0.30127,0.18848-0.51855,0.51465-0.63135,1.03809c-0.00098,0.00391-0.00195,0.00684-0.00293,0.01074
        c-0.01221,0.05762-0.01807,0.12793-0.02783,0.19043c-0.0127,0.08105-0.02783,0.15625-0.03613,0.24609
        c-0.00781,0.09082-0.03125,0.18945-0.05859,0.28906c-0.00537,0.02051-0.00586,0.03809-0.01221,0.05859
        c-0.02832,0.09375-0.07178,0.19336-0.11572,0.29492c-0.01318,0.03027-0.02051,0.05762-0.03564,0.08887
        c-0.03955,0.08496-0.09229,0.17578-0.14209,0.26465c-0.02881,0.05273-0.05176,0.10156-0.0835,0.15527
        s-0.07275,0.11035-0.10791,0.16504c-0.87842,1.37598-2.67188,3.15527-4.4707,4.79688h25.02637
        C26.96777,630.14258,22.57666,624.46094,20.69629,624.46094z"/>
      <path class="megm-2" d="M3.30664,485.83301c0-0.07422,0.01025-0.14941,0.03027-0.22559
        c0.62012-2.35254,10.53809-5.2959,10.53809-7.59961v-27.88965v-0.00488c0,0,32.99121,0.67969,33.99023,0
        c0.49414-4.76855,0.57861-9.56445,0.26025-14.3418c-0.01514-0.22656-0.01855-0.45313-0.03564-0.67969c0,0,0,0,0.00049,0
        c-0.00049-0.00098-0.00049-0.00293-0.00049-0.00488l0,0c-1.24902-2.19238,0.54248-3.99512-7.26367-3.99512H3.18945
        c-0.00244,0.00293-0.00439,0.00488-0.00684,0.00781C1.96631,432.40625,0.90381,433.83887,0,435.36426v66.78809h3.30664V485.83301z
        "/>
      <path class="megm-2" d="M5.94922,569.01367c1.09229,0.46875,2.06885,1.14453,2.8833,1.97852
        c0.04541,0.04688,0.08496,0.09863,0.12939,0.14551c0.07227,0.07813,0.14111,0.15918,0.21094,0.23926
        c0.59766,0.68652,1.0918,1.46387,1.45996,2.31348c0.03662,0.08301,0.07959,0.16211,0.11328,0.24707c0.00244,0,0.00488,0,0.00732,0
        c3.0415-0.07813,5.39404-1.1123,5.39404-3.36816c0-1.92969,0.64795-4.30371,2.52588-4.22559
        c0.85449,0.03418,1.96436,0.5752,3.38525,1.89648c1.41602,1.31348,2.38867,1.85059,3.08545,1.92676
        c0.33789,0.03516,0.60547-0.0459,0.83252-0.19043c0.80029-0.51367,1.03027-1.92773,1.51758-2.67773
        c0.22559-0.35352,0.59668-0.5,1.06787-0.52148c1.40674-0.06738,3.70313,0.9834,5.74316,0.98535
        c2.21436-0.21191,4.375-0.84961,6.35205-1.87109c0,0,0.00635,0,0.00684,0l0,0c0,0,37.69238,0.46289,39.5-0.4668
        c0.11328-0.05859,0.21777-0.14453,0.31396-0.25293c1.44336-1.6416,1.04834-8.63574,1.04834-8.63574l-3.17969-3.27148
        l-9.53027-92.19922h17.05078c-18.20117-12.39941-33.80664-22.92969-35.79102-24.09961
        c-0.81934-0.43457-1.50244-1.08105-1.98584-1.87402c-0.00049,0-0.00049,0-0.00049,0
        c0.01709,0.22656,0.02051,0.45313,0.03564,0.67969c0.31934,4.78027,0.24414,9.58105-0.26025,14.34668
        c-0.98633,0.68262-33.99023,0-33.99023,0v27.88965c0,2.30371-9.91797,5.24707-10.53809,7.59961
        c-0.02002,0.07617-0.03027,0.15137-0.03027,0.22559v16.31934H0v60.0332C1.21338,564.67578,3.30566,568.1084,5.94922,569.01367z"/>
      <path class="megm-2" d="M82.95215,611.57715c0.89648-1.85352-2.09863-6.1748-0.60059-6.79199
        c1.49121-0.61328,6.58008,0.62695,8.07617-3.70508c0.74023-2.32227,1.85156-4.51074,3.29785-6.47852
        c0,0,0.59473-9.24316,3.28711-7.7002c2.70313,1.53516,5.99023,4,6.28809,0.29883c0.30762-3.70215,3.59473-1.5293,4.49219-4.93457
        c0.89844-3.39551,2.69824-3.08887,4.79688-2.47363c2.0918,0.62207,3.88086-0.30078,3.88086-2.15234
        c0-1.8457,4.7959-0.92578,8.68652-3.70215c3.88867-2.78027,5.9873-4.93262,7.4834-3.39648
        c0.03467,0.0166,0.07129,0.02539,0.10596,0.04102c1.98633,0.60547,4.02979,0.96289,6.08643,1.07813
        c0.03223-0.00293,0.06396,0.00098,0.0957-0.00195c-0.00049,0.00293-0.00098,0.00586-0.00098,0.00977
        c0.55664,0.0293,1.11426,0.04102,1.67236,0.03418c1.64111-0.02344,3.27881-0.19434,4.89014-0.52734
        c1.31299-0.54395,3.77246-2.74023,6.78223-2.8584c0.42676-0.01758,0.86523,0.00684,1.3125,0.08301
        c5.30859-0.20703,10.604-0.72754,15.85254-1.54688c3.58887,0,5.08691-3.40332,6.28711-4.62695
        c1.2002-1.22754,2.10352-6.78125,3.2998-7.70898c0.85547-9.23145,1.07617-18.50488,0.65527-27.75684l-7.28125,1.6123
        l-1.32324,0.29297l-0.00391,0.00098c0,0-0.0127-6.52539-3.64258-9.97461c-0.4043-0.38477-0.84082-0.74121-1.33984-1.03516
        c-1.48975-0.7959-3.03906-1.46973-4.62842-2.03125c-2.18701-0.77246-4.45166-1.3252-6.7583-1.62988
        c-1.80566-3.39063-3.9541-6.58887-6.41309-9.53809c-1.70313-1.76953-35.01563-24.71191-62.42188-43.3916H68.81641
        l9.53516,92.19922l3.1748,3.27148c0,0,0.39502,6.99414-1.04834,8.63574c-0.09619,0.10938-0.20068,0.19434-0.31396,0.25293
        c-1.80762,0.92969-39.5,0.4668-39.5,0.4668l0,0c-1.98145,1.02148-4.13477,1.65918-6.35449,1.87109c-0.00146,0-0.00293,0-0.00439,0
        c-2.04004-0.00195-4.33643-1.05273-5.74316-0.98535c-0.46875,0.02344-0.83887,0.16992-1.06787,0.52148
        c-0.4873,0.75-0.71729,2.16406-1.51758,2.67773c-0.22705,0.14551-0.49414,0.22656-0.83252,0.19043
        c-0.69678-0.07617-1.66943-0.61328-3.08545-1.92676c-1.4209-1.32129-2.53076-1.8623-3.38525-1.89648
        c-1.87646-0.07422-2.521,2.29688-2.521,4.22559c0,2.25586-2.35742,3.29883-5.39893,3.36816c-0.00244,0-0.00488,0-0.00732,0
        l0.88086,2.33789l0.02637,0.06934l1.8623,2.8457c-0.20508,2.26758,0.31934,4.53711,1.49805,6.48047
        c2.16895,1.93555,3.99414,4.22852,5.38477,6.79199c0.12012,2.19629,0.11719,4.39746,0.04688,6.5957
        c-0.01025,0.32813-0.01172,0.65625-0.02686,0.9834c-0.01074,0.24707-0.00635,0.49414-0.02002,0.74023
        c0,0,0.78662,3.45605,2.04297,3.43945c0.29199-0.00391,0.60889-0.19434,0.94727-0.66016
        c1.79297-2.46289,4.4873,2.1543,5.69238-0.9209c1.20215-3.08203,5.08203,0.61426,6.88086,0.61426
        c1.79102,0,5.99072-5.55078,7.17969-5.24414l0,0c2.12207,2.06055,3.49121,4.7793,3.89258,7.70508
        c0,0.27051,0.08398,0.54102,0.22754,0.80859c0.01074,0.01953,0.02588,0.03809,0.03662,0.05664
        c0.06543,0.11523,0.13623,0.22949,0.2207,0.34277c0.01074,0.01367,0.0249,0.02734,0.03564,0.04199
        c1.3042,1.69727,4.51953,3.19824,3.07617,3.69922c-1.45605,0.50195-1.93213,2.62402-0.93848,3.40723
        c0.23145,0.18164,0.54199,0.29199,0.93848,0.29199c0.3916,0,0.67871,0.14063,0.89404,0.38086
        c0.93896,1.04492,0.52295,3.98242,1.49658,5.49023c0.00244,0.00391,0.00537,0.00684,0.00781,0.01074
        c0.36133,0.55566,0.83154,1.08008,1.34424,1.46875l0,0c1.18848,0.90234,2.60645,1.06641,3.44189-0.86914
        c0.65186-1.50586,1.73926-1.46582,2.78955-0.82227c0.88574,0.54395,1.74463,1.51367,2.29346,2.36426
        c0.07324,0.11426,0.15771,0.21191,0.25049,0.2959c1.4126,1.28906,4.88477-0.75293,5.44189-3.08008
        c0.40723-1.69336,2.78906-2.65527,4.14941-2.19922c0.62256,0.20898,1.03125,0.71484,0.9375,1.58594
        c-0.07471,0.67578-0.07617,1.22168,0.00488,1.63281c0.25195,1.28223,1.31836,1.24121,3.58008-0.3877
        c0.83594-0.59961,1.86816-0.81152,2.87207-0.61328c0.00049-0.00195,0.00098-0.00293,0.00146-0.00488
        c-0.00439-0.00098-0.00879-0.00293-0.01318-0.00391C80.89746,616.87695,81.84473,614.19922,82.95215,611.57715z"/>
      <path class="megm-2" d="M162.8457,603.47656c-1.13281-1.05566-2.56641-1.7334-4.10547-1.9043
        c-0.89746,0-0.22656-5.0918-0.67188-5.78223c-0.4541-0.69531-3.36621,1.38184-5.16602,0.69043
        c-1.79688-0.69043-2.46777-3.69824-4.03906-3.69824c-1.57617,0-1.79688-1.84863-1.79688-3.94043
        c0-2.0791-2.91992-4.16113-4.7207-5.55273c-1.79004-1.38281-2.23828-5.3125-3.36523-6.00977
        c-0.64063-0.41309-0.38867-3.25195-0.06738-5.60352c0.00439,0,0.00879,0,0.01318,0
        c0.00049-0.00293,0.00098-0.00586,0.00146-0.00781c0-0.00391,0.00049-0.00684,0.00098-0.00977
        c-0.03174,0.00293-0.06348-0.00098-0.0957,0.00195c-2.08936,0.16211-4.18555-0.20703-6.08643-1.07813
        c-0.03467-0.01563-0.07129-0.02441-0.10596-0.04102c-1.49609-1.53613-3.59473,0.61621-7.4834,3.39648
        c-3.89063,2.77637-8.68652,1.85645-8.68652,3.70215c0,1.85156-1.78906,2.77441-3.88086,2.15234
        c-2.09863-0.61523-3.89844-0.92188-4.79688,2.47363c-0.89746,3.40527-4.18457,1.23242-4.49219,4.93457
        c-0.29785,3.70117-3.58496,1.23633-6.28809-0.29883c-2.69238-1.54297-3.28711,7.7002-3.28711,7.7002
        c-1.44629,1.96777-2.55762,4.15625-3.29785,6.47852c-1.49609,4.33203-6.58496,3.0918-8.07617,3.70508
        c-1.49805,0.61719,1.49707,4.93848,0.60059,6.79199c-1.10742,2.62207-2.05469,5.2998-2.8291,8.03613
        c0.00439,0.00098,0.00879,0.00293,0.01318,0.00391c1.9917,0.4668,3.73193,1.66016,4.896,3.34961
        c0.00684,0.00977,0.01465,0.01855,0.02148,0.02832c0.89453,1.53906,6.27734,7.40234,8.97363,4.31348
        c3.32227-2.86133,8.02734-3.46289,11.96387-1.53418c0.00049,0,0.00049,0.00098,0.00098,0.00098
        c0.01465,0.01465,0.02881,0.02637,0.04346,0.04102c0.30127,0.30273,0.58545,0.52637,0.85107,0.68164
        c0.36035,0.20996,0.68555,0.29199,0.97461,0.27051c0.00586,0,0.01172-0.00098,0.01709-0.00098
        c0.54932-0.04883,0.96045-0.47852,1.20898-1.14063c0.00342-0.00977,0.00732-0.01953,0.01074-0.0293
        c0.03564-0.09766,0.06738-0.19824,0.0957-0.30469c0.00537-0.02051,0.01221-0.04102,0.01709-0.0625
        c0.0249-0.09766,0.0459-0.19922,0.06445-0.30371c0.00488-0.02637,0.01123-0.05176,0.01563-0.0791
        c0.00195-0.01074,0-0.02637,0.00146-0.03809c0.25928-1.89648-2.97607-8.59961-1.79492-10.45117
        c0.00146-0.00293,0.00195-0.00586,0.00342-0.00781c8.4248-1.48926,17.08105-1.28125,25.43262,0.61328
        c0.11279,0.19434,0.21143,0.29199,0.30371,0.31543c0.00049,0,0.00049,0,0.00049,0
        c0.04492,0.01074,0.08838,0.00293,0.13086-0.01855c0.00195-0.00098,0.00391-0.00195,0.00586-0.00293
        c0.56885-0.31152,1.02637-3.31152,3.45166-3.07031c0.02783,0.00684,0.05566,0.01172,0.0835,0.01953
        c1.62158,0.4209,3.26709,0.72559,4.9248,0.92871c1.41748,0.17383,2.84375,0.27832,4.2749,0.29297
        c1.80078-0.6123,3.88574-4.01563,5.68262-4.01563c1.79785,0,7.18457-0.30176,8.38477-1.8457
        c1.19922-1.55469,3.28516-1.54492,2.69043-4.00586l-0.00049-0.02441l-0.00244-0.0791
        c-0.00488-0.00488-0.01074-0.00781-0.01563-0.0127c-0.00293-0.00293-0.00635-0.00488-0.00977-0.00781
        C162.8418,603.4502,162.84277,603.46387,162.8457,603.47656z"/>
      <path class="megm-2" d="M273.55859,480.98535c-4.7168-2.03809-9.8125-3.0332-14.94434-2.93262l-43.41797,27.87988l-18.50586,15.40234
        c0,0-12.09766,4.39941-15.66699,5.13672l-1.30566,0.27539l-0.03809,0.00781l0,0c0,0.00195,0,0.00391,0,0.00488
        c0.4209,9.25195,0.2002,18.52539-0.65527,27.75684c-1.19629,0.92773-2.09961,6.48145-3.2998,7.70898
        c-1.2002,1.22363-2.69824,4.62695-6.28711,4.62695c-5.24854,0.81934-10.54395,1.33984-15.85254,1.54688
        c-0.00293,0.00098-0.00635,0.00098-0.00977,0.00098c-0.44434-0.07617-0.87891-0.10059-1.30273-0.08398
        c-3.00977,0.11816-5.46924,2.31445-6.78223,2.8584c-1.61133,0.33301-3.24902,0.50391-4.89014,0.52734
        c-0.55762,0.00879-1.11621,0-1.67383-0.02637c-0.00439,0-0.00879,0-0.01318,0c-0.32129,2.35156-0.57324,5.19043,0.06738,5.60352
        c1.12695,0.69727,1.5752,4.62695,3.36523,6.00977c1.80078,1.3916,4.7207,3.47363,4.7207,5.55273
        c0,2.0918,0.2207,3.94043,1.79688,3.94043c1.57129,0,2.24219,3.00781,4.03906,3.69824
        c1.7998,0.69141,4.71191-1.38574,5.16602-0.69043c0.44531,0.69043-0.22559,5.78223,0.67188,5.78223
        c1.53906,0.1709,2.97266,0.84863,4.10547,1.9043c-0.00293-0.0127-0.00391-0.02637-0.00684-0.03906
        c-0.15576-0.7334-0.15674-1.53516-0.00537-2.27734c0.00146-0.00586,0.00195-0.0127,0.00342-0.01953
        c0.02881-0.13965,0.06445-0.27539,0.10449-0.41016c0.00586-0.01953,0.01025-0.04004,0.01611-0.05957
        c0.03662-0.11816,0.08057-0.23145,0.12549-0.34375c0.01367-0.0332,0.02441-0.06836,0.03857-0.10156
        c0.03955-0.0918,0.08594-0.17871,0.13184-0.26563c0.02539-0.04883,0.04785-0.09961,0.07471-0.14648
        c0.03711-0.06348,0.08008-0.12109,0.12061-0.18164c0.04297-0.06348,0.08301-0.12988,0.12939-0.18848
        c0.02539-0.0332,0.05566-0.06152,0.08252-0.09277c0.06738-0.0791,0.13525-0.15918,0.20996-0.22949
        c0.00049,0,0.00146-0.00098,0.00195-0.00098c0.61328-0.57227,1.47119-0.81934,2.57373-0.47754
        c3.00195,0.92578,0.60059,3.7002,3.29395,4.94238c0.09766,0.04492,0.18262,0.06641,0.27734,0.10547
        c0.4873,0.20215,0.93408,0.33496,1.33545,0.39453c0.67725,0.10059,1.22559,0.00488,1.64209-0.25586
        c0.32227-0.20215,0.56445-0.50098,0.71973-0.88965c0.13721-0.34375,0.2207-0.74121,0.2207-1.20996
        c0-2.16113,3.59082-6.47363,4.49219-7.39941c0.84668-0.87988,0.62744-6.19336,2.13281-8.05273
        c0.08154-0.10156,0.16504-0.19922,0.25781-0.27832c1.80078-1.54688,7.18359-0.31445,9.27832,0
        c0.90039,0.13184,1.69043-0.65039,2.48828-1.41309c0.48145-0.45996,0.96582-0.91113,1.47949-1.15234
        c0.61523-0.28906,1.27148-0.27344,2.01465,0.39941c2.39453,2.16602,6.28809,0.31348,8.08301,2.46484
        c1.80176,2.16113,2.69336,7.41016,5.69434,5.55176c2.53516-1.51465,5.41211-2.3623,8.37207-2.47363
        c1.7959,0,5.99316,3.70215,7.78906,4.31348c2.82813,0.81934,5.80713,0.91992,8.67236,0.30176
        c0.40137-0.08691,0.80127-0.18652,1.19775-0.30176c1.57813-0.91895,3.49268-3.41895,5.48975-4.46777
        c0.65771-0.3457,1.32422-0.53418,1.99072-0.45605c4.29102-0.21973,8.59863-0.12207,12.87598,0.29785
        c1.68604,0.87012,5.23193,2.83398,7.88477,2.2998c0.16992-0.03516,0.33643-0.07813,0.49805-0.13379
        c2.53564-0.87109,4.79297-5.54492,6.80078-6.62695c0.12939-0.07031,0.2583-0.12598,0.38574-0.16406
        c0.03027-0.01074,0.0625-0.01563,0.09277-0.02539c0.13281-0.04199,0.26758-0.06934,0.40479-0.08496
        c0.31299-0.03613,0.6333-0.01074,0.94287,0.08594l0.00098-0.05469l0.05371-3.3125c0-5.55078,1.19531-3.38477,2.09375-8.94141
        c3.77148-7.20898,8.67773-13.75488,14.50732-19.4082c0.54639-0.53027,1.09033-1.0625,1.65283-1.57715
        c1.04297-8.59863,1.44727-17.26465,1.19238-25.91309c0.10742-3.76465,0.71387-7.50684,1.79395-11.10352
        c1.19629-4.32227-2.08691-4.01172-1.79395-8.0293c0.2959-4.00879-4.16504-7.0957-4.16504-9.5752
        c0-1.50684-0.33789-8.32227-0.59863-13.4043l-8.02734,4.49316C278.28516,484.97949,276.11328,482.73633,273.55859,480.98535z"/>
      <path class="megm-2" d="M340.66992,631.3291c3.0625-3.71289,6.35938-7.20996,9.87207-10.49316
        c1.49805-1.53516,0-2.77539,2.10547-4.94629c2.0957-2.17188,6.28711-4.62793,8.37402-3.38672l1.75342,0.40625
        c-0.00732-0.00879-0.01465-0.01855-0.02246-0.02734l-0.30615-0.36914l1.24609-4.50781c0,0-4.99316-2.5791-4.99316-6.43262
        c0-3.85547,1.25098-7.71777-1.87402-7.71777c-3.12695,0-3.74219-3.21875-1.25098-5.79199
        c2.5-2.5752-3.74609-7.72754,0.62988-7.72754c4.36719,0,1.25391-7.07324,4.36719-7.71582
        c3.11328-0.63867,3.74609-3.21777,3.74609-6.43066c0-1.00391,0.67188-1.88281,1.65283-2.6377
        c1.2207-0.93945,2.91846-1.68164,4.39893-2.23047c1.13184-0.41992,2.13672-0.72852,2.69531-0.91895
        c1.8623-0.6543,1.24121-10.93848,1.24121-10.93848l0.00879-28.58789l-77.26953-42.82617l-9.05371,5.0459
        c0.26074,5.08203,0.59863,11.89746,0.59863,13.4043c0,2.47949,4.48828,5.56641,4.18652,9.57031
        c-0.30078,4,2.99609,3.70215,1.79395,8.01563c-1.08301,3.61133-1.69043,7.34473-1.79395,11.10352
        c0.25195,8.66211-0.14746,17.3252-1.19531,25.91406c-0.00635,0.00586-0.01221,0.01172-0.01855,0.01758
        c-0.5625,0.51465-1.10645,1.04688-1.65283,1.57715c-5.82568,5.65527-10.7251,12.19238-14.48389,19.39453
        c-0.89844,5.55664-2.0957,3.38672-2.0957,8.95215l-0.05322,3.32324l-0.00049,0.0332
        c0.76367,0.27148,1.12402,1.14258,2.14648,2.19727c0.1543,0.15918,0.29004,0.33398,0.41748,0.51563
        c0.03564,0.05078,0.07178,0.10156,0.10596,0.15332c0.07373,0.1123,0.14404,0.22754,0.21143,0.34473
        c0.79199,1.37402,1.25732,2.99023,3.17188,3.25781c0.00391,0.00098,0.00684,0.00195,0.01025,0.00195
        c0.14941,0.02051,0.31006,0.03027,0.47803,0.03418c0.03027,0,0.05615,0.00488,0.0874,0.00488
        c0.56494,0,1.15039,0.25098,1.72656,0.67773c0.10059,0.07324,0.19922,0.16602,0.29883,0.25
        c0.08936,0.07617,0.17969,0.14258,0.26807,0.22559c2.26123,2.12598,4.22021,6.63672,3.99854,8.71875
        c-0.30176,2.7793,1.48926,4.32031,1.19141,9.26074c-0.05029,2.9209,0.50879,5.79785,1.61963,8.45703
        c0.66406,1.58984,1.52588,3.10254,2.57275,4.49902c0.19238,0.11816,0.32324,0.2373,0.40088,0.35254
        c0.52979,0.78906-1.46826,1.46094-4.59326,1.18652c-3.59375-0.31055-8.68066-0.30664-8.68066,1.24023
        c-0.65381,2.04395-0.27539,4.23047,0.92773,5.91504c0.40039,0.55957,0.89111,1.06445,1.46582,1.48926
        c0.05957,0.05664,0.11768,0.10742,0.17676,0.16309h56.33105C338.81787,632.96875,339.88965,632.35254,340.66992,631.3291z"/>
      <path class="megm-0" d="M464.88965,389.45996c-4.43945,0.82129-9.02734-0.18164-12.71387-2.77734
        c-7.23242-1.63184-14.81934-0.35742-21.11621,3.54785c-1.93848,2.93066-6.88086,2.93066-11.37402,1.08008
        c-5.85156-2.80371-12.11621-4.62793-18.55664-5.4043c-5.34229,0.17188-10.62549-1.12793-15.27539-3.76074
        c-0.00098-0.00049-0.00146-0.00049-0.00195-0.00098l-0.92676,2.59766c-2.50098,7.07813,1.875,9.01074-1.25,12.87109
        c-3.11621,3.86035,0,8.36621,0,15.43848v80.37891h0.01367h61.73535l3.27051-2.24902l0.33301-0.22949
        c1.78906-1.2373,4.78906,1.84668,4.78906,1.84668h13.16895c1.31348,1.70996,3.25,2.81543,5.3877,3.09082
        c0.37305,0,0.67188-0.07715,0.91895-0.21094c1.4707-0.82129,1.06836-3.70703,3.55762-4.51172
        c0.41602-0.13672,0.91309-0.21484,1.51367-0.21484c0.5957,0,1.09766-0.11523,1.52734-0.31641
        c2.80859-1.31934,2.49707-6.30469,4.53516-7.00293c0.15918-0.05566,0.33203-0.08691,0.52246-0.08691
        c1.49121,0,2.08887,0.15332,2.6875-0.22949c0.59668-0.3877,1.19629-1.31348,2.68555-3.4668l0.33301-0.4375
        c-0.22852-1.38184-0.23828-2.7959-0.02637-4.18848c0.74609-4.93457-2.5459-4.62793-4.6416-11.57129
        c-2.08594-6.94043-10.47852-17.27051-10.77148-21.28809c-0.2959-4.0166-4.94238-6.32129-4.79297-10.64746
        c0.1416-4.31934-2.25-4.78027-6.88672-11.56445c-3.78125-5.61719-5.42871-12.40137-4.6416-19.12988
        c1.34473-0.46387,5.24121,10.0293,6.7373,13.41992c1.48828,3.39453,6.58984,13.7334,10.18359,13.7334
        c3.58301,0,4.9375-15.58398,6.13281-14.81055l0.25195,0.45605l0.3916-2.82715l1.47949-4.19629l-7.26367-18.375l-1.1377,1.64941
        C474.47266,391.31055,469.53418,388.21973,464.88965,389.45996z"/>
      <path class="megm-2" d="M478.07422,617.26563c-0.58984-0.60645-0.62402-1.08594-0.31641-1.51855
        c0.64551-0.95898,2.98242-1.67676,4.71582-3.02246c0.15527-0.12012,0.29297-0.25586,0.41699-0.4043
        c0.0625-0.07617,0.1123-0.16309,0.16797-0.24609c0.05078-0.0752,0.10742-0.14453,0.15234-0.22461
        c1.25977-2.27344,0.33984-6.51953,1.9668-8.91406c0.12695-0.19141,0.26953-0.37207,0.43066-0.53809
        c2.5-2.56836,4.37598-7.73145,6.26465-7.73145c1.23242-0.25195,2.29883-0.90918,3.07227-1.81543
        c0.76758-0.91016,1.24023-2.06836,1.28516-3.3252c0-2.57813,4.38574-7.73828,3.76074-12.25488l-0.00488-0.08203
        c0.00586-0.00098,0.01172-0.00195,0.01758-0.00293c0-0.00391-0.00098-0.00781-0.00195-0.01172
        c-0.00977,0.00195-0.01953,0.00293-0.0293,0.00488c-0.63281-4.50586-2.46777-10.26563,0.64355-14.11133
        c2.76074-2.6748,4.14648-6.48535,3.75098-10.31738c-0.62988-3.21875,3.75098-3.86816,5.63672-5.80566
        c3.82617-2.41309,7.10938-5.56836,9.67188-9.2832c0.00391,0.00391,0.00684,0.00781,0.00977,0.01172
        c0.00293-0.00391,0.00586-0.00781,0.00879-0.01172c-1.69922-2.37891-3.83691-4.40918-6.28809-5.99023
        c-3.5918-1.69141-5.99414-8.17578-5.99414-16.35352c-0.20801-5.80957-0.9668-11.59277-2.24414-17.26953
        c-1.51563-4.56836-4.65527-8.41406-8.8291-10.80566c-2.99805-1.58496-5.09473-4.48633-5.64844-7.83594l-0.33691,0.42969
        c-1.5127,2.16895-2.11621,3.09473-2.71777,3.48047c-0.59863,0.38281-1.19629,0.22949-2.6875,0.22949
        c-0.19043,0-0.36328,0.03125-0.52246,0.08691c-2.0332,0.71582-1.73047,5.69238-4.53516,7.00293
        c-0.42969,0.20117-0.93164,0.31641-1.52734,0.31641c-0.60059,0-1.09766,0.07813-1.51367,0.21484
        c-2.4834,0.8125-2.07422,3.70313-3.55762,4.51172c-0.24707,0.13379-0.5459,0.21094-0.91895,0.21094
        c-2.1377-0.27539-4.07422-1.38086-5.3877-3.09082h-13.16895c0,0-3-3.08398-4.78906-1.84668l-0.33301,0.22949l-3.2627,2.24902
        h-0.00781h-61.73535v21.91504v0.00879h-9.37402v5.51367v0.00684v28.58789c0,0,0.62598,10.29297-1.24609,10.93848
        c-0.55908,0.19141-1.56592,0.49902-2.69922,0.91895c-1.48047,0.54883-3.17822,1.29102-4.39893,2.23047
        c-0.97803,0.75391-1.64795,1.63281-1.64795,2.6377c0,3.22168-0.61621,5.79199-3.74609,6.43066
        c-3.11816,0.63379,0,7.71582-4.36328,7.71582c-4.37109,0,1.875,5.15234-0.62988,7.72754
        c-2.49707,2.57324-1.87109,5.79199,1.24707,5.79199c3.12988,0,1.87598,3.85254,1.87598,7.71777c0,3.8623,5,6.43262,5,6.43262
        l-1.25586,4.50781c0.10303,0.12109,0.19971,0.24707,0.30225,0.36914c0.00781,0.00879,0.01514,0.01855,0.02246,0.02734
        c0.57422,0.68262,1.14404,1.36914,1.69629,2.06934c1.80811,2.29395,3.48779,4.68652,5.04785,7.1582
        c0.14063,0.22266,0.28955,0.43945,0.42822,0.66406c0.42725,1.32813,2.33203,4.1748,2.26172,6.4541
        c-0.03174,1.03516-0.4707,1.9541-1.64063,2.55957c-1.01611,0.52344-1.5625,1.0459-1.83301,1.53125h105.87598
        c0.25488-2.12598,0.375-4.26758,0.32813-6.41113C474.93945,623.07129,479.95996,619.19336,478.07422,617.26563z"/>
      <path class="megm-2" d="M558.49414,597.36914c-1.48047,2.53125-3.12598,4.96191-4.92285,7.27734
        c-0.75781,1.35059-1.16504,2.84277-1.21973,4.34668c-0.05273,1.50293,0.24707,3.01758,0.90234,4.41797
        c0.62988,1.2998,3.78711,0.97168,5.67969-0.32813c1.46289-1.01953,2.57227-0.43359,4.75391,1.68945
        c0.00586,0.00586,0.01074,0.00977,0.0166,0.01563c1.26074-1.83203,2.66016-3.54785,4.18848-5.15527
        c-2.50293-1.49805-4.81055-1.75-5.42383-2.36914c-1.04688-1.08008,2.99121-4.16992,5.83887-4.79297
        c2.83691-0.60645,1.49121-3.99512-0.89844-6.16406l-1.34668-1.25781l-2.73242,1.96484
        C562.58008,598.03711,560.9209,597.65234,558.49414,597.36914z"/>
      <path class="megm-2" d="M510.00391,546.94434c-1.88574,1.9375-6.2666,2.58691-5.63672,5.80566
        c0.39551,3.83203-0.99023,7.64258-3.75098,10.31738c-3.11133,3.8457-1.27637,9.60547-0.64355,14.11133
        c0.00977-0.00195,0.01953-0.00293,0.0293-0.00488c2.81152-0.50391,5.7002-0.34277,8.43945,0.46582
        c0.00293,0.00293,0.00586,0.00488,0.00977,0.00879l0,0c0.01758,0.01758,0.0332,0.03027,0.05078,0.04785
        c1.53711,1.51074,2.16504-0.09961,3.09668-3.31055c0.94922-3.24121,2.84863-2.92578,4.73633-1.2998
        c1.89746,1.62305,3.15137,4.22852,6.30957,2.60449c3.1543-1.63086,2.83691-0.65039,4.42188,0
        c1.57422,0.64941,7.88477-0.33105,10.0918,0c7.61426,6.6748,14.78711,13.84277,21.45898,21.45898l-0.00195,0.00293
        c0.00195,0.00195,0.00391,0.00391,0.00586,0.00586c-0.04102,0.07129-0.08594,0.13965-0.12695,0.21094
        c2.42676,0.2832,4.08594,0.66797,4.83594-0.35547l2.73242-1.96484c-3.87891-3.89453-7.4873-8.06445-10.78027-12.4668
        c-3.66504-5.7207-9.39941-9.8125-16.00977-11.41602c-3.58984,0-4.19336-6.32715-6.43262-6.63574
        c-2.23926-0.30957-7.33594-9.40918-7.4834-15.27051c-0.77734-4.3125-2.73438-8.32422-5.6709-11.58691
        c-0.00293-0.00391-0.00586-0.00781-0.00977-0.01172C517.11328,541.37598,513.83008,544.53125,510.00391,546.94434z"/>
      <path class="megm-2" d="M568.4541,628.16309c-0.66504-0.68652-1.29688-1.40332-1.89355-2.14746
        c-0.16113-0.20117-0.31445-0.41016-0.4707-0.61523c-0.13184-0.17285-0.26855-0.34277-0.39648-0.51855
        c-0.1543-0.21094-0.29883-0.42871-0.44727-0.64355c-0.12305-0.17676-0.25-0.35156-0.36914-0.53125
        c-0.10059-0.15137-0.19238-0.30762-0.29004-0.46094c-0.15918-0.24902-0.32031-0.49707-0.47266-0.75098
        c-0.03613-0.06152-0.06836-0.12402-0.10449-0.18555c-0.6875-1.16797-1.30273-2.37988-1.83594-3.63184
        c-0.00098-0.00195-0.00098-0.00391-0.00098-0.00586c-0.30957-0.72461,0.41016-2.19629,1.53711-3.87402
        c-0.00879-0.00879-0.01563-0.01465-0.02441-0.02246c-2.18164-2.11719-3.28711-2.69238-4.75195-1.68164
        c-1.89258,1.29297-5.0498,1.62598-5.67969,0.32617c-0.65723-1.40137-0.95703-2.91992-0.90234-4.42676
        c0.05469-1.50391,0.46191-2.99609,1.21973-4.34668c1.79688-2.31543,3.44238-4.74609,4.92285-7.27734
        c0.04102-0.07129,0.08594-0.13965,0.12695-0.21094c-0.00195-0.00195-0.00391-0.00391-0.00586-0.00586
        c-6.67383-7.61914-13.83984-14.78027-21.45313-21.45801c-2.21094-0.32617-8.52148,0.65918-10.0957,0
        c-1.58008-0.64648-1.26367-1.62109-4.42188,0c-3.1582,1.62988-4.4209-0.97168-6.30664-2.59766
        c-1.88672-1.63184-3.79004-1.95215-4.72949,1.29883c-0.93848,3.22168-1.57129,4.82813-3.10645,3.30078
        c-0.01758-0.01758-0.0332-0.03027-0.05078-0.04785l0,0c-2.75293-0.80957-5.64648-0.96582-8.44727-0.46289
        c-0.00586,0.00098-0.01172,0.00195-0.01758,0.00293l0.00488,0.08203c0.625,4.5166-3.76074,9.67676-3.76074,12.25488
        c-0.04492,1.25684-0.51758,2.41504-1.28516,3.3252c-0.77441,0.91699-1.84766,1.58203-3.08887,1.83789
        c-1.87598,0-3.75293,5.16211-6.27051,7.74121c-0.15234,0.15723-0.28711,0.32617-0.4082,0.50586
        c-1.62695,2.39453-0.70703,6.64063-1.9668,8.91406c-0.04492,0.08008-0.10254,0.14941-0.15234,0.22461
        c-0.05566,0.08301-0.10547,0.16992-0.16797,0.24609c-0.12891,0.15723-0.27246,0.30078-0.43555,0.42676
        c-1.71875,1.33203-4.02539,2.05371-4.69727,3c-0.30762,0.43262-0.27344,0.91211,0.31641,1.51855
        c1.88574,1.92773-3.13477,5.80566-3.13477,9.66895c0.04688,2.14355-0.07324,4.28516-0.32813,6.41113h98.90039
        C571.76074,631.73438,569.79492,628.85352,568.4541,628.16309z"/>
      <path class="megm-0" d="M488.46484,365.875l2.71289,1.18311c2.13281-3.95654,4.73535-7.64209,7.75781-10.97021
        c1.90039-1.73047,3.03125-8.4834-4.9043-8.11914l-0.10156,1.37109c-1.9668,5.45801-4.48047,10.69727-7.52539,15.63672
        l0.01172,0.00488c0.00098-0.00146,0.00098-0.00342,0.00195-0.00488L488.46484,365.875z"/>
      <path class="megm-2" d="M498.93555,356.08789c-3.02246,3.32813-5.625,7.01367-7.75781,10.97021
        c-0.00098,0.00098-0.00098,0.00244-0.00195,0.00342l0.34668,0.15332c0.00098-0.00244,0.00098-0.00439,0.00098-0.00684
        l0.18359,0.07813l0.7959,3.70898l9.19336,4.9502c2.92676-2.23145,6.16504-4.03125,9.59766-5.35254
        c1.8252-0.74561,3.62402-1.55273,5.40723-2.39551c1.9834-0.99072,4.35254-2.2168,6.87402-3.54492
        c0.2168-0.12158,0.43652-0.23535,0.65234-0.35791c0-0.00049,0-0.00098,0-0.00146
        c9.92578-5.22754,21.82813-11.82324,21.82813-11.82324s2.79688-4.53027,2.39648-6.18066
        c-0.71387-2.75293-0.43848-5.66992,0.80469-8.23633c1.5957-2.05762-2.00879-5.3457-0.80469-8.65332
        c1.2002-3.30566,6.38672-4.94531,6.38672-4.94531l6.00293-4.53516l-0.20215-0.05957c0,0,0-0.00049,0.00098-0.00049
        l-3.78418-1.17139c-3.58008,1.29395-7.38867,1.85059-11.18848,1.64746c-5.98535-0.41016-7.1875,4.12207-13.58301,4.12207
        c-5.60645,0-9.06152,1.25684-10.90723,0.19336c-0.26465-0.15137-0.49609-0.3501-0.69629-0.60742
        c-0.39941-0.51416-0.72461-0.82324-1.05566-0.96533c-0.99219-0.41895-2.04492,0.66357-5.33594,2.20166
        c-3.29492,1.54639-5.69336,1.70166-7.53516,0.98193c-0.02832-0.01123-0.05566-0.02539-0.08398-0.03662
        c-0.12207-0.0498-0.24316-0.10107-0.36035-0.15869c-0.06934-0.03418-0.13574-0.07373-0.20313-0.11035
        c-0.07422-0.04004-0.14941-0.07715-0.22168-0.12012c-0.06152-0.03711-0.12012-0.07861-0.17969-0.11719
        c-0.07617-0.04932-0.15332-0.09619-0.22656-0.14795c-0.01953-0.01367-0.03711-0.0293-0.05664-0.04297
        c-0.10938-0.08008-0.21875-0.16113-0.32422-0.24805c-1.99707-1.64941-3.59375,0.41309-3.99805,2.47559
        c-0.36523,1.92969,0.22363,6.36328-5.99219,7.23926c0.00098-0.00195,0.00098-0.00391,0.00195-0.00586
        c-0.00195,0-0.00391,0.00098-0.00586,0.00098c-0.42578,1.90039-1.15527,3.875-0.08984,5.25195
        c1.58887,2.04102-0.31445,4.44141-0.7832,7.72754C501.9668,347.60449,500.83594,354.35742,498.93555,356.08789z"/>
      <path class="megm-0" d="M488.25684,374.13721c-0.21191,0.02539-0.42383,0.05371-0.63379,0.09326
        c-1.53711,0.62598-2.14844,6.60059-2.14844,7.54199c0.55859,2.43311,1.7998,4.65576,3.55566,6.42188
        c0.19531-1.09424,0.38867-2.18848,0.54102-3.28906c0.30371-3.72607,0.45898-7.46875,0.75879-8.71973
        c0-0.00439,0.00098-0.0083,0.00098-0.0127c0.00195-0.00781,0.00391-0.01904,0.00586-0.02734l0.33594-1.99512
        C489.86914,374.04395,489.05859,374.04199,488.25684,374.13721z"/>
      <path class="megm-0" d="M487.9375,393.45508c0.42676-1.7417,0.77734-3.49805,1.09277-5.26074
        c-1.75586-1.76611-2.99707-3.98877-3.55566-6.42188c0-0.94141,0.61133-6.91602,2.14844-7.54199
        c0.20996-0.03955,0.42188-0.06787,0.63379-0.09326c0.80469-0.09619,1.61914-0.09326,2.4248,0.01318l0.83203-6.8501
        c0.00293-0.02734,0.00586-0.05566,0.00879-0.08301l-3.05762-1.34229l-2.04688-0.89844
        c-0.00098,0.00146-0.00098,0.00342-0.00195,0.00488c-0.74609,1.54932-1.28809,3.18945-1.61719,4.88281
        c-1.69043,6.55664-4.38867,12.81738-7.99219,18.55859l7.27637,18.375l-0.43457,1.20898l1.0459,0.58398
        c0-0.00195,0-0.00391,0.00098-0.00586c-0.00098,0-0.00098,0-0.00098,0
        C485.13672,403.42676,486.22949,398.34277,487.9375,393.45508z"/>
      <path class="megm-0" d="M488.74902,410.75781c0.70215,0.31641,1.41406,0.61328,2.13477,0.88965
        c0.07422,0.02832,0.14746,0.06152,0.22168,0.08984c0.51563,0.19336,1.04297,0.35645,1.56641,0.5293
        c0.34473,0.11035,0.68359,0.23633,1.03125,0.33594c3.74121-0.04688,6.99316-2.56348,7.98828-6.16895
        c0-0.30957,0.05078-0.58594,0.14453-0.83398c0.64648-1.74609,3.4502-2.06348,6.25488-2.06348
        c3.20313,0,3.59863-3.70996,5.99414-4.94629c0.37207-0.19238,0.50293-0.52441,0.44922-0.9502
        c-0.30273-2.32178-6.01855-7.4043-8.04004-8.09668c-0.60352-0.20801-0.77441-0.70361-0.56836-1.33447
        c0.00391-0.01416,0.01465-0.03027,0.01953-0.04492c0.0498-0.14111,0.11133-0.28662,0.19727-0.43945
        c1.05273-1.87744,4.84863-4.50244,10.3457-4.79248c7.99219-0.41797,6.00293-3.2959,10.38867-3.2959h0.24121
        c0.09766-0.00684,0.19434-0.01025,0.29199-0.0166c-0.77344-3.59766-1.73242-7.9375-2.3623-10.21484
        c-0.41016-1.67822-0.69043-3.38672-0.82031-5.10986c-0.21582,0.12256-0.43555,0.23633-0.65234,0.35791
        c-2.25098,1.26416-4.54492,2.44385-6.87402,3.54492c-1.7832,0.84277-3.58203,1.6499-5.40723,2.39551
        c-3.43262,1.32129-6.6709,3.12109-9.59766,5.35254l-9.19336-4.9502l-0.7959-3.70898l-0.18359-0.07813
        c0,0.00244,0,0.00439-0.00098,0.00684c0,0.00098,0,0.00195,0,0.00244c-0.00293,0.02734-0.00586,0.05566-0.00879,0.08301
        c-0.37891,3.51221-0.86035,7.52295-1.17676,8.84521c-0.00195,0.0083-0.00391,0.01953-0.00586,0.02734
        c0,0.00439-0.00098,0.0083-0.00098,0.0127c-0.10449,2.9209-0.35742,5.83057-0.75879,8.71973
        c-0.15234,1.10059-0.3457,2.19482-0.54102,3.28906c-0.31543,1.7627-0.66602,3.51904-1.09277,5.26074
        c-1.70801,4.8877-2.80078,9.97168-3.24316,15.12988c0,0,0,0,0.00098,0c0.68652,0.43066,1.38867,0.83496,2.10254,1.21582
        c0.09766,0.05078,0.19629,0.09766,0.29395,0.14746C487.63672,410.23242,488.18945,410.50195,488.74902,410.75781z"/>
      <path class="megm-2" d="M562.76563,264.50195c0.61719-0.28906,1.16113-0.39404,1.65527-0.34863
        c2.14258,0.19434,3.34375,3.2041,5.53125,6.21289c0.1748,0.24121,0.33008,0.46777,0.46875,0.68457
        c0.91406-0.14551,1.77441-0.32227,2.60742-0.51318c2.52344-0.57861,4.80469-1.28467,7.61328-1.62646
        c3.02148-0.17725,6.04883,0.14209,8.96777,0.94336c-0.24414-0.43848-0.42578-0.84912-0.52441-1.22656
        c-0.00488-0.0166-0.00879-0.0332-0.0127-0.0498c-0.03418-0.14063-0.05273-0.2749-0.05762-0.4043
        c-0.00293-0.05566-0.00781-0.1123-0.00488-0.16553c0.00586-0.09619,0.03223-0.18555,0.05957-0.27393
        c0.01465-0.04834,0.01172-0.104,0.0332-0.14941c0.22559-0.46338,0.67676-0.65576,1.24902-0.65967
        c1.71387-0.02051,4.52637,1.65625,5.65039,2.81494c2.24023,1.72559,5.29297,1.9668,7.77637,0.62305
        c0.37207-0.38721,0.4082-0.77441,0.24023-1.16064c-0.50781-1.15674-2.8584-2.30908-3.53613-3.46436
        c-0.24609-0.42236,0.00684-1.09473,0.49121-1.84717c0.39844-0.62354,0.95313-1.30127,1.54102-1.94678
        c-2.59863-1.48682-5.28613-3.62598-6.22461-3.2959c-0.34473,0.11816-0.60059-0.25-0.82129-0.85889
        c-0.43457-1.15674-0.72852-3.2583-1.26563-4.55371c-0.27246-0.63818-0.60547-1.06885-1.05078-1.06885
        c-1.80078,0-1.80078-1.38477-3.59375-1.38477c-3.50977-0.22656-7.02246,0.24219-10.32617,1.38477
        c-1.7959,1.39063-4.04883-3.2373-6.28906-3.2373c-2.57227-0.4834-4.84375-1.98438-6.29102-4.16406
        c-1.3457-1.85449-12.56152,0.92188-15.70801-1.3877c-3.56055-2.47754-7.79199-3.77539-12.12402-3.70508
        c-1.35156,0.40039-2.63574,1.01855-3.78906,1.82422c5.89551,3.53125,14.45898,2.6123,15.61426,8.97852
        c1.35449,7.40527,5.39355,4.4707,3.89551,10.79785c-0.42285,1.3877-1.23242,2.625-2.34277,3.55908
        C555.71289,265.33691,559.29102,265.2251,562.76563,264.50195z"/>
      <path class="megm-0" d="M413.01172,259.73828c-0.71094,0.1377-1.45996,0.08447-2.16895-0.17383
        c-2.10254-0.58643-4.28613-0.80664-6.45313-0.66064c-1.29785,0.08789-2.58887,0.30713-3.85352,0.65771
        c-0.58398,0.24072-0.31982,1.41992,0.06738,2.85205c0.00195,0,0.00293-0.00049,0.00391-0.00049
        c0.22266,0.68262,0.40234,1.37891,0.54102,2.0835c0.16113,0.7041,0.27832,1.396,0.27832,2.00732
        c0,2.5459-2.91016,3.23828-2.91016,4.85938c0,1.25098-1.07129,2.10352-2.37109,3.58008
        c1.5957,1.58301,3.65625,2.62402,5.88379,2.98047c4.19434,0,5.83105-4.62793,9.42676-6.01563
        c3.59668-1.38965,11.6748,0.46191,13.62598-1.38965c1.94727-1.85156-5.09961-4.16406-7.63574-6.32715
        c-1.32324-1.53809-2.18652-3.41211-2.49707-5.41797c-0.41895,0.37891-0.9043,0.65674-1.42188,0.82959
        c-0.02637,0.00879-0.05371,0.0127-0.08008,0.021C413.30371,259.66895,413.15918,259.70996,413.01172,259.73828z"/>
      <path class="megm-0" d="M500.90039,327.75488c0.4043-2.0625,2.00098-4.125,3.99805-2.47559
        c0.10547,0.08691,0.21484,0.16797,0.32422,0.24805c0.01953,0.01367,0.03711,0.0293,0.05664,0.04297
        c0.07324,0.05176,0.15039,0.09863,0.22656,0.14795c0.06055,0.03857,0.11816,0.08008,0.17969,0.11719
        c0.07227,0.04297,0.14746,0.08008,0.22168,0.12012c0.06738,0.03662,0.13379,0.07617,0.20313,0.11035
        c0.11719,0.05762,0.23828,0.10889,0.36035,0.15869c0.02832,0.01172,0.05566,0.02539,0.08398,0.03662
        c1.8418,0.71973,4.24023,0.56445,7.53516-0.98193c3.29102-1.53809,4.34375-2.62061,5.33594-2.20166
        c0.33301,0.14014,0.6582,0.44922,1.05957,0.96729c0.19922,0.25586,0.42969,0.4541,0.69238,0.60547
        c1.8457,1.06348,5.30078-0.19336,10.90723-0.19336c6.39551,0,7.59766-4.53223,13.58301-4.12207
        c3.7998,0.20313,7.6084-0.35352,11.18848-1.64746l3.78418,1.17139c1.56348-1.47461,1.44531-3.13135,4.51367-3.52783
        c4.79297-0.62109,6.58984,3.08594,8.98145,1.85059c0.4502-0.23096,1.08398-0.12158,1.82031,0.17139
        c1.95703,0.77295,4.65332,2.86084,6.57031,3.32715c1.19336,0.28516,2.08105-0.06396,2.29492-1.76123
        c-0.02344,0.0708-0.02832,0.15332-0.02441,0.24219c0.00488-0.02295,0.00879-0.04541,0.0127-0.06934
        c0.00293-0.05762,0-0.11816,0.01563-0.16699c-0.61621-3.20898-2.31152-6.11328-4.78418-8.24609
        c-0.2998-0.07568-0.45898-0.45654-0.52441-1.03564c-0.28223-2.42529,1.06543-8.32471,0.57227-9.9209
        c-0.0166-0.05273-0.02734-0.12061-0.04785-0.16357c-0.15137-0.30957-0.34375-0.80762-0.54199-1.4126
        c-0.68652-2.09424-1.42578-5.45801-0.70117-6.72559c0.18164-0.31885,0.44531-0.51904,0.83496-0.51904
        c0.14844,0,0.27441-0.02441,0.38379-0.06836c0.00684-0.00293,0.01172-0.0083,0.01855-0.01172
        c0.10059-0.04443,0.18848-0.10449,0.25977-0.18359c0-0.00098,0.00098-0.00146,0.00098-0.00195
        c0.94629-1.05762-0.5332-4.90771,1.96582-5.14209c-0.36035-1.36816-0.98242-2.65625-1.83496-3.78809
        c-1.12402-0.9165-3.92383,0.23242-6.01172-1.08203c-0.7002-0.43848-1.32129-1.15234-1.77246-2.31299
        c-0.50293-1.29297-0.7207-2.26758-0.83789-3.08887c-0.01172-0.07861-0.02344-0.15576-0.0332-0.23145
        c-0.00488-0.03711-0.00977-0.07422-0.01367-0.11035c-0.21387-1.72021-0.05762-2.79199-1.36328-4.82422
        c0.00879-0.00146,0.01758-0.00342,0.02637-0.00488c-0.13867-0.2168-0.29395-0.44336-0.46875-0.68457
        c-2.18652-3.00781-3.39063-6.01709-5.53125-6.21289c-0.49414-0.04541-1.03809,0.05957-1.65527,0.34863
        c-3.47461,0.72314-7.05273,0.83496-10.56738,0.33057c-0.00098,0-0.00293-0.00049-0.00488-0.00049
        c-5.20703,4.45801-11.91895,6.77051-18.76367,6.46387c-5.88477-0.59766-11.81543-0.44043-17.66016,0.46582
        c-5.83496,0-7.49023-4.32422-11.97754-4.0166c-4.49316,0.31445-4.49316-4.16406-8.97754-4.16406
        c-4.49316,0-1.04199-2.30762-15.10645-4.16406c-11.12695-1.06055-22.20898,2.44922-30.68555,9.7168
        c-4.49316,4.0127-20.66211,0.15527-22.15137,1.23633c-1.49219,1.07715,0.29785,5.85938-5.09473,8.01953
        c-5.38281,2.16309-17.21094,0-21.25,1.84961c-4.03906,1.85059-5.53711,8.02051-2.0957,7.71875
        c3.43945-0.3125,5.23926,1.3877,4.19043,2.92578c-1.03906,1.54199,2.84277,5.86621,0.9043,7.7168
        c-1.92969,1.86328-1.97852,4.93457-0.11719,6.86133c0.13574,0.13672,0.27148,0.26172,0.41504,0.38379
        c2.39746,1.85059,2.8418,5.25195,0,5.71289c-2.84375,0.46191,4.94629,3.85449,5.08496,5.39648
        c0.13574,1.53418,6.59473,2.30469,6.59473,4.16406c0,1.85156,1.95215,2.47168,5.08691,1.54492
        c3.14355-0.92383,4.34375,3.54492,6.28711,6.63086c1.93945,3.08789,13.02344,0.9248,13.02344-2.46582
        c0-3.39453,3.29199-4.32324,8.23633-3.85938c4.93359,0.45703,11.37695,7.4043,13.77051,8.33008
        c2.3916,0.92578,7.33105-1.85156,10.46973-1.85156c3.14844,0,7.63184-5.09082,9.43652-6.1709
        c1.79395-1.07617,4.9375,3.55176,7.78516,2.00195c2.8418-1.54199,5.97656-3.23438,7.7793-2.15234
        c1.79395,1.08594-4.34863,5.85742-2.69336,8.33105c0.55078,1.09375,0.6377,2.3667,0.22559,3.53027
        c-0.00098,0.00195-0.00098,0.00391-0.00195,0.00586C501.12402,334.11816,500.53516,329.68457,500.90039,327.75488z"/>
      <path class="megm-2" d="M320.76953,249.93848c0.65527-2.8501,1.98242-5.50293,3.86328-7.73779
        c-0.00146-0.00195-0.00342-0.00391-0.00488-0.00537c1.53418-2.14258,3.08887-3.87012,3.76855-3.87012
        c0.00439,0,0.00879-0.00293,0.01318-0.00342l0.00049-0.00049c0.06934,0,0.13574-0.03906,0.20068-0.08691
        c0.02197-0.01709,0.04395-0.02979,0.06543-0.05176c0.05225-0.05176,0.10205-0.12402,0.15137-0.20508
        c0.03564-0.06055,0.0708-0.12891,0.10449-0.2041c0.0127-0.02686,0.0249-0.05811,0.03711-0.0874
        c0.90283-2.15674,1.18408-9.28564,0.70996-13.29395c-2.78662-2.04443-5.85986-3.64697-9.13525-4.76025
        c-5.23926-0.19043-10.46973-0.72754-15.64258-1.61523c-2.23145-0.91309-4.46582,3.00195-6.48145,1.3916
        c-1.12354-0.79395-2.63086-0.65771-3.59961,0.25391c-0.00195,0.00195-0.00342,0.00439-0.00586,0.00635
        c-0.13135,0.12451-0.25195,0.26416-0.36084,0.41699c-0.03174,0.04443-0.05518,0.09424-0.08447,0.14063
        c-0.06787,0.10791-0.13574,0.21631-0.18799,0.33105c-0.02197,0.04834-0.03516,0.10059-0.05469,0.15039
        c-0.04736,0.12109-0.09424,0.24268-0.125,0.36914c-0.00928,0.03857-0.01025,0.07959-0.01807,0.11865
        c-0.02734,0.13965-0.05225,0.28027-0.05908,0.42334c-0.00781,0.1792-0.00195,0.36133,0.0249,0.54541
        c0.45215,2.29883,4.69727,3.69141,4.69727,7.1416c0,3.44922,8.49902,8.52246,8.27051,10.36719
        c-0.15332,1.28418,5.08984,4.79883,8.22559,9.54102c1.80762,1.21875,3.54395,2.54395,5.20801,3.95801l0.00049-0.00439
        l-0.00049-0.00049L320.76953,249.93848z"/>
      <path class="megm-2" d="M306.91699,239.67285c0.22852-1.84473-8.27051-6.91797-8.27051-10.36719
        c0-3.4502-4.24512-4.84277-4.69727-7.1416c-0.02686-0.18408-0.03271-0.36621-0.0249-0.54541
        c0.00635-0.14453,0.03174-0.28418,0.05908-0.42334c0.00781-0.03906,0.00879-0.08008,0.01807-0.11865
        c0.03076-0.12744,0.07764-0.24854,0.125-0.36914c0.01953-0.0498,0.03271-0.10205,0.05469-0.15039
        c0.05273-0.11621,0.12012-0.22314,0.18799-0.33105c0.0293-0.04639,0.05273-0.09619,0.08447-0.14063
        c0.10693-0.1499,0.22705-0.28955,0.36084-0.41699c0.00244-0.00195,0.00391-0.00439,0.00586-0.00635
        c0.40869-0.38623,0.93262-0.65869,1.53125-0.75c0.72461-0.10645,1.4707,0.06543,2.06836,0.49316
        c2.01563,1.6123,4.25-2.31152,6.48145-1.38965c5.17285,0.88477,10.40332,1.42188,15.64258,1.6123
        c3.2749,1.1123,6.34814,2.71777,9.13477,4.75879c-0.00293-0.02588-0.00586-0.05225-0.00879-0.07764l-0.44922-2.11572
        c-0.00488-0.01172-0.00879-0.02637-0.01367-0.03809c-1.21484-2.90625,0-6.21484-3.22168-7.45898
        c-1.64941-0.64258-2.35254-3.5957-2.63867-6.3125l0.00488-0.00146c0-0.00195-0.00049-0.00439-0.00098-0.00635
        c-1.38623,0.43652-2.57324,0.79785-3.29883,0.99365l-0.18652,0.05615c-1.37012,0.35547-2.77148,0.4502-4.13818,0.30029
        c-2.27734-0.24805-4.46094-1.17725-6.2417-2.71729c-2.06006-2.09961-4.36328-3.93262-6.84424-5.50146
        c-0.51953-0.32715-1.03613-0.65869-1.5708-0.96045c-0.82178,0.81836-1.71387,1.55762-2.65674,2.22461
        c-1.23828,0.95508-2.58154,1.84961-3.38818,1.95508c-1.80371,0.22852-0.6748,5.08691-2.47266,5.08691
        c-1.79395,0-3.14453,3.24121-3.59863,4.62793c-0.11035,0.34814-0.44629,0.46436-0.9165,0.45361
        c-1.17432-0.02588-3.18359-0.84473-4.60254-0.81592c-0.28369,0.00537-0.54395,0.04492-0.76904,0.13184
        c-2.33789,0.6377-4.72168,1.11328-7.12793,1.41895v-0.00049c-0.00146,0-0.00244,0.00049-0.00391,0.00049
        c0.31836,2.10742,0.18848,4.44922,1.8252,4.44922c2.69434,0,7.63672-0.30762,7.78125,3.39063
        c1.93359,5.45996,4.99219,10.45215,8.98145,14.65918c2.39355,2.77344,7.78516,2.16113,8.07813,4.47656
        c0.28223,2.31445,4.19434,4.46484,8.68652,4.78125c1.54395,0.2666,2.99609,0.8916,4.25586,1.82715
        C312.00684,244.47168,306.76367,240.95703,306.91699,239.67285z"/>
      <path class="megm-2" d="M339.875,284.17773c0.08594-0.03809,0.17578-0.06738,0.26855-0.08691
        c2.24512-0.46387,0.89551-2.31641,2.46973-3.93555c1.5791-1.74609,2.80176-3.78906,3.5918-6.01367
        c0.02393-0.12402,0.09912-0.22021,0.2041-0.30078c-3.03955-2.34863-5.16943-4.04395-5.83887-6.44629
        c-0.43506-3.39111-0.12939-6.83203,0.86523-10.09131c0.07617-0.25049,0.14746-0.50195,0.23193-0.75049
        c-2.69482-2.06982-5.63574-3.78613-8.77881-5.07813l-0.30713,0.0791c0.00098,0.00098,0.00146,0.00195,0.00244,0.00293
        c-1.13477,0.27979-2.56641,2.02197-3.54932,4.19287c-0.02246,0.0498-0.04346,0.09961-0.06543,0.14941
        c-0.06152,0.14063-0.12207,0.28271-0.17969,0.42627c-0.0459,0.11572-0.08936,0.23242-0.13281,0.34961
        c-0.03174,0.08594-0.06494,0.17188-0.09521,0.2583c-0.06885,0.19824-0.13379,0.39795-0.19336,0.59961
        c-0.00342,0.01123-0.00732,0.02246-0.01074,0.03369c-0.1333,0.45313-0.2417,0.91113-0.32031,1.36719
        c3.93555,2.15332,5.46777,3.69531,2.75586,6.49023c-3.0791,4.65039-3.19727,10.66113-0.2959,15.4248
        c2.98291,2.49414,3.84473,7.44873,6.72949,8.87207C337.99268,287.37793,338.57861,284.75293,339.875,284.17773z"/>
      <path class="megm-2" d="M234.85547,188.77246c-0.13965,0.9585-0.17773,1.92529-0.13379,2.88818
        c0.05859,0.66406,0.13379,1.22607,0.13379,1.49268c0,0.92773,3.14648,3.70215,5.16602,2.77832
        c1.30518-0.42871,2.70752-0.37598,3.96533,0.11719c0.83545,0.27197,1.62598,0.69434,2.32275,1.27051
        c1.79395,1.1543,5.1582-0.92285,6.28711-2.08105c1.11523-1.15723,7.40332-1.15625,8.07715-1.15625
        c0.26123,0,0.45361,0.55469,0.65674,1.27344c0.19141,0.6748,0.39209,1.49561,0.66602,2.14355
        c0.18945,0.44434,0.41455,0.80615,0.69678,0.97949c1.43652,0.43652,2.90381,0.7666,4.38672,0.99902
        c0.25586,0.02344,0.51025,0.05273,0.7666,0.06445c0.00049-0.00342,0.00049-0.00732,0.00098-0.01074l4.05273,0.3291
        c3.81836,0.22852,9.42188,2.0791,11.2207,2.0791c1.79395,0,4.71289-3.23828,8.75684-3.23828
        c4.03906,0,6.2832-2.54004,6.96191-2.30469c0.01074,0.00391,0.02393,0.01563,0.03516,0.021
        c0.28662-1.29199,0.74902-2.31543,1.62891-2.44482c1.64648-0.24121,0.47559-3.14258,1.64648-4.58496
        c1.16992-1.44629-0.93555-4.35352,1.16992-4.10938c2.1123,0.24219,4.4502,0.47754,4.4502-2.65332
        c0.0625-1.06934,0.54443-2.06738,1.35254-2.76758c-0.22461-0.36523-0.42773-0.74316-0.64014-1.11523
        c-1.03613-1.63379-1.8877-3.37842-2.50439-5.21924c-0.02832-0.07275-0.05518-0.14551-0.08252-0.21826
        c-0.00391-0.01172-0.00928-0.02246-0.01318-0.03418c0-0.00049,0.00049-0.00098,0.00049-0.00098
        c-0.2627-0.69141-0.51025-1.38867-0.73291-2.09473c0,0-5.02051,2.06348-7.69531-1.03418
        c-2.66943-2.07568-5.9707-3.16992-9.35205-3.104c-0.36426,1.66992-0.92139,3.29004-1.67236,4.82178
        c-0.15527,0.26367-0.44629,0.47754-0.83203,0.64746c-1.72363,0.76221-5.40771,0.62256-7.96045-0.08203
        c-0.68018,0.90674-3.20947,1.66553-4.62012,2.73975c-0.04199,0.03223-0.08154,0.06543-0.12109,0.09814
        c-0.07861,0.06396-0.15137,0.12939-0.2207,0.19531c-0.0459,0.04443-0.09082,0.08887-0.13232,0.13379
        c-0.05664,0.06152-0.10596,0.12451-0.15381,0.18848c-0.03564,0.04736-0.07373,0.09375-0.104,0.14258
        c-0.05811,0.09326-0.10449,0.18945-0.14063,0.28809c-0.00684,0.01855-0.01904,0.03613-0.02539,0.05469
        c-0.58594,1.8208-4.43994,1.16211-4.34375,4.68848c0.01416,0.50391,0.1084,1.09326,0.30469,1.78711
        c0.03174,0.11328,0.04736,0.20459,0.07471,0.31152c0.99658,3.9043-0.9917,3.83887-2.68555,3.27197
        c-0.54346-0.18066-1.05762-0.41016-1.43115-0.57568c-0.89404-0.13281-1.7915-0.18604-2.68359-0.16309
        c-2.68408,0.07178-5.3208,0.83936-7.64355,2.24805c-1.59277,1.51172-4.02539,1.70215-5.83789,0.46387
        c-1.94336-1.3457-4.60449-0.93848-6.05664,0.92285c-0.25342,0.43555-0.54688,0.64063-0.86914,0.68945
        c-1.2832,0.2041-3.03076-2.09521-4.59033-2.30225c-0.0874-0.01123-0.17334-0.00635-0.25928-0.00342
        c-0.03857,0.00098-0.07813-0.00586-0.1167-0.00195c-0.00537,0.00098-0.00977,0.00342-0.01514,0.00391l-1.09961,1.16602
        c-0.00977-0.00537-0.01953-0.00977-0.02881-0.01514c-0.00049,0.00195-0.00146,0.00391-0.00195,0.00586
        C234.84277,188.76563,234.84912,188.76904,234.85547,188.77246z"/>
      <path class="megm-2" d="M309.12305,179.85742c-0.80811,0.7002-1.29004,1.69824-1.35254,2.76758
        c0,3.13086-2.33789,2.89551-4.4502,2.65332c-2.10547-0.24414,0,2.66309-1.16992,4.10938
        c-1.1709,1.44238,0,4.34375-1.64648,4.58496c-0.87988,0.12939-1.34229,1.15283-1.62891,2.44482
        c0.41846,0.2002,1.21289,1.57422,1.78076,2.64063c0.10986,0.19971,0.21436,0.40234,0.31689,0.60693
        c0.20117,0.39258,0.33203,0.66211,0.33203,0.66211s-0.08887,0.08203-0.23633,0.21582
        c0.53564,0.30371,1.05273,0.63574,1.57275,0.96436c2.48096,1.56885,4.78418,3.40186,6.84424,5.50146
        c1.78076,1.54004,3.96436,2.46924,6.2417,2.71729c1.36328,0.14844,2.76025,0.05273,4.12451-0.30322
        c0.06055-0.01563,0.13086-0.03467,0.2002-0.05322c0.72559-0.1958,1.9126-0.55713,3.29883-0.99365
        c0.21387-0.06738,0.43262-0.13623,0.65479-0.20654l8.479-2.76123c0.87256-0.28906,1.41309-0.46973,1.41309-0.46973
        c1.41211,0.55078,2.86426,1.01172,4.33984,1.38184l2.84277,0.47754c0.40967-0.44678,1.25293-0.85889,2.33643-1.51172
        c1.27051-0.88379,2.49463-1.83252,3.65381-2.8584c0.00879-0.00928,0.01758-0.01709,0.02637-0.02637
        c0.3457-0.35596,0.71191-0.81689,1.08887-1.34863c3.0542-4.31299,6.91357-13.55322,8.11328-14.37695
        c0.00098-0.00098,0.00293-0.00195,0.00391-0.00244c0.00146-0.00146,0.00342-0.00342,0.00488-0.00439
        c0.84229-0.58154,2.55762-1.60742,4.16455-2.63428c0.96582-0.61865,1.89209-1.23682,2.56299-1.7583
        c0.66357-0.51514,1.01758-1.0293,1.10059-1.50879c0.00293-0.01758,0.00195-0.03467,0.00391-0.05225
        c0.01563-0.11816,0.00781-0.23291-0.00977-0.3457c-0.00977-0.05859-0.02246-0.1167-0.04053-0.17334
        c-0.01904-0.06055-0.04932-0.11816-0.07764-0.17676l-0.00879,0.00098l-1.64551-1.18848
        c-0.92676-0.3418-1.74902-0.87256-2.44336-1.53223c-0.53906-0.08496-1.07275-0.19238-1.59912-0.32861
        c-1.64941-0.42676-3.22998-1.10303-4.68896-2.00732c-2.92188-1.5498-6.08887-2.59961-9.35449-3.10449
        c-1.00195-0.34473-7.35742,6.20508-9.36328,6.20508c-2.00977,0-10.69824,1.02832-10.69824,3.44629
        c0,2.41016-10.70508,2.75879-13.71777,0c-0.03125-0.03613-0.05664-0.07666-0.0874-0.11328l-1.27979-1.54199
        C309.12451,179.85596,309.12402,179.85693,309.12305,179.85742z"/>
      <path class="megm-2" d="M277.7334,171.6626c0.1377,0.26953,0.08545,0.51953-0.09375,0.75928
        c2.55127,0.70068,6.23291,0.84277,7.95898,0.08398c0.38574-0.16992,0.67676-0.38379,0.83203-0.64746
        c0.75098-1.53174,1.30811-3.15186,1.67236-4.82178c0.00049-0.00195,0.00098-0.00342,0.00146-0.00537
        c3.37207-0.06641,6.67773,1.02734,9.35059,3.10156c2.68262,3.10449,7.69531,1.03906,7.69531,1.03906l0.73242,2.09375
        c0.71973-1.69434,1.65918-3.19727,2.81543-3.36914c1.66406-0.03076,3.27344-0.41602,4.75732-1.0752
        c1.20752-0.53809,2.32178-1.26563,3.30371-2.16699h-45.04541c2.09277,1.55322,4.10986,3.20605,6.02441,4.97949L277.7334,171.6626z
        "/>
      <path class="megm-2" d="M308.68945,169.90137c-1.14697,0.17188-2.08643,1.67041-2.80615,3.36816c0,0-0.00049,0.00049-0.00049,0.00098
        c0.00391,0.01172,0.00928,0.02246,0.01318,0.03418c0.02393,0.07422,0.05762,0.14453,0.08252,0.21826
        c0.6167,1.84082,1.46826,3.58545,2.50439,5.21924c0.58545,0.92236,1.21436,1.81738,1.92188,2.65527
        c0.03076,0.03662,0.05615,0.07715,0.0874,0.11328c3.0127,2.75879,13.71777,2.41016,13.71777,0
        c0-2.41797,8.68848-3.44629,10.69824-3.44629c2.00586,0,8.36133-6.5498,9.36328-6.20508
        c3.26563,0.50488,6.43262,1.55469,9.35449,3.10449c1.45898,0.9043,3.03955,1.58057,4.68896,2.00732
        c0.52637,0.13623,1.06006,0.24365,1.59912,0.32861c-0.18848-0.17871-0.36914-0.36523-0.53662-0.56348
        c-0.68799-0.72754-1.16406-1.62207-1.39502-2.60059c-0.03906-0.1333-0.0874-0.26221-0.11914-0.39844
        c0.53516-1.79736,1.34033-3.49707,2.38184-5.04199c0.48047-0.7168,1.01758-1.396,1.59668-2.04102h-45.08252
        c-0.98193,0.90137-2.09619,1.62891-3.30371,2.16699C311.9707,169.4834,310.35889,169.87061,308.68945,169.90137z"/>
      <path class="megm-2" d="M64.53613,310.14258c0.99316-0.18457,1.4248-0.53125,1.49951-0.96484
        c0.1958-1.31738-2.86768-3.42334-3.68701-4.27148c-0.34375-0.354-0.43604-1.04053-0.33301-1.81641
        c0.01465-0.11475,0.03857-0.2334,0.06201-0.35156c0.00732-0.03711,0.01123-0.07275,0.01953-0.11035
        c0.3501-1.6001,1.43652-3.37207,2.80811-3.37207c0.66992,0,0.9248-0.25,0.875-0.68164
        c-0.13232-0.97803-1.78809-2.86572-3.7959-4.93457c-1.09131-1.12646-1.36182-1.9873-1.13965-2.62354
        c0.36182-1.06836,2.10156-1.51123,3.69141-1.51123c2.5459,0-0.73535-4.5,1.81055-7.13086
        c0.479-0.49268,0.75342-1.06396,0.8833-1.68213c0.54785-2.67725-1.604-6.23291-1.604-8.06396
        c0-0.14453,0.01611-0.28271,0.04492-0.41602c0.40576-1.94922,3.77881-2.7832,6.5166-5.60156
        c0.36182-0.37354,0.59326-0.68262,0.72266-0.94238c0.87793-1.80859-3.28223-1.20508-3.28223-3.16406
        c0-2.23926-3.63965-3.00586-6.18945-1.13184c-2.54883,1.88281-1.81348,0-4.00098,0c-2.2002,0-6.19824,0.75684-6.19824-1.50098
        c0-2.25586-1.81152-2.62207-3.64063-0.37305c-0.7168,0.72266-1.68066,1.14551-2.69824,1.17871
        c-0.50391,2.34473-0.40332,4.77734,0.27539,7.07813c0.5498,8.78125-1.64355,17.5127-6.28711,24.98828
        c-3.89453,4.62891-0.14453,5.86621,3.89258,9.10645c4.04395,3.23828,1.3457,14.95898,1.3457,17.73535
        c0,2.77441,3.59473,2.00684,7.78516,2.00684c2.73242-0.33398,5.43066-0.88086,8.07715-1.64258
        c-0.74805-0.99707-1.25098-2.16309-1.46777-3.39355c0.00391-0.854,0.00684-1.86475,0.22217-2.83398
        C61.09277,312.10938,62.02783,310.6084,64.53613,310.14258z"/>
      <path class="megm-2" d="M428.36035,214.53906c-1.19141,0-4.78809,2.59668-5.68359,2.4668
        c-2.0918-0.30664-4.78516-1.22949-3.89453-2.4668c0.00586-0.00684,0.01074-0.01465,0.0166-0.02148l0.25586-0.35059
        c-2.75586-3.26855-3.42969-7.81641-1.74902-11.74707c0.20605-0.21191,0.32422-0.49316,0.40527-0.80273
        c0.03125-0.12158,0.0752-0.23389,0.08984-0.36816c0.35742-3.3418-4.5-10.56006-6.90332-13.44922
        c-2.47949-3.75439-4.69824-7.66992-6.65039-11.7207c-1.69238,0.65723-3.33789,1.44238-4.91309,2.36523
        c-1.39453,1.77246-3.44922,2.8877-5.69336,3.09082c-1.78516,0-3.58398-0.92773-5.08691,0.61133
        c-1.49707,1.54199-3.58398,2.16406-4.4873,1.23828c-1.76367-1.20313-3.84961-1.84863-5.99121-1.84961
        c-2.0918,0-8.67969-1.85352-10.16992-1.85352l-3.88477,0.3374c0.02832,0.05811,0.04932,0.11719,0.06885,0.17725
        c0.01807,0.05664,0.03076,0.11475,0.04053,0.17334c0.01953,0.11279,0.02295,0.22803,0.00977,0.3457
        c-0.00195,0.01758-0.00098,0.03467-0.00391,0.05225c-0.07471,0.48193-0.42285,0.99707-1.09668,1.50879
        c-0.67383,0.52246-1.60059,1.14063-2.56689,1.7583c-1.60693,1.02686-3.32227,2.05273-4.16455,2.63428
        c-0.00146,0.00098-0.00342,0.00293-0.00488,0.00439c-1.20557,0.85303-5.06592,10.07813-8.11719,14.37939
        c-0.37695,0.53174-0.74316,0.99268-1.08887,1.34863c-0.00879,0.00928-0.01758,0.01709-0.02637,0.02637
        c-1.3208,1.35742-2.59863,2.22266-3.65381,2.8584c-1.0835,0.65283-1.92676,1.06494-2.33643,1.51172
        c-0.00146,0.00146-0.00293,0.00293-0.00439,0.00439l0.021,0.00342l-0.2793,0.4707
        c-0.20508,1.15625,3.59082,4.15674,3.59082,6.00293c-0.00049,0.00488,0,0.00928-0.00049,0.01416
        c0,0.00049,0.00049,0.00146,0.00049,0.00244c-0.00049,0.00439,0,0.00879-0.00049,0.01318
        c-0.02197,0.19385-0.02393,0.38525-0.0127,0.57324c0.00537,0.09277,0.02295,0.18115,0.03564,0.27148
        c0.01953,0.1333,0.03613,0.2666,0.0708,0.39453c0.01514,0.05615,0.03809,0.10791,0.05615,0.1626
        c0.05078,0.15479,0.10498,0.30859,0.17725,0.45313c0.01172,0.02344,0.02686,0.04395,0.03857,0.06689
        c0.08936,0.16748,0.18848,0.33008,0.30469,0.47949c0.00293,0.00391,0.00684,0.00732,0.00977,0.01123
        c0.51807,0.66064,1.28662,1.125,2.18848,1.23633c0.15723,0.02051,0.32813,0.02734,0.49512,0.02344
        c1.24268,0,1.18311,1.5127,1.0127,3.07959c-0.06299,0.5835-0.1416,1.17529-0.1748,1.69971
        c-0.0542,0.87109,0.01953,1.5542,0.50684,1.69824c1.57813,0.46289,11.23047,1.15625,11.23047,3.46191
        c0,0.20752,0.01709,0.41064,0.0415,0.61084c0.00439,0.03809,0.00928,0.07617,0.01465,0.11426
        c0.02637,0.18652,0.05908,0.37061,0.09912,0.55029c0.02002,0.08984,0.04248,0.17725,0.06445,0.26465
        c0.01318,0.05225,0.02588,0.10449,0.03955,0.15625c0.28857,1.08252,0.66895,2.03906,0.3623,2.87012
        c2.70117,0.48926,4.68359,4.27197,5.74561,3.99805c1.2002-0.30371,9.2832,1.54785,10.77637,1.23535
        c3.59277-0.19336,7.19727-0.08789,10.78027,0.3125c2.73535,0.12207,5.38281-1.0127,7.17871-3.08984
        c3.67871-1.9248,7.86426-2.67578,11.97656-2.1582c2.3916,0.31055,7.18164,0.93066,8.07715,2.47266
        c1.82129,1.41016,3.90723,2.43066,6.13965,3c0.13379-1.82422,0.13379-3.65137,0-5.47266
        c0.14746-3.24121,1.94629-8.16992,5.08496-8.48438c3.14844-0.30957,5.69434-0.30957,5.24023-5.70605l-0.02832-0.49121
        c-0.00586-0.00684-0.01367-0.0127-0.01953-0.01904c0.00098,0.01465,0.00098,0.02686,0.00195,0.0415
        C430.91016,216.05273,429.69629,215.17871,428.36035,214.53906z"/>
      <path class="megm-2" d="M417.80078,201.24902c-0.01465,0.13428-0.05859,0.24658-0.08984,0.36816
        c-0.08105,0.30957-0.19922,0.59082-0.40527,0.80273c-1.68066,3.93066-0.99902,8.47852,1.74902,11.74707
        c0.54102-0.62939,1.05176-1.28174,1.5459-1.9458c1.57031-2.15918,2.9082-4.47754,4.01172-6.91504
        c0.0498-0.11523,0.11133-0.2251,0.16016-0.34082c0.00098-0.81299,0.05957-1.97754,0.42773-2.95264
        c0.46973-1.25781,1.4502-2.20605,3.47559-1.68555c3.33398,0.86133,6.4209,2.25879,6.83496,1.45117
        c0.02441-0.05566,0.03711-0.12012,0.03711-0.19531c-0.16699-2.43652-0.99512-4.78711-2.38965-6.78711
        c-2.74219-3.07031-4.875-6.62598-6.28711-10.48535c-1.00684-3.16895-3.86719-5.38672-7.18555-5.55469
        c-2.0918,0-5.68848-4.93945-8.08203-4.62793c-0.21875,0.03223-0.43555,0.07568-0.6543,0.1123
        c-1.98926,0.38867-3.95508,0.90186-5.8877,1.53027c-0.27344,0.09814-0.54395,0.20361-0.81445,0.30859
        c1.95215,4.05078,4.1709,7.96631,6.65918,11.7207C413.30273,190.68359,418.1582,197.90625,417.80078,201.24902z"/>
      <path class="megm-2" d="M360.13086,232.49121c-0.04639,0.03906-0.08301,0.08301-0.12598,0.12305
        c-0.13525,0.12988-0.25732,0.26758-0.37451,0.40869c-0.125,0.15137-0.2373,0.30859-0.34131,0.46973
        c-0.06787,0.10596-0.13477,0.2124-0.19336,0.32227c-0.09033,0.16797-0.1665,0.34082-0.23486,0.51611
        c-0.04443,0.11328-0.08838,0.22705-0.12354,0.34326c-0.05615,0.18359-0.09766,0.37012-0.13086,0.55811
        c-0.02002,0.11084-0.03955,0.22119-0.05176,0.33301c-0.021,0.20166-0.02637,0.40381-0.02246,0.60596
        c0.00195,0.09863,0.00342,0.19678,0.01172,0.29541c0.01758,0.21338,0.05273,0.4248,0.09863,0.63477
        c0.01904,0.08789,0.0376,0.17529,0.06152,0.26172c0.05859,0.20996,0.13574,0.41553,0.22314,0.61719
        c0.03711,0.08545,0.07373,0.1709,0.11621,0.25488c0.09619,0.19043,0.20996,0.37354,0.33301,0.55176
        c0.06055,0.08789,0.12158,0.17529,0.18896,0.26025c0.1709,0.21582,0.35254,0.42627,0.56543,0.61572
        c1.52441,1.41943,3.35742,4.02686,3.72998,5.54297c0.00293,0.01074,0.00781,0.02344,0.01025,0.03418
        c0.16162,0.68848,0.01221,1.14355-0.62012,1.14355c-1.8916,0-3.38379,2.43555-3.38477,3.88525
        c0.00049,0.09521,0.00684,0.18604,0.02051,0.27197c0.06445,0.38672,0.0376,0.90283-0.01563,1.45605
        c-0.03027,0.57275-0.08643,1.14404-0.18164,1.70996c-0.00098,0-0.00195,0.00049-0.00293,0.00049
        c0,0.00195,0,0.00391-0.00049,0.00635c0.00391-0.00098,0.00732-0.00146,0.01123-0.00195l-0.03516,1.12891l0.01367,0.08496
        l0.21777,0.70996c2.16113,1.97314,4.05176,4.22656,5.60352,6.70703c0.01758,0.11084,0.06348,0.21191,0.10742,0.31396
        c0.01611,0.03857,0.02148,0.08105,0.04053,0.11816c0.42871,0.81641,1.56494,1.37402,1.8042,2.1084
        c0.04102,0.12109,0.05713,0.24707,0.04199,0.37988c0.00146,0,0.00293,0,0.00391-0.00049l0.00098-0.00195
        c4.5166-0.5752,9.08496-0.52148,13.58398,0.16309c3.50635,1.02881,7.125,1.65088,10.77148,1.85596
        c2.38721-0.00342,4.47754-0.92822,3.87598-2.46143c-0.28467-0.73535,0.58398-1.32959,1.79883-1.75098
        c0.92578-0.32275,2.05566-0.54395,3.0332-0.64844c0.0127-0.00146,0.02637-0.00342,0.03906-0.00488
        c-0.39063-1.42285-0.66211-2.60254-0.08105-2.84961c1.27051-0.35303,2.56836-0.57324,3.87207-0.66064
        c2.16699-0.146,4.35059,0.07422,6.45313,0.66064c0.70898,0.2583,1.45801,0.31152,2.16895,0.17383
        c0.14746-0.02832,0.29199-0.06934,0.43555-0.11426c0.02637-0.0083,0.05371-0.01221,0.08008-0.021
        c0.51367-0.17236,0.99414-0.44922,1.40918-0.82568c-0.36133-1.2959-0.70605-2.31934-1.40039-2.4541
        c-1.64063-0.30176-4.18066-7.40234-2.0957-7.86719c2.09277-0.46289,4.7959-7.86523,8.07813-8.94238
        c1.08008-0.2793,1.89453-1.15332,2.11426-2.24512c-2.23926-0.56445-4.32715-1.58496-6.14355-2.99707
        c-0.88184-1.54102-5.67578-2.16309-8.07227-2.47461c-4.11621-0.5127-8.28906,0.24121-11.96777,2.16602
        c-1.7959,2.07129-4.44336,3.21094-7.19141,3.08496c-3.57031-0.40137-7.1748-0.50586-10.76758-0.31055
        c-1.49805,0.31055-9.57617-1.54199-10.77637-1.23535c-1.06152,0.27051-3.04199-3.50391-5.75439-3.99219
        C360.8252,231.85889,360.57373,232.18701,360.13086,232.49121z"/>
      <path class="megm-2" d="M341.66406,256.56445c-0.08301,0.24414-0.15332,0.49219-0.22852,0.73877
        c-0.99463,3.25928-1.30029,6.7002-0.86523,10.09131c0.66943,2.40234,2.79932,4.09766,5.83887,6.44629
        c0.8042-0.62012,3.80078-0.10303,6.15723-0.50195l0.17236-0.03174c0.146-0.02783,0.29053-0.05713,0.43018-0.09326
        c2.68848-0.69922,3.35449-3.7002,4.70703-3.00781c1.35449,0.69531,8.52832-3.08203,9.43164-4.46777l0.18408-0.4668
        c0.00049-0.00293-0.00098-0.00537-0.00049-0.0083c0.01514-0.13281-0.00098-0.25879-0.04199-0.37988
        c-0.24805-0.72998-1.37988-1.28662-1.8042-2.1084c-0.01904-0.03711-0.02441-0.07959-0.04053-0.11816
        c-0.0415-0.09863-0.08691-0.19629-0.10449-0.30322c-1.55664-2.47559-3.43555-4.73828-5.60449-6.71191l-0.00195-0.00586
        l-0.21777-0.70996l-0.01367-0.08496l0.03516-1.12891c-0.00391,0.00049-0.00732,0.00098-0.01123,0.00195
        c-7.01904,1.05322-17.42822,3.28223-17.42822,3.28223L341.66406,256.56445z"/>
      <path class="megm-2" d="M357.86328,173.7373c0.03174,0.13623,0.08008,0.26514,0.11914,0.39844
        c0.28467,0.96533,0.75879,1.84912,1.39502,2.60059c0.16748,0.19824,0.34814,0.38477,0.53662,0.56348
        c0.69434,0.65967,1.5166,1.19043,2.44336,1.53223l1.64551,1.18848l0.00879-0.00098l0.00879-0.00049l3.88477-0.3374
        c1.49023,0,8.07813,1.85352,10.16992,1.85352c2.1416,0.00098,4.22754,0.64648,5.99121,1.84961
        c0.90332,0.92578,2.99023,0.30371,4.4873-1.23828c1.50293-1.53906,3.30176-0.61133,5.08691-0.61133
        c2.24414-0.20313,4.29883-1.31836,5.69336-3.09082c1.5752-0.92285,3.2207-1.70801,4.91309-2.36523l0,0
        c0.27051-0.10498,0.54102-0.21045,0.81445-0.30859c1.90918-0.68555,3.87891-1.19531,5.8877-1.53027
        c0.21875-0.03662,0.43555-0.08008,0.6543-0.1123c2.39355-0.31152,5.99023,4.62793,8.08203,4.62793
        c3.31836,0.16797,6.17871,2.38574,7.18555,5.55469c1.41211,3.85938,3.54492,7.41504,6.28711,10.48535
        c1.39453,2,2.22266,4.35059,2.38965,6.78711c0,0.0752-0.0127,0.13965-0.03711,0.19531
        c-0.36621,0.854-3.47363-0.56152-6.84473-1.43164c-2.0166-0.51904-2.99512,0.41748-3.46582,1.66602
        c-0.36816,0.9751-0.42676,2.13965-0.42773,2.95264c0,0.00342,0,0.00684,0,0.01025c-0.0498,0.1123-0.10938,0.21875-0.16016,0.33057
        c-1.10352,2.4375-2.44141,4.75586-4.01172,6.91504c-0.57129,0.78613-1.1709,1.55322-1.80176,2.29639
        c-0.00586,0.00684-0.01074,0.01465-0.0166,0.02148c-0.89063,1.2373,1.80273,2.16016,3.89453,2.4668
        c0.89551,0.12988,4.49219-2.4668,5.68359-2.4668c1.33594,0.63965,2.5498,1.51367,3.56152,2.58691
        c-0.00098-0.01465-0.00098-0.02686-0.00195-0.0415c-0.32227-4.77441,0.65039-4.52344,4.8252-7.7876
        c4.34375-3.3916,4.78906-9.71875,8.67871-10.49512c3.53516-1.5293,7.39355-2.11523,11.2207-1.69922
        c5.69434,0.92578-2.83691,4.62793,1.04785,6.79297c3.88672,2.16602,15.87305-0.61328,16.47168,2.46289
        c0.5918,3.08008-13.77637,6.17676-13.4707,7.7168c0.29688,1.54199,7.62695,3.08789,10.18164,4.78027
        c2.5459,1.69629-0.75098,6.47852,0.4502,8.02832c1.19043,1.53809,5.07324,0.30176,8.22168-2.01074
        c3.13867-2.31348,5.69336-1.54199,9.73145-4.31934c4.03027-2.77734,12.41797-0.30664,13.46484-1.99805
        c1.05859-1.69141-2.39258-3.40039-10.17773-2.77832c-7.78027,0.6123-8.22461-6.94141-8.22461-8.48828
        c3.14355-4.17188,7.64063-7.11914,12.72168-8.32813c4.49316-0.53418,8.8623-1.8916,12.86328-4.01074
        c2.64941-1.09375,5.40625-1.8916,8.22656-2.38574c-0.09961-0.45947-0.20215-0.93213-0.29492-1.39746
        c-0.36719-1.7793-0.55273-3.45166,0.51855-3.61719c1.57129-0.22949,6.28418-5.55273,7.85449-5.55273
        c1.56836,0,8.30566,1.39063,8.30566,0.23145c0-1.14746,2.20215-5.01807,1.80469-6.2124
        c-0.38574-0.93945-1.91602-2.18115-2.23145-3.229c-0.08984-0.25977-0.11133-0.5083-0.02344-0.73828
        c0.4502-1.1582,3.59668-1.1582,3.59668-2.54492c0-0.08594-0.00977-0.16846-0.02734-0.24902
        c-0.30371-1.11279-2.16309-1.75977-2.40039-2.53809c-0.00879-0.02051-0.01855-0.0415-0.02441-0.0625H361.85059
        c-0.58203,0.64453-1.12207,1.32422-1.60547,2.04102C359.20361,170.24023,358.39844,171.93994,357.86328,173.7373z"/>
      <path class="megm-2" d="M221.83203,202.16113c0.89648-0.62109,3.29883,4.94824,5.69238,7.09766
        c2.38281,2.15527,2.69238-1.84473,2.69238-3.70117c0-1.85156,2.09277-2.46973,3.58594-2.1582
        c1.49609,0.31445,4.79492,0.92578,4.79492-1.23828c0-2.15918,1.79785-1.22559,2.98926-1.22559
        c1.18848,0,1.50293-2.47559,1.50293-2.47559l1.95508-1.87305c-0.33545-0.21875-0.69189-0.39453-1.05811-0.53809
        c-1.25781-0.49316-2.66016-0.5459-3.96533-0.11719c-2.01953,0.92383-5.16602-1.85059-5.16602-2.77832
        c-0.07227-0.49512-0.11084-0.99365-0.13379-1.49268c-0.04395-0.96289-0.00586-1.92969,0.13379-2.88818
        c-0.00635-0.00342-0.0127-0.00684-0.01904-0.01025c-1.86719-1.03369-3.80713-1.91699-5.81982-2.62549
        c-1.56006-0.70459-3.29395-0.84521-4.92676-0.4541c-0.67432,0.16211-1.33252,0.40625-1.9502,0.75488
        c-0.62354,0.14502-1.25439,0.25293-1.88672,0.34375c-2.21191,0.3208-4.46045,0.31641-6.68018-0.03076
        c-0.25439,0.93408-0.65723,1.56006-1.37842,1.56006c-2.46289,0.26953-4.51465,2.01465-5.17188,4.40039
        c-0.44434,2.3125-5.1543,4.1709-6.50586,8.3291c-0.75635,2.33496-0.87354,3.93994-0.47314,4.44824
        c0.31738,0.36572,0.93652,0.08691,1.80908-1.01172c1.6416-2.07764,3.58301-1.8457,4.25146-0.56934
        c0.16357,0.29883,0.25732,0.65576,0.25732,1.05762c0,0.51611,0.16602,0.91748,0.40088,1.27246
        c0.30078,0.4458,0.70752,0.82031,1.03076,1.25684c0.45703,0.60889,0.75342,1.33691,0.35742,2.55859
        c-0.01611,0.05029-0.02148,0.09326-0.03662,0.14258c3.07275-0.27539,7.33008-0.63184,8.62939-0.63184
        C218.8418,209.56348,220.92773,202.7832,221.83203,202.16113z"/>
      <path class="megm-2" d="M216.44531,168.39648c0.08398-0.01465,0.17725-0.02344,0.27686-0.02734
        c0.99756-0.03613,2.66064,0.47168,2.84229,1.52002c0.09473,0.52686-0.18018,1.19043-1.09473,1.9917
        c-2.30469,2.29102-3.7334,5.32227-4.03711,8.56348c-0.43262,1.93213-0.40234,4.62451-0.85986,6.30566
        c2.21436,0.34717,4.46777,0.3501,6.68018,0.03125c0.63232-0.09082,1.26318-0.19873,1.88672-0.34375
        c0.61768-0.34863,1.27588-0.59277,1.9502-0.75488c1.63037-0.39258,3.36572-0.25098,4.92676,0.4541
        c2.00928,0.70557,3.95654,1.58447,5.82178,2.61963c0.00928,0.00537,0.01904,0.00977,0.02881,0.01514l1.09961-1.16602
        l0.00391-0.00391c0.00391-0.00049,0.00732,0.00049,0.01123,0c0.03857-0.00391,0.07813,0.00293,0.1167,0.00195
        c0.08594-0.00244,0.17188-0.00781,0.25928,0.00342c1.55957,0.20703,3.30713,2.50635,4.59033,2.30225
        c0.31982-0.05078,0.61133-0.25635,0.86328-0.68945c1.45801-1.86133,4.11523-2.26855,6.0625-0.92578
        c1.8125,1.24121,4.24512,1.0459,5.83789-0.46094c2.3252-1.41016,4.9585-2.17773,7.64355-2.24805
        c0.89209-0.02295,1.78955,0.03027,2.68359,0.16309c0.37354,0.16553,0.8877,0.39502,1.43115,0.57568
        c1.6958,0.56396,3.67725,0.62646,2.68555-3.27197c-0.02734-0.10693-0.04297-0.19824-0.07471-0.31152
        c-0.19629-0.69385-0.29053-1.2832-0.30469-1.78711c-0.09668-3.52686,3.75781-2.86768,4.34375-4.69141
        c0.00586-0.01807,0.01855-0.03418,0.02539-0.05176c0.03613-0.09863,0.08252-0.19482,0.14063-0.28809
        c0.03027-0.04883,0.06836-0.09521,0.104-0.14258c0.04785-0.06396,0.09717-0.12695,0.15381-0.18848
        c0.0415-0.04492,0.08594-0.08936,0.13232-0.13379c0.06934-0.06592,0.14209-0.13135,0.2207-0.19531
        c0.04004-0.03271,0.07861-0.06592,0.12109-0.09814c1.41064-1.07422,3.93994-1.83301,4.62012-2.73975
        c0.00049-0.00049,0.00098-0.00098,0.00146-0.00195c0.1792-0.23975,0.23145-0.48975,0.09375-0.75928
        c-0.00488-0.00977-0.00342-0.01904-0.00879-0.02881c-1.90869-1.77344-3.92578-3.42627-6.01904-4.97949h-59.55518
        c1.82275,0.3584,2.8208,1.52393,3.79688,1.72949C216.11279,168.41846,216.27734,168.42578,216.44531,168.39648z"/>
      <path class="megm-0" d="M614.80078,420.84277l-5.19434-4.53125l-6.23828-0.79785c0-0.00098,0.00098-0.00098,0.00098-0.00195
        c-0.00391-0.00098-0.00781-0.00098-0.01172-0.00195c-0.00098,0.00293-0.00195,0.00488-0.00293,0.00781
        c-4.62207-0.58203-10.35645-1.25684-11.73145-1.25684c-4.34473-0.03027-8.56348-1.47363-11.99414-4.12402
        c-7.79688-6.60547-15.92969-12.79199-24.375-18.54102c-6.33496-4.51563-13.00879-8.51172-19.99512-11.94238
        c-0.28516-0.02344-0.57129-0.03467-0.85742-0.05273c-1.05566-0.06348-2.11133-0.10303-3.16699-0.10645
        c-1.27441,0.00293-2.54785,0.05469-3.81738,0.15918c-0.00195-0.01123-0.00488-0.02295-0.00684-0.03418
        c-0.09766,0.00635-0.19434,0.00977-0.29199,0.0166c-0.08008,0.00586-0.16113,0.00781-0.24121,0.01367
        c-4.39453,0-2.39648,2.88379-10.38867,3.29883c-5.49512,0.28174-9.28613,2.89746-10.3457,4.77588
        c-0.08594,0.15283-0.14746,0.29834-0.19727,0.43945c-0.00488,0.01465-0.01563,0.03076-0.01953,0.04492
        c-0.20605,0.63086-0.03516,1.12646,0.56836,1.33447c2.02148,0.69238,7.7373,5.7749,8.04004,8.09668
        c0.05664,0.43213-0.07422,0.76904-0.45215,0.96387c-2.40234,1.23535-2.79297,4.94336-5.99121,4.94336
        c-2.79688,0-5.59668,0.31738-6.25488,2.05273c-0.09375,0.24805-0.14453,0.52441-0.14453,0.83398c0,0.00098,0,0.00098,0,0.00195
        c-0.98926,3.60938-4.24609,6.13379-7.98828,6.17969c-0.34766-0.10449-0.68652-0.23438-1.03125-0.34863
        c-0.52344-0.17285-1.05078-0.33594-1.56641-0.5293c-0.07422-0.02832-0.14746-0.06152-0.22168-0.08984
        c-0.7207-0.27637-1.43262-0.57324-2.13477-0.88965c-0.56152-0.25293-1.1084-0.53125-1.65723-0.80957
        c-0.09766-0.0498-0.19629-0.09668-0.29395-0.14746c-1.07324-0.55469-2.12988-1.13867-3.14941-1.78906l-1.05762,2.98535
        l-0.38281,2.82422c0.69727,2.18652-0.35059,11.17969,1.69238,11.87695c2.23438,0.77148,3.43457,4.9375,8.2207,10.33691
        c6.125,6.97168,11.08203,14.8877,14.67871,23.44824c0.47754,4.91797,3.40918,9.26367,7.77246,11.57129
        c5.99023,4.16016,5.99023,9.25488,9.13477,11.72363c3.1377,2.47266,2.39258,5.39844,2.53809,11.57324
        c-0.06836,6.80762,2.96094,13.28418,8.24023,17.58496c5.39063,3.05469,9.53027,7.91016,11.67969,13.72168
        c2.03809,5.8418,5.19141,11.23438,9.27148,15.89258c3.43848,3.54395,1.49805,5.39355,3.43848,8.02246
        c1.35449,1.87598,2.47363,3.90918,3.33887,6.05859c1.65625-0.7002,3.21973-1.58203,4.67383-2.63867
        c0.07031-0.14746,0.10547-0.32813,0.11426-0.5332c0.05273-1.30078-0.93164-3.56836-0.5752-4.76465
        c0.03906-0.13672,0.08887-0.26465,0.16602-0.37109c1.08008-1.65918,2.57813-2.99512,4.33984-3.87598
        c1.73242-0.58887,3.76172,1.79102,7.5166,1.19434c4.1875-0.50781,8.43262-0.10156,12.44141,1.18848
        c0.85742,0.89746,11.56543,0.30371,14.16895,2.38867c2.6084,2.09277,5.20898,1.4873,6.94336-2.68262
        c4.3457-5.54199,9.63574-10.29492,15.62109-14.01074c1.7373-0.90137,14.75098,0,19.96387-1.19629
        c5.2002-1.19238,39.33496-12.51563,40.77344-13.71289c1.85938-4.19824,3.43652-8.50879,4.73633-12.90234
        c0.86035-2.91699,1.60156-5.87012,2.20703-8.85254c0-2.08594-2.59766-7.75488-5.20117-7.4541
        c-0.69434,0.0332-1.38672,0.0293-2.0752-0.02246c-6.33105-0.36035-12.63965-1.08789-18.88965-2.15137
        c-1.5-0.25098-2.99805-0.51563-4.49219-0.80859c-3.91016-2.60059-6.63672-6.61621-7.6377-11.2002
        c0.00098,0.00098,0.00195,0.00098,0.00293,0.00195c-0.00098-0.00293-0.00195-0.00684-0.00293-0.00977
        c-2.02734-1.75879-3.48828-4.08301-4.19434-6.66699h-0.00098c0,0.00195,0,0.00391,0.00098,0.00586
        c-3.95313,0.50293-7.62891-2.1084-8.4541-6.00977h0.00293c-0.00098-0.00391-0.00195-0.00684-0.00293-0.00977
        c-1.98535-1.39844-7.24805-9.09375-5.41211-11.24121c2.24414-2.61816-1.19141-8.1748-4.48438-11.26172
        c-3.30566-3.08789-10.02734-8.79297-10.33887-12.33887c-0.25195-1.51758-0.67578-3.00684-1.26953-4.42969l0,0v0.00098
        L614.80078,420.84277z"/>
      <path class="megm-0" d="M701.66797,462.34375h-3.83105v7.90137l-6.38867,8.35254l-3.41992,8.78809
        c0.68848,0.05176,1.38086,0.05566,2.0752,0.02246c2.60352-0.30078,5.20117,5.36816,5.20117,7.4541
        c-0.60547,2.98242-1.34668,5.93555-2.20703,8.85254c-1.29785,4.39648-2.87598,8.71191-4.73633,12.91211
        c-1.03711,0.86328-19.07129,6.98828-31.00879,10.78613c2.58789,5.52832,7.74902,16.53027,12.39355,26.35352
        c-0.00098,0.00098-0.00195,0.00098-0.00391,0.00098c0.00195,0.00293,0.00293,0.00586,0.00391,0.00781l2.57715-1.27637
        c5.67871-3.3916,12.12109-1.69043,16.45703-2.93262c4.35059-1.21973,0.15332-4.92773,3.89355-8.17285
        c3.74609-3.24902,9.13086-0.76855,11.07813-1.85156c1.93848-1.08789,3.29199-8.16797,6.58398-9.5625
        c3.28809-1.39551,6.88086-0.46973,6.43359-3.4043c-0.45117-2.92871,0.29883-13.41602,3.43652-14.03613
        c3.14941-0.6123,8.07813-4.00879,8.23242-5.85645c2.54102-4.23242,5.33887-8.30371,8.38379-12.18262
        c1.94336-3.39746,2.09863-5.24902-0.7373-5.70801c-2.84766-0.4668-7.34473-8.64453-8.53418-10.95117
        c-1.19141-2.31934-9.88281-0.78223-16.91797-6.48047c-2.92676-3.03613-4.83496-6.91211-5.44531-11.0918l-0.00195,0.00098
        c0.00098,0.00195,0.00098,0.00488,0.00195,0.00684L701.66797,462.34375z"/>
      <path class="megm-0" d="M652.81445,466.55664c-0.00098-0.00195-0.00098-0.00391-0.00098-0.00586
        c-0.2959-0.96094-0.21387-1.99316,0.20215-2.89844c1.94336-3.39844,3.14551-12.19336-1.18945-13.42285
        c-4.34668-1.24023-6.73828,7.71484-6.73828,10.17969l-0.72461,0.1377h-0.00293
        C645.18555,464.44824,648.86133,467.05957,652.81445,466.55664z"/>
      <path class="megm-0" d="M664.64648,484.42578c1.49219,0.2959,2.99219,0.55371,4.49219,0.80859
        c6.25,1.06348,12.55859,1.79102,18.88965,2.15137l3.41992-8.78809l6.38867-8.35254v-7.90137h3.83105l3.51953-2.06738
        c-0.00098-0.00195-0.00098-0.00488-0.00195-0.00684c-1.48047-6.42676-1.70801-12.87891-2.77441-12.9707
        c-1.79395-0.15234-9.13867,8.01465-12.11914,14.03613c-3.92578,6.68457-11.49219,10.34082-19.16406,9.25586
        c-4.28516-1.15137-8.85645,0.08984-11.97168,3.23828c-0.5,0.36035-1.28906,0.07324-2.14453-0.60156
        c-0.00098-0.00098-0.00195-0.00098-0.00293-0.00195C658.00977,477.80957,660.73633,481.8252,664.64648,484.42578z"/>
      <path class="megm-2" d="M600.67676,370.71729c-1.21094-1.04736-1.98633-2.10889-1.85938-3.00732
        c0.39551-2.875-3.19629-4.9502-4.79883-5.77051c-1.59668-0.8252-3.19824-8.2373-1.2002-9.47363s1.60156-8.24609,3.99316-8.24609
        c2.39551,0,0.40039-3.69922,1.20215-6.1748c0.55176-1.69824-0.59766-2.81396-2.01855-3.3457
        c-0.64258-0.23877-1.33887-0.3584-1.95801-0.3584c-0.00586-0.00146-0.01172-0.00439-0.01855-0.00586l0,0
        c-0.01953-0.00488-0.03809-0.01367-0.05762-0.01855c-0.3457-0.08643-0.68359-0.19238-1.00781-0.32422
        c-2.41895-0.98438-4.17871-3.19385-4.53711-5.83594c0-2.7168-3.55078-6.53271-3.61914-7.99512
        c-0.00391-0.08887,0.00098-0.17139,0.02441-0.24219c-0.21387,1.69727-1.10156,2.04639-2.29492,1.76123
        c-1.91113-0.45752-4.60742-2.5459-6.57031-3.32715c-0.73633-0.29297-1.37012-0.40234-1.82031-0.17139
        c-2.3916,1.23535-4.18848-2.47168-8.98145-1.85059c-3.06836,0.39648-2.9502,2.05322-4.51367,3.52783
        c-0.00098,0-0.00098,0.00049-0.00098,0.00049l0.20215,0.05957l-6.00293,4.53516c0,0-5.18652,1.63965-6.38672,4.94531
        c-1.2041,3.30762,2.40039,6.5957,0.80469,8.65332c-1.24316,2.56641-1.51855,5.4834-0.80469,8.23633
        c0.40039,1.65039-2.39648,6.18066-2.39648,6.18066s-11.90234,6.5957-21.82813,11.82324c0,0.00049,0,0.00098,0,0.00146
        c0.12988,1.72314,0.41016,3.43164,0.82031,5.10986c0.62988,2.27734,1.58887,6.61719,2.3623,10.21484
        c0.00195,0.01123,0.00488,0.02295,0.00684,0.03418c1.26953-0.10449,2.54297-0.15625,3.81738-0.15918
        c1.05664-0.00195,2.1123,0.03857,3.16699,0.10645c0.28613,0.01807,0.57227,0.0293,0.85742,0.05273
        c6.98633,3.43066,13.66016,7.42676,19.99512,11.94238c8.44531,5.74902,16.57813,11.93555,24.375,18.54102
        c3.43066,2.65039,7.64941,4.09375,11.99414,4.12402c1.375,0,7.10938,0.6748,11.73145,1.25684
        c0.00098-0.00293,0.00195-0.00488,0.00293-0.00781c0.9707-2.69922,2.14746-5.31934,3.50195-7.84863
        c0.2373-0.44824,0.46973-0.89941,0.71973-1.34082c1.28125-1.4082,1.5166-3.3623,4.61328-3.83203
        c2.46387-0.375,3.99414,1.27832,6.7041,3.875c1.27148-1.13965,2.78125-1.97559,4.41602-2.43848
        c-2.70605-2.88086-5.94824-6.2168-5.71484-8.20508c0.40137-3.29688-3.2002-4.94434-3.59668-7.00684
        c-0.39746-2.05762,2.40234-5.34961,0.7998-7c-1.49023-1.53857-1.94336-5.2417-4.2793-6.05615
        c-0.15625-0.05273-0.32129-0.09326-0.49512-0.11865C608.09863,375.25439,603.33203,373.02344,600.67676,370.71729z"/>
      <path class="megm-2" d="M612.19238,402.48828c-3.09668,0.46973-3.33203,2.42383-4.61328,3.83203
        c-0.25,0.44141-0.48242,0.89258-0.71973,1.34082c-1.34668,2.53125-2.51953,5.15137-3.49023,7.85059
        c0,0.00098-0.00098,0.00098-0.00098,0.00195l6.23828,0.79785l5.19434,4.53125l8.05469,0.42383v-0.00098
        c-1.90137-4.27051-3.52637-8.66895-4.85645-13.15527l0.90039-1.74414c-0.00098-0.00098-0.00195-0.00195-0.00293-0.00293
        C616.18652,403.7666,614.65625,402.11328,612.19238,402.48828z"/>
      <path class="megm-2" d="M230.36621,266.35449c4.34473-0.61621,3.73242-19.28516,2.09863-19.43848
        c-1.64844-0.15039-2.09863,2.92773-6.28613,5.55469C221.99512,255.0957,226.21094,266.94336,230.36621,266.35449z"/>
      <path class="megm-2" d="M119.4707,173.95215c-1.94629,1.54492-6.14063-2.47363-8.83301-0.46387
        c-2.69434,2.00879-2.84473-1.84961-6.13672-3.0791c-3.28711-1.23145-4.64648,1.53711-9.87109,1.0752
        c-5.24121-0.46582-8.23926,2.62305-6.28711,4.94824c1.95117,2.32324-0.31152,4.46777,1.19238,6.47852
        c1.48633,2.00684,8.38086,1.69141,13.46777,4.46875c5.08691,2.77637,6.28809,0.46191,8.83301,2.47266
        c2.54785,2.00781,3.14258,0.76563,3.29883,4.62793c0.38672,4.39746,3.60742,8.02539,7.93164,8.94336
        c4.94824,1.38672,1.20215,4.7832,2.69238,8.17773c1.49707,3.39258-2.24316,8.33008-1.49023,12.49512
        c0.27246,6.60645-1.39355,13.14746-4.79004,18.81445l-0.59375,0.12109c2.24023,1.14844,4.58936,2.14697,5.74219,2.93359
        c2.18555,1.50781,6.92188,2.99805,9.83105,4.88477c2.91797,1.88477,8.00879,1.49609,8.00879-0.75391
        c0-2.2041,2.8042-0.80566,4.62109,1.01416c0.03955,0.03955,0.08105,0.07764,0.11963,0.11719
        c0.04004,0.04102,0.09521,0.07813,0.13965,0.11768c0.08301,0.07373,0.16211,0.14844,0.26074,0.21729
        c0.13965,0.09766,0.29688,0.18994,0.46484,0.27881c0.01318,0.00684,0.02295,0.01416,0.03613,0.021
        c2.5918,1.34326,8.24805,1.7124,11.11475,2.3667c3.45508,0.38965,6.92676,0.49414,10.39844,0.30762
        c0.00098,0.00244,0.00146,0.00537,0.00195,0.00781c0.00146,0,0.00293,0,0.00391,0l-0.61133-2.68164
        c-1.79395-6.01563,3.4375-9.56445,8.37402-10.34082c6.43359-0.10449,12.80078,1.31836,18.56543,4.16504
        c2.84473,1.85156,6.7373,1.3877,11.67285-4.00488c1.66016-2.00879,3.86426-3.48633,6.34863-4.25879
        c0-0.00098,0.00049-0.00195,0.00049-0.00244c-0.00391,0.00049-0.0083,0.00146-0.01221,0.00244
        c0.24805-1.22168,0.63086-2.40527,1.14063-3.53711c0.04199-0.11572,0.06885-0.22217,0.08203-0.3208
        c0.19971-1.4751-2.69238-1.08057-4.7998-1.29932c-0.19043-0.01514-0.37646-0.05322-0.55957-0.10205
        c-0.04932-0.01318-0.09668-0.0293-0.14502-0.04443c-0.13916-0.04443-0.2749-0.09814-0.40723-0.16162
        c-0.04053-0.01904-0.08203-0.03613-0.12158-0.05713c-0.16211-0.08594-0.32031-0.18164-0.46777-0.29492
        c-0.47656-0.37354-0.80664-0.86035-1.00244-1.38965c-0.0127-0.03369-0.03125-0.06494-0.04297-0.09912
        c-0.04541-0.13721-0.0708-0.27832-0.09668-0.41943c-0.01221-0.06348-0.0332-0.12305-0.0415-0.1875
        c-0.0249-0.20361-0.03076-0.40967-0.01611-0.61475c0-0.00439-0.00098-0.0083-0.00049-0.01221
        c0.01465-0.1792,0.04834-0.35498,0.0918-0.52783c0.01367-0.05615,0.0332-0.10986,0.05029-0.16504
        c0.04004-0.13086,0.08838-0.25781,0.14502-0.38184c0.02246-0.04883,0.04346-0.09717,0.06836-0.14453
        c0.08887-0.16992,0.1875-0.33447,0.30762-0.48584c1.57617-2.54785-1.34668-2.77637-2.91602-5.32227
        c-0.59131-0.95557-0.64502-1.4541-0.40967-1.74072c0.39063-0.48193,1.58643-0.37256,2.4292-0.80713
        c1.34375-0.70117,3.13477-3.46973,2.01953-4.16211c-0.27686-0.17139-0.53809-0.40088-0.76123-0.68896
        c-0.67432-0.85742-1.01123-2.24414-0.39844-4.26221c0.01514-0.04932,0.02051-0.09229,0.03662-0.14258
        c0.396-1.22168,0.09961-1.94971-0.35742-2.55859c-0.32617-0.43457-0.73389-0.80859-1.03076-1.25684
        c-0.23486-0.35498-0.40088-0.75635-0.40088-1.27246c0-0.40186-0.09375-0.75879-0.25732-1.05762
        c-0.68311-1.25146-2.60986-1.45898-4.23584,0.59375c-0.88477,1.11328-1.51221,1.38428-1.82471,0.9873
        c-0.40039-0.5083-0.2832-2.11328,0.47314-4.44824c1.35156-4.1582,6.06152-6.0166,6.50586-8.3291
        c0.65723-2.38574,2.70898-4.13086,5.17188-4.40039c0.72119,0,1.12402-0.62598,1.37842-1.56006V186.75
        c0.45752-1.68115,0.42725-4.37354,0.85986-6.30566c0.30371-3.24121,1.73242-6.27246,4.03711-8.56348
        c0.91455-0.80127,1.18945-1.46484,1.09473-1.9917c-0.18896-1.0498-1.84668-1.55566-2.84229-1.52002
        c-0.09961,0.00391-0.19287,0.0127-0.27686,0.02734c-0.16797,0.0293-0.33252,0.02197-0.49805-0.0127
        c-0.97607-0.20557-1.97412-1.37109-3.79688-1.72949h-91.88574C121.30762,169.78076,120.83838,172.86182,119.4707,173.95215z"/>
      <path class="megm-2" d="M161.6748,291.34375c4.49219,3.08789,9.5791,2.16211,8.8291-3.08691
        C169.75293,283.01465,159.47461,289.83105,161.6748,291.34375z"/>
      <path class="megm-2" d="M159.22363,254.22949c-2.8667-0.6543-8.52295-1.02344-11.11475-2.3667
        c-0.01318-0.00635-0.02295-0.01416-0.03613-0.021c-0.16797-0.08887-0.3252-0.18115-0.46484-0.27881
        c-0.09863-0.06885-0.17725-0.14355-0.26074-0.21729c-0.04443-0.03955-0.09961-0.07666-0.13965-0.11768
        c-0.00146-0.00146-0.00391-0.00293-0.00537-0.00439c-0.03662-0.03809-0.07666-0.07471-0.11426-0.11279
        c-1.81689-1.81982-4.62109-3.21826-4.62109-1.01416c0,2.25-5.09082,2.63867-8.00879,0.75391
        c-2.90918-1.88672-7.64551-3.37695-9.83105-4.88477c-1.15283-0.78662-3.50195-1.78516-5.74219-2.93359
        c-0.00439-0.00244-0.00879-0.00439-0.01367-0.00684c-3.03516,0.61035-11.75098-2.69727-17.51074-1.97656
        c-6.13672,0.77148-25.74707-0.92285-33.37988-0.30566c-7.63379,0.62207-8.07813-3.23535-12.26855-3.23535
        c-4.19434,0-2.39453,5.23828-8.38477,5.39258c-5.99023,0.16309-6.58301,4.00977-2.24805,7.56445
        c2.47656,2.02051,2.18066,6.28906,1.82715,10.19043c1.01465-0.03027,1.98047-0.45117,2.69238-1.1748
        c1.8291-2.24512,3.65137-1.87891,3.65137,0.37695c0,2.25781,4.00098,1.50098,6.1875,1.50098c2.1875,0,1.45215,1.88281,4.00488,0
        c2.5459-1.87793,6.18555-1.12598,6.18555,1.12891c0,1.97754,4.19189,1.35205,3.28223,3.18066
        c-0.12939,0.25977-0.36084,0.56885-0.72266,0.94238c-2.73779,2.81836-6.11084,3.65234-6.5166,5.60156
        c-0.02686,0.12793-0.04102,0.26074-0.04102,0.39941c0,1.8291,2.16455,5.396,1.6001,8.08057
        c-0.12988,0.61816-0.4043,1.18945-0.8833,1.68213c-2.5459,2.63086,0.73535,7.13086-1.81055,7.13086
        c-1.58984,0-3.32959,0.44287-3.69141,1.51123c-0.21533,0.63574,0.05615,1.49219,1.13965,2.60986
        c2.02148,2.08301,3.68457,3.97803,3.7959,4.94824c0.0498,0.43164-0.20508,0.68164-0.875,0.68164
        c-1.37158,0-2.45801,1.77197-2.80811,3.37207c-0.0083,0.0376-0.01221,0.07324-0.01953,0.11035
        c-0.02344,0.11816-0.04736,0.23682-0.06201,0.35156c-0.10059,0.77002-0.0083,1.45068,0.33301,1.80273
        c0.82275,0.84717,3.91357,2.96875,3.68701,4.28516c-0.07471,0.43359-0.50635,0.78027-1.49951,0.96484
        c-2.5083,0.46582-3.44336,1.9668-3.79248,3.5791c-0.20801,0.95996-0.2085,1.95898-0.2085,2.80273
        c0.21484,1.2334,0.71387,2.39551,1.46484,3.39453c1.79492-0.44434,3.68652-0.29883,5.39063,0.41309
        c4.18359,1.99805,6.60938,12.75488,12.2666,12.03223c2.40234-0.30664,1.2002-2.1582,2.40234-3.39844
        c1.2002-1.23242,3.43652-0.30176,5.83887-0.30176c2.39551,0,3.58398-3.55273,9.27148-3.70215
        c5.68652-0.15234,9.28223-0.62305,12.86816-0.46289c3.00684,0.56934,6.0127-0.9502,7.33496-3.70117
        c1.45215-3.14844,4.48242-5.26465,7.93066-5.55469c4.0498-0.61426,3.4502-4.94629,3.74219-7.40332
        c0.2959-2.45801,7.04102-6.17676,7.4873-8.02832c0.44434-1.84863-3.89258-8.94141,0.14648-15.27148
        c4.03906-6.32031,8.37891-6.62793,8.37891-10.18066c0-3.25195,4.19629-4.46777,9.29004-5.08984
        c5.09277-0.62012,6.73145-4.16602,11.07227-6.0166c3.50244-1.31348,5.35449-5.14844,4.20313-8.70801
        c-0.00049-0.00244-0.00098-0.00537-0.00195-0.00781C166.15039,254.72363,162.67871,254.61914,159.22363,254.22949z"/>
      <path class="megm-2" d="M282.66602,214.21094c0.2251-0.08691,0.48535-0.12646,0.76904-0.13184
        c1.41846-0.0293,3.42822,0.78906,4.60254,0.81592c0.47021,0.01074,0.80615-0.10547,0.9165-0.45361
        c0.4541-1.38672,1.80469-4.62793,3.59863-4.62793c1.79785,0,0.66895-4.8584,2.47266-5.08691
        c0.80664-0.10547,2.1499-1,3.38818-1.95508c1.14941-0.88623,2.20313-1.81982,2.65479-2.22852
        c0.14746-0.13379,0.23633-0.21582,0.23633-0.21582s-0.13086-0.26953-0.33203-0.66211
        c-0.09277-0.18066-0.19922-0.38574-0.31689-0.60693c-0.56787-1.06641-1.3623-2.44043-1.78076-2.64063
        c-0.01123-0.00537-0.02441-0.01709-0.03516-0.021c-0.67871-0.23535-2.92285,2.30469-6.96191,2.30469
        c-4.04395,0-6.96289,3.23828-8.75684,3.23828c-1.79883,0-7.40234-1.85059-11.2207-2.0791l-4.05273-0.3291
        c-0.00049,0.00342-0.00049,0.00732-0.00098,0.01074c0.00049,0,0.00049,0,0.00098,0
        c-0.3877,3.44336-0.58594,6.90332-0.59424,10.36523c-0.00244,1.15186,0.01563,2.30371,0.05518,3.45605
        c2.00488-0.97949,4.27637-1.27539,6.46191-0.84375c1.1748,0.48535,1.56543,1.74561,1.76758,3.10986v0.00049
        C277.94434,215.32422,280.32813,214.84863,282.66602,214.21094z"/>
      <path class="megm-2" d="M370.00293,341.00684c4.78906,1.84082,9.75,3.18848,14.80957,4.01172
        c5.8418,0.16602,13.77539-2.77637,13.62598-4.31641c-0.14648-1.54199-3.89453,0.92871-6.27832-0.46094v-0.00195
        c-2.4043-1.38574-13.18262,0.92676-16.02441-1.84961C373.29883,335.61133,367.49609,340.58008,370.00293,341.00684z"/>
      <path class="megm-2" d="M400.60352,262.41357c-0.01465,0.00146-0.0293,0.00342-0.04395,0.00537
        c-0.97754,0.10449-2.10742,0.32568-3.0332,0.64844c-1.20508,0.42041-2.0625,1.0127-1.78027,1.74316
        c0.60254,1.54395-1.49805,2.46973-3.89063,2.46973c-0.00146,0-0.00293-0.00049-0.00391-0.00049
        c-3.64648-0.20508-7.26514-0.82715-10.77148-1.85596c-4.49902-0.68457-9.06738-0.73828-13.58398-0.16309l-0.00098,0.00195
        l-0.00342,0.00879l-0.18408,0.4668c-0.90332,1.38574-8.07715,5.16309-9.43164,4.46777
        c-1.35254-0.69238-2.01855,2.30859-4.70703,3.00781c-0.13965,0.03613-0.28418,0.06543-0.43018,0.09326
        c-0.05713,0.01123-0.11426,0.02197-0.17236,0.03174c-2.35645,0.39893-5.35303-0.11816-6.15723,0.50195
        c-0.10498,0.08057-0.18018,0.17676-0.2041,0.30078c-0.79004,2.22461-2.0127,4.26758-3.5918,6.01367
        c-1.57422,1.61914-0.22461,3.47168-2.46973,3.93555c-0.09277,0.01953-0.18262,0.04883-0.26855,0.08691
        c-1.29639,0.5752-1.88232,3.2002-2.64844,5.54297c0,0.00146-0.00049,0.00244-0.00098,0.00391l0.44434,0.22852
        c3.58008,1.4541,5.77441,5.09961,5.38281,8.94629c-0.44922,3.86035,4.7998,5.24902,7.1875,2.47363
        c2.38867-2.77539,9.12891,1.53809,9.12891,3.38965s-6.88574-2.00293-10.7793,0.92578
        c-3.88965,2.92773,2.99512,5.70508,2.10156,8.79199c-0.74414,3.19141,1.24023,6.38574,4.43262,7.12695
        c0.27051,0.05957,0.53711,0.10254,0.80078,0.125c3.44922-0.14746,7.33984,5.70801,9.43164,4.62793
        c2.0957-1.07715-2.5459-9.25586-1.79395-10.49023c0.75-1.23926,4.94238,2.16211,7.0293-0.31055
        c2.0957-2.47266-1.49414-4.31543-4.6377-4.16113c-3.14355,0.14746-0.14355-4.31836,3.59863-2.00488
        c3.74121,2.3125,5.52734-0.61719,7.32617-0.92578c1.79883-0.31055,2.24805-6.94141-3.28906-8.48535
        c-5.54004-1.53809-4.79688,4.16602-8.38574,0.30469c-3.59375-3.84961,3.30078-2.30566,3.30078-5.55371
        c0-3.24121-5.53613-7.86719-8.24316-11.71875c-2.69043-3.85645,3.75098-5.70703,5.23828-2.93262
        c1.50684,2.77832,5.5459,2.3125,8.08984,1.2334c2.53223-1.07715-2.69141-6.0166,1.93945-7.86719
        c4.63184-1.85254,4.64648,1.38867,6.13965,2.0127c1.49707,0.61621,2.8418-3.86523,7.63086-3.86523
        c2.57813,0.35547,4.97461,1.52637,6.83691,3.34766c1.29492-1.47461,2.37012-2.3291,2.37012-3.58008
        c0-1.61816,2.91504-2.30469,2.91504-4.85645c-0.0498-0.67529-0.14355-1.34668-0.27344-2.01025
        c-0.13867-0.70459-0.31836-1.40088-0.54102-2.0835C400.60645,262.41309,400.60547,262.41357,400.60352,262.41357z"/>
      <path class="megm-2" d="M460.54785,347.80273c5.38965,3.23828,14.07324-1.54199,13.77637-3.24121
        c-0.31055-1.69922,1.93848-5.24316,5.38379-6.63086c3.44922-1.38965,2.0918-4.01172-0.15723-2.00879
        c-4.21875,2.91895-9.23242,4.47754-14.36523,4.47461C458.44922,340.70508,456.35352,345.27637,460.54785,347.80273z"/>
      <path class="megm-2" d="M794.66406,241.23145c-6.80957,0.1543-13.61523-0.11914-20.38379-0.82324
        c-2.00879-0.8252-12.39063,3.29395-13.19922,0.82324c-3.3252-4.95898-7.30957-9.44531-11.83301-13.33496
        c-0.43945,1.81738-1.49414,3.41895-3.00586,4.51953c-7.48633,5.55371-14.6582,4.62793-12.26367-2.16406
        c2.3916-6.78809,2.68945-14.80957-1.20215-14.80957c-3.89355,0-1.19238,14.19824-4.19336,15.12402
        c-2.63965,0.82617-3.66309-10.30273-1.44336-16.13428l-25.59961,7.03564l-1.19434,46.14063c0,0-0.04492,0.03906-0.11328,0.09375
        c2.24414,1.01172,4.64453,1.60645,7.10449,1.73438c3.8877,0.30566,3.59375-2.77734,3.59375-4.93848
        c0.99316-3.16699,3.86621-5.3877,7.18555-5.55371c2.99609,0.3125,2.68945-4.00879,3.58789-4.00879
        c1.65137-0.07031,3.17676-0.85645,4.18359-2.15723c0.72949-1.25537,4.03418-0.26904,6.04785,0.97363
        c0.4707,0.28955,0.87402,0.59326,1.1582,0.88574c0.29004,0.1875,0.58496,0.36621,0.88379,0.53662
        c2.12012,1.2002,4.45703,1.96289,6.88184,2.23096c0.50586,0.11523,0.94922,0.51465,1.33398,1.09814
        c1.6709,2.51953,2.26465,8.51123,2.26465,10.01807c0,0.17139,0.11133,0.32422,0.31348,0.46094
        c2.00391,1.33008,12.75195,1.09717,14.64844,1.38379c0.2666,0.03857,0.49805,0.13281,0.70605,0.27246
        c1.43262,0.94824,1.65234,4.08691,3.49219,6.51465c1.83789,2.68848,3.34473,5.59082,4.4873,8.64355
        c0.05566,0.13428,0.1377,0.27051,0.24121,0.40918c0.06152,0.08301,0.14551,0.16797,0.22266,0.25195
        c0.05176,0.05615,0.09277,0.11133,0.15039,0.16846c0.6748,0.6626,1.76855,1.37012,2.98438,2.10596
        c2.22363,1.34473,4.86621,2.78809,6.16602,4.24316c0.00195,0.00293,0.00586,0.00586,0.00781,0.0083
        c0.11816,0.13232,0.21387,0.26514,0.30762,0.39746c0.02832,0.04053,0.06738,0.08105,0.09375,0.12158
        c0.71582,0.73633,1.47656,1.42139,2.2666,2.06885c0.10059,0.08203,0.19531,0.17041,0.29688,0.25098
        c2.69629,2.146,5.78516,3.76758,9.10645,4.77002c1.31055,0.48486,2.5918,1.39063,3.80664,2.39648
        c1.36035,1.12598,2.63672,2.37158,3.78125,3.30273c0.91504,0.74268,1.74609,1.28174,2.46289,1.38184v-62.00195
        C798.15625,243.81592,796.59961,241.23145,794.66406,241.23145z"/>
      <path class="megm-2" d="M672.24219,599.45996c3.36426,3.57813,9.95313-0.59668,10.29492-2.02832
        C682.88184,596.00195,668.46387,595.43164,672.24219,599.45996z"/>
      <path class="megm-2" d="M657.35254,527.41309c0,0-0.00098-0.00098-0.00098-0.00195c-4.60938,1.4668-8.32813,2.5918-9.76367,2.92871
        c-5.21289,1.19141-18.23535,0.28516-19.96387,1.19141c-5.99023,3.71973-11.27539,8.46387-15.62109,14.01465
        c-1.73438,4.17676-4.33496,4.77148-6.94336,2.68359c-2.60352-2.08887-13.31152-1.4834-14.16895-2.38867
        c-4.00879-1.29102-8.25391-1.70117-12.44141-1.18848c-3.75488,0.5957-5.78809-1.79297-7.5166-1.19434
        c-1.77051,0.875-3.25977,2.21582-4.33984,3.87598c-0.07617,0.10352-0.12598,0.22852-0.16602,0.36133
        c-0.35645,1.19629,0.62793,3.46387,0.5752,4.76465c-0.00781,0.20801-0.04297,0.3916-0.11426,0.54199
        c-1.4541,1.04785-3.01758,1.93848-4.67383,2.63379c0.97754,1.99414,1.28223,4.25195,0.8584,6.42969
        c-1.05273,5.7373,0.00977,11.65234,2.99023,16.65723c3.13867,4.78027,1.93945,13.56934,3.4375,15.26855
        c6.17969,1.75781,12.80273,0.97852,18.41113-2.15918c3.74316-2.81934,8.33789-4.29004,13.02344-4.1709
        c4.2334-0.46094,8.16602-2.4375,11.06836-5.54785c1.3457-1.85156,7.48828-2.00391,10.03711-1.68945
        c2.54297,0.30273,5.08398-2.62695,7.92285-5.55566c6.75977-3.40332,13.96875-5.84961,21.39648-7.25488
        c9.58984-3.07324,7.48633-7.71387,9.73438-10.48828c1.87988-2.32813,4.51563-1.6543,8.64844-3.35742
        c0.00195,0,0.00293,0,0.00391-0.00098C665.10156,543.94336,659.94043,532.94141,657.35254,527.41309z"/>
      <path class="megm-2" d="M793.75586,302.98926c-1.21289-1.00342-2.49121-1.90527-3.79785-2.3877
        c-3.32129-1.00293-6.41309-2.62793-9.11523-4.77881c-0.10156-0.08057-0.19629-0.16895-0.29688-0.25098
        c-0.78711-0.64551-1.54688-1.32861-2.26172-2.06201c-0.02734-0.04297-0.06836-0.08545-0.09863-0.12842
        c-0.09375-0.13232-0.18945-0.26514-0.30762-0.39746c-0.00195-0.00244-0.00586-0.00537-0.00781-0.0083
        c-1.2998-1.45508-3.94238-2.89844-6.16602-4.24316c-1.21777-0.73633-2.31152-1.44385-2.98438-2.10596
        c-0.05762-0.05713-0.09863-0.1123-0.15039-0.16846c-0.07715-0.08398-0.16113-0.16895-0.22266-0.25195
        c-0.10352-0.13867-0.18555-0.2749-0.24121-0.40918c-1.14258-3.05273-2.64941-5.95508-4.4873-8.64355
        c-1.83984-2.42773-2.05957-5.56641-3.49219-6.51465c-0.20508-0.13525-0.43457-0.22607-0.69629-0.26367
        c-1.90527-0.28516-12.68066-0.05273-14.6582-1.39258c-0.20215-0.13672-0.31348-0.28955-0.31348-0.46094
        c0-1.50684-0.59375-7.49854-2.26465-10.01807c-0.38379-0.57959-0.8252-0.97607-1.3291-1.09131
        c-2.43066-0.271-4.77246-1.03516-6.88672-2.23779c-0.29883-0.17041-0.59375-0.34912-0.88379-0.53662
        c-0.28418-0.29248-0.6875-0.59619-1.1582-0.88574c-2.01758-1.23828-5.30176-2.21777-6.0332-0.96387
        c-1.01172,1.2959-2.54199,2.08301-4.18457,2.15625c-0.90332,0-0.59668,4.32422-3.59668,4.00879
        c-3.31934,0.16602-6.18848,2.38379-7.17676,5.55371c0,2.16113,0.29395,5.24414-3.59863,4.94238
        c-2.45801-0.1377-4.87012-0.72656-7.11719-1.74463c-0.07324,0.05859-0.18262,0.1377-0.3252,0.23047
        c-0.01367,0.00879-0.02832,0.01807-0.04297,0.02686c-0.05273,0.03369-0.10449,0.06641-0.16504,0.10156
        c-0.77344,0.45752-2.21094,1.00537-3.87891,0.25098c-0.01465-0.00635-0.0293-0.0127-0.04395-0.01953
        c-0.18848-0.0874-0.37891-0.19385-0.57324-0.31738c-0.02441-0.01563-0.04883-0.0293-0.07422-0.0459
        c-0.1875-0.12402-0.37793-0.26904-0.56934-0.43115c-0.02832-0.02344-0.05566-0.04297-0.08301-0.06738
        c-0.20313-0.17725-0.40723-0.37793-0.6123-0.60254c-0.01465-0.01514-0.02832-0.02734-0.04297-0.04346
        c-0.22168-0.24609-0.44434-0.51563-0.66699-0.82227c-3.60547-4.94922-9.20313-9.89453-12.38672-9.89453
        c-1.26074,0-3.86816,0.00439-6.20605,0.48389c-0.03613,0.00732-0.07031,0.01563-0.10645,0.02344
        c-0.12793,0.02686-0.25586,0.05469-0.38281,0.08496c-2.78223,0.66748-5.00098,2.08496-3.76172,5.09424
        c0.7002-1.26416,2.04395-2.10791,4.71484-1.87598c7.17871,0.61426,3.88672,9.25879,7.7832,9.56543
        c3.8877,0.30469,8.37695,6.47754,4.77734,6.16504c-3.58008-0.30762-6.58398,3.39453-10.16699,2.16309
        c-3.59375-1.23242-8.08691-4.32617-9.28809,0c-1.20215,4.32031-2.69531,8.94336,2.09766,8.62891
        c4.79688-0.30176,3.8877,3.0918,0.59668,6.48633c-3.28711,3.39258,5.09082-0.61621,5.69238,2.46582
        c0.59473,3.08887,3.28516,3.39258,2.09277,8.33203c-0.47461,4.6416-0.46973,9.32324,0.01367,13.96484
        c0.00098,0,0.00098,0,0.00195,0c-0.00098-0.00537-0.00098-0.01123-0.00195-0.0166c3.1748-0.02148,7.45117-0.30957,8.36523-1.62598
        c1.50195-2.15625,6.28711-6.47852,8.98145-6.47852c2.68945,0,5.67871,1.22754,7.17676-0.92578
        c1.51172-2.15723,10.18359-1.54199,11.68066,0.30762c2.11914,1.82617,4.70898,3.00391,7.47949,3.39258
        c1.20215,0,1.79785,2.77734,4.79883,2.4707c2.80859-0.29053,7.98242,0.78174,8.60645,2.44482
        c0.0459,0.11523,0.07227,0.23242,0.07227,0.35303c0.04102,0.27002,0.10645,0.53027,0.19336,0.78027
        c0.62891,1.77393,2.34961,2.98193,4.29199,2.90234c2.39453,0,4.47949,6.16113,6.88184,5.85742
        c2.25586-0.28418,6.6084-0.30127,7.12891,0.97217c0.03906,0.08789,0.06152,0.18115,0.06152,0.28174
        c0.0459,1.81201,0.19824,3.6167,0.41699,5.4126c0.30469,2.47217,0.75098,4.92578,1.37891,7.34033
        c-0.00098,0.00049-0.00195,0.00146-0.00293,0.00195c0.00098,0.00146,0.00098,0.00293,0.00098,0.00439
        c0.09473-0.05176,0.18945-0.09814,0.28418-0.13916c0.18457-0.09229,0.375-0.15771,0.57422-0.19434
        c0.02734-0.00635,0.05566-0.01318,0.08301-0.01855c0.05664-0.0083,0.11328-0.01904,0.1709-0.02295
        c0.41602-0.05176,0.78711,0.00342,1.04492,0.18018c1.20313,0.8252,6.40039-0.82129,7.19824,2.06152
        c0.19238,0.68799,0.82324,1.11133,1.65723,1.30566c2.66016,0.61816,7.44336-1.15674,7.13477-4.60449
        c-0.40137-4.5332,13.19238-4.5332,14.39063-8.64551c0.0752-0.25781,0.15137-0.52832,0.22949-0.80859
        c1.17383-4.20703,2.76953-10.65527,5.76758-9.49707c2.10645,0.81689,3.69336-1.76807,5.33203-3.50635v-9.55762
        c-0.7168-0.10303-1.54785-0.64453-2.46289-1.38818C796.39258,305.36084,795.11621,304.11523,793.75586,302.98926z"/>
      <path class="megm-2" d="M765.08691,446.80957c2.86133-1.51465,5.97168-2.49316,9.19336-2.88672
        c3.20215-0.40234,0-6.99805-2.40527-7.82324c-2.40137-0.8252,0-9.48047-4.78516-11.54199
        c-4.49805-1.58496-8.21875-4.82813-10.4082-9.05762c-0.58887-1.03613-1.27148-2.01074-2.03223-2.9209
        c-0.86523-0.91211-1.83984-1.80371-2.6875-2.6582c-0.00879-0.00684-0.0166-0.01563-0.02539-0.02246c0.00098,0,0.00195,0,0.00293,0
        c-0.14941-0.15039-0.2959-0.30078-0.43555-0.44922c-0.15234-0.16211-0.29785-0.32227-0.43555-0.48047
        c-0.01465-0.01758-0.02734-0.03418-0.04199-0.05078c-0.13477-0.1582-0.25977-0.31348-0.37598-0.46777
        c-0.02148-0.0293-0.04004-0.05762-0.06055-0.08691c-0.10254-0.13965-0.19434-0.27734-0.27637-0.41406
        c-0.02148-0.03516-0.04102-0.07031-0.06055-0.10547c-0.07324-0.12988-0.13477-0.25781-0.18652-0.38379
        c-0.01563-0.03711-0.03223-0.07422-0.0459-0.11035c-0.04492-0.12598-0.07715-0.24902-0.09863-0.37109
        c-0.00586-0.03418-0.0166-0.06934-0.02148-0.10254c-0.0166-0.14453-0.01855-0.28809,0.00293-0.42773
        c0.00098-0.00781-0.00098-0.0166,0-0.02441c0.37109-2.31934,10.56543-9.32813,10.53809-13.93311
        c-0.00391-0.3042-0.05078-0.59766-0.15039-0.87842c-1.59961-4.52734-9.19336-3.70605-9.99121-5.35547
        c-0.10742-0.22217-0.17676-0.52441-0.22363-0.8833c-0.22168-1.63232,0.05176-4.55176-0.39063-7.51563
        c-0.18164-1.20215-0.48145-2.41113-0.98145-3.54346c-0.37695-0.854-0.55371-1.69385-0.60449-2.51123
        c-0.19141-2.94385,1.26172-5.5957,0.98633-7.56006c-0.05469-0.37793-0.17188-0.73145-0.38184-1.05469
        c-0.40137-0.61816-0.55078-1.39111-0.54395-2.22852c0.00977-2.51563,1.43945-5.61914,1.74316-6.85449
        c0.37207-1.53662,3.90332-3.81445,4.02148-7.4834c0.00684-0.2417,0-0.48926-0.02441-0.74316
        c-0.00098-0.00879,0-0.0166-0.00098-0.02588c-0.16797-1.03857,0.03711-2.06836,0.52051-2.95068
        c0.05371-0.10352,0.10938-0.20313,0.16992-0.29932c0.01953-0.03125,0.04004-0.0625,0.06055-0.09375
        c0.40234-0.61035,0.91406-1.06348,1.44238-1.35889c0.01367-0.0083,0.02539-0.01904,0.03906-0.02734
        c0.00098-0.00049,0.00195-0.00146,0.00293-0.00195c-0.62793-2.41455-1.07422-4.86816-1.37891-7.34033
        c-0.22168-1.80273-0.37598-3.61328-0.42578-5.43115c0-0.09326-0.01953-0.18066-0.05273-0.26318
        c-0.52051-1.27344-4.87305-1.25635-7.12891-0.97217c-2.40234,0.30371-4.4873-5.85742-6.88184-5.85742
        c-1.94238,0.07959-3.66309-1.12842-4.29199-2.90234c-0.09082-0.25586-0.15918-0.52295-0.20215-0.7998
        c0-0.11377-0.02246-0.22461-0.06348-0.3335c-0.62402-1.66309-5.79785-2.73535-8.60645-2.44482
        c-3.00098,0.30664-3.59668-2.4707-4.79883-2.4707c-2.77051-0.38867-5.36035-1.56641-7.47949-3.39258
        c-1.49707-1.84961-10.16895-2.46484-11.68066-0.30762c-1.49805,2.15332-4.4873,0.92578-7.17676,0.92578
        c-2.69434,0-7.47949,4.32227-8.98145,6.47852c-0.91406,1.31641-5.19043,1.60449-8.36523,1.62598
        c0.00098,0.00537,0.00098,0.01123,0.00195,0.0166c0.26172,1.81982,0.14941,3.67822-0.31152,5.45605
        c-6.75391,3.47168-14.26953,5.17285-21.84961,4.93164c-8.68359,0.30957-9.28027-11.41113-18.56445-11.41113
        c-6.60254-0.44434-11.8418-5.71875-12.24609-12.32373c-2.5-0.98145-4.92188-2.40283-6.24902-3.60791
        c-0.80176-0.72461-1.20996-1.37158-1.00293-1.79785c0.67188-1.38525,2.18164-8.90723-0.21094-9.77686
        c-0.44727-0.15283-1.0293-0.07764-1.77441,0.30713c-1.80078,1.28711-3.63379,2.52441-5.49316,3.72168
        c-1.97266,1.4292-4.1543,2.90137-6.54199,3.96191c-0.03125,0.01758-0.06152,0.03613-0.09277,0.05371
        c-0.08496,0.03857-0.17383,0.06494-0.25977,0.10205c-0.26758,0.11279-0.53711,0.2168-0.81055,0.31787
        c-0.24805,0.09229-0.49512,0.18555-0.74805,0.26514c-0.41504,0.13379-0.83789,0.24609-1.26465,0.34717
        c-0.04492,0.01025-0.08789,0.02441-0.13184,0.03369c-1.34277,0.30664-2.74414,0.42529-4.2002,0.31494l-0.84277-0.05078
        c-3.8584-0.54346-4.79785-1.00781-5.00488-1.53076c-0.1582-0.37402,0.06934-0.77881-0.125-1.26563
        c-0.15918-0.3833-0.58398-0.81689-1.67285-1.32568c-1.10254-0.51563-1.74805-1.21436-2.17188-1.9834
        c-0.00586-0.00977-0.01074-0.02002-0.0166-0.03027c-0.09863-0.18213-0.18848-0.36719-0.26563-0.55615
        c-0.00977-0.02344-0.01758-0.04688-0.02734-0.0708c-0.05469-0.13867-0.10449-0.2793-0.15137-0.41992
        c-0.27539-0.82861-0.4248-1.67725-0.68262-2.43213c-0.00293-0.01025-0.00586-0.02051-0.00977-0.03076
        c-0.00391-0.01416-0.01074-0.02686-0.01465-0.04102c-0.18066-0.51221-0.42383-0.9751-0.79688-1.35645
        c-0.03027-0.03125-0.06055-0.06396-0.09375-0.09424c-0.40918-0.37939-0.96484-0.66846-1.76367-0.81787h-0.95117
        c-0.00391-0.0166-0.01074-0.03223-0.01563-0.04883c0,0-0.00098,0.00049-0.00195,0.00049
        c-2.49902,0.23438-1.01953,4.08447-1.96582,5.14209c-0.00098,0.00049-0.00098,0.00098-0.00098,0.00195
        c-0.07129,0.0791-0.15918,0.13916-0.25977,0.18359c-0.00684,0.00342-0.01172,0.00879-0.01855,0.01172
        c-0.10938,0.04395-0.23535,0.06836-0.38379,0.06836c-0.38965,0-0.65332,0.2002-0.83496,0.51904
        c-0.7207,1.27002,0.0166,4.63135,0.70117,6.72559c0.19824,0.60498,0.39063,1.10303,0.54199,1.4126
        c0.02051,0.04297,0.03125,0.11084,0.04785,0.16357c0.50488,1.59229-0.84863,7.49268-0.57227,9.9209
        c0.06543,0.5791,0.22461,0.95996,0.52441,1.03564c2.47266,2.13281,4.16797,5.03711,4.78418,8.24609
        c-0.01563,0.04883-0.0127,0.10938-0.01563,0.16699c-0.05859,1.40088,3.62012,5.30127,3.62012,8.06543
        c0.36035,2.64307,2.1084,4.84863,4.52344,5.83496c0.32422,0.13184,0.66211,0.23779,1.00781,0.32422
        c0.01953,0.00488,0.03809,0.01367,0.05762,0.01855c0.00684,0.00146,0.0127,0.00439,0.01855,0.00586
        c0.61914,0,1.31543,0.11963,1.95801,0.3584c1.42676,0.5293,2.58496,1.64697,2.03711,3.35352
        c-0.80273,2.47266,1.19434,6.1709-1.20215,6.1709c-2.40527,0-2.00391,7.00781-3.99512,8.24609
        c-1.99512,1.24023-0.39453,8.64941,1.20215,9.47168c1.60156,0.82813,5.18945,2.88867,4.79004,5.77051
        c-0.12402,0.90137,0.64648,1.96094,1.84961,3.00537c2.65527,2.30615,7.42188,4.53711,9.34961,4.8208
        c0.17383,0.02539,0.33887,0.06592,0.49512,0.11865c2.35547,0.79688,2.79395,4.51709,4.29297,6.06494
        c1.59863,1.64746-1.19629,4.94531-0.79492,7c0.39355,2.05371,3.99805,3.70508,3.5918,7.00488
        c-0.24219,1.99023,3.0127,5.32617,5.71484,8.20801l2.45996-0.44141c2.69043,0,2.54297-3.08203,4.63965-1.23047
        c2.0918,1.85352,4.94727-0.16504,7.03418-0.30957c2.08008-0.14844,7.77637,10.95605,10.32617,16.34863
        c2.5459,5.40137,2.39355,7.25488,5.83691,9.56055c3.4502,2.30957,7.33008,1.08008,12.27441,6.3291
        c6.64746,5.1875,14.64941,8.3457,23.04492,9.09863c5.38574,0.30664,9.42676-6.01855,16.32422-4.63086
        c6.88574,1.38965,5.22559,10.49023,7.77637,13.73145c11.53613,3.3584,23.36426,5.63574,35.32324,6.79199
        c3.48828,0.64258,7.04102,0.96289,10.58301,0.94336l-0.25781-2.6084C758.58594,453.01855,761.07227,448.85059,765.08691,446.80957
        z"/>
      <polygon class="megm-2" points="604.2793,297.15674 604.28516,297.16699 604.2793,297.15674 		"/>
      <path class="megm-2" d="M606.1748,168.85156c-0.90332,0.46387-3.13574,5.08789-2.25,6.47656
        c0.02539,0.03516,0.05957,0.06689,0.09082,0.1001c0.0332,0.03809,0.0625,0.07715,0.10254,0.11279
        c0.97949,0.81348,3.79492,0.91113,5.03711,2.12207c0.46387,0.43457,0.72266,1.01514,0.6084,1.83203
        c-0.32617,2.40332-0.65723,4.80176,0.28906,5.50488c0.33008,0.22314,0.80762,0.25195,1.48047,0.02148
        c2.3418-0.59277,4.7793-0.75,7.17676-0.4668c0.45703,0,0.88477,0.24121,1.32031,0.65869
        c1.28906,1.20947,2.63477,3.99219,4.99902,6.7749c3.14453,3.70801,4.49414,5.55566,1.34375,6.47949
        c-2.54297,0.75342-2.13965,2.10742,0.24902,3.57764c0.55273,0.3374,1.20996,0.68115,1.95996,1.0249l0.04492,0.02686
        c0.9502,0.46875,1.85156,1.0293,2.71582,1.64111l0.54785,0.33105c-0.01074,0.00732-0.02246,0.01465-0.0332,0.02197
        c0.01074,0.0083,0.02246,0.01465,0.0332,0.02295l1.82715-1.39648c5.65625-5.42871,13.46289-8.03711,21.25-7.10059
        c6.88086,1.85059,12.57031-1.22754,15.56543,3.09277c2.99512,4.31445,0,12.33789,1.18945,15.4209
        c1.19531,3.08398-5.38477,3.39355-9.87402,2.77148c-4.49219-0.61133-13.77441,1.85547-12.57324,4.63086
        c1.19922,2.77832,4.49219,9.26367-1.48926,5.86426c-5.99316-3.39551-8.38477,3.39941-5.09082,3.39941
        c3.74609,0.44141,6.83887,3.14258,7.7793,6.79297c0,2.77539,0.29199,8.94434,3.29297,8.01855
        c2.99414-0.93066,2.99414,4.62793,7.18164,4.32715c4.18848-0.30469,8.08105,1.23242,4.79688,5.23828
        c-3.29883,4.0166,2.08496,15.12207,3.28418,12.64648c0.75684-1.5498,0.09766-4.91699,1.27637-7.0625c0,0,0-0.00049,0-0.00098
        c-1.23926-3.00928,0.97949-4.42676,3.76172-5.09424c0.12598-0.03027,0.25488-0.05762,0.38281-0.08496
        c0.03613-0.00781,0.07031-0.01611,0.10645-0.02344c2.34082-0.48145,4.95215-0.48584,6.20703-0.48584
        c3.19434,0,8.78906,4.94531,12.38574,9.89258c0.22266,0.30664,0.44531,0.58057,0.66699,0.82617
        c0.01465,0.01611,0.02832,0.02832,0.04297,0.04346c0.20508,0.22412,0.40918,0.42529,0.6123,0.60254
        c0.02734,0.02441,0.05469,0.04395,0.08301,0.06738c0.19141,0.16162,0.38184,0.30713,0.56934,0.43115
        c0.02539,0.0166,0.0498,0.03027,0.07422,0.0459c0.19336,0.12305,0.38477,0.23047,0.57324,0.31738
        c0.01465,0.00684,0.0293,0.01318,0.04395,0.01953c1.6543,0.74561,3.08887,0.20703,3.87891-0.25098
        c0.06055-0.03516,0.1123-0.06787,0.16504-0.10156c0.01465-0.00879,0.0293-0.01807,0.04297-0.02686
        c0.14258-0.09277,0.25195-0.17188,0.3252-0.23047c0.00195-0.00098,0.00195-0.00146,0.00391-0.00244
        c0.06836-0.05469,0.11328-0.09375,0.11328-0.09375l1.19434-46.14063l25.59961-7.03564l0.00488-0.00146l0.84863-1.75879
        c3.29492-4.9375,7.71777-0.85254,9.28027,0.31152c1.31152,0.96875,0.59473-5.24316,4.19336-4.94531
        c3.59473,0.29785-0.29785,6.16504,2.3916,6.16504c2.10645,0,6.8916,7.66699,5.39355,13.68945
        c4.52539,3.88477,8.51367,8.37402,11.84277,13.32813c0.7998,2.47266,11.19043-1.64844,13.19043-0.82227
        c6.76758,0.70508,13.57422,0.97754,20.38281,0.82227c1.93652,0,3.49219,2.57813,5.33203,4.43359v-79H609.31738
        C608.51465,167.64502,606.84766,168.50537,606.1748,168.85156z M757.08301,206.06152
        c-1.20605,1.69238-13.04492-1.46777-14.02832-3.95605C741.99316,199.43652,759.66406,202.43262,757.08301,206.06152z"/>
      <path class="megm-2" d="M537.65527,169.25488c0.01758,0.08057,0.02734,0.16309,0.02734,0.24902
        c0,1.38672-3.14648,1.38672-3.59668,2.54492c-0.08789,0.22998-0.06641,0.47852,0.02344,0.73828
        c0.3584,1.04492,1.86133,2.26904,2.22461,3.19531c0.00391,0.00977,0.00391,0.02344,0.00684,0.03369
        c0.39746,1.19434-1.80469,5.06494-1.80469,6.2124c0,1.15918-6.7373-0.23145-8.30566-0.23145
        c-1.57031,0-6.2832,5.32324-7.85449,5.55273c-1.07129,0.16553-0.88574,1.83789-0.51855,3.61719
        c0.0918,0.44482,0.19141,0.89502,0.29004,1.33203c4.83984-0.95508,9.79883-1.3457,10.32715-0.23926
        c0.90234,1.85156-10.47559,6.79102-12.56641,7.55371c-2.10156,0.77051,4.03906,4.01758,3.89453,5.86816
        c-0.14844,1.84863-6.58398,2.30469-5.99609,6.01465c0.59473,3.71094-2.09473,4.47461-5.53125,4.47461
        c-3.44922,0-4.34863,2.15723-2.69434,2.47559c1.64648,0.31738,1.49414,4.93652,7.93359,6.62793
        c7.40723,2.93945,13.9209,7.78223,18.85059,14.04102c0.77637,0.83789,1.66309,1.55273,2.6416,2.12793
        c1.16309-0.80371,2.4375-1.41992,3.79395-1.82129c4.3291-0.06836,8.57715,1.22363,12.12109,3.7041
        c3.14648,2.30762,14.3623-0.46582,15.70801,1.38574c1.45215,2.17969,3.71875,3.68262,6.29199,4.16504
        c2.24805,0,4.48242,4.62793,6.28711,3.23926c3.31836-1.13965,6.83105-1.61328,10.32715-1.3877
        c1.79199,0,1.79199,1.3877,3.59375,1.3877c0.45703,0,0.79688,0.45459,1.07324,1.11963
        c0.53711,1.29541,0.83105,3.39697,1.26563,4.55371c0.2168,0.57764,0.46777,0.92041,0.80273,0.80615
        c1.3457-0.45996,6.28809,4.16504,9.42871,4.625c3.1377,0.46289,6.28027,6.01758,7.62988,5.55469s3.5957,2.31055,4.48438,0
        c0.85938-2.18945,5.29492-1.12207,6.5332-4.93164c-5.05078-5.22949-9.00293-11.42188-11.61133-18.20508
        c0.29883-5.24121,2.38477-8.63574-3.89844-14.18066c-6.27832-5.55078,0.60352-9.25586,5.0957-15.12207
        c4.00879-5.2334,12.5127-7.52539,17.92383-11.24463c0.01074-0.00732,0.02246-0.01465,0.0332-0.02197l-0.54785-0.33105
        l-2.71582-1.64111l-0.04492-0.02686c-0.75-0.34375-1.40723-0.6875-1.95996-1.0249
        c-2.42383-1.48096-2.83789-2.84814-0.27441-3.60205c3.1377-0.92578,1.79199-2.77734-1.3457-6.47949
        c-2.34766-2.76025-3.69238-5.52393-4.97168-6.75049c-0.43555-0.41748-0.86328-0.65869-1.32031-0.65869
        c-2.39746-0.2832-4.83496-0.12598-7.17676,0.4668c-0.67285,0.23047-1.15039,0.20166-1.48047-0.02148
        c-0.99121-0.6709-0.65625-3.09814-0.32129-5.53516c0.1123-0.79834-0.13281-1.37012-0.57617-1.80176
        c-1.24219-1.21094-4.05762-1.30859-5.03711-2.12207c-0.04102-0.03516-0.06836-0.07568-0.10254-0.11279
        c-0.03125-0.0332-0.06543-0.06494-0.09082-0.1001c-0.00684-0.01025-0.01953-0.01855-0.02637-0.0293
        c-0.90234-1.3877,1.3457-6.01563,2.23633-6.47656c0.66699-0.33887,2.30762-1.18848,3.12109-2.16797h-74.02539
        c0.00586,0.021,0.01563,0.04199,0.02441,0.0625C535.56738,167.48926,537.40918,168.13281,537.65527,169.25488z"/>
      <path class="megm-2" d="M602.28027,291.95703c0-2.46484-6.28711-4.63086-6.28711-7.70996c0-3.05176-4.1084-5.81201-3.59473-8.26465
        c0.00391-0.02246,0.00293-0.04492,0.00781-0.06738c0.03418-0.13916,0.04492-0.28418,0.04688-0.43213
        c0.00098-0.03662,0-0.07324-0.00195-0.11035c-0.00488-0.14209-0.01953-0.28662-0.04883-0.43555
        c-0.00098-0.00635-0.00195-0.01221-0.00293-0.01807c-0.03418-0.16895-0.08496-0.34229-0.14648-0.51807
        c-0.00391-0.01172-0.00879-0.02344-0.0127-0.03516c-0.51855-1.44873-1.84375-3.10156-2.62988-4.50977
        c-2.91895-0.80273-5.94629-1.12012-8.96875-0.94385c-2.80859,0.34131-5.08984,1.04688-7.61328,1.62549
        c-0.83301,0.19092-1.69336,0.36768-2.60742,0.51318c-0.00879,0.00146-0.01758,0.00342-0.02637,0.00488
        c1.30566,2.03223,1.14941,3.104,1.36328,4.82422c0.00488,0.03662,0.00879,0.07324,0.01367,0.11035
        c0.00977,0.07568,0.02148,0.15283,0.0332,0.23145c0.12305,0.82422,0.3457,1.80273,0.84668,3.09375
        c0.44922,1.15625,1.06641,1.86914,1.76367,2.30811c2.08789,1.31445,4.8877,0.16553,6.01172,1.08203
        c0.00195,0.00146,0.00391,0.00195,0.00586,0.00342c0.85254,1.12598,1.4707,2.41309,1.83105,3.78418
        c0.00488,0.0166,0.01172,0.03223,0.01563,0.04883h0.95117c0.6748,0.0791,1.28613,0.37598,1.76367,0.81787
        c0.0332,0.03027,0.06348,0.06299,0.09375,0.09424c0.36914,0.37109,0.65039,0.83105,0.79688,1.35645
        c0.00391,0.01416,0.01074,0.02686,0.01465,0.04102c1.74219,0.5918,3.40625,1.40234,4.9502,2.40234
        c1.29785,0.58887,2.16211-1.18945,4.74805,1.77441c2.25293,2.58887,2.43457,3.25977,2.1582,5.56445
        c1.11719-0.00244,2.22754-0.12402,3.31445-0.35986c0.04395-0.00928,0.08691-0.02344,0.13184-0.03369
        c0.42578-0.09619,0.84668-0.21582,1.26465-0.34717c0.25293-0.07959,0.5-0.17285,0.74805-0.26514
        c0.27246-0.10059,0.54395-0.20215,0.81055-0.31787c0.08594-0.03711,0.1748-0.06348,0.25977-0.10205
        c-0.00195-0.00342-0.00391-0.00684-0.00586-0.01025C603.28516,295.56738,602.6123,293.80176,602.28027,291.95703z"/>
      <path class="megm-2" d="M627.43945,298.12793c5.07324-6.47559,8.96289-16.65625,5.97168-22.20996
        c-2.77734-4.18555-5.7959-8.20313-9.04492-12.03809c-1.23438,3.8125-5.66992,2.74121-6.52441,4.93359
        c-0.89355,2.31055-3.14648-0.46289-4.49316,0c-1.3457,0.46289-4.48828-5.08789-7.62988-5.55078
        c-1.13379-0.25098-2.21973-0.7002-3.19922-1.32324c-0.00195,0.00195-0.00293,0.00342-0.00488,0.00488
        c-0.58789,0.64551-1.14258,1.32324-1.54102,1.94678c-0.48145,0.75098-0.73242,1.42188-0.49121,1.84424
        c0.67871,1.15137,3.03027,2.30762,3.53613,3.46729c0.16797,0.38623,0.13184,0.77344-0.24023,1.16064
        c-2.4834,1.34375-5.53613,1.10254-7.77637-0.62305c-1.12402-1.15869-3.93652-2.83545-5.65039-2.81494
        c-0.56738,0.00635-1.01563,0.19922-1.24023,0.65967c-0.02246,0.04639-0.02637,0.1001-0.04199,0.14941
        c-0.02734,0.08838-0.05371,0.17773-0.05957,0.27393c-0.00391,0.05322,0.00293,0.11035,0.00488,0.16553
        c0.00488,0.12939,0.02344,0.26367,0.05762,0.4043c0.00391,0.0166,0.00781,0.0332,0.0127,0.0498
        c0.09863,0.37744,0.28027,0.78809,0.52441,1.22656c0.00098,0.00049,0.00098,0.00098,0.00098,0.00146
        c0.78613,1.4082,2.11133,3.06104,2.62988,4.50977c0.00391,0.01172,0.00879,0.02344,0.0127,0.03516
        c0.06152,0.17578,0.1123,0.34912,0.14648,0.51807c0.00098,0.00586,0.00195,0.01172,0.00293,0.01807
        c0.0293,0.14893,0.04395,0.29346,0.04883,0.43555c0.00195,0.03711,0.00293,0.07373,0.00195,0.11035
        c-0.00195,0.14795-0.0127,0.29297-0.04688,0.43213c-0.00488,0.02246-0.00391,0.04492-0.00781,0.06738
        c-0.49609,2.45459,3.60352,5.20361,3.60352,8.26074c0,3.08594,6.28711,5.24316,6.28711,7.70801
        c0.32324,1.84668,1.00098,3.61328,1.99023,5.20557c0.00195,0.00342,0.00391,0.00684,0.00586,0.01025
        c0.03125-0.01758,0.06152-0.03613,0.09277-0.05371c2.21973-1.2583,4.39941-2.58203,6.54199-3.96191
        c1.85938-1.19727,3.69238-2.43457,5.49316-3.72168c0.74512-0.38477,1.32715-0.45996,1.77441-0.30713
        c2.42773,0.83008,0.90625,8.39209,0.22461,9.78467c-0.20801,0.4248,0.19434,1.06885,0.98926,1.79004
        c1.32715,1.20508,3.74902,2.62646,6.24902,3.60791c0.00586,0.00195,0.01074,0.00439,0.01563,0.00635
        C625.46777,302.10059,626.1084,299.89648,627.43945,298.12793z"/>
      <path class="megm-2" d="M595.59766,293.04004c-2.58984-2.9707-3.4502-1.19238-4.74805-1.78125v-0.00488
        c-1.54395-1-3.20801-1.81055-4.9502-2.40234c0.00391,0.01025,0.00684,0.02051,0.00977,0.03076
        c0.2627,0.75391,0.41016,1.60352,0.68262,2.43213c0.04688,0.14063,0.09668,0.28125,0.15137,0.41992
        c0.00977,0.02393,0.01758,0.04736,0.02734,0.0708c0.07715,0.18896,0.16699,0.37402,0.26563,0.55615
        c0.00586,0.01025,0.01074,0.02051,0.0166,0.03027c0.42383,0.76904,1.06934,1.46777,2.17188,1.9834
        c1.08887,0.50879,1.51367,0.94238,1.67285,1.32568c0.20215,0.48584-0.02441,0.89111,0.125,1.26563
        c0.20703,0.52295,1.14648,0.9873,5.00488,1.53076l0.84277,0.05078l0.87695,0.05273
        C598.03223,296.29102,597.85059,295.63379,595.59766,293.04004z"/>
      <path class="megm-2" d="M794.66797,320.74414c-2.99805-1.1582-4.59375,5.29004-5.76758,9.49707
        c-0.07813,0.28027-0.1543,0.55078-0.22949,0.80859c-1.19824,4.1123-14.79199,4.1123-14.39063,8.64551
        c0.30859,3.45508-4.47461,5.22559-7.13477,4.60449c-0.83398-0.19434-1.46484-0.61768-1.65723-1.30566
        c-0.79785-2.88281-5.99512-1.23633-7.19824-2.06152c-0.25781-0.17676-0.62891-0.23193-1.04492-0.18018
        c-0.05762,0.00732-0.11328,0.01172-0.1709,0.02295c-0.02734,0.00537-0.05566,0.01221-0.08301,0.01855
        c-0.18652,0.04199-0.37988,0.10938-0.57422,0.19434c-0.09473,0.04102-0.18945,0.0874-0.28418,0.13916
        c-0.01367,0.00732-0.02637,0.01514-0.04004,0.02295c-0.52832,0.29541-1.04004,0.74854-1.44238,1.35889
        c-0.02051,0.03125-0.04102,0.06201-0.06055,0.09375c-0.06055,0.09619-0.11621,0.1958-0.16992,0.29932
        c-0.40332,0.77686-0.63184,1.75684-0.52051,2.95068c0.00098,0.00928,0,0.01709,0.00098,0.02588
        c0.02441,0.25391,0.03125,0.50146,0.02441,0.74316c-0.10938,3.68359-3.64941,5.95898-4.02148,7.50293
        c-0.2998,1.23633-1.72363,4.32568-1.74316,6.83496c-0.00684,0.8374,0.14258,1.61035,0.54395,2.22852
        c0.20996,0.32324,0.32715,0.67676,0.38184,1.05469c0.28711,1.95898-1.17188,4.61133-0.98633,7.56006
        c0.05078,0.81738,0.22754,1.65723,0.60449,2.51123c0.5,1.13232,0.7998,2.34131,0.98145,3.54346
        c0.44629,2.9624,0.17383,5.87939,0.39063,7.51563c0.04688,0.35889,0.11621,0.66113,0.22363,0.8833
        c0.79785,1.64941,8.3916,0.82813,9.99121,5.35547c0.09961,0.28076,0.14648,0.57422,0.15039,0.87842
        c0.05371,4.6001-10.16602,11.63818-10.53809,13.95264c0,0.00195,0,0.00391,0,0.00488
        c-0.02148,0.13965-0.01953,0.2832-0.00293,0.42773c0.00391,0.0332,0.01563,0.06836,0.02148,0.10254
        c0.02148,0.12207,0.05371,0.24512,0.09863,0.37109c0.01367,0.03613,0.03027,0.07324,0.0459,0.11035
        c0.05176,0.12598,0.11328,0.25391,0.18652,0.38379c0.01953,0.03516,0.03906,0.07031,0.06055,0.10547
        c0.08203,0.13672,0.17383,0.27441,0.27637,0.41406c0.02051,0.0293,0.03906,0.05762,0.06055,0.08691
        c0.11621,0.1543,0.24121,0.30957,0.37598,0.46777c0.01465,0.0166,0.02734,0.0332,0.04199,0.05078
        c0.1377,0.1582,0.2832,0.31836,0.43555,0.48047c0.14063,0.14844,0.28711,0.29883,0.43652,0.44922
        c2.60938,0.12109,5.21582,0.3291,7.81152,0.62305c2.59766,0.29395,5.18457,0.67578,7.75391,1.14355
        c10.91797-0.10059,21.79785-1.25293,32.49414-3.40234v-91.0249C798.36133,318.97607,796.77441,321.56104,794.66797,320.74414z"/>
      <path class="megm-2" d="M759.75195,410.52148c-2.5957-0.29395-5.20215-0.50195-7.81152-0.62305c0,0,0,0-0.00098,0
        s-0.00195,0-0.00293,0c0.00879,0.00684,0.0166,0.01563,0.02539,0.02246c0.98242,0.80078,1.87988,1.69238,2.6875,2.6582
        c0.76074,0.91016,1.44336,1.88477,2.03223,2.9209c2.18945,4.22949,5.91016,7.47266,10.4082,9.05762
        c4.78516,2.06152,2.38379,10.7168,4.78516,11.54199c2.40527,0.8252,5.60742,7.4209,2.40527,7.82324
        c-3.22168,0.39355-6.33203,1.37207-9.19336,2.88672c-4.01465,2.04102-6.50098,6.20898-6.39551,10.70801l0.25781,2.6084l0,0
        c6.32715,0.55664,12.7041,0.08496,18.875-1.4082c2.0498-2.06738,5.2041-2.57227,7.79102-1.24023
        c4.64551,1.66113,9.66602,1.81934,14.38477,0.48633V408.2627c-10.69629,2.14941-21.57617,3.30176-32.49414,3.40234
        C764.93555,411.19727,762.34863,410.81641,759.75195,410.52148z"/>
      <path class="megm-2" d="M274.37305,317.56836c7.4707,2.01367,8.37402,7.24219,11.51367,7.40527
        c3.14844,0.1543,3.4502-2.9375,2.70313-7.10156c-0.75098-4.16406,3.74121-8.94531,4.18652-9.71973
        c0.44531-0.77051-1.94629-2.00098-8.07715-0.61328c-6.14258,1.3877-8.68359,1.85059-12.27441,0
        c-3.58691-1.85059-9.4082,1.68652-8.53125,4.16602C264.48828,313.40234,266.88965,315.55762,274.37305,317.56836z"/>
      <path class="megm-2" d="M223.18359,287.79785c-1.34668,6.01855,0.75,13.58008,4.18262,10.02734
        c3.44531-3.54492,5.09863-1.84863,7.03809-4.4707c1.94336-2.62988,0.30664-12.33691,1.65137-16.96484
        c1.34375-4.62793-5.98535-6.80176-9.2832-3.86426c-3.2959,2.93652-6.00195-0.50293-7.32617,1.85352
        C217.79297,277.31348,224.52832,281.7832,223.18359,287.79785z"/>
      <path class="megm-2" d="M267.84766,199.54199c-0.00049,0-0.00049,0-0.00098,0c-0.25635-0.01172-0.51074-0.04102-0.7666-0.06445
        c-1.49854-0.13525-2.97656-0.45947-4.38672-0.99023c-0.28174-0.17432-0.50684-0.53955-0.69678-0.98828
        c-0.27393-0.64795-0.47461-1.46875-0.66602-2.14355c-0.20313-0.71484-0.39648-1.26563-0.65674-1.26563
        c-0.67383,0-6.96191,0-8.07715,1.15527c-1.11523,1.15332-4.49316,3.24121-6.28711,2.08301l-1.25586-0.71387l-1.95996,1.87207
        c0,0-0.29688,2.47266-1.49805,2.47266c-1.2002,0-2.98633-0.92578-2.98633,1.22754c0,2.15723-3.30078,1.5498-4.79297,1.2373
        c-1.49805-0.31152-3.59473,0.3125-3.59473,2.16309s-0.2959,5.86621-2.69238,3.7002
        c-2.39355-2.16406-4.78809-7.7168-5.69238-7.10059c-0.89258,0.61523-2.98828,7.40723-5.08301,7.40723
        c-1.30078,0-5.5625,0.35059-8.63672,0.62695c-0.6001,1.99268-0.27002,3.37891,0.39502,4.23682
        c0.22314,0.28809,0.48438,0.51758,0.76123,0.68896c1.11523,0.69238-0.67578,3.46094-2.01953,4.16211
        c-0.84473,0.43896-2.0376,0.3291-2.4292,0.80713c-0.23535,0.28662-0.18164,0.78516,0.40967,1.74072
        c1.56934,2.5459,4.49219,2.77441,2.91602,5.32227c-0.12061,0.1543-0.21973,0.31836-0.30762,0.48584
        c-0.0249,0.04736-0.0459,0.0957-0.06836,0.14453c-0.05713,0.12451-0.10498,0.25244-0.14502,0.38184
        c-0.01709,0.05518-0.03662,0.10889-0.05029,0.16504c-0.04492,0.17822-0.07861,0.3584-0.09131,0.54004
        c-0.01465,0.20508-0.00879,0.41113,0.01611,0.61475c0.0083,0.06348,0.02979,0.12451,0.0415,0.1875
        c0.02588,0.14111,0.05127,0.28223,0.09668,0.41943c0.01123,0.03418,0.03076,0.06543,0.04297,0.09912
        c0.1958,0.5293,0.52588,1.01611,1.00244,1.38965c0.14746,0.11328,0.30566,0.20898,0.46777,0.29492
        c0.04004,0.02051,0.08105,0.03809,0.12158,0.05713c0.13232,0.06348,0.26807,0.11719,0.40723,0.16162
        c0.04834,0.01514,0.09619,0.03125,0.14502,0.04443c0.18311,0.04883,0.36914,0.08691,0.55957,0.10205
        c2.10742,0.21875,4.99951-0.17578,4.7998,1.29932c-0.01318,0.09863-0.04004,0.20508-0.08203,0.3208
        c-0.50977,1.13184-0.89258,2.31543-1.14063,3.53711c0.00391-0.00098,0.0083-0.00195,0.01221-0.00244
        c1.67383-0.31104,3.30908-0.7998,4.88232-1.44971c3.13965-1.69629,1.79199-4.62793,7.02637-6.1709
        c5.50879-0.68848,10.95508,1.67773,14.22168,6.1709c2.08984,3.2373,2.68848,3.54688,3.13965,6.1748
        c1.30762,5.35449,4.77148,9.93066,9.57813,12.64258c7.18262,4.78516,10.0332,10.18457,13.76758,12.18457
        c3.74121,2.01172,7.48828,1.54785,9.73145,4.32422c2.4375,2.66309,5.45508,4.7207,8.82031,6.01563
        c3.59668,1.70313,3.74609,5.70508,7.18555,6.16895c3.44141,0.46191,1.2002,3.55078,3.30566,6.16992
        c2.30762,3.35254,2.4834,7.73828,0.44043,11.2666c-2.24316,3.08691-1.04785,8.32813,0.4502,8.32813
        c1.50293,0,4.63672-5.55371,5.08691-8.64355c0.45313-3.08496,3.44629-2.92773,5.83789-6.16309
        c2.39355-3.24121-3.59375-4.01758-4.6377-8.18164c-1.04785-4.16406,4.49414-7.86328,9.7334-4.47559
        c5.23828,3.40137,7.33105,5.71289,7.63184,0.46484c0.28809-5.25098-16.18262-12.19824-20.06348-14.66016v0.00977
        c-3.88965-2.47461-1.34375-6.48145-7.4873-6.1748c-6.13574,0.30762-13.47363-7.55859-15.71875-16.65723
        c-2.23926-9.09766-11.81445-9.5625-14.2168-14.96094c-2.39648-5.39355,2.24414-6.01465,0.1582-10.49414
        c-1.16211-2.48535,1.41113-4.68555,4.45703-5.93359c-0.03955-1.15625-0.05811-2.31348-0.05518-3.46973
        C267.26172,206.44531,267.45996,202.98535,267.84766,199.54199z"/>
      <path class="megm-2" d="M328.56152,256.93311c0.03027-0.08643,0.06348-0.17236,0.09521-0.2583
        c0.04346-0.1167,0.08643-0.23389,0.13281-0.34961c0.05762-0.14355,0.11816-0.28564,0.17969-0.42627
        c0.02197-0.0498,0.04297-0.1001,0.06543-0.14941c0.98291-2.1709,2.41455-3.91309,3.54932-4.19287
        c-0.00098-0.00098-0.00146-0.00195-0.00244-0.00293l-0.01123,0.00293c-2.31982-2.92969-5.7334-7.07861-7.9375-9.35596
        c-1.88086,2.23486-3.20801,4.8877-3.86328,7.73779l-0.41895,3.22852l0.00049,0.00049
        c2.21582,2.02148,4.61377,3.85596,7.15479,5.47705l0.52637,0.29492c0.0791-0.4585,0.19092-0.91846,0.3252-1.37305
        c0.00342-0.01123,0.00732-0.02246,0.01074-0.03369C328.42822,257.33105,328.49219,257.13135,328.56152,256.93311z"/>
      <path class="megm-2" d="M360.60596,228.61719c-0.01367-0.05176-0.02588-0.104-0.03955-0.15625
        c-0.02197-0.0874-0.04443-0.1748-0.06445-0.26465c-0.04053-0.17969-0.07275-0.36377-0.09912-0.55029
        c-0.00537-0.03809-0.01025-0.07617-0.01465-0.11426c-0.02393-0.1958-0.0415-0.39355-0.0415-0.59619
        c0-2.3125-9.65234-3.00977-11.23047-3.46094c-0.49072-0.14355-0.5625-0.83398-0.50684-1.71387
        c0.0332-0.52441,0.11182-1.11621,0.1748-1.69971c0.16846-1.56201,0.22314-3.06592-1.0127-3.06592
        c-1.08789,0.03174-2.06055-0.47559-2.68359-1.27344c-0.00293-0.00391-0.00684-0.00732-0.00977-0.01123
        c-0.11572-0.1499-0.21533-0.31055-0.30469-0.47949c-0.01172-0.02295-0.02686-0.04346-0.03857-0.06689
        c-0.07178-0.14453-0.12695-0.29736-0.17725-0.45313c-0.01807-0.05469-0.04102-0.10645-0.05615-0.1626
        c-0.03369-0.12793-0.05176-0.26074-0.0708-0.39453c-0.0127-0.09033-0.03027-0.17871-0.03564-0.27148
        c-0.00146-0.0293-0.01025-0.05664-0.01123-0.08643c-0.00391-0.16162,0.00098-0.32617,0.02393-0.48682
        c0.00049-0.00439,0-0.00879,0.00049-0.01318c0-0.00098-0.00049-0.00195-0.00049-0.00244
        c-0.00342-1.85498-3.81396-4.85742-3.59033-6.01709l0,0l0.2793-0.4707l-0.021-0.00342l-2.8208-0.47119
        c-1.47461-0.37207-2.92871-0.83301-4.35254-1.38477l-1.41797,0.46191l-8.479,2.76123l-0.65381,0.21289l-0.00488,0.00146
        c0.28613,2.7168,0.98926,5.66992,2.63867,6.3125c3.22168,1.24414,2.00684,4.55273,3.22168,7.45898
        c0.00488,0.01172,0.00879,0.02637,0.01367,0.03809c0.18994,0.47705,0.33887,1.21387,0.44922,2.11572
        c0.00293,0.02539,0.00586,0.05176,0.00879,0.07764c0,0.00146,0.00049,0.00342,0.00049,0.00537
        c0.47412,4.0083,0.19287,11.13721-0.70996,13.29395c-0.01221,0.0293-0.02441,0.06006-0.03711,0.0874
        c-0.03369,0.0752-0.06885,0.14355-0.10449,0.2041c-0.04932,0.08252-0.09961,0.15137-0.15137,0.20508
        c-0.02148,0.02197-0.04346,0.03467-0.06543,0.05176c-0.06494,0.05078-0.13184,0.08447-0.20117,0.0874
        c-0.00439,0.00049-0.00879,0.00342-0.01318,0.00342c-0.67969,0-2.23438,1.72754-3.76855,3.87012
        c0.00146,0.00146,0.00342,0.00342,0.00488,0.00537c2.2041,2.27734,5.61768,6.42627,7.9375,9.35596l0.01123-0.00293l0.30713-0.0791
        c3.14307,1.29199,6.08398,3.0083,8.77881,5.07813c0.19287,0.14844,0.39502,0.28271,0.58545,0.43457
        c0,0,10.41211-2.23193,17.43359-3.27979c0.00098,0,0.00195-0.00049,0.00293-0.00049
        c0.09521-0.56592,0.15137-1.13721,0.18164-1.70996c0.02588-0.48242,0.03467-0.96631,0.01563-1.44824
        c-0.01367-0.08789-0.02051-0.18164-0.02051-0.27979c0.00098-1.44971,1.49316-3.88525,3.38477-3.88525
        c0.63232,0,0.78174-0.45508,0.62012-1.14355c-0.00244-0.01074-0.00732-0.02344-0.01025-0.03418
        c-0.37256-1.51611-2.20557-4.12354-3.72998-5.54297c-0.21289-0.18945-0.39453-0.3999-0.56543-0.61572
        c-0.06738-0.08496-0.12842-0.17188-0.18896-0.26025c-0.12305-0.17822-0.23682-0.36133-0.33301-0.55176
        c-0.04248-0.08398-0.0791-0.16895-0.11621-0.25488c-0.0874-0.20166-0.16455-0.40723-0.22314-0.61719
        c-0.02441-0.08691-0.04199-0.17383-0.06152-0.26172c-0.0459-0.20996-0.08105-0.42139-0.09863-0.63477
        c-0.0083-0.09863-0.00928-0.19629-0.01172-0.29541c-0.00391-0.20215,0.00146-0.4043,0.02246-0.60596
        c0.01172-0.1123,0.03174-0.22168,0.05176-0.33301c0.0332-0.18799,0.07471-0.37451,0.13086-0.55811
        c0.03516-0.11621,0.07861-0.229,0.12354-0.34326c0.06836-0.17529,0.14453-0.34814,0.23486-0.51611
        c0.05859-0.11035,0.12549-0.21582,0.19336-0.32227c0.104-0.16113,0.21631-0.31836,0.34131-0.46973
        c0.03857-0.04639,0.06445-0.09863,0.10498-0.14404c0.08545-0.09375,0.17822-0.17822,0.26953-0.26465
        c0.04297-0.04004,0.07959-0.08398,0.12598-0.12305c0.44287-0.3042,0.69434-0.63232,0.82861-0.98145
        c0.00293-0.00732,0.00635-0.01514,0.00879-0.02246C361.2749,230.65625,360.89453,229.69971,360.60596,228.61719z"/>
    </g>
  </g>
  </svg>
</template>

<script>
export default {
  name: "middleEastGlobalMap"
}
</script>

<style>
  .megm-0{fill:#0168BA;stroke:#000000;}
  .megm-1{fill:none;stroke:#000000;}
  .megm-2{fill:#6E6E6E;stroke:#000000;}
  .megm-3{fill:#252525;stroke:#000000;}
</style>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-.40 0 2.06521 1.60933">
    <defs>
      <clipPath id="clip-path" transform="translate(0.07617 0.01645)">
        <circle class="mal-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.07617 0.01645)">
        <rect class="mal-i-1" x="-0.07617" y="-0.01645" width="2.06521" height="1.20773"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="mal-i-2">
          <g id="Malaysia">
            <g>
              <path d="M.73431,1.493c0-.022-.00367-.04332-.00367-.058h0c-.00367.01468-.011.036-.01468.05066L.691,1.5503H.68072l-.022-.06461a.3266.3266,0,0,1-.014-.05066h0a.2713.2713,0,0,0-.00367.058L.6374,1.5503H.61978l.01028-.12922h.022l.02129.06461a.16275.16275,0,0,1,.014.04258h0a.19285.19285,0,0,1,.01468-.04258L.7233,1.42108h.022l.0066.12922H.738Z" transform="translate(0.07617 0.01645)"/>
              <path d="M.84518,1.52827v.022h-.014V1.53561h0a.04535.04535,0,0,1-.02864.01469c-.022,0-.02863-.01469-.02863-.022,0-.02863.01762-.03524.0536-.03524V1.48569c0-.00734,0-.02129-.0213-.02129-.00734,0-.01835.0066-.022.0066L.78057,1.4644A.03855.03855,0,0,1,.8092,1.45705c.02863,0,.036.014.036.036ZM.82756,1.49964c-.01836,0-.03965.00734-.03965.022,0,.01395.00734.02129.01835.02129.01028,0,.01762-.01468.0213-.02129h0Z" transform="translate(0.07617 0.01645)"/>
              <path d="M.87381,1.41374H.88849V1.5503H.87381Z" transform="translate(0.07617 0.01645)"/>
              <path d="M.98541,1.52827v.022H.97072L.96705,1.53561h0a.03145.03145,0,0,1-.02863.01469.02859.02859,0,0,1-.02864-.022c0-.02863.0213-.03524.05727-.03524V1.48569A.02006.02006,0,0,0,.94576,1.4644c-.00734,0-.01762.0066-.025.0066L.91713,1.4644c.00734-.00735.01762-.00735.0323-.00735.02863,0,.036.014.036.036ZM.96705,1.49964c-.01762,0-.03891.00734-.03891.022,0,.01395.00661.02129.014.02129.01468,0,.02129-.01468.025-.02129h0Z" transform="translate(0.07617 0.01645)"/>
              <path d="M1.01776,1.45705l.01757.05727.00734.01395h0L1.05,1.51432l.01762-.05727h.01762l-.025.06461c-.01395.03524-.02129.04993-.0323.05727l-.01757.014-.00734-.014.011-.00734a.14169.14169,0,0,0,.01762-.02129h0V1.543l-.03592-.0859Z" transform="translate(0.07617 0.01645)"/>
              <path d="M1.09627,1.53561c.00734,0,.01468.00734.02129.00734.01468,0,.01835-.00734.01835-.01468,0-.01395-.00367-.01395-.01468-.02129-.01762-.00734-.025-.01395-.025-.02129,0-.02129.011-.02864.0323-.02864.00734,0,.01762,0,.02135.00735l-.00373.0066c-.00294,0-.01028-.0066-.01762-.0066-.011,0-.01762.0066-.01762.014a.016.016,0,0,0,.01762.01468c.01762.00661.025.01395.025.02863,0,.02129-.01395.02864-.036.02864a.0301.0301,0,0,1-.025-.00735Z" transform="translate(0.07617 0.01645)"/>
              <path d="M1.19318,1.43209s-.00367.01028-.011.01028L1.17194,1.435c0-.01028.00656-.014.01023-.014A.00973.00973,0,0,1,1.19318,1.43209Zm-.01836.11821V1.45705h.01836V1.5503Z" transform="translate(0.07617 0.01645)"/>
              <path d="M1.28642,1.52827v.022H1.27174V1.53561H1.26807a.03059.03059,0,0,1-.02864.01469.02419.02419,0,0,1-.025-.022c0-.02863.01836-.03524.0536-.03524V1.48569c0-.00734,0-.02129-.02129-.02129-.00735,0-.01763.0066-.025.0066l-.00367-.0066a.05233.05233,0,0,1,.03231-.00735c.02863,0,.036.014.036.036Zm-.01835-.02863c-.01762,0-.03892.00734-.03892.022,0,.01395.00661.02129.01763.02129s.01835-.01468.02129-.02129h0Z" transform="translate(0.07617 0.01645)"/>
            </g>
            <g class="mal-i-3">
              <rect class="mal-i-4" width="2.06521" height="0.08957"/>
              <rect class="mal-i-5" y="0.08957" width="2.06521" height="0.08223"/>
              <rect class="mal-i-4" y="0.1718" width="2.06521" height="0.08957"/>
              <rect class="mal-i-5" y="0.26137" width="2.06521" height="0.08663"/>
              <rect class="mal-i-4" y="0.348" width="2.06521" height="0.0859"/>
              <rect class="mal-i-5" y="0.4339" width="2.06521" height="0.0859"/>
              <rect class="mal-i-4" y="0.5198" width="2.06521" height="0.0859"/>
              <rect class="mal-i-5" y="0.6057" width="2.06521" height="0.0859"/>
              <rect class="mal-i-4" y="0.6916" width="2.06521" height="0.08663"/>
              <rect class="mal-i-5" y="0.77824" width="2.06521" height="0.0859"/>
              <rect class="mal-i-4" y="0.86414" width="2.06521" height="0.0859"/>
              <rect class="mal-i-5" y="0.95004" width="2.06521" height="0.0859"/>
              <rect class="mal-i-4" y="1.03594" width="2.06521" height="0.0859"/>
              <rect class="mal-i-5" y="1.12184" width="2.06521" height="0.0859"/>
              <rect class="mal-i-6" width="1.20474" height="0.6916"/>
              <g class="mal-i-3">
                <path class="mal-i-7" d="M.24687.33155A.22509.22509,0,0,1,.47221.10542a.22029.22029,0,0,1,.11894.0323A.24452.24452,0,0,0,.41861.07312a.25807.25807,0,0,0,0,.51613A.24751.24751,0,0,0,.59115.521a.21654.21654,0,0,1-.11894.036A.22682.22682,0,0,1,.24687.33155" transform="translate(0.07617 0.01645)"/>
                <polygon class="mal-i-7" points="0.839 0.348 0.965 0.297 0.832 0.308 0.921 0.211 0.807 0.28 0.846 0.151 0.775 0.261 0.753 0.133 0.739 0.226 0.735 0.251 0.735 0.261 0.66 0.151 0.7 0.28 0.584 0.211 0.678 0.308 0.545 0.297 0.667 0.348 0.545 0.394 0.678 0.383 0.584 0.48 0.7 0.412 0.66 0.541 0.735 0.43 0.735 0.441 0.739 0.466 0.753 0.563 0.775 0.43 0.846 0.541 0.807 0.412 0.921 0.48 0.832 0.383 0.965 0.394 0.839 0.348"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "malaysiaIcon"
}
</script>

<style>
  .mal-i-1 {
    fill: none;
  }

  .mal-i-2 {
    clip-path: url(#clip-path);
  }

  .mal-i-3 {
    clip-path: url(#clip-path-2);
  }

  .mal-i-4 {
    fill: #d80031;
  }

  .mal-i-5 {
    fill: #fff;
  }

  .mal-i-6 {
    fill: #2530a9;
  }

  .mal-i-7 {
    fill: #f5d028;
  }
</style>
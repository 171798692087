
.product-wrap {
  padding: 15px;

  #loading-products {
    font-size: 60px;
    text-align: center;
    position: relative;
    height: 10vh;

    .loading-contents {
      width: 100%;
      position: absolute;
      z-index: 100;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }
}

@media (max-width: 768px) {
  .product-wrap {
    display: none !important;
  }
}

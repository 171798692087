<template>
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 9.4503 5.5116"
  >
    <defs>
      <clipPath id="clippath">
        <rect class="france-4" width="9.4503" height="5.5116" />
      </clipPath>
    </defs>
    <g id="World_Flags_285">
      <g class="france-5">
        <rect class="france-1" width="9.4503" height="5.5116" />
        <polygon
          class="france-2"
          points="1.64 0 4.3958 1.8383 9.4503 1.8383 9.4503 0 1.64 0"
        />
        <polygon
          class="france-1"
          points="4.9537 2.1979 9.4503 2.1979 9.4503 1.8383 4.3958 1.8383 4.9537 2.1979"
        />
        <polygon
          class="france-1"
          points="4.3958 3.6733 9.4503 3.6733 9.4503 3.3137 4.9537 3.3137 4.3958 3.6733"
        />
        <polygon
          class="france-3"
          points="4.3958 3.6733 1.64 5.5116 9.4503 5.5116 9.4503 3.6733 4.3958 3.6733"
        />
        <path
          d="m0,1.0821l1.0821.7562H0v-.7562Zm0,.7562v1.8349h1.0821l1.3779-.9175-1.3779-.9175H0Zm0,1.8349v.7226l1.0821-.7226H0Z"
        />
        <polygon
          class="france-7"
          points="0 1.0821 2.46 2.7558 0 4.3958 0 4.8562 3.1154 2.7558 0 .6553 0 1.0821"
        />
        <polygon
          class="france-6"
          points="0 0 0 .6553 3.1154 2.7558 0 4.8562 0 5.5116 .9849 5.5116 4.2984 3.3137 9.4503 3.3137 9.4503 2.1979 4.2984 2.1979 .9849 0 0 0"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "southAfricaFlag",
};
</script>

<style type="text/css">
.france-1 {
  fill: #fff;
}
.france-2 {
  fill: #d03033;
}
.france-3 {
  fill: #1f189b;
}
.france-4,
.france-5 {
  fill: none;
}
.france-6 {
  fill: #32784c;
}
.france-5 {
  clip-path: url(#clippath);
}
.france-7 {
  fill: #f2b126;
}
</style>

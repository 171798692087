
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#loading-cart {
  text-align: center;
  position: relative;
  height: 100vh;
  z-index: 0;

  .loading-contents {
    width: 100%;
    position: absolute;
    z-index: 100;
    background-color: #e1e1e1;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;

    h1 {
      position: fixed;
      width: 100%;
    }
  }
}

.alert {
  margin-bottom: 0;
}

.wrap-cart {
  .cart-items {
    .main-wrapper {
      grid-template-areas:
      'hd'
      'line-item-wrapper';
      display: grid;
      overflow: hidden;

      .header {
        grid-area: hd;
        display: grid;
        gap: 10px;
        grid-template-areas: 'hd-1 hd-2 hd-3 hd-4';
        grid-template-columns: 37% 18% auto;

        h3 {
          color: #c4c4c4;
        }

        .hd-1 {
          grid-area: hd-1;
        }
        .hd-2 {
          grid-area: hd-2;
        }
        .hd-3 {
          grid-area: hd-3;
        }
        .hd-4 {
          grid-area: hd-4;
          height: min-content;
          font-size: 1rem;
          margin: 10px 0 0 0;
        }
      }

      .line-item-wrapper {
        display: grid;
        gap: 10px;
        grid-template-columns: 15% 20% 15% 15% 30%;
        grid-template-areas:
        'product-image product-details product-qty product-price product-actions';

        p, h4 {
          margin: 0;
        }

        .product-image {
          grid-area: product-image;
          margin: 0 auto;

          img {
            width: 100px;
          }
        }

        .product-details {
          grid-area: product-details;

          p {
            font-size: 12px;
          }

          .label {
            display: inline-block;
            white-space: normal;
          }
        }

        .product-qty {
          grid-area: product-qty;

          input {
            display: inline-block;
          }
        }

        .product-price {
          grid-area: product-price;
          margin: 0 auto;
          text-align: left;

          .price-per-unit{
            color: #c4c4c4;
          }
        }

        .product-actions {
          grid-area: product-actions;
          text-align: center;
          justify-content: center;
          align-items: baseline;
          display: flex;

          button {
            font-size: 1rem;
            margin: 0 5px;
          }
        }
      }
    }

    .cart-footer {
      display: grid;
      grid-template-columns: 65% 35%;
    }
  }
}


@media (max-width: 768px) {
  .wrap-cart .cart-items {
    position: inherit;

    .main-wrapper {
      hr {
        display: none;
      }

      .header {
        display: none;
      }

      .line-item-wrapper {
        grid-template-columns: auto;
        grid-template-areas:
        'product-image'
        'product-details'
        'product-qty'
        'product-price'
        'product-actions';

        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 25px;
        margin-bottom: 25px;
      }

      .line-item-wrapper:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .cart-footer {
      display: inline-block;
      width: 100%;

      .add-product {
        max-width: inherit;
      }
    }
  }

  #loading-cart {
    margin-top: -25px;
    height: 50vh;

    .loading-contents {
      z-index: 1;
    }
  }
}

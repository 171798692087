
.cc-types {
  display: block;
  font-size: 3rem;
  color: #a9a9a9;
}

@media (max-width: 768px) {
  .cart-summary {
    position: inherit;
  }
}

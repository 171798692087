<template>
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 5.9123 5.9123"
  >
    <defs>
      <clipPath id="clippath">
        <circle class="sa-4" cx="2.9561" cy="2.9561" r="2.9561" />
      </clipPath>
      <clipPath id="clippath-1">
        <rect
          class="sa-4"
          x="-.8269"
          y="-.0684"
          width="10.3729"
          height="6.0496"
        />
      </clipPath>
    </defs>
    <g id="World_Flags_285">
      <g class="sa-5">
        <g class="sa-6">
          <rect
            class="sa-1"
            x="-.8269"
            y="-.0684"
            width="10.3729"
            height="6.0496"
          />
          <polygon
            class="sa-2"
            points=".9733 -.0684 3.9981 1.9494 9.546 1.9494 9.546 -.0684 .9733 -.0684"
          />
          <polygon
            class="sa-1"
            points="4.6104 2.3441 9.546 2.3441 9.546 1.9494 3.9981 1.9494 4.6104 2.3441"
          />
          <polygon
            class="sa-1"
            points="3.9981 3.9635 9.546 3.9635 9.546 3.5688 4.6104 3.5688 3.9981 3.9635"
          />
          <polygon
            class="sa-3"
            points="3.9981 3.9635 .9733 5.9812 9.546 5.9812 9.546 3.9635 3.9981 3.9635"
          />
          <path
            d="m-.8269,1.1194L.3609,1.9494H-.8269v-.83Zm0,.83v2.0141H.3609l1.5124-1.007L.3609,1.9494H-.8269Zm0,2.0141v.7931l1.1878-.7931H-.8269Z"
          />
          <polygon
            class="sa-8"
            points="-.8269 1.1194 1.8733 2.9564 -.8269 4.7566 -.8269 5.2619 2.5926 2.9564 -.8269 .6509 -.8269 1.1194"
          />
          <polygon
            class="sa-7"
            points="-.8269 -.0684 -.8269 .6509 2.5926 2.9564 -.8269 5.2619 -.8269 5.9812 .2542 5.9812 3.8911 3.5688 9.546 3.5688 9.546 2.3441 3.8911 2.3441 .2542 -.0684 -.8269 -.0684"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "southAfricaIcon",
};
</script>

<style type="text/css">
.sa-1 {
  fill: #fff;
}
.sa-2 {
  fill: #d03033;
}
.sa-3 {
  fill: #1f189b;
}
.sa-4,
.sa-5,
.sa-6 {
  fill: none;
}
.sa-7 {
  fill: #32784c;
}
.sa-5 {
  clip-path: url(#clippath);
}
.sa-6 {
  clip-path: url(#clippath-1);
}
.sa-8 {
  fill: #f2b126;
}
</style>

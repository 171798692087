<template>
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 10.3936 6.0633"
  >
    <defs>
      <clipPath id="clippath">
        <rect class="france-flag-3" width="10.3936" height="6.0633" />
      </clipPath>
    </defs>
    <g id="World_Flags_285">
      <g class="france-flag-4">
        <rect class="france-flag-5" width="3.4661" height="6.0633" />
        <rect class="france-flag-1" x="3.4661" width="3.4618" height="6.0633" />
        <rect class="france-flag-2" x="6.9279" width="3.4658" height="6.0633" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "franceFlag",
};
</script>

<style type="text/css">
.france-flag-1 {
  fill: #f4f4f4;
}
.france-flag-2 {
  fill: #d80031;
}
.france-flag-3,
.france-flag-4 {
  fill: none;
}
.france-flag-4 {
  clip-path: url(#clippath);
}
.france-flag-5 {
  fill: #323e95;
}
</style>

<template>
  <svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06174 1.20317">
    <defs>
      <clipPath id="clip-path">
        <rect class="swe-2" width="2.07" height="1.2" />
      </clipPath>
    </defs>
    <g id="World_Flags_285" data-name="World Flags 285">
      <g class="swe-4">
        <rect class="swe-1" width="2.07" height="1.2" />
        <rect class="swe-3" x=".6" width=".24" height="1.2" />
        <rect class="swe-3" y=".48" width="2.07" height=".24" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "swedenFlag"
}
</script>

<style type="text/css">
.swe-1 {
  fill: #2d5a95;
}

.swe-1,
.swe-2,
.swe-3 {
  stroke-width: 0px;
}

.swe-2 {
  fill: none;
}

.swe-4 {
  clip-path: url(#clippath);
}

.swe-3 {
  fill: #f3d02f;
}
</style>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06247 1.20317">
    <defs>
      <clipPath id="clip-path" transform="translate(0.44382 0.01417)">
        <circle class="aus-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.44382 0.01417)">
        <rect class="aus-i-1" x="-0.44382" y="-0.01417" width="2.06247" height="1.20317"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="aus-i-2">
          <g class="aus-i-3">
            <rect class="aus-i-4" width="2.06247" height="1.20317"/>
            <polygon class="aus-i-5" points="0.54 0.738 0.573 0.838 0.669 0.798 0.612 0.885 0.702 0.938 0.598 0.946 0.612 1.049 0.54 0.974 0.469 1.049 0.483 0.946 0.383 0.938 0.473 0.885 0.415 0.798 0.508 0.838 0.54 0.738"/>
            <polygon class="aus-i-5" points="1.626 0.914 1.643 0.96 1.686 0.946 1.661 0.985 1.704 1.01 1.654 1.013 1.661 1.06 1.626 1.028 1.593 1.06 1.601 1.013 1.55 1.01 1.593 0.985 1.568 0.946 1.611 0.96 1.626 0.914"/>
            <polygon class="aus-i-5" points="1.357 0.484 1.371 0.53 1.418 0.513 1.389 0.555 1.432 0.58 1.382 0.584 1.389 0.631 1.357 0.598 1.321 0.631 1.329 0.584 1.282 0.58 1.321 0.555 1.296 0.513 1.339 0.53 1.357 0.484"/>
            <polygon class="aus-i-5" points="1.626 0.19 1.643 0.237 1.686 0.218 1.661 0.262 1.704 0.287 1.654 0.29 1.661 0.337 1.626 0.301 1.593 0.337 1.601 0.29 1.55 0.287 1.593 0.262 1.568 0.218 1.611 0.237 1.626 0.19"/>
            <polygon class="aus-i-5" points="1.869 0.412 1.883 0.458 1.93 0.441 1.901 0.484 1.944 0.509 1.894 0.513 1.901 0.559 1.869 0.523 1.833 0.559 1.84 0.513 1.793 0.509 1.833 0.484 1.808 0.441 1.854 0.458 1.869 0.412"/>
            <polygon class="aus-i-5" points="1.736 0.631 1.747 0.659 1.78 0.663 1.755 0.68 1.761 0.713 1.736 0.695 1.708 0.713 1.715 0.68 1.694 0.663 1.722 0.659 1.736 0.631"/>
            <polygon class="aus-i-5" points="0.451 0 0.451 0.183 0 0.183 0 0.362 0.451 0.362 0.451 0.545 0.633 0.545 0.633 0.362 1.085 0.362 1.085 0.183 0.633 0.183 0.633 0 0.451 0"/>
            <polygon class="aus-i-5" points="0 0 0 0.061 0.963 0.545 1.085 0.545 1.085 0.484 0.122 0 0 0"/>
            <polygon class="aus-i-5" points="0.963 0 0 0.484 0 0.545 0.122 0.545 1.085 0.061 1.085 0 0.963 0"/>
            <polygon class="aus-i-6" points="0.487 0 0.487 0.218 0 0.218 0 0.326 0.487 0.326 0.487 0.545 0.595 0.545 0.595 0.326 1.085 0.326 1.085 0.218 0.595 0.218 0.595 0 0.487 0"/>
            <path class="aus-i-6" d="M-.44382-.01417V.02909l.27934.1393h.08212Zm1.00233,0L.197.16839H.2791L.6413-.01417Z" transform="translate(0.44382 0.01417)"/>
            <path class="aus-i-6" d="M-.08236.348-.44382.53059h.0785L-.00317.348Zm.36146,0L.6413.53059V.491L.35829.348Z" transform="translate(0.44382 0.01417)"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "australiaIcon"
}
</script>

<style>
  .aus-i-1 {
    fill: none;
  }

  .aus-i-2 {
    clip-path: url(#clip-path);
  }

  .aus-i-3 {
    clip-path: url(#clip-path-2);
  }

  .aus-i-4 {
    fill: #180087;
  }

  .aus-i-5 {
    fill: #fff;
  }

  .aus-i-6 {
    fill: #ea0020;
  }
</style>
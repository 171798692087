<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="59.43311 285.05469 681.12842 229.88965">
  <g>
    <path class="direct-to-door" d="M570.2002,335.61133c-0.2002-0.02246-0.38867-0.04492-0.5332-0.06689
      C569.81152,335.56641,569.97754,335.58887,570.2002,335.61133z"/>
    <path class="direct-to-door" d="M271.08887,412.17773L97.54443,462.84473l116.2334-32.02246l61.01123-17.66699l5.1333,1.35547
      c-0.81104,0.66699-1.28906,1.08887-1.41113,1.18945l-2.87793,2.56641L282.1001,436l3.37744,0.47754
      c0.22266,0.0332,19.92236,2.7666,46.5332,4.2334l-0.02148,6.95605l-94.84473,27.68848L318,454.11133l19.18848-5.60059
      l8.06738-2.82227l8.39941-4.17773c35.02246,0.66699,63.85547-2.02148,85.82227-8.04395
      c20.41113-5.82227,48.01172-13.81152,77.63379-22.43359l0.09961,0.05566l36.95605-10.84473l3.25488-0.95508l2.86719-0.84473
      l26.98828-7.92236c0,0.01123,0,0.02246,0.01172,0.02246c0.77734-0.2334,1.55566-0.45557,2.33301-0.67773
      c-0.22168,1.56641-0.25586,3.18896-0.08887,4.8335c0.7998,7.81055,5.92188,13.89941,12.02246,14.4668
      c-0.60059,0.0332-1.2002,0.02148-1.7998-0.02246c-4.11133-0.31152-7.86719-2.43359-10.57813-5.7666l-28.2998,6.21094
      L587.5,400.93359c-0.5-0.86719-0.92188-1.7998-1.28906-2.7666L557.5332,404.5l-3.05566,0.67773l-34.11133,7.5332l40.47852,20.83398
      l17.58887,13.66602c0,0,9.35547-2.08887,14.4668-3.22168c5.11035-1.14453,5.46582-4.91113,5.46582-4.91113l3.14453-27.21191
      l0.4668-0.14355l-0.04395-0.2334c0.62207-0.34473,1.2334-0.75586,1.81055-1.2002c2.42285-0.74512,4.60059-2.21094,6.36719-4.24512
      c-0.05566-0.02148-0.10059-0.04395-0.15625-0.06641c0.75586-0.7666,1.45605-1.63281,2.05566-2.63281
      c1.86719-3.11133,2.68945-7,2.35645-11.00049c-0.01172-0.6001-0.04492-1.19971-0.11133-1.7998
      c-0.18945-1.85547-0.62207-3.61133-1.22266-5.24463h-0.01074c0,0-0.11133,0.2002-0.33398,0.54443
      c-0.49902,0.7666-1.55469,2.2334-3.09961,3.67773c-1.42188,1.3335-3.2666,2.65576-5.47754,3.38916
      c-3.07813,1.01123-8.4668,2.21094-8.4668,2.21094c-1.95508-3.22217-0.74414-6.57764-0.65527-6.82227
      c0-0.01074,0.01074-0.02197,0.01074-0.02197l33.11133-9.68896c32.13281-9.43359,57.71094-16.97754,67.5332-19.92236
      c21.2666-6.35547,32.43359-17.65527,37.7998-23.07764c0.36719-0.3667,0.7002-0.71094,1.01074-1.02246
      c4.64453-4.62207,6.65625-9.59961,5.97852-14.7998c-0.93359-7.12207-6.68945-13.52197-18.13379-20.12207
      c-1.04395-0.6001-2.14453-1.2666-3.2998-1.97803c-8.2998-5.0332-19.67773-11.93311-36.9668-12.56641
      c-8.43359-0.31104-17.52246,0.97754-26.98828,3.81104c-14,4.18896-68.60059,20.45557-127.72266,38.06641
      c0.95508,1.47803,1.77734,3.1001,2.44434,4.84473c0.86719,2.2666,1.45605,4.72217,1.72266,7.32227
      c0.02148,0.25537,0.0332,0.52197,0.05566,0.77783l54.7666,6.38867l1.87793,0.31104l8.44434,1.38916
      c0,0-10.28906-8.7002-19.72266-11.31104l63.2998-17.81152c0,0-2.33301-0.95557-4.65527-0.5
      c-6.92188,1.35596-22.37793,5.95557-44.5,11.75586c-4.10059,1.07764-11.05566,3.02197-19.98926,5.57764
      c-0.2998-0.0332-0.58887-0.06641-0.84375-0.1001c0.22168,0.02246,0.5,0.06689,0.84375,0.1001
      c-0.06641,0.02246-0.12207,0.0332-0.17773,0.05566l-25.15527-2.42236c53.07813-15.81104,99.78906-29.7334,112.58887-33.55566
      c8.27734-2.47754,16.12207-3.59961,23.32227-3.33301c6.98926,0.25537,12.82227,1.75537,17.85547,3.7666
      c-8.36621,0.1001-16.14453,1.5-16.14453,1.5l6.56641,2.97754c0,0,11.80078-0.97754,20.92285,1.31152
      c0.77734,0.46631,1.54395,0.92188,2.27734,1.37744c1.2334,0.74463,2.40039,1.45557,3.51074,2.1001
      c9.24512,5.3335,11.72266,9.1001,12.35645,10.97754c-5.66699,1.08936-12.38965,0.92236-17.15625,2.58936
      c-6.4668,2.2666-20.38867,7.02197-20.38867,7.02197s-38.33301,11.12207-86.77734,25.17773l-10.94434-1.35547l-62.08984-7.71094
      c0.13379-1.51123,0.13379-3.05566,0-4.61133c-0.01074-0.17773-0.01074-0.34424-0.0332-0.52246
      c-1.11035-10.85547-8.41113-19.27734-16.9668-19.58887c-0.65527-0.02197-1.32129,0-1.96582,0.07813
      c-2.05566,0.22217-4,0.91113-5.7666,2c-2.2002,1.35547-4.12305,3.33301-5.63379,5.8667
      c-2.59961,4.32227-3.72168,9.77783-3.14453,15.35547c1.07813,10.5332,7.98926,18.74414,16.21094,19.51123
      c-0.81055,0.04443-1.61035,0.0332-2.39941-0.02246c-8.7002-0.64453-16.25586-7.3335-18.94434-16.85547l-30.08887,5.42236
      l29.34375-8.91113c-0.25488-1.72217-0.35547-3.52246-0.28906-5.3667l-32.12207,9.77783v0.02197
      c-1.75488,0.38916-3.41113,1.08887-4.97754,1.98877l0,0c0-0.0332,0.01074-0.06641,0.02246-0.11084
      c0.09961-0.72217,0.2334-1.42236,0.37793-2.12207c0.07715-0.3335,0.14453-0.67822,0.2334-1.01123
      c0.02148-0.07764,0.04395-0.15576,0.06641-0.2334c1.81055-6.77783,6.12207-12.56689,11.62207-13.78906l26.37793-8.0332
      c3.48828-4.64453,10.21094-7.8999,10.21094-7.8999l-31,9.18896l-245.02246-23.57813c0,0-7.44434-1.2998-14.33301,0.64453
      c-11.1001,3.1333-34.6665,9.67773-35.75586,9.97803c-1.42188,0.3999-1.37744,1-1.37744,1L336.2666,354.2334l48.04492,15.31104
      c-19.74512,5.87793-32.30078,9.62207-32.87793,9.78906c-1.43359,0.42188-2.83398,0.85547-4.22266,1.2998
      c-23.2334-10.04443-58.56641-29.71094-58.56641-29.71094s-2.81104-1.1333-5.5-0.1333
      c-4.7666,1.7666-25.51123,8.0332-25.51123,8.0332l14.25537,25.28906c10.02246,0.91113,20.93359,1.88867,30.92236,2.77783
      c-9-8.37793-19.27783-18.3667-24.95557-23.62256l-10.3335-0.43311c0,0,11.07813-3.01123,14.11133-3.84473
      c3.04443-0.83301,5.48877,0.62256,5.48877,0.62256l47.47754,25.33301l13.64453,7.27783c2.08887-0.67773,4.22266-1.35547,6.41113-2
      c0.71094-0.21094,20.12207-5.98877,48.86719-14.54443l15.2666,4.8667c-21.25586,6.3335-35.63379,10.62207-35.63379,10.62207
      l36.63379-10.31104L465.74414,395.5c-23.59961,6.83301-42.25488,12.2334-51.22168,14.81152
      c-30.87793,8.87695-90.5332,12.07715-116.78906,13.08887l49.32227-19.84473l7.71094-1.87793c0,0-2.95508-1.61133-6.77734-3.2666
      c-0.08887-0.0332-0.17773-0.07813-0.2666-0.11133c-3.75586-1.59961-8.27832-3.19971-11.7002-3.35547
      c-7.04492-0.33301-88.64453-8.97754-93.75586-8.87744c-5.11084,0.1001-8.31104,1.25537-8.31104,1.25537l-14.61133,5.87793
      l-7.78857,3.13281l-82.18896,23.98926l80.31104-21.07813l5.85547-1.71094L271.08887,412.17773z M454.05566,336.5332
      l-7.97754-0.98877l-224.47803-31.7334l0.08887-0.05566l237.42236,27.7002
      C457.18848,332.82227,455.48926,334.5332,454.05566,336.5332z M527.09961,347.3999c0,0-0.39941-0.64453-1.08887-1.65527
      c-2.11035-3.08887-7.01074-9.54443-11.88867-11.30029c-2.82227-1.02197-7.35547-1.17773-7.35547-1.17773
      s2.5-5.11084,7.27832-5.97754c1.48828-0.27783,3.19922-0.1333,5.12207,0.69971
      C529.37793,332.42236,527.09961,347.3999,527.09961,347.3999z M719.98926,326.12207c0,0-14.7666,12.81104-48.64453,22.16699
      c-12.15625,3.35547-245.74512,73.44434-245.74512,73.44434s242.84473-76.08887,257.98926-80.92236
      C705.07813,333.95557,719.98926,326.12207,719.98926,326.12207z M635.07813,366.05566
      c-14.15625,4.19971-119.67773,35.54395-156.2334,46.39941c-7.01172,2.07813-20.25586,5.24512-20.25586,5.24512
      s137.47754-41.26709,156.66699-46.87793l37.91113-10.7334C653.16699,360.1001,651.95508,361.04443,635.07813,366.05566z"/>
    <polygon class="direct-to-door" points="459.02246,444.43359 410.2334,458.67773 209.37793,514.94434 522.62207,423.5 	"/>
    <polygon class="direct-to-door" points="240.7666,498.43359 511.95508,419.2666 457.85547,437.82227 372.21094,462.43359 	"/>
    <polygon class="direct-to-door" points="533.36621,463.05566 451.04492,487.08887 381.5,505.02246 567.98926,450.57813 	"/>
    <polygon class="direct-to-door" points="119.2666,334.3999 59.43311,349.5 168.91113,317.54443 135.28906,329.72217 	"/>
  </g>
  </svg>

</template>

<script>
export default {
  name: "directToDoor"
}
</script>

<style>
  .direct-to-door{fill:#333232;}
</style>
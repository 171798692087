
.col-md-6, .col-md-offset-3{
  margin-bottom: 25px;
}
.description {
  padding: 10px;
  text-align: center;
}

.thumbnail-box {
  padding: 0;
}


.applicator-card {
  transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
  border-radius: var(--applicator-border-radius);
  box-shadow: 0 1px 2px 0 rgba(black, 0.15);
  margin-bottom: var(--sidebar-padding);
  transform: translate3d(0, 0, 0);
  background-color: #ffffff;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  flex-wrap: nowrap;
  display: flex;

  &[aria-disabled] {
    pointer-events: none;
    user-select: none;
    -moz-user-focus: none;
  }

  &-loading {
    background-color: rgba(white, 0.85);
    justify-content: center;
    border-radius: inherit;
    align-items: center;
    position: absolute;
    overflow: hidden;
    display: flex;
    z-index: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  &.distributor {
    border-style: solid;
    border-width: 3px;
    border-color: #F78F1B;
    background-color: #ffb5001A;
  }

  &:hover,
  &:focus-within {
    box-shadow: 0 10px 20px rgba(black, 0.19), 0 6px 6px rgba(black, 0.23);
    transform: translate3d(0, -10px, 0);
    z-index: 2;
  }

  &-close {
    transition: box-shadow 200ms ease-in-out, color 200ms ease-in-out;
    box-shadow: 0 1px 2px 0 rgba(black, 0.15);
    transform: translate3d(50%, -50%, 0);
    justify-content: center;
    background-color: white;
    align-items: center;
    position: absolute;
    border-radius: 50%;
    font-size: 30px;
    color: #222222;
    line-height: 1;
    outline: none;
    display: flex;
    height: 36px;
    width: 36px;
    z-index: 2;
    padding: 0;
    border: 0;
    right: 0;
    top: 0;

    &:hover,
    &:focus-within {
      box-shadow: 0 10px 20px rgba(black, 0.19), 0 6px 6px rgba(black, 0.23);
      color: #f69422;
    }
  }

  &-label {
    border-radius: var(--applicator-border-radius);
    display: inline-flex;
    align-items: center;
    margin-bottom: 4px;
    font-weight: 600;
    padding: 4px 6px;
    font-size: 12px;
    color: white;

    &.certified {
      background-color: #333;
    }

    &.distributor {
      background-color: #3E6B85;
    }

    &.customers-choice {
      background-color: #f69422;
    }

    &.comingSoon {
      background-color: #0ba10b;
    }

    > .fa {
      margin-right: 4px;
      font-size: 16px;
    }
  }

  &-showcase {
    flex-direction: column;
    margin-bottom: 16px;
    display: flex;
    width: 35%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    &-slider {
      border-radius: var(--applicator-border-radius);
      background-color: #dadada;
      justify-content: center;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;
      display: flex;
      width: 100%;

      @media screen and (max-width: 1024px) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin: -16px -16px 0;
        padding-bottom: 80%;
        width: auto;
      }
    }

    &-projects {
      transition: transform 200ms ease-in-out;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      position: absolute;
      flex-wrap: nowrap;
      min-width: 100%;
      display: flex;
      height: 100%;
      width: auto;
      left: 0;
      top: 0;

      &[data-active='0'] {
        transform: translate3d(0, 0, 0);
      }

      &[data-active='1'] {
        transform: translate3d(-100%, 0, 0);
      }

      &[data-active='2'] {
        transform: translate3d(-200%, 0, 0);
      }

      &[data-active='3'] {
        transform: translate3d(-300%, 0, 0);
      }

      &[data-active='4'] {
        transform: translate3d(-400%, 0, 0);
      }

      > img {
        max-width: 100%;
        flex: 1 0 100%;
        display: block;
        height: auto;
        width: 100%;
      }
    }

    &-nav {
      margin-top: 6px;
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 6px;
      width: 100%;

      > button {
        border-radius: var(--applicator-border-radius);
        border: 2px solid #e6e6e6;
        background-color: transparent;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        display: flex;
        padding: 2px;
        flex: 1;

        > img {
          border-radius: inherit;
          display: block;
          width: 100%;
        }

        &[aria-selected] {
          border-color: #f69422;
        }

        &:hover,
        &:focus {
          outline: none;
        }
      }
    }
  }

  &-banner {
    border-radius: var(--applicator-border-radius);
    background-color: #dadada;
    justify-content: center;
    padding-bottom: 35%;
    position: relative;
    overflow: hidden;
    display: flex;
    width: 35%;
    z-index: 1;
    height: 0;

    @media screen and (max-width: 1024px) {
      padding-bottom: calc(56.25% + 32px);
      margin: -16px -16px 16px -16px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      width: auto;
    }

    > img {
      position: absolute;
      min-width: 100%;
      display: block;
      height: 100%;
      width: auto;
      top: 0;
    }

    &.placeholder {
      > svg {
        transform: translate3d(-50%, -50%, 0);
        position: absolute;
        opacity: 0.25;
        width: 50%;
        left: 50%;
        top: 50%;

        .logo-1,
        .logo-2 {
          fill: #3a3a39;
        }
      }
    }
  }

  &-content {
    flex-direction: row;
    padding: 16px;
    display: flex;
    width: 100%;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &-info-panel {
    flex-direction: column;
    word-wrap: break-word;
    max-width: 100%;
    font-size: 14px;
    display: flex;

    &-undefined {
      color: #a8a8a8;
    }

    &-pills,
    &-links {
      flex-direction: row;
      list-style: none;
      flex-wrap: wrap;
      margin-top: 4px;
      display: flex;
      width: 100%;
    }

    &-pills {
      > li {
        border-radius: var(--applicator-border-radius);
        background-color: #efefef;
        margin: 0 4px 4px 0;
        font-weight: 600;
        padding: 4px 6px;
        font-size: 12px;
      }
    }

    &-links {
      > li {
        + li::before {
          content: ',';
          margin: 0 4px 0 1px;
          color: #222222;
        }
      }
    }

    + .applicator-card-info-panel {
      margin-top: 8px;
    }
  }

  &-details {
    flex-direction: column;
    padding-left: 16px;
    color: #222222;
    display: flex;
    width: 100%;
    flex: 1;

    &:hover,
    &:focus-within {
      color: #222222 !important;

      h3 {
        color: #f69422;
      }
    }

    @media screen and (max-width: 1024px) {
      padding-left: 0;
    }

    &-header {
      align-items: flex-start;
      padding-bottom: 16px;
      flex-direction: row;
      display: flex;
    }

    &-avatar {
      background-color: white;
      border-radius: 50%;
      margin-right: 10px;
      overflow: hidden;
      height: 72px;
      width: 72px;
      z-index: 1;

      @media screen and (max-width: 1024px) {
        height: 60px;
        width: 60px;
      }
    }

    &-name {
      flex: 1;

      h3 {
        transition: color 200ms ease-in-out;
        line-height: 1.365;
        font-weight: 600;
        font-size: 19px;
        color: #222222;
        margin: 0;
      }
    }

    &-location {
      align-items: center;
      flex-direction: row;
      font-size: 14px;
      color: #222222;
      line-height: 1;
      margin: 4px 0;
      display: flex;

      > .fa {
        margin-right: 4px;
        font-size: 18px;
      }
    }

    &-rating {
      flex-direction: row;
      align-items: center;
      margin: 4px 0;
      display: flex;

      .fa {
        margin-right: 2px;
        color: #d8d8d8;
        font-size: 18px;

        &.rated {
          color: #f69422;
        }
      }

      &-count {
        margin-left: 6px;
        font-weight: 600;
        color: #8f8f8f;
        font-size: 12px;
      }
    }
  }

  &-actions {
    padding: 0 16px 16px;
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    width: 100%;

    .btn {
      flex: 1;

      + .btn {
        margin-left: 10px;
        margin-top: 0;
      }
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;

      .btn {
        flex: auto;

        + .btn {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }
}

<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="65px"
    height="65px"
    viewBox="0 0 180.41113 180.41162"
    style="enable-background:new 0 0 180.41113 180.41162;"
    xml:space="preserve">
    <g id="Layer_1">
      <path class="logo0" d="M90.20605,1.5C41.21484,1.5,1.5,41.21484,1.5,90.20557c0,48.99121,39.71484,88.70605,88.70605,88.70605
        c48.99023,0,88.70508-39.71484,88.70508-88.70605C178.91113,41.21484,139.19629,1.5,90.20605,1.5z M109.8584,57.47754H93.54688
        V39.22559c0-1.5957-0.83496-2.39355-2.50488-2.39355c-1.74902,0-2.62305,0.79785-2.62305,2.39355v68.28369
        c0,1.59668,0.87402,2.39258,2.62305,2.39258c1.66992,0,2.50488-0.7959,2.50488-2.39258V86.55469h16.31152v20.22217
        c0,4.64844-0.43457,9.86133-4.27637,12.66602c-3.83984,2.80273-9.5,3.22949-14.33887,3.22949
        c-13.98242,0-20.47461-2.9541-20.47461-18.15137V41.40918c0-14.57031,6.49219-17.40381,20.47461-17.40381
        c12.52051,0,18.61523,4.11621,18.61523,15.69434V57.47754z"/>
    </g>
    <g id="Layer_2">
      <rect x="71.04688" y="138.19287" class="logo1" width="38.31738" height="16.7207"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "cerakoteLogo"
  }
</script>

<style>
  .logo0{fill:none;stroke:#000000;stroke-width:1;stroke-miterlimit:10;}
  .logo1{fill:none;stroke:#F69422;stroke-width:1;stroke-miterlimit:10;}
</style>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 -0.25 800 620">
  <g>
    <path class="spa-i-2" d="M400.4502,3c-75.51611,0-142.6123,36.04248-185.03174,91.86084h370.06299
      C543.06348,39.04248,475.9668,3,400.4502,3z"/>
    <path class="spa-i-2" d="M400.4502,467.51953c75.98145,0,143.4375-36.48535,185.81152-92.88867H214.63965
      C257.01465,431.03418,324.47119,467.51953,400.4502,467.51953z"/>
    <path class="spa-i-4" d="M498.97559,335.6416c0.33105,0.07422,0.67773,0.13086,1.03613,0.17383
      C499.65332,335.77246,499.30664,335.71582,498.97559,335.6416z"/>
    <path class="spa-i-4" d="M497.91211,332.6416c0.29492,0.10645,0.60547,0.19434,0.93164,0.26855
      C498.51855,332.83496,498.20703,332.74805,497.91211,332.6416z"/>
    <path class="spa-i-4" d="M474.18359,219.41406c0.11621-0.03027,0.23828-0.05078,0.36719-0.05078
      C474.40039,219.36328,474.29688,219.37891,474.18359,219.41406z"/>
    <path class="spa-i-4" d="M432.35059,165.04297c0,0.02197-0.00684,0.0415-0.00781,0.06299
      c0.98145,0.00977,1.93848-0.0083,2.8291-0.07373c3.05469-0.06738,7.46289,0.82764,11.88281,2.77588
      c2.15918,0.95947,4.10254,2.04199,5.75488,3.15918c0.91797-1.34912,1.46289-2.94775,1.46289-4.66992
      c0-1.27686-0.29102-2.49658-0.81543-3.58203c-1.96973-4.27539-8.77344-7.44189-16.90918-7.44189
      c-1.5625,0-3.07813,0.12158-4.51758,0.34277c-0.19824,0.12891-0.4375,0.20605-0.69824,0.20605
      c-0.10156,0-0.2002-0.01465-0.29395-0.03711c-0.04297,0.0083-0.08691,0.01367-0.12988,0.02246
      c0.2793,0.43848,0.52539,0.89014,0.73438,1.35449c0.14355-0.05518,0.29883-0.08691,0.46191-0.08691
      c0.68066,0,1.23145,0.52148,1.23145,1.16406c0,0.5708-0.43457,1.04346-1.00879,1.14258
      c0.07129,0.41699,0.1084,0.8418,0.11816,1.27197c0.58008,0.09277,1.02441,0.56787,1.02441,1.14404
      c0,0.64258-0.55176,1.16406-1.23047,1.16406c-0.01953,0-0.03613-0.00391-0.05371-0.00537
      c-0.0957,0.38477-0.21582,0.76367-0.36133,1.13574C432.14258,164.30322,432.35059,164.64893,432.35059,165.04297z"/>
    <path class="spa-i-4" d="M493.47754,219.38525c0.04102,0.00684,0.0791,0.01807,0.11719,0.02734
      C493.55664,219.40332,493.51855,219.39209,493.47754,219.38525z"/>
    <path class="spa-i-4" d="M497.89941,335.33203c0.30469,0.11035,0.62988,0.20508,0.96875,0.28516
      C498.5293,335.53711,498.2041,335.44238,497.89941,335.33203z"/>
    <path class="spa-i-4" d="M500.12305,335.83008c0.38281,0.04004,0.77734,0.06055,1.18457,0.0625
      C500.90039,335.8916,500.50586,335.87012,500.12305,335.83008z"/>
    <path class="spa-i-4" d="M480.33398,203.30518c0.0127-0.00098,0.02441-0.00146,0.03711-0.00293
      C480.3584,203.30371,480.34668,203.3042,480.33398,203.30518z"/>
    <path class="spa-i-4" d="M500.13574,333.125c0.37988,0.03906,0.77148,0.05762,1.17188,0.05957
      C500.90527,333.18359,500.51367,333.16309,500.13574,333.125z"/>
    <path class="spa-i-4" d="M368.48193,164.08496c-0.14355-0.36914-0.26318-0.74609-0.35742-1.12842
      c-0.01807,0.00146-0.03564,0.00537-0.0542,0.00537c-0.6792,0-1.23096-0.52148-1.23096-1.16406
      c0-0.57617,0.44385-1.05176,1.02588-1.14404c0.00879-0.43018,0.04541-0.85498,0.11621-1.27197
      c-0.57324-0.09961-1.00732-0.57227-1.00732-1.14258c0-0.64258,0.55078-1.16406,1.23193-1.16406
      c0.16309,0,0.31836,0.03174,0.46045,0.08643c0.20898-0.46387,0.45459-0.91602,0.73633-1.354
      c-0.04346-0.00879-0.08838-0.01416-0.13135-0.02246c-0.09424,0.02246-0.19238,0.03711-0.29395,0.03711
      c-0.25879,0-0.49854-0.07617-0.69678-0.20557c-1.44385-0.2207-2.95654-0.34326-4.51855-0.34326
      c-8.13477,0-14.9502,3.1665-16.92041,7.44189c-0.52588,1.08545-0.81641,2.30518-0.81641,3.58203
      c0,1.63623,0.48682,3.16455,1.32129,4.47021c1.58594-1.04639,3.41895-2.05713,5.44873-2.95947
      c4.42041-1.94824,8.8291-2.84326,11.88428-2.77588c1.02686,0.07568,2.12891,0.0957,3.27441,0.06982
      c-0.00098-0.02002-0.00635-0.03809-0.00635-0.05908C367.94775,164.64551,368.15918,164.29492,368.48193,164.08496z"/>
    <path class="spa-i-4" d="M476.36133,334.05859c0.05176,0.00488,0.10254,0.01074,0.1543,0.01563
      C476.46387,334.06934,476.41406,334.06348,476.36133,334.05859z"/>
    <path class="spa-i-4" d="M499.00195,332.94629c0.31738,0.06836,0.65039,0.12207,0.99121,0.16211
      C499.65137,333.06836,499.31934,333.01465,499.00195,332.94629z"/>
    <path class="spa-i-4" d="M429.01758,154.7002c-0.06738-0.12354-0.14355-0.24365-0.2168-0.36572
      C428.87598,154.45557,428.94922,154.57764,429.01758,154.7002z"/>
    <path class="spa-i-4" d="M496.95508,334.9209c0.27832,0.14551,0.58105,0.2793,0.90234,0.39746
      C497.53613,335.2002,497.2334,335.06641,496.95508,334.9209z"/>
    <path class="spa-i-4" d="M477.38867,334.20801c0.10938,0.02246,0.21875,0.04688,0.3252,0.07129
      C477.60645,334.25488,477.49902,334.23047,477.38867,334.20801z"/>
    <path class="spa-i-4" d="M478.41309,334.46875c0.09375,0.0293,0.18555,0.05957,0.27441,0.09082
      C478.59863,334.52832,478.50684,334.49805,478.41309,334.46875z"/>
    <path class="spa-i-4" d="M371.28906,154.7041c0.07275-0.12695,0.14844-0.25391,0.22705-0.37891
      C371.43896,154.45068,371.36035,154.57617,371.28906,154.7041z"/>
    <path class="spa-i-4" d="M426.29688,164.58398c1.15723-1.44336,1.85742-3.23096,1.85742-5.16943
      c0-0.34717-0.02344-0.68359-0.06738-1.01855c-0.23535-4.85693-6.1543-8.86328-13.65234-8.86328
      c-3.89258,0-7.39355,1.05713-9.86621,2.7583c0.0752,0.84863,0.125,1.7334,0.1416,2.64697
      c0.01074,0.20117-0.01172,0.39111-0.01172,0.58252c0,0.22266,0.04492,0.45752,0.05566,0.68213
      c0.02051,1.22754,0.09375,2.40039,0.20313,3.51074c5.27539,0.3833,9.87988,1.33105,13.14941,2.62256
      C420.29102,163.3291,423.14746,164.08252,426.29688,164.58398z"/>
    <path class="spa-i-4" d="M500.76563,260.00195c0.06348,0.0625,0.12207,0.12695,0.17871,0.19141
      C500.8877,260.12891,500.8291,260.06445,500.76563,260.00195z"/>
    <path class="spa-i-4" d="M501.54102,261c0.04297,0.07227,0.08496,0.14453,0.12109,0.2168
      C501.62598,261.14453,501.58398,261.07227,501.54102,261z"/>
    <path class="spa-i-4" d="M501.32031,260.66406c0.05078,0.07227,0.10156,0.14258,0.14746,0.21484
      C501.42188,260.80664,501.37109,260.73633,501.32031,260.66406z"/>
    <path class="spa-i-4" d="M500.43652,259.67383c0.05957,0.05664,0.11816,0.1123,0.1748,0.16895
      C500.55469,259.78613,500.49609,259.73047,500.43652,259.67383z"/>
    <path class="spa-i-4" d="M501.0625,260.33203c0.05664,0.06836,0.11426,0.13672,0.16602,0.20508
      C501.17676,260.46875,501.11914,260.40039,501.0625,260.33203z"/>
    <path class="spa-i-4" d="M502.70313,292.66016c0.00195,0.01172,0.00195,0.02441,0.00391,0.03711
      C502.70508,292.68457,502.70508,292.67188,502.70313,292.66016z"/>
    <path class="spa-i-4" d="M499.23535,258.70313c0.02148,0.01563,0.04199,0.0293,0.06348,0.04492
      C499.27734,258.73242,499.25684,258.71875,499.23535,258.70313z"/>
    <path class="spa-i-4" d="M499.66895,259.02539c0.04297,0.03125,0.08398,0.06445,0.125,0.09668
      C499.75293,259.08984,499.71191,259.05664,499.66895,259.02539z"/>
    <path class="spa-i-4" d="M500.06934,259.34766c0.05371,0.04688,0.10742,0.0918,0.16113,0.13867
      C500.17578,259.43945,500.12305,259.39453,500.06934,259.34766z"/>
    <path class="spa-i-4" d="M483.89453,203.14746c-0.00098,0-0.00293,0-0.00488,0c0.42871,0,0.85352,0.00732,1.27051,0.01953
      C484.74414,203.15527,484.32227,203.14746,483.89453,203.14746z"/>
    <path class="spa-i-4" d="M501.98633,262.38672c0.00293,0.05078,0.00879,0.10156,0.00684,0.15137
      C501.99512,262.48828,501.98926,262.4375,501.98633,262.38672z"/>
    <path class="spa-i-4" d="M481.45703,203.22119c0.14063-0.0083,0.28418-0.0127,0.4248-0.02002
      C481.74121,203.2085,481.59668,203.21289,481.45703,203.22119z"/>
    <path class="spa-i-4" d="M485.22656,203.1709c0.39551,0.01221,0.78613,0.03027,1.16895,0.05371
      C486.0127,203.20117,485.62305,203.18311,485.22656,203.1709z"/>
    <path class="spa-i-4" d="M482.64648,203.1665c0.32324-0.00928,0.65234-0.0127,0.98242-0.01416
      C483.29883,203.1543,482.96973,203.15723,482.64648,203.1665z"/>
    <path class="spa-i-4" d="M501.71973,261.33691c0.03418,0.07129,0.06543,0.14355,0.09375,0.21387
      C501.78613,261.47949,501.75391,261.4082,501.71973,261.33691z"/>
    <path class="spa-i-4" d="M395.33838,159.6626c0.10742-1.09473,0.18164-2.25146,0.20557-3.45996
      c0-0.22461,0.04443-0.45947,0.04443-0.68213c0-0.19141-0.01074-0.38135-0.01074-0.58252
      c0.0166-0.91797,0.06543-1.80713,0.14111-2.65967c-2.46973-1.69385-5.97314-2.74561-9.84375-2.74561
      c-7.49756,0-13.41748,4.00635-13.65137,8.86328c-0.04736,0.33496-0.06836,0.67139-0.06836,1.01855
      c0,1.90723,0.67725,3.67139,1.79736,5.10352c2.9917-0.50195,5.7002-1.23145,7.79346-2.18213
      C385.18115,160.98242,389.83838,160.00586,395.33838,159.6626z"/>
    <path class="spa-i-4" d="M501.85547,261.67773c0.02344,0.06836,0.0459,0.1377,0.06348,0.20508
      C501.90137,261.81543,501.87891,261.74609,501.85547,261.67773z"/>
    <path class="spa-i-4" d="M501.94629,262.02539c0.01172,0.0625,0.02539,0.125,0.03223,0.1875
      C501.97168,262.15039,501.95801,262.08789,501.94629,262.02539z"/>
    <path class="spa-i-4" d="M332.06641,330.25293c0.31934,0.06836,0.65234,0.12305,0.99609,0.16504
      C332.71826,330.37695,332.38574,330.32129,332.06641,330.25293z"/>
    <path class="spa-i-4" d="M312.54248,326.79395c1.13037,0.59375,2.67383,1.00781,4.39795,1.00781
      C315.21631,327.80176,313.67285,327.3877,312.54248,326.79395z"/>
    <path class="spa-i-4" d="M475.23535,325.89941c0.40234,0,0.79492,0.02246,1.17871,0.0625
      C476.03027,325.92188,475.63574,325.89941,475.23535,325.89941z"/>
    <path class="spa-i-4" d="M332.06445,332.94629c0.32129,0.06836,0.65527,0.12402,1.00098,0.16211
      C332.71973,333.07031,332.38525,333.01465,332.06445,332.94629z"/>
    <path class="spa-i-4" d="M316.94043,325.10449c1.65674,0,3.17285-0.35156,4.29541-0.91699h-8.50928
      C313.8374,324.74121,315.30664,325.10449,316.94043,325.10449z"/>
    <path class="spa-i-4" d="M330.02197,334.9209c0.27881,0.14551,0.58154,0.28125,0.9043,0.39844
      C330.60449,335.20215,330.30127,335.06641,330.02197,334.9209z"/>
    <path class="spa-i-4" d="M333.20117,333.125c0.37939,0.03906,0.77246,0.05762,1.17578,0.05957
      C333.97217,333.18359,333.58008,333.16309,333.20117,333.125z"/>
    <path class="spa-i-4" d="M483.87402,325.10449c1.65625,0,3.17188-0.35156,4.29492-0.91699h-8.50977
      C480.76953,324.74121,482.23926,325.10449,483.87402,325.10449z"/>
    <path class="spa-i-4" d="M333.18848,335.83008c0.38232,0.04004,0.7793,0.06055,1.18848,0.0625
      C333.96729,335.8916,333.5708,335.87012,333.18848,335.83008z"/>
    <path class="spa-i-4" d="M330.96289,335.33203c0.30811,0.11035,0.63525,0.20605,0.97803,0.28613
      C331.59863,335.53809,331.271,335.44238,330.96289,335.33203z"/>
    <path class="spa-i-4" d="M332.03809,335.64063c0.33398,0.0752,0.68359,0.13379,1.04639,0.17578
      C332.72168,335.77441,332.37207,335.71582,332.03809,335.64063z"/>
    <path class="spa-i-4" d="M308.30029,334.00195c1.62354,0,3.12256,0.33594,4.24219,0.88477
      C311.42285,334.32617,309.93506,334.00195,308.30029,334.00195z"/>
    <path class="spa-i-4" d="M333.20166,330.43457c0.37891,0.04004,0.77148,0.0625,1.17432,0.06348
      C333.97217,330.49805,333.58008,330.47363,333.20166,330.43457z"/>
    <path class="spa-i-4" d="M330.979,329.94824c0.29443,0.10254,0.60547,0.19238,0.93115,0.26855
      C331.58447,330.14063,331.27344,330.05176,330.979,329.94824z"/>
    <path class="spa-i-4" d="M330.9624,327.24023c0.30078,0.10742,0.62012,0.2002,0.95313,0.27832
      C331.58252,327.44043,331.2627,327.34863,330.9624,327.24023z"/>
    <path class="spa-i-4" d="M330.02197,332.22266c0.27539,0.14746,0.57471,0.28027,0.89209,0.39648
      C330.59766,332.50293,330.29785,332.37012,330.02197,332.22266z"/>
    <path class="spa-i-4" d="M502.60449,292.37012c0.00195,0.00391,0.00391,0.00879,0.00586,0.0127
      C502.6084,292.37891,502.60645,292.37402,502.60449,292.37012z"/>
    <path class="spa-i-4" d="M333.20068,327.73828c0.37939,0.04004,0.77246,0.06152,1.17627,0.0625
      C333.97168,327.7998,333.5791,327.77734,333.20068,327.73828z"/>
    <path class="spa-i-4" d="M332.06055,327.55371c0.32275,0.07031,0.65771,0.12695,1.00391,0.16797
      C332.71777,327.68066,332.38232,327.62402,332.06055,327.55371z"/>
    <path class="spa-i-4" d="M330.02197,329.53711c0.2749,0.14355,0.57275,0.27051,0.89014,0.38477
      C330.5957,329.80762,330.29736,329.68066,330.02197,329.53711z"/>
    <path class="spa-i-4" d="M330.92529,327.22754c-0.32129-0.11914-0.62402-0.25098-0.90332-0.39941
      c-0.83936-0.42773-1.90527-0.73535-3.09326-0.86328c1.18799,0.12793,2.25391,0.43652,3.09326,0.86523
      C330.30029,326.97656,330.60303,327.10938,330.92529,327.22754z"/>
    <path class="spa-i-4" d="M477.58398,326.15332c0.33984,0.07324,0.66309,0.16211,0.96973,0.26465
      C478.24707,326.31543,477.92383,326.22656,477.58398,326.15332z"/>
    <path class="spa-i-4" d="M478.58887,329.12793c0.31543,0.10742,0.6123,0.22754,0.88672,0.36328
      C479.20117,329.35645,478.90527,329.23535,478.58887,329.12793z"/>
    <path class="spa-i-4" d="M477.58398,328.84766c0.33984,0.07422,0.66309,0.16406,0.96973,0.26855
      C478.24707,329.01172,477.92383,328.92188,477.58398,328.84766z"/>
    <path class="spa-i-4" d="M476.4541,325.9668c0.36523,0.04004,0.72266,0.09668,1.06152,0.16992
      C477.17676,326.06348,476.81934,326.00684,476.4541,325.9668z"/>
    <path class="spa-i-4" d="M476.4541,328.66406c0.36523,0.03906,0.72266,0.09668,1.06152,0.16992
      C477.17676,328.76074,476.81934,328.70313,476.4541,328.66406z"/>
    <path class="spa-i-4" d="M496.95508,329.53711c0.27344,0.14258,0.57031,0.27051,0.88867,0.38477
      C497.52832,329.80762,497.22949,329.68066,496.95508,329.53711z"/>
    <path class="spa-i-4" d="M330.97754,332.64063c0.2959,0.10742,0.60938,0.19531,0.93652,0.27148
      C331.58691,332.83594,331.27344,332.74805,330.97754,332.64063z"/>
    <path class="spa-i-4" d="M500.1377,330.43555c0.37793,0.03906,0.76953,0.06152,1.16992,0.0625
      C500.90527,330.49805,500.51563,330.47363,500.1377,330.43555z"/>
    <path class="spa-i-4" d="M350.09863,184.47021c-0.05859,0.05811-0.10645,0.1333-0.14746,0.20508
      C349.99316,184.60352,350.03857,184.53174,350.09863,184.47021z"/>
    <path class="spa-i-4" d="M497.91406,329.94824c0.29297,0.10254,0.60156,0.19141,0.92383,0.2666
      C498.5166,330.13867,498.20703,330.05176,497.91406,329.94824z"/>
    <path class="spa-i-4" d="M475.23535,328.5957c0.40234,0,0.79492,0.02344,1.17871,0.0625
      C476.03027,328.61816,475.63574,328.5957,475.23535,328.5957z"/>
    <path class="spa-i-4" d="M497.85645,327.22559c-0.32031-0.11816-0.62305-0.24902-0.90137-0.39746
      c-0.84082-0.42773-1.90527-0.73535-3.09277-0.86328c1.1875,0.12793,2.25195,0.43652,3.09277,0.86523
      C497.23242,326.97656,497.53516,327.10742,497.85645,327.22559z"/>
    <path class="spa-i-4" d="M478.58887,326.43164c0.31543,0.10645,0.6123,0.22949,0.88672,0.3623
      C479.20117,326.66113,478.90527,326.53809,478.58887,326.43164z"/>
    <path class="spa-i-4" d="M479.47559,326.79395c1.12988,0.59375,2.67383,1.00781,4.39844,1.00781
      C482.14941,327.80176,480.60547,327.3877,479.47559,326.79395z"/>
    <path class="spa-i-4" d="M496.95508,332.22266c0.27441,0.14648,0.57324,0.2793,0.89063,0.39551
      C497.53027,332.50195,497.23047,332.37012,496.95508,332.22266z"/>
    <path class="spa-i-4" d="M497.89844,327.24121c0.29785,0.10645,0.61523,0.19824,0.94531,0.27637
      C498.51465,327.43848,498.19629,327.34863,497.89844,327.24121z"/>
    <path class="spa-i-4" d="M390.73047,197.1665c3.09082-0.18018,6.28857-0.27979,9.57031-0.28516
      c2.08594,0.00391,4.13379,0.04785,6.14551,0.12305c-2.0459-0.08105-4.12402-0.12988-6.23535-0.13477
      C396.9624,196.87744,393.79541,196.98193,390.73047,197.1665z"/>
    <path class="spa-i-4" d="M500.13574,327.73828c0.37793,0.04004,0.77148,0.06152,1.17188,0.0625
      C500.90527,327.7998,500.51367,327.77734,500.13574,327.73828z"/>
    <path class="spa-i-4" d="M498.99902,327.55371c0.31836,0.07031,0.65039,0.12598,0.99219,0.16602
      C499.64941,327.67969,499.31738,327.62402,498.99902,327.55371z"/>
    <path class="spa-i-4" d="M390.20508,197.1958c-0.15967,0.01025-0.31689,0.02246-0.4751,0.0332
      C389.88818,197.21826,390.04492,197.20605,390.20508,197.1958z"/>
    <path class="spa-i-4" d="M499.00293,330.25293c0.31641,0.06836,0.64746,0.12305,0.9873,0.16309
      C499.65039,330.37598,499.31934,330.32129,499.00293,330.25293z"/>
    <path class="spa-i-4" d="M298.98584,261.87793c0.01367-0.05762,0.0332-0.11426,0.05176-0.17188
      C299.02002,261.76367,298.99951,261.82031,298.98584,261.87793z"/>
    <path class="spa-i-4" d="M298.92383,262.20898c0.00586-0.05078,0.01855-0.10156,0.02734-0.15234
      C298.94238,262.10742,298.92969,262.1582,298.92383,262.20898z"/>
    <path class="spa-i-4" d="M500.5625,290.40332c0.01563,0.01074,0.03418,0.02344,0.0498,0.03613
      C500.59668,290.42676,500.57813,290.41406,500.5625,290.40332z"/>
    <path class="spa-i-4" d="M299.44043,260.87012c0.03955-0.0625,0.0835-0.12402,0.12793-0.18652
      C299.52393,260.74609,299.47998,260.80664,299.44043,260.87012z"/>
    <path class="spa-i-4" d="M307.25195,219.41357c0.11621-0.02979,0.2373-0.05029,0.36621-0.05029
      C307.46826,219.36328,307.36523,219.37891,307.25195,219.41357z"/>
    <path class="spa-i-4" d="M299.0918,261.54492c0.02344-0.06055,0.05225-0.12207,0.08008-0.18359
      C299.14404,261.42285,299.11523,261.48438,299.0918,261.54492z"/>
    <path class="spa-i-4" d="M299.24414,261.20898c0.03223-0.0625,0.06885-0.12598,0.10498-0.1875
      C299.31299,261.08301,299.27637,261.14648,299.24414,261.20898z"/>
    <path class="spa-i-4" d="M490.05664,203.64502c0.2373,0.0415,0.47266,0.0835,0.69824,0.12842
      C490.5293,203.72852,490.29395,203.68652,490.05664,203.64502z"/>
    <path class="spa-i-4" d="M486.49316,203.23193c0.37012,0.02393,0.7334,0.05127,1.08984,0.08447
      C487.22656,203.2832,486.86328,203.25586,486.49316,203.23193z"/>
    <path class="spa-i-4" d="M323.13281,203.64648c0.23242,0.04102,0.46436,0.08252,0.68652,0.12646
      C323.59717,203.729,323.36523,203.6875,323.13281,203.64648z"/>
    <path class="spa-i-4" d="M324.24219,203.86621c0.16016,0.03516,0.32178,0.0708,0.47656,0.10889
      C324.56396,203.93652,324.40234,203.90137,324.24219,203.86621z"/>
    <path class="spa-i-4" d="M488.89844,203.46729c0.29785,0.03955,0.59082,0.08203,0.875,0.12744
      C489.48926,203.54932,489.19629,203.50684,488.89844,203.46729z"/>
    <path class="spa-i-4" d="M299.68164,260.52734c0.04639-0.05957,0.09473-0.11914,0.14502-0.17773
      C299.77637,260.4082,299.72803,260.46777,299.68164,260.52734z"/>
    <path class="spa-i-4" d="M487.71777,203.33105c0.33887,0.03271,0.67285,0.06885,0.99512,0.10986
      C488.38867,203.3999,488.05664,203.36377,487.71777,203.33105z"/>
    <path class="spa-i-4" d="M326.54346,219.38525c0.0415,0.00684,0.08057,0.01807,0.11963,0.02832
      C326.62402,219.40381,326.58496,219.39209,326.54346,219.38525z"/>
    <path class="spa-i-4" d="M332.93799,262.79102c-0.23975,1.28125-2.85449,3.44727-6.88867,5.83398v0.00391
      C330.0918,266.23633,332.71191,264.06738,332.93799,262.79102z"/>
    <path class="spa-i-4" d="M300.89209,290.02539c0.09277-0.0625,0.18896-0.12305,0.28516-0.18359
      C301.08154,289.90234,300.98486,289.96289,300.89209,290.02539z"/>
    <path class="spa-i-4" d="M300.27783,290.44727c0.0249-0.01758,0.0498-0.03516,0.07471-0.05273
      C300.32764,290.41211,300.30273,290.42969,300.27783,290.44727z"/>
    <path class="spa-i-4" d="M298.29004,292.38379c0.00195-0.00488,0.00586-0.01074,0.00781-0.01465
      C298.2959,292.37305,298.29199,292.37891,298.29004,292.38379z"/>
    <path class="spa-i-4" d="M322.83936,203.59424c-0.1499-0.02393-0.30371-0.04541-0.45703-0.06836l-0.00049,0.00244
      C322.53418,203.55078,322.69043,203.57031,322.83936,203.59424z"/>
    <path class="spa-i-4" d="M491.16602,203.86426c0.16406,0.03613,0.3291,0.07227,0.48633,0.11133
      C491.49512,203.93652,491.33008,203.90088,491.16602,203.86426z"/>
    <path class="spa-i-4" d="M168.19092,235.25977c0,52.29395,17.28418,100.55078,46.44873,139.37109h371.62207
      c29.16504-38.82031,46.44922-87.07715,46.44922-139.37109c0-52.75635-17.59277-101.40381-47.22949-140.39893H215.41846
      C185.78418,133.85596,168.19092,182.50342,168.19092,235.25977z M334.41895,327.80176h0.00098l-0.02197,2.69727
      c0.00684,0,0.01367,0.00098,0.021,0.00098h0.00098l-0.02197,2.68457c0.00684,0,0.01367,0.00098,0.021,0.00098l-0.021,2.70703
      c0.00684,0,0.01367,0,0.021,0l-0.021,2.69824c-1.72314,0-3.23389-0.36914-4.37598-0.96289
      c-1.11963-0.58203-2.64063-0.93945-4.31885-0.93945c-1.68945,0-3.24609,0.35742-4.37646,0.93945
      c-1.12939,0.58203-2.68604,0.96289-4.38623,0.96289c-1.72412,0-3.26758-0.41504-4.39795-1.00781
      c-1.11963-0.55859-2.60742-0.89453-4.24219-0.89453c-1.70068,0-3.2002,0.33594-4.31934,0.91797
      c-1.14063,0.59082-2.70752,0.98438-4.41992,0.98438v-2.6748c1.7124,0,3.2793-0.41406,4.41992-1.00781
      c-1.14063,0.5918-2.70752,0.98438-4.41992,0.98438v-2.68555c1.7124,0,3.2793-0.40234,4.41992-1.00684
      c-1.14063,0.60449-2.70752,0.98535-4.41992,0.98535V330.5v-2.69824v-2.69727c1.7124,0,3.2793-0.38086,4.41992-0.98535
      c0.06738-0.03516,0.14111-0.06543,0.2124-0.09961v-6.35645h-0.0332c1.72217,0,3.14453-1.31055,3.14453-2.94141
      c0-0.24805-0.08838-0.45996-0.14648-0.69727c0.13525,0.04688,0.31396,0.06934,0.45996,0.06934
      c-0.62744,0-1.1416-0.40234-1.1416-0.88379s0.51416-0.87305,1.1416-0.87305h18.67676c0.63867,0,1.1416,0.3916,1.1416,0.87305
      s-0.50293,0.88379-1.1416,0.88379c0.16846,0,0.34766-0.0127,0.49219-0.06934c-0.05518,0.2373-0.1001,0.46191-0.1001,0.69727
      c0,1.63086,1.40967,2.94141,3.14502,2.94141h-0.04395v6.36035c0.07813,0.03613,0.15918,0.07031,0.23389,0.1084
      c1.14209,0.60352,2.67529,0.97266,4.39697,0.97266h0.00098l-0.02197,2.69727
      C334.40479,327.80176,334.41162,327.80176,334.41895,327.80176z M501.35254,327.80176l-0.02148,2.69727
      c0.00781,0,0.01465,0.00098,0.02148,0.00098l-0.02148,2.68457c0.00781,0,0.01465,0.00098,0.02148,0.00098l-0.02148,2.70703
      c0.00781,0,0.01465,0,0.02148,0l-0.02148,2.69824c-1.72363,0-3.23438-0.36914-4.37598-0.96289
      c-1.12012-0.58203-2.6416-0.93945-4.31934-0.93945c-1.69043,0-3.24609,0.35742-4.37598,0.93945s-2.68555,0.96289-4.38574,0.96289
      c-1.72461,0-3.26855-0.41504-4.39844-1.00781c-1.11816-0.55859-2.60742-0.89453-4.24023-0.89453
      c-1.7041,0-3.20215,0.33594-4.31934,0.91797c-1.14258,0.59082-2.70996,0.98438-4.42188,0.98438v-2.6748
      c1.71191,0,3.2793-0.41406,4.42188-1.00781c-1.14258,0.5918-2.70996,0.98438-4.42188,0.98438v-2.68555
      c1.71191,0,3.2793-0.40234,4.42188-1.00684c-1.14258,0.60449-2.70996,0.98535-4.42188,0.98535V330.5v-2.69824v-2.69727
      c1.71191,0,3.2793-0.38086,4.42188-0.98535c0.06738-0.03516,0.13965-0.06543,0.21094-0.09961v-6.35645h-0.03418
      c1.72461,0,3.14453-1.31055,3.14453-2.94141c0-0.24805-0.08984-0.45996-0.14453-0.69727
      c0.13281,0.04688,0.31445,0.06934,0.45801,0.06934c-0.62598,0-1.14063-0.40234-1.14063-0.88379s0.51465-0.87305,1.14063-0.87305
      h18.67969c0.63574,0,1.13965,0.3916,1.13965,0.87305s-0.50391,0.88379-1.13965,0.88379c0.16504,0,0.3457-0.0127,0.49121-0.06934
      c-0.05664,0.2373-0.10156,0.46191-0.10156,0.69727c0,1.63086,1.41016,2.94141,3.14453,2.94141h-0.0459v6.36035
      c0.07813,0.03613,0.16113,0.07031,0.23633,0.1084c1.1416,0.60352,2.6748,0.97266,4.39746,0.97266l-0.02148,2.69727
      C501.33887,327.80176,501.3457,327.80176,501.35254,327.80176z M471.58691,200.86523c0.2002,0,0.39063,0.07861,0.50195,0.22363
      l0.03613-0.17383c0-0.00146-0.00195-0.00488-0.00195-0.00586c-0.02344-0.1792-0.08984-0.6377-0.08984-0.6377
      c-0.14648-0.72656,0.97363-1.56641,2.57324-1.92432c0.625-0.14453,1.19043-0.17871,1.6748-0.13379
      c-0.04102-0.07031-0.07227-0.14795-0.07227-0.23584c0-0.26514,0.22461-0.48047,0.50293-0.48047
      c0.27734,0,0.50293,0.21533,0.50293,0.48047c0,0.05566-0.01563,0.10693-0.0332,0.15576
      c0.70703-0.43408,1.91797-0.72559,3.31152-0.72559c0.68555,0,1.32813,0.06982,1.89258,0.19287
      c-0.03906-0.19385-0.06836-0.39551-0.06836-0.60742c0-0.80908,0.3125-1.50879,0.77246-1.88965
      c-0.08398-0.09521-0.14941-0.20508-0.19043-0.32666h-0.4043v-0.64844h0.41895c0.1084-0.27344,0.3418-0.48633,0.63379-0.58105
      v-0.87402h-0.69238v-0.6499h0.69238v-0.64746h0.68457v0.64746h0.68066v0.6499h-0.68066v0.87793
      c0.28711,0.09668,0.51563,0.30713,0.62109,0.57715h0.47363v0.64844h-0.45898c-0.04297,0.12988-0.11426,0.24756-0.20703,0.34766
      c0.44531,0.38477,0.74609,1.07422,0.74609,1.86865c0,0.21631-0.0293,0.4209-0.07129,0.61914
      c0.5752-0.13086,1.24219-0.20459,1.9502-0.20459c1.33984,0,2.49805,0.27148,3.21289,0.67773
      c-0.00781-0.03516-0.02246-0.06934-0.02246-0.10791c0-0.26514,0.22461-0.48047,0.50293-0.48047
      c0.27734,0,0.50293,0.21533,0.50293,0.48047c0,0.09033-0.0332,0.16943-0.07715,0.24121
      c0.50391-0.05762,1.09766-0.0249,1.7666,0.12842c1.59082,0.35791,2.71973,1.19775,2.5752,1.92432
      c0,0-0.06836,0.4585-0.08887,0.6377c-0.00195,0.0127-0.00781,0.03564-0.01367,0.05273
      c0.10059-0.05664,0.21289-0.09668,0.33789-0.09668c0.33594,0,0.65039,0.22363,0.65039,0.31348c0,0-0.53906,0.16699-0.75293,0.36963
      c-0.2334,0.20947-0.52441,0.56934-0.66016,0.73193c-0.00195,0.12207-0.0625,0.25977-0.18945,0.36426
      c-0.15527,0.1377-0.34961,0.18701-0.49121,0.13721c-0.20605,0.29785-0.57813,0.79346-0.92969,0.99268
      c-0.28027,0.16797-0.7168,0.34717-1.06445,0.27979c-0.02637-0.00439-0.0498-0.01904-0.07813-0.02637l-0.05469,0.14941
      c-0.06738-0.01953-0.14258-0.03711-0.21289-0.05615c0.15039,0.0415,0.30566,0.08057,0.44727,0.12354
      c0.14551,0.04492,0.24609,0.2124,0.2002,0.35791c-0.04199,0.12109-0.21289,0.22656-0.35742,0.19043
      c-0.00098,0.00391-0.16504,0.28857-0.4082,0.71436l0.27441,0.58398l-0.65625,0.13135
      c-0.125,0.25879-0.22363,0.49609-0.2168,0.49609c-1.95703-0.60449-4.49805-0.90771-7.6084-0.91797
      c-2.94727,0.00977-5.75684,0.35156-7.38281,0.86963c1.64453-0.50635,4.52051-0.85938,7.39258-0.85938
      c2.9541,0,5.875,0.36914,7.50977,0.89502c0.21191,0.08984,0.36914,0.24756,0.41309,0.42529
      c0.07813,0.39258-0.3584,0.7168-1.0957,0.94043c-0.59277,0.1499-1.72656,0.3374-3.06836,0.48193h8.77441v6.53467h-3.17676
      c0.63672,0,1.15137,0.33594,1.15137,0.73877c0,0.29346-0.25977,0.54541-0.63867,0.66797
      c0.01074-0.00293,0.02539-0.00488,0.03516-0.00732c-0.64844,0.21289-1.15234,0.80518-1.15234,1.48779
      c0,0.68311,0.45703,1.26514,1.10742,1.47705c-0.01563-0.00488-0.03223-0.00732-0.04883-0.01221
      c0.39941,0.13574,0.67578,0.44092,0.67578,0.79492c0,0.49316-0.50391,0.88477-1.13965,0.88477h-18.67969
      c-0.62598,0-1.14063-0.3916-1.14063-0.88477c0-0.36377,0.29688-0.67627,0.71387-0.80664
      c0.6416-0.21191,1.05371-0.8374,1.05371-1.45313c0-0.64844-0.38086-1.24268-1.05176-1.44336l-0.10059-0.02197
      c0.15625,0.05566,0.33496,0.06689,0.52539,0.06689c-0.62598,0-1.14063-0.33545-1.14063-0.75
      c0-0.40283,0.51465-0.73877,1.14063-0.73877h-3.07715v-6.53467h8.64746c-1.34473-0.14453-2.48145-0.33203-3.07422-0.48193
      c-0.73926-0.22363-1.17578-0.54785-1.09766-0.94043c0.04492-0.17285,0.19434-0.3252,0.39648-0.41504
      c-0.04785,0.01563-0.10449,0.03027-0.14941,0.04736c0.01758,0-0.06348-0.22852-0.1709-0.52393l-0.02051,0.03076l-0.64844-0.21338
      l0.34668-0.55957l0.0293,0.00586c-0.20801-0.4082-0.43164-0.71094-0.43164-0.71094
      c-0.14551,0.04492-0.32422-0.06689-0.37012-0.18994c-0.04395-0.14551,0.05566-0.31299,0.20117-0.35791
      c0.10645-0.03174,0.22363-0.06055,0.33203-0.0918l-0.05957-0.15381c-0.05469,0.021-0.10742,0.04541-0.16895,0.05518
      c-0.33594,0.06738-0.77344-0.11182-1.05371-0.27979c-0.38477-0.2124-0.81445-0.77002-1.01855-1.05566
      c-0.13672,0.13379-0.39551,0.09961-0.58203-0.07422c-0.10938-0.09131-0.17383-0.20801-0.19336-0.31836
      c-0.10645-0.12305-0.43652-0.54297-0.68945-0.77783c-0.22461-0.20264-0.76172-0.36963-0.76172-0.36963
      C470.93652,201.08887,471.25098,200.86523,471.58691,200.86523z M461.48145,167.27051c0,0.64258-0.55078,1.16406-1.23145,1.16406
      s-1.23047-0.52148-1.23047-1.16406c0-0.64355,0.5498-1.16309,1.23047-1.16309S461.48145,166.62695,461.48145,167.27051z
      M457.76367,174.41016c0-0.64258,0.55176-1.16357,1.23047-1.16357c0.68066,0,1.23438,0.521,1.23438,1.16357
      s-0.55371,1.16504-1.23438,1.16504C458.31543,175.5752,457.76367,175.05273,457.76367,174.41016z M458.54883,177.37549
      c0,0.64209-0.55176,1.16357-1.23242,1.16357c-0.67871,0-1.23047-0.52148-1.23047-1.16357
      c0-0.64307,0.55176-1.16406,1.23047-1.16406C457.99707,176.21143,458.54883,176.73242,458.54883,177.37549z M460.11523,172.12744
      c-0.68066,0-1.23242-0.521-1.23242-1.16406c0-0.64258,0.55176-1.16357,1.23242-1.16357c0.67871,0,1.23145,0.521,1.23145,1.16357
      C461.34668,171.60645,460.79395,172.12744,460.11523,172.12744z M460.57422,163.64453c0,0.64307-0.55176,1.16357-1.23047,1.16357
      c-0.67969,0-1.23047-0.52051-1.23047-1.16357c0-0.64258,0.55078-1.16309,1.23047-1.16309
      C460.02246,162.48145,460.57422,163.00195,460.57422,163.64453z M457.45215,159.1792c0.67969,0,1.23047,0.52197,1.23047,1.16406
      c0,0.64258-0.55078,1.16357-1.23047,1.16357c-0.68066,0-1.23145-0.521-1.23145-1.16357
      C456.2207,159.70117,456.77148,159.1792,457.45215,159.1792z M454.93457,156.46045c0.67871,0,1.23145,0.521,1.23145,1.16357
      s-0.55273,1.16406-1.23145,1.16406c-0.68164,0-1.23242-0.52148-1.23242-1.16406S454.25293,156.46045,454.93457,156.46045z
      M452.0127,154.21045c0.67969,0,1.23047,0.52051,1.23047,1.16309c0,0.64404-0.55078,1.16455-1.23047,1.16455
      c-0.68066,0-1.23145-0.52051-1.23145-1.16455C450.78125,154.73096,451.33203,154.21045,452.0127,154.21045z M448.52148,152.36426
      c0.67969,0,1.22949,0.52148,1.22949,1.16406s-0.5498,1.16357-1.22949,1.16357s-1.23047-0.521-1.23047-1.16357
      S447.8418,152.36426,448.52148,152.36426z M444.89453,151.05518c0.68066,0,1.23047,0.521,1.23047,1.16357
      s-0.5498,1.16406-1.23047,1.16406c-0.67871,0-1.23047-0.52148-1.23047-1.16406S444.21582,151.05518,444.89453,151.05518z
      M440.84375,150.32715c0.68066,0,1.23242,0.52148,1.23242,1.16406s-0.55176,1.16357-1.23242,1.16357
      c-0.67871,0-1.23145-0.521-1.23145-1.16357S440.16504,150.32715,440.84375,150.32715z M436.99316,150.12598
      c0.68066,0,1.2334,0.52148,1.2334,1.16406c0,0.64307-0.55273,1.16357-1.2334,1.16357c-0.67969,0-1.22949-0.52051-1.22949-1.16357
      C435.76367,150.64746,436.31348,150.12598,436.99316,150.12598z M433.22363,150.26074c0.68066,0,1.23047,0.521,1.23047,1.16357
      c0,0.64209-0.5498,1.16406-1.23047,1.16406c-0.67969,0-1.23047-0.52197-1.23047-1.16406
      C431.99316,150.78174,432.54395,150.26074,433.22363,150.26074z M429.44141,150.26074c0.67871,0,1.23145,0.521,1.23145,1.16357
      c0,0.64209-0.55273,1.16406-1.23145,1.16406c-0.68066,0-1.23047-0.52197-1.23047-1.16406
      C428.21094,150.78174,428.76074,150.26074,429.44141,150.26074z M426.58789,148.01123c0.68066,0,1.23145,0.52051,1.23145,1.16309
      c0,0.64355-0.55078,1.16357-1.23145,1.16357c-0.67871,0-1.23145-0.52002-1.23145-1.16357
      C425.35645,148.53174,425.90918,148.01123,426.58789,148.01123z M423.22949,146.15381c0.68066,0,1.23145,0.52051,1.23145,1.16455
      c0,0.64209-0.55078,1.16309-1.23145,1.16309c-0.67871,0-1.23047-0.521-1.23047-1.16309
      C421.99902,146.67432,422.55078,146.15381,423.22949,146.15381z M419.53711,145.0459c0.67969,0,1.23145,0.52051,1.23145,1.16309
      s-0.55176,1.16504-1.23145,1.16504s-1.23047-0.52246-1.23047-1.16504S418.85742,145.0459,419.53711,145.0459z M415.68848,144.38477
      c0.67969,0,1.23047,0.52246,1.23047,1.16406c0,0.64307-0.55078,1.16406-1.23047,1.16406c-0.68066,0-1.23242-0.521-1.23242-1.16406
      C414.45605,144.90723,415.00781,144.38477,415.68848,144.38477z M411.92773,144.44141c0.67969,0,1.23047,0.52051,1.23047,1.16309
      s-0.55078,1.16504-1.23047,1.16504c-0.68066,0-1.23242-0.52246-1.23242-1.16504S411.24707,144.44141,411.92773,144.44141z
      M408.01172,145.10059c0.67871,0,1.23145,0.52148,1.23145,1.16406c0,0.64307-0.55273,1.16455-1.23145,1.16455
      c-0.68164,0-1.2334-0.52148-1.2334-1.16455C406.77832,145.62207,407.33008,145.10059,408.01172,145.10059z M392.2876,145.10059
      c0.68018,0,1.23047,0.52148,1.23047,1.16406c0,0.64307-0.55029,1.16455-1.23047,1.16455c-0.6792,0-1.23047-0.52148-1.23047-1.16455
      C391.05713,145.62207,391.6084,145.10059,392.2876,145.10059z M388.38135,144.44141c0.68066,0,1.23096,0.52051,1.23096,1.16309
      s-0.55029,1.16504-1.23096,1.16504c-0.67871,0-1.22998-0.52246-1.22998-1.16504S387.70264,144.44141,388.38135,144.44141z
      M384.61084,144.38477c0.67969,0,1.23145,0.52246,1.23145,1.16406c0,0.64307-0.55176,1.16406-1.23145,1.16406
      c-0.68018,0-1.23145-0.521-1.23145-1.16406C383.37939,144.90723,383.93066,144.38477,384.61084,144.38477z M380.77295,145.0459
      c0.67969,0,1.22998,0.52051,1.22998,1.16309s-0.55029,1.16504-1.22998,1.16504c-0.68115,0-1.23193-0.52246-1.23193-1.16504
      S380.0918,145.0459,380.77295,145.0459z M377.06885,146.15381c0.67969,0,1.23047,0.52051,1.23047,1.16455
      c0,0.64209-0.55078,1.16309-1.23047,1.16309c-0.68066,0-1.23145-0.521-1.23145-1.16309
      C375.8374,146.67432,376.38818,146.15381,377.06885,146.15381z M373.72217,148.01123c0.68066,0,1.23047,0.52051,1.23047,1.16309
      c0,0.64355-0.5498,1.16357-1.23047,1.16357c-0.6792,0-1.23193-0.52002-1.23193-1.16357
      C372.49023,148.53174,373.04297,148.01123,373.72217,148.01123z M370.85742,150.26074c0.67969,0,1.23047,0.521,1.23047,1.16357
      c0,0.64209-0.55078,1.16406-1.23047,1.16406c-0.68018,0-1.23047-0.52197-1.23047-1.16406
      C369.62695,150.78174,370.17725,150.26074,370.85742,150.26074z M367.08643,150.26074c0.68018,0,1.23096,0.521,1.23096,1.16357
      c0,0.64209-0.55078,1.16406-1.23096,1.16406c-0.68115,0-1.23193-0.52197-1.23193-1.16406
      C365.85449,150.78174,366.40527,150.26074,367.08643,150.26074z M363.31445,150.12598c0.6792,0,1.23145,0.52148,1.23145,1.16406
      c0,0.64307-0.55225,1.16357-1.23145,1.16357c-0.67969,0-1.23096-0.52051-1.23096-1.16357
      C362.0835,150.64746,362.63477,150.12598,363.31445,150.12598z M359.46533,150.32715c0.67969,0,1.23047,0.52148,1.23047,1.16406
      s-0.55078,1.16357-1.23047,1.16357c-0.68018,0-1.23096-0.521-1.23096-1.16357S358.78516,150.32715,359.46533,150.32715z
      M355.41406,151.05518c0.67969,0,1.23193,0.521,1.23193,1.16357s-0.55225,1.16406-1.23193,1.16406
      s-1.23193-0.52148-1.23193-1.16406S354.73438,151.05518,355.41406,151.05518z M351.78857,152.36426
      c0.68018,0,1.23145,0.52148,1.23145,1.16406s-0.55127,1.16357-1.23145,1.16357c-0.68066,0-1.23096-0.521-1.23096-1.16357
      S351.10791,152.36426,351.78857,152.36426z M348.29639,154.21045c0.6792,0,1.23096,0.52051,1.23096,1.16309
      c0,0.64404-0.55176,1.16455-1.23096,1.16455c-0.68066,0-1.23096-0.52051-1.23096-1.16455
      C347.06543,154.73096,347.61572,154.21045,348.29639,154.21045z M345.36475,156.46045c0.68018,0,1.23096,0.521,1.23096,1.16357
      s-0.55078,1.16406-1.23096,1.16406c-0.6792,0-1.23096-0.52148-1.23096-1.16406S344.68555,156.46045,345.36475,156.46045z
      M342.84766,159.1792c0.67969,0,1.22998,0.52197,1.22998,1.16406c0,0.64258-0.55029,1.16357-1.22998,1.16357
      c-0.68018,0-1.23193-0.521-1.23193-1.16357C341.61572,159.70117,342.16748,159.1792,342.84766,159.1792z M340.95557,162.48145
      c0.68018,0,1.23193,0.52051,1.23193,1.16309c0,0.64307-0.55176,1.16357-1.23193,1.16357s-1.23193-0.52051-1.23193-1.16357
      C339.72363,163.00195,340.27539,162.48145,340.95557,162.48145z M341.31299,175.5752c-0.6792,0-1.23047-0.52246-1.23047-1.16504
      s0.55127-1.16357,1.23047-1.16357c0.67969,0,1.23145,0.521,1.23145,1.16357S341.99268,175.5752,341.31299,175.5752z
      M342.9917,176.21143c0.67969,0,1.23145,0.521,1.23145,1.16406c0,0.64209-0.55176,1.16357-1.23145,1.16357
      c-0.68018,0-1.23096-0.52148-1.23096-1.16357C341.76074,176.73242,342.31152,176.21143,342.9917,176.21143z M338.96338,170.96338
      c0-0.64258,0.55029-1.16357,1.23047-1.16357s1.23145,0.521,1.23145,1.16357c0,0.64307-0.55127,1.16406-1.23145,1.16406
      S338.96338,171.60645,338.96338,170.96338z M340.0498,166.10742c0.67969,0,1.23047,0.51953,1.23047,1.16309
      c0,0.64258-0.55078,1.16406-1.23047,1.16406c-0.68018,0-1.23193-0.52148-1.23193-1.16406
      C338.81787,166.62695,339.36963,166.10742,340.0498,166.10742z M304.65234,200.86523c0.01025,0,0.01855,0.00488,0.02881,0.00537
      c-0.18701-0.34424-0.67871-1.37305-0.37451-2.20996c0.37939-1.01855,1.01709-1.37646,1.58789-1.67871
      c0.57031-0.29102,1.76855-0.66016,1.76855-0.66016s0.125-0.0542,0.29785-0.12549c-0.13672-0.0459-0.24902-0.04834-0.33252,0.00244
      c0.11182-0.35889,0.24707-0.62598,0.52686-0.79492c0.49219-0.2915,1.17969-0.21338,1.95264,0.15967
      c0.15918-0.04102,0.27393-0.06982,0.27393-0.06982s0.31445-0.1792,0.62646-0.30273
      c0.32568-0.12305,0.76123-0.12305,1.01855-0.17822c0.24756-0.06787,0.88477-0.26904,1.26563-0.2915
      c0.58057-0.02197,1.521,0.11182,1.83496,0.11182c0.10596,0,0.30371,0.00732,0.53125,0.01563
      c0.12305-0.32959,0.28564-0.60156,0.47314-0.81152c-0.06445-0.08008-0.11475-0.17041-0.15039-0.2666h-0.41846v-0.64941h0.42627
      c0.10645-0.27002,0.33643-0.48291,0.62646-0.57861v-0.89844h-0.69385v-0.6377h0.69385v-0.63818h0.67188v0.63818h0.69287v0.6377
      h-0.69287v0.89209c0.30029,0.09326,0.53809,0.30859,0.64746,0.58496h0.45898v0.64941h-0.45166
      c-0.03955,0.10645-0.09717,0.2041-0.16992,0.29053c0.17236,0.20068,0.32227,0.45947,0.43848,0.76563
      c0.49854-0.03125,0.99316-0.06104,1.21338-0.06104c0.43555,0,0.76172-0.05566,1.26416,0
      c0.50439,0.06738,1.38818,0.3584,1.646,0.48193c0.14502,0.06934,0.68652,0.26904,1.23486,0.4624
      c0.88525-0.4917,1.67773-0.63232,2.22266-0.30615c0.29053,0.16895,0.41357,0.43604,0.52588,0.79492
      c-0.13428-0.07861-0.34668-0.02197-0.61523,0.14307c0.5708,0.13135,1.19531,0.28418,1.56689,0.46143
      c0.62646,0.30176,1.01807,0.80469,1.33105,1.23047c0.31299,0.41406,0.38086,0.87256,0.50439,1.17529
      c0.12354,0.29102,0.12354,0.94043,0,1.24121c-0.05127,0.11963-0.15576,0.29248-0.2627,0.45605
      c0.06738-0.01953,0.1333-0.04102,0.20703-0.04102c0.33447,0,0.64893,0.22363,0.64893,0.31348c0,0-0.53711,0.16699-0.75,0.36963
      c-0.23535,0.20947-0.52686,0.56982-0.6626,0.73193c-0.00195,0.12256-0.06299,0.25977-0.18896,0.36426
      c-0.1543,0.1377-0.34912,0.18701-0.4917,0.13721c-0.2041,0.29785-0.57764,0.79346-0.92969,0.99268
      c-0.27979,0.16797-0.71436,0.34717-1.0625,0.27979c-0.02832-0.00439-0.05127-0.01904-0.07861-0.02637l-0.05566,0.14941
      c-0.06348-0.01855-0.13379-0.03516-0.19971-0.05273c0.14551,0.04004,0.29688,0.07813,0.43506,0.12012
      c0.14502,0.04492,0.24707,0.2124,0.20068,0.35791c-0.04297,0.12109-0.21436,0.22705-0.35889,0.19043
      c-0.00146,0.00342-0.16553,0.28857-0.4082,0.71387l0.27539,0.58447l-0.6582,0.13135
      c-0.12158,0.25879-0.22217,0.49609-0.21484,0.49609c-1.95898-0.60449-4.49902-0.90771-7.60938-0.91797
      c-2.9458,0.00977-5.75684,0.35156-7.38281,0.86963c1.64502-0.50635,4.52051-0.85938,7.39355-0.85938
      c2.95508,0,5.875,0.36914,7.50879,0.89502c0.21338,0.08984,0.36914,0.24756,0.41406,0.42529
      c0.0791,0.39258-0.3584,0.7168-1.09717,0.94043c-0.59229,0.1499-1.72656,0.3374-3.06738,0.48193h8.77588v6.53467h-3.1792
      c0.63818,0,1.15381,0.33594,1.15381,0.73877c0,0.29346-0.26123,0.54443-0.63916,0.66748
      c0.00977-0.00244,0.02393-0.00439,0.03369-0.00684c-0.64893,0.21289-1.15137,0.80518-1.15137,1.48779
      c0,0.68311,0.45801,1.26514,1.10791,1.47705c-0.01611-0.00488-0.03418-0.00732-0.05029-0.0127
      c0.40039,0.13574,0.67578,0.44092,0.67578,0.79541c0,0.49316-0.50293,0.88477-1.1416,0.88477h-18.67676
      c-0.62744,0-1.1416-0.3916-1.1416-0.88477c0-0.36426,0.29736-0.67676,0.71436-0.80664
      c0.64111-0.21289,1.05518-0.83789,1.05518-1.45313c0-0.64844-0.38232-1.2417-1.05225-1.44336
      c-0.03418-0.00781-0.07031-0.01074-0.10303-0.02197l0.10254,0.02197h0.00049c0.13135,0.03125,0.27246,0.04492,0.42432,0.04492
      c-0.62744,0-1.1416-0.33545-1.1416-0.75c0-0.40283,0.51416-0.73877,1.1416-0.73877h-3.07813v-6.53467h8.64746
      c-1.34375-0.14453-2.48096-0.33203-3.07324-0.48193c-0.73828-0.22363-1.17578-0.54785-1.09766-0.94043
      c0.04443-0.17285,0.19385-0.3252,0.39697-0.41455c-0.04785,0.01514-0.10547,0.02979-0.15088,0.04688
      c0.01807,0-0.06348-0.22852-0.16992-0.52344l-0.02002,0.03027l-0.64893-0.21338l0.34668-0.55957l0.0293,0.00586
      c-0.20801-0.4082-0.43164-0.71094-0.43164-0.71094c-0.146,0.04492-0.3252-0.06689-0.37012-0.18994
      c-0.04395-0.14551,0.05664-0.31299,0.20215-0.35791c0.10449-0.03174,0.22168-0.06055,0.33057-0.0918l-0.05957-0.1543
      c-0.05469,0.02148-0.10938,0.0459-0.1709,0.05566c-0.33398,0.06738-0.77148-0.11182-1.05176-0.27979
      c-0.38477-0.2124-0.81396-0.77051-1.01904-1.05566c-0.13574,0.13379-0.39453,0.10059-0.58008-0.07422
      c-0.10986-0.09131-0.17529-0.20801-0.19482-0.31836c-0.10596-0.12256-0.43652-0.54297-0.69092-0.77783
      c-0.22363-0.20264-0.76025-0.36963-0.76025-0.36963C304.00342,201.08887,304.31641,200.86523,304.65234,200.86523z
      M307.86377,244.56836v-22.40674h18.18555v19.84033c0.01465,0,0.03027,0,0.04443,0c7.55908,0,14.14551,0.95703,18.21924,2.40234
      v-36.97021h40.68799c-7.4873-0.72217-14.14697-1.94971-19.49219-3.55859c-0.63867-0.20166-0.97461-0.78271-0.93994-1.39844
      c-0.01074-0.48975,0.23779-0.93359,0.67871-1.17969c-0.05371,0.0166-0.11182,0.03174-0.16504,0.04834
      c0.60498-0.28711,0.65039-0.97607,0.13379-2.52881l-0.20117,0.14502l-2.125-0.89404l1.25537-1.63867
      c-0.89063-1.28857-1.8584-1.37109-2.06299-1.37305c-0.01416,0.00391-0.02979,0.00781-0.04346,0.01172
      c-0.64893,0.18994-1.33203-0.05518-1.54492-0.67139c-0.21143-0.61523,0.13477-1.32031,0.78369-1.51074
      c0.38525-0.11816,0.78857-0.23145,1.18799-0.34619l-0.01514-0.04248c-0.22461,0.08643-0.45654,0.15771-0.70361,0.19873
      c-1.521,0.24561-3.44629-0.5249-4.72119-1.26465c-1.69385-0.97705-3.27734-2.9873-4.01611-4.01172
      c-0.62451,0.3252-1.59033,0.13818-2.34131-0.49805c-0.76318-0.65625-1.05615-1.5625-0.75586-2.18018
      c-0.00098,0.00293-0.00293,0.00586-0.00439,0.0083c0,0-1.24316-2.32666-2.69629-3.11035
      c-1.44482-0.78369-3.90674-0.58154-3.90674-0.58154c0-1.63281,1.21436-2.96436,2.86279-3.18799l-0.0332-0.30322l-1.5083-1.19727
      l0.21777-0.34668c-1.70117-2.18945-2.69238-4.75537-2.69238-7.47656c0-4.69971,2.98828-8.80713,7.51025-11.52637
      c-0.70166,0.44482-1.34033,0.92432-1.92383,1.4292c3.53223-3.05078,9.3877-5.04395,16.02393-5.04395
      c1.54492,0,3.04297,0.1167,4.48633,0.3208c0.20361-0.14355,0.45459-0.23145,0.729-0.23145
      c0.43457,0,0.81543,0.21436,1.0332,0.53662c0.20654,0.04248,0.41992,0.07715,0.62354,0.12354
      c-0.15283,0.16797-0.29297,0.34277-0.42822,0.521c0,0.01172-0.00342,0.02393-0.00439,0.03516
      c1.03027-1.25537,2.35547-2.37354,3.90137-3.29834c-1.02832,0.83008-1.86523,1.77197-2.50977,2.79297
      c2.45703-3.79541,7.84619-6.52979,14.28174-6.52979c3.84619,0,7.38086,0.95605,10.09619,2.53418
      c0.02197-0.146,0.04102-0.29932,0.06543-0.44189c0.11279-0.49365,0.34326-0.94482,0.64648-1.34717
      c-0.21143,0.16113-0.47656,0.26172-0.76904,0.26172c-0.68066,0-1.23145-0.521-1.23145-1.16309
      c0-0.64307,0.55078-1.16406,1.23145-1.16406c0.67969,0,1.23047,0.521,1.23047,1.16406c0,0.17725-0.04492,0.34229-0.12012,0.49268
      c0.55273-0.55859,1.28174-0.95898,2.08789-1.15625c-1.57568-0.33105-2.87012-1.36914-3.48877-2.74951
      c0,0-0.00049,0-0.00049-0.00049l-0.00049-0.00146c-0.00879-0.02148-0.01611-0.04297-0.02441-0.06348
      c-0.23975-0.54395-0.36719-1.146-0.36719-1.77051c0-2.16992,1.55322-3.99072,3.65967-4.53174v-0.00049v-3.52441h-2.70801v-2.5752
      h2.70801v-2.52832h2.71973v2.52832h2.70703v2.5752h-2.70703v3.55566c1.99316,0.56543,3.45508,2.28662,3.52734,4.34326
      c0.01758,0.05029,0.03027,0.10205,0.03027,0.15771c0,0.64893-0.14453,1.27637-0.39063,1.83545
      c-0.01074,0.02246-0.03418,0.02246-0.03418,0.03271h-0.00586c-0.62695,1.36816-1.91895,2.39648-3.48828,2.7207
      c0.78223,0.19629,1.48145,0.58838,2.01465,1.13428c-0.06836-0.14453-0.1084-0.30469-0.1084-0.47412
      c0-0.64307,0.5498-1.16406,1.22949-1.16406c0.68066,0,1.23145,0.521,1.23145,1.16406c0,0.64209-0.55078,1.16309-1.23145,1.16309
      c-0.29102,0-0.55371-0.09863-0.76563-0.2583c0.29395,0.40137,0.51855,0.85156,0.63086,1.34375
      c0.02441,0.14453,0.04297,0.2998,0.06543,0.44824c2.72754-1.58203,6.25586-2.54053,10.11914-2.54053
      c6.42969,0,11.81445,2.72949,14.27344,6.51904c-0.64258-1.0166-1.47656-1.95557-2.50195-2.78223
      c1.54492,0.92432,2.87012,2.0415,3.89941,3.29639c0-0.01123-0.00293-0.02148-0.00293-0.03174
      c-0.13672-0.17871-0.2793-0.354-0.43652-0.52246c0.20605-0.04688,0.42285-0.08154,0.63281-0.125
      c0.21875-0.32129,0.59766-0.53516,1.0332-0.53516c0.27344,0,0.52637,0.08789,0.72949,0.23096
      c1.44531-0.20361,2.94043-0.32031,4.48633-0.32031c7.75293,0,14.43066,2.71924,17.59863,6.66846
      c-0.90234-1.12744-2.08398-2.15771-3.49805-3.05371c4.50879,2.71924,7.49707,6.82666,7.49707,11.52637
      c0,2.72021-0.97949,5.28613-2.67969,7.47461l0.21875,0.34863l-1.59082,1.25293l-0.32031-0.52344l-0.08301,0.73975
      c0.09961-0.00732,0.2002-0.01514,0.30273-0.01514c1.89258,0,3.35742,1.44385,3.35742,3.23438c0,0-2.46289-0.20215-3.91699,0.58154
      c-1.45313,0.78369-2.68555,3.11035-2.68555,3.11035c-0.04492-0.08789-0.12207-0.20117-0.20215-0.2793
      c0.57324,0.59375,0.31445,1.69043-0.56934,2.45117c-0.75195,0.63623-1.7168,0.82324-2.3418,0.49805
      c-0.7373,1.02441-2.31738,3.03467-4.00293,4.01172c-1.27637,0.73975-3.20117,1.51025-4.7334,1.26465
      c-0.50684-0.08447-0.96289-0.2793-1.37891-0.54102l-0.06348,0.18652c0.63574,0.17676,1.25879,0.35938,1.86426,0.54443
      c0.65039,0.19043,0.99805,0.89551,0.78711,1.51074c-0.21484,0.61621-0.89551,0.86133-1.5459,0.67139
      c-0.01074-0.00342-0.02441-0.00586-0.03711-0.00977c-0.28613,0.0166-1.20801,0.16846-2.04688,1.40332l1.22266,1.60645
      l-2.11621,0.89404l-0.16406-0.11914c-0.50684,1.53467-0.45605,2.2168,0.1543,2.50293
      c-0.09961-0.03027-0.20215-0.05859-0.30273-0.08789c0.49023,0.2334,0.75879,0.70068,0.75,1.21924
      c0.0332,0.61572-0.30273,1.19678-0.92773,1.39844c-5.34668,1.60889-12.00195,2.83643-19.48926,3.55859h41.15234v36.91162
      c4.08496-1.41211,10.58887-2.3418,18.04004-2.34375v-19.84033h18.18457v22.38916
      c8.16309,2.39551,13.62598,6.00391,13.05957,9.06738c-0.06641,0.34668-4.13965,9.53418-4.13965,9.53418
      c0.01172-0.06641,0.04492-0.22266,0.05566-0.29004c-0.40332,2.10254-3.83789,3.45801-8.88477,3.81543l-0.03418-8.71777
      c1.58496,0,3.1123-0.17773,4.46582-0.33398c-1.28711-0.71191-2.8125-1.3916-4.52246-2.01465v21.38379
      c6.95117,2.55371,11.875,5.43848,11.39258,6.87207v0.01074l-1.66113,8.89844c-0.00195,0.05566-0.00977,0.10645-0.02734,0.1543
      c-0.13574,0.68359-0.94043,1.19824-2.09277,1.95898c-1.8125,1.26563-2.91113,3.51465-2.04785,4.55566
      c-1.50098-1.20898-2.45117-3.01074-2.45117-5.02539c0-2.0918,1.00684-3.98438,2.61914-5.19238
      c0.27051,0.15625,0.53125,0.31348,0.78418,0.46973c-1.79102-1.11914-4.12109-2.26563-6.46875-3.07813
      c-0.01563-0.00391-0.03223-0.00977-0.04688-0.01563v24.66992h-18.18457v-32.70508
      c-5.31445-3.14063-8.64063-6.05078-8.00195-7.24609c0,0,0.80762-8.79688,1.32129-9.22266l-0.04297,0.08398l0.05371-0.09375
      c1.17188-0.96094,3.52539-1.74023,6.66992-2.22363v-7.48926c-0.02148,0-0.04492-0.00098-0.06641-0.00098
      c-7.44434,0.24707-13.96191,1.38574-17.97363,2.9248v14.4541h0.05664v37.32227c0,3.10645-0.56738,6.08398-1.59863,8.85547
      c-2.31738,6.44336-7.11328,11.77539-13.4082,14.8916c-12.19824,5.97559-26.47754,9.55664-41.125,9.55664
      c-14.6377,0-29.15332-3.58105-41.35107-9.55664c-3.3833-1.67676-6.3374-3.99512-8.70654-6.78223v0.06641
      c-0.57471-0.70703-1.15039-1.48535-1.70801-2.33008c-0.90576-1.33008-1.69238-2.73926-2.3418-4.21973
      c-1.10059-2.43457-1.83057-5.05566-2.11963-7.80078c-0.13232-1.02246-0.21973-2.08203-0.24316-3.18457l0.07764-37.82422h0.02393
      v-13.38379c-3.99854-1.57227-10.59229-2.73926-18.14063-2.98926c-0.04004,0.00098-0.08252,0.00293-0.12305,0.00293V268.625
      l0.0332-8.7627c3.15674,0.48242,5.51709,1.26465,6.69287,2.22754l0.05469,0.09375l-0.04395-0.08398
      c0.51563,0.42578,1.3208,9.22266,1.3208,9.22266c0.64063,1.19922-2.70996,4.12598-8.05762,7.2793v32.67188h-18.18555v-24.65039
      c-2.354,0.81641-4.69141,1.9668-6.48291,3.08984c0.25977-0.16211,0.52881-0.32422,0.81055-0.48535
      c1.61035,1.20801,2.61816,3.10059,2.61816,5.19238c0,2.01465-0.95166,3.81641-2.45117,5.02539
      c0.8623-1.04102-0.23535-3.29004-2.04785-4.55566c-1.15283-0.76074-1.9585-1.27539-2.09375-1.95898
      c-0.01709-0.04785-0.02637-0.09863-0.02832-0.15332l-1.66064-8.89941v-0.01074c-0.479-1.42969,4.41846-4.30273,11.33594-6.85156
      v-19.05566l-0.0332,8.71777c-5.04639-0.35742-8.48291-1.71289-8.88428-3.81543c0.01025,0.06738,0.04395,0.22363,0.05518,0.29004
      c0,0-4.07373-9.1875-4.14111-9.53418C294.29395,250.56055,299.73438,246.96289,307.86377,244.56836z"/>
    <path class="spa-i-4" d="M298.1958,292.69727c0.00146-0.0127,0.00244-0.02539,0.00488-0.03711
      C298.19824,292.67188,298.19727,292.68457,298.1958,292.69727z"/>
    <path class="spa-i-4" d="M332.94678,262.48828c-0.00732-0.04297-0.02148-0.08594-0.03711-0.12695
      C332.92432,262.40234,332.93994,262.44531,332.94678,262.48828z"/>
    <path class="spa-i-4" d="M300.68945,259.47168c0.04199-0.03613,0.08545-0.07324,0.12891-0.1123
      C300.77441,259.39844,300.73145,259.43555,300.68945,259.47168z"/>
    <path class="spa-i-4" d="M300.30371,259.83008c0.04883-0.04785,0.09814-0.09473,0.14844-0.14258
      C300.40186,259.73535,300.35254,259.78223,300.30371,259.83008z"/>
    <path class="spa-i-4" d="M301.12842,259.10547c0.03027-0.02246,0.05908-0.04688,0.08936-0.06934
      C301.1875,259.05859,301.15869,259.08301,301.12842,259.10547z"/>
    <path class="spa-i-4" d="M303.3999,257.62598c1.35107,0.15625,2.87939,0.33398,4.46387,0.33398v-2.32715
      C306.17676,256.25,304.67139,256.92188,303.3999,257.62598z"/>
    <path class="spa-i-4" d="M299.96924,260.18164c0.04932-0.05566,0.1001-0.10938,0.15234-0.16406
      C300.06934,260.07227,300.01855,260.12598,299.96924,260.18164z"/>
    <path class="spa-i-4" d="M301.63184,258.72754c0.00586-0.00391,0.0127-0.00879,0.01953-0.01367
      C301.64453,258.71875,301.6377,258.72363,301.63184,258.72754z"/>
    <path class="spa-i-4" d="M332.95117,262.56055c0.00342,0.06055,0.00244,0.12305-0.00684,0.18848
      C332.95313,262.68359,332.95508,262.62012,332.95117,262.56055z"/>
    <path class="spa-i-4" d="M298.91064,262.53711c-0.00146-0.03906,0.00293-0.07813,0.00342-0.11719
      C298.91357,262.45898,298.90918,262.49805,298.91064,262.53711z"/>
    <path class="spa-i-4" d="M345.47021,161.04883c-0.16895,0.27197-0.32715,0.54639-0.46387,0.82764
      C345.14355,161.59619,345.30127,161.32031,345.47021,161.04883z"/>
    <path class="spa-i-4" d="M346.1875,160.04346c0.43213-0.53613,0.92627-1.0498,1.48193-1.53564
      C347.11475,158.99463,346.61914,159.50732,346.1875,160.04346z"/>
    <path class="spa-i-4" d="M467.95898,262.75098c-0.01074-0.06738-0.01074-0.13086-0.00781-0.19238
      C467.94727,262.62012,467.9502,262.68359,467.95898,262.75098z"/>
    <path class="spa-i-4" d="M454.73145,160.88477c-0.16016-0.24561-0.33203-0.48682-0.51855-0.72314
      C454.39941,160.39795,454.57129,160.63965,454.73145,160.88477z"/>
    <path class="spa-i-4" d="M467.95605,262.48926c0.00684-0.04395,0.02344-0.08691,0.03711-0.12891
      C467.97852,262.40234,467.96289,262.44531,467.95605,262.48926z"/>
    <path class="spa-i-4" d="M346.05664,160.21191c-0.16211,0.20996-0.31494,0.42383-0.45752,0.64014
      C345.74219,160.63574,345.89355,160.42139,346.05664,160.21191z"/>
    <path class="spa-i-4" d="M455.30273,161.87646c-0.13867-0.28711-0.30273-0.56787-0.47461-0.84521
      C455.00098,161.30859,455.16211,161.58984,455.30273,161.87646z"/>
    <path class="spa-i-4" d="M499.75195,289.85742c0.08203,0.05469,0.16895,0.1084,0.24902,0.16113
      C499.9209,289.96484,499.83398,289.91211,499.75195,289.85742z"/>
    <path class="spa-i-4" d="M467.96484,262.78906c0.22168,1.27051,2.81934,3.42578,6.83203,5.80664v-0.00391
      C470.79492,266.2168,468.2002,264.06445,467.96484,262.78906z"/>
    <path class="spa-i-0" d="M317.87646,198.62207l0.02539,0.01611l-0.6123,1.31934c0.03857,0.0293,0.06982,0.06348,0.09814,0.10205
      l1.23096-0.3584l0.43555,0.69385l-0.02295,0.0332h0.16943c0.15674-0.27881,0.33594-0.55859,0.51563-0.83789
      c1.18799-1.8042,2.61182-3.18945,3.85303-3.88037c-0.54834-0.19336-1.08984-0.39307-1.23486-0.4624
      c-0.25781-0.12354-1.1416-0.41455-1.646-0.48193c-0.50244-0.05566-0.82861,0-1.26416,0c-0.22021,0-0.71484,0.02979-1.21338,0.06104
      c0.17236,0.45068,0.27393,0.99561,0.27393,1.58447C318.48486,197.31494,318.24365,198.11084,317.87646,198.62207z"/>
    <path class="spa-i-0" d="M323.28613,198.8042l0.68066,0.65186l-0.80371,1.06494c0.03174,0.03662,0.06299,0.07422,0.08301,0.11865
      l1.28076-0.10986l0.26953,0.76025l-0.57178,0.53809l-1.09961-0.5957c-0.05225,0.04834-0.11377,0.08447-0.18164,0.10938
      c-0.07275,0.15137-0.10693,0.31348-0.10693,0.49707c0,0.62598,0.52686,1.12939,1.1875,1.12939
      c0.41357,0,0.77246-0.2002,0.98438-0.50342l0.01221-0.01465c-0.07129-0.09082-0.09326-0.23828-0.0459-0.3877
      c0.0791-0.20166,0.2583-0.33496,0.41406-0.30273c0.146,0.05566,0.21338,0.26904,0.146,0.47021
      c-0.05615,0.1499-0.16113,0.25732-0.26953,0.29346v0.01953c0,0.38086,0.15771,0.73926,0.5498,0.79492
      c0,0,0.42529,0.03467,1.01807-0.4248c0.26563-0.21045,0.47266-0.40137,0.62207-0.55029l-1.23828-0.13281l-0.12305-0.73779l0,0
      v-0.00098v0.00098l0.69336-0.45947l1.17432,0.97266c0.03467-0.00342,0.06738,0.00146,0.09863,0.00635
      c-0.0127-0.08008-0.07422-0.49609,0.03662-0.7876c0.13477-0.30273,0.40381-0.35889,0.40381-0.35889l0.04346,0.22363
      c0.07227-0.09277,0.18018-0.14648,0.29834-0.18262c0.10693-0.16357,0.21143-0.33643,0.2627-0.45605
      c0.12354-0.30078,0.12354-0.9502,0-1.24121c-0.12354-0.30273-0.19141-0.76123-0.50439-1.17529
      c-0.31299-0.42578-0.70459-0.92871-1.33105-1.23047c-0.37158-0.17725-0.99609-0.33008-1.56689-0.46143
      C325.11035,196.70313,324.23877,197.59912,323.28613,198.8042z"/>
    <path class="spa-i-0" d="M314.24316,199.59033c0.17871,0.2793,0.3584,0.55908,0.51514,0.83789h0.10059l-0.02344-0.0332l0.44775-0.69385
      l1.229,0.36084c0.02588-0.03369,0.0498-0.06592,0.08252-0.09277l-0.61719-1.33105l0.02539-0.01611
      c-0.36719-0.51172-0.6084-1.30713-0.6084-2.21094c0-0.57861,0.09717-1.11621,0.26416-1.5625
      c-0.22754-0.0083-0.42529-0.01563-0.53125-0.01563c-0.31396,0-1.25439-0.13379-1.83496-0.11182
      c-0.38086,0.02246-1.01807,0.22363-1.26563,0.2915c-0.25732,0.05518-0.69287,0.05518-1.01855,0.17822
      c-0.31201,0.12354-0.62646,0.30273-0.62646,0.30273s-0.11475,0.02881-0.27393,0.06982
      C311.40967,196.19043,312.9585,197.65137,314.24316,199.59033z"/>
    <path class="spa-i-0" d="M305.2002,200.86523c0,0,0.26953,0.05615,0.3916,0.35889c0.12402,0.29541,0.06982,0.71777,0.05957,0.78955
      c0.02441-0.00537,0.0498-0.00391,0.07568-0.00439v-0.00244l1.18652-0.97412l0.69336,0.45947v-0.00098v0.00098l0,0l-0.12354,0.73779
      l-1.0415,0.11182c0.15332,0.15088,0.37109,0.35107,0.64893,0.57129c0.5835,0.45947,1.01904,0.4248,1.01904,0.4248
      c0.38037-0.05566,0.54883-0.41406,0.54883-0.79492v-0.01953c-0.10791-0.03613-0.21338-0.14355-0.26953-0.29346
      c-0.06543-0.20117,0-0.41455,0.14697-0.47021c0.15625-0.03223,0.33594,0.10107,0.41309,0.30273
      c0.04834,0.15381,0.0249,0.3042-0.04932,0.39502l0.00586,0.00732c0.21143,0.30322,0.5708,0.50342,0.98291,0.50342
      c0.66162,0,1.1875-0.50342,1.1875-1.12939c0-0.18408-0.02344-0.34668-0.10596-0.49902
      c-0.06543-0.02539-0.12549-0.06299-0.17627-0.11035l-1.10596,0.59863l-0.5708-0.53809l0.27979-0.76025l1.28076,0.10986
      c0.01953-0.04199,0.04932-0.07617,0.07764-0.11084l-0.80908-1.07275l0.70166-0.67139
      c-1.10791-1.40674-2.11426-2.39648-2.68701-2.58838c-0.17285,0.07129-0.29785,0.12549-0.29785,0.12549
      s-1.19824,0.36914-1.76855,0.66016c-0.5708,0.30225-1.2085,0.66016-1.58789,1.67871
      c-0.3042,0.83691,0.1875,1.86572,0.37451,2.20996c0.18896,0.00732,0.36914,0.08057,0.4751,0.21826L305.2002,200.86523z"/>
    <path class="spa-i-3" d="M317.38574,194.34473c0.17676,0.46875,0.29346,1.2168,0.29346,2.06641
      c0,0.77881-0.09863,1.46875-0.25098,1.94043l0.44824,0.27051c0.36719-0.51123,0.6084-1.30713,0.6084-2.21094
      c0-0.58887-0.10156-1.13379-0.27393-1.58447c-0.11621-0.30615-0.26611-0.56494-0.43848-0.76563
      C317.66895,194.18115,317.53857,194.28027,317.38574,194.34473z"/>
    <path class="spa-i-3" d="M316.00293,198.62207l0.48535-0.29297c-0.14746-0.47168-0.24219-1.15234-0.24219-1.91797
      c0-0.84961,0.11621-1.59766,0.29297-2.06641c-0.16309-0.06787-0.30176-0.17529-0.40723-0.30762
      c-0.1875,0.20996-0.3501,0.48193-0.47314,0.81152c-0.16699,0.44629-0.26416,0.98389-0.26416,1.5625
      C315.39453,197.31494,315.63574,198.11035,316.00293,198.62207z"/>
    <path class="spa-i-3" d="M317.42822,198.35156c0.15234-0.47168,0.25098-1.16162,0.25098-1.94043
      c0-0.84961-0.1167-1.59766-0.29346-2.06641c-0.12988,0.05371-0.27246,0.08594-0.42383,0.08594
      c-0.15039,0-0.29346-0.03223-0.42285-0.08594c-0.17676,0.46875-0.29297,1.2168-0.29297,2.06641
      c0,0.76563,0.09473,1.44629,0.24219,1.91797l0.45215-0.27295L317.42822,198.35156z"/>
    <path class="spa-i-3" d="M316.61572,192.54248c-0.29004,0.0957-0.52002,0.30859-0.62646,0.57861h0.62646V192.54248z"/>
    <path class="spa-i-3" d="M317.93506,193.12109c-0.10938-0.27637-0.34717-0.4917-0.64746-0.58496v0.58496H317.93506z"/>
    <path class="spa-i-3" d="M316.53906,194.34473c0.12939,0.05371,0.27246,0.08594,0.42285,0.08594
      c0.15137,0,0.29395-0.03223,0.42383-0.08594c0.15283-0.06445,0.2832-0.16357,0.38672-0.28369
      c0.07275-0.08643,0.13037-0.18408,0.16992-0.29053h-1.96094c0.03564,0.09619,0.08594,0.18652,0.15039,0.2666
      C316.2373,194.16943,316.37598,194.27686,316.53906,194.34473z"/>
    <polygon class="spa-i-3" points="317.94238,193.77051 318.39404,193.77051 318.39404,193.12109 317.93506,193.12109 
      317.2876,193.12109 317.2876,192.53613 317.2876,191.64404 317.98047,191.64404 317.98047,191.00635 317.2876,191.00635 
      317.2876,190.36816 316.61572,190.36816 316.61572,191.00635 315.92188,191.00635 315.92188,191.64404 316.61572,191.64404 
      316.61572,192.54248 316.61572,193.12109 315.98926,193.12109 315.56299,193.12109 315.56299,193.77051 315.98145,193.77051 	"/>
    <path class="spa-i-3" d="M322.38232,203.52588c-0.13428-0.02002-0.26953-0.03857-0.40674-0.05713
      c0.13867,0.01855,0.27148,0.03906,0.40625,0.05957L322.38232,203.52588z"/>
    <path class="spa-i-3" d="M319.57373,203.23291c0.36426,0.02393,0.72363,0.05078,1.0752,0.0835
      C320.29736,203.28369,319.93799,203.25684,319.57373,203.23291z"/>
    <path class="spa-i-3" d="M318.30762,203.17139c0.39063,0.01221,0.77539,0.02979,1.15381,0.05322
      C319.08301,203.20117,318.69824,203.18359,318.30762,203.17139z"/>
    <path class="spa-i-3" d="M320.79541,203.33252c0.33447,0.03223,0.66357,0.06787,0.98389,0.1084
      C321.45898,203.40039,321.12988,203.36475,320.79541,203.33252z"/>
    <path class="spa-i-3" d="M313.40137,203.30518c0.01221-0.00098,0.02441-0.00146,0.03613-0.00293
      C313.42578,203.30371,313.41309,203.3042,313.40137,203.30518z"/>
    <path class="spa-i-3" d="M314.52393,203.22119c0.14209-0.0083,0.2876-0.01318,0.43262-0.02002
      C314.8125,203.20801,314.66553,203.21289,314.52393,203.22119z"/>
    <path class="spa-i-3" d="M316.96191,203.14746c-0.00195,0-0.00391,0-0.00537,0c0.42871,0,0.85205,0.00732,1.27002,0.01953
      C317.81055,203.15527,317.38867,203.14746,316.96191,203.14746z"/>
    <path class="spa-i-3" d="M315.71289,203.1665c0.32764-0.00928,0.66211-0.0127,0.99658-0.01465
      C316.37402,203.15381,316.04004,203.15723,315.71289,203.1665z"/>
    <path class="spa-i-3" d="M310.64795,198.78467l0.04834-0.04639l0.99609,0.35889l-0.28223,1.28564
      c0.04883,0.02197,0.09375,0.05127,0.1333,0.08594l1.12158-0.58838l0.58105,0.58154l-0.29004,0.70605l-1.27197-0.11963
      c-0.02783,0.06592-0.06641,0.12451-0.11719,0.17432l0.00098,0.00146c-0.01123,0.04395-0.01123,0.08936-0.01123,0.13379
      c0,0.66064,0.54834,1.18506,1.24268,1.18506c0.61475,0,1.15332-0.40186,1.24219-0.99463c0.00146,0,0.00195-0.00781,0.00293-0.01563
      c-0.11377-0.04639-0.2085-0.18018-0.22656-0.34326c-0.0332-0.22412,0.07764-0.41406,0.23486-0.43555
      c0.1582-0.02393,0.31348,0.14453,0.33594,0.36914c0.0249,0.16016-0.03223,0.31299-0.12939,0.38428
      c0.00146,0.00195,0.00391,0.00732,0.00488,0.00732c0.15771,0.46973,0.62793,0.81738,1.1875,0.81738
      c0.68164,0,1.24121-0.52734,1.24121-1.17627c0-0.1333-0.00635-0.24951-0.04004-0.36377
      c-0.05957-0.03809-0.10791-0.08496-0.14795-0.14111l-1.22168,0.38184l-0.42432-0.60449h-0.10059
      c-0.15674-0.27881-0.33643-0.55859-0.51514-0.83789c-1.28467-1.93896-2.8335-3.3999-4.13525-4.02686
      c-0.77295-0.37305-1.46045-0.45117-1.95264-0.15967c-0.27979,0.16895-0.41504,0.43604-0.52686,0.79492
      c0.0835-0.05078,0.1958-0.04834,0.33252-0.00244C308.53369,196.38818,309.54004,197.37793,310.64795,198.78467z"/>
    <path class="spa-i-3" d="M319.71631,199.59033c-0.17969,0.2793-0.35889,0.55908-0.51563,0.83789h-0.16943l-0.4126,0.60449
      l-1.22168-0.38184c-0.03369,0.04736-0.07324,0.08838-0.12158,0.12402c-0.0415,0.12012-0.05566,0.24121-0.05566,0.38086
      c0,0.64893,0.55957,1.17627,1.25293,1.17627c0.54883,0,1.01807-0.34766,1.18652-0.81738c0.00098,0,0.00342-0.00537,0.00488-0.00977
      c-0.0918-0.07129-0.14502-0.22314-0.12744-0.38184c0.02197-0.22461,0.17969-0.39307,0.33496-0.36914
      c0.15674,0.02148,0.26807,0.21143,0.24609,0.43555c-0.0249,0.16406-0.12402,0.29883-0.24072,0.34326
      c0.00195,0.00781,0.00439,0.01563,0.00684,0.01563c0.07715,0.59277,0.61475,0.99463,1.23096,0.99463
      c0.69336,0,1.2417-0.52441,1.2417-1.18506c0-0.04443,0-0.08984-0.01074-0.13379l0.00732-0.0083
      c-0.04834-0.04834-0.08691-0.10498-0.11328-0.16846l-1.28174,0.12061l-0.29053-0.70605l0.58057-0.58154l1.12891,0.59131
      c0.0376-0.03418,0.08105-0.06055,0.12744-0.08301l-0.28271-1.2915l0.99658-0.35889l0.06787,0.06592
      c0.95264-1.20508,1.82422-2.10107,2.4165-2.4624c0.26855-0.16504,0.48096-0.22168,0.61523-0.14307
      c-0.1123-0.35889-0.23535-0.62598-0.52588-0.79492c-0.54492-0.32617-1.3374-0.18555-2.22266,0.30615
      C322.32813,196.40088,320.9043,197.78613,319.71631,199.59033z"/>
    <path class="spa-i-3" d="M316.95117,203.7417c-3.36865,0.01025-6.40088,0.41406-8.58301,1.05078c0,0,0.22363,0.30273,0.43164,0.71094
      l0.69824,0.14014l-0.40527,0.59668c0.10645,0.29492,0.18799,0.52344,0.16992,0.52344
      c0.04541-0.01709,0.10303-0.03174,0.15088-0.04688c0.00635-0.00391,0.01074-0.00781,0.01758-0.01074
      c0.03857-0.01221,0.08691-0.02344,0.12646-0.03564c1.62598-0.51807,4.43701-0.85986,7.38281-0.86963
      c3.11035,0.01025,5.65039,0.31348,7.60938,0.91797c-0.00732,0,0.09326-0.2373,0.21484-0.49609l-0.01318,0.00293l-0.35791-0.66016
      l0.75-0.06787l0.00391,0.00928c0.24268-0.42529,0.40674-0.71045,0.4082-0.71387c-0.00342-0.00049-0.00732,0.00049-0.01074-0.00049
      C323.36328,204.15576,320.31934,203.75195,316.95117,203.7417z M312.35205,205.38623l-0.79541,0.12354l-0.77148,0.12207
      c-0.20117,0.02295-0.39063-0.10059-0.42627-0.29053c-0.02148-0.18994,0.11328-0.35889,0.30225-0.39111l0.78369-0.11279
      l0.79492-0.12256c0.20215-0.02295,0.37988,0.10107,0.41406,0.28027C312.67578,205.18555,312.54248,205.36475,312.35205,205.38623z
      M314.17529,205.39697c-0.271,0-0.49219-0.20508-0.49219-0.4585c0-0.25293,0.22119-0.4585,0.49219-0.4585
      c0.27295,0,0.49268,0.20557,0.49268,0.4585C314.66797,205.19189,314.44824,205.39697,314.17529,205.39697z M318.09131,205.14014
      h-1.10645h-1.07373c-0.20215,0-0.36914-0.15771-0.36914-0.34766s0.15576-0.3457,0.35742-0.3457h2.19189
      c0.21387,0,0.36963,0.15576,0.36963,0.3457S318.29395,205.14014,318.09131,205.14014z M319.7373,205.39697
      c-0.27148,0-0.49121-0.20508-0.49121-0.4585c0-0.25293,0.21973-0.4585,0.49121-0.4585s0.49268,0.20557,0.49268,0.4585
      C320.22998,205.19189,320.00879,205.39697,319.7373,205.39697z M323.12744,205.63184l-0.771-0.12207l-0.79443-0.12354
      c-0.20264-0.02148-0.33545-0.20068-0.30322-0.39111c0.02344-0.1792,0.21387-0.30322,0.41504-0.28027l0.79395,0.12256
      l0.78369,0.11279c0.20215,0.03223,0.32422,0.20117,0.30273,0.39111C323.52002,205.53125,323.33057,205.65479,323.12744,205.63184z"
      />
    <path class="spa-i-3" d="M309.41357,206.7168c-0.20313,0.08936-0.35254,0.2417-0.39697,0.41455
      c-0.07813,0.39258,0.35938,0.7168,1.09766,0.94043c0.59229,0.1499,1.72949,0.3374,3.07324,0.48193h7.52197
      c1.34082-0.14453,2.4751-0.33203,3.06738-0.48193c0.73877-0.22363,1.17627-0.54785,1.09717-0.94043
      c-0.04492-0.17773-0.20068-0.33545-0.41406-0.42529c-1.63379-0.52588-4.55371-0.89502-7.50879-0.89502
      c-2.87305,0-5.74854,0.35303-7.39355,0.85938c-0.03955,0.01221-0.08789,0.02344-0.12646,0.03564
      C309.42432,206.70898,309.41992,206.71289,309.41357,206.7168z M316.94043,206.62842c2.72998,0,5.20313,0.30176,7.0835,0.78271
      c-1.88037,0.48193-4.35352,0.72754-7.0835,0.72754c-2.71924,0-5.18262-0.24561-7.05176-0.72754
      C311.75781,206.93018,314.22119,206.63965,316.94043,206.62842z"/>
    <path class="spa-i-3" d="M324.86279,203.37256c-0.25684,0.15576-0.57031,0.24512-0.896,0.24512
      c-0.70313,0-1.34131-0.39063-1.58838-0.96289c-0.32373,0.34766-0.77197,0.56006-1.29785,0.56006
      c-0.5708,0-1.08496-0.25684-1.41016-0.64844c-0.31299,0.30176-0.75049,0.48145-1.2207,0.48145
      c-0.61475,0-1.17383-0.31445-1.48779-0.77295c-0.32324,0.4585-0.87158,0.77295-1.49902,0.77295
      c-0.47021,0-0.90625-0.17969-1.21973-0.48145c-0.32471,0.3916-0.83984,0.64844-1.39893,0.64844
      c-0.53809,0-0.98438-0.2124-1.30908-0.56006c-0.24805,0.57227-0.88477,0.96289-1.58887,0.96289
      c-0.3252,0-0.62793-0.08936-0.88428-0.24512c-0.1123,0.27832-0.31348,0.51855-0.59082,0.62598l0.05957,0.1543
      c1.34863-0.38525,3.00879-0.68066,4.87061-0.84766c0.01172-0.00098,0.02441-0.00146,0.03613-0.00293
      c0.35596-0.03125,0.7168-0.05957,1.08643-0.08105c0.1416-0.0083,0.28857-0.01318,0.43262-0.02002
      c0.25098-0.0127,0.50049-0.02637,0.75635-0.03467c0.32715-0.00928,0.66113-0.0127,0.99658-0.01465
      c0.08105,0,0.15967-0.00439,0.2417-0.00439c0.00195,0,0.00391,0,0.00537,0s0.00342,0,0.00537,0
      c0.42676,0,0.84863,0.00781,1.26465,0.01953c0.02734,0.00049,0.05371,0.00244,0.08105,0.00439
      c0.39063,0.01221,0.77539,0.02979,1.15381,0.05322c0.03809,0.00244,0.07422,0.00635,0.1123,0.0083
      c0.36426,0.02393,0.72363,0.05078,1.0752,0.0835c0.04932,0.00488,0.09668,0.01025,0.14648,0.01611
      c0.33447,0.03223,0.66357,0.06787,0.98389,0.1084c0.06738,0.00879,0.12988,0.01855,0.19629,0.02783
      c0.13721,0.01855,0.27246,0.03711,0.40674,0.05713c0.15332,0.02295,0.30713,0.04443,0.45703,0.06836
      c0.1001,0.0166,0.19385,0.03516,0.29346,0.05225c0.23242,0.04102,0.46436,0.08252,0.68652,0.12646
      c0.14551,0.02979,0.28223,0.06201,0.42285,0.09326c0.16016,0.03516,0.32178,0.07031,0.47656,0.10889
      c0.19385,0.04736,0.37744,0.09814,0.56006,0.14941c0.06592,0.01758,0.13623,0.03418,0.19971,0.05273l0.05566-0.14941
      C325.21875,203.94336,324.98584,203.68164,324.86279,203.37256z"/>
    <path class="spa-i-3" d="M327.49268,202.71143c-0.08398-0.08398-0.08154-0.21777-0.02246-0.34717l-0.01514-0.00146
      c-0.14941,0.14893-0.35645,0.33984-0.62207,0.55029c-0.59277,0.45947-1.01807,0.4248-1.01807,0.4248
      c-0.39209-0.05566-0.5498-0.41406-0.5498-0.79492v-0.01953c-0.04443,0.01611-0.09033,0.02246-0.13281,0.00928
      c-0.04688-0.01318-0.08154-0.04395-0.11182-0.08252l-0.01221,0.01465c-0.21191,0.30322-0.5708,0.50342-0.98438,0.50342
      c-0.66064,0-1.1875-0.50342-1.1875-1.12939c0-0.18359,0.03418-0.3457,0.10693-0.49707
      c-0.06152,0.02344-0.12646,0.03809-0.19531,0.03809c-0.15576,0-0.29443-0.06396-0.39502-0.16406l-0.00732,0.0083
      c0.01074,0.04395,0.01074,0.08936,0.01074,0.13379c0,0.66064-0.54834,1.18506-1.2417,1.18506
      c-0.61621,0-1.15381-0.40186-1.23096-0.99463c-0.00244,0-0.00488-0.00781-0.00684-0.01563
      c-0.03467,0.01318-0.06982,0.02002-0.10596,0.01563c-0.03955-0.00342-0.07568-0.01855-0.10693-0.04346
      c-0.00146,0.00439-0.00391,0.00977-0.00488,0.00977c-0.16846,0.46973-0.6377,0.81738-1.18652,0.81738
      c-0.69336,0-1.25293-0.52734-1.25293-1.17627c0-0.13965,0.01416-0.26074,0.05566-0.38086
      c-0.0918,0.06641-0.20068,0.11279-0.32422,0.11279c-0.1123,0-0.21143-0.04004-0.29834-0.0957
      c0.03369,0.11426,0.04004,0.23047,0.04004,0.36377c0,0.64893-0.55957,1.17627-1.24121,1.17627
      c-0.55957,0-1.02979-0.34766-1.1875-0.81738c-0.00098,0-0.00342-0.00537-0.00488-0.00732
      c-0.03125,0.02344-0.06738,0.0376-0.10596,0.04102c-0.03662,0.00488-0.07324-0.00146-0.10889-0.01563
      c-0.00098,0.00781-0.00146,0.01563-0.00293,0.01563c-0.08887,0.59277-0.62744,0.99463-1.24219,0.99463
      c-0.69434,0-1.24268-0.52441-1.24268-1.18506c0-0.04443,0-0.08984,0.01123-0.13379l-0.00098-0.00146
      c-0.09961,0.09717-0.23682,0.15723-0.39014,0.15723c-0.07324,0-0.14209-0.01465-0.20654-0.04004
      c0.08252,0.15234,0.10596,0.31494,0.10596,0.49902c0,0.62598-0.52588,1.12939-1.1875,1.12939
      c-0.41211,0-0.77148-0.2002-0.98291-0.50342l-0.00586-0.00732c-0.02832,0.03516-0.06396,0.06299-0.10742,0.0752
      c-0.04199,0.01318-0.08838,0.00684-0.13379-0.00928v0.01953c0,0.38086-0.16846,0.73926-0.54883,0.79492
      c0,0-0.43555,0.03467-1.01904-0.4248c-0.27783-0.22021-0.49561-0.42041-0.64893-0.57129l-0.17822,0.01953
      c0.06006,0.13037,0.06396,0.26563-0.02344,0.3501c-0.00293,0.00342-0.00732,0.00391-0.01074,0.00732
      c0.20508,0.28516,0.63428,0.84326,1.01904,1.05566c0.28027,0.16797,0.71777,0.34717,1.05176,0.27979
      c0.06152-0.00977,0.11621-0.03418,0.1709-0.05566c0.27734-0.10742,0.47852-0.34766,0.59082-0.62598
      c0.25635,0.15576,0.55908,0.24512,0.88428,0.24512c0.7041,0,1.34082-0.39063,1.58887-0.96289
      c0.32471,0.34766,0.771,0.56006,1.30908,0.56006c0.55908,0,1.07422-0.25684,1.39893-0.64844
      c0.31348,0.30176,0.74951,0.48145,1.21973,0.48145c0.62744,0,1.17578-0.31445,1.49902-0.77295
      c0.31396,0.4585,0.87305,0.77295,1.48779,0.77295c0.47021,0,0.90771-0.17969,1.2207-0.48145
      c0.3252,0.3916,0.83936,0.64844,1.41016,0.64844c0.52588,0,0.97412-0.2124,1.29785-0.56006
      c0.24707,0.57227,0.88525,0.96289,1.58838,0.96289c0.32568,0,0.63916-0.08936,0.896-0.24512
      c0.12305,0.30908,0.35596,0.5708,0.67139,0.65527c0.02734,0.00732,0.05029,0.02197,0.07861,0.02637
      c0.34814,0.06738,0.78271-0.11182,1.0625-0.27979c0.35205-0.19922,0.72559-0.69482,0.92969-0.99268
      C327.56299,202.7666,327.52344,202.74561,327.49268,202.71143z"/>
    <path class="spa-i-3" d="M324.24219,203.86621c-0.14063-0.03125-0.27734-0.06348-0.42285-0.09326
      c-0.22217-0.04395-0.4541-0.08545-0.68652-0.12646c-0.09961-0.01709-0.19336-0.03564-0.29346-0.05225
      c-0.14893-0.02393-0.30518-0.04346-0.45752-0.06592c-0.13477-0.02051-0.26758-0.04102-0.40625-0.05957
      c-0.06641-0.00928-0.12891-0.01904-0.19629-0.02783c-0.32031-0.04053-0.64941-0.07617-0.98389-0.1084
      c-0.0498-0.00586-0.09717-0.01123-0.14648-0.01611c-0.35156-0.03271-0.71094-0.05957-1.0752-0.0835
      c-0.03809-0.00195-0.07422-0.00586-0.1123-0.0083c-0.37842-0.02344-0.76318-0.04102-1.15381-0.05322
      c-0.02734-0.00195-0.05371-0.00391-0.08105-0.00439c-0.41797-0.01221-0.84131-0.01953-1.27002-0.01953
      c-0.00146,0-0.00342,0-0.00537,0c-0.08203,0-0.16064,0.00439-0.2417,0.00439c-0.33447,0.00195-0.66895,0.00537-0.99658,0.01465
      c-0.25586,0.0083-0.50537,0.02197-0.75635,0.03467c-0.14502,0.00684-0.29053,0.01172-0.43262,0.02002
      c-0.36963,0.02148-0.73047,0.0498-1.08643,0.08105c-0.01172,0.00146-0.02393,0.00195-0.03613,0.00293
      c-1.86182,0.16699-3.52197,0.4624-4.87061,0.84766c-0.10889,0.03125-0.22607,0.06006-0.33057,0.0918
      c-0.14551,0.04492-0.24609,0.2124-0.20215,0.35791c0.04492,0.12305,0.22412,0.23486,0.37012,0.18994
      c2.18213-0.63672,5.21436-1.04053,8.58301-1.05078c3.36816,0.01025,6.41211,0.41406,8.59375,1.05078
      c0.00342,0.00098,0.00732,0,0.01074,0.00049c0.14453,0.03662,0.31592-0.06934,0.35889-0.19043
      c0.04639-0.14551-0.05566-0.31299-0.20068-0.35791c-0.13818-0.04199-0.28955-0.08008-0.43506-0.12012
      c-0.18262-0.05127-0.36621-0.10205-0.56006-0.14941C324.56396,203.93701,324.40234,203.90137,324.24219,203.86621z"/>
    <path class="spa-i-3" d="M305.45459,202.32617c-0.0166-0.0918-0.00195-0.18018,0.06006-0.24121
      c0.03613-0.03662,0.08398-0.05908,0.13672-0.07129c0.01025-0.07178,0.06445-0.49414-0.05957-0.78955
      c-0.12207-0.30273-0.3916-0.35889-0.3916-0.35889l-0.04395,0.22363c-0.10596-0.1377-0.28613-0.21094-0.4751-0.21826
      c-0.01025-0.00049-0.01855-0.00537-0.02881-0.00537c-0.33594,0-0.64893,0.22363-0.64893,0.31348
      c0,0,0.53662,0.16699,0.76025,0.36963C305.01807,201.7832,305.34863,202.20361,305.45459,202.32617z"/>
    <path class="spa-i-3" d="M306.1084,202.15234c0.07275,0.06104,0.12061,0.13379,0.15527,0.20898l0.17822-0.01953l1.0415-0.11182
      l0.12354-0.73779l-0.69336-0.45947l-1.18652,0.97412v0.00244C305.85254,202.00537,305.99316,202.05078,306.1084,202.15234z"/>
    <polygon class="spa-i-3" points="307.60693,201.49121 307.60693,201.49219 307.60693,201.49219 	"/>
    <path class="spa-i-3" d="M306.26367,202.36133c-0.03467-0.0752-0.08252-0.14795-0.15527-0.20898
      c-0.11523-0.10156-0.25586-0.14697-0.38135-0.14307c-0.02588,0.00049-0.05127-0.00098-0.07568,0.00439
      c-0.05273,0.01221-0.10059,0.03467-0.13672,0.07129c-0.06201,0.06104-0.07666,0.14941-0.06006,0.24121
      c0.01953,0.11035,0.08496,0.22705,0.19482,0.31836c0.18555,0.1748,0.44434,0.20801,0.58008,0.07422
      c0.00342-0.00342,0.00781-0.00391,0.01074-0.00732C306.32764,202.62695,306.32373,202.4917,306.26367,202.36133z"/>
    <path class="spa-i-3" d="M308.79248,202.53271c0.04346-0.01221,0.0791-0.04004,0.10742-0.0752
      c0.07422-0.09082,0.09766-0.24121,0.04932-0.39502c-0.07715-0.20166-0.25684-0.33496-0.41309-0.30273
      c-0.14697,0.05566-0.2124,0.26904-0.14697,0.47021c0.05615,0.1499,0.16162,0.25732,0.26953,0.29346
      C308.7041,202.53955,308.75049,202.5459,308.79248,202.53271z"/>
    <path class="spa-i-3" d="M310.62842,200.85352c0-0.07617,0.01904-0.14795,0.04932-0.21387l-1.28076-0.10986l-0.27979,0.76025
      l0.5708,0.53809l1.10596-0.59863C310.69238,201.13379,310.62842,201.00195,310.62842,200.85352z"/>
    <path class="spa-i-3" d="M311.17676,200.32813c0.08447,0,0.16211,0.02148,0.2334,0.05469l0.28223-1.28564l-0.99609-0.35889
      l-0.04834,0.04639l-0.70166,0.67139l0.80908,1.07275C310.85645,200.40869,311.00391,200.32813,311.17676,200.32813z"/>
    <path class="spa-i-3" d="M311.7251,200.85352c0,0.07031-0.01563,0.13525-0.04102,0.19482l1.27197,0.11963l0.29004-0.70605
      l-0.58105-0.58154l-1.12158,0.58838C311.65332,200.56445,311.7251,200.69971,311.7251,200.85352z"/>
    <path class="spa-i-3" d="M311.56689,201.22266c0.05078-0.0498,0.08936-0.1084,0.11719-0.17432
      c0.02539-0.05957,0.04102-0.12451,0.04102-0.19482c0-0.15381-0.07178-0.28906-0.18164-0.38477
      c-0.03955-0.03467-0.08447-0.06396-0.1333-0.08594c-0.07129-0.0332-0.14893-0.05469-0.2334-0.05469
      c-0.17285,0-0.32031,0.08057-0.42139,0.20068c-0.02832,0.03467-0.05811,0.06885-0.07764,0.11084
      c-0.03027,0.06592-0.04932,0.1377-0.04932,0.21387c0,0.14844,0.06396,0.28027,0.16553,0.37598
      c0.05078,0.04736,0.11084,0.08496,0.17627,0.11035c0.06445,0.02539,0.1333,0.04004,0.20654,0.04004
      C311.33008,201.37988,311.46729,201.31982,311.56689,201.22266z"/>
    <path class="spa-i-3" d="M314.25928,201.50732c0.09717-0.07129,0.1543-0.22412,0.12939-0.38428
      c-0.02246-0.22461-0.17773-0.39307-0.33594-0.36914c-0.15723,0.02148-0.26807,0.21143-0.23486,0.43555
      c0.01807,0.16309,0.11279,0.29688,0.22656,0.34326c0.03564,0.01416,0.07227,0.02051,0.10889,0.01563
      C314.19189,201.54492,314.22803,201.53076,314.25928,201.50732z"/>
    <path class="spa-i-3" d="M316.40283,200.36133c0-0.11426,0.04492-0.21387,0.10938-0.29932l-1.229-0.36084l-0.44775,0.69385
      l0.02344,0.0332l0.42432,0.60449l1.22168-0.38184C316.44482,200.56592,316.40283,200.47119,316.40283,200.36133z"/>
    <path class="spa-i-3" d="M317.28955,199.95752l0.6123-1.31934l-0.02539-0.01611l-0.44824-0.27051l-0.48779-0.29541l-0.45215,0.27295
      l-0.48535,0.29297l-0.02539,0.01611l0.61719,1.33105c0.09619-0.08057,0.21875-0.1333,0.35645-0.1333
      C317.08057,199.83594,317.1958,199.88525,317.28955,199.95752z"/>
    <path class="spa-i-3" d="M317.39697,200.65088l1.22168,0.38184l0.4126-0.60449l0.02295-0.0332l-0.43555-0.69385l-1.23096,0.3584
      c0.06543,0.08594,0.1123,0.18652,0.1123,0.30176C317.5,200.47119,317.45703,200.56592,317.39697,200.65088z"/>
    <path class="spa-i-3" d="M317.27539,200.7749c0.04834-0.03564,0.08789-0.07666,0.12158-0.12402
      c0.06006-0.08496,0.10303-0.17969,0.10303-0.28955c0-0.11523-0.04688-0.21582-0.1123-0.30176
      c-0.02832-0.03857-0.05957-0.07275-0.09814-0.10205c-0.09375-0.07227-0.20898-0.12158-0.33838-0.12158
      c-0.1377,0-0.26025,0.05273-0.35645,0.1333c-0.03271,0.02686-0.05664,0.05908-0.08252,0.09277
      c-0.06445,0.08545-0.10938,0.18506-0.10938,0.29932c0,0.10986,0.04199,0.20459,0.10205,0.28955
      c0.04004,0.05615,0.08838,0.10303,0.14795,0.14111c0.08691,0.05566,0.18604,0.0957,0.29834,0.0957
      C317.07471,200.8877,317.18359,200.84131,317.27539,200.7749z"/>
    <path class="spa-i-3" d="M319.771,201.54834c0.03613,0.00439,0.07129-0.00244,0.10596-0.01563
      c0.1167-0.04443,0.21582-0.1792,0.24072-0.34326c0.02197-0.22412-0.08936-0.41406-0.24609-0.43555
      c-0.15527-0.02393-0.31299,0.14453-0.33496,0.36914c-0.01758,0.15869,0.03564,0.31055,0.12744,0.38184
      C319.69531,201.52979,319.73145,201.54492,319.771,201.54834z"/>
    <path class="spa-i-3" d="M322.37695,200.47168l-1.12891-0.59131l-0.58057,0.58154l0.29053,0.70605l1.28174-0.12061
      c-0.02441-0.05908-0.04053-0.12402-0.04053-0.19385C322.19922,200.70166,322.26855,200.56787,322.37695,200.47168z"/>
    <path class="spa-i-3" d="M323.16309,200.521l0.80371-1.06494l-0.68066-0.65186l-0.06787-0.06592l-0.99658,0.35889l0.28271,1.2915
      c0.07373-0.03613,0.15479-0.06055,0.24365-0.06055C322.91699,200.32813,323.0625,200.40527,323.16309,200.521z"/>
    <path class="spa-i-3" d="M323.125,201.23242l1.09961,0.5957l0.57178-0.53809l-0.26953-0.76025l-1.28076,0.10986
      c0.03076,0.06592,0.0498,0.1377,0.0498,0.21387C323.2959,201.00391,323.22949,201.13672,323.125,201.23242z"/>
    <path class="spa-i-3" d="M322.74805,201.37988c0.06885,0,0.13379-0.01465,0.19531-0.03809
      c0.06787-0.0249,0.12939-0.06104,0.18164-0.10938c0.10449-0.0957,0.1709-0.22852,0.1709-0.37891
      c0-0.07617-0.01904-0.14795-0.0498-0.21387c-0.02002-0.04443-0.05127-0.08203-0.08301-0.11865
      c-0.10059-0.11572-0.24609-0.19287-0.41504-0.19287c-0.08887,0-0.16992,0.02441-0.24365,0.06055
      c-0.04639,0.02246-0.08984,0.04883-0.12744,0.08301c-0.1084,0.09619-0.17773,0.22998-0.17773,0.38184
      c0,0.06982,0.01611,0.13477,0.04053,0.19385c0.02637,0.06348,0.06494,0.12012,0.11328,0.16846
      C322.45361,201.31592,322.59229,201.37988,322.74805,201.37988z"/>
    <path class="spa-i-3" d="M325.13232,202.53271c0.04248,0.01318,0.08838,0.00684,0.13281-0.00928
      c0.1084-0.03613,0.21338-0.14355,0.26953-0.29346c0.06738-0.20117,0-0.41455-0.146-0.47021
      c-0.15576-0.03223-0.33496,0.10107-0.41406,0.30273c-0.04736,0.14941-0.02539,0.29688,0.0459,0.3877
      C325.05078,202.48877,325.08545,202.51953,325.13232,202.53271z"/>
    <path class="spa-i-3" d="M328.50049,200.86523c0,0-0.26904,0.05615-0.40381,0.35889c-0.11084,0.2915-0.04932,0.70752-0.03662,0.7876
      c0.06299,0.00977,0.12061,0.02979,0.16064,0.07324c0.05078,0.05078,0.06592,0.12158,0.06494,0.19531
      c0.13574-0.16211,0.42725-0.52246,0.6626-0.73193c0.21289-0.20264,0.75-0.36963,0.75-0.36963
      c0-0.08984-0.31445-0.31348-0.64893-0.31348c-0.07373,0-0.13965,0.02148-0.20703,0.04102
      c-0.11816,0.03613-0.22607,0.08984-0.29834,0.18262L328.50049,200.86523z"/>
    <polygon class="spa-i-3" points="326.09375,201.49219 326.09375,201.49219 326.09375,201.49121 	"/>
    <path class="spa-i-3" d="M327.62695,202.15234c0.104-0.08594,0.22461-0.13623,0.33447-0.14697l-1.17432-0.97266l-0.69336,0.45947
      l0.12305,0.73779l1.23828,0.13281l0.01514,0.00146C327.50439,202.28857,327.55273,202.21436,327.62695,202.15234z"/>
    <path class="spa-i-3" d="M328.2207,202.08496c-0.04004-0.04346-0.09766-0.06348-0.16064-0.07324
      c-0.03125-0.00488-0.06396-0.00977-0.09863-0.00635c-0.10986,0.01074-0.23047,0.06104-0.33447,0.14697
      c-0.07422,0.06201-0.12256,0.13623-0.15674,0.21191c-0.05908,0.12939-0.06152,0.26318,0.02246,0.34717
      c0.03076,0.03418,0.07031,0.05518,0.1123,0.07031c0.14258,0.0498,0.3374,0.00049,0.4917-0.13721
      c0.12598-0.10449,0.18701-0.2417,0.18896-0.36426C328.28662,202.20654,328.27148,202.13574,328.2207,202.08496z"/>
    <polygon class="spa-i-0" points="308.7998,205.50342 308.77051,205.49756 308.42383,206.05713 309.07275,206.27051 
      309.09277,206.24023 309.49805,205.64355 	"/>
    <path class="spa-i-10" d="M312.23975,204.71484l-0.79492,0.12256l-0.78369,0.11279c-0.18896,0.03223-0.32373,0.20117-0.30225,0.39111
      c0.03564,0.18994,0.2251,0.31348,0.42627,0.29053l0.77148-0.12207l0.79541-0.12354
      c0.19043-0.02148,0.32373-0.20068,0.30176-0.39111C312.61963,204.81592,312.44189,204.69189,312.23975,204.71484z"/>
    <ellipse class="spa-i-6" cx="314.17529" cy="204.93848" rx="0.49219" ry="0.4585"/>
    <path class="spa-i-0" d="M318.09131,204.44678h-2.19189c-0.20166,0-0.35742,0.15576-0.35742,0.3457s0.16699,0.34766,0.36914,0.34766
      h1.07373h1.10645c0.20264,0,0.36963-0.15771,0.36963-0.34766S318.30518,204.44678,318.09131,204.44678z"/>
    <ellipse class="spa-i-6" cx="319.7373" cy="204.93848" rx="0.49121" ry="0.4585"/>
    <path class="spa-i-10" d="M323.25146,204.9502l-0.78369-0.11279l-0.79395-0.12256c-0.20117-0.02295-0.3916,0.10107-0.41504,0.28027
      c-0.03223,0.19043,0.10059,0.36963,0.30322,0.39111l0.79443,0.12354l0.771,0.12207
      c0.20313,0.02295,0.39258-0.10059,0.42676-0.29053C323.57568,205.15137,323.45361,204.98242,323.25146,204.9502z"/>
    <polygon class="spa-i-0" points="324.39355,205.56543 324.75146,206.22559 324.76465,206.22266 325.42285,206.09131 
      325.14746,205.50684 325.14355,205.49756 	"/>
    <path class="spa-i-0" d="M324.02393,207.41113c-1.88037-0.48096-4.35352-0.78271-7.0835-0.78271
      c-2.71924,0.01123-5.18262,0.30176-7.05176,0.78271c1.86914,0.48193,4.33252,0.72754,7.05176,0.72754
      C319.67041,208.13867,322.14355,207.89307,324.02393,207.41113z"/>
    <path class="spa-i-9" d="M334.41895,325.10449c-1.72168,0-3.25488-0.36914-4.39697-0.97266
      c-0.07471-0.03809-0.15576-0.07227-0.23389-0.1084v0.16406h-8.55225c-1.12256,0.56543-2.63867,0.91699-4.29541,0.91699
      c-1.63379,0-3.10303-0.36328-4.21387-0.91699h-8.5332v-0.16797c-0.07129,0.03418-0.14502,0.06445-0.2124,0.09961
      c-1.14063,0.60449-2.70752,0.98535-4.41992,0.98535v2.69727c1.7124,0,3.2793-0.39063,4.41992-0.98438
      c1.11914-0.58203,2.61865-0.91797,4.31934-0.91797c1.63477,0,3.12256,0.34668,4.24219,0.89453
      c1.13037,0.59375,2.67383,1.00781,4.39795,1.00781c1.7002,0,3.25684-0.36914,4.38623-0.96191
      c1.13037-0.58301,2.68701-0.94043,4.37646-0.94043c0.41943,0,0.82861,0.02246,1.22559,0.06543
      c1.18799,0.12793,2.25391,0.43555,3.09326,0.86328c0.2793,0.14844,0.58203,0.28027,0.90332,0.39941
      c0.0127,0.00391,0.0249,0.00781,0.03711,0.0127c0.30029,0.1084,0.62012,0.2002,0.95313,0.27832
      c0.04883,0.01172,0.09619,0.02441,0.14502,0.03516c0.32178,0.07031,0.65723,0.12695,1.00391,0.16797
      c0.04492,0.00391,0.09033,0.01074,0.13623,0.0166c0.37842,0.03906,0.771,0.06152,1.17627,0.0625
      c0.00635,0,0.01367,0.00098,0.021,0.00098l0.02197-2.69727H334.41895z"/>
    <path class="spa-i-7" d="M333.20068,327.73828c-0.0459-0.00586-0.09131-0.0127-0.13623-0.0166
      c-0.34619-0.04102-0.68115-0.09766-1.00391-0.16797c-0.04883-0.01074-0.09619-0.02344-0.14502-0.03516
      c-0.33301-0.07813-0.65234-0.1709-0.95313-0.27832c-0.01221-0.00488-0.02441-0.00879-0.03711-0.0127
      c-0.32227-0.11816-0.625-0.25098-0.90332-0.39746c-0.83936-0.42871-1.90527-0.7373-3.09326-0.86523
      c-0.39697-0.04297-0.80615-0.06543-1.22559-0.06543c-1.68945,0-3.24609,0.35742-4.37646,0.94043
      c-1.12939,0.59277-2.68604,0.96191-4.38623,0.96191c-1.72412,0-3.26758-0.41406-4.39795-1.00781
      c-1.11963-0.54785-2.60742-0.89453-4.24219-0.89453c-1.70068,0-3.2002,0.33594-4.31934,0.91797
      c-1.14063,0.59375-2.70752,0.98438-4.41992,0.98438V330.5c1.7124,0,3.2793-0.3916,4.41992-0.98535
      c1.11914-0.58203,2.61865-0.91895,4.31934-0.91895c1.63477,0,3.12256,0.34668,4.24219,0.89551
      c1.13037,0.60449,2.67383,1.00879,4.39795,1.00879c1.7002,0,3.25684-0.38086,4.38623-0.96289
      c1.13037-0.58301,2.68701-0.94141,4.37646-0.94141c1.67822,0,3.19922,0.3584,4.31885,0.94141
      c0.27539,0.14355,0.57373,0.27051,0.89014,0.38477c0.02197,0.00879,0.04443,0.0166,0.06689,0.02637
      c0.29443,0.10352,0.60547,0.19238,0.93115,0.26855c0.05176,0.01172,0.10303,0.02344,0.15625,0.03613
      c0.31934,0.06836,0.65186,0.12402,0.99609,0.16504c0.0459,0.00586,0.09277,0.01172,0.13916,0.0166
      c0.37842,0.03906,0.77051,0.06348,1.17432,0.06348c0.00732,0,0.01465,0.00098,0.02197,0.00098l0.02197-2.69727h-0.00098
      c-0.00732,0-0.01416,0-0.021,0c-0.00732,0-0.01465-0.00098-0.021-0.00098
      C333.97314,327.7998,333.58008,327.77832,333.20068,327.73828z"/>
    <path class="spa-i-9" d="M333.20166,330.43457c-0.04639-0.00488-0.09326-0.01074-0.13916-0.0166
      c-0.34375-0.04199-0.67676-0.09668-0.99609-0.16504c-0.05322-0.0127-0.10449-0.02441-0.15625-0.03613
      c-0.32568-0.07617-0.63672-0.16602-0.93115-0.26855c-0.02246-0.00977-0.04492-0.01758-0.06689-0.02637
      c-0.31738-0.11426-0.61523-0.24121-0.89014-0.38477c-1.11963-0.58301-2.64063-0.94141-4.31885-0.94141
      c-1.68945,0-3.24609,0.3584-4.37646,0.94141c-1.12939,0.58203-2.68604,0.96289-4.38623,0.96289
      c-1.72412,0-3.26758-0.4043-4.39795-1.00879c-1.11963-0.54883-2.60742-0.89551-4.24219-0.89551
      c-1.70068,0-3.2002,0.33691-4.31934,0.91895c-1.14063,0.59375-2.70752,0.98535-4.41992,0.98535v2.68555
      c1.7124,0,3.2793-0.38086,4.41992-0.98535c1.11914-0.57129,2.61865-0.89551,4.31934-0.89551
      c1.62354,0,3.12256,0.33496,4.24219,0.88477c1.13037,0.60254,2.67383,0.99609,4.39795,0.99609
      c1.7002,0,3.25684-0.3584,4.38623-0.95117c1.13037-0.58203,2.68701-0.92969,4.37646-0.92969
      c1.67822,0,3.19922,0.34766,4.31885,0.91797c0.27588,0.14746,0.57568,0.28027,0.89209,0.39648
      c0.021,0.00684,0.04248,0.01465,0.06348,0.02148c0.2959,0.10742,0.60938,0.19531,0.93652,0.27148
      c0.05029,0.01172,0.1001,0.02441,0.15039,0.03418c0.3208,0.06836,0.65527,0.12402,1.00098,0.16211
      c0.04541,0.00488,0.09082,0.00977,0.13574,0.0166c0.37891,0.03809,0.771,0.05859,1.17578,0.05957c0.00635,0,0.01367,0,0.021,0
      l0.02197-2.68457h-0.00098c-0.00732,0-0.01416-0.00098-0.021-0.00098c-0.00732,0-0.01465-0.00098-0.02197-0.00098
      C333.97314,330.49707,333.58057,330.47461,333.20166,330.43457z"/>
    <path class="spa-i-7" d="M333.20117,333.125c-0.04492-0.00684-0.09033-0.01172-0.13574-0.0166
      c-0.3457-0.03809-0.67969-0.09375-1.00098-0.16211c-0.05029-0.00977-0.1001-0.02246-0.15039-0.03418
      c-0.32715-0.07617-0.64063-0.16406-0.93652-0.27148c-0.021-0.00684-0.04248-0.01465-0.06348-0.02148
      c-0.31738-0.11621-0.6167-0.24902-0.89209-0.39648c-1.11963-0.57031-2.64063-0.91797-4.31885-0.91797
      c-1.68945,0-3.24609,0.34766-4.37646,0.92969c-1.12939,0.59277-2.68604,0.95117-4.38623,0.95117
      c-1.72412,0-3.26758-0.39355-4.39795-0.99609c-1.11963-0.5498-2.61865-0.88477-4.24219-0.88477
      c-1.70068,0-3.2002,0.32422-4.31934,0.89551c-1.14063,0.60449-2.70752,1.00684-4.41992,1.00684v2.68555
      c1.7124,0,3.2793-0.39258,4.41992-0.98438c1.11914-0.58203,2.61865-0.90625,4.31934-0.90625
      c1.63477,0,3.12256,0.32422,4.24219,0.88477c1.13037,0.59277,2.67383,1.00586,4.39795,1.00586
      c1.7002,0,3.25684-0.37891,4.38623-0.96289c1.13037-0.58203,2.68701-0.92773,4.37646-0.92773
      c1.67822,0,3.19922,0.3457,4.31885,0.91895c0.2793,0.14551,0.58252,0.28125,0.9043,0.39844
      c0.01221,0.00391,0.02441,0.00879,0.03662,0.0127c0.30811,0.11035,0.63574,0.20605,0.97803,0.28613
      c0.03174,0.00781,0.06445,0.01563,0.09717,0.02246c0.33398,0.0752,0.68359,0.13379,1.04639,0.17578
      c0.03516,0.00488,0.06836,0.00977,0.104,0.01367c0.38232,0.04004,0.77881,0.06152,1.18848,0.0625c0.00635,0,0.01367,0,0.021,0
      l0.021-2.70703c-0.00732,0-0.01416-0.00098-0.021-0.00098c-0.00732,0-0.01465,0-0.021,0
      C333.97363,333.18262,333.58057,333.16406,333.20117,333.125z"/>
    <path class="spa-i-9" d="M333.18848,335.83008c-0.03564-0.00391-0.06885-0.00879-0.104-0.01367
      c-0.36279-0.04199-0.7124-0.10059-1.04639-0.17578c-0.03271-0.00684-0.06543-0.01465-0.09717-0.02246
      c-0.34277-0.08008-0.66992-0.17578-0.97803-0.28613c-0.01221-0.00391-0.02441-0.00879-0.03662-0.0127
      c-0.32275-0.11719-0.62549-0.25293-0.9043-0.39844c-1.11963-0.57324-2.64063-0.91895-4.31885-0.91895
      c-1.68945,0-3.24609,0.3457-4.37646,0.92773c-1.12939,0.58398-2.68604,0.96289-4.38623,0.96289
      c-1.72412,0-3.26758-0.41309-4.39795-1.00586c-1.11963-0.54883-2.61865-0.88477-4.24219-0.88477
      c-1.70068,0-3.2002,0.32422-4.31934,0.90625c-1.14063,0.59375-2.70752,1.00781-4.41992,1.00781v2.6748
      c1.7124,0,3.2793-0.39355,4.41992-0.98438c1.11914-0.58203,2.61865-0.91797,4.31934-0.91797
      c1.63477,0,3.12256,0.33594,4.24219,0.89453c1.13037,0.59277,2.67383,1.00781,4.39795,1.00781
      c1.7002,0,3.25684-0.38086,4.38623-0.96289c1.13037-0.58203,2.68701-0.93945,4.37646-0.93945
      c1.67822,0,3.19922,0.35742,4.31885,0.93945c1.14209,0.59375,2.65283,0.96289,4.37598,0.96289l0.021-2.69824
      c-0.00732,0-0.01416,0-0.021,0c-0.00732,0-0.01465,0-0.021,0C333.96777,335.89063,333.5708,335.87012,333.18848,335.83008z"/>
    <path class="spa-i-3" d="M329.83203,317.66309c-1.73535,0-3.14502-1.31055-3.14502-2.94141c0-0.23535,0.04492-0.45996,0.1001-0.69727
      c-0.14453,0.05664-0.32373,0.06934-0.49219,0.06934h-18.67676c-0.146,0-0.32471-0.02246-0.45996-0.06934
      c0.05811,0.2373,0.14648,0.44922,0.14648,0.69727c0,1.63086-1.42236,2.94141-3.14453,2.94141h0.0332h25.59473H329.83203z"/>
    <path class="spa-i-3" d="M327.43652,313.20996c0-0.48145-0.50293-0.87305-1.1416-0.87305h-18.67676
      c-0.62744,0-1.1416,0.3916-1.1416,0.87305s0.51416,0.88379,1.1416,0.88379h18.67676
      C326.93359,314.09375,327.43652,313.69141,327.43652,313.20996z"/>
    <polygon class="spa-i-3" points="312.72656,324.1875 321.23584,324.1875 329.78809,324.1875 329.78809,324.02344 329.78809,317.66309 
      304.19336,317.66309 304.19336,324.01953 304.19336,324.1875 	"/>
    <path class="spa-i-7" d="M307.86377,255.63281v2.32715v19.05566c0.01904-0.00586,0.03711-0.01367,0.05566-0.02051
      c3.17822-1.12988,5.79639-2.31543,9.05371-3.74805c3.42627-1.5166,6.53027-3.1123,9.07617-4.61816V268.625v-16.24609
      c-2.64355,0.0293-5.56299,0.2832-8.59424,0.78125C313.90869,253.75488,310.64014,254.61719,307.86377,255.63281z"/>
    <path class="spa-i-7" d="M307.87549,286.61914c-0.00342,0.00195-0.00684,0.00391-0.01172,0.00391v24.65039h18.18555v-32.67188
      c-1.93408,1.14063-4.12744,2.3125-6.49072,3.44043C316.71631,283.4082,310.70703,285.62402,307.87549,286.61914z"/>
    <path class="spa-i-7" d="M326.04932,242.00195v-19.84033h-18.18555v22.40674c2.31787-0.68359,4.85303-1.26953,7.53076-1.7168
      C319.17236,242.2168,322.84912,241.97168,326.04932,242.00195z"/>
    <polygon class="spa-i-3" points="304.54004,208.55371 304.54004,215.08838 307.61816,215.08838 326.30615,215.08838 
      329.48535,215.08838 329.48535,208.55371 320.70947,208.55371 313.1875,208.55371 	"/>
    <path class="spa-i-3" d="M307.09082,216.51025c0.03271,0.01123,0.06885,0.01416,0.10303,0.02197h-0.00049L307.09082,216.51025z"/>
    <path class="spa-i-3" d="M307.61816,216.57715c-0.15186,0-0.29297-0.01367-0.42432-0.04492
      c0.66992,0.20166,1.05225,0.79492,1.05225,1.44336c0,0.61523-0.41406,1.24023-1.05518,1.45313
      c0.01953-0.00635,0.04102-0.00928,0.06104-0.01514c0.11328-0.03467,0.21631-0.05029,0.36621-0.05029h18.67676
      c0.08496,0,0.16846,0.0083,0.24854,0.02197c0.0415,0.00684,0.08057,0.01855,0.11963,0.02832
      c0.0332,0.00879,0.06641,0.01563,0.09766,0.02637c0.01611,0.00537,0.03418,0.00781,0.05029,0.0127
      c-0.6499-0.21191-1.10791-0.79395-1.10791-1.47705c0-0.68262,0.50244-1.2749,1.15137-1.48779
      c-0.00977,0.00244-0.02393,0.00439-0.03369,0.00684c-0.15527,0.05127-0.3291,0.08252-0.51465,0.08252H307.61816z"/>
    <path class="spa-i-3" d="M326.54346,219.38525c-0.08008-0.01367-0.16357-0.02197-0.24854-0.02197h-18.67676
      c-0.12891,0-0.25,0.02051-0.36621,0.05029c-0.02002,0.00586-0.0415,0.00879-0.06104,0.01514
      c-0.41699,0.12988-0.71436,0.44238-0.71436,0.80664c0,0.49316,0.51416,0.88477,1.1416,0.88477h18.67676
      c0.63867,0,1.1416-0.3916,1.1416-0.88477c0-0.35449-0.27539-0.65967-0.67578-0.79541
      c-0.03125-0.01074-0.06445-0.01758-0.09766-0.02637C326.62402,219.40332,326.58496,219.39209,326.54346,219.38525z"/>
    <path class="spa-i-3" d="M306.47656,215.82715c0,0.41455,0.51416,0.75,1.1416,0.75h18.68799c0.18555,0,0.35938-0.03125,0.51465-0.08252
      c0.37793-0.12305,0.63916-0.37402,0.63916-0.66748c0-0.40283-0.51563-0.73877-1.15381-0.73877h-18.68799
      C306.99072,215.08838,306.47656,215.42432,306.47656,215.82715z"/>
    <path class="spa-i-0" d="M300.89209,290.02539c-0.18652,0.12305-0.36768,0.24707-0.53955,0.36914
      c-0.0249,0.01758-0.0498,0.03516-0.07471,0.05273c-1.00684,0.72363-1.71973,1.39941-1.97998,1.92188
      c-0.00195,0.00391-0.00586,0.00977-0.00781,0.01465c-0.04688,0.09766-0.07617,0.18848-0.08936,0.27637
      c-0.00244,0.01172-0.00342,0.02441-0.00488,0.03711c-0.0083,0.08398-0.00488,0.16309,0.021,0.2334
      c0.13525,0.68359,0.94092,1.19824,2.09375,1.95898c1.8125,1.26563,2.91016,3.51465,2.04785,4.55566
      c1.49951-1.20898,2.45117-3.01074,2.45117-5.02539c0-2.0918-1.00781-3.98438-2.61816-5.19238
      c-0.28174,0.16113-0.55078,0.32324-0.81055,0.48535c-0.06787,0.04199-0.13672,0.08594-0.20361,0.12891
      C301.08105,289.90234,300.98486,289.96289,300.89209,290.02539z"/>
    <path class="spa-i-0" d="M298.2168,292.93066l-0.02832-0.15332C298.19043,292.83203,298.19971,292.88281,298.2168,292.93066z"/>
    <path class="spa-i-0" d="M307.16992,250.30566c0.73926-0.46973,0.79639-1.14063,0.56006-1.73535
      c-0.32324-0.78223-1.04102-1.12988-1.6792-0.89453l-0.77148,0.28125l1.1084,2.71777
      C306.65625,250.56348,306.93652,250.45117,307.16992,250.30566z"/>
    <path class="spa-i-0" d="M294.86035,253.61816c0.06738,0.34668,4.14111,9.53418,4.14111,9.53418
      c-0.01123-0.06641-0.04492-0.22266-0.05518-0.29004c-0.021-0.10742-0.03223-0.21582-0.03564-0.3252
      c-0.00146-0.03906,0.00293-0.07813,0.00342-0.11719c0.00146-0.07031,0.00146-0.14063,0.00977-0.21094
      c0.00586-0.05078,0.01855-0.10156,0.02734-0.15234c0.01074-0.05957,0.01904-0.11914,0.03467-0.17871
      c0.01367-0.05762,0.03418-0.11426,0.05176-0.17188c0.01758-0.05371,0.0332-0.10742,0.0542-0.16113
      c0.02344-0.06055,0.05225-0.12207,0.08008-0.18359c0.02344-0.05078,0.04541-0.10156,0.07227-0.15234
      c0.03223-0.0625,0.06885-0.12598,0.10498-0.1875c0.02979-0.05078,0.05859-0.10156,0.09131-0.15137
      c0.03955-0.06348,0.0835-0.12402,0.12793-0.18652c0.03662-0.05176,0.07324-0.10352,0.11328-0.15625
      c0.04639-0.05957,0.09473-0.11914,0.14502-0.17773c0.04639-0.05664,0.09375-0.11328,0.14258-0.16797
      c0.04932-0.05566,0.1001-0.10938,0.15234-0.16406c0.05908-0.06348,0.11963-0.125,0.18213-0.1875
      c0.04883-0.04785,0.09814-0.09473,0.14844-0.14258c0.07666-0.07227,0.15527-0.14258,0.2373-0.21582
      c0.04199-0.03613,0.08496-0.07324,0.12891-0.1123c0.09961-0.08398,0.20361-0.16797,0.31006-0.25391
      c0.03027-0.02246,0.05908-0.04688,0.08936-0.06934c0.13281-0.10254,0.271-0.20605,0.41406-0.30859
      c0.00586-0.00391,0.0127-0.00879,0.01953-0.01367c0.51611-0.36816,1.09473-0.7334,1.73633-1.08887
      c0.00439,0,0.0083,0.00098,0.01221,0.00098c1.27148-0.7041,2.77686-1.37598,4.46387-1.99316
      c2.77637-1.01563,6.04492-1.87793,9.59131-2.47266c3.03125-0.49805,5.95068-0.75195,8.59424-0.78125
      c0.04053,0,0.08301-0.00195,0.12305-0.00293c7.54834,0.25,14.14209,1.41699,18.14063,2.98926V244.4043
      c-4.07373-1.44531-10.66016-2.40234-18.21924-2.40234c-0.01416,0-0.02979,0-0.04443,0
      c-3.2002-0.03027-6.87695,0.21484-10.65479,0.84961c-2.67773,0.44727-5.21289,1.0332-7.53076,1.7168
      C299.73438,246.96289,294.29395,250.56055,294.86035,253.61816z M328.94824,249.54492l0.24512,0.02148
      c0.1123,0.73926,0.78467,1.24219,1.69092,1.33203c0.81592,0.07813,1.57666-0.21289,1.65527-0.92773
      c0.18994-1.67871-3.80371-1.46484-3.51318-3.99609c0.13477-1.20703,1.2749-2.0918,3.27881-1.8916
      c0.95117,0.08887,1.75684,0.45801,2.02588,0.70605c-0.19189,0.3252-0.36963,0.64844-0.53711,0.99609l-0.20215-0.02344
      c-0.20166-0.58203-0.65967-0.98438-1.40967-1.0625c-1.04199-0.10156-1.50049,0.29102-1.56641,0.88379
      c-0.15723,1.42188,3.87109,1.55566,3.61377,3.96191c-0.14453,1.3418-1.7002,2.15918-3.74902,1.94727
      c-0.71631-0.06836-1.47803-0.2793-2.06934-0.66016C328.62305,250.40625,328.81299,249.98145,328.94824,249.54492z
      M319.51367,244.2627c0.38086-0.0332,0.77295-0.09082,1.15381-0.13379c0.78223,1.7002,1.80029,3.35742,2.71875,5.04688
      c0.771-1.7793,1.49854-3.57031,2.09229-5.37207c0.18994,0.01074,0.40332,0.01074,0.604,0
      c0.20166-0.01172,0.40234-0.04395,0.60449-0.07813c-1.10742,2.47266-2.22705,4.91309-3.14404,7.46387
      c-0.19043-0.01172-0.38037-0.03418-0.55908-0.02344c-0.20215,0.02344-0.38037,0.05859-0.55957,0.08984
      c-1.29834-2.37109-2.66455-4.66602-4.07422-6.9707C318.73047,244.27441,319.13379,244.28516,319.51367,244.2627z
      M312.75439,245.38086c0.32471-0.06641,0.63867-0.15625,0.96143-0.24512h0.00146l1.41016,6.27734
      c1.11865-0.22363,2.05859-0.49121,2.92139-0.73828c0.01025,0.14453,0.01025,0.30176,0.04248,0.45898
      c0.02344,0.13379,0.10156,0.29004,0.1582,0.42578l-4.90186,0.98438l-1.57813-7.02734
      C312.09521,245.48242,312.43018,245.4375,312.75439,245.38086z M306.25195,246.99316
      c1.7251-0.62598,2.86523-0.41406,3.32422,0.70508c0.70557,1.74609-0.74951,2.70801-2.97656,3.51465v0.01172l1.20898,2.95313
      c-0.3125,0.08984-0.63867,0.17969-0.95117,0.29199c-0.30225,0.11035-0.60547,0.24512-0.90674,0.37988l-2.72998-6.74805
      L306.25195,246.99316z"/>
    <path class="spa-i-0" d="M303.3877,257.625c-0.6416,0.35547-1.22021,0.7207-1.73633,1.08887
      c-0.00684,0.00488-0.01367,0.00977-0.01953,0.01367c-0.14307,0.10254-0.28125,0.20605-0.41406,0.30859
      c-0.03027,0.02246-0.05908,0.04688-0.08936,0.06934c-0.10645,0.08594-0.21045,0.16992-0.31006,0.25391
      c-0.04346,0.03906-0.08691,0.07617-0.12891,0.1123c-0.08203,0.07324-0.16064,0.14355-0.2373,0.21582
      c-0.05029,0.04785-0.09961,0.09473-0.14844,0.14258c-0.0625,0.0625-0.12305,0.12402-0.18213,0.1875
      c-0.05225,0.05469-0.10303,0.1084-0.15234,0.16406c-0.04883,0.05469-0.09619,0.11133-0.14258,0.16797
      c-0.05029,0.05859-0.09863,0.11816-0.14502,0.17773c-0.04004,0.05273-0.07666,0.10449-0.11328,0.15625
      c-0.04443,0.0625-0.08838,0.12402-0.12793,0.18652c-0.03271,0.0498-0.06152,0.10059-0.09131,0.15137
      c-0.03613,0.06152-0.07275,0.125-0.10498,0.1875c-0.02686,0.05078-0.04883,0.10156-0.07227,0.15234
      c-0.02783,0.06152-0.05664,0.12305-0.08008,0.18359c-0.021,0.05371-0.03662,0.10742-0.0542,0.16113
      c-0.01855,0.05762-0.03809,0.11426-0.05176,0.17188c-0.01563,0.05957-0.02393,0.11914-0.03467,0.17871
      c-0.00879,0.05078-0.02148,0.10156-0.02734,0.15234c-0.0083,0.07031-0.0083,0.14063-0.00977,0.21094
      c-0.00049,0.03906-0.00488,0.07813-0.00342,0.11719c0.00342,0.10938,0.01465,0.21777,0.03564,0.3252
      c0.40137,2.10254,3.83789,3.45801,8.88428,3.81543l0.0332-8.71777c-1.58447,0-3.11279-0.17773-4.46387-0.33398
      C303.396,257.62598,303.39209,257.625,303.3877,257.625z"/>
    <path class="spa-i-0" d="M332.93799,262.79102c0.00195-0.01367,0.00488-0.02832,0.00635-0.04199
      c0.00928-0.06543,0.01025-0.12793,0.00684-0.18848c-0.00146-0.02441-0.00049-0.04883-0.00439-0.07227
      c-0.00684-0.04297-0.02246-0.08594-0.03711-0.12695c-0.0083-0.02344-0.01172-0.04785-0.02246-0.07031l-0.05713-0.10742
      l-0.05469-0.09375c-1.17578-0.96289-3.53613-1.74512-6.69287-2.22754l-0.0332,8.7627
      C330.0835,266.23828,332.69824,264.07227,332.93799,262.79102z"/>
    <path class="spa-i-0" d="M332.90967,262.36133c0.01563,0.04102,0.02979,0.08398,0.03711,0.12695
      c0.00391,0.02344,0.00293,0.04785,0.00439,0.07227c0.00391,0.05957,0.00195,0.12305-0.00684,0.18848
      c-0.00146,0.01367-0.00439,0.02832-0.00635,0.04199c-0.22607,1.27637-2.84619,3.44531-6.88867,5.83789
      c-2.5459,1.50586-5.6499,3.10156-9.07617,4.61816c-3.25732,1.43262-5.87549,2.61816-9.05371,3.74805
      c-0.01855,0.00684-0.03662,0.01465-0.05566,0.02051c-6.91748,2.54883-11.81494,5.42188-11.33594,6.85156v0.01074l1.66064,8.89941
      l0.02832,0.15332c-0.02588-0.07031-0.0293-0.14941-0.021-0.2334c0.00146-0.0127,0.00244-0.02539,0.00488-0.03711
      c0.01318-0.08789,0.04248-0.17871,0.08936-0.27637c0.00195-0.00488,0.00586-0.01074,0.00781-0.01465
      c0.26025-0.52246,0.97314-1.19824,1.97998-1.92188c0.0249-0.01758,0.0498-0.03516,0.07471-0.05273
      c0.17188-0.12207,0.35303-0.24609,0.53955-0.36914c0.09277-0.0625,0.18945-0.12305,0.28516-0.18359
      c0.06689-0.04297,0.13574-0.08691,0.20361-0.12891c1.7915-1.12305,4.12891-2.27344,6.48291-3.08984
      c0.00488,0,0.0083-0.00195,0.01172-0.00391c2.83154-0.99512,8.84082-3.21094,11.68311-4.57715
      c2.36328-1.12793,4.55664-2.2998,6.49072-3.44043c5.34766-3.15332,8.69824-6.08008,8.05762-7.2793
      c0,0-0.80518-8.79688-1.3208-9.22266l0.04395,0.08398l0.05713,0.10742
      C332.89795,262.31348,332.90137,262.33789,332.90967,262.36133z"/>
    <path class="spa-i-0" d="M484.22363,199.95752c0.03809,0.02979,0.06836,0.06396,0.09668,0.10205l1.23242-0.3584l0.43457,0.69385
      l-0.43457,0.6377l-1.22266-0.38184c-0.03516,0.04785-0.07422,0.08838-0.12207,0.12451
      c-0.04199,0.11963-0.05469,0.24121-0.05469,0.38037c0,0.64893,0.55859,1.17627,1.25391,1.17627
      c0.54883,0,1.01758-0.34766,1.18457-0.81738c0,0,0.00391-0.00537,0.00488-0.01025c-0.09082-0.0708-0.14453-0.22314-0.12793-0.38135
      c0.02441-0.22461,0.17871-0.39307,0.33789-0.36914c0.15527,0.02148,0.2666,0.21143,0.24414,0.43555
      c-0.02637,0.16406-0.125,0.29883-0.23926,0.34326c0.00195,0.00781,0.00293,0.01563,0.00488,0.01563
      c0.07813,0.59277,0.61621,0.99463,1.22949,0.99463c0.69434,0,1.24414-0.52441,1.24414-1.18506c0-0.04443,0-0.08984-0.01074-0.13379
      l0.00684-0.0083c-0.04785-0.04834-0.08691-0.10498-0.11328-0.16846l-1.28223,0.12061l-0.29004-0.70605l0.58203-0.58154
      l1.12695,0.5918c0.03809-0.03369,0.08105-0.06104,0.12793-0.0835l-0.2832-1.2915l0.99805-0.35889l0.74805,0.71777l-0.80371,1.06494
      c0.03027,0.03662,0.06152,0.07422,0.08301,0.11865l1.28223-0.10986l0.2666,0.76025l-0.57031,0.53809l-1.09961-0.5957
      c-0.05273,0.04834-0.11328,0.08447-0.18262,0.10938c-0.07324,0.15137-0.10547,0.31348-0.10547,0.49707
      c0,0.62598,0.52637,1.12939,1.18555,1.12939c0.41504,0,0.77246-0.2002,0.98438-0.50342l0.0127-0.01465
      c-0.07129-0.09131-0.09277-0.23828-0.04492-0.3877c0.0791-0.20166,0.25781-0.33496,0.41504-0.30273
      c0.14355,0.05566,0.21191,0.26904,0.14355,0.47021c-0.05469,0.15039-0.16016,0.25781-0.26953,0.29443v0.01855
      c0,0.38086,0.1582,0.73926,0.5498,0.79492c0,0,0.4248,0.03467,1.01855-0.4248c0.2666-0.21045,0.47461-0.40137,0.62207-0.55029
      l-1.2373-0.13281l-0.12207-0.73779l0,0v-0.00098v0.00098l0.69336-0.45947l1.17188,0.97266
      c0.03516-0.00293,0.06738,0.00146,0.09961,0.00635c-0.01367-0.08008-0.07422-0.49609,0.03711-0.7876
      c0.13477-0.30273,0.40234-0.35889,0.40234-0.35889l0.0459,0.22363c0.04199-0.05615,0.10449-0.0918,0.16602-0.12695
      c0.00586-0.01709,0.01172-0.04004,0.01367-0.05273c0.02051-0.1792,0.08887-0.6377,0.08887-0.6377
      c0.14453-0.72656-0.98438-1.56641-2.5752-1.92432c-0.66895-0.15332-1.2627-0.18604-1.7666-0.12842
      c-0.08887,0.14111-0.24219,0.23975-0.42578,0.23975c-0.23828,0-0.42773-0.16064-0.48047-0.37305
      c-0.71484-0.40625-1.87305-0.67773-3.21289-0.67773c-0.70801,0-1.375,0.07373-1.9502,0.20459
      c-0.09375,0.45313-0.28613,0.84375-0.5459,1.12256L484.22363,199.95752z"/>
    <path class="spa-i-0" d="M472.52637,201.22412c0.12207,0.29541,0.06836,0.71777,0.05762,0.78955
      c0.02344-0.00537,0.05078-0.00391,0.07422-0.00439l0.00098-0.00244l1.1875-0.97412l0.69336,0.45947v-0.00098v0.00098l0,0
      l-0.12305,0.73779l-1.04199,0.11182c0.15234,0.15088,0.37207,0.35107,0.65039,0.57129
      c0.58105,0.45947,1.01758,0.4248,1.01758,0.4248c0.38184-0.05566,0.54785-0.41406,0.54785-0.79492v-0.01953
      c-0.10645-0.03613-0.21289-0.14355-0.26758-0.29346c-0.06641-0.20117,0-0.41455,0.14551-0.47021
      c0.15625-0.03223,0.33691,0.10107,0.41504,0.30273c0.04688,0.15381,0.02441,0.30518-0.05078,0.39551l0.00488,0.00684
      c0.21191,0.30322,0.57129,0.50342,0.98633,0.50342c0.6582,0,1.18457-0.50342,1.18457-1.12939
      c0-0.18408-0.02344-0.34717-0.10547-0.49902c-0.06543-0.02588-0.12598-0.06348-0.17578-0.11084l-1.10742,0.59912l-0.57031-0.53809
      l0.2793-0.76025l1.28223,0.10986c0.01953-0.04199,0.0498-0.07617,0.07813-0.11084l-0.80957-1.07275l0.74902-0.71777
      l0.99609,0.35889l-0.28223,1.28564c0.05078,0.02197,0.09375,0.05127,0.13281,0.08594l1.12207-0.58838l0.58203,0.58154
      l-0.29004,0.70605l-1.27344-0.11963c-0.02539,0.06543-0.06445,0.12402-0.11719,0.17383l0.00098,0.00195
      c-0.00977,0.04395-0.00977,0.08936-0.00977,0.13379c0,0.66064,0.54785,1.18506,1.24121,1.18506
      c0.61621,0,1.15234-0.40186,1.24316-0.99463c0.00195,0,0.00195-0.00781,0.00391-0.01611
      c-0.11523-0.0459-0.21094-0.18018-0.22754-0.34277c-0.0332-0.22412,0.0791-0.41406,0.23535-0.43555
      c0.15527-0.02393,0.31348,0.14453,0.33691,0.36914c0.02344,0.15918-0.03223,0.3125-0.13086,0.3833
      c0.00195,0.00293,0.00488,0.0083,0.00586,0.0083c0.15625,0.46973,0.62598,0.81738,1.18652,0.81738
      c0.68262,0,1.24219-0.52734,1.24219-1.17627c0-0.13281-0.00684-0.24854-0.04102-0.36377
      c-0.05859-0.03809-0.10645-0.08496-0.14648-0.14111l-1.22363,0.38184l-0.44629-0.6377l0.44629-0.69385l1.23145,0.36084
      c0.02344-0.03418,0.04883-0.06592,0.08008-0.09326l-0.5459-1.17627c-0.28613-0.28369-0.49707-0.70361-0.5957-1.19189
      c-0.56445-0.12305-1.20703-0.19287-1.89258-0.19287c-1.39355,0-2.60449,0.2915-3.31152,0.72559
      c-0.06836,0.18848-0.25195,0.3252-0.46973,0.3252c-0.18652,0-0.34473-0.10107-0.43066-0.24512
      c-0.48438-0.04492-1.0498-0.01074-1.6748,0.13379c-1.59961,0.35791-2.71973,1.19775-2.57324,1.92432
      c0,0,0.06641,0.4585,0.08984,0.6377c0,0.00098,0.00195,0.00439,0.00195,0.00586l0.00879-0.0498
      C472.13379,200.86523,472.40332,200.92139,472.52637,201.22412z"/>
    <path class="spa-i-3" d="M484.31348,195.36963c0.17285,0.36914,0.28711,0.95557,0.28711,1.62354
      c0,0.5376-0.07422,1.02344-0.19238,1.38623l0.42773,0.25879l-0.0459,0.09668c0.25977-0.27881,0.45215-0.66943,0.5459-1.12256
      c0.04199-0.19824,0.07129-0.40283,0.07129-0.61914c0-0.79443-0.30078-1.48389-0.74609-1.86865
      C484.56348,195.22754,484.44727,195.3125,484.31348,195.36963z"/>
    <path class="spa-i-3" d="M482.91016,198.63818l0.46582-0.28223c-0.11426-0.36035-0.18652-0.83545-0.18652-1.36279
      c0-0.66211,0.1123-1.24463,0.28418-1.61426c-0.15137-0.06104-0.2793-0.15771-0.38379-0.27539
      c-0.45996,0.38086-0.77246,1.08057-0.77246,1.88965c0,0.21191,0.0293,0.41357,0.06836,0.60742
      c0.09863,0.48828,0.30957,0.9082,0.5957,1.19189L482.91016,198.63818z"/>
    <path class="spa-i-3" d="M483.87402,198.05615l0.53418,0.32324c0.11816-0.36279,0.19238-0.84863,0.19238-1.38623
      c0-0.66797-0.11426-1.25439-0.28711-1.62354c-0.13086,0.05713-0.27539,0.09082-0.42969,0.09082
      c-0.14648,0-0.28418-0.0293-0.41016-0.08154c-0.17188,0.36963-0.28418,0.95215-0.28418,1.61426
      c0,0.52734,0.07227,1.00244,0.18652,1.36279L483.87402,198.05615z"/>
    <path class="spa-i-3" d="M484.85352,194.12842c-0.10547-0.27002-0.33398-0.48047-0.62109-0.57715v0.57715H484.85352z"/>
    <path class="spa-i-3" d="M483.54785,193.54736c-0.29199,0.09473-0.52539,0.30762-0.63379,0.58105h0.63379V193.54736z"/>
    <path class="spa-i-3" d="M483.47363,195.37891c0.12598,0.05225,0.26367,0.08154,0.41016,0.08154
      c0.1543,0,0.29883-0.03369,0.42969-0.09082c0.13379-0.05713,0.25-0.14209,0.34766-0.24512
      c0.09277-0.1001,0.16406-0.21777,0.20703-0.34766h-1.96875c0.04102,0.12158,0.10645,0.23145,0.19043,0.32666
      C483.19434,195.22119,483.32227,195.31787,483.47363,195.37891z"/>
    <polygon class="spa-i-3" points="485.32715,194.77686 485.32715,194.12842 484.85352,194.12842 484.23242,194.12842 
      484.23242,193.55127 484.23242,192.67334 484.91309,192.67334 484.91309,192.02344 484.23242,192.02344 484.23242,191.37598 
      483.54785,191.37598 483.54785,192.02344 482.85547,192.02344 482.85547,192.67334 483.54785,192.67334 483.54785,193.54736 
      483.54785,194.12842 482.91406,194.12842 482.49512,194.12842 482.49512,194.77686 482.89941,194.77686 484.86816,194.77686 	"/>
    <path class="spa-i-6" d="M476.71191,198.4585c0.21777,0,0.40137-0.13672,0.46973-0.3252c0.01758-0.04883,0.0332-0.1001,0.0332-0.15576
      c0-0.26514-0.22559-0.48047-0.50293-0.48047c-0.27832,0-0.50293,0.21533-0.50293,0.48047
      c0,0.08789,0.03125,0.16553,0.07227,0.23584C476.36719,198.35742,476.52539,198.4585,476.71191,198.4585z"/>
    <path class="spa-i-6" d="M490.97949,198.4585c0.18359,0,0.33691-0.09863,0.42578-0.23975
      c0.04395-0.07178,0.07715-0.15088,0.07715-0.24121c0-0.26514-0.22559-0.48047-0.50293-0.48047
      c-0.27832,0-0.50293,0.21533-0.50293,0.48047c0,0.03857,0.01465,0.07275,0.02246,0.10791
      C490.55176,198.29785,490.74121,198.4585,490.97949,198.4585z"/>
    <path class="spa-i-3" d="M483.88379,203.7417c-3.36816,0.01025-6.40137,0.41406-8.58301,1.05078c0,0,0.22363,0.30273,0.43164,0.71094
      l0.7002,0.14014l-0.40723,0.59619c0.10742,0.29541,0.18848,0.52393,0.1709,0.52393
      c0.04492-0.01709,0.10156-0.03174,0.14941-0.04736c0.00781-0.00342,0.01172-0.00732,0.01758-0.01025
      c0.04004-0.01221,0.08789-0.02344,0.12793-0.03564c1.62598-0.51807,4.43555-0.85986,7.38281-0.86963
      c3.11035,0.01025,5.65137,0.31348,7.6084,0.91797c-0.00684,0,0.0918-0.2373,0.2168-0.49609l-0.01465,0.00293l-0.3584-0.66016
      l0.75098-0.06787l0.00391,0.00977c0.24316-0.42578,0.40723-0.71045,0.4082-0.71436
      c-0.00293-0.00049-0.00684,0.00049-0.01074-0.00049C490.29688,204.15576,487.25195,203.75195,483.88379,203.7417z
      M479.28613,205.38623l-0.79688,0.12354l-0.77148,0.12207c-0.20215,0.02295-0.39063-0.10059-0.4248-0.29053
      c-0.02344-0.18994,0.11035-0.35889,0.30078-0.39111l0.78418-0.11279l0.7959-0.12256
      c0.2002-0.02295,0.37891,0.10107,0.41309,0.28027C479.60938,205.18555,479.47559,205.36475,479.28613,205.38623z
      M481.10938,205.39697c-0.27246,0-0.49316-0.20508-0.49316-0.4585c0-0.25293,0.2207-0.4585,0.49316-0.4585
      s0.49219,0.20557,0.49219,0.4585C481.60156,205.19189,481.38184,205.39697,481.10938,205.39697z M485.02637,205.14014h-1.1084
      h-1.07422c-0.20215,0-0.36914-0.15771-0.36914-0.34766s0.15625-0.3457,0.35938-0.3457h2.19238
      c0.21191,0,0.36914,0.15576,0.36914,0.3457S485.22656,205.14014,485.02637,205.14014z M486.6709,205.39697
      c-0.27246,0-0.49121-0.20508-0.49121-0.4585c0-0.25293,0.21875-0.4585,0.49121-0.4585c0.27148,0,0.49316,0.20557,0.49316,0.4585
      C487.16406,205.19189,486.94238,205.39697,486.6709,205.39697z M490.0625,205.63184l-0.77246-0.12207l-0.7959-0.12354
      c-0.2002-0.02148-0.33594-0.20068-0.30176-0.39111c0.02246-0.1792,0.21191-0.30322,0.41504-0.28027l0.79297,0.12256l0.7832,0.11279
      c0.20215,0.03223,0.3252,0.20117,0.30371,0.39111C490.4541,205.53125,490.26367,205.65479,490.0625,205.63184z"/>
    <path class="spa-i-3" d="M476.3457,206.71631c-0.20215,0.08984-0.35156,0.24219-0.39648,0.41504
      c-0.07813,0.39258,0.3584,0.7168,1.09766,0.94043c0.59277,0.1499,1.72949,0.3374,3.07422,0.48193h7.52148
      c1.3418-0.14453,2.47559-0.33203,3.06836-0.48193c0.7373-0.22363,1.17383-0.54785,1.0957-0.94043
      c-0.04395-0.17773-0.20117-0.33545-0.41309-0.42529c-1.63477-0.52588-4.55566-0.89502-7.50977-0.89502
      c-2.87207,0-5.74805,0.35303-7.39258,0.85938c-0.04004,0.01221-0.08789,0.02344-0.12793,0.03564
      C476.35742,206.70898,476.35352,206.71289,476.3457,206.71631z M483.87402,206.62842c2.72852,0,5.2041,0.30176,7.08203,0.78271
      c-1.87793,0.48193-4.35352,0.72754-7.08203,0.72754c-2.71973,0-5.18262-0.24561-7.0498-0.72754
      C478.69141,206.93018,481.1543,206.63965,483.87402,206.62842z"/>
    <path class="spa-i-3" d="M491.79688,203.37256c-0.25879,0.15576-0.57031,0.24512-0.89648,0.24512
      c-0.7041,0-1.34277-0.39063-1.58691-0.96289c-0.32617,0.34766-0.77441,0.56006-1.29785,0.56006
      c-0.57324,0-1.08789-0.25684-1.41309-0.64844c-0.3125,0.30176-0.75,0.48145-1.21973,0.48145
      c-0.61426,0-1.1748-0.31445-1.48828-0.77295c-0.32422,0.4585-0.87109,0.77295-1.49902,0.77295
      c-0.4707,0-0.90625-0.17969-1.21973-0.48145c-0.32422,0.3916-0.83984,0.64844-1.39844,0.64844
      c-0.53711,0-0.98535-0.2124-1.30859-0.56006c-0.24707,0.57227-0.88477,0.96289-1.58887,0.96289
      c-0.3252,0-0.62891-0.08936-0.88574-0.24512c-0.11035,0.27832-0.31348,0.51904-0.58984,0.62646l0.05957,0.15381
      c1.34863-0.38525,3.00879-0.68066,4.87012-0.84766c0.0127-0.00098,0.02441-0.00146,0.03711-0.00293
      c0.35449-0.03125,0.71582-0.05957,1.08594-0.08105c0.13965-0.0083,0.28418-0.0127,0.4248-0.02002
      c0.25391-0.0127,0.50586-0.02637,0.76465-0.03467c0.32324-0.00928,0.65234-0.01221,0.98242-0.01416
      c0.08594-0.00049,0.16992-0.00488,0.25488-0.00488c0.00195,0,0.00391,0,0.00586,0s0.00391,0,0.00488,0
      c0.42773,0,0.84961,0.00781,1.26563,0.01953c0.02246,0.00049,0.04492,0.00195,0.06641,0.00391
      c0.39648,0.01221,0.78613,0.03027,1.16895,0.05371c0.0332,0.00244,0.06445,0.00537,0.09766,0.00732
      c0.37012,0.02393,0.7334,0.05127,1.08984,0.08447c0.04492,0.00391,0.08984,0.00977,0.13477,0.01465
      c0.33887,0.03271,0.6709,0.06885,0.99512,0.10986c0.06348,0.0083,0.12207,0.01758,0.18555,0.02637
      c0.29785,0.03955,0.59082,0.08203,0.875,0.12744c0.09668,0.01563,0.1875,0.03369,0.2832,0.05029
      c0.2373,0.0415,0.47266,0.0835,0.69824,0.12842c0.1416,0.02881,0.27344,0.06055,0.41113,0.09082
      c0.16406,0.03662,0.3291,0.07227,0.48633,0.11133c0.19043,0.04639,0.37012,0.0957,0.54688,0.14551
      c0.07031,0.01904,0.14551,0.03662,0.21289,0.05615l0.05469-0.14941C492.15234,203.94336,491.91895,203.68164,491.79688,203.37256z"
      />
    <path class="spa-i-3" d="M494.40332,202.36426l-0.01563-0.00146c-0.14746,0.14893-0.35547,0.33984-0.62207,0.55029
      c-0.59375,0.45947-1.01855,0.4248-1.01855,0.4248c-0.3916-0.05566-0.5498-0.41406-0.5498-0.79492v-0.01855
      c-0.04395,0.01514-0.08887,0.02148-0.13184,0.0083c-0.04492-0.01318-0.08105-0.04395-0.1123-0.08252l-0.0127,0.01465
      c-0.21191,0.30322-0.56934,0.50342-0.98438,0.50342c-0.65918,0-1.18555-0.50342-1.18555-1.12939
      c0-0.18359,0.03223-0.3457,0.10547-0.49707c-0.06055,0.02344-0.12598,0.03809-0.19531,0.03809
      c-0.15527,0-0.2959-0.06396-0.39453-0.16406l-0.00684,0.0083c0.01074,0.04395,0.01074,0.08936,0.01074,0.13379
      c0,0.66064-0.5498,1.18506-1.24414,1.18506c-0.61328,0-1.15137-0.40186-1.22949-0.99463
      c-0.00195,0-0.00293-0.00781-0.00488-0.01563c-0.03613,0.01318-0.07129,0.02002-0.10742,0.01563
      c-0.04004-0.00342-0.07617-0.01855-0.10742-0.04395c-0.00098,0.00488-0.00488,0.01025-0.00488,0.01025
      c-0.16699,0.46973-0.63574,0.81738-1.18457,0.81738c-0.69531,0-1.25391-0.52734-1.25391-1.17627
      c0-0.13916,0.0127-0.26074,0.05469-0.38037c-0.0918,0.06592-0.2002,0.1123-0.32422,0.1123
      c-0.1123,0-0.20996-0.04004-0.29785-0.0957c0.03418,0.11523,0.04102,0.23096,0.04102,0.36377
      c0,0.64893-0.55957,1.17627-1.24219,1.17627c-0.56055,0-1.03027-0.34766-1.18652-0.81738
      c-0.00098,0-0.00391-0.00537-0.00586-0.0083c-0.03223,0.02441-0.06836,0.03857-0.10547,0.04199
      c-0.03711,0.00488-0.07422-0.00146-0.1084-0.01611c-0.00195,0.0083-0.00195,0.01611-0.00391,0.01611
      c-0.09082,0.59277-0.62695,0.99463-1.24316,0.99463c-0.69336,0-1.24121-0.52441-1.24121-1.18506
      c0-0.04443,0-0.08984,0.00977-0.13379l-0.00098-0.00195c-0.09766,0.09717-0.23535,0.15771-0.38965,0.15771
      c-0.07227,0-0.1416-0.01465-0.20605-0.04004c0.08203,0.15186,0.10547,0.31494,0.10547,0.49902
      c0,0.62598-0.52637,1.12939-1.18457,1.12939c-0.41504,0-0.77441-0.2002-0.98633-0.50342l-0.00488-0.00684
      c-0.02832,0.03516-0.06348,0.0625-0.10645,0.07471c-0.04492,0.01318-0.08984,0.00684-0.13574-0.00928v0.01953
      c0,0.38086-0.16602,0.73926-0.54785,0.79492c0,0-0.43652,0.03467-1.01758-0.4248c-0.27832-0.22021-0.49805-0.42041-0.65039-0.57129
      l-0.17773,0.01953c0.05859,0.13037,0.0625,0.26563-0.02246,0.3501c-0.00391,0.00342-0.00781,0.00391-0.01172,0.00732
      c0.2041,0.28564,0.63379,0.84326,1.01855,1.05566c0.28027,0.16797,0.71777,0.34717,1.05371,0.27979
      c0.06152-0.00977,0.11426-0.03418,0.16895-0.05518c0.27637-0.10742,0.47949-0.34814,0.58984-0.62646
      c0.25684,0.15576,0.56055,0.24512,0.88574,0.24512c0.7041,0,1.3418-0.39063,1.58887-0.96289
      c0.32324,0.34766,0.77148,0.56006,1.30859,0.56006c0.55859,0,1.07422-0.25684,1.39844-0.64844
      c0.31348,0.30176,0.74902,0.48145,1.21973,0.48145c0.62793,0,1.1748-0.31445,1.49902-0.77295
      c0.31348,0.4585,0.87402,0.77295,1.48828,0.77295c0.46973,0,0.90723-0.17969,1.21973-0.48145
      c0.3252,0.3916,0.83984,0.64844,1.41309,0.64844c0.52344,0,0.97168-0.2124,1.29785-0.56006
      c0.24414,0.57227,0.88281,0.96289,1.58691,0.96289c0.32617,0,0.6377-0.08936,0.89648-0.24512
      c0.12207,0.30908,0.35547,0.5708,0.66992,0.65527c0.02832,0.00732,0.05176,0.02197,0.07813,0.02637
      c0.34766,0.06738,0.78418-0.11182,1.06445-0.27979c0.35156-0.19922,0.72363-0.69482,0.92969-0.99268
      c-0.04297-0.01514-0.08301-0.03613-0.11328-0.07031C494.34082,202.62744,494.34375,202.49365,494.40332,202.36426z"/>
    <path class="spa-i-3" d="M491.16602,203.86426c-0.1377-0.03027-0.26953-0.06201-0.41113-0.09082
      c-0.22559-0.04492-0.46094-0.08691-0.69824-0.12842c-0.0957-0.0166-0.18652-0.03467-0.2832-0.05029
      c-0.28418-0.04541-0.57715-0.08789-0.875-0.12744c-0.06348-0.00879-0.12207-0.01807-0.18555-0.02637
      c-0.32227-0.04102-0.65625-0.07715-0.99512-0.10986c-0.04492-0.00488-0.08984-0.01074-0.13477-0.01465
      c-0.35645-0.0332-0.71973-0.06055-1.08984-0.08447c-0.0332-0.00195-0.06445-0.00488-0.09766-0.00732
      c-0.38281-0.02344-0.77344-0.0415-1.16895-0.05371c-0.02148-0.00195-0.04395-0.00342-0.06641-0.00391
      c-0.41699-0.01221-0.8418-0.01953-1.27051-0.01953c-0.00195,0-0.00391,0-0.00586,0c-0.08496,0-0.16895,0.00439-0.25488,0.00488
      c-0.33008,0.00146-0.65918,0.00488-0.98242,0.01416c-0.25879,0.0083-0.51074,0.02197-0.76465,0.03467
      c-0.14063,0.00732-0.28418,0.01172-0.4248,0.02002c-0.37012,0.02148-0.73145,0.0498-1.08594,0.08105
      c-0.0127,0.00146-0.02441,0.00195-0.03711,0.00293c-1.86133,0.16699-3.52148,0.4624-4.87012,0.84766
      c-0.1084,0.03125-0.22559,0.06006-0.33203,0.0918c-0.14551,0.04492-0.24512,0.2124-0.20117,0.35791
      c0.0459,0.12305,0.22461,0.23486,0.37012,0.18994c2.18164-0.63672,5.21484-1.04053,8.58301-1.05078
      c3.36816,0.01025,6.41309,0.41406,8.59473,1.05078c0.00391,0.00098,0.00781,0,0.01074,0.00049
      c0.14453,0.03613,0.31543-0.06934,0.35742-0.19043c0.0459-0.14551-0.05469-0.31299-0.2002-0.35791
      c-0.1416-0.04297-0.29688-0.08203-0.44727-0.12354c-0.17676-0.0498-0.35645-0.09912-0.54688-0.14551
      C491.49512,203.93652,491.33008,203.90039,491.16602,203.86426z"/>
    <path class="spa-i-3" d="M472.3877,202.32617c-0.0166-0.0918-0.00195-0.18018,0.06055-0.24121
      c0.03613-0.03662,0.08398-0.05908,0.13574-0.07129c0.01074-0.07178,0.06445-0.49414-0.05762-0.78955
      c-0.12305-0.30273-0.39258-0.35889-0.39258-0.35889l-0.00879,0.0498l-0.03613,0.17383
      c-0.11133-0.14502-0.30176-0.22363-0.50195-0.22363c-0.33594,0-0.65039,0.22363-0.65039,0.31348
      c0,0,0.53711,0.16699,0.76172,0.36963C471.95117,201.7832,472.28125,202.20313,472.3877,202.32617z"/>
    <polygon class="spa-i-3" points="474.54004,201.49121 474.54004,201.49219 474.54004,201.49219 	"/>
    <path class="spa-i-3" d="M473.04102,202.15234c0.07324,0.06104,0.12109,0.13379,0.15625,0.20898l0.17773-0.01953l1.04199-0.11182
      l0.12305-0.73779l-0.69336-0.45947l-1.1875,0.97412l-0.00098,0.00244C472.78516,202.00537,472.9248,202.05078,473.04102,202.15234z
      "/>
    <path class="spa-i-3" d="M473.19727,202.36133c-0.03516-0.0752-0.08301-0.14795-0.15625-0.20898
      c-0.11621-0.10156-0.25586-0.14697-0.38281-0.14307c-0.02344,0.00049-0.05078-0.00098-0.07422,0.00439
      c-0.05176,0.01221-0.09961,0.03467-0.13574,0.07129c-0.0625,0.06104-0.07715,0.14941-0.06055,0.24121
      c0.01953,0.11035,0.08398,0.22705,0.19336,0.31836c0.18652,0.17383,0.44531,0.20801,0.58203,0.07422
      c0.00391-0.00342,0.00781-0.00391,0.01172-0.00732C473.25977,202.62695,473.25586,202.4917,473.19727,202.36133z"/>
    <path class="spa-i-3" d="M475.72656,202.53271c0.04297-0.01221,0.07813-0.03955,0.10645-0.07471
      c0.0752-0.09033,0.09766-0.2417,0.05078-0.39551c-0.07813-0.20166-0.25879-0.33496-0.41504-0.30273
      c-0.14551,0.05566-0.21191,0.26904-0.14551,0.47021c0.05469,0.1499,0.16113,0.25732,0.26758,0.29346
      C475.63672,202.53955,475.68164,202.5459,475.72656,202.53271z"/>
    <path class="spa-i-3" d="M477.56152,200.85352c0-0.07617,0.01953-0.14795,0.0498-0.21387l-1.28223-0.10986l-0.2793,0.76025
      l0.57031,0.53809l1.10742-0.59912C477.62598,201.13379,477.56152,201.00146,477.56152,200.85352z"/>
    <path class="spa-i-3" d="M478.10938,200.32813c0.08398,0,0.16309,0.02148,0.2334,0.05469l0.28223-1.28564l-0.99609-0.35889
      l-0.74902,0.71777l0.80957,1.07275C477.79004,200.40869,477.93652,200.32813,478.10938,200.32813z"/>
    <path class="spa-i-3" d="M478.6582,200.85352c0,0.07031-0.0166,0.13525-0.04199,0.19482l1.27344,0.11963l0.29004-0.70605
      l-0.58203-0.58154l-1.12207,0.58838C478.58691,200.56445,478.6582,200.69971,478.6582,200.85352z"/>
    <path class="spa-i-3" d="M478.49902,201.22217c0.05273-0.0498,0.0918-0.1084,0.11719-0.17383
      c0.02539-0.05957,0.04199-0.12451,0.04199-0.19482c0-0.15381-0.07129-0.28906-0.18262-0.38477
      c-0.03906-0.03467-0.08203-0.06396-0.13281-0.08594c-0.07031-0.0332-0.14941-0.05469-0.2334-0.05469
      c-0.17285,0-0.31934,0.08057-0.41992,0.20068c-0.02832,0.03467-0.05859,0.06885-0.07813,0.11084
      c-0.03027,0.06592-0.0498,0.1377-0.0498,0.21387c0,0.14795,0.06445,0.28027,0.16602,0.37549
      c0.0498,0.04736,0.11035,0.08496,0.17578,0.11084c0.06445,0.02539,0.13379,0.04004,0.20605,0.04004
      C478.26367,201.37988,478.40137,201.31934,478.49902,201.22217z"/>
    <path class="spa-i-3" d="M481.19238,201.50635c0.09863-0.0708,0.1543-0.22412,0.13086-0.3833
      c-0.02344-0.22461-0.18164-0.39307-0.33691-0.36914c-0.15625,0.02148-0.26855,0.21143-0.23535,0.43555
      c0.0166,0.1626,0.1123,0.29688,0.22754,0.34277c0.03418,0.01465,0.07129,0.021,0.1084,0.01611
      C481.12402,201.54492,481.16016,201.53076,481.19238,201.50635z"/>
    <path class="spa-i-3" d="M483.44727,200.06201l-1.23145-0.36084l-0.44629,0.69385l0.44629,0.6377l1.22363-0.38184
      c-0.06152-0.08496-0.10352-0.17969-0.10352-0.28955C483.33594,200.24707,483.38184,200.14746,483.44727,200.06201z"/>
    <path class="spa-i-3" d="M484.22363,199.95752l0.56641-1.22266l0.0459-0.09668l-0.42773-0.25879l-0.53418-0.32324l-0.49805,0.2998
      l-0.46582,0.28223l0.07129,0.1543l0.5459,1.17627c0.09668-0.08008,0.21875-0.13281,0.35645-0.13281
      C484.01367,199.83594,484.12891,199.88574,484.22363,199.95752z"/>
    <path class="spa-i-3" d="M484.33008,200.65088l1.22266,0.38184l0.43457-0.6377l-0.43457-0.69385l-1.23242,0.3584
      c0.06738,0.08594,0.1123,0.18652,0.1123,0.30176C484.43262,200.47119,484.39063,200.56592,484.33008,200.65088z"/>
    <path class="spa-i-3" d="M484.20801,200.77539c0.04785-0.03613,0.08691-0.07666,0.12207-0.12451
      c0.06055-0.08496,0.10254-0.17969,0.10254-0.28955c0-0.11523-0.04492-0.21582-0.1123-0.30176
      c-0.02832-0.03809-0.05859-0.07227-0.09668-0.10205c-0.09473-0.07178-0.20996-0.12158-0.33984-0.12158
      c-0.1377,0-0.25977,0.05273-0.35645,0.13281c-0.03125,0.02734-0.05664,0.05908-0.08008,0.09326
      c-0.06543,0.08545-0.11133,0.18506-0.11133,0.29932c0,0.10986,0.04199,0.20459,0.10352,0.28955
      c0.04004,0.05615,0.08789,0.10303,0.14648,0.14111c0.08789,0.05566,0.18555,0.0957,0.29785,0.0957
      C484.00781,200.8877,484.11621,200.84131,484.20801,200.77539z"/>
    <path class="spa-i-3" d="M486.7041,201.54834c0.03613,0.00439,0.07129-0.00244,0.10742-0.01563
      c0.11426-0.04443,0.21289-0.1792,0.23926-0.34326c0.02246-0.22412-0.08887-0.41406-0.24414-0.43555
      c-0.15918-0.02393-0.31348,0.14453-0.33789,0.36914c-0.0166,0.1582,0.03711,0.31055,0.12793,0.38135
      C486.62793,201.52979,486.66406,201.54492,486.7041,201.54834z"/>
    <path class="spa-i-3" d="M489.30957,200.47217l-1.12695-0.5918l-0.58203,0.58154l0.29004,0.70605l1.28223-0.12061
      c-0.02344-0.05957-0.04004-0.12402-0.04004-0.19385C489.13281,200.70166,489.20313,200.56787,489.30957,200.47217z"/>
    <path class="spa-i-3" d="M490.09668,200.521l0.80371-1.06494l-0.74805-0.71777l-0.99805,0.35889l0.2832,1.2915
      c0.07422-0.03613,0.1543-0.06055,0.24316-0.06055C489.85059,200.32813,489.99512,200.40527,490.09668,200.521z"/>
    <path class="spa-i-3" d="M490.05859,201.23242l1.09961,0.5957l0.57031-0.53809l-0.2666-0.76025l-1.28223,0.10986
      c0.03125,0.06592,0.0498,0.1377,0.0498,0.21387C490.22949,201.00391,490.16211,201.13721,490.05859,201.23242z"/>
    <path class="spa-i-3" d="M489.68066,201.37988c0.06934,0,0.13477-0.01465,0.19531-0.03809
      c0.06934-0.0249,0.12988-0.06104,0.18262-0.10938c0.10352-0.09521,0.1709-0.22852,0.1709-0.37891
      c0-0.07617-0.01855-0.14795-0.0498-0.21387c-0.02148-0.04443-0.05273-0.08203-0.08301-0.11865
      c-0.10156-0.11572-0.24609-0.19287-0.41602-0.19287c-0.08887,0-0.16895,0.02441-0.24316,0.06055
      c-0.04688,0.02246-0.08984,0.0498-0.12793,0.0835c-0.10645,0.0957-0.17676,0.22949-0.17676,0.38135
      c0,0.06982,0.0166,0.13428,0.04004,0.19385c0.02637,0.06348,0.06543,0.12012,0.11328,0.16846
      C489.38477,201.31592,489.52539,201.37988,489.68066,201.37988z"/>
    <path class="spa-i-3" d="M492.06543,202.53271c0.04297,0.01318,0.08789,0.00684,0.13184-0.0083
      c0.10938-0.03662,0.21484-0.14404,0.26953-0.29443c0.06836-0.20117,0-0.41455-0.14355-0.47021
      c-0.15723-0.03223-0.33594,0.10107-0.41504,0.30273c-0.04785,0.14941-0.02637,0.29639,0.04492,0.3877
      C491.98438,202.48877,492.02051,202.51953,492.06543,202.53271z"/>
    <path class="spa-i-3" d="M495.43262,200.86523c0,0-0.26758,0.05615-0.40234,0.35889c-0.11133,0.2915-0.05078,0.70752-0.03711,0.7876
      c0.06348,0.00977,0.12109,0.02979,0.16016,0.07324c0.05078,0.05078,0.06738,0.12109,0.06641,0.19531
      c0.13574-0.1626,0.42676-0.52246,0.66016-0.73193c0.21387-0.20264,0.75293-0.36963,0.75293-0.36963
      c0-0.08984-0.31445-0.31348-0.65039-0.31348c-0.125,0-0.2373,0.04004-0.33789,0.09668
      c-0.06152,0.03516-0.12402,0.0708-0.16602,0.12695L495.43262,200.86523z"/>
    <polygon class="spa-i-3" points="493.02832,201.49219 493.02832,201.49219 493.02832,201.49121 	"/>
    <path class="spa-i-3" d="M494.56055,202.15234c0.10156-0.08594,0.22266-0.13623,0.33301-0.14697l-1.17188-0.97266l-0.69336,0.45947
      l0.12207,0.73779l1.2373,0.13281l0.01563,0.00146C494.4375,202.28857,494.48633,202.21436,494.56055,202.15234z"/>
    <path class="spa-i-3" d="M495.15332,202.08496c-0.03906-0.04346-0.09668-0.06348-0.16016-0.07324
      c-0.03223-0.00488-0.06445-0.00928-0.09961-0.00635c-0.11035,0.01074-0.23145,0.06104-0.33301,0.14697
      c-0.07422,0.06201-0.12305,0.13623-0.15723,0.21191c-0.05957,0.12939-0.0625,0.26318,0.02246,0.34717
      c0.03027,0.03418,0.07031,0.05518,0.11328,0.07031c0.1416,0.0498,0.33594,0.00049,0.49121-0.13721
      c0.12695-0.10449,0.1875-0.24219,0.18945-0.36426C495.2207,202.20605,495.2041,202.13574,495.15332,202.08496z"/>
    <polygon class="spa-i-0" points="475.73242,205.50342 475.70313,205.49756 475.35645,206.05713 476.00488,206.27051 
      476.02539,206.23975 476.43262,205.64355 	"/>
    <path class="spa-i-10" d="M479.17383,204.71484l-0.7959,0.12256l-0.78418,0.11279c-0.19043,0.03223-0.32422,0.20117-0.30078,0.39111
      c0.03418,0.18994,0.22266,0.31348,0.4248,0.29053l0.77148-0.12207l0.79688-0.12354
      c0.18945-0.02148,0.32324-0.20068,0.30078-0.39111C479.55273,204.81592,479.37402,204.69189,479.17383,204.71484z"/>
    <ellipse class="spa-i-6" cx="481.10938" cy="204.93848" rx="0.49316" ry="0.4585"/>
    <path class="spa-i-0" d="M485.02637,204.44678h-2.19238c-0.20313,0-0.35938,0.15576-0.35938,0.3457s0.16699,0.34766,0.36914,0.34766
      h1.07422h1.1084c0.2002,0,0.36914-0.15771,0.36914-0.34766S485.23828,204.44678,485.02637,204.44678z"/>
    <ellipse class="spa-i-6" cx="486.6709" cy="204.93848" rx="0.49121" ry="0.4585"/>
    <path class="spa-i-10" d="M490.18359,204.9502l-0.7832-0.11279l-0.79297-0.12256c-0.20313-0.02295-0.39258,0.10107-0.41504,0.28027
      c-0.03418,0.19043,0.10156,0.36963,0.30176,0.39111l0.7959,0.12354l0.77246,0.12207c0.20117,0.02295,0.3916-0.10059,0.4248-0.29053
      C490.50879,205.15137,490.38574,204.98242,490.18359,204.9502z"/>
    <polygon class="spa-i-0" points="491.32617,205.56543 491.68457,206.22559 491.69922,206.22266 492.35547,206.09131 
      492.08105,205.50732 492.07715,205.49756 	"/>
    <path class="spa-i-0" d="M490.95605,207.41113c-1.87793-0.48096-4.35352-0.78271-7.08203-0.78271
      c-2.71973,0.01123-5.18262,0.30176-7.0498,0.78271c1.86719,0.48193,4.33008,0.72754,7.0498,0.72754
      C486.60254,208.13867,489.07813,207.89307,490.95605,207.41113z"/>
    <path class="spa-i-9" d="M496.95508,324.13184c-0.0752-0.03809-0.1582-0.07227-0.23633-0.1084v0.16406h-8.5498
      c-1.12305,0.56543-2.63867,0.91699-4.29492,0.91699c-1.63477,0-3.10449-0.36328-4.21484-0.91699h-8.53223v-0.16797
      c-0.07129,0.03418-0.14355,0.06445-0.21094,0.09961c-1.14258,0.60449-2.70996,0.98535-4.42188,0.98535v2.69727
      c1.71191,0,3.2793-0.39063,4.42188-0.98438c1.11719-0.58203,2.61523-0.91797,4.31934-0.91797
      c0.40039,0,0.79492,0.02246,1.17871,0.0625c0.01367,0.00195,0.02637,0.00391,0.04004,0.00488
      c0.36523,0.04004,0.72266,0.09668,1.06152,0.16992c0.02344,0.00488,0.0459,0.00879,0.06836,0.0166
      c0.33984,0.07324,0.66309,0.16211,0.96973,0.26465c0.01172,0.00391,0.02246,0.00879,0.03516,0.01367
      c0.31641,0.10645,0.6123,0.22949,0.88672,0.3623c1.12988,0.59375,2.67383,1.00781,4.39844,1.00781
      c1.7002,0,3.25586-0.36914,4.38574-0.96191c1.12988-0.58301,2.68555-0.94043,4.37598-0.94043
      c0.41895,0,0.8291,0.02246,1.22656,0.06543c1.1875,0.12793,2.25195,0.43555,3.09277,0.86328
      c0.27832,0.14844,0.58105,0.2793,0.90137,0.39746c0.01367,0.00586,0.02832,0.01074,0.04199,0.01563
      c0.29785,0.10742,0.61621,0.19727,0.94531,0.27637c0.0498,0.0127,0.10254,0.02539,0.15527,0.03613
      c0.31836,0.07031,0.65039,0.12598,0.99219,0.16602c0.0498,0.00586,0.0957,0.0127,0.14453,0.01855
      c0.37793,0.03906,0.76953,0.06152,1.17188,0.0625c0.00781,0,0.01465,0.00098,0.02344,0.00098l0.02148-2.69727
      C499.62988,325.10449,498.09668,324.73535,496.95508,324.13184z"/>
    <path class="spa-i-7" d="M500.13574,327.73828c-0.04883-0.00586-0.09473-0.0127-0.14453-0.01855
      c-0.3418-0.04004-0.67383-0.0957-0.99219-0.16602c-0.05273-0.01074-0.10547-0.02344-0.15527-0.03613
      c-0.33008-0.07813-0.64746-0.16992-0.94531-0.27637c-0.01367-0.00488-0.02832-0.00977-0.04199-0.01563
      c-0.32129-0.11816-0.62402-0.24902-0.90137-0.39551c-0.84082-0.42871-1.90527-0.7373-3.09277-0.86523
      c-0.39746-0.04297-0.80762-0.06543-1.22656-0.06543c-1.69043,0-3.24609,0.35742-4.37598,0.94043
      c-1.12988,0.59277-2.68555,0.96191-4.38574,0.96191c-1.72461,0-3.26855-0.41406-4.39844-1.00781
      c-0.27441-0.13281-0.57129-0.25586-0.88672-0.3623c-0.0127-0.00488-0.02344-0.00977-0.03516-0.01367
      c-0.30664-0.10254-0.62988-0.19141-0.96973-0.26465c-0.02246-0.00781-0.04492-0.01172-0.06836-0.0166
      c-0.33887-0.07324-0.69629-0.12988-1.06152-0.16992c-0.01367-0.00098-0.02637-0.00293-0.04004-0.00488
      c-0.38379-0.04004-0.77637-0.0625-1.17871-0.0625c-1.7041,0-3.20215,0.33594-4.31934,0.91797
      c-1.14258,0.59375-2.70996,0.98438-4.42188,0.98438V330.5c1.71191,0,3.2793-0.3916,4.42188-0.98535
      c1.11719-0.58203,2.61523-0.91895,4.31934-0.91895c0.40039,0,0.79492,0.02246,1.17871,0.0625
      c0.01367,0.00098,0.02637,0.00391,0.04004,0.00586c0.36523,0.03906,0.72266,0.09668,1.06152,0.16992
      c0.02344,0.00391,0.0459,0.00879,0.06836,0.01367c0.33984,0.07422,0.66309,0.16406,0.96973,0.26855
      c0.01172,0.00293,0.02246,0.00781,0.03516,0.01172c0.31641,0.10742,0.6123,0.22852,0.88672,0.36328
      c1.12988,0.60449,2.67383,1.00879,4.39844,1.00879c1.7002,0,3.25586-0.38086,4.38574-0.96289
      c1.12988-0.58301,2.68555-0.94141,4.37598-0.94141c1.67773,0,3.19922,0.3584,4.31934,0.94141
      c0.27441,0.14355,0.57324,0.27051,0.88867,0.38477c0.02344,0.00879,0.04688,0.0166,0.07031,0.02637
      c0.29297,0.10352,0.60254,0.19043,0.92383,0.2666c0.05371,0.0127,0.10938,0.02539,0.16504,0.03809
      c0.31641,0.06836,0.64746,0.12305,0.9873,0.16309c0.0498,0.00781,0.09668,0.01367,0.14746,0.01953
      c0.37793,0.03809,0.76758,0.0625,1.16992,0.0625c0.00781,0,0.01465,0.00098,0.02344,0.00098l0.02148-2.69727
      c-0.00684,0-0.01367,0-0.02148,0c-0.00879,0-0.01563-0.00098-0.02344-0.00098
      C500.90723,327.7998,500.51367,327.77832,500.13574,327.73828z"/>
    <path class="spa-i-9" d="M500.1377,330.43555c-0.05078-0.00586-0.09766-0.01172-0.14746-0.01953
      c-0.33984-0.04004-0.6709-0.09473-0.9873-0.16309c-0.05566-0.0127-0.11133-0.02539-0.16504-0.03809
      c-0.32227-0.0752-0.63086-0.16406-0.92383-0.2666c-0.02344-0.00977-0.04688-0.01758-0.07031-0.02637
      c-0.31836-0.11426-0.61523-0.24219-0.88867-0.38477c-1.12012-0.58301-2.6416-0.94141-4.31934-0.94141
      c-1.69043,0-3.24609,0.3584-4.37598,0.94141c-1.12988,0.58203-2.68555,0.96289-4.38574,0.96289
      c-1.72461,0-3.26855-0.4043-4.39844-1.00879c-0.27441-0.13574-0.57129-0.25586-0.88672-0.36328
      c-0.0127-0.00391-0.02344-0.00879-0.03516-0.01172c-0.30664-0.10449-0.62988-0.19434-0.96973-0.26855
      c-0.02246-0.00488-0.04492-0.00977-0.06836-0.01367c-0.33887-0.07324-0.69629-0.13086-1.06152-0.16992
      c-0.01367-0.00195-0.02637-0.00488-0.04004-0.00586c-0.38379-0.03906-0.77637-0.0625-1.17871-0.0625
      c-1.7041,0-3.20215,0.33691-4.31934,0.91895c-1.14258,0.59375-2.70996,0.98535-4.42188,0.98535v2.68555
      c1.71191,0,3.2793-0.38086,4.42188-0.98535c1.11719-0.57129,2.61523-0.89551,4.31934-0.89551
      c1.62012,0,3.12207,0.33496,4.24023,0.88477c1.12988,0.60254,2.67383,0.99609,4.39844,0.99609
      c1.7002,0,3.25586-0.3584,4.38574-0.95117c1.12988-0.58203,2.68555-0.92969,4.37598-0.92969
      c1.67773,0,3.19922,0.34766,4.31934,0.91797c0.27539,0.14746,0.5752,0.2793,0.89063,0.39551
      c0.02148,0.00781,0.04492,0.0166,0.06641,0.02344c0.29492,0.10645,0.60645,0.19336,0.93164,0.26855
      c0.05078,0.01367,0.10352,0.02637,0.1582,0.03613c0.31738,0.06836,0.64941,0.12207,0.99121,0.16211
      c0.04785,0.00488,0.09473,0.00977,0.14258,0.0166c0.37793,0.03809,0.76953,0.05859,1.17188,0.05957c0.00781,0,0.01465,0,0.02344,0
      l0.02148-2.68457c-0.00684,0-0.01367-0.00098-0.02148-0.00098c-0.00879,0-0.01563-0.00098-0.02344-0.00098
      C500.90723,330.49707,500.51563,330.47461,500.1377,330.43555z"/>
    <path class="spa-i-7" d="M500.13574,333.125c-0.04785-0.00684-0.09473-0.01172-0.14258-0.0166
      c-0.34082-0.04004-0.67383-0.09375-0.99121-0.16211c-0.05469-0.00977-0.10742-0.02246-0.1582-0.03613
      c-0.32617-0.07422-0.63672-0.16211-0.93164-0.26855c-0.02148-0.00684-0.04492-0.01563-0.06641-0.02344
      c-0.31738-0.11621-0.61621-0.24902-0.89063-0.39551c-1.12012-0.57031-2.6416-0.91797-4.31934-0.91797
      c-1.69043,0-3.24609,0.34766-4.37598,0.92969c-1.12988,0.59277-2.68555,0.95117-4.38574,0.95117
      c-1.72461,0-3.26855-0.39355-4.39844-0.99609c-1.11816-0.5498-2.62012-0.88477-4.24023-0.88477
      c-1.7041,0-3.20215,0.32422-4.31934,0.89551c-1.14258,0.60449-2.70996,1.00684-4.42188,1.00684v2.68555
      c1.71191,0,3.2793-0.39258,4.42188-0.98438c1.11719-0.58203,2.61523-0.90625,4.31934-0.90625
      c0.38379,0,0.75879,0.02051,1.12598,0.05664c0.05273,0.00488,0.10254,0.01074,0.1543,0.01563
      c0.29883,0.03418,0.58984,0.08008,0.87305,0.13379c0.11035,0.02246,0.21777,0.04688,0.3252,0.07129
      c0.24219,0.05664,0.47461,0.11914,0.69922,0.18945c0.09375,0.0293,0.18555,0.05957,0.27441,0.09082
      c0.27832,0.09961,0.54395,0.20605,0.78809,0.32715c1.12988,0.59277,2.67383,1.00586,4.39844,1.00586
      c1.7002,0,3.25586-0.37891,4.38574-0.96289c1.12988-0.58203,2.68555-0.92773,4.37598-0.92773
      c1.67773,0,3.19922,0.3457,4.31934,0.91895c0.27832,0.14551,0.58105,0.2793,0.90234,0.39746
      c0.01367,0.00488,0.02734,0.00977,0.04199,0.01367c0.30469,0.11035,0.62988,0.20508,0.96875,0.28516
      c0.03516,0.00879,0.07129,0.0166,0.10742,0.02441c0.33105,0.07422,0.67773,0.13086,1.03613,0.17383
      c0.03711,0.00488,0.07422,0.01074,0.11133,0.01465c0.38281,0.04004,0.77734,0.06152,1.18457,0.0625c0.00781,0,0.01465,0,0.02344,0
      l0.02148-2.70703c-0.00684,0-0.01367-0.00098-0.02148-0.00098c-0.00879,0-0.01563,0-0.02344,0
      C500.90723,333.18262,500.51563,333.16406,500.13574,333.125z"/>
    <path class="spa-i-9" d="M500.12305,335.83008c-0.03711-0.00391-0.07422-0.00977-0.11133-0.01465
      c-0.3584-0.04297-0.70508-0.09961-1.03613-0.17383c-0.03613-0.00781-0.07227-0.01563-0.10742-0.02441
      c-0.33887-0.08008-0.66406-0.1748-0.96875-0.28516c-0.01465-0.00391-0.02832-0.00879-0.04199-0.01367
      c-0.32129-0.11816-0.62402-0.25195-0.90234-0.39746c-1.12012-0.57324-2.6416-0.91895-4.31934-0.91895
      c-1.69043,0-3.24609,0.3457-4.37598,0.92773c-1.12988,0.58398-2.68555,0.96289-4.38574,0.96289
      c-1.72461,0-3.26855-0.41309-4.39844-1.00586c-0.24414-0.12109-0.50977-0.22754-0.78809-0.32715
      c-0.08887-0.03125-0.18066-0.06152-0.27441-0.09082c-0.22461-0.07031-0.45703-0.13281-0.69922-0.18945
      c-0.10645-0.02441-0.21582-0.04883-0.3252-0.07129c-0.2832-0.05371-0.57422-0.09961-0.87305-0.13379
      c-0.05176-0.00488-0.10254-0.01074-0.1543-0.01563c-0.36719-0.03613-0.74219-0.05664-1.12598-0.05664
      c-1.7041,0-3.20215,0.32422-4.31934,0.90625c-1.14258,0.59375-2.70996,1.00781-4.42188,1.00781v2.6748
      c1.71191,0,3.2793-0.39355,4.42188-0.98438c1.11719-0.58203,2.61523-0.91797,4.31934-0.91797
      c1.63281,0,3.12207,0.33594,4.24023,0.89453c1.12988,0.59277,2.67383,1.00781,4.39844,1.00781
      c1.7002,0,3.25586-0.38086,4.38574-0.96289s2.68555-0.93945,4.37598-0.93945c1.67773,0,3.19922,0.35742,4.31934,0.93945
      c1.1416,0.59375,2.65234,0.96289,4.37598,0.96289l0.02148-2.69824c-0.00684,0-0.01367,0-0.02148,0c-0.00879,0-0.01563,0-0.02344,0
      C500.90039,335.89063,500.50586,335.87012,500.12305,335.83008z"/>
    <path class="spa-i-3" d="M496.76465,317.66309c-1.73438,0-3.14453-1.31055-3.14453-2.94141c0-0.23535,0.04492-0.45996,0.10156-0.69727
      c-0.14551,0.05664-0.32617,0.06934-0.49121,0.06934h-18.67969c-0.14355,0-0.3252-0.02246-0.45801-0.06934
      c0.05469,0.2373,0.14453,0.44922,0.14453,0.69727c0,1.63086-1.41992,2.94141-3.14453,2.94141h0.03418h25.5918H496.76465z"/>
    <path class="spa-i-3" d="M494.37012,313.20996c0-0.48145-0.50391-0.87305-1.13965-0.87305h-18.67969
      c-0.62598,0-1.14063,0.3916-1.14063,0.87305s0.51465,0.88379,1.14063,0.88379h18.67969
      C493.86621,314.09375,494.37012,313.69141,494.37012,313.20996z"/>
    <polygon class="spa-i-3" points="479.65918,324.1875 488.16895,324.1875 496.71875,324.1875 496.71875,324.02344 496.71875,317.66309 
      471.12695,317.66309 471.12695,324.01953 471.12695,324.1875 	"/>
    <path class="spa-i-7" d="M474.79688,252.37695v7.48926c0.00879-0.00098,0.01465-0.00293,0.02246-0.00391l0.03223,8.7627
      c-0.01855-0.01172-0.03613-0.02148-0.05469-0.0332v0.00391c2.55664,1.5166,5.68066,3.12305,9.13086,4.65137
      c3.25781,1.43262,5.875,2.61816,9.05371,3.74805v-21.38379c-2.7627-1.00586-6.01172-1.86133-9.5332-2.45117
      C480.39648,252.65918,477.4541,252.4043,474.79688,252.37695z"/>
    <path class="spa-i-7" d="M485.50684,242.85156c2.65527,0.44336,5.17383,1.02344,7.47461,1.69922v-22.38916h-18.18457v19.84033
      c0.00488,0,0.00879,0,0.01172,0C478.01953,241.96973,481.71387,242.21387,485.50684,242.85156z"/>
    <path class="spa-i-7" d="M474.79688,278.56836v32.70508h18.18457v-24.66992c-2.84668-1.00195-8.80957-3.20117-11.63672-4.56152
      C478.95898,280.90137,476.74512,279.71973,474.79688,278.56836z"/>
    <polygon class="spa-i-3" points="471.47363,208.55371 471.47363,215.08838 474.55078,215.08838 493.24023,215.08838 
      496.41699,215.08838 496.41699,208.55371 487.64258,208.55371 480.12109,208.55371 	"/>
    <path class="spa-i-3" d="M474.55078,216.57715c-0.19043,0-0.36914-0.01123-0.52539-0.06689l0.10059,0.02197
      c0.6709,0.20068,1.05176,0.79492,1.05176,1.44336c0,0.61572-0.41211,1.24121-1.05371,1.45313
      c0.01758-0.00586,0.04004-0.00879,0.05957-0.01465c0.11328-0.03516,0.2168-0.05078,0.36719-0.05078h18.67969
      c0.08398,0,0.16602,0.0083,0.24707,0.02197c0.04102,0.00684,0.0791,0.01807,0.11719,0.02734
      c0.03418,0.00928,0.06738,0.0166,0.09961,0.02783c0.0166,0.00488,0.0332,0.00732,0.04883,0.01221
      c-0.65039-0.21191-1.10742-0.79395-1.10742-1.47705c0-0.68262,0.50391-1.2749,1.15234-1.48779
      c-0.00977,0.00244-0.02441,0.00439-0.03516,0.00732c-0.1543,0.05127-0.32715,0.08203-0.5127,0.08203H474.55078z"/>
    <path class="spa-i-3" d="M493.47754,219.38525c-0.08105-0.01367-0.16309-0.02197-0.24707-0.02197h-18.67969
      c-0.12891,0-0.25098,0.02051-0.36719,0.05078c-0.01953,0.00586-0.04199,0.00879-0.05957,0.01465
      c-0.41699,0.13037-0.71387,0.44287-0.71387,0.80664c0,0.49316,0.51465,0.88477,1.14063,0.88477h18.67969
      c0.63574,0,1.13965-0.3916,1.13965-0.88477c0-0.354-0.27637-0.65918-0.67578-0.79492
      c-0.03223-0.01123-0.06543-0.01855-0.09961-0.02783C493.55664,219.40332,493.51855,219.39209,493.47754,219.38525z"/>
    <path class="spa-i-3" d="M473.41016,215.82715c0,0.41455,0.51465,0.75,1.14063,0.75h18.68945c0.18555,0,0.3584-0.03076,0.5127-0.08203
      c0.37891-0.12256,0.63867-0.37451,0.63867-0.66797c0-0.40283-0.51465-0.73877-1.15137-0.73877h-18.68945
      C473.9248,215.08838,473.41016,215.42432,473.41016,215.82715z"/>
    <path class="spa-i-0" d="M502.71289,292.77637l-0.02734,0.1543C502.70313,292.88281,502.71094,292.83203,502.71289,292.77637z"/>
    <path class="spa-i-0" d="M502.70313,292.66016c-0.01563-0.08789-0.04395-0.17871-0.09277-0.27734
      c-0.00195-0.00391-0.00391-0.00879-0.00586-0.0127c-0.26074-0.52441-0.97852-1.2041-1.99219-1.93066
      c-0.01563-0.0127-0.03418-0.02539-0.0498-0.03613c-0.17773-0.12793-0.36719-0.25684-0.56152-0.38477
      c-0.08008-0.05273-0.16699-0.10645-0.24902-0.16113c-0.08301-0.05273-0.16992-0.10645-0.25488-0.16016
      c-0.25293-0.15625-0.51367-0.31348-0.78418-0.46973c-1.6123,1.20801-2.61914,3.10059-2.61914,5.19238
      c0,2.01465,0.9502,3.81641,2.45117,5.02539c-0.86328-1.04102,0.23535-3.29004,2.04785-4.55566
      c1.15234-0.76074,1.95703-1.27539,2.09277-1.95898c0.02539-0.07031,0.0293-0.15039,0.02148-0.2334
      C502.70508,292.68457,502.70508,292.67188,502.70313,292.66016z"/>
    <polygon class="spa-i-0" points="498.48828,250.96484 496.10449,252.49902 498.20801,253.65234 	"/>
    <path class="spa-i-0" d="M474.73047,252.37598c0.02148,0,0.04492,0.00098,0.06641,0.00098
      c2.65723,0.02734,5.59961,0.28223,8.65137,0.7832c3.52148,0.58984,6.77051,1.44531,9.5332,2.45117
      c1.70996,0.62305,3.23535,1.30273,4.52246,2.01465c0.00293-0.00098,0.00684-0.00098,0.00977-0.00098
      c0.63574,0.35156,1.20898,0.71289,1.72168,1.07813c0.02148,0.01563,0.04199,0.0293,0.06348,0.04492
      c0.12695,0.0918,0.25098,0.18457,0.37012,0.27734c0.04297,0.03125,0.08398,0.06445,0.125,0.09668
      c0.09473,0.0752,0.1875,0.15137,0.27539,0.22559c0.05371,0.04688,0.10645,0.0918,0.16113,0.13867
      c0.06934,0.0625,0.13867,0.125,0.20605,0.1875c0.05957,0.05664,0.11816,0.1123,0.1748,0.16895
      c0.05371,0.05273,0.10449,0.10547,0.1543,0.15918c0.06348,0.0625,0.12207,0.12695,0.17871,0.19141
      c0.04102,0.04492,0.0791,0.0918,0.11816,0.13867c0.05664,0.06836,0.11426,0.13672,0.16602,0.20508
      c0.0332,0.04297,0.0625,0.08594,0.0918,0.12695c0.05078,0.07227,0.10156,0.14258,0.14746,0.21484
      c0.02734,0.04004,0.04883,0.08008,0.07324,0.12109c0.04297,0.07227,0.08496,0.14453,0.12109,0.2168
      c0.02148,0.04004,0.03809,0.08008,0.05762,0.12012c0.03418,0.07129,0.06641,0.14258,0.09375,0.21387
      c0.0166,0.04297,0.02832,0.08496,0.04199,0.12695c0.02344,0.06836,0.0459,0.1377,0.06348,0.20508
      c0.0127,0.04785,0.01758,0.0957,0.02734,0.14258c0.01172,0.0625,0.02539,0.125,0.03223,0.1875
      c0.00684,0.05664,0.00586,0.11523,0.00781,0.17383c0.00293,0.05078,0.00879,0.10156,0.00684,0.15137
      c-0.00391,0.10938-0.01563,0.21777-0.03613,0.32422c-0.01074,0.06738-0.04395,0.22363-0.05566,0.29004
      c0,0,4.07324-9.1875,4.13965-9.53418c0.56641-3.06348-4.89648-6.67188-13.05957-9.06738
      c-2.30078-0.67578-4.81934-1.25586-7.47461-1.69922c-3.79297-0.6377-7.4873-0.88184-10.69824-0.84961
      c-0.00293,0-0.00684,0-0.01172,0c-7.45117,0.00195-13.95508,0.93164-18.04004,2.34375v10.95508
      C460.76855,253.76172,467.28613,252.62305,474.73047,252.37598z M499.5498,249.20898
      c0.13672,0.10156,0.26953,0.18945,0.41602,0.26758c0.15527,0.0791,0.30273,0.14746,0.45898,0.21289l-0.67285,8.0918
      c-0.32227-0.20117-0.62598-0.40332-0.96094-0.58203c-0.32422-0.19043-0.67188-0.33496-1.00781-0.49121
      c0.14551-0.81836,0.28906-1.5791,0.34766-2.35059v-0.01172l-2.6748-1.46484c-0.71777,0.53613-1.34375,0.9502-1.94727,1.45313
      c-0.17969-0.09961-0.33691-0.21191-0.52734-0.3125c-0.17871-0.09961-0.37988-0.19043-0.56934-0.28027L499.5498,249.20898z
      M486.91699,245.1582l2.73145,0.5918c0.61523,0.14746,1.55371,0.32617,2.04785,0.67188
      c0.64941,0.4707,0.92871,1.01855,0.76074,1.71289c-0.24609,1.00586-1.20996,1.45508-2.46289,1.41016
      c0.46973,1.12988,1.04199,2.25977,1.57813,3.37891l0.34668,0.75c-0.38086-0.11133-0.76074-0.22266-1.16113-0.3125
      c-0.38281-0.07813-0.77344-0.13477-1.17676-0.20117l-1.00781-2.48535c-0.12305-0.32422-0.36914-0.87207-0.54785-1.33105
      l-0.09082-0.01172l-0.80469,3.28906c-0.31348-0.08789-0.63672-0.18945-0.9502-0.25586
      c-0.32715-0.06738-0.66113-0.1123-0.9873-0.16797L486.91699,245.1582z M478.05371,244.01563l6.79395,0.56055
      c-0.0459,0.14453-0.10156,0.29102-0.1123,0.43555c-0.01172,0.15625,0.01074,0.31445,0.02148,0.45898
      c-0.77246-0.13281-1.54297-0.2793-2.35059-0.3457l-0.5918,6.45703c-0.3252-0.0459-0.64941-0.10156-0.98438-0.12305
      c-0.3252-0.03516-0.66113-0.03516-0.98535-0.03516l0.59277-6.45605c-0.94043-0.07813-1.7793-0.07813-2.47266-0.05566
      c0.04395-0.14453,0.09961-0.29102,0.1123-0.44727C478.08691,244.31934,478.06445,244.17188,478.05371,244.01563z
      M472.54688,243.91602c0.32617,0.02148,0.66016,0.06641,0.99609,0.06641c0.32422,0.01172,0.66211-0.02148,0.98535-0.0332
      l-0.11133,6.41211c1.15332,0.02344,2.12695-0.0332,3.0332-0.08789c-0.03418,0.14355-0.0791,0.30078-0.0791,0.44629
      s0.03223,0.31348,0.06641,0.45801l-5.02441-0.08789L472.54688,243.91602z M464.11035,244.78906
      c0.92871,1.63281,2.09277,3.19922,3.16797,4.81152c0.60449-1.84668,1.17383-3.69238,1.58887-5.5293
      c0.19922-0.01074,0.41211-0.02148,0.60352-0.05566c0.2002-0.02148,0.40234-0.06641,0.59375-0.12305
      c-0.87305,2.56445-1.75781,5.08203-2.45215,7.68945c-0.17871,0.01074-0.36816,0.01074-0.54883,0.0332
      c-0.2002,0.02344-0.37988,0.08887-0.55859,0.13477c-1.51172-2.26172-3.07813-4.44336-4.7002-6.61426
      c0.3916-0.0459,0.7832-0.06738,1.16504-0.12402C463.34863,244.95703,463.73047,244.86719,464.11035,244.78906z"/>
    <path class="spa-i-0" d="M490.41895,247.86621c0.03516-0.14453,0.05762-0.30176,0.06836-0.53809
      c0-0.89453-0.98633-1.01758-1.7793-1.19727l-0.67188,2.75391C489.36719,249.27539,490.16211,248.91797,490.41895,247.86621z"/>
    <path class="spa-i-0" d="M501.99316,262.53809c0.00195-0.0498-0.00391-0.10059-0.00684-0.15137
      c-0.00195-0.05859-0.00098-0.11719-0.00781-0.17383c-0.00684-0.0625-0.02051-0.125-0.03223-0.1875
      c-0.00977-0.04688-0.01465-0.09473-0.02734-0.14258c-0.01758-0.06738-0.04004-0.13672-0.06348-0.20508
      c-0.01367-0.04199-0.02539-0.08398-0.04199-0.12695c-0.02832-0.07031-0.05957-0.14258-0.09375-0.21387
      c-0.01953-0.04004-0.03613-0.08008-0.05762-0.12012c-0.03613-0.07227-0.07813-0.14453-0.12109-0.2168
      c-0.02441-0.04102-0.0459-0.08105-0.07324-0.12109c-0.0459-0.07227-0.09668-0.14258-0.14746-0.21484
      c-0.0293-0.04102-0.05859-0.08398-0.0918-0.12695c-0.05176-0.06836-0.10938-0.13672-0.16602-0.20508
      c-0.03906-0.04688-0.07715-0.09375-0.11816-0.13867c-0.05664-0.06445-0.11523-0.12891-0.17871-0.19141
      c-0.0498-0.05371-0.10059-0.10645-0.1543-0.15918c-0.05664-0.05664-0.11523-0.1123-0.1748-0.16895
      c-0.06738-0.0625-0.13672-0.125-0.20605-0.1875c-0.05371-0.04688-0.10742-0.0918-0.16113-0.13867
      c-0.08789-0.07422-0.18066-0.15039-0.27539-0.22559c-0.04102-0.03223-0.08203-0.06543-0.125-0.09668
      c-0.11914-0.09277-0.24316-0.18555-0.37012-0.27734c-0.02148-0.01563-0.04199-0.0293-0.06348-0.04492
      c-0.5127-0.36523-1.08594-0.72656-1.72168-1.07813c-0.00293,0-0.00684,0-0.00977,0.00098
      c-1.35352,0.15625-2.88086,0.33398-4.46582,0.33398l0.03418,8.71777c5.04688-0.35742,8.48145-1.71289,8.88477-3.81543
      C501.97754,262.75586,501.98926,262.64746,501.99316,262.53809z"/>
    <path class="spa-i-0" d="M467.99316,262.36035c-0.01367,0.04199-0.03027,0.08496-0.03711,0.12891
      c-0.00391,0.02246-0.00293,0.0459-0.00488,0.06934c-0.00293,0.06152-0.00293,0.125,0.00781,0.19238
      c0.00195,0.0127,0.00391,0.02539,0.00586,0.03809c0.23535,1.27539,2.83008,3.42773,6.83203,5.80273
      c0.01855,0.01172,0.03613,0.02148,0.05469,0.0332l-0.03223-8.7627c-0.00781,0.00098-0.01367,0.00293-0.02246,0.00391
      c-3.14453,0.4834-5.49805,1.2627-6.66992,2.22363l-0.05371,0.09375l-0.05664,0.10742
      C468.00488,262.3125,468.00098,262.33789,467.99316,262.36035z"/>
    <path class="spa-i-0" d="M474.79688,268.5957c-4.0127-2.38086-6.61035-4.53613-6.83203-5.80664
      c-0.00195-0.0127-0.00391-0.02539-0.00586-0.03809c-0.00879-0.06738-0.01172-0.13086-0.00781-0.19238
      c0.00195-0.02344,0.00098-0.04688,0.00488-0.06934c0.00684-0.04395,0.02246-0.08691,0.03711-0.12891
      c0.00781-0.02246,0.01172-0.04785,0.02344-0.06934l0.05664-0.10742l0.04297-0.08398
      c-0.51367,0.42578-1.32129,9.22266-1.32129,9.22266c-0.63867,1.19531,2.6875,4.10547,8.00195,7.24609
      c1.94824,1.15137,4.16211,2.33301,6.54785,3.47363c2.82715,1.36035,8.79004,3.55957,11.63672,4.56152
      c0.01465,0.00586,0.03125,0.01172,0.04688,0.01563c2.34766,0.8125,4.67773,1.95898,6.46875,3.07813
      c0.08496,0.05371,0.17188,0.10742,0.25488,0.16016c0.08203,0.05469,0.16895,0.10742,0.24902,0.16113
      c0.19434,0.12793,0.38379,0.25684,0.56152,0.38477c0.01563,0.01074,0.03418,0.02344,0.0498,0.03613
      c1.01367,0.72656,1.73145,1.40625,1.99219,1.93066c0.00195,0.00391,0.00391,0.00879,0.00586,0.0127
      c0.04883,0.09863,0.07715,0.18945,0.09277,0.27734c0.00195,0.01172,0.00195,0.02441,0.00391,0.03711
      c0.00781,0.08301,0.00391,0.16309-0.02148,0.2334l0.02734-0.1543l1.66113-8.89844v-0.01074
      c0.48242-1.43359-4.44141-4.31836-11.39258-6.87207c-3.17871-1.12988-5.7959-2.31543-9.05371-3.74805
      C480.47754,271.71875,477.35352,270.1123,474.79688,268.5957z"/>
    <path class="spa-i-3" d="M306.85742,254.46973c0.3125-0.1123,0.63867-0.20215,0.95117-0.29199l-1.20898-2.95313v-0.01172
      c2.22705-0.80664,3.68213-1.76855,2.97656-3.51465c-0.45898-1.11914-1.59912-1.33105-3.32422-0.70508l-3.03125,1.1084
      l2.72998,6.74805C306.25195,254.71484,306.55518,254.58008,306.85742,254.46973z M306.05078,247.67578
      c0.63818-0.23535,1.35596,0.1123,1.6792,0.89453c0.23633,0.59473,0.1792,1.26563-0.56006,1.73535
      c-0.2334,0.14551-0.51367,0.25781-0.78223,0.36914l-1.1084-2.71777L306.05078,247.67578z"/>
    <path class="spa-i-3" d="M318.24951,251.55957c-0.05664-0.13574-0.13477-0.29199-0.1582-0.42578
      c-0.03223-0.15723-0.03223-0.31445-0.04248-0.45898c-0.86279,0.24707-1.80273,0.51465-2.92139,0.73828l-1.41016-6.27734h-0.00146
      c-0.32275,0.08887-0.63672,0.17871-0.96143,0.24512c-0.32422,0.05664-0.65918,0.10156-0.98486,0.13574l1.57813,7.02734
      L318.24951,251.55957z"/>
    <path class="spa-i-3" d="M322.98389,251.16602c0.17871-0.01074,0.36865,0.01172,0.55908,0.02344
      c0.91699-2.55078,2.03662-4.99121,3.14404-7.46387c-0.20215,0.03418-0.40283,0.06641-0.60449,0.07813
      c-0.20068,0.01074-0.41406,0.01074-0.604,0c-0.59375,1.80176-1.32129,3.59277-2.09229,5.37207
      c-0.91846-1.68945-1.93652-3.34668-2.71875-5.04688c-0.38086,0.04297-0.77295,0.10059-1.15381,0.13379
      c-0.37988,0.02246-0.7832,0.01172-1.16357,0.02246c1.40967,2.30469,2.77588,4.59961,4.07422,6.9707
      C322.60352,251.22461,322.78174,251.18945,322.98389,251.16602z"/>
    <path class="spa-i-3" d="M334.22949,249.54492c0.25732-2.40625-3.771-2.54004-3.61377-3.96191
      c0.06592-0.59277,0.52441-0.98535,1.56641-0.88379c0.75,0.07813,1.20801,0.48047,1.40967,1.0625l0.20215,0.02344
      c0.16748-0.34766,0.34521-0.6709,0.53711-0.99609c-0.26904-0.24805-1.07471-0.61719-2.02588-0.70605
      c-2.00391-0.2002-3.14404,0.68457-3.27881,1.8916c-0.29053,2.53125,3.70313,2.31738,3.51318,3.99609
      c-0.07861,0.71484-0.83936,1.00586-1.65527,0.92773c-0.90625-0.08984-1.57861-0.59277-1.69092-1.33203l-0.24512-0.02148
      c-0.13525,0.43652-0.3252,0.86133-0.53711,1.28711c0.59131,0.38086,1.35303,0.5918,2.06934,0.66016
      C332.5293,251.7041,334.08496,250.88672,334.22949,249.54492z"/>
    <path class="spa-i-3" d="M466.50488,251.75c0.17871-0.0459,0.3584-0.11133,0.55859-0.13477
      c0.18066-0.02246,0.37012-0.02246,0.54883-0.0332c0.69434-2.60742,1.5791-5.125,2.45215-7.68945
      c-0.19141,0.05664-0.39355,0.10156-0.59375,0.12305c-0.19141,0.03418-0.4043,0.04492-0.60352,0.05566
      c-0.41504,1.83691-0.98438,3.68262-1.58887,5.5293c-1.0752-1.6123-2.23926-3.17871-3.16797-4.81152
      c-0.37988,0.07813-0.76172,0.16797-1.14063,0.22266c-0.38184,0.05664-0.77344,0.07813-1.16504,0.12402
      C463.42676,247.30664,464.99316,249.48828,466.50488,251.75z"/>
    <path class="spa-i-3" d="M477.4375,251.17773c-0.03418-0.14453-0.06641-0.3125-0.06641-0.45801s0.04492-0.30273,0.0791-0.44629
      c-0.90625,0.05469-1.87988,0.11133-3.0332,0.08789l0.11133-6.41211c-0.32324,0.01172-0.66113,0.04492-0.98535,0.0332
      c-0.33594,0-0.66992-0.04492-0.99609-0.06641l-0.13379,7.17383L477.4375,251.17773z"/>
    <path class="spa-i-3" d="M480.4375,244.96777l-0.59277,6.45605c0.32422,0,0.66016,0,0.98535,0.03516
      c0.33496,0.02148,0.65918,0.07715,0.98438,0.12305l0.5918-6.45703c0.80762,0.06641,1.57813,0.21289,2.35059,0.3457
      c-0.01074-0.14453-0.0332-0.30273-0.02148-0.45898c0.01074-0.14453,0.06641-0.29102,0.1123-0.43555l-6.79395-0.56055
      c0.01074,0.15625,0.0332,0.30371,0.02344,0.44922c-0.0127,0.15625-0.06836,0.30273-0.1123,0.44727
      C478.6582,244.88965,479.49707,244.88965,480.4375,244.96777z"/>
    <path class="spa-i-3" d="M487.12988,252.62109l0.80469-3.28906l0.09082,0.01172c0.17871,0.45898,0.4248,1.00684,0.54785,1.33105
      l1.00781,2.48535c0.40332,0.06641,0.79395,0.12305,1.17676,0.20117c0.40039,0.08984,0.78027,0.20117,1.16113,0.3125l-0.34668-0.75
      c-0.53613-1.11914-1.1084-2.24902-1.57813-3.37891c1.25293,0.04492,2.2168-0.4043,2.46289-1.41016
      c0.16797-0.69434-0.11133-1.24219-0.76074-1.71289c-0.49414-0.3457-1.43262-0.52441-2.04785-0.67188l-2.73145-0.5918
      l-1.72461,7.03906c0.32617,0.05566,0.66016,0.10059,0.9873,0.16797C486.49316,252.43164,486.81641,252.5332,487.12988,252.62109z
      M488.70801,246.13086c0.79297,0.17969,1.7793,0.30273,1.7793,1.19727c-0.01074,0.23633-0.0332,0.39355-0.06836,0.53809
      c-0.25684,1.05176-1.05176,1.40918-2.38281,1.01855L488.70801,246.13086z"/>
    <path class="spa-i-3" d="M492.98145,254.02148c0.19043,0.10059,0.34766,0.21289,0.52734,0.3125
      c0.60352-0.50293,1.22949-0.91699,1.94727-1.45313l2.6748,1.46484v0.01172c-0.05859,0.77148-0.20215,1.53223-0.34766,2.35059
      c0.33594,0.15625,0.68359,0.30078,1.00781,0.49121c0.33496,0.17871,0.63867,0.38086,0.96094,0.58203l0.67285-8.0918
      c-0.15625-0.06543-0.30371-0.13379-0.45898-0.21289c-0.14648-0.07813-0.2793-0.16602-0.41602-0.26758l-7.1377,4.53223
      C492.60156,253.83105,492.80273,253.92188,492.98145,254.02148z M498.48828,250.96484l-0.28027,2.6875l-2.10352-1.15332
      L498.48828,250.96484z"/>
    <path class="spa-i-0" d="M398.2334,175.9707c-0.04248,0.06348-0.0791,0.13135-0.11523,0.20117
      C398.1543,176.10303,398.19141,176.03516,398.2334,175.9707z"/>
    <path class="spa-i-0" d="M349.42627,179.06592l0.25781-1.19775c0,0,1.52148,0.30176,2.21631,2.03711
      c0.69287,1.73389-0.06738,4.25244-0.06738,4.25244s0.37988-1.07422,0.95068-1.80225c0.57178-0.71631,2.02588-1.5,2.78662-1.85791
      c0.74902-0.34619,1.53369-0.88379,1.53369-0.88379s0.03369,0.20117,0.05469,0.68311c0.03467,0.57031-0.00977,0.9292-0.00977,0.9292
      c1.38721-0.19141,3.00977,0.04395,4.28613,0.53613c-0.54932,1.06348-1.57813,2.06006-2.93213,2.57471
      c0,0,0.49268,0.40234,0.92871,0.83887c0.38086,0.38037,0.49219,0.54785,0.49219,0.54785s-0.9502,0.14502-1.4209,0.21289
      c-0.48145,0.05615-2.06006,0.31348-3.01123,0.24561c-0.5083-0.03174-1.06348-0.10156-1.50977-0.1709
      c0.66943,0.65283,1.60889,1.51025,2.79688,2.44336c2.56299,2.0249,4.49902,1.90283,4.49902,1.90283
      c1.69043-0.28027,2.41699-1.82422,2.41699-3.55957v-0.03564c-0.48682-0.15918-0.94678-0.63281-1.17432-1.28467
      c-0.31445-0.9292-0.03467-1.84717,0.63721-2.04736c0.67236-0.2124,1.47754,0.36865,1.79102,1.29736
      c0.22607,0.67188,0.13916,1.3335-0.17773,1.73145l0.02051,0.02637c0.93994,1.33105,2.55078,2.2041,4.36572,2.2041
      c2.89746,0,5.24707-2.21631,5.24707-4.96924c0-0.79395-0.14111-1.54492-0.48877-2.21338
      c-0.25-0.09375-0.47412-0.22949-0.67529-0.39209c-0.27197,0.26416-0.84229,0.79443-1.4873,1.22949
      c-0.68262,0.45947-2.01465,0.9292-2.40625,1.07471c-0.40332,0.14551-1.19727,0.43604-1.19727,0.43604s0-0.20166,0.06689-0.68262
      c0.09033-0.58252,0.22363-0.96191,0.22363-0.96191c-1.45508,0.14502-2.92041-0.3252-4.02832-1.08545
      c0.76025-1.08594,1.96875-2.01514,3.39014-2.33838c0,0-0.25732-0.32471-0.56006-0.83936
      c-0.23389-0.42627-0.3125-0.61719-0.3125-0.61719s0.86133,0.0127,1.28711,0.03418c0.42432,0,1.84619,0.01123,2.65234,0.22412
      c0.76172,0.20068,1.47705,0.50732,1.8252,0.66699c0.0083-0.01514,0.01709-0.03076,0.02588-0.04639
      c-0.25977-0.25146-0.73486-0.74414-1.1582-1.35889c-0.61475-0.90674-1.04004-1.99268-1.26416-2.32813
      c-0.23486-0.33545-0.80518-1.2876-0.80518-1.2876s0.52588-0.20068,1.2749-0.05566c0.75,0.13428,0.98584,0.3584,0.98584,0.3584
      c-0.15527-1.41943,0.30566-2.9043,1.14111-3.98682l-0.3584-0.28857l0.01367-0.02295
      c-0.15137-0.11572-0.30273-0.23193-0.44873-0.35205c-0.21973,0.18994-0.50879,0.30859-0.83008,0.30859
      c-0.6792,0-1.23145-0.52197-1.23145-1.16406c0-0.31641,0.13477-0.60254,0.35205-0.8125
      c-0.29785-0.34766-0.57373-0.70703-0.82422-1.07617c-0.12256,0.03955-0.25244,0.06494-0.38818,0.06494
      c-0.65918,0-1.19189-0.49023-1.22461-1.10547c-1.14551,0.02588-2.24756,0.00586-3.27441-0.06982
      c-3.05518-0.06738-7.46387,0.82764-11.88428,2.77588c-2.02979,0.90234-3.86279,1.91309-5.44873,2.95947
      c0.48926,0.76514,1.09717,1.45459,1.79932,2.0415l-2.94189,4.81299l-0.06934-0.05566l0.0332,0.30322
      c0.15918-0.02197,0.31738-0.04639,0.4834-0.04639C347.75977,177.82324,348.7998,178.28223,349.42627,179.06592z"/>
    <path class="spa-i-0" d="M381.98047,188.91357c-2.36035,0-4.36426-0.95215-5.80762-2.51807
      c-1.11914,2.59521-3.93896,4.31982-7.07324,4.31982c-1.46484,0-2.81934-0.40234-3.97266-1.08545
      c-0.51221,1.271-1.42236,2.39063-2.67627,2.86719l0.01514,0.04248c5.61816-1.61768,12.47705-2.87988,20.14209-3.66113
      C382.39941,188.89453,382.19189,188.91357,381.98047,188.91357z"/>
    <path class="spa-i-0" d="M398.3833,175.77295c0.01221-0.01465,0.0249-0.02832,0.03662-0.04346l-0.00195-0.00195
      C398.40527,175.74121,398.396,175.7583,398.3833,175.77295z"/>
    <path class="spa-i-0" d="M397.86182,177.18506c0,0.146,0.01611,0.28857,0.04492,0.42676
      C397.87939,177.47266,397.86182,177.33105,397.86182,177.18506z"/>
    <path class="spa-i-0" d="M452.80957,170.96729c-1.65234-1.11719-3.5957-2.19971-5.75488-3.15918
      c-4.41992-1.94824-8.82813-2.84326-11.88281-2.77588c-0.89063,0.06543-1.84766,0.0835-2.8291,0.07373
      c-0.0332,0.61279-0.56738,1.10156-1.22363,1.10156c-0.13184,0-0.25879-0.0249-0.37988-0.06152
      c-0.24902,0.36816-0.52344,0.72607-0.82227,1.07275c0.21777,0.20996,0.35156,0.49609,0.35156,0.8125
      c0,0.64209-0.55078,1.16406-1.23145,1.16406c-0.32031,0-0.60938-0.11865-0.82715-0.30859
      c-0.14648,0.11914-0.29785,0.23584-0.4502,0.35205l0.01563,0.02295l-1.55664,1.24219l-2.32813-3.80518
      c0.92578-0.56396,1.74121-1.28223,2.40625-2.11475c-3.14941-0.50146-6.00586-1.25488-8.19043-2.24805
      c-3.26953-1.2915-7.87402-2.23926-13.14941-2.62256c0.24023,2.45166,0.6709,4.56006,1.21875,6.02441l-3.83691,3.67822
      c0.04688,0.23096,0.08301,0.46289,0.09863,0.69727c0,0,0.21289-0.30225,1.01953-0.59326
      c0.81543-0.29102,1.43164-0.1792,1.43164-0.1792s-0.4375,1.17432-0.61621,1.58838
      c-0.19043,0.41455-0.43457,1.71289-0.94043,2.83252c-0.39355,0.89551-0.90332,1.61035-1.09277,1.86328
      c0.28418-0.2041,1.09961-0.76416,2.01074-1.17041c0.85059-0.3916,2.42871-0.66064,2.90918-0.74902
      c0.4707-0.08984,1.41113-0.26904,1.41113-0.26904s-0.02344,0.22412-0.21191,0.75c-0.22461,0.62598-0.44824,1.04053-0.44824,1.04053
      c1.64453,0.08887,3.20117,0.90674,4.2627,1.96875c-1.06152,1.05273-2.60742,1.85791-4.2627,1.9585
      c0,0,0.22363,0.40381,0.44824,1.04199c0.17773,0.51416,0.21191,0.74951,0.21191,0.74951l-1.41113-0.26953
      c-0.48047-0.08887-2.05859-0.35742-2.90918-0.74902c-1.09375-0.49902-2.07617-1.19189-2.1377-1.23438
      c-0.13281,0.14551-0.2793,0.27979-0.44629,0.39014c-0.15234,0.5-0.20313,1.03857-0.20313,1.60498
      c0,2.87646,2.47461,5.20361,5.50586,5.20361c2.44043,0,4.52344-1.51025,5.22656-3.5918
      c0.00879,0.00537,0.02246-0.0127,0.03613-0.04346c-0.41406-0.32324-0.65332-0.98438-0.56152-1.70313
      c0.12305-0.97217,0.79395-1.68896,1.49902-1.61035c0.70508,0.07813,1.16309,0.92822,1.04199,1.90234
      c-0.09375,0.74951-0.51563,1.3418-1.02637,1.54053c0.00586,0.02441,0.01074,0.04004,0.01855,0.03711
      c0.34766,2.61816,2.75488,4.39795,5.44922,4.39795c3.03223,0,5.49512-2.32813,5.49512-5.2041
      c0-0.21191-0.01074-0.42529-0.04492-0.63672l0.00781-0.00732c-0.12988-0.12793-0.24121-0.27246-0.33691-0.42529
      c-0.36719,0.16406-1.05664,0.45264-1.78418,0.64404c-0.7959,0.21338-2.22754,0.22461-2.65332,0.22461l-1.27734,0.0332
      c0,0,0.06836-0.18994,0.31348-0.62695c0.29297-0.51563,0.54883-0.8291,0.54883-0.8291
      c-1.40918-0.32373-2.62891-1.24072-3.39063-2.32666c1.1084-0.77246,2.58691-1.25342,4.02832-1.09717
      c0,0-0.12207-0.39063-0.21191-0.97266c-0.0791-0.48145-0.06934-0.68213-0.06934-0.68213s0.79688,0.30127,1.18848,0.44678
      c0.40332,0.14502,1.73535,0.60449,2.41797,1.06348c0.59961,0.4126,1.12988,0.90137,1.41699,1.18164
      c0.01758-0.01465,0.03613-0.02881,0.05371-0.04248c-0.15625-0.33057-0.4082-0.91943-0.57715-1.59814
      c-0.24512-1.05322-0.24512-2.21582-0.34473-2.59717c-0.10059-0.39111-0.29199-1.47656-0.29199-1.47656
      s0.57227-0.01123,1.21973,0.3584c0.66016,0.38037,0.79395,0.66064,0.79395,0.66064
      c0.37012-1.38818,1.39746-2.64111,2.58594-3.39111c0.83887,1.08545,1.31934,2.58447,1.15332,4.01709
      c0,0,0.23438-0.22412,0.9834-0.3584c0.75098-0.14502,1.27637,0.05566,1.27637,0.05566s-0.57129,0.95215-0.80566,1.2876
      c-0.22363,0.33545-0.64844,1.42139-1.25391,2.32813c-0.42285,0.61377-0.89648,1.10596-1.15625,1.3584
      c0.00977,0.01514,0.01758,0.03125,0.02637,0.0459c0.34766-0.15918,1.06348-0.46533,1.82422-0.66602
      c0.80664-0.21289,2.22754-0.22412,2.63867-0.22412c0.43848-0.02148,1.28906-0.03418,1.28906-0.03418
      s-0.06543,0.19092-0.3125,0.61719c-0.29199,0.51465-0.5498,0.83936-0.5498,0.83936c1.41016,0.32324,2.61914,1.25244,3.3916,2.33838
      c-1.10938,0.76025-2.58496,1.23047-4.02832,1.08545c0,0,0.12109,0.37939,0.20996,0.96191
      c0.06836,0.48096,0.06836,0.68262,0.06836,0.68262s-0.79395-0.29053-1.18555-0.43604
      c-0.40137-0.14551-1.73242-0.61523-2.41602-1.07471c-0.63477-0.43311-1.20605-0.95996-1.48242-1.22559
      c-0.20313,0.16357-0.42969,0.29883-0.68262,0.39209c-0.34668,0.66748-0.48926,1.41748-0.48926,2.20947
      c0,2.75293,2.35059,4.96924,5.24902,4.96924c1.82422,0,3.4248-0.87305,4.36523-2.2041l0.02051-0.02637
      c-0.31738-0.39844-0.4043-1.05957-0.17773-1.73145c0.3252-0.92871,1.13184-1.50977,1.80273-1.29736
      c0.6709,0.2002,0.94922,1.11816,0.63672,2.04736c-0.22852,0.65381-0.69434,1.12842-1.18555,1.28613v0.03418
      c0,1.73535,0.73926,3.2793,2.41797,3.55957c0,0,1.93555,0.11182,4.50781-1.90283c1.18848-0.93359,2.12695-1.79102,2.79297-2.44385
      c-0.44238,0.06934-0.99707,0.13965-1.51465,0.17139c-0.94141,0.06787-2.51953-0.18945-3.00098-0.24561
      c-0.46973-0.06787-1.4209-0.21289-1.4209-0.21289s0.11133-0.16748,0.48242-0.54785
      c0.44531-0.43652,0.92773-0.83887,0.92773-0.83887c-1.35449-0.51465-2.38281-1.51123-2.9209-2.57471
      c1.26465-0.49219,2.88672-0.72754,4.28516-0.53613c0,0-0.04395-0.35889-0.02051-0.9292
      c0.0332-0.48193,0.06543-0.68311,0.06543-0.68311s0.77148,0.5376,1.53516,0.88379c0.75879,0.35791,2.21484,1.1416,2.78613,1.85791
      c0.57129,0.72803,0.93945,1.80225,0.93945,1.80225s-0.75-2.51855-0.05566-4.25244c0.69238-1.73535,2.21484-2.03711,2.21484-2.03711
      l0.25879,1.19775c0.55762-0.71143,1.46777-1.15088,2.45996-1.22754l0.08301-0.73975l-2.62207-4.28955
      C451.79102,172.2749,452.3457,171.65088,452.80957,170.96729z"/>
    <path class="spa-i-0" d="M397.90771,176.74609c-0.02881,0.14355-0.0459,0.28906-0.0459,0.43896
      C397.86182,177.03467,397.87939,176.88916,397.90771,176.74609z"/>
    <path class="spa-i-0" d="M398.03711,176.33496c-0.03662,0.08594-0.06201,0.17578-0.08789,0.26563
      C397.9751,176.51074,398.00098,176.4209,398.03711,176.33496z"/>
    <path class="spa-i-0" d="M398.45361,178.6792v0.00537c0,0-1.01807,0.72852-2.13721,1.24268
      c-0.86133,0.3916-2.43994,0.66016-2.90967,0.74902c-0.48047,0.09033-1.43213,0.26953-1.43213,0.26953
      s0.04443-0.23535,0.22363-0.74951c0.22314-0.63818,0.44775-1.04199,0.44775-1.04199
      c-1.64551-0.10059-3.18994-0.90576-4.26465-1.9585c1.07471-1.06201,2.61914-1.87988,4.26465-1.96875
      c0,0-0.22461-0.41455-0.44775-1.04053c-0.1792-0.52588-0.22363-0.75-0.22363-0.75s0.95166,0.1792,1.43213,0.26904
      c0.46973,0.08838,2.04834,0.35742,2.90967,0.74902c0.9585,0.43164,1.82178,1.04004,2.05225,1.20752
      c-0.16455-0.21436-0.70947-0.95898-1.12354-1.90039c-0.50244-1.11963-0.74951-2.41797-0.94043-2.83252
      c-0.19043-0.41406-0.62695-1.58838-0.62695-1.58838s0.62695-0.11182,1.43311,0.1792
      c0.80566,0.29102,1.03027,0.59326,1.03027,0.59326c0.01123-0.16504,0.03516-0.3291,0.06104-0.49316l-4.10254-3.88232
      c0.55957-1.47412,0.99414-3.60107,1.23877-6.0752c-5.5,0.34326-10.15723,1.31982-13.59229,2.67334
      c-2.09326,0.95068-4.80176,1.68018-7.79346,2.18213c0.67432,0.86035,1.50977,1.60107,2.45557,2.18066l-2.31836,3.79053
      c0.65479,0.68555,1.16895,1.52441,1.40137,2.42188c0,0,0.14355-0.28027,0.79297-0.66064
      c0.66016-0.36963,1.21973-0.3584,1.21973-0.3584s-0.18994,1.08545-0.28027,1.47656
      c-0.09961,0.38135-0.09961,1.54395-0.35693,2.59717c-0.16748,0.67773-0.41406,1.26514-0.56689,1.5957
      c0.01904,0.01416,0.03711,0.02979,0.05615,0.04395c0.2876-0.27979,0.81689-0.76807,1.41602-1.18066
      c0.67236-0.45898,2.01611-0.91846,2.40723-1.06348c0.40234-0.14551,1.19775-0.44678,1.19775-0.44678s0,0.20068-0.06738,0.68213
      c-0.09082,0.58203-0.22314,0.97266-0.22314,0.97266c1.45459-0.15625,2.93066,0.32471,4.03857,1.09717
      c-0.77051,1.08594-1.97998,2.00293-3.40186,2.32666c0,0,0.2583,0.31348,0.56006,0.8291
      c0.23438,0.43701,0.31396,0.62695,0.31396,0.62695s-0.86133-0.02246-1.2876-0.0332c-0.42578,0-1.84668-0.01123-2.65088-0.22461
      c-0.7251-0.18994-1.40869-0.47559-1.77686-0.64063c-0.09814,0.15479-0.20898,0.2998-0.34082,0.42773l0.00098,0.00146
      c-0.02197,0.21143-0.04492,0.4248-0.04492,0.63672c0,2.87598,2.4624,5.2041,5.49512,5.2041
      c2.70801,0,5.10254-1.77979,5.46094-4.39795c0.00537,0.00293,0.01025-0.0127,0.01514-0.03516
      c-0.50537-0.19873-0.93018-0.79199-1.0332-1.54248c-0.12256-0.97412,0.34766-1.82422,1.04053-1.90234
      c0.70557-0.07861,1.37598,0.63818,1.49902,1.61035c0.09277,0.72021-0.14014,1.38281-0.55908,1.70459
      c0.01465,0.0293,0.02637,0.04736,0.03418,0.04199c0.71533,2.08154,2.78564,3.5918,5.23633,3.5918
      c3.03223,0,5.49561-2.32715,5.49561-5.20361c0-0.54736-0.03125-1.06836-0.17969-1.55518
      C398.79395,179.00928,398.6123,178.85498,398.45361,178.6792z"/>
    <path class="spa-i-0" d="M352.98975,184.9917c0.00342,0.00342,0.00684,0.00732,0.01025,0.01025
      c0.0083,0.00977,0.01904,0.01758,0.02734,0.02734C353.01563,185.01611,353.00195,185.00391,352.98975,184.9917z"/>
    <path class="spa-i-0" d="M435.5293,189.62988c-1.15332,0.68311-2.51855,1.08545-3.97168,1.08545
      c-3.14746,0-5.9668-1.72461-7.07617-4.31982c-1.44141,1.56592-3.45703,2.51807-5.81641,2.51807
      c-0.27246,0-0.54199-0.01904-0.80957-0.04492c7.41016,0.74854,14.08594,1.94141,19.60059,3.47266l0.06348-0.18652
      C436.61621,191.58203,435.93555,190.65723,435.5293,189.62988z"/>
    <path class="spa-i-0" d="M400.32227,184.72754c-1.22754,1.79834-3.28418,3.06885-5.64502,3.35254
      c1.83691-0.05811,3.70117-0.09131,5.58936-0.09521c1.96875,0.00391,3.90918,0.03955,5.82227,0.10303
      C403.67676,187.83301,401.57129,186.55566,400.32227,184.72754z"/>
    <path class="spa-i-0" d="M412.43164,186.04834c-1.37305,1.25391-3.20703,2.02979-5.24512,2.08252
      c3.61426,0.14063,7.11328,0.38232,10.46484,0.7168C415.54688,188.58838,413.66699,187.54492,412.43164,186.04834z"/>
    <path class="spa-i-0" d="M352.74023,184.74951c0.01855,0.01611,0.03271,0.03418,0.05127,0.05078v-0.00049
      C352.77344,184.78369,352.75879,184.76563,352.74023,184.74951z"/>
    <path class="spa-i-0" d="M383.12646,188.8252c3.29443-0.3252,6.73438-0.55908,10.28467-0.69629
      c-2.01025-0.06396-3.83545-0.8374-5.18701-2.08057C387.01563,187.51367,385.17822,188.53906,383.12646,188.8252z"/>
    <path class="spa-i-3" d="M371.28906,154.7041c-0.63037,1.14355-1.01611,2.37939-1.11426,3.68066
      c-0.03369,0.34619-0.05664,0.68262-0.05664,1.02979c0,3.08838,1.41016,5.88525,3.64795,7.82227l-1.12988,2.06982
      c-0.02979-0.02197-0.05713-0.04541-0.08691-0.06787l-0.01367,0.02295l0.3584,0.28857
      c0.00732-0.01025,0.01416-0.02051,0.02148-0.03027c0.41992,0.26514,0.81689,0.59521,1.17432,0.96924l2.31836-3.79053
      c-0.9458-0.57959-1.78125-1.32031-2.45557-2.18066c-1.12012-1.43213-1.79736-3.19629-1.79736-5.10352
      c0-0.34717,0.021-0.68359,0.06836-1.01855c0.23389-4.85693,6.15381-8.86328,13.65137-8.86328
      c3.87061,0,7.37402,1.05176,9.84375,2.74561c0.06494-0.72314,0.15234-1.40967,0.25244-2.06885
      c-2.71533-1.57813-6.25-2.53418-10.09619-2.53418c-6.43555,0-11.82471,2.73438-14.28174,6.52979
      c-0.02588,0.04004-0.05225,0.07959-0.07715,0.12012C371.4375,154.4502,371.36182,154.57715,371.28906,154.7041z"/>
    <path class="spa-i-3" d="M369.48877,155.71387c-0.02051,0.03223-0.04297,0.06494-0.06348,0.09717
      c-0.00781-0.00146-0.01563-0.00195-0.02246-0.00342c-0.28174,0.43799-0.52734,0.89014-0.73633,1.354
      c0.45117,0.17334,0.76953,0.58984,0.76953,1.07764c0,0.64258-0.55029,1.16357-1.22998,1.16357
      c-0.07666,0-0.15137-0.0083-0.22461-0.021c-0.0708,0.41699-0.10742,0.8418-0.11621,1.27197
      c0.06641-0.01074,0.13477-0.01953,0.20508-0.01953c0.68018,0,1.23096,0.521,1.23096,1.16357
      c0,0.62451-0.52197,1.13086-1.17676,1.15869c0.09424,0.38232,0.21387,0.75928,0.35742,1.12842
      c0.19824-0.12939,0.43799-0.20508,0.69678-0.20508c0.67969,0,1.23047,0.521,1.23047,1.16309
      c0,0.51367-0.35449,0.94531-0.84229,1.09961c0.25049,0.36914,0.52637,0.72852,0.82422,1.07617
      c0.22412-0.21631,0.53564-0.35156,0.87939-0.35156c0.68066,0,1.23193,0.521,1.23193,1.16406
      c0,0.33887-0.15674,0.64209-0.40186,0.85547c0.146,0.12012,0.29736,0.23633,0.44873,0.35205
      c0.02979,0.02246,0.05713,0.0459,0.08691,0.06787l1.12988-2.06982c-2.23779-1.93701-3.64795-4.73389-3.64795-7.82227
      c0-0.34717,0.02295-0.68359,0.05664-1.02979c0.09814-1.30127,0.48389-2.53711,1.11426-3.68066
      c0.07129-0.12793,0.1499-0.25342,0.22705-0.37891c0.0249-0.04053,0.05127-0.08008,0.07715-0.12012
      c0.64453-1.021,1.48145-1.96289,2.50977-2.79297c-1.5459,0.9248-2.87109,2.04297-3.90137,3.29834
      C370.18066,155.15723,369.896,155.53711,369.48877,155.71387z"/>
    <path class="spa-i-3" d="M370.20605,154.67529c0.13525-0.17822,0.27539-0.35303,0.42822-0.521
      c-0.20361-0.04639-0.41699-0.08105-0.62354-0.12354c0.12354,0.18115,0.19678,0.396,0.19678,0.62744
      C370.20752,154.66406,370.20605,154.66895,370.20605,154.67529z"/>
    <path class="spa-i-3" d="M349.146,172.80908c-0.70215-0.58691-1.31006-1.27637-1.79932-2.0415
      c-0.83447-1.30566-1.32129-2.83398-1.32129-4.47021c0-1.27686,0.29053-2.49658,0.81641-3.58203
      c1.97021-4.27539,8.78564-7.44189,16.92041-7.44189c1.56201,0,3.07471,0.12256,4.51855,0.34326
      c-0.32178-0.20996-0.53418-0.56104-0.53418-0.9585c0-0.3833,0.19922-0.72021,0.50195-0.93262
      c-1.44336-0.2041-2.94141-0.3208-4.48633-0.3208c-6.63623,0-12.4917,1.99316-16.02393,5.04395
      c-0.02246,0.02002-0.04639,0.03955-0.06885,0.05908c-0.55566,0.48584-1.0498,0.99951-1.48193,1.53564
      c-0.04541,0.05518-0.0874,0.11182-0.13086,0.16846c-0.16309,0.20947-0.31445,0.42383-0.45752,0.64014
      c-0.04248,0.06592-0.08691,0.13135-0.12891,0.19678c-0.16895,0.27148-0.32666,0.54736-0.46387,0.82764
      c-0.64844,1.34277-1.01758,2.84229-1.01758,4.4209c0,2.60742,1.00732,5.00146,2.6626,6.84863l-1.79102,2.89844
      c-0.00635-0.00732-0.01074-0.01514-0.01611-0.02197l-0.21777,0.34668l1.5083,1.19727l0.06934,0.05566L349.146,172.80908z"/>
    <path class="spa-i-3" d="M369.42529,155.81104c0.02051-0.03223,0.04297-0.06494,0.06348-0.09717
      c-0.06885,0.03027-0.1416,0.05371-0.21729,0.07129c0.04297,0.0083,0.08789,0.01367,0.13135,0.02246
      C369.40967,155.80908,369.41748,155.80957,369.42529,155.81104z"/>
    <path class="spa-i-3" d="M346.65137,173.146c-1.65527-1.84717-2.6626-4.24121-2.6626-6.84863c0-1.57861,0.36914-3.07813,1.01758-4.4209
      c0.13672-0.28125,0.29492-0.55566,0.46387-0.82764c0.04199-0.06543,0.08643-0.13086,0.12891-0.19678
      c0.14258-0.21631,0.29541-0.43018,0.45752-0.64014c0.04346-0.05664,0.08545-0.11328,0.13086-0.16846
      c0.43164-0.53613,0.92725-1.04883,1.48193-1.53564c0.02246-0.01953,0.04639-0.03906,0.06885-0.05908
      c0.5835-0.50488,1.22217-0.98438,1.92383-1.4292c-4.52197,2.71924-7.51025,6.82666-7.51025,11.52637
      c0,2.72119,0.99121,5.28711,2.69238,7.47656c0.00537,0.00684,0.00977,0.01465,0.01611,0.02197L346.65137,173.146z"/>
    <path class="spa-i-3" d="M395.57764,154.93799c0,0.20117,0.01074,0.39111,0.01074,0.58252c0,0.22266-0.04443,0.45752-0.04443,0.68213
      c-0.02393,1.2085-0.09814,2.36523-0.20557,3.45996c-0.24463,2.47412-0.6792,4.60107-1.23877,6.0752l4.10254,3.88232
      c0.09131-0.57568,0.27148-1.13672,0.51953-1.66602c-0.26025-0.22852-0.45361-0.5166-0.56689-0.84277l-1.97217-1.86621
      c0.75-2.01465,1.28613-5.29297,1.35254-9.00928c0-0.22363,0.0459-0.43604,0.0459-0.65967c0-0.18018-0.01123-0.3584-0.01123-0.5376
      c0.03418-1.76855,0.18994-3.43506,0.44727-4.90137c0.20166-0.90625,1.14111-1.55566,2.16064-1.55566
      c1.01758,0,1.87891,0.64941,2.09277,1.55566c0.25781,1.46631,0.41211,3.13281,0.44727,4.90137
      c0,0.1792-0.01172,0.35742-0.01172,0.5376c0,0.22363,0.03418,0.43604,0.04395,0.65967
      c0.05762,3.71631,0.59375,6.99463,1.35547,9.00928l-1.87598,1.77441c-0.08789,0.30078-0.24512,0.57129-0.45898,0.79736
      c0.26074,0.50586,0.45605,1.04541,0.56836,1.59912l3.83691-3.67822c-0.54785-1.46436-0.97852-3.57275-1.21875-6.02441
      c-0.10938-1.11035-0.18262-2.2832-0.20313-3.51074c-0.01074-0.22461-0.05566-0.45947-0.05566-0.68213
      c0-0.19141,0.02246-0.38135,0.01172-0.58252c-0.0166-0.91357-0.06641-1.79834-0.1416-2.64697
      c-0.00879,0.00635-0.01855,0.01123-0.02734,0.01758c-0.06641-0.75-0.13281-1.39893-0.24512-2.08203
      c0.00684-0.00391,0.0127-0.00684,0.01953-0.01074c-0.02246-0.14844-0.04102-0.30371-0.06543-0.44824
      c-0.1123-0.49219-0.33691-0.94238-0.63086-1.34375c-0.15039-0.11572-0.27539-0.26074-0.35547-0.43066
      c-0.5332-0.5459-1.23242-0.93799-2.01465-1.13428c-0.3418,0.07178-0.69629,0.11084-1.06152,0.11084
      c-0.36914,0-0.72852-0.04102-1.0752-0.11426c-0.80615,0.19727-1.53516,0.59766-2.08789,1.15625
      c-0.08008,0.16016-0.19678,0.29834-0.34131,0.40869c-0.30322,0.40234-0.53369,0.85352-0.64648,1.34717
      c-0.02441,0.14258-0.04346,0.2959-0.06543,0.44189c-0.1001,0.65918-0.1875,1.3457-0.25244,2.06885
      C395.64307,153.13086,395.59424,154.02002,395.57764,154.93799z"/>
    <path class="spa-i-3" d="M399.82617,164.50635c-1.0127-0.1582-1.78662-0.98145-1.78662-1.98047
      c0-1.11279,0.95654-2.01416,2.13818-2.01416c1.18066,0,2.13672,0.90137,2.13672,2.01416c0,0.99902-0.77441,1.82227-1.78613,1.98047
      c1.01172,0.15869,1.78613,0.98193,1.78613,1.98096c0,0.18604-0.03516,0.36182-0.08496,0.53223l1.87598-1.77441
      c-0.76172-2.01465-1.29785-5.29297-1.35547-9.00928c-0.00977-0.22363-0.04395-0.43604-0.04395-0.65967
      c0-0.18018,0.01172-0.3584,0.01172-0.5376c-0.03516-1.76855-0.18945-3.43506-0.44727-4.90137
      c-0.21387-0.90625-1.0752-1.55566-2.09277-1.55566c-1.01953,0-1.95898,0.64941-2.16064,1.55566
      c-0.25732,1.46631-0.41309,3.13281-0.44727,4.90137c0,0.1792,0.01123,0.35742,0.01123,0.5376
      c0,0.22363-0.0459,0.43604-0.0459,0.65967c-0.06641,3.71631-0.60254,6.99463-1.35254,9.00928l1.97217,1.86621
      c-0.06885-0.19727-0.11523-0.40527-0.11523-0.62402C398.03955,165.48828,398.81348,164.66504,399.82617,164.50635z
      M400.17773,150.15967c0.54297,0,0.98438,0.41553,0.98438,0.92969c0,0.51221-0.44141,0.92725-0.98438,0.92725
      c-0.54395,0-0.98438-0.41504-0.98438-0.92725C399.19336,150.5752,399.63379,150.15967,400.17773,150.15967z M400.17773,153.28223
      c0.67871,0,1.23047,0.52051,1.23047,1.16406c0,0.64258-0.55176,1.1626-1.23047,1.1626c-0.67969,0-1.23242-0.52002-1.23242-1.1626
      C398.94531,153.80273,399.49805,153.28223,400.17773,153.28223z M398.47607,158.27344c0-0.89111,0.76221-1.6123,1.70166-1.6123
      c0.93848,0,1.7002,0.72119,1.7002,1.6123c0,0.88965-0.76172,1.61084-1.7002,1.61084
      C399.23828,159.88428,398.47607,159.16309,398.47607,158.27344z"/>
    <path class="spa-i-3" d="M404.54102,152.30859c0.00879-0.00635,0.01855-0.01123,0.02734-0.01758
      c2.47266-1.70117,5.97363-2.7583,9.86621-2.7583c7.49805,0,13.41699,4.00635,13.65234,8.86328
      c0.04395,0.33496,0.06738,0.67139,0.06738,1.01855c0,1.93848-0.7002,3.72607-1.85742,5.16943
      c-0.66504,0.83252-1.48047,1.55078-2.40625,2.11475l2.32813,3.80518l1.55664-1.24219l-0.01563-0.02295
      c-0.03027,0.02246-0.05664,0.04541-0.08691,0.06787l-1.1416-2.06982c2.23828-1.93701,3.6582-4.73389,3.6582-7.82227
      c0-0.34717-0.02148-0.68359-0.05469-1.02979c-0.09766-1.30273-0.48633-2.54102-1.11719-3.68457
      c-0.06836-0.12256-0.1416-0.24463-0.2168-0.36572c-0.03125-0.04688-0.06152-0.09326-0.09277-0.14014
      c-2.45898-3.78955-7.84375-6.51904-14.27344-6.51904c-3.86328,0-7.3916,0.9585-10.11914,2.54053
      c-0.00684,0.00391-0.0127,0.00684-0.01953,0.01074C404.4082,150.90967,404.47461,151.55859,404.54102,152.30859z"/>
    <path class="spa-i-3" d="M429.01758,154.7002c0.63086,1.14355,1.01953,2.38184,1.11719,3.68457
      c0.0332,0.34619,0.05469,0.68262,0.05469,1.02979c0,3.08838-1.41992,5.88525-3.6582,7.82227l1.1416,2.06982
      c0.03027-0.02246,0.05664-0.04541,0.08691-0.06787c0.15234-0.11621,0.30371-0.23291,0.4502-0.35205
      c-0.24609-0.21338-0.40332-0.5166-0.40332-0.85547c0-0.64307,0.55078-1.16406,1.23047-1.16406
      c0.34766,0,0.65723,0.13525,0.87988,0.35156c0.29883-0.34668,0.57324-0.70459,0.82227-1.07275
      c-0.49219-0.15186-0.85059-0.58545-0.85059-1.10303c0-0.64209,0.55078-1.16309,1.23047-1.16309
      c0.2627,0,0.50586,0.08008,0.70508,0.2124c0.14551-0.37207,0.26563-0.75098,0.36133-1.13574
      c-0.65625-0.02637-1.17773-0.5332-1.17773-1.15869c0-0.64258,0.55078-1.16357,1.23145-1.16357
      c0.06836,0,0.1377,0.00879,0.20605,0.01953c-0.00977-0.43018-0.04688-0.85498-0.11816-1.27197
      c-0.07129,0.0127-0.14551,0.021-0.22266,0.021c-0.67871,0-1.23047-0.521-1.23047-1.16357
      c0-0.48779,0.31738-0.90381,0.76855-1.07715c-0.20898-0.46436-0.45508-0.91602-0.73438-1.35449
      c-0.00977,0.00146-0.01563,0.00195-0.02441,0.00342c-0.02051-0.03174-0.04102-0.06396-0.0625-0.09619
      c-0.4082-0.17725-0.69434-0.55859-0.71582-1.00635c-1.0293-1.25488-2.35449-2.37207-3.89941-3.29639
      c1.02539,0.82666,1.85938,1.76563,2.50195,2.78223c0.03125,0.04688,0.06152,0.09326,0.09277,0.14014
      C428.87402,154.45654,428.9502,154.57666,429.01758,154.7002z"/>
    <path class="spa-i-3" d="M432.56348,154.6582c0,0.39697-0.21094,0.74707-0.5332,0.95801
      c1.43945-0.22119,2.95508-0.34277,4.51758-0.34277c8.13574,0,14.93945,3.1665,16.90918,7.44189
      c0.52441,1.08545,0.81543,2.30518,0.81543,3.58203c0,1.72217-0.54492,3.3208-1.46289,4.66992
      c-0.46387,0.68359-1.01855,1.30762-1.6582,1.8418l2.62207,4.28955l0.32031,0.52344l1.59082-1.25293l-0.21875-0.34863
      c-0.00684,0.00781-0.01074,0.0166-0.0166,0.02393l-1.79102-2.89844c1.64453-1.84717,2.66309-4.24121,2.66309-6.84863
      c0-1.57861-0.37012-3.07813-1.01855-4.4209c-0.14063-0.28662-0.30176-0.56787-0.47461-0.84521
      c-0.03125-0.04883-0.06543-0.09766-0.09668-0.14648c-0.16016-0.24512-0.33203-0.48682-0.51855-0.72314
      c-0.02148-0.02979-0.04395-0.05957-0.06641-0.08838c-3.16797-3.94922-9.8457-6.66846-17.59863-6.66846
      c-1.5459,0-3.04102,0.1167-4.48633,0.32031C432.36523,153.9375,432.56348,154.2749,432.56348,154.6582z"/>
    <path class="spa-i-3" d="M430.29883,154.0293c-0.20996,0.04346-0.42676,0.07813-0.63281,0.125
      c0.15723,0.16846,0.2998,0.34375,0.43652,0.52246c0-0.00635-0.00195-0.01221-0.00195-0.01855
      C430.10059,154.42627,430.1748,154.21143,430.29883,154.0293z"/>
    <path class="spa-i-3" d="M430.82129,155.71484c0.02148,0.03223,0.04199,0.06445,0.0625,0.09619
      c0.00879-0.00146,0.01465-0.00195,0.02441-0.00342c0.04297-0.00879,0.08691-0.01416,0.12988-0.02246
      C430.96289,155.76758,430.88965,155.74463,430.82129,155.71484z"/>
    <path class="spa-i-3" d="M454.73145,160.88477c0.03125,0.04883,0.06543,0.09766,0.09668,0.14648
      c0.17188,0.27734,0.33594,0.55811,0.47461,0.84521c0.64844,1.34277,1.01855,2.84229,1.01855,4.4209
      c0,2.60742-1.01855,5.00146-2.66309,6.84863l1.79102,2.89844c0.00586-0.00732,0.00977-0.01611,0.0166-0.02393
      c1.7002-2.18848,2.67969-4.75439,2.67969-7.47461c0-4.69971-2.98828-8.80713-7.49707-11.52637
      c1.41406,0.896,2.5957,1.92627,3.49805,3.05371c0.02246,0.02881,0.04492,0.05859,0.06641,0.08838
      C454.39941,160.39795,454.57129,160.63916,454.73145,160.88477z"/>
    <path class="spa-i-9" d="M398.89063,141.60938v-3.87109c-2.10645,0.54102-3.65967,2.36182-3.65967,4.53174
      c0-0.24609,0.21289-0.4248,0.24609-0.66064H398.89063z"/>
    <path class="spa-i-9" d="M395.62256,144.104c-0.00928-0.02148-0.01611-0.04297-0.02441-0.06348
      C395.60645,144.06104,395.61377,144.08252,395.62256,144.104z"/>
    <path class="spa-i-9" d="M401.61035,141.60938h3.31055c0.02734,0.18164,0.1582,0.33154,0.2168,0.50293
      c-0.07227-2.05664-1.53418-3.77783-3.52734-4.34326v0.00244V141.60938z"/>
    <path class="spa-i-9" d="M401.24902,146.85889c1.56934-0.32422,2.86133-1.35254,3.48828-2.7207h-9.08154
      c-0.01025-0.01025-0.03174-0.01123-0.03223-0.03223c0.61865,1.38037,1.91309,2.41846,3.48877,2.74951
      c0.34668,0.07324,0.70605,0.11426,1.0752,0.11426C400.55273,146.96973,400.90723,146.93066,401.24902,146.85889z"/>
    <path class="spa-i-3" d="M404.7373,144.13818h0.00586c0-0.01025,0.02344-0.01025,0.03418-0.03271
      c0.24609-0.55908,0.39063-1.18652,0.39063-1.83545c0-0.05566-0.0127-0.10742-0.03027-0.15771
      c-0.05859-0.17139-0.18945-0.32129-0.2168-0.50293h-3.31055v-3.83789v-0.00244v-3.55566h2.70703v-2.5752h-2.70703v-2.52832
      h-2.71973v2.52832h-2.70801v2.5752h2.70801v3.52441v0.00049v3.87109h-3.41357c-0.0332,0.23584-0.24609,0.41455-0.24609,0.66064
      c0,0.62451,0.12744,1.22656,0.36719,1.77051c0.0083,0.02051,0.01514,0.04199,0.02441,0.06348l0.00049,0.00146
      c0,0.00049,0.00049,0.00049,0.00049,0.00049c0.00049,0.021,0.02197,0.02197,0.03223,0.03223H404.7373z"/>
    <path class="spa-i-6" d="M400.25391,165.83789c0.60254,0.54492,1.12988,1.22607,1.5166,1.979
      c0.21387-0.22607,0.37109-0.49658,0.45898-0.79736c0.0498-0.17041,0.08496-0.34619,0.08496-0.53223
      c0-0.99902-0.77441-1.82227-1.78613-1.98096c-0.11426,0.01807-0.23145,0.03369-0.35059,0.03369
      c-0.12207,0-0.2373-0.01563-0.35156-0.03369c-1.0127,0.15869-1.78662,0.98193-1.78662,1.98096
      c0,0.21875,0.04639,0.42676,0.11523,0.62402c0.11328,0.32617,0.30664,0.61426,0.56689,0.84277
      C399.09863,167.14697,399.62988,166.41504,400.25391,165.83789z"/>
    <path class="spa-i-6" d="M402.31445,162.52588c0-1.11279-0.95605-2.01416-2.13672-2.01416c-1.18164,0-2.13818,0.90137-2.13818,2.01416
      c0,0.99902,0.77393,1.82227,1.78662,1.98047c0.11426,0.01807,0.22949,0.03369,0.35156,0.03369
      c0.11914,0,0.23633-0.01563,0.35059-0.03369C401.54004,164.34814,402.31445,163.5249,402.31445,162.52588z"/>
    <ellipse class="spa-i-6" cx="400.177" cy="158.27344" rx="1.70093" ry="1.6123"/>
    <ellipse class="spa-i-6" cx="400.17773" cy="154.44556" rx="1.23047" ry="1.16333"/>
    <ellipse class="spa-i-6" cx="400.17773" cy="151.08813" rx="0.98438" ry="0.92847"/>
    <ellipse class="spa-i-6" cx="342.99194" cy="177.37549" rx="1.2312" ry="1.16357"/>
    <ellipse class="spa-i-6" cx="341.31299" cy="174.41089" rx="1.23047" ry="1.16431"/>
    <ellipse class="spa-i-6" cx="340.19434" cy="170.96338" rx="1.23096" ry="1.16357"/>
    <ellipse class="spa-i-6" cx="340.0498" cy="167.271" rx="1.23047" ry="1.16357"/>
    <ellipse class="spa-i-6" cx="340.95557" cy="163.64478" rx="1.23193" ry="1.16333"/>
    <ellipse class="spa-i-6" cx="342.84766" cy="160.34302" rx="1.22998" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="345.36475" cy="157.62427" rx="1.23096" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="348.29639" cy="155.37427" rx="1.23096" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="351.78857" cy="153.52808" rx="1.23145" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="355.41406" cy="152.21899" rx="1.23193" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="359.46533" cy="151.49097" rx="1.23047" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="363.31445" cy="151.28979" rx="1.23145" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="367.08643" cy="151.42456" rx="1.23096" ry="1.16382"/>
    <path class="spa-i-6" d="M372.50244,168.03125c0-0.64307-0.55127-1.16406-1.23193-1.16406c-0.34375,0-0.65527,0.13525-0.87939,0.35156
      c-0.21729,0.20996-0.35205,0.49609-0.35205,0.8125c0,0.64209,0.55225,1.16406,1.23145,1.16406
      c0.32129,0,0.61035-0.11865,0.83008-0.30859C372.3457,168.67334,372.50244,168.37012,372.50244,168.03125z"/>
    <path class="spa-i-6" d="M370.40918,165.04297c0-0.64209-0.55078-1.16309-1.23047-1.16309c-0.25879,0-0.49854,0.07568-0.69678,0.20508
      c-0.32275,0.20996-0.53418,0.56055-0.53418,0.95801c0,0.021,0.00537,0.03906,0.00635,0.05908
      c0.03271,0.61523,0.56543,1.10547,1.22461,1.10547c0.13574,0,0.26563-0.02539,0.38818-0.06494
      C370.05469,165.98828,370.40918,165.55664,370.40918,165.04297z"/>
    <path class="spa-i-6" d="M366.83936,161.79785c0,0.64258,0.55176,1.16406,1.23096,1.16406c0.01855,0,0.03613-0.00391,0.0542-0.00537
      c0.65479-0.02783,1.17676-0.53418,1.17676-1.15869c0-0.64258-0.55078-1.16357-1.23096-1.16357
      c-0.07031,0-0.13867,0.00879-0.20508,0.01953C367.2832,160.74609,366.83936,161.22168,366.83936,161.79785z"/>
    <path class="spa-i-6" d="M366.97412,158.23926c0,0.57031,0.43408,1.04297,1.00732,1.14258c0.07324,0.0127,0.14795,0.021,0.22461,0.021
      c0.67969,0,1.22998-0.521,1.22998-1.16357c0-0.48779-0.31836-0.9043-0.76953-1.07764
      c-0.14209-0.05469-0.29736-0.08643-0.46045-0.08643C367.5249,157.0752,366.97412,157.59668,366.97412,158.23926z"/>
    <path class="spa-i-6" d="M367.74658,154.6582c0,0.39746,0.2124,0.74854,0.53418,0.9585
      c0.19824,0.12939,0.43799,0.20557,0.69678,0.20557c0.10156,0,0.19971-0.01465,0.29395-0.03711
      c0.07568-0.01758,0.14844-0.04102,0.21729-0.07129c0.40723-0.17676,0.69189-0.55664,0.71289-1.00342
      c0.00098-0.01123,0.00439-0.02344,0.00439-0.03516c0-0.00635,0.00146-0.01123,0.00146-0.01709
      c0-0.23145-0.07324-0.44629-0.19678-0.62744c-0.21777-0.32227-0.59863-0.53662-1.0332-0.53662
      c-0.27441,0-0.52539,0.08789-0.729,0.23145C367.9458,153.93799,367.74658,154.2749,367.74658,154.6582z"/>
    <ellipse class="spa-i-6" cx="370.85742" cy="151.42456" rx="1.23047" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="373.72217" cy="149.17456" rx="1.23047" ry="1.16333"/>
    <ellipse class="spa-i-6" cx="377.06885" cy="147.31763" rx="1.23047" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="380.77295" cy="146.20996" rx="1.22998" ry="1.16406"/>
    <ellipse class="spa-i-6" cx="384.61084" cy="145.54883" rx="1.23145" ry="1.16406"/>
    <ellipse class="spa-i-6" cx="388.38135" cy="145.60547" rx="1.23096" ry="1.16406"/>
    <ellipse class="spa-i-6" cx="392.2876" cy="146.26489" rx="1.23047" ry="1.16431"/>
    <path class="spa-i-6" d="M397.02441,148.01172c0.0752-0.15039,0.12012-0.31543,0.12012-0.49268
      c0-0.64307-0.55078-1.16406-1.23047-1.16406c-0.68066,0-1.23145,0.521-1.23145,1.16406c0,0.64209,0.55078,1.16309,1.23145,1.16309
      c0.29248,0,0.55762-0.10059,0.76904-0.26172C396.82764,148.31006,396.94434,148.17188,397.02441,148.01172z"/>
    <path class="spa-i-6" d="M403.61914,148.42383c0.21191,0.15967,0.47461,0.2583,0.76563,0.2583c0.68066,0,1.23145-0.521,1.23145-1.16309
      c0-0.64307-0.55078-1.16406-1.23145-1.16406c-0.67969,0-1.22949,0.521-1.22949,1.16406c0,0.16943,0.04004,0.32959,0.1084,0.47412
      C403.34375,148.16309,403.46875,148.30811,403.61914,148.42383z"/>
    <ellipse class="spa-i-6" cx="408.01172" cy="146.26489" rx="1.23145" ry="1.16431"/>
    <ellipse class="spa-i-6" cx="411.92773" cy="145.60547" rx="1.23047" ry="1.16406"/>
    <ellipse class="spa-i-6" cx="415.68848" cy="145.54883" rx="1.23047" ry="1.16406"/>
    <ellipse class="spa-i-6" cx="419.53711" cy="146.20996" rx="1.23145" ry="1.16406"/>
    <ellipse class="spa-i-6" cx="423.22949" cy="147.31763" rx="1.23145" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="426.58789" cy="149.17456" rx="1.23145" ry="1.16333"/>
    <ellipse class="spa-i-6" cx="429.44141" cy="151.42456" rx="1.23145" ry="1.16382"/>
    <path class="spa-i-6" d="M430.82129,155.71484c0.06836,0.02979,0.1416,0.05273,0.2168,0.07031
      c0.09375,0.02246,0.19238,0.03711,0.29395,0.03711c0.26074,0,0.5-0.07715,0.69824-0.20605
      c0.32227-0.21094,0.5332-0.56104,0.5332-0.95801c0-0.3833-0.19824-0.7207-0.50195-0.93311
      c-0.20313-0.14307-0.45605-0.23096-0.72949-0.23096c-0.43555,0-0.81445,0.21387-1.0332,0.53516
      c-0.12402,0.18213-0.19824,0.39697-0.19824,0.62891c0,0.00635,0.00195,0.01221,0.00195,0.01855
      c0,0.01025,0.00293,0.02051,0.00293,0.03174C430.12695,155.15625,430.41309,155.5376,430.82129,155.71484z"/>
    <path class="spa-i-6" d="M433.33594,158.23926c0-0.64258-0.55078-1.16406-1.23145-1.16406c-0.16309,0-0.31836,0.03174-0.46191,0.08691
      c-0.45117,0.17334-0.76855,0.58936-0.76855,1.07715c0,0.64258,0.55176,1.16357,1.23047,1.16357
      c0.07715,0,0.15137-0.0083,0.22266-0.021C432.90137,159.28271,433.33594,158.81006,433.33594,158.23926z"/>
    <path class="spa-i-6" d="M433.46973,161.79785c0-0.57617-0.44434-1.05127-1.02441-1.14404
      c-0.06836-0.01074-0.1377-0.01953-0.20605-0.01953c-0.68066,0-1.23145,0.521-1.23145,1.16357
      c0,0.62549,0.52148,1.13232,1.17773,1.15869c0.01758,0.00146,0.03418,0.00537,0.05371,0.00537
      C432.91797,162.96191,433.46973,162.44043,433.46973,161.79785z"/>
    <path class="spa-i-6" d="M432.34277,165.10596c0.00098-0.02148,0.00781-0.04102,0.00781-0.06299
      c0-0.39404-0.20801-0.73975-0.52637-0.95068c-0.19922-0.13232-0.44238-0.2124-0.70508-0.2124
      c-0.67969,0-1.23047,0.521-1.23047,1.16309c0,0.51758,0.3584,0.95117,0.85059,1.10303
      c0.12109,0.03662,0.24805,0.06152,0.37988,0.06152C431.77539,166.20752,432.30957,165.71875,432.34277,165.10596z"/>
    <path class="spa-i-6" d="M430.26855,168.03125c0-0.31641-0.13379-0.60254-0.35156-0.8125
      c-0.22266-0.21631-0.53223-0.35156-0.87988-0.35156c-0.67969,0-1.23047,0.521-1.23047,1.16406
      c0,0.33887,0.15723,0.64209,0.40332,0.85547c0.21777,0.18994,0.50684,0.30859,0.82715,0.30859
      C429.71777,169.19531,430.26855,168.67334,430.26855,168.03125z"/>
    <ellipse class="spa-i-6" cx="433.22363" cy="151.42456" rx="1.23047" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="436.99316" cy="151.28979" rx="1.2334" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="440.84375" cy="151.49097" rx="1.23242" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="444.89453" cy="152.21899" rx="1.23047" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="448.52148" cy="153.52808" rx="1.22949" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="452.0127" cy="155.37427" rx="1.23047" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="454.93457" cy="157.62427" rx="1.23145" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="457.45215" cy="160.34302" rx="1.23047" ry="1.16382"/>
    <ellipse class="spa-i-6" cx="459.34375" cy="163.64453" rx="1.23047" ry="1.16357"/>
    <ellipse class="spa-i-6" cx="460.25049" cy="167.27051" rx="1.23096" ry="1.16406"/>
    <ellipse class="spa-i-6" cx="460.11475" cy="170.96338" rx="1.23193" ry="1.16357"/>
    <ellipse class="spa-i-6" cx="458.99609" cy="174.41016" rx="1.23242" ry="1.16358"/>
    <ellipse class="spa-i-6" cx="457.31738" cy="177.37549" rx="1.23145" ry="1.16357"/>
    <path class="spa-i-3" d="M400.46777,207.43408h15.13672c7.4873-0.72217,14.14258-1.94971,19.48926-3.55859
      c0.625-0.20166,0.96094-0.78271,0.92773-1.39844c0.00879-0.51855-0.25977-0.98584-0.75-1.21924
      c-7.41406-2.24854-17.55664-3.80664-28.8252-4.25342c-2.01172-0.0752-4.05957-0.11914-6.14551-0.12305
      c-3.28174,0.00537-6.47949,0.10498-9.57031,0.28516c-0.17432,0.01025-0.35254,0.01855-0.52539,0.0293
      c-0.16016,0.01025-0.31689,0.02246-0.4751,0.0332c-9.54492,0.63672-18.00635,2.08838-24.48242,4.06836
      c-0.44092,0.24609-0.68945,0.68994-0.67871,1.17969c-0.03467,0.61572,0.30127,1.19678,0.93994,1.39844
      c5.34521,1.60889,12.00488,2.83643,19.49219,3.55859h15.44434H400.46777z M368.1709,202.71143
      c8.51709-2.20459,19.71924-3.54639,32.0957-3.56982c12.3877,0.02344,23.64648,1.36523,32.16016,3.56982
      c-8.51367,2.20508-19.77246,3.63721-32.16016,3.64795C387.89014,206.34863,376.68799,204.9165,368.1709,202.71143z"/>
    <path class="spa-i-3" d="M390.20508,197.1958c0.17285-0.01074,0.35107-0.01904,0.52539-0.0293
      c3.06494-0.18457,6.23193-0.28906,9.48047-0.29688c2.11133,0.00488,4.18945,0.05371,6.23535,0.13477
      c11.26855,0.44678,21.41113,2.00488,28.8252,4.25342c0.10059,0.0293,0.20313,0.05762,0.30273,0.08789
      c-0.61035-0.28613-0.66113-0.96826-0.1543-2.50293l-2.8457-2.07422l3.77148-0.48047l0.13184,0.17334
      c0.83887-1.23486,1.76074-1.38672,2.04688-1.40332c-9.7002-2.85889-23.23535-4.64453-38.25781-4.66797
      c-15.00732,0.02344-28.49609,1.80762-38.18408,4.66602c0.20459,0.00195,1.17236,0.08447,2.06299,1.37305l0.10889-0.14111
      l3.77197,0.48047l-2.81006,2.04834c0.5166,1.55273,0.47119,2.2417-0.13379,2.52881c0.05322-0.0166,0.11133-0.03174,0.16504-0.04834
      c6.47607-1.97998,14.9375-3.43164,24.48242-4.06836C389.88818,197.21826,390.04541,197.20605,390.20508,197.1958z
      M371.06885,195.96338c0-0.64893,0.54883-1.16357,1.23145-1.16357c0.68408,0,1.23193,0.51465,1.23193,1.16357
      c0,0.63867-0.54785,1.16406-1.23193,1.16406C371.61768,197.12744,371.06885,196.60205,371.06885,195.96338z M378.87012,193.73633
      l2.65234-0.31396l2.70801-0.31299c0.67236-0.07813,1.28613,0.37012,1.36475,1.00684
      c0.07861,0.63818-0.40186,1.22119-1.07422,1.29883l-2.69678,0.31348l-2.64258,0.30225
      c-0.68164,0.07813-1.30811-0.37012-1.38623-1.00684C377.70605,194.38574,378.18848,193.80469,378.87012,193.73633z
      M390.77734,194.96777c-0.68359,0-1.23145-0.52686-1.23145-1.16309c0-0.64941,0.54785-1.16504,1.23145-1.16504
      c0.6709,0,1.23096,0.51563,1.23096,1.16504C392.0083,194.44092,391.44824,194.96777,390.77734,194.96777z M408.59277,193.80469
      c0-0.64941,0.55957-1.16504,1.23047-1.16504c0.68262,0,1.23047,0.51563,1.23047,1.16504c0,0.63623-0.54785,1.16309-1.23047,1.16309
      C409.15234,194.96777,408.59277,194.44092,408.59277,193.80469z M415.00488,194.11621
      c0.07715-0.63672,0.68359-1.08496,1.36523-1.00684l2.70898,0.31299l2.65039,0.31396c0.67285,0.06836,1.1543,0.64941,1.07617,1.2876
      c-0.08008,0.63672-0.7168,1.08496-1.38965,1.00684l-2.63867-0.30225l-2.69727-0.31348
      C415.40918,195.3374,414.91504,194.75439,415.00488,194.11621z M427.06836,195.96338c0-0.64893,0.5498-1.16357,1.23047-1.16357
      c0.68359,0,1.23047,0.51465,1.23047,1.16357c0,0.63867-0.54688,1.16406-1.23047,1.16406
      C427.61816,197.12744,427.06836,196.60205,427.06836,195.96338z M396.60693,192.39355h7.43213
      c0.68164,0,1.23047,0.51465,1.23047,1.16309c0,0.63867-0.57129,1.15332-1.24316,1.15332h-3.72559h-3.68311
      c-0.67139,0-1.24121-0.51465-1.24121-1.15332C395.37646,192.9082,395.92529,192.39355,396.60693,192.39355z"/>
    <path class="spa-i-3" d="M400.2666,190.39014c15.02246,0.02344,28.55762,1.80908,38.25781,4.66797
      c0.0127,0.00391,0.02637,0.00635,0.03711,0.00977c0.65039,0.18994,1.33105-0.05518,1.5459-0.67139
      c0.21094-0.61523-0.13672-1.32031-0.78711-1.51074c-0.60547-0.18506-1.22852-0.36768-1.86426-0.54443
      c-5.51465-1.53125-12.19043-2.72412-19.60059-3.47266c-0.06836-0.00684-0.13672-0.01318-0.2041-0.021
      c-3.35156-0.33447-6.85059-0.57617-10.46484-0.7168c-0.07227,0.00195-0.14453,0.01025-0.21777,0.01025
      c-0.29785,0-0.58984-0.02344-0.87988-0.05322c-1.91309-0.06348-3.85352-0.09912-5.82227-0.10303
      c-1.88818,0.00391-3.75244,0.03711-5.58936,0.09521c-0.32861,0.03906-0.6626,0.06104-1.00098,0.06104
      c-0.08936,0-0.17627-0.00977-0.26514-0.01221c-3.55029,0.13721-6.99023,0.37109-10.28467,0.69629
      c-0.17139,0.02344-0.34473,0.04053-0.51855,0.05322c-7.66504,0.78125-14.52393,2.04346-20.14209,3.66113
      c-0.39941,0.11475-0.80273,0.22803-1.18799,0.34619c-0.64893,0.19043-0.99512,0.89551-0.78369,1.51074
      c0.21289,0.61621,0.896,0.86133,1.54492,0.67139c0.01367-0.00391,0.0293-0.00781,0.04346-0.01172
      C371.77051,192.19775,385.25928,190.41357,400.2666,190.39014z"/>
    <path class="spa-i-3" d="M447.21094,185.65332c0.01953-0.04102,0.04199-0.08154,0.06445-0.12109
      C447.25293,185.57275,447.23047,185.61182,447.21094,185.65332z"/>
    <path class="spa-i-3" d="M365.12695,189.62988c1.15332,0.68311,2.50781,1.08545,3.97266,1.08545
      c3.13428,0,5.9541-1.72461,7.07324-4.31982c1.44336,1.56592,3.44727,2.51807,5.80762,2.51807
      c0.21143,0,0.41895-0.01904,0.62744-0.03516c0.17383-0.0127,0.34717-0.02979,0.51855-0.05322
      c2.05176-0.28613,3.88916-1.31152,5.09766-2.77686c1.35156,1.24316,3.17676,2.0166,5.18701,2.08057
      c0.08887,0.00244,0.17578,0.01221,0.26514,0.01221c0.33838,0,0.67236-0.02197,1.00098-0.06104
      c2.36084-0.28369,4.41748-1.5542,5.64502-3.35254c1.24902,1.82813,3.35449,3.10547,5.7666,3.36035
      c0.29004,0.02979,0.58203,0.05322,0.87988,0.05322c0.07324,0,0.14551-0.0083,0.21777-0.01025
      c2.03809-0.05273,3.87207-0.82861,5.24512-2.08252c1.23535,1.49658,3.11523,2.54004,5.21973,2.79932
      c0.06738,0.00781,0.13574,0.01416,0.2041,0.021c0.26758,0.02588,0.53711,0.04492,0.80957,0.04492
      c2.35938,0,4.375-0.95215,5.81641-2.51807c1.10938,2.59521,3.92871,4.31982,7.07617,4.31982
      c1.45313,0,2.81836-0.40234,3.97168-1.08545c0.40625,1.02734,1.08691,1.95215,1.99023,2.5249
      c0.41602,0.26172,0.87207,0.45654,1.37891,0.54102c1.53223,0.24561,3.45703-0.5249,4.7334-1.26465
      c1.68555-0.97705,3.26563-2.9873,4.00293-4.01172c-0.11035-0.05762-0.21387-0.12744-0.30176-0.21777
      c-0.33008-0.34521-0.37305-0.86279-0.18555-1.3833c-0.01465,0.03906-0.0332,0.07861-0.04688,0.11816h-0.00098
      c-0.125,0.02344-0.27051,0.04834-0.43066,0.07422c-0.66602,0.65283-1.60449,1.51025-2.79297,2.44385
      c-2.57227,2.01465-4.50781,1.90283-4.50781,1.90283c-1.67871-0.28027-2.41797-1.82422-2.41797-3.55957v-0.03418
      c-0.20508,0.06689-0.41699,0.08203-0.61719,0.02295c-0.18457-0.05859-0.33691-0.17578-0.45898-0.32764l-0.02051,0.02637
      c-0.94043,1.33105-2.54102,2.2041-4.36523,2.2041c-2.89844,0-5.24902-2.21631-5.24902-4.96924
      c0-0.79199,0.14258-1.54199,0.48926-2.20947c-0.27344,0.10059-0.56836,0.16211-0.87891,0.16211
      c-0.68262,0-1.29883-0.27002-1.73926-0.7002l-0.00781,0.00732c0.03418,0.21143,0.04492,0.4248,0.04492,0.63672
      c0,2.87598-2.46289,5.2041-5.49512,5.2041c-2.69434,0-5.10156-1.77979-5.44922-4.39795
      c-0.00781,0.00293-0.0127-0.0127-0.01855-0.03711c-0.15137,0.06055-0.31152,0.08838-0.47363,0.07031
      c-0.17676-0.01953-0.33691-0.09082-0.47949-0.19971c-0.01367,0.03076-0.02734,0.04883-0.03613,0.04346
      c-0.70313,2.08154-2.78613,3.5918-5.22656,3.5918c-3.03125,0-5.50586-2.32715-5.50586-5.20361
      c0-0.56641,0.05078-1.10498,0.20313-1.60498c-0.39258,0.25586-0.86523,0.4082-1.37695,0.4082
      c-0.47363,0-0.91406-0.13428-1.28711-0.3584c0.14844,0.48682,0.17969,1.00781,0.17969,1.55518
      c0,2.87646-2.46338,5.20361-5.49561,5.20361c-2.45068,0-4.521-1.51025-5.23633-3.5918
      c-0.00781,0.00537-0.01953-0.0127-0.03418-0.04199c-0.14063,0.1084-0.30322,0.17871-0.48096,0.19824
      c-0.15967,0.01807-0.31641-0.00977-0.46631-0.06836c-0.00488,0.02246-0.00977,0.03809-0.01514,0.03516
      c-0.3584,2.61816-2.75293,4.39795-5.46094,4.39795c-3.03271,0-5.49512-2.32813-5.49512-5.2041
      c0-0.21191,0.02295-0.42529,0.04492-0.63672l-0.00098-0.00146c-0.44092,0.42773-1.05322,0.69434-1.73242,0.69434
      c-0.31689,0-0.61621-0.06201-0.89307-0.16602c0.34766,0.66846,0.48877,1.41943,0.48877,2.21338
      c0,2.75293-2.34961,4.96924-5.24707,4.96924c-1.81494,0-3.42578-0.87305-4.36572-2.2041l-0.02051-0.02637
      c-0.12158,0.15186-0.27393,0.26904-0.45996,0.32764c-0.2002,0.05957-0.41064,0.04346-0.61621-0.02441v0.03564
      c0,1.73535-0.72656,3.2793-2.41699,3.55957c0,0-1.93604,0.12207-4.49902-1.90283c-1.18799-0.93311-2.12744-1.79053-2.79688-2.44336
      c-0.16162-0.0249-0.31152-0.05029-0.43701-0.07471c-0.00537-0.0166-0.01367-0.03223-0.01904-0.04834
      c0.15723,0.49609,0.10107,0.98389-0.21533,1.31348c-0.08643,0.09033-0.18994,0.16016-0.30078,0.21777
      c0.73877,1.02441,2.32227,3.03467,4.01611,4.01172c1.2749,0.73975,3.2002,1.51025,4.72119,1.26465
      c0.24707-0.04102,0.479-0.1123,0.70361-0.19873C363.70459,192.02051,364.61475,190.90088,365.12695,189.62988z"/>
    <path class="spa-i-3" d="M447.39453,185.32568c0.01367-0.021,0.03223-0.04199,0.04785-0.06348
      C447.42676,185.28369,447.4082,185.30469,447.39453,185.32568z"/>
    <path class="spa-i-3" d="M352.7915,184.7998v0.00049c0.07422,0.06494,0.14355,0.13281,0.2085,0.20166
      c-0.00342-0.00293-0.00684-0.00684-0.01025-0.01025C352.92871,184.92578,352.86133,184.86182,352.7915,184.7998z"/>
    <path class="spa-i-3" d="M353.42627,185.62891c-0.00293-0.00684-0.00684-0.01416-0.01074-0.021
      C353.41943,185.61475,353.42334,185.62207,353.42627,185.62891z"/>
    <path class="spa-i-3" d="M353.25391,185.32178c-0.01074-0.01514-0.02441-0.02979-0.03516-0.0459
      C353.22949,185.29199,353.24316,185.30664,353.25391,185.32178z"/>
    <path class="spa-i-3" d="M350.09863,184.47021c0.58203-0.60449,1.75537-0.48047,2.6416,0.2793
      c0.01855,0.01611,0.0332,0.03418,0.05127,0.05029c0.06982,0.06201,0.13721,0.12598,0.19824,0.19189
      c0.01221,0.01221,0.02588,0.02441,0.0376,0.0376c0.07178,0.08057,0.13232,0.16309,0.19141,0.24658
      c0.01074,0.01611,0.02441,0.03076,0.03516,0.0459c0.06299,0.09424,0.11475,0.18994,0.16162,0.28613
      c0.00391,0.00684,0.00781,0.01416,0.01074,0.021c0.0415,0.08691,0.07275,0.17285,0.09961,0.25928
      c0.00537,0.01611,0.01367,0.03174,0.01904,0.04834c0.12549,0.02441,0.27539,0.0498,0.43701,0.07471
      c0.44629,0.06934,1.00146,0.13916,1.50977,0.1709c0.95117,0.06787,2.52979-0.18945,3.01123-0.24561
      c0.4707-0.06787,1.4209-0.21289,1.4209-0.21289s-0.11133-0.16748-0.49219-0.54785
      c-0.43604-0.43652-0.92871-0.83887-0.92871-0.83887c1.354-0.51465,2.38281-1.51123,2.93213-2.57471
      c-1.27637-0.49219-2.89893-0.72754-4.28613-0.53613c0,0,0.04443-0.35889,0.00977-0.9292
      c-0.021-0.48193-0.05469-0.68311-0.05469-0.68311s-0.78467,0.5376-1.53369,0.88379
      c-0.76074,0.35791-2.21484,1.1416-2.78662,1.85791c-0.5708,0.72803-0.95068,1.80225-0.95068,1.80225
      s0.76025-2.51855,0.06738-4.25244c-0.69482-1.73535-2.21631-2.03711-2.21631-2.03711l-0.25781,1.19775
      c-0.62646-0.78369-1.6665-1.24268-2.7749-1.24268c-0.16602,0-0.32422,0.02441-0.4834,0.04639
      c-1.64844,0.22363-2.86279,1.55518-2.86279,3.18799c0,0,2.46191-0.20215,3.90674,0.58154
      c1.45313,0.78369,2.69629,3.11035,2.69629,3.11035c0.00146-0.00244,0.00342-0.00537,0.00439-0.0083
      c0.01221-0.02246,0.02588-0.04395,0.03857-0.06592C349.99219,184.60352,350.04004,184.52832,350.09863,184.47021z"/>
    <path class="spa-i-0" d="M353.52588,185.88818c-0.02686-0.08643-0.05811-0.17236-0.09961-0.25928
      c-0.00293-0.00684-0.00684-0.01416-0.01074-0.021c-0.04688-0.09619-0.09863-0.19189-0.16162-0.28613
      c-0.01074-0.01514-0.02441-0.02979-0.03516-0.0459c-0.05908-0.0835-0.11963-0.16602-0.19141-0.24658
      c-0.0083-0.00977-0.01904-0.01758-0.02734-0.02734c-0.06494-0.06885-0.13428-0.13672-0.2085-0.20166
      c-0.01855-0.0166-0.03271-0.03467-0.05127-0.05078c-0.88623-0.75977-2.05957-0.88379-2.6416-0.2793
      c-0.06006,0.06152-0.10547,0.1333-0.14746,0.20508c-0.0127,0.02197-0.02637,0.04346-0.03857,0.06592
      c-0.30029,0.61768-0.00732,1.52393,0.75586,2.18018c0.75098,0.63623,1.7168,0.82324,2.34131,0.49805
      c0.11084-0.05762,0.21436-0.12744,0.30078-0.21777C353.62695,186.87207,353.68311,186.38428,353.52588,185.88818z"/>
    <path class="spa-i-6" d="M364.94873,184.72754c-0.31348-0.92871-1.11865-1.50977-1.79102-1.29736
      c-0.67188,0.2002-0.95166,1.11816-0.63721,2.04736c0.22754,0.65186,0.6875,1.12549,1.17432,1.28467
      c0.20557,0.06787,0.41602,0.08398,0.61621,0.02441c0.18604-0.05859,0.33838-0.17578,0.45996-0.32764
      C365.08789,186.06104,365.1748,185.39941,364.94873,184.72754z"/>
    <path class="spa-i-3" d="M372.37988,179.36719c0-0.40527,0.11914-0.78125,0.31396-1.11182
      c-0.34814-0.15967-1.06348-0.46631-1.8252-0.66699c-0.80615-0.21289-2.22803-0.22412-2.65234-0.22412
      c-0.42578-0.02148-1.28711-0.03418-1.28711-0.03418s0.07861,0.19092,0.3125,0.61719
      c0.30273,0.51465,0.56006,0.83936,0.56006,0.83936c-1.42139,0.32324-2.62988,1.25244-3.39014,2.33838
      c1.10791,0.76025,2.57324,1.23047,4.02832,1.08545c0,0-0.1333,0.37939-0.22363,0.96191
      c-0.06689,0.48096-0.06689,0.68262-0.06689,0.68262s0.79395-0.29053,1.19727-0.43604
      c0.3916-0.14551,1.72363-0.61523,2.40625-1.07471c0.64502-0.43506,1.21533-0.96533,1.4873-1.22949
      C372.71777,180.69141,372.37988,180.06982,372.37988,179.36719z"/>
    <path class="spa-i-3" d="M374.80859,177.06299c0.56494,0,1.07861,0.19092,1.49121,0.49854
      c0.15283-0.33057,0.39941-0.91797,0.56689-1.5957c0.25732-1.05322,0.25732-2.21582,0.35693-2.59717
      c0.09033-0.39111,0.28027-1.47656,0.28027-1.47656s-0.55957-0.01123-1.21973,0.3584
      c-0.64941,0.38037-0.79297,0.66064-0.79297,0.66064c-0.23242-0.89746-0.74658-1.73633-1.40137-2.42188
      c-0.35742-0.37402-0.75439-0.7041-1.17432-0.96924c-0.00732,0.00977-0.01416,0.02002-0.02148,0.03027
      c-0.83545,1.08252-1.29639,2.56738-1.14111,3.98682c0,0-0.23584-0.22412-0.98584-0.3584
      c-0.74902-0.14502-1.2749,0.05566-1.2749,0.05566s0.57031,0.95215,0.80518,1.2876c0.22412,0.33545,0.64941,1.42139,1.26416,2.32813
      c0.42334,0.61475,0.89844,1.10742,1.1582,1.35889C373.14111,177.52637,373.91406,177.06299,374.80859,177.06299z"/>
    <path class="spa-i-3" d="M377.23633,179.36719c0,0.43506-0.13428,0.83643-0.35449,1.18359
      c0.36816,0.16504,1.05176,0.45068,1.77686,0.64063c0.8042,0.21338,2.2251,0.22461,2.65088,0.22461
      c0.42627,0.01074,1.2876,0.0332,1.2876,0.0332s-0.07959-0.18994-0.31396-0.62695c-0.30176-0.51563-0.56006-0.8291-0.56006-0.8291
      c1.42188-0.32373,2.63135-1.24072,3.40186-2.32666c-1.10791-0.77246-2.58398-1.25342-4.03857-1.09717
      c0,0,0.13232-0.39063,0.22314-0.97266c0.06738-0.48145,0.06738-0.68213,0.06738-0.68213s-0.79541,0.30127-1.19775,0.44678
      c-0.39111,0.14502-1.73486,0.60449-2.40723,1.06348c-0.59912,0.4126-1.12842,0.90088-1.41602,1.18066
      C376.88916,178.02881,377.23633,178.65625,377.23633,179.36719z"/>
    <path class="spa-i-0" d="M377.23633,179.36719c0-0.71094-0.34717-1.33838-0.88037-1.76172
      c-0.01904-0.01416-0.03711-0.02979-0.05615-0.04395c-0.4126-0.30762-0.92627-0.49854-1.49121-0.49854
      c-0.89453,0-1.66748,0.46338-2.08887,1.146c-0.00879,0.01563-0.01758,0.03125-0.02588,0.04639
      c-0.19482,0.33057-0.31396,0.70654-0.31396,1.11182c0,0.70264,0.33789,1.32422,0.86035,1.74756
      c0.20117,0.1626,0.42529,0.29834,0.67529,0.39209c0.27686,0.104,0.57617,0.16602,0.89307,0.16602
      c0.6792,0,1.2915-0.2666,1.73242-0.69434c0.13184-0.12793,0.24268-0.27295,0.34082-0.42773
      C377.10205,180.20361,377.23633,179.80225,377.23633,179.36719z"/>
    <path class="spa-i-6" d="M388.97461,180.55322c-0.12305-0.97217-0.79346-1.68896-1.49902-1.61035
      c-0.69287,0.07813-1.16309,0.92822-1.04053,1.90234c0.10303,0.75049,0.52783,1.34375,1.0332,1.54248
      c0.1499,0.05859,0.30664,0.08643,0.46631,0.06836c0.17773-0.01953,0.34033-0.08984,0.48096-0.19824
      C388.83447,181.93604,389.06738,181.27344,388.97461,180.55322z"/>
    <path class="spa-i-3" d="M398.36865,175.66211c-0.23047-0.16748-1.09375-0.77588-2.05225-1.20752
      c-0.86133-0.3916-2.43994-0.66064-2.90967-0.74902c-0.48047-0.08984-1.43213-0.26904-1.43213-0.26904s0.04443,0.22412,0.22363,0.75
      c0.22314,0.62598,0.44775,1.04053,0.44775,1.04053c-1.64551,0.08887-3.18994,0.90674-4.26465,1.96875
      c1.07471,1.05273,2.61914,1.85791,4.26465,1.9585c0,0-0.22461,0.40381-0.44775,1.04199
      c-0.1792,0.51416-0.22363,0.74951-0.22363,0.74951s0.95166-0.1792,1.43213-0.26953
      c0.46973-0.08887,2.04834-0.35742,2.90967-0.74902c1.11914-0.51416,2.13721-1.24268,2.13721-1.24268v-0.00537
      c-0.271-0.30078-0.46777-0.6626-0.54688-1.06738c-0.02881-0.13818-0.04492-0.28076-0.04492-0.42676
      c0-0.1499,0.01709-0.29541,0.0459-0.43896c0.01074-0.0498,0.02881-0.09668,0.0415-0.14551
      c0.02588-0.08984,0.05127-0.17969,0.08789-0.26563c0.02295-0.05664,0.05273-0.10889,0.08105-0.16309
      c0.03613-0.06982,0.07275-0.1377,0.11523-0.20117c0.04541-0.06982,0.09766-0.13428,0.1499-0.19775
      c0.0127-0.01465,0.02197-0.03174,0.03467-0.04541C398.41602,175.72363,398.39648,175.69971,398.36865,175.66211z"/>
    <path class="spa-i-3" d="M397.11084,169.52002c-0.80615-0.29102-1.43311-0.1792-1.43311-0.1792s0.43652,1.17432,0.62695,1.58838
      c0.19092,0.41455,0.43799,1.71289,0.94043,2.83252c0.41406,0.94141,0.95898,1.68604,1.12354,1.90039
      c0.02783,0.0376,0.04736,0.06152,0.04932,0.06543l0.00195,0.00195c0.44629-0.51465,1.11328-0.84912,1.86914-0.84912
      c0.74219,0,1.39941,0.32227,1.84375,0.8208c0.00977-0.00684,0.04688-0.03418,0.10645-0.07617
      c0.18945-0.25293,0.69922-0.96777,1.09277-1.86328c0.50586-1.11963,0.75-2.41797,0.94043-2.83252
      c0.17871-0.41406,0.61621-1.58838,0.61621-1.58838s-0.61621-0.11182-1.43164,0.1792
      c-0.80664,0.29102-1.01953,0.59326-1.01953,0.59326c-0.01563-0.23438-0.05176-0.46631-0.09863-0.69727
      c-0.1123-0.55371-0.30762-1.09326-0.56836-1.59912c-0.38672-0.75293-0.91406-1.43408-1.5166-1.979
      c-0.62402,0.57715-1.15527,1.30908-1.53223,2.11621c-0.24805,0.5293-0.42822,1.09033-0.51953,1.66602
      c-0.02588,0.16406-0.0498,0.32813-0.06104,0.49316C398.14111,170.11328,397.9165,169.81104,397.11084,169.52002z"/>
    <path class="spa-i-3" d="M402.71777,177.18506c0,0.5791-0.2334,1.10254-0.60547,1.50781
      c0.06152,0.04248,1.04395,0.73535,2.1377,1.23438c0.85059,0.3916,2.42871,0.66016,2.90918,0.74902l1.41113,0.26953
      c0,0-0.03418-0.23535-0.21191-0.74951c-0.22461-0.63818-0.44824-1.04199-0.44824-1.04199
      c1.65527-0.10059,3.20117-0.90576,4.2627-1.9585c-1.06152-1.06201-2.61816-1.87988-4.2627-1.96875
      c0,0,0.22363-0.41455,0.44824-1.04053c0.18848-0.52588,0.21191-0.75,0.21191-0.75s-0.94043,0.1792-1.41113,0.26904
      c-0.48047,0.08838-2.05859,0.35742-2.90918,0.74902c-0.91113,0.40625-1.72656,0.96631-2.01074,1.17041
      c-0.05957,0.04199-0.09668,0.06934-0.10645,0.07617C402.49219,176.10303,402.71777,176.6167,402.71777,177.18506z"/>
    <path class="spa-i-0" d="M402.1123,178.69287c0.37207-0.40527,0.60547-0.92871,0.60547-1.50781
      c0-0.56836-0.22559-1.08203-0.58496-1.48389c-0.44434-0.49854-1.10156-0.8208-1.84375-0.8208
      c-0.75586,0-1.42285,0.33447-1.86914,0.84912c-0.01172,0.01514-0.02441,0.02881-0.03662,0.04346
      c-0.05225,0.06348-0.10449,0.12793-0.1499,0.19775c-0.04199,0.06445-0.0791,0.13232-0.11523,0.20117
      c-0.02832,0.0542-0.05811,0.10645-0.08105,0.16309c-0.03613,0.08594-0.06201,0.17578-0.08789,0.26563
      c-0.0127,0.04883-0.03076,0.0957-0.0415,0.14551c-0.02832,0.14307-0.0459,0.28857-0.0459,0.43896
      c0,0.146,0.01758,0.2876,0.04492,0.42676c0.0791,0.40479,0.27588,0.7666,0.54688,1.06738
      c0.15869,0.17578,0.34033,0.33008,0.54834,0.45361c0.37305,0.22412,0.81348,0.3584,1.28711,0.3584
      c0.51172,0,0.98438-0.15234,1.37695-0.4082C401.83301,178.97266,401.97949,178.83838,402.1123,178.69287z"/>
    <path class="spa-i-6" d="M414.21094,180.84521c0.12109-0.97412-0.33691-1.82422-1.04199-1.90234
      c-0.70508-0.07861-1.37598,0.63818-1.49902,1.61035c-0.0918,0.71875,0.14746,1.37988,0.56152,1.70313
      c0.14258,0.10889,0.30273,0.18018,0.47949,0.19971c0.16211,0.01807,0.32227-0.00977,0.47363-0.07031
      C413.69531,182.18701,414.11719,181.59473,414.21094,180.84521z"/>
    <path class="spa-i-3" d="M424.30078,177.60645c-0.28711-0.28027-0.81738-0.76904-1.41699-1.18164
      c-0.68262-0.45898-2.01465-0.91846-2.41797-1.06348c-0.3916-0.14551-1.18848-0.44678-1.18848-0.44678
      s-0.00977,0.20068,0.06934,0.68213c0.08984,0.58203,0.21191,0.97266,0.21191,0.97266
      c-1.44141-0.15625-2.91992,0.32471-4.02832,1.09717c0.76172,1.08594,1.98145,2.00293,3.39063,2.32666
      c0,0-0.25586,0.31348-0.54883,0.8291c-0.24512,0.43701-0.31348,0.62695-0.31348,0.62695l1.27734-0.0332
      c0.42578,0,1.85742-0.01123,2.65332-0.22461c0.72754-0.19141,1.41699-0.47998,1.78418-0.64404
      c-0.21875-0.34619-0.35254-0.74707-0.35254-1.18018C423.4209,178.65674,423.76758,178.02881,424.30078,177.60645z"/>
    <path class="spa-i-3" d="M427.9375,178.2085c0.25977-0.25244,0.7334-0.74463,1.15625-1.3584
      c0.60547-0.90674,1.03027-1.99268,1.25391-2.32813c0.23438-0.33545,0.80566-1.2876,0.80566-1.2876
      s-0.52539-0.20068-1.27637-0.05566c-0.74902,0.13428-0.9834,0.3584-0.9834,0.3584c0.16602-1.43262-0.31445-2.93164-1.15332-4.01709
      c-1.18848,0.75-2.21582,2.00293-2.58594,3.39111c0,0-0.13379-0.28027-0.79395-0.66064
      c-0.64746-0.36963-1.21973-0.3584-1.21973-0.3584s0.19141,1.08545,0.29199,1.47656
      c0.09961,0.38135,0.09961,1.54395,0.34473,2.59717c0.16895,0.67871,0.4209,1.26758,0.57715,1.59814
      c0.41211-0.31006,0.92773-0.50098,1.49512-0.50098C426.74316,177.06299,427.51563,177.52539,427.9375,178.2085z"/>
    <path class="spa-i-3" d="M427.41113,181.11865c0.27637,0.26563,0.84766,0.79248,1.48242,1.22559
      c0.68359,0.45947,2.01465,0.9292,2.41602,1.07471c0.3916,0.14551,1.18555,0.43604,1.18555,0.43604s0-0.20166-0.06836-0.68262
      c-0.08887-0.58252-0.20996-0.96191-0.20996-0.96191c1.44336,0.14502,2.91895-0.3252,4.02832-1.08545
      c-0.77246-1.08594-1.98145-2.01514-3.3916-2.33838c0,0,0.25781-0.32471,0.5498-0.83936
      c0.24707-0.42627,0.3125-0.61719,0.3125-0.61719s-0.85059,0.0127-1.28906,0.03418c-0.41113,0-1.83203,0.01123-2.63867,0.22412
      c-0.76074,0.20068-1.47656,0.50684-1.82422,0.66602c0.19434,0.33105,0.31348,0.70752,0.31348,1.11279
      C428.27734,180.07227,427.9375,180.69531,427.41113,181.11865z"/>
    <path class="spa-i-0" d="M428.27734,179.36719c0-0.40527-0.11914-0.78174-0.31348-1.11279
      c-0.00879-0.01465-0.0166-0.03076-0.02637-0.0459c-0.42188-0.68311-1.19434-1.14551-2.08789-1.14551
      c-0.56738,0-1.08301,0.19092-1.49512,0.50098c-0.01758,0.01367-0.03613,0.02783-0.05371,0.04248
      c-0.5332,0.42236-0.87988,1.05029-0.87988,1.76074c0,0.43311,0.13379,0.83398,0.35254,1.18018
      c0.0957,0.15283,0.20703,0.29736,0.33691,0.42529c0.44043,0.43018,1.05664,0.7002,1.73926,0.7002
      c0.31055,0,0.60547-0.06152,0.87891-0.16211c0.25293-0.09326,0.47949-0.22852,0.68262-0.39209
      C427.9375,180.69531,428.27734,180.07227,428.27734,179.36719z"/>
    <path class="spa-i-6" d="M438.13574,185.47754c0.3125-0.9292,0.03418-1.84717-0.63672-2.04736
      c-0.6709-0.2124-1.47754,0.36865-1.80273,1.29736c-0.22656,0.67188-0.13965,1.33301,0.17773,1.73145
      c0.12207,0.15186,0.27441,0.26904,0.45898,0.32764c0.2002,0.05908,0.41211,0.04395,0.61719-0.02295
      C437.44141,186.60596,437.90723,186.13135,438.13574,185.47754z"/>
    <path class="spa-i-3" d="M451.23047,179.06592l-0.25879-1.19775c0,0-1.52246,0.30176-2.21484,2.03711
      c-0.69434,1.73389,0.05566,4.25244,0.05566,4.25244s-0.36816-1.07422-0.93945-1.80225
      c-0.57129-0.71631-2.02734-1.5-2.78613-1.85791c-0.76367-0.34619-1.53516-0.88379-1.53516-0.88379
      s-0.03223,0.20117-0.06543,0.68311c-0.02344,0.57031,0.02051,0.9292,0.02051,0.9292
      c-1.39844-0.19141-3.02051,0.04395-4.28516,0.53613c0.53809,1.06348,1.56641,2.06006,2.9209,2.57471
      c0,0-0.48242,0.40234-0.92773,0.83887c-0.37109,0.38037-0.48242,0.54785-0.48242,0.54785s0.95117,0.14502,1.4209,0.21289
      c0.48145,0.05615,2.05957,0.31348,3.00098,0.24561c0.51758-0.03174,1.07227-0.10205,1.51465-0.17139
      c0.16016-0.02588,0.30566-0.05078,0.43066-0.07422h0.00098c0.01367-0.03955,0.03223-0.0791,0.04688-0.11816
      c0.01953-0.05664,0.03809-0.11035,0.06348-0.16504c0.01953-0.0415,0.04199-0.08057,0.06445-0.12109
      c0.03711-0.06934,0.07324-0.13818,0.11914-0.20654c0.01367-0.021,0.03223-0.04199,0.04785-0.06348
      c0.12695-0.18018,0.27832-0.35547,0.46289-0.5127c0.88379-0.75977,2.07129-0.88379,2.64063-0.2793
      c0.08008,0.07813,0.15723,0.19141,0.20215,0.2793c0,0,1.23242-2.32666,2.68555-3.11035
      c1.4541-0.78369,3.91699-0.58154,3.91699-0.58154c0-1.79053-1.46484-3.23438-3.35742-3.23438
      c-0.10254,0-0.20313,0.00781-0.30273,0.01514C452.69824,177.91504,451.78809,178.35449,451.23047,179.06592z"/>
    <path class="spa-i-0" d="M447.90527,184.74951c-0.18457,0.15723-0.33594,0.33252-0.46289,0.5127
      c-0.01563,0.02148-0.03418,0.04248-0.04785,0.06348c-0.0459,0.06836-0.08203,0.13721-0.11914,0.20654
      c-0.02246,0.03955-0.04492,0.08008-0.06445,0.12109c-0.02539,0.05469-0.04395,0.1084-0.06348,0.16504
      c-0.1875,0.52051-0.14453,1.03809,0.18555,1.3833c0.08789,0.09033,0.19141,0.16016,0.30176,0.21777
      c0.625,0.3252,1.58984,0.13818,2.3418-0.49805c0.88379-0.76074,1.14258-1.85742,0.56934-2.45117
      C449.97656,183.86572,448.78906,183.98975,447.90527,184.74951z"/>
    <path class="spa-i-0" d="M400.2666,199.1416c-12.37646,0.02344-23.57861,1.36523-32.0957,3.56982
      c8.51709,2.20508,19.71924,3.63721,32.0957,3.64795c12.3877-0.01074,23.64648-1.44287,32.16016-3.64795
      C423.91309,200.50684,412.6543,199.16504,400.2666,199.1416z"/>
    <polygon class="spa-i-0" points="364.25439,196.28809 364.14551,196.4292 362.89014,198.06787 365.01514,198.96191 
      365.21631,198.81689 368.02637,196.76855 	"/>
    <path class="spa-i-6" d="M373.53223,195.96338c0-0.64893-0.54785-1.16357-1.23193-1.16357c-0.68262,0-1.23145,0.51465-1.23145,1.16357
      c0,0.63867,0.54883,1.16406,1.23145,1.16406C372.98438,197.12744,373.53223,196.60205,373.53223,195.96338z"/>
    <path class="spa-i-10" d="M379.18164,196.03076l2.64258-0.30225l2.69678-0.31348c0.67236-0.07764,1.15283-0.66064,1.07422-1.29883
      c-0.07861-0.63672-0.69238-1.08496-1.36475-1.00684l-2.70801,0.31299l-2.65234,0.31396
      c-0.68164,0.06836-1.16406,0.64941-1.07471,1.2876C377.87354,195.66064,378.5,196.10889,379.18164,196.03076z"/>
    <path class="spa-i-6" d="M390.77734,192.63965c-0.68359,0-1.23145,0.51563-1.23145,1.16504c0,0.63623,0.54785,1.16309,1.23145,1.16309
      c0.6709,0,1.23096-0.52686,1.23096-1.16309C392.0083,193.15527,391.44824,192.63965,390.77734,192.63965z"/>
    <path class="spa-i-0" d="M396.61768,194.70996h3.68311h3.72559c0.67188,0,1.24316-0.51465,1.24316-1.15332
      c0-0.64844-0.54883-1.16309-1.23047-1.16309h-7.43213c-0.68164,0-1.23047,0.51465-1.23047,1.16309
      C395.37646,194.19531,395.94629,194.70996,396.61768,194.70996z"/>
    <path class="spa-i-6" d="M411.05371,193.80469c0-0.64941-0.54785-1.16504-1.23047-1.16504c-0.6709,0-1.23047,0.51563-1.23047,1.16504
      c0,0.63623,0.55957,1.16309,1.23047,1.16309C410.50586,194.96777,411.05371,194.44092,411.05371,193.80469z"/>
    <path class="spa-i-10" d="M418.77734,195.72852l2.63867,0.30225c0.67285,0.07813,1.30957-0.37012,1.38965-1.00684
      c0.07813-0.63818-0.40332-1.21924-1.07617-1.2876l-2.65039-0.31396l-2.70898-0.31299
      c-0.68164-0.07813-1.28809,0.37012-1.36523,1.00684c-0.08984,0.63818,0.4043,1.22119,1.0752,1.29883L418.77734,195.72852z"/>
    <path class="spa-i-6" d="M429.5293,195.96338c0-0.64893-0.54688-1.16357-1.23047-1.16357c-0.68066,0-1.23047,0.51465-1.23047,1.16357
      c0,0.63867,0.5498,1.16406,1.23047,1.16406C428.98242,197.12744,429.5293,196.60205,429.5293,195.96338z"/>
    <polygon class="spa-i-0" points="432.57422,196.76855 435.41992,198.84277 435.58398,198.96191 437.7002,198.06787 
      436.47754,196.46143 436.3457,196.28809 	"/>
    <path class="spa-i-7" d="M428.64746,333.73438c-14.83203,0-26.96875-10.84863-28.06738-24.62305v0.10254
      c-0.20898,1.40039-0.5,2.75879-0.85449,4.05469c-0.42676,1.7168-1.02832,3.37207-1.78613,4.94141
      c-0.6499,1.39453-1.36963,2.62891-2.12695,3.63574c-2.01172,2.87598-4.59424,5.35742-7.59912,7.2998v0.08887
      c-0.92139,0.60449-2.72119,1.61719-4.62744,2.32422c-2.46826,1.01074-5.12354,1.69531-7.90576,1.99512v0.02246
      c-2.08105,0.21289-3.99512,0.25781-6.56934,0v-0.03613c-7.43994-0.83594-13.98926-4.41309-18.48682-9.64355v0.14551
      c2.36914,2.78711,5.32324,5.10547,8.70654,6.78223c12.19775,5.97559,26.71338,9.55664,41.35107,9.55664
      c14.64746,0,28.92676-3.58105,41.125-9.55664c6.29492-3.11621,11.09082-8.44824,13.4082-14.8916
      C451.35352,326.30273,440.91309,333.73438,428.64746,333.73438z M401.48633,338.05371c0,0-0.61523,0.08887-0.81641,0.18848
      c-0.21191,0.10156-0.89551,0.32617-0.89551,0.32617s0.53711-1.34473,0.70508-2.67188
      c-0.12695,0.04395-3.38281,1.10938-6.9165,0.21973c-1.16357-0.29004-3.22266-1.52246-3.83789-2.1377
      c-0.61523-0.61328-2.0249-1.62207-2.06006-1.65625c1.19824,0.06738,3.97412-0.35645,6.44678,0.16797
      c1.89893,0.39844,6.25244,3.31934,6.36865,3.39648c0.01953-0.16406,0.03613-0.3252,0.04297-0.48633
      c0.06641-1.19434-0.15723-2.36816-0.16602-2.41895c-3.05273-0.23242-5.43994-2.15918-5.43994-4.65332
      c0-2.00293,1.11914-4.25391,3.44678-4.40723c0,0-0.06787-0.21582-0.26904-0.56152
      c-0.24561-0.25684-0.71582-0.73926-0.71582-0.73926s0.8833-0.16699,1.39893,0.02344c0.51465,0.2002,0.8623,0.52539,0.8623,0.52539
      s0.23438-0.48145,0.58203-0.85156c0.33496-0.37012,0.78223-0.59277,0.78223-0.59277s0.51563,0.42578,0.68457,0.7168
      c0.16602,0.30176,0.2793,0.66113,0.2793,0.66113s0.46875-0.3916,0.88281-0.54883c0.41406-0.16797,0.93945-0.29102,0.93945-0.29102
      s-0.14453,0.51367-0.24512,0.77246c-0.09961,0.25684-0.15625,0.80371-0.15625,0.80371
      c2.33789,0.70703,3.52539,2.45215,3.52539,4.48926c0,2.58789-2.43652,4.55957-5.65723,4.66699
      c0.04004,0.11719,0.43164,1.33008,0.43164,2.40527C401.68945,336.54199,401.48633,338.05371,401.48633,338.05371z
      M413.05762,334.4043c-0.61523,0.41309-2.20508,1.75684-4.63281,1.78027c-2.43848,0.03223-5.12598-0.25879-5.5625-0.19141
      c-0.44727,0.06738-1.09668,0.16797-1.09668,0.16797s2.84277-2.97559,5.83105-3.37891
      c3.52539-0.49414,5.8418,0.29102,7.20605,0.64941C414.83789,333.43164,413.67285,333.97852,413.05762,334.4043z"/>
    <path class="spa-i-7" d="M346.57422,317.55859c0.64941,1.48047,1.43604,2.88965,2.3418,4.21973
      c-0.396-0.60059-0.78223-1.23535-1.15332-1.90527C347.3291,319.12305,346.93213,318.35059,346.57422,317.55859z"/>
    <path class="spa-i-5" d="M400.93945,332.97266h0.31152c0,0,0.00488,0.01563,0.00684,0.02246
      c3.2207-0.10742,5.65723-2.0791,5.65723-4.66699c0-2.03711-1.1875-3.78223-3.52539-4.48926c0,0,0.05664-0.54688,0.15625-0.80371
      c0.10059-0.25879,0.24512-0.77246,0.24512-0.77246s-0.52539,0.12305-0.93945,0.29102
      c-0.41406,0.15723-0.88281,0.54883-0.88281,0.54883s-0.11328-0.35938-0.2793-0.66113
      c-0.16895-0.29102-0.68457-0.7168-0.68457-0.7168s-0.44727,0.22266-0.78223,0.59277
      c-0.34766,0.37012-0.58203,0.85156-0.58203,0.85156s-0.34766-0.3252-0.8623-0.52539
      c-0.51563-0.19043-1.39893-0.02344-1.39893-0.02344s0.47021,0.48242,0.71582,0.73926
      c0.20117,0.3457,0.26904,0.56152,0.26904,0.56152c-2.32764,0.15332-3.44678,2.4043-3.44678,4.40723
      c0,2.49414,2.38721,4.4209,5.43994,4.65332c0-0.00098-0.00098-0.00879-0.00098-0.00879H400.93945z M400.91504,324.17676
      c1.1084,1.04004,1.80273,2.5166,1.80273,4.15137c0,1.64258-0.69434,3.12109-1.81348,4.16211
      c-1.11816-1.04102-1.8125-2.51953-1.8125-4.16211C399.0918,326.69336,399.78613,325.2168,400.91504,324.17676z"/>
    <path class="spa-i-10" d="M394.11182,332.49023c-2.47266-0.52441-5.24854-0.10059-6.44678-0.16797
      c0.03516,0.03418,1.44482,1.04297,2.06006,1.65625c0.61523,0.61523,2.67432,1.84766,3.83789,2.1377
      c3.53369,0.88965,6.78955-0.17578,6.9165-0.21973c0-0.00391,0.00098-0.00684,0.00098-0.00977
      C400.36426,335.80957,396.01074,332.88867,394.11182,332.49023z"/>
    <path class="spa-i-10" d="M407.59668,332.78223c-2.98828,0.40332-5.83105,3.37891-5.83105,3.37891s0.64941-0.10059,1.09668-0.16797
      c0.43652-0.06738,3.12402,0.22363,5.5625,0.19141c2.42773-0.02344,4.01758-1.36719,4.63281-1.78027
      c0.61523-0.42578,1.78027-0.97266,1.74512-0.97266C413.43848,333.07324,411.12207,332.28809,407.59668,332.78223z"/>
    <path class="spa-i-0" d="M402.71777,328.32813c0-1.63477-0.69434-3.11133-1.80273-4.15137
      c-1.12891,1.04004-1.82324,2.5166-1.82324,4.15137c0,1.64258,0.69434,3.12109,1.8125,4.16211
      C402.02344,331.44922,402.71777,329.9707,402.71777,328.32813z"/>
    <path class="spa-i-10" d="M401.25098,332.97266h-0.31152h-0.58301c0,0,0.00098,0.00781,0.00098,0.00879
      c0.00879,0.05078,0.23242,1.22461,0.16602,2.41895c-0.00684,0.16113-0.02344,0.32227-0.04297,0.48633
      c0,0.00293-0.00098,0.00586-0.00098,0.00977c-0.16797,1.32715-0.70508,2.67188-0.70508,2.67188s0.68359-0.22461,0.89551-0.32617
      c0.20117-0.09961,0.81641-0.18848,0.81641-0.18848s0.20313-1.51172,0.20313-2.65332c0-1.0752-0.3916-2.28809-0.43164-2.40527
      C401.25586,332.98828,401.25098,332.97266,401.25098,332.97266z"/>
    <path class="spa-i-0" d="M394.32471,323.49316l-0.09912-34.79395c-2.25-0.94922-4.28662-2.38672-6.01221-4.19629v44.64258
      c3.00488-1.94238,5.5874-4.42383,7.59912-7.2998C395.32813,322.48926,394.82959,323.04395,394.32471,323.49316z"/>
    <path class="spa-i-0" d="M375.68018,333.55371c2.78223-0.2998,5.4375-0.98438,7.90576-1.99512
      c-0.70508,0.26074-1.42334,0.47949-2.11963,0.62109l0.16455-62.39063h-5.95068V333.55371z"/>
    <path class="spa-i-0" d="M399.72559,313.26855c-0.48633,1.79199-1.0957,3.45801-1.78613,4.94141
      C398.69727,316.64063,399.29883,314.98535,399.72559,313.26855z"/>
    <path class="spa-i-0" d="M350.62402,323.89648c4.49756,5.23047,11.04688,8.80762,18.48682,9.64355v-63.75098h-5.94971l0.16357,62.39063
      c-2.58447-0.61719-4.98877-2.17188-6.74658-3.10156v-59.28906h-5.9541V323.89648z"/>
    <path class="spa-i-0" d="M344.45459,309.75781c0.28906,2.74512,1.01904,5.36621,2.11963,7.80078
      c0.35791,0.79199,0.75488,1.56445,1.18848,2.31445C346.22559,317.09766,344.96631,313.72266,344.45459,309.75781z"/>
    <path class="spa-i-3" d="M350.62402,269.78906h-6.31104v-0.03418v-1.00586h-0.02393l-0.07764,37.82422
      c0.02344,1.10254,0.11084,2.16211,0.24316,3.18457c0.51172,3.96484,1.771,7.33984,3.30811,10.11523
      c0.37109,0.66992,0.75732,1.30469,1.15332,1.90527c0.55762,0.84473,1.1333,1.62305,1.70801,2.33008v-0.06641v-0.14551V269.78906z"
      />
    <path class="spa-i-3" d="M356.57813,329.07813c1.75781,0.92969,4.16211,2.48438,6.74658,3.10156l-0.16357-62.39063h-6.58301V329.07813z
      "/>
    <path class="spa-i-3" d="M369.11084,333.54004v0.03613c2.57422,0.25781,4.48828,0.21289,6.56934,0v-0.02246v-63.76465h-6.56934
      V333.54004z"/>
    <path class="spa-i-3" d="M381.46631,332.17969c0.69629-0.1416,1.41455-0.36035,2.11963-0.62109
      c1.90625-0.70703,3.70605-1.71973,4.62744-2.32422v-0.08887v-44.64258c-3.51025-3.68262-5.72412-8.90527-5.79053-14.71387h-0.79199
      L381.46631,332.17969z"/>
    <path class="spa-i-3" d="M394.32471,323.49316c0.50488-0.44922,1.00342-1.00391,1.48779-1.64746
      c0.75732-1.00684,1.47705-2.24121,2.12695-3.63574c0.69043-1.4834,1.2998-3.14941,1.78613-4.94141
      c0.35449-1.2959,0.64551-2.6543,0.85449-4.05469v-0.10254c-0.05371-0.67188-0.08887-1.34766-0.08887-2.03418v-17.08105
      c-2.20459-0.01563-4.31445-0.47266-6.26563-1.29688L394.32471,323.49316z"/>
    <path class="spa-i-0" d="M418.80273,269.78906c-0.02051,1.82813-0.25488,3.59961-0.67578,5.2832h3.75l0.56934,1.86914h3
      c0.39941-1.15527,1.52441-1.97852,2.86328-1.99316l0.04004,4.7373l0.04004-4.7373c1.33984,0.01465,2.46484,0.83789,2.86523,1.99316
      h2.99707l0.57227-1.86914h5.26953l0.57227,1.86914h3.45898c0.40137-1.16602,1.55371-1.99316,2.91797-1.99316
      c1.68945,0,3.05664,1.28613,3.05664,2.88574c0,1.2666-0.84961,2.35156-2.05859,2.74414v4.53125l1.96973,0.55957v4.99121
      l-1.96973,0.53711l0.01074,4.53125c1.23145,0.36914,2.14746,1.48828,2.14746,2.77539c0,1.29883-0.90625,2.39551-2.13672,2.76563
      v2.94238l1.95898,0.64941l-0.28027,4.98047l-1.96289,0.43457c-0.2627,1.38965-0.68848,2.72949-1.24805,3.99512l0.15625,0.18164
      c1.1084,1.20898,0.9502,3.03125-0.33691,4.07129c-0.82617,0.68262-1.93359,0.85059-2.9082,0.56055
      c-0.84082,0.94727-1.78711,1.81836-2.80957,2.59766l0.42578,1.97949c-1.41113,0.95117-2.9668,1.75684-4.6123,2.36035
      l-1.45313-1.36621h-0.00098c0.04688-0.01563,0.09277-0.03809,0.1416-0.05371c-1.25195,0.42969-2.56152,0.74023-3.92383,0.92871
      c-0.28906,1.24609-1.4248,2.18066-2.81445,2.25488l-0.04492-12.16211l-0.04492,12.16211
      c-1.3877-0.07422-2.52246-1.00879-2.81348-2.25488c-1.36328-0.18848-2.66992-0.49805-3.92285-0.92871
      c0.04785,0.0166,0.0918,0.03809,0.14063,0.05371l-1.4541,1.36621c-1.64746-0.60352-3.20117-1.40918-4.6123-2.36035l0.4248-1.97949
      c-1.02148-0.7793-1.96875-1.65039-2.80664-2.59766c-0.97559,0.29004-2.08203,0.12207-2.91211-0.56055
      c-1.28516-1.04004-1.44238-2.8623-0.33496-4.07129l0.15723-0.18164c-0.56055-1.26563-0.98535-2.60547-1.24902-3.99512
      l-1.96387-0.43457l-0.2793-4.98047l1.95898-0.64941v-2.94238c-1.23145-0.37012-2.1377-1.4668-2.1377-2.76563
      c0-1.28711,0.91699-2.40625,2.14844-2.77539l0.01172-4.53125l-1.9707-0.53711v-1.83398
      C404.78906,289.58398,402.74512,290,400.61328,290c-0.04102,0-0.08105-0.00293-0.12207-0.00391v17.08105
      c0,0.68652,0.03516,1.3623,0.08887,2.03418c1.09863,13.77441,13.23535,24.62305,28.06738,24.62305
      c12.26563,0,22.70605-7.43164,26.56738-17.80176c1.03125-2.77148,1.59863-5.74902,1.59863-8.85547v-37.32227h-0.05664v0.03418
      H418.80273z"/>
    <path class="spa-i-0" d="M435.34961,304.9043l1.86914-0.80469l3.42383,3.80566l-1.11719,1.62207l3.71484,4.11719
      c0.51465-0.19043,1.08301-0.24414,1.63281-0.17871c0.45215-1.04395,0.78223-2.13672,0.99902-3.26953l-1.96191-0.64746
      c0,0,0.15137-1.96191,0.20215-2.49902c0.00391-0.46484,0.06641-2.49219,0.06641-2.49219l1.95801-0.45801l0.01074,2.97754
      c0,0.63184-0.02832,1.25879-0.08887,1.87598c0.00879-0.08984,0.02539-0.17578,0.03516-0.26465l1.89551-0.00977
      c0.04883-0.52539,0.07324-1.05566,0.07324-1.60156v-1.31934l-1.91309,0.00977l-0.0127-4.52148
      c-0.88281-0.29102-1.58887-0.96289-1.89063-1.8125l-3.62598,0.01172l-0.57129,1.86719h-5.28027l-0.56055-1.86719l-2.83789-0.00977
      c-0.08496,0.24023-0.19043,0.47168-0.33496,0.67969L435.34961,304.9043z"/>
    <path class="spa-i-0" d="M429.21777,309.78516l1.96875,0.53711v4.99023l-1.96875,0.54785l0.01172,6.29004
      c0.80566,0.26758,1.41992,0.82715,1.76758,1.56641c1.04004-0.14551,2.05566-0.37793,3.03906-0.6875l-0.43359-1.88477
      c1.70215-0.53809,3.2793-1.32129,4.66895-2.33984l1.4082,1.24219c-1.58301,1.23047-3.4043,2.22559-5.36914,2.89746
      c0.42383-0.14063,0.8418-0.29297,1.25195-0.46777l0.9375,1.5957c0.93652-0.4043,1.83203-0.87891,2.68555-1.40918l-0.92578-1.58691
      c1.35449-0.86035,2.57227-1.90234,3.625-3.0791c-0.73828-0.94824-0.76074-2.21484-0.12207-3.18652l-3.69336-4.1084
      l-1.86816,0.79395l-3.42578-3.80371l1.11816-1.6123l-4.3584-4.8418c-0.10449,0.03516-0.20996,0.06641-0.31738,0.0918V309.78516z"/>
    <path class="spa-i-0" d="M434.25195,290.72656l-1.11816-1.62305l3.42383-3.80469l1.87012,0.7959l6.02051-6.6709
      c-0.13477-0.21191-0.23535-0.43555-0.32324-0.68164h-3.45898l-0.57227,1.86914h-5.26953l-0.57227-1.86914h-2.99707
      c-0.31348,0.89453-1.05176,1.58789-2.00488,1.86914l-0.01074,2.14844l1.96973,0.55859v4.99023l-1.96973,0.53711l0.02344,6.89063
      c0.12793,0.0332,0.25684,0.06445,0.37695,0.11133L434.25195,290.72656z"/>
    <path class="spa-i-0" d="M434.20801,297.64258l0.56055-1.86816h5.28027l0.57129,1.86816l3.59277-0.01074
      c0.29199-0.88477,1.00684-1.57715,1.94629-1.87012l-0.03418-4.56445l-1.95801-0.53711v-4.99121l1.95801-0.55957l0.03418-4.49805
      c-0.07813-0.02344-0.22461-0.02344-0.30371-0.04492l-5.97363,6.70215L441,288.90234l-3.42383,3.79492l-1.86914-0.80664
      l-4.62305,5.14355c0.12012,0.19043,0.21582,0.39453,0.28809,0.6084H434.20801z"/>
    <path class="spa-i-0" d="M434.04102,323.04492c0.09082-0.0293,0.17773-0.06934,0.26953-0.10059
      c-0.09082,0.03125-0.18262,0.05566-0.27441,0.08496L434.04102,323.04492z"/>
    <polygon class="spa-i-0" points="429.26074,287.23633 429.26074,284.31543 428.38867,284.31543 428.30957,284.31543 
      427.43848,284.31543 427.43848,287.23633 428.31641,287.23633 428.38379,287.23633 	"/>
    <path class="spa-i-0" d="M420.14258,285.29883l3.42285,3.80469l-1.11914,1.62305l4.67871,5.19336
      c0.10059-0.04492,0.20117-0.08984,0.31348-0.13477l0.02246-6.93945l-1.96973-0.53711v-4.99023l1.96973-0.55859l-0.0127-2.14844
      c-0.94922-0.28125-1.68848-0.97461-2.00195-1.86914h-3l-0.56934,1.86914h-5.27148l-0.25098-0.82324
      c-0.45313,0.87598-0.95898,1.71191-1.51758,2.49902l3.43555,3.80762L420.14258,285.29883z"/>
    <polygon class="spa-i-0" points="417.90234,288.45508 419.88379,290.6709 421.32617,289.49609 419.34668,287.29199 	"/>
    <polygon class="spa-i-0" points="435.01367,307.2998 437.00488,309.51563 438.44824,308.35352 436.45605,306.13672 	"/>
    <rect x="435.90918" y="297.64258" class="spa-i-0" width="3.18945" height="1.79102"/>
    <path class="spa-i-0" d="M410.75586,309.81055c0.01172,0.06934,0.02148,0.13672,0.03418,0.2041
      C410.77734,309.94727,410.76758,309.87988,410.75586,309.81055z"/>
    <path class="spa-i-0" d="M408.80859,309.5293c-0.00391-0.03027-0.00781-0.05957-0.01074-0.08887
      C408.80078,309.46973,408.80566,309.49902,408.80859,309.5293z"/>
    <polygon class="spa-i-0" points="435.37305,289.49609 436.81641,290.6709 438.7959,288.45508 437.35254,287.29199 	"/>
    <path class="spa-i-0" d="M445.91406,309.99805c0.00879-0.05566,0.0166-0.1084,0.02832-0.16406
      C445.93066,309.88965,445.92285,309.94238,445.91406,309.99805z"/>
    <rect x="435.87402" y="276.94141" class="spa-i-0" width="3.09082" height="1.80078"/>
    <rect x="417.73535" y="276.94141" class="spa-i-0" width="3.08887" height="1.80078"/>
    <polygon class="spa-i-0" points="446.15918,289.58398 448.0625,289.58398 448.05176,286.66504 446.15918,286.66504 	"/>
    <path class="spa-i-0" d="M447.89258,309.52246c0.00293-0.02344,0.00586-0.04785,0.00977-0.07227
      C447.89844,309.47461,447.89551,309.49902,447.89258,309.52246z"/>
    <path class="spa-i-0" d="M445.83496,298.50391c0,0.68164,0.58203,1.23047,1.2998,1.23047c0.72754,0,1.30957-0.54883,1.30957-1.23047
      s-0.58203-1.24121-1.30957-1.24121C446.41699,297.2627,445.83496,297.82227,445.83496,298.50391z"/>
    <path class="spa-i-0" d="M422.66016,323.04492l0.00391-0.01563c-0.08984-0.02832-0.18066-0.05371-0.26953-0.08398
      C422.4834,322.97656,422.57031,323.01563,422.66016,323.04492z"/>
    <path class="spa-i-0" d="M422.80566,306.08008l1.11914,1.6123l-3.4248,3.80371l-1.87012-0.79395l-3.69141,4.1084
      c0.63672,0.97168,0.61328,2.23828-0.12402,3.18652c1.05176,1.17676,2.27246,2.21875,3.62598,3.0791l-0.92578,1.58691
      c0.85156,0.53027,1.74805,1.00488,2.68457,1.40918l0.9375-1.5957c0.41211,0.1748,0.83105,0.32813,1.25781,0.46875
      c-1.96777-0.67188-3.79199-1.66699-5.375-2.89844l1.41016-1.24219c1.3877,1.01855,2.96484,1.80176,4.66699,2.33984
      l-0.43262,1.88477c0.98438,0.30957,1.99707,0.54199,3.03906,0.6875c0.34668-0.73926,0.96289-1.29883,1.76855-1.56641
      l0.00977-6.29004l-1.96875-0.54785v-4.99023l1.96875-0.53711v-8.5293c-0.08398-0.02734-0.1709-0.05273-0.25293-0.08691
      L422.80566,306.08008z"/>
    <path class="spa-i-0" d="M422.49219,299.44531l-0.55957,1.86719h-5.28223l-0.57031-1.86719l-3.62695-0.01172
      c-0.30176,0.84961-1.00781,1.52148-1.89063,1.8125l-0.00977,4.52148l-1.91504-0.00977v1.31934
      c0,0.5459,0.02441,1.07617,0.07324,1.60156l1.89551,0.00977c0.00781,0.0918,0.02637,0.18262,0.03613,0.27441
      c-0.0625-0.62012-0.08984-1.25098-0.08984-1.88574l0.00977-2.97754l1.95898,0.45801c0,0,0.0625,2.02734,0.06641,2.49219
      c0.05078,0.53711,0.20215,2.49902,0.20215,2.49902l-1.96094,0.64746c0.2168,1.13281,0.54785,2.22559,0.99805,3.26953
      c0.54688-0.06543,1.11914-0.01172,1.63281,0.17871l3.7168-4.11719l-1.11914-1.62207l3.42383-3.80566l1.86816,0.80469
      l4.4668-4.95703c-0.01367-0.01953-0.02148-0.04102-0.03418-0.06152c-0.08203-0.14355-0.14941-0.29395-0.20508-0.45215
      L422.49219,299.44531z"/>
    <polygon class="spa-i-0" points="427.48145,314.31738 428.3457,314.31738 428.35352,314.31738 429.21777,314.31738 
      429.21777,311.39746 428.35938,311.39746 428.3418,311.39746 427.48145,311.39746 	"/>
    <path class="spa-i-0" d="M409.56543,299.73438c0.71777,0,1.29883-0.54883,1.29883-1.23047s-0.58105-1.24121-1.29883-1.24121
      c-0.72754,0-1.30957,0.55957-1.30957,1.24121S408.83789,299.73438,409.56543,299.73438z"/>
    <rect x="417.60156" y="297.64258" class="spa-i-0" width="3.18945" height="1.79102"/>
    <path class="spa-i-0" d="M410.54102,289.58398v-2.9043c-0.6084,0.44727-1.24023,0.85547-1.89648,1.21875l-0.00684,1.68555H410.54102z"
      />
    <path class="spa-i-0" d="M412.53125,290.66016l-1.95801,0.53711l-0.0332,4.56445c0.94043,0.29297,1.65527,0.98535,1.94629,1.87012
      l3.59375,0.01074l0.57031-1.86816h5.28223l0.55957,1.86816h3.05469c0.02441-0.06738,0.06055-0.12891,0.09277-0.19434
      c0.01465-0.03809,0.03613-0.0752,0.05176-0.1123c0.02539-0.04688,0.04395-0.0957,0.06836-0.14063l-4.7666-5.30469l-1.86914,0.80664
      l-3.4248-3.79492l1.11914-1.63379l-3.13086-3.51172c-0.53711,0.62402-1.1084,1.20996-1.71094,1.75293l0.55469,0.15918V290.66016z"
      />
    <polygon class="spa-i-0" points="421.68457,307.2998 420.24316,306.13672 418.25,308.35352 419.69434,309.51563 	"/>
    <path class="spa-i-3" d="M416.60547,280.61133h5.27148l0.56934-1.86914h3c0.31348,0.89453,1.05273,1.58789,2.00195,1.86914
      l0.0127,2.14844l-1.96973,0.55859v4.99023l1.96973,0.53711l-0.02246,6.93945c-0.1123,0.04492-0.21289,0.08984-0.31348,0.13477
      l-4.67871-5.19336l1.11914-1.62305l-3.42285-3.80469l-1.87012,0.7959l-3.43555-3.80762
      c-0.3623,0.51074-0.74609,1.00098-1.14941,1.46973l3.13086,3.51172l-1.11914,1.63379l3.4248,3.79492l1.86914-0.80664
      l4.7666,5.30469c-0.02441,0.04492-0.04297,0.09375-0.06836,0.14063c0.46387-0.96484,1.46289-1.6377,2.63477-1.69336
      l-0.00977-8.40625h-0.87793v-2.9209h0.87109l0.04004-4.62988l-0.04004-4.7373c-1.33887,0.01465-2.46387,0.83789-2.86328,1.99316h-3
      l-0.56934-1.86914h-3.75c-0.41699,1.66797-1.0166,3.24902-1.77246,4.71582L416.60547,280.61133z M419.88379,290.6709
      l-1.98145-2.21582l1.44434-1.16309l1.97949,2.2041L419.88379,290.6709z M417.73535,276.94141h3.08887v1.80078h-3.08887V276.94141z"
      />
    <path class="spa-i-3" d="M427.48145,314.31738v-2.91992h0.86035l-0.01074-9.9873c-0.29785-0.0127-0.58008-0.06641-0.84961-0.1543
      v8.5293l-1.96875,0.53711v4.99023l1.96875,0.54785l-0.00977,6.29004c-0.80566,0.26758-1.42188,0.82715-1.76855,1.56641
      c-1.04199-0.14551-2.05469-0.37793-3.03906-0.6875l-0.00391,0.01563c-0.08984-0.0293-0.17676-0.06836-0.26563-0.09961
      c-0.42676-0.14063-0.8457-0.29395-1.25781-0.46875l-0.9375,1.5957c-0.93652-0.4043-1.83301-0.87891-2.68457-1.40918
      l0.92578-1.58691c-1.35352-0.86035-2.57422-1.90234-3.62598-3.0791c0.7373-0.94824,0.76074-2.21484,0.12402-3.18652l3.69141-4.1084
      l1.87012,0.79395l3.4248-3.80371l-1.11914-1.6123l4.42285-4.91113c-0.62305-0.2627-1.12695-0.71777-1.44629-1.2832
      c0.0127,0.02051,0.02051,0.04199,0.03418,0.06152l-4.4668,4.95703l-1.86816-0.80469l-3.42383,3.80566l1.11914,1.62207
      l-3.7168,4.11719c-0.51367-0.19043-1.08594-0.24414-1.63281-0.17871c-0.4502-1.04395-0.78125-2.13672-0.99805-3.26953
      l1.96094-0.64746c0,0-0.15137-1.96191-0.20215-2.49902c-0.00391-0.46484-0.06641-2.49219-0.06641-2.49219l-1.95898-0.45801
      l-0.00977,2.97754c0,0.63477,0.02734,1.26563,0.08984,1.88574c-0.00977-0.0918-0.02832-0.18262-0.03613-0.27441l-1.89551-0.00977
      c-0.04883-0.52539-0.07324-1.05566-0.07324-1.60156v-1.31934l1.91504,0.00977l0.00977-4.52148
      c0.88281-0.29102,1.58887-0.96289,1.89063-1.8125l3.62695,0.01172l0.57031,1.86719h5.28223l0.55957-1.86719l3.08496-0.01172
      c-0.09766-0.2832-0.16504-0.58008-0.16504-0.89551c0-0.38672,0.08301-0.75293,0.22754-1.08984
      c-0.03223,0.06543-0.06836,0.12695-0.09277,0.19434h-3.05469l-0.55957-1.86816h-5.28223l-0.57031,1.86816l-3.59375-0.01074
      c-0.29102-0.88477-1.00586-1.57715-1.94629-1.87012l0.0332-4.56445l1.95801-0.53711v-4.99121l-0.55469-0.15918
      c-0.46094,0.41602-0.94043,0.80566-1.43555,1.16992v2.9043h-1.90332l0.00684-1.68555
      c-0.63281,0.35059-1.28516,0.66016-1.95508,0.92773v1.83398l1.9707,0.53711l-0.01172,4.53125
      c-1.23145,0.36914-2.14844,1.48828-2.14844,2.77539c0,1.29883,0.90625,2.39551,2.1377,2.76563v2.94238l-1.95898,0.64941
      l0.2793,4.98047l1.96387,0.43457c0.26367,1.38965,0.68848,2.72949,1.24902,3.99512l-0.15723,0.18164
      c-1.10742,1.20898-0.9502,3.03125,0.33496,4.07129c0.83008,0.68262,1.93652,0.85059,2.91211,0.56055
      c0.83789,0.94727,1.78516,1.81836,2.80664,2.59766l-0.4248,1.97949c1.41113,0.95117,2.96484,1.75684,4.6123,2.36035l1.4541-1.36621
      c-0.04883-0.01563-0.09277-0.03711-0.14063-0.05371c1.25293,0.43066,2.55957,0.74023,3.92285,0.92871
      c0.29102,1.24609,1.42578,2.18066,2.81348,2.25488l0.04492-12.16211l-0.00391-1.30664H427.48145z M420.24316,306.13672
      l1.44141,1.16309l-1.99023,2.21582l-1.44434-1.16211L420.24316,306.13672z M417.60156,297.64258h3.18945v1.79102h-3.18945
      V297.64258z M408.25586,298.50391c0-0.68164,0.58203-1.24121,1.30957-1.24121c0.71777,0,1.29883,0.55957,1.29883,1.24121
      s-0.58105,1.23047-1.29883,1.23047C408.83789,299.73438,408.25586,299.18555,408.25586,298.50391z M408.79785,309.44043
      c0.00293,0.0293,0.00684,0.05859,0.01074,0.08887C408.80566,309.49902,408.80078,309.46973,408.79785,309.44043z
      M410.79004,310.01465c-0.0127-0.06738-0.02246-0.13477-0.03418-0.2041
      C410.76758,309.87988,410.77734,309.94727,410.79004,310.01465z"/>
    <path class="spa-i-3" d="M418.42969,318.80469l-1.41016,1.24219c1.58301,1.23145,3.40723,2.22656,5.375,2.89844
      c0.08887,0.03027,0.17969,0.05566,0.26953,0.08398l0.43262-1.88477C421.39453,320.60645,419.81738,319.82324,418.42969,318.80469z"
      />
    <path class="spa-i-3" d="M438.27148,318.80469c-1.38965,1.01855-2.9668,1.80176-4.66895,2.33984l0.43359,1.88477
      c0.0918-0.0293,0.18359-0.05371,0.27441-0.08496c1.96484-0.67188,3.78613-1.66699,5.36914-2.89746L438.27148,318.80469z"/>
    <path class="spa-i-3" d="M428.38867,284.31543h0.87207v2.9209h-0.87695h-0.06738l0.00977,8.40625
      c0.05176-0.00195,0.09961-0.01367,0.15332-0.01367c0.27148,0,0.53027,0.04395,0.7832,0.10742l-0.02344-6.89063l1.96973-0.53711
      v-4.99023l-1.96973-0.55859l0.01074-2.14844c0.95313-0.28125,1.69141-0.97461,2.00488-1.86914h2.99707l0.57227,1.86914h5.26953
      l0.57227-1.86914h3.45898c0.08789,0.24609,0.18848,0.46973,0.32324,0.68164l-6.02051,6.6709l-1.87012-0.7959l-3.42383,3.80469
      l1.11816,1.62305l-4.6123,5.12109c0.60742,0.2373,1.10938,0.6582,1.44434,1.18652l4.62305-5.14355l1.86914,0.80664L441,288.90234
      l-1.11816-1.63379l5.97363-6.70215c0.0791,0.02148,0.22559,0.02148,0.30371,0.04492l-0.03418,4.49805l-1.95801,0.55957v4.99121
      l1.95801,0.53711l0.03418,4.56445c-0.93945,0.29297-1.6543,0.98535-1.94629,1.87012l-3.59277,0.01074l-0.57129-1.86816h-5.28027
      l-0.56055,1.86816h-2.83594c0.09668,0.28418,0.16211,0.58008,0.16211,0.89551s-0.06641,0.61426-0.16406,0.89746l2.83789,0.00977
      l0.56055,1.86719h5.28027l0.57129-1.86719l3.62598-0.01172c0.30176,0.84961,1.00781,1.52148,1.89063,1.8125l0.0127,4.52148
      l1.91309-0.00977v1.31934c0,0.5459-0.02441,1.07617-0.07324,1.60156l-1.89551,0.00977
      c-0.00977,0.08887-0.02637,0.1748-0.03516,0.26465c0.06055-0.61719,0.08887-1.24414,0.08887-1.87598l-0.01074-2.97754
      l-1.95801,0.45801c0,0-0.0625,2.02734-0.06641,2.49219c-0.05078,0.53711-0.20215,2.49902-0.20215,2.49902l1.96191,0.64746
      c-0.2168,1.13281-0.54688,2.22559-0.99902,3.26953c-0.5498-0.06543-1.11816-0.01172-1.63281,0.17871l-3.71484-4.11719
      l1.11719-1.62207l-3.42383-3.80566l-1.86914,0.80469l-4.31445-4.78906c-0.35449,0.51563-0.88086,0.90723-1.5,1.12305l4.3584,4.8418
      l-1.11816,1.6123l3.42578,3.80371l1.86816-0.79395l3.69336,4.1084c-0.63867,0.97168-0.61621,2.23828,0.12207,3.18652
      c-1.05273,1.17676-2.27051,2.21875-3.625,3.0791l0.92578,1.58691c-0.85352,0.53027-1.74902,1.00488-2.68555,1.40918l-0.9375-1.5957
      c-0.41016,0.1748-0.82813,0.32715-1.25195,0.46777c-0.0918,0.03125-0.17871,0.07129-0.26953,0.10059l-0.00488-0.01563
      c-0.9834,0.30957-1.99902,0.54199-3.03906,0.6875c-0.34766-0.73926-0.96191-1.29883-1.76758-1.56641l-0.01172-6.29004
      l1.96875-0.54785v-4.99023l-1.96875-0.53711v-8.45508c-0.23828,0.05664-0.4834,0.0957-0.73828,0.0957
      c-0.05176,0-0.09863-0.01367-0.14844-0.01563l0.01074,9.9873h0.01758h0.8584v2.91992h-0.86426h-0.00781l0.00391,1.30664
      l0.04492,12.16211c1.38965-0.07422,2.52539-1.00879,2.81445-2.25488c1.3623-0.18848,2.67188-0.49902,3.92383-0.92871
      c-0.04883,0.01563-0.09473,0.03809-0.1416,0.05371h0.00098l1.45313,1.36621c1.64551-0.60352,3.20117-1.40918,4.6123-2.36035
      l-0.42578-1.97949c1.02246-0.7793,1.96875-1.65039,2.80957-2.59766c0.97461,0.29004,2.08203,0.12207,2.9082-0.56055
      c1.28711-1.04004,1.44531-2.8623,0.33691-4.07129l-0.15625-0.18164c0.55957-1.26563,0.98535-2.60547,1.24805-3.99512
      l1.96289-0.43457l0.28027-4.98047l-1.95898-0.64941v-2.94238c1.23047-0.37012,2.13672-1.4668,2.13672-2.76563
      c0-1.28711-0.91602-2.40625-2.14746-2.77539l-0.01074-4.53125l1.96973-0.53711v-4.99121l-1.96973-0.55957v-4.53125
      c1.20898-0.39258,2.05859-1.47754,2.05859-2.74414c0-1.59961-1.36719-2.88574-3.05664-2.88574
      c-1.36426,0-2.5166,0.82715-2.91797,1.99316h-3.45898l-0.57227-1.86914h-5.26953l-0.57227,1.86914h-2.99707
      c-0.40039-1.15527-1.52539-1.97852-2.86523-1.99316l-0.04004,4.7373l-0.04004,4.62988H428.38867z M436.81641,290.6709
      l-1.44336-1.1748l1.97949-2.2041l1.44336,1.16309L436.81641,290.6709z M439.09863,299.43359h-3.18945v-1.79102h3.18945V299.43359z
      M437.00488,309.51563l-1.99121-2.21582l1.44238-1.16309l1.99219,2.2168L437.00488,309.51563z M445.94238,309.83398
      c-0.01172,0.05566-0.01953,0.1084-0.02832,0.16406C445.92285,309.94238,445.93066,309.88965,445.94238,309.83398z
      M447.90234,309.4502c-0.00391,0.02441-0.00684,0.04883-0.00977,0.07227
      C447.89551,309.49902,447.89844,309.47461,447.90234,309.4502z M448.44434,298.50391c0,0.68164-0.58203,1.23047-1.30957,1.23047
      c-0.71777,0-1.2998-0.54883-1.2998-1.23047s0.58203-1.24121,1.2998-1.24121
      C447.8623,297.2627,448.44434,297.82227,448.44434,298.50391z M448.05176,286.66504l0.01074,2.91895h-1.90332v-2.91895H448.05176z
      M435.87402,276.94141h3.09082v1.80078h-3.09082V276.94141z"/>
    <path class="spa-i-10" d="M425.41211,298.53809c0,0.31543,0.06738,0.6123,0.16504,0.89551
      c0.05566,0.1582,0.12305,0.30859,0.20508,0.45215c0.31934,0.56543,0.82324,1.02051,1.44629,1.2832
      c0.08203,0.03418,0.16895,0.05957,0.25293,0.08691c0.26953,0.08789,0.55176,0.1416,0.84961,0.1543
      c0.0498,0.00195,0.09668,0.01563,0.14844,0.01563c0.25488,0,0.5-0.03906,0.73828-0.0957
      c0.10742-0.02539,0.21289-0.05664,0.31738-0.0918c0.61914-0.21582,1.14551-0.60742,1.5-1.12305
      c0.14453-0.20801,0.25-0.43945,0.33496-0.67969c0.09766-0.2832,0.16406-0.58203,0.16406-0.89746s-0.06543-0.61133-0.16211-0.89551
      c-0.07227-0.21387-0.16797-0.41797-0.28809-0.6084c-0.33496-0.52832-0.83691-0.94922-1.44434-1.18652
      c-0.12012-0.04688-0.24902-0.07813-0.37695-0.11133c-0.25293-0.06348-0.51172-0.10742-0.7832-0.10742
      c-0.05371,0-0.10156,0.01172-0.15332,0.01367c-1.17188,0.05566-2.1709,0.72852-2.63477,1.69336
      c-0.01563,0.03711-0.03711,0.07422-0.05176,0.1123C425.49512,297.78516,425.41211,298.15137,425.41211,298.53809z"/>
    <path class="spa-i-0" d="M344.31299,268.74902v1.00586v0.03418h6.31104h5.9541h6.58301h5.94971h6.56934h5.95068h0.79199
      c-0.00098-0.08984-0.00537-0.17773-0.00537-0.26855c0-2.97949,0.56934-5.80566,1.58594-8.35938h-6.19678h-1.81348h-6.98242
      h-1.80127h-10.60986v-8.54004h4.14063v-16.5498h-2.06934v-8.26953h3.35693h0.52588v-2.85352h-1.81348v-4.12988h1.81348v1.02979
      h1.0293v-1.02979h2.06982v1.02979h1.02979v-1.02979h1.81396v4.12988h-1.81396v2.85352h0.25732h3.11133h0.51514v-9.05371h-2.06982
      v-4.14014h2.06982v1.04004h1.03027v-1.04004h1.8125v1.04004h1.04053v-1.04004h2.06006v4.14014h-2.06006v9.05371h0.51465h2.84229
      h0.51465v-2.85352h-1.81152v-4.12988h1.81152v1.02979h1.29883v-1.02979h1.80176v1.02979h1.04053v-1.02979h1.81299v4.12988h-1.81299
      v2.85352h0.51416h3.36816v8.26953h-2.06934v16.5498h4.12842v1.71582c3.18994-3.25098,7.40869-5.24316,12.0415-5.29102v-41.61182
      h-15.44434h-40.68799v36.97021v10.96094V268.74902z"/>
    <path class="spa-i-3" d="M388.40381,254.33691v-1.71582h-4.12842v-16.5498h2.06934v-8.26953h-3.36816l-0.51416,5.16943h-4.14111
      l-0.51465-5.16943h-2.84229l-0.51465,5.16943h-4.14063l-0.25781-5.16943h-3.11133l-0.25732,5.16943h-4.12891l-0.52588-5.16943
      h-3.35693v8.26953h2.06934v16.5498h-4.14063v8.54004h10.60986v-5.36035l-0.78418-0.07813
      c0-1.03027,0.25732-2.58496,1.04199-3.10156l0.68164,0.53125c0.51807-0.60938,1.31689-1.14258,2.53857-1.40137l-0.37842-0.68457
      c0.51465-0.25781,1.55518-0.51367,2.0708-0.51367c0.77197,0,1.8125,0.25586,2.32666,0.51367l-0.3999,0.7168
      c1.21533,0.27148,2.01758,0.79297,2.53955,1.38672l0.70361-0.54883c0.77148,0.5166,1.0293,2.07129,1.0293,3.10156l-0.77246,0.07617
      v5.3623h6.19678C385.04102,258.55273,386.5459,256.23047,388.40381,254.33691z M382.20557,241.24219v5.69531h-5.6958v-0.52539
      v-5.16992c0-1.5459,1.04004-2.84375,2.85205-2.84375C381.16357,238.39844,382.20557,239.69629,382.20557,241.24219z
      M362.81152,241.24219c0-1.5459,1.0293-2.84375,2.84131-2.84375c1.81445,0,2.84375,1.29785,2.84375,2.84375v5.16797v0.52734
      h-5.68506V241.24219z"/>
    <polygon class="spa-i-3" points="366.68262,227.80176 366.68262,224.94824 368.49658,224.94824 368.49658,220.81836 
      366.68262,220.81836 366.68262,221.84814 365.65283,221.84814 365.65283,220.81836 363.58301,220.81836 363.58301,221.84814 
      362.55371,221.84814 362.55371,220.81836 360.74023,220.81836 360.74023,224.94824 362.55371,224.94824 362.55371,227.80176 	"/>
    <polygon class="spa-i-3" points="374.44971,218.74805 376.50977,218.74805 376.50977,214.60791 374.44971,214.60791 
      374.44971,215.64795 373.40918,215.64795 373.40918,214.60791 371.59668,214.60791 371.59668,215.64795 370.56641,215.64795 
      370.56641,214.60791 368.49658,214.60791 368.49658,218.74805 370.56641,218.74805 370.56641,227.80176 374.44971,227.80176 	"/>
    <polygon class="spa-i-3" points="382.4624,227.80176 382.4624,224.94824 384.27539,224.94824 384.27539,220.81836 382.4624,220.81836 
      382.4624,221.84814 381.42188,221.84814 381.42188,220.81836 379.62012,220.81836 379.62012,221.84814 378.32129,221.84814 
      378.32129,220.81836 376.50977,220.81836 376.50977,224.94824 378.32129,224.94824 378.32129,227.80176 	"/>
    <path class="spa-i-3" d="M369.01074,256.50586c0-0.7832,0.52588-3.11133,3.36914-3.11133h-0.7832l-0.90918-1.64355
      c-1.22168,0.25879-2.02051,0.79199-2.53857,1.40137l1.646,1.2832c-0.2583,0.25684-0.78418,1.0293-0.78418,1.54395l-1.80127-0.17871
      v5.36035h1.80127V256.50586z"/>
    <path class="spa-i-3" d="M377.80664,255.79883l-1.81348,0.18066c0-0.51465-0.51416-1.28711-0.77197-1.54395l1.625-1.26563
      c-0.52197-0.59375-1.32422-1.11523-2.53955-1.38672l-0.89746,1.61133h-1.0293c3.09912,0,3.61328,2.32813,3.61328,3.11133v4.65527
      h1.81348V255.79883z"/>
    <path class="spa-i-3" d="M369.79492,254.43555l-1.646-1.2832l-0.68164-0.53125c-0.78467,0.5166-1.04199,2.07129-1.04199,3.10156
      l0.78418,0.07813l1.80127,0.17871C369.01074,255.46484,369.53662,254.69238,369.79492,254.43555z"/>
    <path class="spa-i-3" d="M371.59668,253.39453h0.7832h1.0293l0.89746-1.61133l0.3999-0.7168
      c-0.51416-0.25781-1.55469-0.51367-2.32666-0.51367c-0.51563,0-1.55615,0.25586-2.0708,0.51367l0.37842,0.68457
      L371.59668,253.39453z"/>
    <path class="spa-i-3" d="M375.99316,255.97949l1.81348-0.18066l0.77246-0.07617c0-1.03027-0.25781-2.58496-1.0293-3.10156
      l-0.70361,0.54883l-1.625,1.26563C375.479,254.69238,375.99316,255.46484,375.99316,255.97949z"/>
    <path class="spa-i-3" d="M368.49658,246.9375v-0.52734v-5.16797c0-1.5459-1.0293-2.84375-2.84375-2.84375
      c-1.81201,0-2.84131,1.29785-2.84131,2.84375v5.69531H368.49658z M363.32471,241.49805c0-1.28613,0.77295-2.58398,2.32813-2.58398
      c1.55664,0,2.32813,1.29785,2.32813,2.58398v4.91211h-4.65625V241.49805z"/>
    <path class="spa-i-3" d="M379.36182,238.39844c-1.81201,0-2.85205,1.29785-2.85205,2.84375v5.16992v0.52539h5.6958v-5.69531
      C382.20557,239.69629,381.16357,238.39844,379.36182,238.39844z M381.68945,241.49805v4.91211h-4.65576v-4.91211
      c0-1.28613,0.77295-2.58398,2.32813-2.58398C380.90674,238.91406,381.68945,240.21191,381.68945,241.49805z"/>
    <polygon class="spa-i-3" points="362.55371,232.97119 366.68262,232.97119 366.93994,227.80176 366.68262,227.80176 
      362.55371,227.80176 362.02783,227.80176 	"/>
    <polygon class="spa-i-3" points="370.30908,232.97119 374.44971,232.97119 374.96436,227.80176 374.44971,227.80176 
      370.56641,227.80176 370.05127,227.80176 	"/>
    <polygon class="spa-i-3" points="378.32129,232.97119 382.4624,232.97119 382.97656,227.80176 382.4624,227.80176 378.32129,227.80176 
      377.80664,227.80176 	"/>
    <path class="spa-i-8" d="M375.99316,256.50586c0-0.7832-0.51416-3.11133-3.61328-3.11133c-2.84326,0-3.36914,2.32813-3.36914,3.11133
      v4.65527h6.98242V256.50586z"/>
    <path class="spa-i-8" d="M367.98096,246.41016v-4.91211c0-1.28613-0.77148-2.58398-2.32813-2.58398
      c-1.55518,0-2.32813,1.29785-2.32813,2.58398v4.91211H367.98096z"/>
    <path class="spa-i-8" d="M379.36182,238.91406c-1.55518,0-2.32813,1.29785-2.32813,2.58398v4.91211h4.65576v-4.91211
      C381.68945,240.21191,380.90674,238.91406,379.36182,238.91406z"/>
    <path class="spa-i-7" d="M436.66211,261.20801c-0.07813-0.06543-0.15234-0.11816-0.21484-0.14746
      c-0.05957-0.02734-0.14453-0.05469-0.2373-0.08398c0.08887,0.03906,0.16699,0.07031,0.21484,0.08398
      C436.47754,261.0791,436.56445,261.13672,436.66211,261.20801z"/>
    <path class="spa-i-7" d="M413.62012,219.36475c0.21387,0.14648,0.44336,0.35596,0.79102,0.37891
      c0.16504,0.0083,0.3584-0.00244,0.54102-0.00781c-0.16602-0.00635-0.37305-0.02051-0.57422-0.04834
      C414.12109,219.646,413.81445,219.48242,413.62012,219.36475z"/>
    <path class="spa-i-7" d="M432.9082,216.42871c0.59082,0.3374,1.35938,0.80762,2.09473,1.35693
      c0.16504,0.12305,0.3291,0.25391,0.49023,0.3877c-0.16699-0.1377-0.33691-0.2749-0.51172-0.41064
      C434.26367,217.21484,433.54785,216.76025,432.9082,216.42871z"/>
    <path class="spa-i-7" d="M435.53125,218.20703c0.4502,0.38135,0.86523,0.7876,1.2041,1.14014
      C436.39551,218.97949,435.98828,218.59131,435.53125,218.20703z"/>
    <path class="spa-i-7" d="M415.95605,229.3125c0,0-0.02734-0.00146-0.07031-0.00342
      C415.93652,229.31201,415.95605,229.3125,415.95605,229.3125z"/>
    <path class="spa-i-7" d="M429.20313,222.14844c0.00195-0.01953,0.00391-0.03711,0.00684-0.05566l-0.00391,0.0127L429.20313,222.14844z"
      />
    <path class="spa-i-7" d="M400.44531,207.43408v41.61182c0.05566-0.00098,0.11133-0.00488,0.16797-0.00488
      c10.0498,0,18.19629,9.16992,18.19629,20.47949c0,0.09082-0.00586,0.17871-0.00684,0.26855h37.9541v-0.03418v-14.4541V244.3457
      v-36.91162h-41.15234h-15.13672H400.44531z M407.89844,226.13379c0,0-0.07813-1.01807,0.40234-1.56641
      c0.47168-0.53711,1.33301-0.66064,1.33301-0.66064s0.37988,0.63818,0.37988,0.90674c0,0.26807,0.24609,0.89404,0.24609,0.89404
      s-0.7832-0.17773-1.29688-0.17773C408.45801,225.52979,407.89844,226.13379,407.89844,226.13379z M411.21094,234.87305
      c0.83008-0.14502,1.29883,0.3584,1.29883,0.3584s-0.22461,0.45801-0.34668,0.6377
      c-0.12207,0.17871-0.50488,0.50488-0.50488,0.50488s-0.29102-0.41504-0.69434-0.50488
      c-0.41113-0.1001-1.11719,0.08984-1.11719,0.08984S410.38281,235.02979,411.21094,234.87305z M410.65234,239.91016
      c-0.44727-0.06738-1.01855,0.41406-1.01855,0.41406s0.03223-0.91797,0.62598-1.39844
      c0.60449-0.48242,1.29785-0.37109,1.29785-0.37109s0.29199,0.51465,0.19141,0.93945
      c-0.03516,0.14746-0.12402,0.71777-0.12402,0.71777S411.08789,239.96484,410.65234,239.91016z M418.1377,253.93164
      c-0.56934-0.05566-1.19629,0.62695-1.19629,0.62695s-0.0332-0.98535,0.46875-1.52148
      c0.5166-0.54883,1.55664-0.39258,1.55664-0.39258s0.15723,0.50391,0.21191,0.77246
      c0.06738,0.28027,0.16797,0.87305,0.16797,0.87305S418.70898,253.9873,418.1377,253.93164z M422.25684,258.09473
      c0,0-0.79395-0.26855-1.17383-0.83984c-0.38281-0.57031,0.13379-1.28613,0.13379-1.28613l1.63281,0.33594
      c0,0-0.64941,0.51367-0.75,0.89453C421.96484,257.67969,422.25684,258.09473,422.25684,258.09473z M414.63477,222.84424
      c0.22461,0.51514,0.79492,1.33154,1.29883,1.65527c0.50391,0.3252,1.45508,0.5708,2.05957,0.95117
      c0.59277,0.39307,1.99219,1.50098,2.46094,1.62305c0.47168,0.12354,0.95117,0.3584,0.95117,0.3584s0.53906-0.23486,1.2666-0.23486
      c0.72656,0,2.3916,0.11133,3.03125-0.15625c0.63867-0.26904,1.45508-0.7168,1.20703-1.2876
      c-0.25586-0.5708-1.64258-1.07471-1.51855-1.52197c0.0752-0.27637,0.2998-0.39453,0.66504-0.45313l-0.0957,0.00635l0.36914-0.59424
      l0.37012,0.53418c0.05859-0.00342,0.11523-0.00586,0.17871-0.0083c0.00684,0,0.01465,0,0.02246,0l0.41406-0.54834l0.47559,0.53125
      c0.5752-0.04102,1.10059-0.22217,1.28711-0.90967c-0.29395-0.03564-1.43359-0.17383-1.9873-0.20264
      c-0.62793-0.02344-2.94336,0.12305-3.60352-0.07861c-0.40332-0.13477-1.33203-0.66016-2.18262-1.7124
      s-1.99023-1.46631-1.99023-1.46631s1.16309-0.69336,2.68457-0.44775c0.63672,0.08936,0.98535,0.55957,1.86914,1.32129
      c0.88379,0.73877,1.73438,1.12939,2.68359,1.39893c0.84766,0.23828,2.36133,0.1499,2.68848,0.12744
      c0.01855-0.62549-0.19434-1.02588-1.0293-1.21289c-0.21387-0.04736-0.41309-0.0874-0.60352-0.12549l0.78125,0.19189
      l-0.66113,0.51563l-0.15527-0.71484c-0.33691-0.06787-0.64063-0.1333-0.88965-0.22705l0.7002,0.24707l-0.83105,0.42529
      l-0.08691-0.74854l-0.00195-0.00146h0.00195v0.00146l0.21777,0.07617c-0.40137-0.14941-0.67871-0.37402-0.79004-0.8042
      c-0.01465-0.05664-0.02734-0.10254-0.04297-0.15479c-0.05566-0.16699-0.12695-0.38184-0.14648-0.47217
      c-0.0332-0.14551-0.13379-0.32471-0.13379-0.32471s0.01563,0,0.04199,0.00049c-0.03516-0.15918-0.01953-0.26465,0.0918-0.36963
      c0.25781-0.23535,0.69434-0.35742,1.57715-0.41309c0.89746-0.06787,1.9043-0.06787,2.19531-0.28027
      c0.27832-0.20215,0.33496-0.77246,0.68164-1.01855c0.2627-0.17822,1.1084-0.34277,1.50293-0.4126
      c0.0166-0.0498,0.23633-0.68896,0.32129-1.32275c0.08887-0.66602-0.08594-1.44238-0.08887-1.45264
      c-0.01953,0.00098-0.03809,0.00342-0.05664-0.00195c-0.12207-0.04443-0.16797-0.20117-0.10059-0.36963
      c0.01465-0.04639,0.03809-0.08545,0.06543-0.11914c-0.05273-0.17627-0.13281-0.43701-0.22168-0.60791
      c-0.09082-0.2124-0.43652-0.48145-0.43652-0.48145c0.03223-0.05518,0.4707-0.21191,0.97168,0.10107
      c0.36035,0.29346,0.09082,0.78174-0.00391,0.92725c0.01855,0.0166,0.03223,0.03711,0.04199,0.06006l0.76855-0.05908
      l0.10156,0.59424l-0.46973,0.41406l-0.41602-0.36719c0.10059,0.50732,0.3457,1.25,0.80762,1.13965
      c0.08887-0.02295,0.18945-0.1123,0.18945-0.12354c-0.00977,0.01123,0.05664,0.22363,0.14551,0.30176
      c0.17871,0.15723,0.53711,0.08936,0.78418-0.19043l0.11035-0.21191l0.0918-0.02051
      c-0.00586-0.01123-0.01367-0.02246-0.01758-0.03369l-0.79004-0.10352l0.02246-0.604l0.56055-0.30127l0.46191,0.62012
      c0.00098,0,0.00098,0,0.00293,0l0.10547-0.84424l0.66016-0.01074l0.30273,0.55908l-0.72754,0.48779l0.79395,0.09326
      l-0.0332,0.60449l-0.54785,0.30273l-0.45605-0.59961c-0.00781,0.00146-0.01465,0-0.02148,0.00146l0.00781,0.11621
      c-0.05762,0.04492-0.0918,0.13477-0.125,0.20264c-0.20117,0.32373-0.13379,0.71533,0.15723,0.88379
      c0.17969,0.11182,0.38086,0.11182,0.57129,0.01074c-0.02148,0.16797,0.0332,0.33545,0.15625,0.45898
      c0.23438,0.23535,0.67188,0.20068,0.96191-0.07813c0.12793-0.11621,0.26074-0.32813,0.28418-0.37842l-0.75293-0.19238
      l0.11133-0.60449l0.59375-0.22363l0.36621,0.67236l0.23828-0.80713l0.66016,0.06787l0.21094,0.59229l-0.79297,0.3916
      c0.00098,0.00244,0.00195,0.00391,0.00195,0.00684l0.75879,0.19531l-0.1123,0.59326l-0.59277,0.22363l-0.31543-0.58301
      c-0.0752,0.06104-0.21973,0.20215-0.30078,0.32471c-0.22461,0.31348-0.16699,0.72852,0.12402,0.89551
      c0.14355,0.08984,0.32422,0.10156,0.50195,0.05713c-0.06543,0.18994-0.02246,0.37988,0.125,0.51465
      c0.23438,0.24561,0.66016,0.22314,0.96191-0.03418c0.04395-0.04443,0.13379-0.10059,0.16895-0.14551l0.12207-0.01758
      c-0.00195-0.00928-0.00391-0.01855-0.00391-0.02783l-0.72363-0.28955l0.20215-0.58252l0.62695-0.14502l0.25391,0.70557
      c0.00098,0,0.00293,0.00098,0.00488,0.00098l0.34473-0.77393l0.6377,0.15576l0.12305,0.61621l-0.8252,0.28369l0.72363,0.29883
      l-0.18945,0.58154l-0.62695,0.14502l-0.26563-0.71045c-0.01074-0.00049-0.02148-0.00537-0.03418-0.00781l-0.00195,0.09229
      l-0.20117,0.14502c-0.22559,0.29102-0.22559,0.62598-0.02344,0.77148c0.10059,0.05664,0.3252,0.07813,0.33496,0.06787
      c-0.00977,0-0.08887,0.11182-0.08887,0.20117c-0.0166,0.45605,0.80469,0.52734,1.35254,0.51172l-0.47852-0.32178l0.32422-0.51416
      l0.63867-0.03467l0.09668,0.72314c0.0293,0.00635,0.05664,0.0166,0.07813,0.03174c0.13379-0.11865,0.5752-0.46924,0.94336-0.20605
      c0.44727,0.41406,0.38086,0.83984,0.3125,0.88428c0,0-0.3584-0.25684-0.58008-0.30127
      c-0.20117-0.0415-0.48828-0.06641-0.68066-0.08105c-0.03125,0.03516-0.06836,0.06836-0.11621,0.09131
      c-0.1543,0.09033-0.33398,0.0791-0.39063-0.02246c-0.00879-0.01367-0.00977-0.02832-0.01367-0.04395
      c-0.07031,0.00146-0.86621,0.02051-1.50781,0.22314c-0.66992,0.21289-1.32031,0.57129-1.32031,0.57129
      c-0.00586-0.00928-0.0127-0.02051-0.01953-0.03076c0.00879,0.27588,0.03027,0.84521,0.08594,1.396
      c0.06641,0.75-0.24512,1.38818-0.12402,1.80029c0.12402,0.41455,0.18066,0.73926,0.33691,1.0415
      c0.15625,0.29102,0.23535,1.05176,0.23535,1.05176s-0.4248-0.31348-0.82813-0.60449
      c-0.39355-0.30127-0.67285-0.49121-0.67285-0.49121s0.07813,0.80469,0.11328,1.15137
      c0.04492,0.34766,0.24609,0.99707,0.56934,1.38867c0.3252,0.36914,0.97559,0.99609,1.17676,1.48779
      c0.2002,0.50439,0.15723,1.61084,0.15723,1.61084s-0.51563-0.83936-0.97461-0.99609
      c-0.43652-0.15576-1.41016-0.69287-1.41016-0.69287s0.88574,0.88379,0.88574,1.73486c0,0.83984-0.35938,1.79053-0.35938,1.79053
      s-0.4043-0.76074-0.92871-1.25391c-0.52637-0.50391-1.2666-1.00586-1.2666-1.00586s0.58203,1.30908,0.58203,2.18066
      c0,0.88379-0.16602,2.76514-0.16602,2.76514s-0.4375-0.7168-0.88477-1.07568c-0.44043-0.34131-0.9541-0.63916-1.13086-0.86084
      c0.11914,0.17188,0.54102,0.83643,0.59375,1.28711c0.08008,0.5708,0.35742,2.63086,2.14844,5.24805
      c1.05273,1.5332,2.6748,4.21875,6.15527,3.33496c3.48145-0.87207,2.19434-5.56152,1.4541-7.74316
      c-0.72656-2.18262-1.09473-4.6001-1.05078-5.4502c0.04492-0.83887,0.64844-3.32324,0.57129-3.79346
      c-0.08008-0.45947-0.26953-2.23877,0.15625-3.6709c0.44727-1.48877,0.81641-2.07129,1.06348-2.68604
      c0.23438-0.61621,0.43652-0.96143,0.51465-1.49951c0.07715-0.53662,0.07715-1.5332,0.07715-1.5332s0.64844,1.18652,0.81641,1.61182
      c0.15723,0.42529,0.15723,1.67871,0.15723,1.67871s0.12305-1.25342,1.09668-1.86865
      c0.97461-0.61572,2.10449-1.26465,2.38477-1.61279c0.27832-0.3457,0.36719-0.56982,0.36719-0.56982
      s-0.08887,2.14844-0.69336,2.98779c-0.40234,0.54883-1.97949,2.33887-1.97949,2.33887s0.81543-0.31348,1.375-0.33594
      c0.57227-0.04443,0.97461,0,0.97461,0s-0.69434,0.53809-1.57617,1.83496c-0.89648,1.29834-0.52832,1.41162-1.17676,2.48535
      c-0.64941,1.07373-1.17383,1.11914-1.99023,1.76807c-1.20996,0.97266-0.56152,4.85645-0.4043,5.43848
      c0.16895,0.57031,2.27148,5.32568,2.31641,6.479c0.0332,1.15332,0.23633,3.72754-1.79102,5.37207
      c-1.29688,1.0625-3.43457,1.07422-3.92773,1.37695c-0.48145,0.3125-1.44238,1.26367-1.44238,3.25586
      c0,2.00195,0.71484,2.30469,1.28516,2.80859c0.57227,0.49219,1.29785,0.22266,1.45605,0.61523
      c0.16797,0.38086,0.24609,0.60352,0.49023,0.83984c0.23633,0.22461,0.40332,0.49219,0.32715,0.91699
      c-0.09082,0.42578-1.01855,1.37695-1.34473,2.07129c-0.32324,0.68359-0.97266,2.49414-0.97266,2.7627
      c0,0.26953-0.0791,1.10938,0.20117,1.53418c0,0,1.01953,1.18555,0.32422,1.41016c-0.06055,0.02148-0.12109,0.03027-0.18164,0.0332
      c0.04004,0.0127,0.07031,0.02148,0.07031,0.02148s-0.43652,0.30273-0.60449,0.80664
      c-0.1582,0.4707,0.35938,1.37695,0.35938,1.37695s-0.58301-0.05664-1.24414-0.63867
      c-0.66016-0.55859-0.3125-1.43164-0.3125-1.43164s0.72656-0.33594,0.85156-0.3584c0.03613-0.01074,0.13867,0.00879,0.2627,0.04004
      c-0.11621-0.05273-0.21387-0.09375-0.28613-0.07422c-0.60449,0.15723-0.92871,0.52734-1.09766,0.50586
      c-0.40137-0.08008-0.40137-0.28125-0.44727-0.85254c-0.03223-0.57129-0.01172-0.80566-0.18945-0.80566
      c-0.24609,0-0.37012,0.20117-0.41406,0.50391c-0.04492,0.30176-0.04492,0.99609-0.32324,0.99609
      c-0.08203,0-0.17188-0.04492-0.26758-0.10645c0.06641,0.05566,0.10938,0.09473,0.10938,0.09473s-0.76074,0.05664-1.17578,0.41406
      c-0.2793,0.24609-0.25879,1.1416-0.25879,1.1416s-0.43457-0.62598-0.69043-1.23047
      c-0.24902-0.60449,0.44727-1.32129,0.44727-1.32129s0.01074,0.00586,0.02637,0.01172
      c-0.0498-0.04688-0.08691-0.09766-0.09473-0.15625c-0.0332-0.30273,0.40137-0.95117,0.85059-1.0752
      c0.44824-0.11133,0.85059-0.33496,0.56934-0.57031c-0.29004-0.22363-0.56934-0.22363-0.84961,0
      c-0.24805,0.20801-0.74512,0.08301-0.83496-0.18848c-0.08789-0.04492-0.49609-0.24609-1.02344-0.32617
      c-0.60254-0.08984-1.42969,0.60352-1.42969,0.60352s0.41211-1.26367,0.91699-1.55469
      c0.5127-0.30273,1.42188-0.08984,1.42188-0.08984s0.12207,0.29102,0.1543,0.50391c0.01172,0.05566,0.01758,0.11426,0.02051,0.17383
      c0.01563-0.1582,0.02441-0.29785,0.00293-0.38672c-0.03418-0.18945-0.52637-0.57031,0.1123-0.91699
      c0.65918-0.34668,0.93945,0.30176,1.58887,0.19043c0.64844-0.11328,0.96289-0.34766,1.20801-0.72852
      c0.24707-0.38086,0.20215-1.18555-0.24512-1.67773c-0.43652-0.50488-0.88574-0.58203-1.05371-0.89648
      c-0.15527-0.30078-0.40137-1.0293-0.40137-1.0293s0.12207,1.34375,0.04297,1.5332
      c-0.07617,0.19141-0.04297,0.99609-0.04297,0.99609s-0.4375-0.50391-0.80566-0.88379
      c-0.3584-0.37988-0.72754-1.53223-0.72754-1.53223s-0.03418,1.07324-0.03418,1.49902c0,0.41309,0.48145,0.80566,0.32422,0.96191
      c-0.16699,0.14648-0.92871-0.80664-1.1416-0.96191c-0.20215-0.15723-0.84961-0.64941-1.12988-1.18652
      s-0.49121-1.29785-0.57031-1.57813c-0.07813-0.26855-0.21191-1.4668-0.07813-1.76758
      c0.20215-0.44727,0.52637-1.26465,0.52637-1.26465h-1.57715c-0.85156,0-1.45605-0.25781-1.78125,0.31348
      c-0.32422,0.57031-0.16797,1.72266,0.23535,3.22266c0.41406,1.48828,0.64941,2.21582,0.53711,2.48438
      c-0.12305,0.26953-0.64941,0.88477-0.84961,0.99609c-0.21289,0.12305-0.77148,0.07813-1.01953-0.03516
      c-0.23438-0.11133-0.6377-0.30078-1.40918-0.30078c-0.77051,0-1.25293,0.0332-1.5332-0.03223
      c-0.29199-0.0791-0.98535-0.42676-1.30957-0.34863c-0.3252,0.0791-0.88477,0.35938-0.72559,0.80566
      c0.24414,0.68262-0.2373,0.83887-0.57422,0.80566c-0.32324-0.04297-0.5918-0.15625-1.00488-0.26855
      c-0.4043-0.12305-1.00781,0-0.92871-0.4707c0.07715-0.45801,0.24512-0.49121,0.44629-0.82617
      c0.20313-0.3584,0.28027-0.58301,0.04492-0.60449c-0.28027-0.02344-0.57031-0.05664-0.78125,0.12305
      c-0.22656,0.17871-0.57324,0.57031-0.85156,0.42578c-0.29199-0.1582-0.51563-0.48145-0.51563-1.20898
      c0-0.71777-0.76074-1.34375-0.05566-1.30957c0.69336,0.03223,1.57813,0.53711,1.73535,0.14551
      c0.15723-0.39258,0.06738-0.57227-0.3125-0.87402c-0.38184-0.30078-0.85156-0.48145-0.34766-0.86035
      c0.06152-0.04883,0.11914-0.0918,0.1709-0.12988c-0.14551,0.08496-0.27344,0.15137-0.27344,0.15137s-0.24512-0.41406-0.50293-0.75
      c-0.25586-0.32422-1.24219-0.24707-1.24219-0.24707s0.82813-0.73633,1.62207-0.77148
      c0.78418-0.02148,1.35449,0.69434,1.35449,0.69434s-0.3623,0.48242-0.5625,0.65625c0.09863-0.0625,0.17285-0.11621,0.26074-0.20898
      c0.19043-0.20117,0.45996-0.88281,0.81738-0.7168c0.69238,0.33594,0.02246,0.81836,0.72559,1.59082
      c0.69629,0.77148,1.13184,1.05176,2.30664,0.92871c1.16406-0.125,1.48926-0.26953,1.48926-0.60449
      c0-0.32422-0.10156-0.91895-0.13574-1.16309c-0.02344-0.23633,0.16895-1.10742,0.16895-1.10742s-0.53906,0.33398-0.70508,0.65918
      c-0.14746,0.3252-0.4707,0.89551-0.4707,0.89551s-0.12207-0.67188-0.08887-1.2207c0.02051-0.32422,0.13281-0.88477,0.12305-0.99609
      c-0.03418-0.30273-0.25781-1.05176-0.25781-1.05176s-0.17871,0.81738-0.31348,1.05176
      c-0.12305,0.23438-0.17969,1.19727-0.17969,1.19727s-0.75-0.64844-0.53711-1.73438
      c0.14648-0.83887-0.13281-1.94727,0.12402-2.30469c0.24414-0.37012,0.84082-1.83496,2.30371-1.89258
      c1.45605-0.05469,2.58594,0.05762,3.10059,0.03418c0.50391-0.03418,2.30566-0.36914,2.30566-0.36914
      s-3.32324-1.70215-4.07324-2.21582c-0.76074-0.50391-1.93555-1.82324-2.31543-2.41602
      c-0.38086-0.60547-0.72852-1.76855-0.72852-1.76855s-0.59375,0.02246-1.1416,0.32324c-0.53711,0.30273-1.0752,0.75-1.38672,1.1084
      c-0.31348,0.3584-0.81836,1.17676-0.81836,1.17676s0.08984-1.05273,0.08984-1.37793c0-0.32422-0.06836-0.96289-0.06836-0.96289
      s-0.36816,1.43262-1.12891,1.9707c-0.76074,0.54688-1.64551,1.28613-1.64551,1.28613s0.0918-0.79395,0.0918-0.98438
      c0-0.17871,0.18848-1.1084,0.18848-1.1084s-0.53613,0.80664-1.35352,0.96289c-0.8291,0.14551-2.02734,0.12402-2.12695,0.62598
      c-0.08984,0.50391,0.22656,1.19727,0.0332,1.55664c-0.18945,0.35742-0.60449,0.60254-0.60449,0.60254
      s-0.46973-0.38965-0.88184-0.42285c-0.23145-0.02051-0.45508,0.04102-0.6084,0.0957
      c0.17676-0.01953,0.43359-0.04199,0.67383-0.04199c0.4043,0,0.81641,0.42676,0.81641,0.42676s-0.44629,0.39063-0.37988,0.83887
      c0.0459,0.30176,0.31348,0.89551,0.31348,0.89551s-0.81543-0.08984-1.26367-0.66113
      c-0.39551-0.50781-0.37598-1.29395-0.37109-1.40918c-0.07227-0.10352-0.30273-0.46094-0.18945-0.7168
      c0.12207-0.29102,0.75-0.73828,0.60352-0.92969c-0.16699-0.17676-0.66992,0.06836-0.9834,0.21387
      c-0.3125,0.15723-0.98535,0.30176-0.91699-0.21387c0.05566-0.50391,0.22168-0.80469,0.05566-1.16309
      c-0.15723-0.3584-0.05566-0.5918,0.20117-0.68164c0.24512-0.08984,1.25293,0.02148,1.35352-0.21387
      c0.08887-0.23438-0.24609-0.53516-0.91895-0.68262c-0.6582-0.15527-0.98242-0.54883-0.63574-0.87207
      c0.35645-0.33691,0.44629-0.42578,0.60449-0.7168c0.15625-0.30225,0.22266-0.85059,0.82715-0.5708
      c0.59277,0.26855,0.4707,0.92822,1.1084,1.12939c0.62598,0.21387,2.11523-0.08984,2.42871-0.26758
      c0.3125-0.17822,1.33105-0.9292,1.67871-1.10742c0.34766-0.16797,1.80078-1.25342,1.80078-1.25342
      s-0.85059-0.59375-1.17383-0.89502c-0.31445-0.30273-0.87402-1.01855-1.16504-1.17529
      c-0.2793-0.146-1.67871-0.68213-2.14844-0.7168c-0.4707-0.02148-1.92578-0.5376-1.92578-0.5376s0.66211-0.21191,0.88574-0.39111
      c0.19922-0.16895,0.65723-0.5708,0.92383-0.58887c-0.42188-0.02295-1.08691-0.06689-1.28223-0.12646
      c-0.28125-0.09033-1.1084-0.60498-1.4209-0.60498c-0.31445,0-0.94043,0.12207-0.94043,0.12207s0.85059-0.53564,1.54395-0.65967
      c0.69434-0.11084,1.23047-0.08936,1.23047-0.08936s-1.07324-0.30176-1.33203-0.64941
      c-0.24512-0.36914-0.50195-0.89404-0.69238-1.14063c-0.19043-0.23535-0.31543-0.62744-0.65918-0.65967
      c-0.34766-0.03467-0.95313,0.42432-1.30078,0.39063c-0.3457-0.03223-0.60449-0.24609-0.63672-0.74854
      c-0.02148-0.51563,0-0.33691-0.1123-0.60498c-0.13379-0.26807-0.63672-0.89551-0.16797-1.04102
      c0.48145-0.15674,1.48828,0.08984,1.58984-0.08936c0.08984-0.1792-0.53711-0.72705-0.95117-0.92871
      c-0.40234-0.20557-1.02539-0.54785-0.75-0.84277c-0.0459,0.02881-0.07813,0.04834-0.07813,0.04834s-0.12402-0.69434-0.53906-0.9624
      c-0.27832-0.1792-1.39844-0.146-1.39844-0.146s0.67285-0.78271,1.68066-0.90625c1.00586-0.11133,1.4873,0.74951,1.4873,0.74951
      s-0.25781,0.42529-0.43652,0.66064c-0.0293,0.03857-0.07031,0.07959-0.11719,0.12158
      c0.12012-0.08252,0.22949-0.16797,0.30762-0.27783c0.19043-0.26904,0.41406-1.01855,0.82715-0.78369
      c0.4043,0.23486,0.97559,1.41016,1.29883,1.3208c0.31348-0.08936,0.33594-0.92871,0.28027-1.28711
      c-0.06738-0.35889,0-0.98486,0.3125-0.92871c0.06445,0.01172,0.12598,0.04053,0.18945,0.07617
      c-0.0625-0.03857-0.09961-0.06494-0.09961-0.06494s0.4707-0.42529,0.72949-0.96387
      c0.17578-0.37939-0.19238-1.19678-0.19238-1.19678s1.23145,0.30225,1.45508,1.64551
      c0.06836,0.41211-0.06641,1.0127-0.12305,1.01758c0.2041,0.01855,0.34473,0.07568,0.31348,0.23535
      c-0.05762,0.35938-0.34766,0.79492-0.69434,1.1875C414.5791,221.56787,414.41113,222.34082,414.63477,222.84424z"/>
    <path class="spa-i-11" d="M429.20313,222.14844l0.00293-0.04297l0.00391-0.0127c0.0166-0.12891,0.02734-0.25049,0.03027-0.36328
      c-0.32715,0.02246-1.84082,0.11084-2.68848-0.12744c-0.94922-0.26953-1.7998-0.66016-2.68359-1.39893
      c-0.88379-0.76172-1.23242-1.23193-1.86914-1.32129c-1.52148-0.24561-2.68457,0.44775-2.68457,0.44775
      s1.13965,0.41406,1.99023,1.46631s1.7793,1.57764,2.18262,1.7124c0.66016,0.20166,2.97559,0.05518,3.60352,0.07861
      c0.55371,0.02881,1.69336,0.16699,1.9873,0.20264c0.01074-0.04297,0.03027-0.07666,0.04004-0.12451
      C429.15234,222.47949,429.17969,222.31006,429.20313,222.14844z"/>
    <path class="spa-i-1" d="M415.60938,219.97852c0.03125-0.15967-0.10938-0.2168-0.31348-0.23535v0.00049
      c-0.03418,0-0.16797,0-0.34375-0.00781c-0.18262,0.00537-0.37598,0.01611-0.54102,0.00781
      c-0.34766-0.02295-0.57715-0.23242-0.79102-0.37891c-0.03613-0.02148-0.06641-0.04102-0.09375-0.05908
      c-0.06348-0.03564-0.125-0.06445-0.18945-0.07617c-0.3125-0.05615-0.37988,0.56982-0.3125,0.92871
      c0.05566,0.3584,0.0332,1.19775-0.28027,1.28711c-0.32324,0.08936-0.89453-1.08594-1.29883-1.3208
      c-0.41309-0.23486-0.63672,0.51465-0.82715,0.78369c-0.07813,0.10986-0.1875,0.19531-0.30762,0.27783
      c-0.18457,0.16992-0.46289,0.35156-0.59863,0.43457c-0.27539,0.29492,0.34766,0.63721,0.75,0.84277
      c0.41406,0.20166,1.04102,0.74951,0.95117,0.92871c-0.10156,0.1792-1.1084-0.06738-1.58984,0.08936
      c-0.46875,0.14551,0.03418,0.77295,0.16797,1.04102c0.1123,0.26807,0.09082,0.08936,0.1123,0.60498
      c0.03223,0.50244,0.29102,0.71631,0.63672,0.74854c0.34766,0.03369,0.95313-0.42529,1.30078-0.39063
      c0.34375,0.03223,0.46875,0.42432,0.65918,0.65967c0.19043,0.24658,0.44727,0.77148,0.69238,1.14063
      c0.25879,0.34766,1.33203,0.64941,1.33203,0.64941s-0.53613-0.02148-1.23047,0.08936
      c-0.69336,0.12402-1.54395,0.65967-1.54395,0.65967s0.62598-0.12207,0.94043-0.12207c0.3125,0,1.13965,0.51465,1.4209,0.60498
      c0.19531,0.05957,0.86035,0.10352,1.28223,0.12646c-0.2666,0.01807-0.72461,0.41992-0.92383,0.58887
      c-0.22363,0.1792-0.88574,0.39111-0.88574,0.39111s1.45508,0.51611,1.92578,0.5376c0.46973,0.03467,1.86914,0.5708,2.14844,0.7168
      c0.29102,0.15674,0.85059,0.87256,1.16504,1.17529c0.32324,0.30127,1.17383,0.89502,1.17383,0.89502
      s-1.45313,1.08545-1.80078,1.25342c-0.34766,0.17822-1.36621,0.9292-1.67871,1.10742
      c-0.31348,0.17773-1.80273,0.48145-2.42871,0.26758c-0.6377-0.20117-0.51563-0.86084-1.1084-1.12939
      c-0.60449-0.27979-0.6709,0.26855-0.82715,0.5708c-0.1582,0.29102-0.24805,0.37988-0.60449,0.7168
      c-0.34668,0.32324-0.02246,0.7168,0.63574,0.87207c0.67285,0.14746,1.00781,0.44824,0.91895,0.68262
      c-0.10059,0.23535-1.1084,0.12402-1.35352,0.21387c-0.25684,0.08984-0.3584,0.32324-0.20117,0.68164
      c0.16602,0.3584,0,0.65918-0.05566,1.16309c-0.06836,0.51563,0.60449,0.37109,0.91699,0.21387
      c0.31348-0.14551,0.81641-0.39063,0.9834-0.21387c0.14648,0.19141-0.48145,0.63867-0.60352,0.92969
      c-0.11328,0.25586,0.11719,0.61328,0.18945,0.7168c0-0.00781,0.00098-0.02246,0.00098-0.02246s0.08496-0.01172,0.20996-0.02637
      c0.15332-0.05469,0.37695-0.11621,0.6084-0.0957c0.41211,0.0332,0.88184,0.42285,0.88184,0.42285s0.41504-0.24512,0.60449-0.60254
      c0.19336-0.35938-0.12305-1.05273-0.0332-1.55664c0.09961-0.50195,1.29785-0.48047,2.12695-0.62598
      c0.81738-0.15625,1.35352-0.96289,1.35352-0.96289s-0.18848,0.92969-0.18848,1.1084c0,0.19043-0.0918,0.98438-0.0918,0.98438
      s0.88477-0.73926,1.64551-1.28613c0.76074-0.53809,1.12891-1.9707,1.12891-1.9707s0.06836,0.63867,0.06836,0.96289
      c0,0.3252-0.08984,1.37793-0.08984,1.37793s0.50488-0.81836,0.81836-1.17676c0.31152-0.3584,0.84961-0.80566,1.38672-1.1084
      c0.54785-0.30078,1.1416-0.32324,1.1416-0.32324s0.34766,1.16309,0.72852,1.76855c0.37988,0.59277,1.55469,1.91211,2.31543,2.41602
      c0.75,0.51367,4.07324,2.21582,4.07324,2.21582s-1.80176,0.33496-2.30566,0.36914
      c-0.51465,0.02344-1.64453-0.08887-3.10059-0.03418c-1.46289,0.05762-2.05957,1.52246-2.30371,1.89258
      c-0.25684,0.35742,0.02246,1.46582-0.12402,2.30469c-0.21289,1.08594,0.53711,1.73438,0.53711,1.73438
      s0.05664-0.96289,0.17969-1.19727c0.13477-0.23438,0.31348-1.05176,0.31348-1.05176s0.22363,0.74902,0.25781,1.05176
      c0.00977,0.11133-0.10254,0.67188-0.12305,0.99609c-0.0332,0.54883,0.08887,1.2207,0.08887,1.2207s0.32324-0.57031,0.4707-0.89551
      c0.16602-0.3252,0.70508-0.65918,0.70508-0.65918s-0.19238,0.87109-0.16895,1.10742
      c0.03418,0.24414,0.13574,0.83887,0.13574,1.16309c0,0.33496-0.3252,0.47949-1.48926,0.60449
      c-1.1748,0.12305-1.61035-0.15723-2.30664-0.92871c-0.70313-0.77246-0.0332-1.25488-0.72559-1.59082
      c-0.35742-0.16602-0.62695,0.51563-0.81738,0.7168c-0.08789,0.09277-0.16211,0.14648-0.26074,0.20898
      c-0.00879,0.00781-0.02051,0.01953-0.03027,0.02734c-0.08887,0.07324-0.23535,0.16406-0.36523,0.23926
      c-0.05176,0.03809-0.10938,0.08105-0.1709,0.12988c-0.50391,0.37891-0.03418,0.55957,0.34766,0.86035
      c0.37988,0.30176,0.46973,0.48145,0.3125,0.87402c-0.15723,0.3916-1.04199-0.11328-1.73535-0.14551
      c-0.70508-0.03418,0.05566,0.5918,0.05566,1.30957c0,0.72754,0.22363,1.05078,0.51563,1.20898
      c0.27832,0.14453,0.625-0.24707,0.85156-0.42578c0.21094-0.17969,0.50098-0.14648,0.78125-0.12305
      c0.23535,0.02148,0.1582,0.24609-0.04492,0.60449c-0.20117,0.33496-0.36914,0.36816-0.44629,0.82617
      c-0.0791,0.4707,0.52441,0.34766,0.92871,0.4707c0.41309,0.1123,0.68164,0.22559,1.00488,0.26855
      c0.33691,0.0332,0.81836-0.12305,0.57422-0.80566c-0.15918-0.44629,0.40039-0.72656,0.72559-0.80566
      c0.32422-0.07813,1.01758,0.26953,1.30957,0.34863c0.28027,0.06543,0.7627,0.03223,1.5332,0.03223
      c0.77148,0,1.1748,0.18945,1.40918,0.30078c0.24805,0.11328,0.80664,0.1582,1.01953,0.03516
      c0.2002-0.11133,0.72656-0.72656,0.84961-0.99609c0.1123-0.26855-0.12305-0.99609-0.53711-2.48438
      c-0.40332-1.5-0.55957-2.65234-0.23535-3.22266c0.3252-0.57129,0.92969-0.31348,1.78125-0.31348h1.57715
      c0,0-0.32422,0.81738-0.52637,1.26465c-0.13379,0.30078,0,1.49902,0.07813,1.76758c0.0791,0.28027,0.29004,1.04102,0.57031,1.57813
      s0.92773,1.0293,1.12988,1.18652c0.21289,0.15527,0.97461,1.1084,1.1416,0.96191c0.15723-0.15625-0.32422-0.54883-0.32422-0.96191
      c0-0.42578,0.03418-1.49902,0.03418-1.49902s0.36914,1.15234,0.72754,1.53223c0.36816,0.37988,0.80566,0.88379,0.80566,0.88379
      s-0.0332-0.80469,0.04297-0.99609c0.0791-0.18945-0.04297-1.5332-0.04297-1.5332s0.24609,0.72852,0.40137,1.0293
      c0.16797,0.31445,0.61719,0.3916,1.05371,0.89648c0.44727,0.49219,0.49219,1.29688,0.24512,1.67773
      c-0.24512,0.38086-0.55957,0.61523-1.20801,0.72852c-0.64941,0.11133-0.92969-0.53711-1.58887-0.19043
      c-0.63867,0.34668-0.14648,0.72754-0.1123,0.91699c0.02148,0.08887,0.0127,0.22852-0.00293,0.38672
      c0.01953,0.33008-0.04199,0.69922-0.04199,0.69922s-0.00781-0.00488-0.01855-0.00977
      c0.08984,0.27148,0.58691,0.39648,0.83496,0.18848c0.28027-0.22363,0.55957-0.22363,0.84961,0
      c0.28125,0.23535-0.12109,0.45898-0.56934,0.57031c-0.44922,0.12402-0.88379,0.77246-0.85059,1.0752
      c0.00781,0.05859,0.04492,0.10938,0.09473,0.15625c0.08301,0.04102,0.4043,0.19531,0.64258,0.29688
      c0.09277,0.0293,0.17773,0.05664,0.2373,0.08398c0.0625,0.0293,0.13672,0.08203,0.21484,0.14746
      c0.15625,0.11426,0.33496,0.26465,0.44727,0.36133c0.0957,0.06152,0.18555,0.10645,0.26758,0.10645
      c0.27832,0,0.27832-0.69434,0.32324-0.99609c0.04395-0.30273,0.16797-0.50391,0.41406-0.50391
      c0.17773,0,0.15723,0.23438,0.18945,0.80566c0.0459,0.57129,0.0459,0.77246,0.44727,0.85254
      c0.16895,0.02148,0.49316-0.34863,1.09766-0.50586c0.07227-0.01953,0.16992,0.02148,0.28613,0.07422
      c0.21582,0.05273,0.49316,0.1416,0.61719,0.18359c0.06055-0.00293,0.12109-0.01172,0.18164-0.0332
      c0.69531-0.22461-0.32422-1.41016-0.32422-1.41016c-0.28027-0.4248-0.20117-1.26465-0.20117-1.53418
      c0-0.26855,0.64941-2.0791,0.97266-2.7627c0.32617-0.69434,1.25391-1.64551,1.34473-2.07129
      c0.07617-0.4248-0.09082-0.69238-0.32715-0.91699c-0.24414-0.23633-0.32227-0.45898-0.49023-0.83984
      c-0.1582-0.39258-0.88379-0.12305-1.45605-0.61523c-0.57031-0.50391-1.28516-0.80664-1.28516-2.80859
      c0-1.99219,0.96094-2.94336,1.44238-3.25586c0.49316-0.30273,2.63086-0.31445,3.92773-1.37695
      c2.02734-1.64453,1.82422-4.21875,1.79102-5.37207c-0.04492-1.15332-2.14746-5.90869-2.31641-6.479
      c-0.15723-0.58203-0.80566-4.46582,0.4043-5.43848c0.81641-0.64893,1.34082-0.69434,1.99023-1.76807
      c0.64844-1.07373,0.28027-1.18701,1.17676-2.48535c0.88184-1.29688,1.57617-1.83496,1.57617-1.83496s-0.40234-0.04443-0.97461,0
      c-0.55957,0.02246-1.375,0.33594-1.375,0.33594s1.57715-1.79004,1.97949-2.33887c0.60449-0.83936,0.69336-2.98779,0.69336-2.98779
      s-0.08887,0.22412-0.36719,0.56982c-0.28027,0.34814-1.41016,0.99707-2.38477,1.61279
      c-0.97363,0.61523-1.09668,1.86865-1.09668,1.86865s0-1.25342-0.15723-1.67871c-0.16797-0.42529-0.81641-1.61182-0.81641-1.61182
      s0,0.99658-0.07715,1.5332c-0.07813,0.53809-0.28027,0.8833-0.51465,1.49951c-0.24707,0.61475-0.61621,1.19727-1.06348,2.68604
      c-0.42578,1.43213-0.23633,3.21143-0.15625,3.6709c0.07715,0.47021-0.52637,2.95459-0.57129,3.79346
      c-0.04395,0.8501,0.32422,3.26758,1.05078,5.4502c0.74023,2.18164,2.02734,6.87109-1.4541,7.74316
      c-3.48047,0.88379-5.10254-1.80176-6.15527-3.33496c-1.79102-2.61719-2.06836-4.67725-2.14844-5.24805
      c-0.05273-0.45068-0.47461-1.11523-0.59375-1.28711c0.17676,0.22168,0.69043,0.51953,1.13086,0.86084
      c0.44727,0.35889,0.88477,1.07568,0.88477,1.07568s0.16602-1.88135,0.16602-2.76514c0-0.87158-0.58203-2.18066-0.58203-2.18066
      s0.74023,0.50195,1.2666,1.00586c0.52441,0.49316,0.92871,1.25391,0.92871,1.25391s0.35938-0.95068,0.35938-1.79053
      c0-0.85107-0.88574-1.73486-0.88574-1.73486s0.97363,0.53711,1.41016,0.69287c0.45898,0.15674,0.97461,0.99609,0.97461,0.99609
      s0.04297-1.10645-0.15723-1.61084c-0.20117-0.4917-0.85156-1.11865-1.17676-1.48779
      c-0.32324-0.3916-0.52441-1.04102-0.56934-1.38867c-0.03516-0.34668-0.11328-1.15137-0.11328-1.15137
      s0.2793,0.18994,0.67285,0.49121c0.40332,0.29102,0.82813,0.60449,0.82813,0.60449s-0.0791-0.76074-0.23535-1.05176
      c-0.15625-0.30225-0.21289-0.62695-0.33691-1.0415c-0.12109-0.41211,0.19043-1.05029,0.12402-1.80029
      c-0.05566-0.55078-0.07715-1.12012-0.08594-1.396c-0.18164-0.31494-0.47363-0.69092-0.84473-1.09375
      c-0.33887-0.35254-0.75391-0.75879-1.2041-1.14014c-0.01367-0.01172-0.02539-0.02246-0.03809-0.03369
      c-0.16113-0.13379-0.3252-0.26465-0.49023-0.3877c-0.73535-0.54932-1.50391-1.01953-2.09473-1.35693
      c-0.46973-0.24316-0.90039-0.42236-1.25098-0.51172c0,0,0.00195-0.00781,0.00293-0.00977
      c-0.39453,0.06982-1.24023,0.23438-1.50293,0.4126c-0.34668,0.24609-0.40332,0.81641-0.68164,1.01855
      c-0.29102,0.2124-1.29785,0.2124-2.19531,0.28027c-0.88281,0.05566-1.31934,0.17773-1.57715,0.41309
      c-0.11133,0.10498-0.12695,0.21045-0.0918,0.36963c0.1875,0.00439,0.93652,0.03711,0.9082,0.30225
      c-0.02051,0.30176-0.31348,0.30176-0.37891,0.41406c-0.05762,0.12305-0.21484,0.30176-0.21484,0.30176
      s-0.03418-0.09766-0.07617-0.22168c0.01563,0.05225,0.02832,0.09814,0.04297,0.15479
      c0.11133,0.43018,0.38867,0.65479,0.79004,0.8042l0,0c0.24902,0.09375,0.55273,0.15918,0.88965,0.22705l-0.00098-0.00146
      l0.03613,0.00879c0.19043,0.03809,0.38965,0.07813,0.60352,0.12549c0.83496,0.18701,1.04785,0.5874,1.0293,1.21289
      c-0.00293,0.11279-0.01367,0.23438-0.03027,0.36328c-0.00293,0.01855-0.00488,0.03613-0.00684,0.05566
      c-0.02344,0.16162-0.05078,0.33105-0.08496,0.5166c-0.00977,0.04785-0.0293,0.08154-0.04004,0.12451
      c-0.18652,0.6875-0.71191,0.86865-1.28711,0.90967l0.00488,0.00635l-0.92773,0.05566l0.0332-0.04492
      c-0.00781,0-0.01563,0-0.02246,0c-0.06348,0.00244-0.12012,0.00488-0.17871,0.0083l0.00977,0.01465l-0.65332,0.03906
      c-0.36523,0.05859-0.58984,0.17676-0.66504,0.45313c-0.12402,0.44727,1.2627,0.95117,1.51855,1.52197
      c0.24805,0.5708-0.56836,1.01855-1.20703,1.2876c-0.63965,0.26758-2.30469,0.15625-3.03125,0.15625
      c-0.72754,0-1.2666,0.23486-1.2666,0.23486s-0.47949-0.23486-0.95117-0.3584c-0.46875-0.12207-1.86816-1.22998-2.46094-1.62305
      c-0.60449-0.38037-1.55566-0.62598-2.05957-0.95117c-0.50391-0.32373-1.07422-1.14014-1.29883-1.65527
      c-0.22363-0.50342-0.05566-1.27637,0.28027-1.67822C415.26172,220.77344,415.55176,220.33789,415.60938,219.97852z
      M431.61328,217.67432c0.63672,0.44678,1.04004,1.14111,1.01855,1.17432c-0.1123,0.10107-0.60547-0.30273-0.9541-0.41406
      c0,0-0.25586,0.05615-0.50293,0.05615c-0.25586,0-0.37988-0.12354-0.41309-0.23486c-0.03418-0.13477,0.0332-0.33594,0.0332-0.33594
      l-0.37988-0.05566l-0.06738-0.48193C430.34766,217.38232,431.20898,217.38232,431.61328,217.67432z M415.95605,229.3125
      c0,0-0.01953-0.00049-0.07031-0.00342C415.92871,229.31104,415.95605,229.3125,415.95605,229.3125z"/>
    <path d="M426.14063,219.11768c0.06543-0.1123,0.3584-0.1123,0.37891-0.41406c0.02832-0.26514-0.7207-0.29785-0.9082-0.30225
      c-0.02637-0.00049-0.04199-0.00049-0.04199-0.00049s0.10059,0.1792,0.13379,0.32471
      c0.01953,0.09033,0.09082,0.30518,0.14648,0.47217c0.04199,0.12402,0.07617,0.22168,0.07617,0.22168
      S426.08301,219.24072,426.14063,219.11768z"/>
    <path d="M430.79492,217.91992c0,0-0.06738,0.20117-0.0332,0.33594c0.0332,0.11133,0.15723,0.23486,0.41309,0.23486
      c0.24707,0,0.50293-0.05615,0.50293-0.05615c0.34863,0.11133,0.8418,0.51514,0.9541,0.41406
      c0.02148-0.0332-0.38184-0.72754-1.01855-1.17432c-0.4043-0.29199-1.26563-0.29199-1.26563-0.29199l0.06738,0.48193
      L430.79492,217.91992z"/>
    <polygon points="426.7002,223.72461 426.33008,223.19043 425.96094,223.78467 426.05664,223.77832 426.70996,223.73926 	"/>
    <polygon points="427.7959,223.70557 427.79102,223.69922 427.31543,223.16797 426.90137,223.71631 426.86816,223.76123 	"/>
    <polygon points="426.46484,220.08057 426.55176,220.8291 427.38281,220.40381 426.68262,220.15674 426.68262,220.15674 	"/>
    <polygon points="426.46289,220.0791 426.46484,220.08057 426.46484,220.0791 	"/>
    <polygon points="427.57227,220.38379 427.72754,221.09863 428.38867,220.58301 427.60742,220.39111 427.57129,220.38232 	"/>
    <path class="spa-i-11" d="M414.37793,219.6875c0.20117,0.02783,0.4082,0.04199,0.57422,0.04834
      c0.17578,0.00781,0.30957,0.00781,0.34375,0.00781v-0.00049c0.05664-0.00488,0.19141-0.60547,0.12305-1.01758
      c-0.22363-1.34326-1.45508-1.64551-1.45508-1.64551s0.36816,0.81738,0.19238,1.19678
      c-0.25879,0.53857-0.72949,0.96387-0.72949,0.96387s0.03711,0.02637,0.09961,0.06494
      c0.02734,0.01807,0.05762,0.0376,0.09375,0.05908C413.81445,219.48242,414.12109,219.646,414.37793,219.6875z"/>
    <path class="spa-i-11" d="M410.42773,221.06445c0.17871-0.23535,0.43652-0.66064,0.43652-0.66064s-0.48145-0.86084-1.4873-0.74951
      c-1.00781,0.12354-1.68066,0.90625-1.68066,0.90625s1.12012-0.0332,1.39844,0.146c0.41504,0.26807,0.53906,0.9624,0.53906,0.9624
      s0.03223-0.01953,0.07813-0.04834c0.13574-0.08301,0.41406-0.26465,0.59863-0.43457
      C410.35742,221.14404,410.39844,221.10303,410.42773,221.06445z"/>
    <path class="spa-i-11" d="M410.01367,224.81348c0-0.26855-0.37988-0.90674-0.37988-0.90674s-0.86133,0.12354-1.33301,0.66064
      c-0.48047,0.54834-0.40234,1.56641-0.40234,1.56641s0.55957-0.604,1.06445-0.604c0.51367,0,1.29688,0.17773,1.29688,0.17773
      S410.01367,225.08154,410.01367,224.81348z"/>
    <path class="spa-i-11" d="M410.96387,235.86914c0.40332,0.08984,0.69434,0.50488,0.69434,0.50488s0.38281-0.32617,0.50488-0.50488
      c0.12207-0.17969,0.34668-0.6377,0.34668-0.6377s-0.46875-0.50342-1.29883-0.3584
      c-0.82813,0.15674-1.36426,1.08594-1.36426,1.08594S410.55273,235.76904,410.96387,235.86914z"/>
    <path class="spa-i-11" d="M411.55762,238.55469c0,0-0.69336-0.11133-1.29785,0.37109c-0.59375,0.48047-0.62598,1.39844-0.62598,1.39844
      s0.57129-0.48145,1.01855-0.41406c0.43555,0.05469,0.97266,0.30176,0.97266,0.30176s0.08887-0.57031,0.12402-0.71777
      C411.84961,239.06934,411.55762,238.55469,411.55762,238.55469z"/>
    <path class="spa-i-11" d="M413.17969,241.64453c-0.00488,0.11523-0.02441,0.90137,0.37109,1.40918
      c0.44824,0.57129,1.26367,0.66113,1.26367,0.66113s-0.26758-0.59375-0.31348-0.89551
      c-0.06641-0.44824,0.37988-0.83887,0.37988-0.83887s-0.41211-0.42676-0.81641-0.42676c-0.24023,0-0.49707,0.02246-0.67383,0.04199
      c-0.125,0.01465-0.20996,0.02637-0.20996,0.02637S413.17969,241.63672,413.17969,241.64453z"/>
    <path class="spa-i-11" d="M420.83203,250.15625c0.2002-0.17383,0.5625-0.65625,0.5625-0.65625s-0.57031-0.71582-1.35449-0.69434
      c-0.79395,0.03516-1.62207,0.77148-1.62207,0.77148s0.98633-0.07715,1.24219,0.24707c0.25781,0.33594,0.50293,0.75,0.50293,0.75
      s0.12793-0.06641,0.27344-0.15137c0.12988-0.0752,0.27637-0.16602,0.36523-0.23926
      C420.81152,250.17578,420.82324,250.16406,420.83203,250.15625z"/>
    <path class="spa-i-11" d="M419.17871,253.41699c-0.05469-0.26855-0.21191-0.77246-0.21191-0.77246s-1.04004-0.15625-1.55664,0.39258
      c-0.50195,0.53613-0.46875,1.52148-0.46875,1.52148s0.62695-0.68262,1.19629-0.62695
      c0.57129,0.05566,1.20898,0.3584,1.20898,0.3584S419.24609,253.69727,419.17871,253.41699z"/>
    <path class="spa-i-11" d="M422.84961,256.30469l-1.63281-0.33594c0,0-0.5166,0.71582-0.13379,1.28613
      c0.37988,0.57129,1.17383,0.83984,1.17383,0.83984s-0.29199-0.41504-0.15723-0.89551
      C422.2002,256.81836,422.84961,256.30469,422.84961,256.30469z"/>
    <path class="spa-i-11" d="M435.26855,258c-0.00293-0.05957-0.00879-0.11816-0.02051-0.17383
      c-0.03223-0.21289-0.1543-0.50391-0.1543-0.50391s-0.90918-0.21289-1.42188,0.08984
      c-0.50488,0.29102-0.91699,1.55469-0.91699,1.55469s0.82715-0.69336,1.42969-0.60352
      c0.52734,0.08008,0.93555,0.28125,1.02344,0.32617c0.01074,0.00488,0.01855,0.00977,0.01855,0.00977
      S435.28809,258.33008,435.26855,258z"/>
    <path class="spa-i-11" d="M436.4248,261.06055c-0.04785-0.01367-0.12598-0.04492-0.21484-0.08398
      c-0.23828-0.10156-0.55957-0.25586-0.64258-0.29688c-0.01563-0.00586-0.02637-0.01172-0.02637-0.01172
      s-0.69629,0.7168-0.44727,1.32129c0.25586,0.60449,0.69043,1.23047,0.69043,1.23047s-0.02051-0.89551,0.25879-1.1416
      c0.41504-0.35742,1.17578-0.41406,1.17578-0.41406s-0.04297-0.03906-0.10938-0.09473
      c-0.1123-0.09668-0.29102-0.24707-0.44727-0.36133C436.56445,261.13672,436.47754,261.0791,436.4248,261.06055z"/>
    <path class="spa-i-11" d="M439.87207,261.3623c-0.125,0.02246-0.85156,0.3584-0.85156,0.3584s-0.34766,0.87305,0.3125,1.43164
      c0.66113,0.58203,1.24414,0.63867,1.24414,0.63867s-0.51758-0.90625-0.35938-1.37695
      c0.16797-0.50391,0.60449-0.80664,0.60449-0.80664s-0.03027-0.00879-0.07031-0.02148
      c-0.12402-0.04199-0.40137-0.13086-0.61719-0.18359C440.01074,261.37109,439.9082,261.35156,439.87207,261.3623z"/>
    <path class="spa-i-3" d="M432.9082,216.42871c0.63965,0.33154,1.35547,0.78613,2.07324,1.33398
      c0.1748,0.13574,0.34473,0.27295,0.51172,0.41064c0.0127,0.01123,0.02441,0.02197,0.03809,0.03369
      c0.45703,0.38428,0.86426,0.77246,1.2041,1.14014c0.37109,0.40283,0.66309,0.77881,0.84473,1.09375
      c0.00684,0.01025,0.01367,0.02148,0.01953,0.03076c0,0,0.65039-0.3584,1.32031-0.57129
      c0.6416-0.20264,1.4375-0.22168,1.50781-0.22314c-0.01172-0.03564-0.01172-0.07373,0.00391-0.11328l-0.1377-0.0918
      c-0.54785,0.01563-1.36914-0.05566-1.35254-0.51172c0-0.08936,0.0791-0.20117,0.08887-0.20117
      c-0.00977,0.01025-0.23438-0.01123-0.33496-0.06787c-0.20215-0.14551-0.20215-0.48047,0.02344-0.77148l0.20117-0.14502
      l0.00195-0.09229c-0.0625-0.0127-0.12598-0.03857-0.1709-0.0874c-0.0459-0.04736-0.06738-0.104-0.07715-0.16309l-0.12207,0.01758
      c-0.03516,0.04492-0.125,0.10107-0.16895,0.14551c-0.30176,0.25732-0.72754,0.27979-0.96191,0.03418
      c-0.14746-0.13477-0.19043-0.32471-0.125-0.51465c-0.17773,0.04443-0.3584,0.03271-0.50195-0.05713
      c-0.29102-0.16699-0.34863-0.58203-0.12402-0.89551c0.08105-0.12256,0.22559-0.26367,0.30078-0.32471l-0.03711-0.07031
      c-0.0752,0.00146-0.15234-0.01758-0.21777-0.06348c-0.06738-0.05029-0.10547-0.12109-0.11523-0.19727l-0.00488-0.00146
      c-0.02344,0.05029-0.15625,0.26221-0.28418,0.37842c-0.29004,0.27881-0.72754,0.31348-0.96191,0.07813
      c-0.12305-0.12354-0.17773-0.29102-0.15625-0.45898c-0.19043,0.10107-0.3916,0.10107-0.57129-0.01074
      c-0.29102-0.16846-0.3584-0.56006-0.15723-0.88379c0.0332-0.06787,0.06738-0.15771,0.125-0.20264l-0.00781-0.11621
      c-0.06738,0.00586-0.13574-0.00342-0.19629-0.03955c-0.05664-0.03369-0.09375-0.08203-0.12012-0.13232l-0.0918,0.02051
      l-0.11035,0.21191c-0.24707,0.27979-0.60547,0.34766-0.78418,0.19043c-0.08887-0.07813-0.15527-0.29053-0.14551-0.30176
      c0,0.01123-0.10059,0.10059-0.18945,0.12354c-0.46191,0.11035-0.70703-0.63232-0.80762-1.13965L432,213.10303
      c-0.03418,0.02002-0.07129,0.02783-0.10742,0.02881c0.00293,0.01025,0.17773,0.78662,0.08887,1.45264
      c-0.08496,0.63379-0.30469,1.27295-0.32129,1.32275c-0.00098,0.00195-0.00293,0.00977-0.00293,0.00977
      C432.00781,216.00635,432.43848,216.18555,432.9082,216.42871z M438.03516,219.34082
      c0.05566-0.06641,0.16699-0.06641,0.25684,0.01172c0.09082,0.06689,0.1123,0.16748,0.05762,0.23535
      c-0.05762,0.06641-0.16895,0.05566-0.25879,0C438.00195,219.5083,437.98047,219.40869,438.03516,219.34082z M437.04004,218.2666
      c0.04395-0.04541,0.14355-0.02148,0.2002,0.03418l0.24707,0.23486l0.23535,0.23389
      c0.05566,0.05664,0.05566,0.13477,0.00977,0.18018c-0.05566,0.04443-0.14355,0.02148-0.2002-0.03418l-0.24609-0.23438
      l-0.23633-0.23535C436.99316,218.39014,436.98242,218.30078,437.04004,218.2666z M436.32324,217.70703
      c0.05566-0.06641,0.17969-0.06641,0.26855,0c0.08984,0.07861,0.11328,0.1792,0.05469,0.23486
      c-0.05469,0.06787-0.16602,0.06787-0.25586,0C436.30078,217.875,436.27734,217.7627,436.32324,217.70703z M435.05957,216.66699
      c0.04395-0.04541,0.13379-0.04541,0.20215,0l0.36621,0.29053l0.37109,0.2793c0.05566,0.04492,0.08887,0.13428,0.04395,0.1792
      c-0.04395,0.04541-0.13379,0.04541-0.2002,0l-0.37012-0.29004l-0.36914-0.27979
      C435.03613,216.80127,435.02539,216.71094,435.05957,216.66699z M434.41016,216.2417c0.05469-0.07959,0.16602-0.06787,0.25684,0
      c0.08887,0.06641,0.11133,0.17871,0.06738,0.23389c-0.05566,0.06787-0.16797,0.06787-0.26953-0.01025
      C434.38574,216.4082,434.35547,216.29688,434.41016,216.2417z M433.15527,215.43604
      c0.03516-0.05713,0.12402-0.06787,0.19043-0.02295l0.29102,0.1792l0.29102,0.16748c0.06934,0.0332,0.10254,0.12354,0.0791,0.1792
      c-0.03223,0.05664-0.12305,0.06738-0.19043,0.02246l-0.29102-0.16846l-0.29004-0.16699
      C433.15527,215.58203,433.12402,215.4917,433.15527,215.43604z M432.36133,214.99902c0.05566-0.06738,0.17969-0.06738,0.27051,0
      c0.08789,0.07813,0.11035,0.1792,0.05371,0.24512c-0.05371,0.05713-0.16602,0.05713-0.25879-0.01025
      C432.33789,215.16699,432.31738,215.06592,432.36133,214.99902z"/>
    <path d="M434.73438,216.47559c0.04395-0.05518,0.02148-0.16748-0.06738-0.23389c-0.09082-0.06787-0.20215-0.07959-0.25684,0
      c-0.05469,0.05518-0.02441,0.1665,0.05469,0.22363C434.56641,216.54346,434.67871,216.54346,434.73438,216.47559z"/>
    <path d="M435.47266,217.12598l0.37012,0.29004c0.06641,0.04541,0.15625,0.04541,0.2002,0
      c0.04492-0.04492,0.01172-0.13428-0.04395-0.1792l-0.37109-0.2793l-0.36621-0.29053c-0.06836-0.04541-0.1582-0.04541-0.20215,0
      c-0.03418,0.04395-0.02344,0.13428,0.04395,0.1792L435.47266,217.12598z"/>
    <path d="M433.52539,215.79297l0.29102,0.16846c0.06738,0.04492,0.1582,0.03418,0.19043-0.02246
      c0.02344-0.05566-0.00977-0.146-0.0791-0.1792l-0.29102-0.16748l-0.29102-0.1792c-0.06641-0.04492-0.15527-0.03418-0.19043,0.02295
      c-0.03125,0.05566,0,0.146,0.08008,0.18994L433.52539,215.79297z"/>
    <path d="M432.68555,215.24414c0.05664-0.06592,0.03418-0.16699-0.05371-0.24512c-0.09082-0.06738-0.21484-0.06738-0.27051,0
      c-0.04395,0.06689-0.02344,0.16797,0.06543,0.23486C432.51953,215.30127,432.63184,215.30127,432.68555,215.24414z"/>
    <path d="M436.64648,217.94189c0.05859-0.05566,0.03516-0.15625-0.05469-0.23486c-0.08887-0.06641-0.21289-0.06641-0.26855,0
      c-0.0459,0.05566-0.02246,0.16797,0.06738,0.23486C436.48047,218.00977,436.5918,218.00977,436.64648,217.94189z"/>
    <path d="M437.28613,218.68115l0.24609,0.23438c0.05664,0.05566,0.14453,0.07861,0.2002,0.03418
      c0.0459-0.04541,0.0459-0.12354-0.00977-0.18018l-0.23535-0.23389l-0.24707-0.23486
      c-0.05664-0.05566-0.15625-0.07959-0.2002-0.03418c-0.05762,0.03418-0.04688,0.12354,0.00977,0.1792L437.28613,218.68115z"/>
    <path d="M438.34961,219.58789c0.05469-0.06787,0.0332-0.16846-0.05762-0.23535c-0.08984-0.07813-0.20117-0.07813-0.25684-0.01172
      c-0.05469,0.06787-0.0332,0.16748,0.05566,0.24707C438.18066,219.64355,438.29199,219.6543,438.34961,219.58789z"/>
    <path class="spa-i-3" d="M434.71387,213.73438c0.06445,0.03369,0.10742,0.09033,0.13086,0.15234h0.00195l0.72754-0.48779
      l-0.30273-0.55908l-0.66016,0.01074l-0.10547,0.84424C434.57422,213.68604,434.64941,213.69531,434.71387,213.73438z"/>
    <path class="spa-i-3" d="M434.27539,213.83447c0.0498-0.08154,0.13672-0.12695,0.22754-0.13965l-0.46191-0.62012l-0.56055,0.30127
      l-0.02246,0.604l0.79004,0.10352C434.2207,214.00342,434.22461,213.91455,434.27539,213.83447z"/>
    <path class="spa-i-3" d="M434.82422,214.14844c-0.0498,0.08057-0.13184,0.12549-0.2207,0.13916l0.45605,0.59961l0.54785-0.30273
      l0.0332-0.60449l-0.79395-0.09326h-0.00195C434.87695,213.96973,434.875,214.06543,434.82422,214.14844z"/>
    <path class="spa-i-3" d="M434.38574,214.24951c0.06055,0.03613,0.12891,0.04541,0.19629,0.03955
      c0.00684-0.00146,0.01367,0,0.02148-0.00146c0.08887-0.01367,0.1709-0.05859,0.2207-0.13916
      c0.05078-0.08301,0.05273-0.17871,0.02051-0.26172c-0.02344-0.06201-0.06641-0.11865-0.13086-0.15234
      c-0.06445-0.03906-0.13965-0.04834-0.20801-0.03955c-0.00195,0-0.00195,0-0.00293,0
      c-0.09082,0.0127-0.17773,0.05811-0.22754,0.13965c-0.05078,0.08008-0.05469,0.16895-0.02734,0.24902
      c0.00391,0.01123,0.01172,0.02246,0.01758,0.03369C434.29199,214.16748,434.3291,214.21582,434.38574,214.24951z"/>
    <path class="spa-i-3" d="M432.04785,212.53711c0.02441,0.00879,0.04492,0.0249,0.0625,0.04297
      c0.09473-0.14551,0.36426-0.63379,0.00391-0.92725c-0.50098-0.31299-0.93945-0.15625-0.97168-0.10107
      c0,0,0.3457,0.26904,0.43652,0.48145c0.08887,0.1709,0.16895,0.43164,0.22168,0.60791
      C431.87109,212.55518,431.96777,212.5127,432.04785,212.53711z"/>
    <path class="spa-i-3" d="M432.16113,212.90625c-0.03223,0.09424-0.09277,0.15967-0.16113,0.19678l0.13672,0.11914l0.41602,0.36719
      l0.46973-0.41406l-0.10156-0.59424l-0.76855,0.05908C432.1875,212.71143,432.19336,212.80518,432.16113,212.90625z"/>
    <path class="spa-i-3" d="M432,213.10303c0.06836-0.03711,0.12891-0.10254,0.16113-0.19678
      c0.03223-0.10107,0.02637-0.19482-0.00879-0.26611c-0.00977-0.02295-0.02344-0.04346-0.04199-0.06006
      c-0.01758-0.01807-0.03809-0.03418-0.0625-0.04297c-0.08008-0.02441-0.17676,0.01807-0.24707,0.104
      c-0.02734,0.03369-0.05078,0.07275-0.06543,0.11914c-0.06738,0.16846-0.02148,0.3252,0.10059,0.36963
      c0.01855,0.00537,0.03711,0.00293,0.05664,0.00195C431.92871,213.13086,431.96582,213.12305,432,213.10303z"/>
    <path class="spa-i-3" d="M437.11816,215.23389c0.06152,0.04346,0.0957,0.10352,0.1123,0.16748l0.79297-0.3916l-0.21094-0.59229
      l-0.66016-0.06787l-0.23828,0.80713l0.00879,0.01465C436.99023,215.17383,437.06152,215.19092,437.11816,215.23389z"/>
    <path class="spa-i-3" d="M436.66992,215.27881c0.06543-0.07764,0.15918-0.10938,0.25293-0.10742l-0.00879-0.01465l-0.36621-0.67236
      l-0.59375,0.22363l-0.11133,0.60449l0.75293,0.19238l0.00488,0.00146C436.5918,215.42871,436.61328,215.34668,436.66992,215.27881z
      "/>
    <path class="spa-i-3" d="M437.17383,215.65918c-0.06055,0.07324-0.14844,0.10645-0.24023,0.10791l0.03711,0.07031l0.31543,0.58301
      l0.59277-0.22363l0.1123-0.59326l-0.75879-0.19531C437.25293,215.49219,437.23633,215.58447,437.17383,215.65918z"/>
    <path class="spa-i-3" d="M436.92285,215.17139c-0.09375-0.00195-0.1875,0.02979-0.25293,0.10742
      c-0.05664,0.06787-0.07813,0.1499-0.06934,0.22754c0.00977,0.07617,0.04785,0.14697,0.11523,0.19727
      c0.06543,0.0459,0.14258,0.06494,0.21777,0.06348c0.0918-0.00146,0.17969-0.03467,0.24023-0.10791
      c0.0625-0.07471,0.0791-0.16699,0.05859-0.25098c0-0.00293-0.00098-0.00439-0.00195-0.00684
      c-0.0166-0.06396-0.05078-0.12402-0.1123-0.16748C437.06152,215.19092,436.99023,215.17383,436.92285,215.17139z"/>
    <path class="spa-i-3" d="M439.29785,217.36816l0.0166,0.00732l0.8252-0.28369l-0.12305-0.61621l-0.6377-0.15576l-0.34473,0.77393
      c0.06836,0.00977,0.1377,0.03564,0.1875,0.0874C439.27051,217.23438,439.29297,217.30127,439.29785,217.36816z"/>
    <path class="spa-i-3" d="M439.19922,217.61719c-0.06445,0.06006-0.15625,0.08203-0.24316,0.07324l0.26563,0.71045l0.62695-0.14502
      l0.18945-0.58154l-0.72363-0.29883l-0.0166-0.00732C439.30371,217.45654,439.27441,217.54785,439.19922,217.61719z"/>
    <path class="spa-i-3" d="M438.7627,217.15869c0.07617-0.05713,0.1748-0.07861,0.2666-0.06592l-0.25391-0.70557l-0.62695,0.14502
      l-0.20215,0.58252l0.72363,0.28955C438.66504,217.31592,438.69238,217.22705,438.7627,217.15869z"/>
    <path class="spa-i-3" d="M438.92188,217.68262c0.0127,0.00244,0.02344,0.00732,0.03418,0.00781
      c0.08691,0.00879,0.17871-0.01318,0.24316-0.07324c0.0752-0.06934,0.10449-0.16064,0.09863-0.24902
      c-0.00488-0.06689-0.02734-0.13379-0.07617-0.18701c-0.0498-0.05176-0.11914-0.07764-0.1875-0.0874
      c-0.00195,0-0.00391-0.00098-0.00488-0.00098c-0.0918-0.0127-0.19043,0.00879-0.2666,0.06592
      c-0.07031,0.06836-0.09766,0.15723-0.09277,0.24561c0,0.00928,0.00195,0.01855,0.00391,0.02783
      c0.00977,0.05908,0.03125,0.11572,0.07715,0.16309C438.7959,217.64404,438.85938,217.66992,438.92188,217.68262z"/>
    <path class="spa-i-3" d="M440.94824,219.65234c0.19238,0.01465,0.47949,0.03955,0.68066,0.08105
      c0.22168,0.04443,0.58008,0.30127,0.58008,0.30127c0.06836-0.04443,0.13477-0.47021-0.3125-0.88428
      c-0.36816-0.26318-0.80957,0.0874-0.94336,0.20605c0.01758,0.01221,0.03711,0.02344,0.04688,0.03955
      C441.04883,219.46777,441.02051,219.56982,440.94824,219.65234z"/>
    <path class="spa-i-3" d="M440.59766,219.37402c0.09668-0.05371,0.19629-0.06689,0.27734-0.04932l-0.09668-0.72314l-0.63867,0.03467
      l-0.32422,0.51416l0.47852,0.32178l0.1377,0.0918C440.4541,219.49365,440.51074,219.42432,440.59766,219.37402z"/>
    <path class="spa-i-3" d="M440.59766,219.37402c-0.08691,0.05029-0.14355,0.11963-0.16602,0.18994
      c-0.01563,0.03955-0.01563,0.07764-0.00391,0.11328c0.00391,0.01563,0.00488,0.03027,0.01367,0.04395
      c0.05664,0.10156,0.23633,0.11279,0.39063,0.02246c0.04785-0.02295,0.08496-0.05615,0.11621-0.09131
      c0.07227-0.08252,0.10059-0.18457,0.05176-0.25635c-0.00977-0.01611-0.0293-0.02734-0.04688-0.03955
      c-0.02148-0.01514-0.04883-0.02539-0.07813-0.03174C440.79395,219.30713,440.69434,219.32031,440.59766,219.37402z"/>
    <path class="spa-i-0" d="M388.21338,284.50293c1.72559,1.80957,3.76221,3.24707,6.01221,4.19629
      c1.95117,0.82422,4.06104,1.28125,6.26563,1.29688c0.04102,0.00098,0.08105,0.00391,0.12207,0.00391
      c2.13184,0,4.17578-0.41602,6.07617-1.17383c0.66992-0.26758,1.32227-0.57715,1.95508-0.92773
      c0.65625-0.36328,1.28809-0.77148,1.89648-1.21875c0.49512-0.36426,0.97461-0.75391,1.43555-1.16992
      c0.60254-0.54297,1.17383-1.12891,1.71094-1.75293c0.40332-0.46875,0.78711-0.95898,1.14941-1.46973
      c0.55859-0.78711,1.06445-1.62305,1.51758-2.49902c0.75586-1.4668,1.35547-3.04785,1.77246-4.71582
      c0.4209-1.68359,0.65527-3.45508,0.67578-5.2832c0.00098-0.08984,0.00684-0.17773,0.00684-0.26855
      c0-11.30957-8.14648-20.47949-18.19629-20.47949c-0.05664,0-0.1123,0.00391-0.16797,0.00488
      c-4.63281,0.04785-8.85156,2.04004-12.0415,5.29102c-1.85791,1.89355-3.36279,4.21582-4.40039,6.82422
      c-1.0166,2.55371-1.58594,5.37988-1.58594,8.35938c0,0.09082,0.00439,0.17871,0.00537,0.26855
      C382.48926,275.59766,384.70313,280.82031,388.21338,284.50293z M387.82275,269.47461
      c0-8.29297,5.73145-15.01758,12.80127-15.01758c7.07129,0,12.80273,6.72461,12.80273,15.01758
      c0,8.29492-5.73145,15.01953-12.80273,15.01953C393.5542,284.49414,387.82275,277.76953,387.82275,269.47461z"/>
    <path class="spa-i-9" d="M400.62402,284.49414c7.07129,0,12.80273-6.72461,12.80273-15.01953
      c0-8.29297-5.73145-15.01758-12.80273-15.01758c-7.06982,0-12.80127,6.72461-12.80127,15.01758
      C387.82275,277.76953,393.5542,284.49414,400.62402,284.49414z M404.4082,276.81641l-0.54883,1.10742
      c-0.18066-0.41211-0.60449-0.63672-1.09766-0.63672c-0.66016,0-1.18555,0.50293-1.18555,1.12988
      c0,0.10059,0,0.19043,0.02148,0.27832l1.36621,0.01367h0.01172v1.15137h-0.01172h-1.20898l0.89551,1.80078l-1.19727-0.46973
      l-0.94043,1.01855l-0.90625-1.01855l-1.19727,0.46973l0.90625-1.80078h-1.22021v-1.15137l1.36572-0.01367
      c0.03418-0.08789,0.04395-0.17773,0.04395-0.27832c0-0.62695-0.54688-1.12988-1.2085-1.12988
      c-0.49219,0-0.91699,0.22461-1.09619,0.63672l-0.54736-1.10742c-0.11279-0.21289-0.26855-0.61426-0.26855-0.86133
      c0-0.88281,0.74951-1.58887,1.67822-1.58887c0.72656,0,1.3418,0.4375,1.57813,1.05176c0,0-0.63867-1.28711-0.63867-2.8418
      c0-1.5459,1.51074-3.20117,1.51074-3.20117s1.55566,1.65527,1.55566,3.20117c0,1.32715-0.46094,2.45117-0.59766,2.75391
      c0.24707-0.56641,0.83496-0.96387,1.53809-0.96387c0.91797,0,1.66797,0.70605,1.66797,1.58887
      C404.67676,276.20215,404.51855,276.60352,404.4082,276.81641z M404.01563,264.13867c0.72754,0,1.34375,0.43555,1.57813,1.05176
      c0,0-0.63672-1.28809-0.63672-2.84473c0-1.54297,1.50879-3.19922,1.50879-3.19922s1.55664,1.65625,1.55664,3.19922
      c0,1.32813-0.46191,2.45215-0.59668,2.75684c0.24707-0.56836,0.83496-0.96387,1.53711-0.96387
      c0.91699,0,1.66602,0.7041,1.66602,1.58789c0,0.24609-0.15527,0.64844-0.26758,0.86328l-0.54883,1.10645
      c-0.17871-0.41504-0.60547-0.63672-1.09668-0.63672c-0.66016,0-1.1875,0.50195-1.1875,1.12891c0,0.10059,0,0.19043,0.02344,0.28027
      l1.36426,0.01074h0.01172v1.15234h-0.01172h-1.20898l0.89648,1.80176l-1.19727-0.4707l-0.94043,1.01953l-0.90723-1.01953
      l-1.19727,0.4707l0.9082-1.80176h-1.21973v-1.15234l1.36523-0.01074c0.03223-0.08984,0.04297-0.17969,0.04297-0.28027
      c0-0.62695-0.54785-1.12891-1.20801-1.12891c-0.49121,0-0.91797,0.22168-1.09473,0.63672l-0.54883-1.10645
      c-0.11328-0.21484-0.26855-0.61719-0.26855-0.86328C402.33789,264.84277,403.08691,264.13867,404.01563,264.13867z
      M392.11914,264.13867c0.72852,0,1.34375,0.43555,1.57813,1.05176c0,0-0.6377-1.28809-0.6377-2.84473
      c0-1.54297,1.51074-3.19922,1.51074-3.19922h0.00098c0,0,1.5542,1.65625,1.5542,3.19922c0,1.33398-0.46582,2.46387-0.59961,2.76172
      c0.24756-0.57031,0.83545-0.96875,1.54004-0.96875c0.91748,0,1.66748,0.7041,1.66748,1.58789
      c0,0.24609-0.15723,0.64844-0.26855,0.86328l-0.54834,1.10645c-0.17969-0.41504-0.60352-0.63672-1.09619-0.63672
      c-0.66016,0-1.18604,0.50195-1.18604,1.12891c0,0.10059,0,0.19043,0.02148,0.28027l1.36621,0.01074h0.01074v1.15234h-0.01074
      h-1.2085l0.89404,1.80176l-1.19678-0.4707l-0.93945,1.01953l-0.90625-1.01953l-1.19824,0.4707l0.90576-1.80176h-1.21973v-1.15234
      l1.36523-0.01074c0.03418-0.08984,0.04492-0.17969,0.04492-0.28027c0-0.62695-0.54736-1.12891-1.20898-1.12891
      c-0.49268,0-0.9165,0.22168-1.09619,0.63672l-0.54834-1.10645c-0.11133-0.21484-0.26807-0.61719-0.26807-0.86328
      C390.44141,264.84277,391.19092,264.13867,392.11914,264.13867z"/>
    <path class="spa-i-3" d="M391.25781,267.69629c0.17969-0.41504,0.60352-0.63672,1.09619-0.63672
      c0.66162,0,1.20898,0.50195,1.20898,1.12891c0,0.10059-0.01074,0.19043-0.04492,0.28027l-1.36523,0.01074h4.86914l-1.36621-0.01074
      c-0.02148-0.08984-0.02148-0.17969-0.02148-0.28027c0-0.62695,0.52588-1.12891,1.18604-1.12891
      c0.49268,0,0.9165,0.22168,1.09619,0.63672l0.54834-1.10645c0.11133-0.21484,0.26855-0.61719,0.26855-0.86328
      c0-0.88379-0.75-1.58789-1.66748-1.58789c-0.70459,0-1.29248,0.39844-1.54004,0.96875
      c0.13379-0.29785,0.59961-1.42773,0.59961-2.76172c0-1.54297-1.5542-3.19922-1.5542-3.19922h-0.00098
      c0,0-1.51074,1.65625-1.51074,3.19922c0,1.55664,0.6377,2.84473,0.6377,2.84473c-0.23438-0.61621-0.84961-1.05176-1.57813-1.05176
      c-0.92822,0-1.67773,0.7041-1.67773,1.58789c0,0.24609,0.15674,0.64844,0.26807,0.86328L391.25781,267.69629z"/>
    <polygon class="spa-i-3" points="393.37256,269.63184 392.4668,271.43359 393.66504,270.96289 394.57129,271.98242 
      395.51074,270.96289 396.70752,271.43359 395.81348,269.63184 	"/>
    <polygon class="spa-i-3" points="392.15283,268.47949 392.15283,269.63184 393.37256,269.63184 395.81348,269.63184 
      397.02197,269.63184 397.03271,269.63184 397.03271,268.47949 397.02197,268.47949 	"/>
    <polygon class="spa-i-3" points="404.36133,271.43359 405.55859,270.96289 406.46582,271.98242 407.40625,270.96289 
      408.60352,271.43359 407.70703,269.63184 405.26953,269.63184 	"/>
    <path class="spa-i-3" d="M403.15527,267.69629c0.17676-0.41504,0.60352-0.63672,1.09473-0.63672
      c0.66016,0,1.20801,0.50195,1.20801,1.12891c0,0.10059-0.01074,0.19043-0.04297,0.28027l-1.36523,0.01074h4.86621l-1.36426-0.01074
      c-0.02344-0.08984-0.02344-0.17969-0.02344-0.28027c0-0.62695,0.52734-1.12891,1.1875-1.12891
      c0.49121,0,0.91797,0.22168,1.09668,0.63672l0.54883-1.10645c0.1123-0.21484,0.26758-0.61719,0.26758-0.86328
      c0-0.88379-0.74902-1.58789-1.66602-1.58789c-0.70215,0-1.29004,0.39551-1.53711,0.96387
      c0.13477-0.30469,0.59668-1.42871,0.59668-2.75684c0-1.54297-1.55664-3.19922-1.55664-3.19922s-1.50879,1.65625-1.50879,3.19922
      c0,1.55664,0.63672,2.84473,0.63672,2.84473c-0.23438-0.61621-0.85059-1.05176-1.57813-1.05176
      c-0.92871,0-1.67773,0.7041-1.67773,1.58789c0,0.24609,0.15527,0.64844,0.26855,0.86328L403.15527,267.69629z"/>
    <polygon class="spa-i-3" points="404.0498,269.63184 405.26953,269.63184 407.70703,269.63184 408.91602,269.63184 
      408.92773,269.63184 408.92773,268.47949 408.91602,268.47949 404.0498,268.47949 	"/>
    <path class="spa-i-3" d="M403.00879,274.36621c-0.70313,0-1.29102,0.39746-1.53809,0.96387
      c0.13672-0.30273,0.59766-1.42676,0.59766-2.75391c0-1.5459-1.55566-3.20117-1.55566-3.20117s-1.51074,1.65527-1.51074,3.20117
      c0,1.55469,0.63867,2.8418,0.63867,2.8418c-0.23633-0.61426-0.85156-1.05176-1.57813-1.05176
      c-0.92871,0-1.67822,0.70605-1.67822,1.58887c0,0.24707,0.15576,0.64844,0.26855,0.86133l0.54736,1.10742
      c0.1792-0.41211,0.604-0.63672,1.09619-0.63672c0.66162,0,1.2085,0.50293,1.2085,1.12988
      c0,0.10059-0.00977,0.19043-0.04395,0.27832l-1.36572,0.01367h4.86865l-1.36621-0.01367
      c-0.02148-0.08789-0.02148-0.17773-0.02148-0.27832c0-0.62695,0.52539-1.12988,1.18555-1.12988
      c0.49316,0,0.91699,0.22461,1.09766,0.63672l0.54883-1.10742c0.11035-0.21289,0.26855-0.61426,0.26855-0.86133
      C404.67676,275.07227,403.92676,274.36621,403.00879,274.36621z"/>
    <polygon class="spa-i-3" points="399.31543,279.86035 398.40918,281.66113 399.60645,281.19141 400.5127,282.20996 
      401.45313,281.19141 402.65039,281.66113 401.75488,279.86035 	"/>
    <polygon class="spa-i-3" points="398.09521,278.70898 398.09521,279.86035 399.31543,279.86035 401.75488,279.86035 
      402.96387,279.86035 402.97559,279.86035 402.97559,278.70898 402.96387,278.70898 	"/>
  </g>
  </svg>
</template>

<script>
export default {
  name: "spainIcon"
}
</script>

  <style type="text/css">
    .spa-i-0{fill:#AD1519;}
    .spa-i-1{fill:#ED72AA;}
    .spa-i-2{fill:#C60B1E;}
    .spa-i-3{fill:#C8B100;}
    .spa-i-4{fill:#FFC400;}
    .spa-i-5{fill:#FFD691;}
    .spa-i-6{fill:#FFFFFF;}
    .spa-i-7{fill:#CCCCCC;}
    .spa-i-8{fill:#0039F0;}
    .spa-i-9{fill:#005BBF;}
    .spa-i-10{fill:#058E6E;}
    .spa-i-11{fill:#DB4446;}
  </style>

#applicator-map-search.landing {
  box-shadow: none;
  display: none;

  @media screen and (max-width: 1024px) {
    display: flex;
  }
}

#applicator-map-search.initial {
  display: block;
  box-shadow: none;
  background-color: inherit;

  #applicator-map-search-input {
    width: 95%;
    max-width: 600px;
    margin: 0 auto;

    > input::placeholder {
      text-align: center;
    }
  }
  #applicator-map-search-center {
    justify-content: center;
  }
}

#applicator-map-search {
  box-shadow: 0 10px 20px rgba(black, 0.09), 0 6px 6px rgba(black, 0.13);
  background-color: #f9fafc;
  flex-direction: row;
  padding: 12px;
  display: flex;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    box-shadow: 0 -1px 2px 0 rgba(black, 0.15);
    transition: transform 200ms ease-in-out;
    padding: 24px var(--sidebar-padding);
    transform: translate3d(0, 100%, 0);
    border-top: 1px solid #cccccc;
    flex-direction: column;
    height: 0px;

    > div + div {
      margin-top: 12px;
    }

    &[visible-on-mobile] {
      transform: translate3d(0, 0, 0);
      height: auto;
      pointer-events: all;
    }
  }

  .mobile-toggle {
    transform: translate3d(-50%, 0, 0);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: #f9fafc;
    border: 1px solid #cccccc;
    justify-content: center;
    align-items: center;
    border-bottom: none;
    visibility: hidden;
    position: absolute;
    padding: 0 16px;
    display: none;
    outline: none;
    height: 40px;
    bottom: 100%;
    left: 50%;
    pointer-events: all;

    > .fa {
      margin-left: 6px;
    }

    &:focus {
      outline: none;
    }

    @media screen and (max-width: 1024px) {
      visibility: visible;
      display: flex;
    }
  }

  &-toggle-filters {
    display: none !important;

    @media screen and (max-width: 1024px) {
      display: flex !important;
    }
  }

  &-left,
  &-right,
  &-center {
    // flex-direction: row;
    // align-items: center;
    // display: flex;

    @media screen and (max-width: 1024px) {
      > * {
        // flex: 1;
      }
    }
  }

  &-left,
  &-center {
    // justify-content: flex-start;
    // flex: 0 1 auto;

    > * + * {
      margin-left: 16px;
    }
  }

  &-center {
    margin: 0 16px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      margin: 0;
    }
  }

  &-right {
    // justify-content: flex-end;
    // flex: 1;

    > * + * {
      margin-right: 16px;
    }

    @media screen and (max-width: 1024px) {
      justify-content: flex-start;

      > * + * {
        margin-left: 16px;
        margin-right: 0;
      }
    }
  }

  &-input.initial {
    width: 95%;
  }

  &-input {
    transition: border-color 200ms ease-in-out;
    border: 1px solid #cccccc;
    background-color: white;
    flex-direction: row;
    display: flex;
    width: 100%;

    @media screen and (max-width: 1024px) {
      margin-right: 0;
      flex: 1;
      opacity: 1;
    }

    > input,
    > button {
      background-color: transparent;
      outline: none;
      height: 40px;
      border: 0;

      &:focus {
        outline: none;
      }
    }

    > input {
      padding-left: 12px;
      flex: 1;

      @media screen and (max-width: 1024px) {
        min-width: 0;
        opacity: 1;
      }
    }

    > button {
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #f69422;
      display: flex;
      width: 40px;
    }

    &:focus-within {
      border-color: #5c9ead;
    }
  }

  &-input.hideDesktop {
    opacity: 0;

    @media screen and (max-width: 1024px) {
      opacity: 1;
    }
  }

  button[type='button'] {
    margin: 0 auto;
    border: 1px solid #cccccc;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 4px;
    padding: 0 12px;
    display: flex;
    height: 40px;
    min-width: 155px;

    > i {
      margin-right: 6px;
      font-size: 18px;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}

<template>
<div id="addressForm" class="my-account">
  <div class="heading-bordered heading-account">
    <h2><span>Manage Your {{ addressType }} Addresses</span></h2>
  </div>
  <h5>
    <a href="#" @click.prevent="showShippingForm = true" class="add-shipping-option">
      <i class="fa fa-plus" aria-hidden="true"></i>
      Add {{ addressType }} address
    </a>
    <input class="form-control" type="text" v-model="addressSearchText" :id="addressType + 'Search'" placeholder="Filter Addresses..." @input="filterAddresses">
  </h5>
  <div class="row shipping-options" v-show="showShippingForm">
    <div class="alert alert-danger color-error msgblock" v-if="formErrors">
      <ul style="padding-left: 20px;">
        <li v-for="(message,index) in formError" :key="index">
          {{ message }}<br v-if="index < formError.length - 1">
        </li>
      </ul>
    </div>
    <form @submit.prevent="addShippingAddress">
      <div class="form-group">
        <label class="col-sm-2 text-right" for="name">Name*</label>
        <div :class="addressType == 'shipping' ? 'col-sm-4' : 'col-sm-10'">
          <input name="name" class="form-control" type="text"
          v-model="newAddress.name.given_name"
          @input="newAddress.name.given_name = formatName($event.target.value)" autocomplete="name" required>
        </div>
        <label class="col-sm-2 text-right" for="phone" v-show="addressType == 'shipping'">Phone Number*</label>
        <div class="col-sm-4" v-show="addressType == 'shipping'">
          <input type="tel" name="tel" class="form-control" :class="{'has-error' : errors.has('phone_number')}" maxlength="30"
              v-validate="'required'"
              v-model="newAddress.phone_number"
              autocomplete="tel"
              @input="newAddress.phone_number = formatPhone($event.target.value)" required>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 text-right" for="street_address">Street Address*</label>
        <div class="col-sm-4">
          <input class="form-control" type="text" name="address-line1" maxlength="30"
                v-model="newAddress.street_address"
                @input="newAddress.street_address = formatAddress($event.target.value)" autocomplete="address-line1" required>
        </div>
        <label class="col-sm-2 text-right" for="street_address_2">Street Address Line 2</label>
        <div class="col-sm-4">
          <input class="form-control" type="text" name="address-line2" maxlength="30"
                v-model="newAddress.street_address_2"
                @input="newAddress.street_address_2 = formatAddress($event.target.value)" autocomplete="address-line2">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 text-right" for="country_code">Country*</label>
        <div class="col-sm-4">
          <span class="styled-select">
            <select name="country" type="text" v-model="newAddress.country_code" class="form-control" @change="updatedCountry()" autocomplete="country" required>
              <option value="USA">United States</option>
              <option value="CAN">Canada</option>
              <option disabled="disabled">--------------</option>
              <option v-for="country in countries" :value="country.code" :key="country.index">
                {{ country.long_name }}
              </option>
            </select>
          </span>
        </div>
        <label class="col-sm-2 text-right" for="postal_code">
          Postal Code {{ (postalCheck(newAddress.country_code)) ? '*': '' }}
        </label>
        <div class="col-sm-4">
          <input class="form-control" name="postal-code" type="text" maxlength="10"
                v-model="newAddress.postal_code"
                autocomplete="postal-code"
                @input="newAddress.postal_code = formatPostalCode($event.target.value)"
                :disabled="!postalCheck(newAddress.country_code)" :required="postalCheck(newAddress.country_code)">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 text-right" for="city">City*</label>
        <div class="col-sm-4">
          <input class="form-control" type="text" name="address-level2"
                v-model="newAddress.city"
                @input="newAddress.city = formatAddress($event.target.value)" autocomplete="address-level2" required>
        </div>
        <label class="col-sm-2 text-right" for="city">
          State {{ (regionCheck()) ? '*' : ''}}
        </label>
        <div class="col-sm-4">
          <span class="styled-select">
            <select name="address-level1" type="text" v-model="newAddress.region_code" class="form-control" :disabled="!isStateRequired(newAddress.country_code)" :required="isStateRequired(newAddress.country_code) && regionCheck(newAddress.country_code)" autocomplete="address-level1">
              <option v-for="region in regions" :value="region.code" :key="region.index">
                {{ region.name }}
              </option>
            </select>
          </span>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-10 col-sm-offset-2">
          <div class="row">
            <div class="col-sm-6">
              <a href="#" class="btn btn-block btn-black" @click.prevent="closeUpdateAddress()" v-if="newAddress.id">Cancel</a>
              <a href="#" class="btn btn-block btn-black" @click.prevent="showShippingForm = false" v-else>Cancel</a>
            </div>
            <div class="col-sm-6">
              <input type="submit" class="btn btn-block btn-blue" value="Update Address" v-if="newAddress.id" :disabled="processing">
              <input type="submit" class="btn btn-block btn-blue" value="Add Address" v-else :disabled="processing">
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="wrap-accordion" v-show="!showShippingForm">
    <div id="accordionShipping" class="accordion-in" role="tablist" aria-multiselectable="true">
      <div class="card shadow-box" v-for="(shippingAddress, index) in shippingAddresses" :id="shippingAddress.id" :key="index">
        <div class="accordion-head">
          <a class="collapsed" data-toggle="collapse" data-parent="#accordionShipping" :href="`#collapseShipment${shippingAddress.id}`" aria-expanded="true" aria-controls="collapseOne">
            <i class="fa fa-angle-up" aria-hidden="true"></i>
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </a>
          <div class="col-sm-5 col-md-4">
            <span>
              {{ shippingAddress.name.given_name }}
            </span>
          </div>
          <div class="col-sm-7 col-md-8">
            <span>
              {{ shippingAddress.street_address }}
              {{ shippingAddress.street_address_2 }}
            </span>
          </div>
        </div>
        <div :id="`collapseShipment${shippingAddress.id}`" class="collapse col-sm-12" role="tabpanel" aria-labelledby="headingOne">
          <div class="row">
            <div class="col-sm-5 col-md-4">
              <h3>Name</h3>
              <span>
                {{ shippingAddress.name.given_name }}<br>
                {{ shippingAddress.company }}
              </span>
            </div>
            <div class="col-sm-7 col-md-8">
              <h3>
                {{ shippingAddress.type }}
                Address
              </h3>
              <address>
                {{ shippingAddress.street_address }}
                {{ shippingAddress.street_address_2 }}<br>
                {{ shippingAddress.city }},
                <span v-if="shippingAddress.region !== null && shippingAddress.region !== undefined">
                  <span v-if="shippingAddress.region.code !== null && shippingAddress.region.code !== undefined">{{ shippingAddress.region.code }}</span>
                  <span v-else>{{ shippingAddress.region.name }}</span></span>
                <span v-else><span>{{ shippingAddress.region_code }}</span></span>
                {{ shippingAddress.postal_code }}<br>
                <span v-if="shippingAddress.country !== null && shippingAddress.country !== undefined">
                  {{ shippingAddress.country.long_name }}</span>
                <span v-else><span>{{ shippingAddress.country_code }}</span></span>
              </address>
            </div>
          </div>
          <div class="text-right">
            <a href="#addressForm" class="btn btn-blue" @click.prevent="editShippingAddress(shippingAddress, index)">Edit</a>
            <a href="#" class="btn btn-blue" @click.prevent="deleteShippingAddress(shippingAddress)">Delete</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.shipping-options {
  margin-top: 20px;
}
</style>

<script>
import postalRequired from '../../mixins/postal_required';
import formatting from '../../mixins/formatting';
import countries from '../../mixins/countries';

export default {
  name: 'address-list',

  components: {
    AddressForm: require('../checkout/AddressForm.vue').default
  },

  props: {
    addressType: {
      type: String,
      required: true
    }
  },

  mixins: [formatting, postalRequired, countries],

  data() {
    return {
      showShippingForm: false,
      addresses: window._PR?.addresses?.data || [],
      newAddress: {
        city: "",
        company: "",
        country_code: "",
        name: {
          given_name: ""
        },
        postal_code: "",
        street_address: "",
        street_address_2: "",
        region_code: "",
        type: this.addressType
      },
      addressSearchText: '',
      editAddress: {},
      editAddressIndex: '',
      countries: [],
      regions: [],
      postalRequired: [],
      regionRequired: [],
      shippingAddresses: [],
      formErrors: null,
      processing: false
    }
  },

  computed: {
    addressRegex() {
      return new RegExp("[^\\w'\\-\\s\\.,#\\/]", "gu");
    },
    postalRegex() {
      return new RegExp("[^\\w'\\-\\s\\-.\\/]", "gu");
    },
    nameRegex() {
      return /(^|[^\-a-z])n[\/\-\s\\]*a([^\-a-z]|$)/i;
    },
    specialCharRegex() {
      return new RegExp("[^\x00-\x7F]+", "gu");
    },
    poBoxRegex() {
      return /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i;
    },
    formError() {
      return this.formErrors.split('|');
    },
    filteredAddress() {
      if (this.newAddress) {
        let address = this.newAddress;
        let payload = {
          city: address.city,
          company: address.company,
          country_code: address.country_code,
          name: {
            given_name: address.name.given_name
          },
          postal_code: address.postal_code.replace(/_/g, ""),
          region_code: address.region_code,
          street_address: address.street_address,
          street_address_2: address.street_address_2,
          type: address.type
        };


        if (this.addressType === 'shipping') {
          payload['phone_number'] = address.phone_number;
        }

        return payload;
      }
    }
  },

  created() {
    this.shippingAddresses = this.addresses.filter(address => address.type === this.addressType);
  },

  watch: {
    showShippingForm() {
      let vm = this;
      if (vm.showShippingForm) {
        vm.getCountries();
        if (vm.countries.length) {
          vm.postalRequired = vm.countries.filter(function(elem) {
              if (elem.postal_required == true) return elem.code;
            }).map(a => a.code);
          vm.regionRequired = vm.countries.filter(function(elem) {
              if (elem.region_required == true) return elem.code;
            }).map(a => a.code);
        }
      }
    }
  },

  methods: {
    isStateRequired() {
      let code = this.newAddress.country_code;
      return ['CAN', 'USA', 'JAM', 'IND', 'AUS'].includes(code);
    },
    postalCheck() {
      return _.includes(this.postalRequired, this.newAddress.country_code);

    },
    regionCheck() {
      return _.includes(this.regionRequired, this.newAddress.country_code);

    },
    filterAddresses() {
      if (this.addressSearchText !== null && this.addressSearchText !== undefined && this.addressSearchText !== "") {
        this.shippingAddresses = this.addresses.filter((address) =>
          address.type === this.addressType &&
          (
            (address.company != null ? address.company.toLowerCase().includes(this.addressSearchText.toLowerCase()) : false) ||
            address.name.given_name.toLowerCase().includes(this.addressSearchText.toLowerCase()) ||
            address.street_address.toLowerCase().includes(this.addressSearchText.toLowerCase()) ||
            address.street_address_2.toLowerCase().includes(this.addressSearchText.toLowerCase()) ||
            address.city.toLowerCase().includes(this.addressSearchText.toLowerCase()) ||
            address.postal_code.includes(this.addressSearchText)
          )
        );
      } else {
        this.shippingAddresses = this.addresses.filter(address => address.type === this.addressType);
      }
    },
    async updatedCountry() {

      if (!this.isPostalCodeRequired(this.newAddress.country_code)) {
        // If user has switched from a country where postal code is required to non required then remove the previously filled value
        this.newAddress.postal_code = '';
      }

      if (this.isStateRequired()) {
        let regionsUrl = '/api/proxify/domains/cerakote/shop/countries/' + this.newAddress.country_code + '/regions';

        try {
            let response = await axios.get(regionsUrl);
            this.regions = response.data;

            if (this.newAddress.id) {
                if (this.editAddress.region) {
                    this.newAddress.region_code = this.editAddress.region.code;
                } else {
                    this.newAddress.region_code = this.editAddress.region_code;
                }
            }
        } catch (error) {
            console.error(error);
        }
      } else {
        // If user has switched from a country where state is required to non required then remove the previously filled value
        this.newAddress.region_code = '';
        this.newAddress.region = '';
      }

    },
    verifyAddressLines() {
      if (this.addressType === 'shipping') {
        let addressRegex = this.poBoxRegex;
        if (addressRegex.test(this.newAddress.street_address)) {
          this.formErrors = 'Invalid Address. The address cannot contain a PO Box.';
        }
      }
    },
    verifyName() {
      let nameRegex = this.nameRegex;
      if (nameRegex.test(this.newAddress.name.given_name)) {
        if (this.formErrors) {
          this.formErrors = this.formErrors + '| Invalid Name. The name on the address cannot include N/A';
        } else {
          this.formErrors = 'Invalid Name. The name on the address cannot include N/A';
        }
      }

      let specialCharRegex = this.specialCharRegex;
      if (specialCharRegex.test(this.newAddress.name.given_name)) {
        if (this.formErrors) {
          this.formErrors = this.formErrors + '| Invalid Name. The name on the address cannot include special characters';
        } else {
          this.formErrors = 'Invalid Name. The name on the address cannot include special characters';
        }
      }

      if (specialCharRegex.test(this.newAddress.street_address) ||
          specialCharRegex.test(this.newAddress.city)) {
        if (this.formErrors) {
          this.formErrors = this.formErrors + '| Invalid address. The address cannot include special characters';
        } else {
          this.formErrors = 'Invalid address. The address cannot include special characters';
        }
      }
    },
    addShippingAddress() {

      let vm = this;
      vm.processing = true;
      const newAddress = Object.assign({}, vm.newAddress);
      // vm.newAddress.formatted_full = newAddress.company + " " + newAddress.name.given_name + " " + newAddress.street_address + " " + newAddress.city + " " + newAddress.region_code + " " + newAddress.postal_code;

      vm.formErrors = null;
      vm.verifyAddressLines();
      vm.verifyName();

      if (this.formErrors) {
        vm.processing = false;
        return;
      }

      if (vm.newAddress.id) {
        const index = vm.editAddressIndex;

        if (vm.newAddress.country_code !== vm.filteredAddress.country_code) {
          vm.filteredAddress.country_code = vm.newAddress.country_code;
          vm.filteredAddress.region_code = vm.newAddress.region_code;
        }

        // If region field exists for region and postal check validation, then delete before posting
        if (vm.newAddress.hasOwnProperty('region')) {
          delete vm.newAddress.region;
        }

        axios.patch(`/api/proxify/me/addresses/${newAddress.id}`, vm.filteredAddress)
          .then(response => {
            vm.newAddress = {
              city: "",
              company: "",
              country_code: "",
              name: {
                given_name: ""
              },
              postal_code: "",
              street_address: "",
              street_address_2: "",
              region_code: ""
            };

            delete newAddress.created_at;
            delete newAddress.last_used_at;
            delete newAddress.updated_at;
            delete newAddress.country;
            delete newAddress.region;
            delete newAddress.formatted_full;
            delete newAddress.formatted_medium;
            delete newAddress.formatted_short;
            delete newAddress.owner;
            delete newAddress.name.family_name;
            delete newAddress.name.formatted_name;
            delete newAddress.name.display_name;
            delete newAddress.name.middle_name;
            vm.shippingAddresses.splice(index, 1);
            vm.addresses.unshift(newAddress);
            vm.shippingAddresses.push(newAddress);
            vm.showShippingForm = false;
            vm.processing = false;
            vm.formErrors = null;
          })
          .catch(response => {
            console.log(response);
            alert(response);
            vm.processing = false;
          })
      } else {
        delete vm.newAddress.originalData;
        delete vm.newAddress.errors;
        delete vm.newAddress.processing;
        delete vm.newAddress.initial;
        delete vm.newAddress.__options;

        // If region field exists for region and postal check validation, then delete before posting
        if (vm.newAddress.hasOwnProperty('region')) {
          delete vm.newAddress.region;
        }

        axios.post(`/api/proxify/me/addresses`, vm.newAddress)
          .then(response => {
            newAddress.id = response.data.id;
            vm.addresses.unshift(newAddress);
            vm.showShippingForm = false;
            vm.processing = false;
            vm.shippingAddresses.push(newAddress);
          })
          .catch(response => {
            console.log(response);
            alert(response);
            vm.processing = false;
          })
      }
    },
    editShippingAddress(address, index) {
      const element = `#${address.id} .accordion-head > a`;

      this.editAddressIndex = index;
      this.newAddress = address;

      if (address.country) {
        this.newAddress.country_code = address.country.code;
      } else {
        this.newAddress.country_code = address.country_code;
      }

      this.updatedCountry();
      this.editAddress = address;
      $(element).click();
      this.scrollTo(`#${this.addressType}-addresses`);
      this.showShippingForm = true;
    },
    scrollTo(element, offset = 140) {
      let target = 0;

      if (element !== 'top') {
        target = $(element).offset().top - offset;
      }

      $('html, body').animate({
        scrollTop: target,
      }, 500);
    },
    closeUpdateAddress() {
      let vm = this;
      vm.newAddress = {
        city: "",
        company: "",
        country_code: "",
        name: {
          given_name: ""
        },
        postal_code: "",
        street_address: "",
        street_address_2: "",
        region_code: ""
      }
      vm.showShippingForm = false;
    },
    deleteShippingAddress(address) {
      let vm = this;
      axios({
          url: `/api/proxify/me/addresses/${address.id}`,
          method: 'delete',
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        })
        .then(response => {
          $('#' + address.id).remove();
          vm.addresses = vm.addresses.filter(addr => addr.id !== address.id);
        })
        .catch(response => alert(response));
    }
  }
}
</script>

<style lang="scss" scoped>
  .shipping-options {
    margin-top: 20px;
  }
  h5 {
    .add-shipping-option {
      float: right;
    }
    input {
      margin-top: 20px;
    }
  }
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06453 1.60566">
    <defs>
      <clipPath id="clip-path" transform="translate(0.44485 0.01462)">
        <circle class="it-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.44485 0.01462)">
        <rect class="it-i-1" x="-0.44485" y="-0.01462" width="2.06453" height="1.20406"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="it-i-2">
          <g id="Italy">
            <g>
              <path d="M.44792,1.41924v.12922H.43323V1.41924Z" transform="translate(0.44485 0.01462)"/>
              <path d="M.50151,1.43319v.022h.025V1.4655h-.025v.05066c0,.014.00367.02129.01034.02129.00734,0,.00734-.00367.011-.00367v.01468h-.014c-.00734,0-.01468,0-.01835-.00734a.031.031,0,0,1-.00734-.022V1.4655H.46921V1.45522H.48316V1.43686Z" transform="translate(0.44485 0.01462)"/>
              <path d="M.6087,1.52644a.04655.04655,0,0,0,.00367.022H.59475v-.011h0a.04115.04115,0,0,1-.02863.011c-.022,0-.02863-.011-.02863-.0257,0-.02129.01762-.03524.05359-.03524h0c0-.00734,0-.022-.01835-.022a.03013.03013,0,0,0-.025.00734l-.00367-.011a.0441.0441,0,0,1,.02863-.01028.03441.03441,0,0,1,.036.03891Zm-.014-.025c-.022-.00367-.03964,0-.03964.01762a.01679.01679,0,0,0,.01468.01836c.01028,0,.02129-.00734.02129-.01469l.00367-.0066Z" transform="translate(0.44485 0.01462)"/>
              <path d="M.63734,1.4119H.652v.13656H.63734Z" transform="translate(0.44485 0.01462)"/>
              <path d="M.68805,1.45522l.02124.0536c0,.00734.00367.01394.00367.02129H.71663c0-.00735.00367-.014.00367-.02129l.01762-.0536H.75554l-.025.06387a.11614.11614,0,0,1-.03231.06168A.03224.03224,0,0,1,.68071,1.591L.67337,1.57709a.0488.0488,0,0,0,.0183-.011l.014-.01762V1.54112l-.036-.0859Z" transform="translate(0.44485 0.01462)"/>
            </g>
            <g class="it-i-3">
              <rect class="it-i-4" width="0.68793" height="1.20406"/>
              <rect class="it-i-5" x="0.68793" width="0.68867" height="1.20406"/>
              <rect class="it-i-6" x="1.3766" width="0.68793" height="1.20406"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "italyIcon"
}
</script>

<style>
  .it-i-1 {
    fill: none;
  }

  .it-i-2 {
    clip-path: url(#clip-path);
  }

  .it-i-3 {
    clip-path: url(#clip-path-2);
  }

  .it-i-4 {
    fill: #3d9348;
  }

  .it-i-5 {
    fill: #f4f4f4;
  }

  .it-i-6 {
    fill: #d80031;
  }
</style>
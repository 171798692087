
$imgDir: '../../../img/';

.slide-enter-active,
.slide-leave-active {
  transition: transform 250ms cubic-bezier(0.5, 0, 0.15, 0.8);
}

.slide-enter,
.slide-leave-to {
  transform: translate3d(-100%, 0, 0);
}

#applicator-map.applicator-map-overlay {
  min-height:auto;
}

#applicator-map {
  --applicator-border-radius: 4px;
  --applicator-filters-width: 280px;
  --sidebar-padding: 32px;
  --sidebar-width: 750px;

  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  z-index: 11;
  width: 100%;

  min-height: 840px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  &-controls {
    right: var(--sidebar-padding);
    left: var(--sidebar-padding);
    top: var(--sidebar-padding);
    align-items: flex-start;
    pointer-events: none;
    flex-direction: row;
    position: absolute;
    z-index: 2;
    display: flex;

    &-left,
    &-right {
      flex-direction: row;
      display: flex;
      flex: 1;
    }

    &-left {
      justify-content: flex-start;

      .map-action + .map-action {
        margin-left: 16px;
      }
    }

    &-right {
      justify-content: flex-end;

      .map-action + .map-action {
        margin-right: 16px;
      }
    }
  }

  .map-action {
    box-shadow: 0 1px 2px 0 rgba(black, 0.15);
    transition: box-shadow 200ms ease-in-out;
    background-color: white;
    pointer-events: all;
    position: relative;
    border-radius: 4px;
    padding: 8px;
    z-index: 2;

    &:hover,
    &:focus-within {
      box-shadow: 0 10px 20px rgba(black, 0.19), 0 6px 6px rgba(black, 0.23);
    }

    @media screen and (max-width: 1024px) {
      border-radius: 0;
    }
  }

  &-filter,
  &-search,
  &-zoom,
  &-view,
  &-sidebar,
  &-list {
    display: flex;
  }

  &-sidebar,
  &-search,
  &-list,
  &-zoom,
  &-view {
    flex-direction: column;
  }

  &-zoom {
    z-index: 2;
    padding: 0;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    &-in,
    &-out {
      justify-content: center;
      align-items: center;
      background: none;
      display: flex;
      height: 40px;
      width: 40px;
      border: 0;
    }
  }

  &-view {
    position: relative;
    outline: none;
    flex: 1;

    @media screen and (max-width: 1024px) {
      pointer-events: none;
      visibility: hidden;
      position: absolute;
      // height: 100vh;
      width: 100vw;
    }

    &-init {
      outline: none;
      width: 100%;
      flex: 1;
    }
  }

  &-markers {
    visibility: hidden;
    display: none;
  }

  &.sidebar-left {
    #applicator-map-sidebar.landing {
      border-right: none;
    }

    #applicator-map-sidebar {
      border-right: 1px solid #dbdbdb;
      order: 1;

      &-results {
        padding-left: var(--sidebar-padding);

        &-info {
          margin: 10px 0;
        }
      }
    }

    #applicator-map-content {
      order: 2;
    }
  }

  &.sidebar-right {
    #applicator-map-sidebar {
      border-left: 1px solid #dbdbdb;
      order: 2;

      &-results {
        padding-right: var(--sidebar-padding);
      }

      @media screen and (max-width: 1024px) {
        order: 1;
      }
    }

    #applicator-map-content {
      order: 1;

      @media screen and (max-width: 1024px) {
        order: 2;
      }
    }
  }

  &-sidebar.landing {
    z-index: 0;
    max-width:50%;
    width: 50%;

    .bg-image{
      filter: blur(4px);
      -webkit-filter: blur(4px);
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url($imgDir + 'applicator-map-placeholder.jpg');
    }

    border-right:none;

    @media screen and (max-width: 1024px) {
      border: none;
      width: 100%;
      max-width:100%;
    }
  }

  &-sidebar {
    background-color: #f9fafc;
    flex-direction: row;
    position: relative;
    max-width: 100%;
    display: flex;
    z-index: 2;

    @media screen and (max-width: 1024px) {
      border: none;
      width: 100%;
    }

    &-filters,
    &-results {
      padding: var(--sidebar-padding);
      flex-direction: column;
      position: relative;
      display: flex;
    }

    &-filters.landing {
      width:0px;
    }

    &-filters {
      max-height: calc(100vh - var(--desktop-header-size));
      width: var(--applicator-filters-width);
      border-right: 1px solid #dbdbdb;
      top: var(--desktop-header-size);
      overflow-y: auto;
      position: sticky;
      z-index: 2;

      @media screen and (max-width: 1500px) {
        transition: transform 200ms ease-in-out;
        transform: translate3d(-100%, 0, 0);
        top: var(--desktop-header-size);
        background-color: #f9fafc;
        max-height: 100vh;
        overflow-y: auto;
        position: fixed;
        width: auto;
        bottom: 0;
        left: 0;

        &[visible-on-desktop] {
          transform: translate3d(0, 0, 0);
          box-shadow: 0 10px 20px rgba(black, 0.19), 0 6px 6px rgba(black, 0.23);
        }
      }

      @media screen and (max-width: 1024px) {
        transform: translate3d(0, 0, 0);
        padding: var(--sidebar-padding);
        top: var(--mobile-header-size);
        box-shadow: none;
        display: none;
        border: none;
        right: 0;

        &[visible-on-mobile] {
          display: flex;
        }
      }
    }

    &-results.landing {
      width: 100%;

      @media screen and (max-width: 1024px) {
        filter: none !important;
      }
    }

    &-results {
      width: var(--sidebar-width);
      align-items: flex-start;
      z-index: 1;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      > .btn {
        margin-top: 16px;
      }
    }

    h2 {
      font-family: 'Teko', sans-serif;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      line-height: 0.75;
      font-weight: 500;
      font-size: 4rem;
      margin: 0;
      text-align: center;

      + strong {
        margin-top: 12px;
      }
    }

    &-loading {
      background-color: #efefef;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-radius: 4px;
      margin-top: 24px;
      display: flex;
      padding: 16px;
      width: 100%;

      > strong {
        margin-top: 16px;
      }
    }
  }

  &-list {
    list-style: none;
    margin-top: 24px;
    width: 100%;
  }

  &-content {
    height: calc(100vh - calc(var(--desktop-header-size) + 1px));
    top: calc(var(--desktop-header-size) + 1px);
    flex-direction: column;
    position: sticky;
    display: flex;
    flex: 1;

    @media screen and (max-width: 1024px) {
      height: auto;
      z-index: 2;
      bottom: 0;
      pointer-events: none;

      &.applicator-selected {
        display: none;
      }
    }
  }

  &-no-results,
  &-end-of-results {
    width: 100%;
    border-radius: 4px;
    font-weight: 600;
    padding: 16px;
    display: flex;
  }

  &-no-results {
    border: 1px solid #ffbb35;
    background-color: #f7f2d2;
  }

  &-end-of-results {
    border: 1px solid #40cbf9;
    background-color: #d7f4fe;
  }

  &[mobile-filters-open] {
    @media screen and (max-width: 1024px) {
      > #applicator-map-sidebar {
        background-color: red;
        z-index: 3;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  #applicator-map {
    height: 80vh;
  }

  .applicator-map-overlay {
    #applicator-map-view .map-placeholder {
      display: none;
    }

    #applicator-map-list .result-placeholder {
      display: none;
    }

    #applicator-map-content {
      pointer-events: all !important;
      min-height: auto;
      background: #fff;
    }

    #applicator-map-search {
      background-color: transparent;
      box-shadow: none;
      border-top: none;

      .mobile-toggle {
        display: none;
      }
    }
  }
}

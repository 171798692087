<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2.06174 1.20317">
    <defs>
      <clipPath id="clip-path" transform="translate(0.44346 0.01417)">
        <circle class="can-i-1" cx="0.58741" cy="0.58741" r="0.58741"/>
      </clipPath>
      <clipPath id="clip-path-2" transform="translate(0.44346 0.01417)">
        <rect class="can-i-1" x="-0.44346" y="-0.01417" width="2.06174" height="1.20317"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="World_Flags_285" data-name="World Flags 285">
        <g class="can-i-2">
          <g class="can-i-3">
            <rect class="can-i-4" width="0.47951" height="1.20317"/>
            <rect class="can-i-4" x="1.5749" width="0.48684" height="1.20317"/>
            <rect class="can-i-5" x="0.47951" width="1.09539" height="1.20317"/>
            <g class="can-i-3">
              <path class="can-i-4" d="M.58741.1398.51556.2791C.50823.29377.4943.29377.473.28644L.42244.25784.4657.469C.473.50859.44444.50859.42978.49393L.344.38688.32933.44041C.32273.44774.3154.45507.30807.45507L.19369.43014.22228.54085C.22961.56578.23621.57678.215.58338l-.04326.022.201.16423A.02315.02315,0,0,1,.38.79821L.36531.85906C.42978.8488.4943.84146.55882.83413c.00733,0,.01393.00367.01393.011l-.0066.21849H.60134L.59474.8488A.0151.0151,0,0,1,.60868.83413c.0719.00733.129.01467.20089.02493L.78757.79821A.03851.03851,0,0,1,.79491.76961L.99586.60538.95254.58338C.93788.57678.93788.56578.94521.54085L.97386.43014.86676.45507c-.01466,0-.022-.00733-.022-.01466L.83083.38688l-.09311.107C.72305.50859.70179.50859.70912.469L.74505.25784.69446.28644C.68058.29377.66592.29377.65927.2791Z" transform="translate(0.44346 0.01417)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "canadaIcon"
}
</script>

<style>
  .can-i-1 {
    fill: none;
  }

  .can-i-2 {
    clip-path: url(#clip-path);
  }

  .can-i-3 {
    clip-path: url(#clip-path-2);
  }

  .can-i-4 {
    fill: #ea0020;
  }

  .can-i-5 {
    fill: #f4f4f4;
  }
</style>